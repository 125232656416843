import { Grid } from '@mantine/core';
import { useTranslation } from 'next-i18next';

import { useRecapFormContext } from '@/views/recap-form';

import { Select } from './RecapFormElements';

export const LawJurisdictionFormGroup = () => {
  const { t } = useTranslation('recapForm');
  const { masterdata, getInputProps } = useRecapFormContext();

  return (
    <Grid>
      <Grid.Col span={12} md={3}>
        <Select {...getInputProps('lawJurisdiction.law')} data={masterdata.law} label={t('labels.law')} />
      </Grid.Col>
      <Grid.Col span={12} md={3}>
        <Select
          {...getInputProps('lawJurisdiction.jurisdiction')}
          data={masterdata.jurisdiction}
          label={t('labels.jurisdiction')}
        />
      </Grid.Col>
    </Grid>
  );
};

import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Box, Button, Group, Pagination, Paper, Stack, createStyles } from '@mantine/core';
import { randomId, usePagination } from '@mantine/hooks';
import { BiSortAlt2 } from 'react-icons/bi';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';

interface ListCardProps<T> {
  items: T[];
  itemsPerPage?: number;
  enableSort?: boolean;
  sortField?: keyof T;
  goToLastPageFlag?: boolean;
  renderCard: (item: T) => React.ReactNode;
}

const useStyles = createStyles((theme) => ({
  sort: {
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: 0,
  },
  button: {
    color: theme.colors.gray[7],
  },
  icon: {
    color: theme.colors.gray[6],
    fontSize: '1.2em',
    marginLeft: 5,
  },
}));

export function ListCard<T>(props: ListCardProps<T>) {
  const { items, itemsPerPage = 10, enableSort, sortField, goToLastPageFlag, renderCard } = props;

  const { t } = useTranslation('common');

  const { classes } = useStyles();

  //Sort
  const [sortOrder, setSortOrder] = useState<SortOrderType>('none');
  const [sortedItems, setSortedItems] = useState(items);

  const toggleSortOrder = () => {
    if (sortOrder === 'none') {
      setSortOrder('asc');
    } else if (sortOrder === 'asc') {
      setSortOrder('desc');
    } else {
      setSortOrder('none');
    }

    pagination.setPage(1);
  };

  const getSortIcon = (sortOrder) => {
    switch (sortOrder) {
      case 'asc':
        return <BsArrowUp className={classes.icon} />;
      case 'desc':
        return <BsArrowDown className={classes.icon} />;
      default:
        return <BiSortAlt2 className={classes.icon} />;
    }
  };

  useEffect(() => {
    if (sortOrder === 'none') {
      setSortedItems(items);
    } else {
      const sorted = [...items].sort((a, b) => {
        if (!sortField) return 0;
        if (sortOrder === 'asc') {
          return a[sortField] < b[sortField] ? -1 : 1;
        } else {
          return a[sortField] > b[sortField] ? -1 : 1;
        }
      });
      setSortedItems(sorted);
    }
  }, [sortOrder, items, sortField]);

  //Pagination
  const totalPages = Math.ceil(sortedItems.length / itemsPerPage);
  const pagination = usePagination({ total: totalPages, initialPage: 1 });

  const startIndex = (pagination.active - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = sortedItems.slice(startIndex, endIndex);

  useEffect(() => {
    if (goToLastPageFlag) {
      pagination.setPage(totalPages);
    }
  }, [goToLastPageFlag, totalPages]);

  const handlePageChange = (page: number) => {
    pagination.setPage(page);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Stack spacing={0}>
      {enableSort && sortField && (
        <Paper py={8} className={classes.sort}>
          <Group position={'right'}>
            <Button className={classes.button} onClick={toggleSortOrder} variant={'subtle'}>
              {t('listCard.sort')}
              {getSortIcon(sortOrder)}
            </Button>
          </Group>
        </Paper>
      )}
      {currentItems.map((item) => (
        <Box
          key={randomId()}
          sx={(theme) => ({
            borderBottom: `1px solid ${theme.colors.gray[3]}`,
          })}
        >
          {renderCard(item)}
        </Box>
      ))}
      <Paper py={12}>
        <Group position={'center'}>
          <Pagination total={totalPages} page={pagination.active} onChange={handlePageChange} />
        </Group>
      </Paper>
    </Stack>
  );
}

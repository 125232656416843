/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type GetCargoTenderMarketplaceListSortCreatedAt =
  (typeof GetCargoTenderMarketplaceListSortCreatedAt)[keyof typeof GetCargoTenderMarketplaceListSortCreatedAt];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCargoTenderMarketplaceListSortCreatedAt = {
  asc: 'asc',
  desc: 'desc',
} as const;

import { CheckboxProps } from '@mantine/core';

import { ThemeComponent } from './types';

export const Checkbox: ThemeComponent<CheckboxProps> = {
  defaultProps: {
    color: 'green',
  },
  styles: () => ({
    root: {
      position: 'relative',
    },
    body: {
      position: 'relative',
    },
    error: {
      position: 'absolute',
      marginTop: 0,
    },
    label: {
      fontSize: 16,
    },
  }),
};

import { FreightRateTerms, FreightRecapForm, FreightRequestForm } from '@/blink-forms';

export function createInitialValuesFromRequest(freightRequestForm: FreightRequestForm): FreightRecapForm {
  return {
    price: {
      price: freightRequestForm.priceTarget ?? freightRequestForm.priceEstimate ?? 0,
    },
    product: {
      name: freightRequestForm.productName,
      grade: freightRequestForm.productGrade,
      packaging: freightRequestForm.productPackaging,
      quantity: freightRequestForm.quantity,
      minTolerance: freightRequestForm.toleranceMinPercentage,
      maxTolerance: freightRequestForm.toleranceMaxPercentage,
      loadingPort: freightRequestForm.loadingPort,
      loadingPortGuaranteedDraft: freightRequestForm.loadingGuaranteedDraft,
      loadingPortGuaranteedLOA: freightRequestForm.loadingGuaranteedLOA,
      loadingPortGuaranteedBeam: freightRequestForm.loadingGuaranteedBeam,
      dischargingPort: freightRequestForm.dischargingPort,
      dischargingPortGuaranteedDraft: freightRequestForm.dischargingGuaranteedDraft,
      dischargingPortGuaranteedLOA: freightRequestForm.dischargingGuaranteedLOA,
      dischargingPortGuaranteedBeam: freightRequestForm.dischargingGuaranteedBeam,
      laycan: [freightRequestForm.laycanMin, freightRequestForm.laycanMax],
    },
    loadingConditions: {
      freightRate: freightRequestForm.loadingRate,
      freightRateTerms: freightRequestForm.loadingTerms as FreightRateTerms,
    },
    dischargingConditions: {
      freightRate: freightRequestForm.dischargingRate,
      freightRateTerms: freightRequestForm.dischargingTerms as FreightRateTerms,
    },
  };
}

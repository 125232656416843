import { Badge, createStyles } from '@mantine/core';
import { NegotiationStatus } from 'api/generated/model';
import { useTranslation } from 'next-i18next';
import { ReactElement } from 'react';
import { DefaultMantineColor } from '@mantine/core';
import {
  RiCheckboxCircleLine,
  RiFileMarkLine,
  RiFolderReceivedLine,
  RiFolderSharedLine,
  RiForbid2Line,
  RiPenNibLine,
  RiTimeLine,
} from 'react-icons/ri';

export interface NegotiationBadgeProps {
  status: NegotiationStatus;
  hasDraft: boolean;
}
const useStyles = createStyles((theme) => ({
  badge: {
    alignItems: 'flex-start',
    textAlign: 'start',
  },
  icon: {
    fontSize: 18,
  },
  iconYellow: {
    color: theme.colors.yellow[9],
  },
  iconGreen: {
    color: theme.colors.green[9],
  },
  iconRed: {
    color: theme.colors.red[9],
  },
  iconBlue: {
    color: theme.fn.primaryColor(),
  },
  iconIndigo: {
    color: theme.colors.indigo[9],
  },
  iconGray: {
    color: theme.colors.gray[9],
  },
}));

export function NegotiationBadge(props: NegotiationBadgeProps) {
  const { status: unpreparedStatus, hasDraft } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation('common');
  const status = hasDraft ? NegotiationStatus.DRAFT : unpreparedStatus;

  let icon: ReactElement;
  let color: DefaultMantineColor;

  switch (status) {
    case 'RFQ_SENT':
    case 'OFFER_SENT':
    case 'COUNTER_OFFER_SENT':
      icon = <RiFolderSharedLine className={cx(classes.iconGreen, classes.icon)} />;
      color = 'green';
      break;
    case 'RFQ_RECEIVED':
    case 'OFFER_RECEIVED':
    case 'COUNTER_OFFER_RECEIVED':
      icon = <RiFolderReceivedLine className={cx(classes.iconYellow, classes.icon)} />;
      color = 'yellow';
      break;
    case 'REVIEW_SENT':
      icon = <RiPenNibLine className={cx(classes.iconYellow, classes.icon)} />;
      color = 'yellow';
      break;
    case 'REVIEW_RECEIVED':
      icon = <RiPenNibLine className={cx(classes.iconIndigo, classes.icon)} />;
      color = 'indigo';
      break;
    case 'EXPIRED':
      icon = <RiTimeLine className={cx(classes.iconGray, classes.icon)} />;
      color = 'gray';
      break;
    case 'REJECTED':
    case 'REJECTED_BUYER':
    case 'REJECTED_SELLER':
    case 'CANCELED':
    case 'SELLER_CANCELED':
    case 'BUYER_CANCELED':
    case 'INVALID_BUYER':
    case 'INVALID_SELLER':
      icon = <RiForbid2Line className={cx(classes.iconRed, classes.icon)} />;
      color = 'red';
      break;
    case 'SELLER_ACCEPTED':
    case 'BUYER_ACCEPTED':
      icon = <RiCheckboxCircleLine className={cx(classes.iconBlue, classes.icon)} />;
      color = 'blue';
      break;
    case 'DRAFT':
      icon = <RiFileMarkLine className={cx(classes.iconGray, classes.icon)} />;
      color = 'gray';
      break;
    default:
      throw new Error(`Invalid negotiation status`);
  }

  return (
    <Badge className={classes.badge} leftSection={icon} color={color} size="xs" data-testid="negotiation-badge">
      {t(`badges.negotiationBadge.${status}`)}
    </Badge>
  );
}

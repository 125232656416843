import { useQuery } from '@tanstack/react-query';
import { SelectItem } from '@mantine/core';

import { RecapFormMasterdata } from './../types';
import masterdata from '../masterdata.json';

const getMasterdata = (): Promise<RecapFormMasterdata> => Promise.resolve(masterdata);

function selectItemsToTranslation(items: SelectItem[]) {
  return items.reduce<Record<string, string>>((acc, curr) => ({ ...acc, [curr.value]: curr.label || curr.value }), {});
}

export type RecapFormMasterdataTranslations = Record<keyof RecapFormMasterdata, Record<string, string>>;

function masterdataToTranslations(data: RecapFormMasterdata): RecapFormMasterdataTranslations {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => [key, selectItemsToTranslation(value)])
  ) as RecapFormMasterdataTranslations;
}

// simulate that we get it from the backend
export function useRecapFormMasterdata() {
  const dropdownValues = useQuery(['masterdata'], getMasterdata);
  const translations = useQuery(['masterdata'], getMasterdata, { select: masterdataToTranslations });

  return { dropdownValues, translations };
}

import { FreightRecapForm } from '@/blink-forms';

import { emptyFreightRecapFormValues, emptySelectedFreightPaymentConditions } from './empty-freight-recap-form';

export function populateInitState(freightRequestForm?: FreightRecapForm) {
  const emptyFormState = emptyFreightRecapFormValues();
  const initialValues: FreightRecapForm = freightRequestForm
    ? {
        price: { ...emptyFormState.price, ...freightRequestForm.price },
        product: { ...emptyFormState.product, ...freightRequestForm.product },
        vessel: { ...emptyFormState.vessel, ...freightRequestForm.vessel },
        loadingConditions: { ...emptyFormState.loadingConditions, ...freightRequestForm.loadingConditions },
        dischargingConditions: { ...emptyFormState.dischargingConditions, ...freightRequestForm.dischargingConditions },
        nomination: { ...emptyFormState.nomination, ...freightRequestForm.nomination },
        payment: { ...emptyFormState.payment, ...freightRequestForm.payment },
        comments: freightRequestForm?.comments ?? emptyFormState.comments,
      }
    : emptyFormState;
  const selectedFreightPaymentConditions = emptySelectedFreightPaymentConditions(initialValues.payment.conditions);

  return {
    initialValues,
    selectedFreightPaymentConditions,
  };
}

import { ReactNode, ReactElement } from 'react';
import { BaseButton } from 'v2/components/shared/buttons/BaseButton/BaseButton';
import { Stack, Modal, Center, ModalProps, Flex } from '@mantine/core';

import { Title, NeedHelp } from '@/components';

import { useFlexibleConfirmationModalStyle } from './FlexibleConfirmationModal.style';

export interface FlexibleConfirmationModalProps extends Omit<ModalProps, 'onClose' | 'content'> {
  opened: boolean;
  header: ReactNode;
  icon?: ReactElement;
  cancelLabel: string;
  confirmLabel: string;
  scrollable?: boolean;
  content?: ReactElement;
  onCancel: VoidFunction;
  onConfirm: VoidFunction;
  displayContact?: boolean;
  displayClosing?: boolean;
  titleCentralized?: boolean;
  onConfirmDisabled?: boolean;
}

export const FlexibleConfirmationModal = ({
  icon,
  opened,
  header,
  content,
  onCancel,
  onConfirm,
  cancelLabel,
  confirmLabel,
  scrollable = false,
  displayContact = false,
  displayClosing = false,
  titleCentralized = false,
  onConfirmDisabled = false,
  ...mantineModalProps
}: FlexibleConfirmationModalProps) => {
  const { classes } = useFlexibleConfirmationModalStyle({ scrollable, titleCentralized });

  return (
    <Modal
      radius="md"
      padding={24}
      opened={opened}
      onClose={onCancel}
      classNames={classes}
      data-testid={'flexible-modal'}
      closeOnEscape={displayClosing}
      withCloseButton={displayClosing}
      closeOnClickOutside={displayClosing}
      {...mantineModalProps}
    >
      <Stack spacing={25}>
        <Center mb={-8}>{icon}</Center>
        <Title order={2} size="XL" weight={600} className={classes.customTextAlign}>
          {header}
        </Title>
        {content}
        <Flex justify="space-between" mt={10}>
          <BaseButton
            size="lg"
            type="submit"
            variant="outline"
            onClick={onCancel}
            label={cancelLabel}
            sx={{ width: '48.5%' }}
          />
          <BaseButton
            size="lg"
            onClick={onConfirm}
            label={confirmLabel}
            sx={{ width: '48.5%' }}
            disabled={onConfirmDisabled}
          />
        </Flex>
        {displayContact && <NeedHelp />}
      </Stack>
    </Modal>
  );
};

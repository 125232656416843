import { z } from 'zod';

import {
  BerthConditions,
  CostsTermsWithoutShared,
  ExclusionFromLayTime,
  LaytimeCalculations,
  NorTendering,
  TurnTimeTerms,
} from '../../types';

export const loadingDischargingConditionsGeneralInfo = z.object({
  noOfSafePort: z.number(),
  noOfSafeBerth: z.number(),
  berthConditions: z.nativeEnum(BerthConditions).optional().nullable(),
  norTendering: z.nativeEnum(NorTendering),
  turnTime: z.number(),
  turnTimeTerms: z.nativeEnum(TurnTimeTerms).optional().nullable(),
  exclusionFromLaytime: z.array(z.nativeEnum(ExclusionFromLayTime)).optional(),
  otherExclusionFormLaytime: z.string().optional(),
  laytimeCalculations: z.array(z.nativeEnum(LaytimeCalculations)).optional(),
  cargoPDA: z.nativeEnum(CostsTermsWithoutShared).optional(),
  vesselPDA: z.nativeEnum(CostsTermsWithoutShared).optional(),
  other: z.string().optional(),
});

export type LoadingDischargingConditionsGeneralInfoSchema = z.infer<typeof loadingDischargingConditionsGeneralInfo>;

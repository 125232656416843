import { createStyles, ThemeIcon } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { RiErrorWarningFill } from 'react-icons/ri';
import { StringParam, useQueryParams } from 'use-query-params';
import { useGetCargoNegotiationHistory } from 'api/generated/cargo-negotiation/cargo-negotiation';

import { BaseModal } from '@/components';

import { CancelNegotiationsHeaders, CancelNegotiationState } from '../types';
import { useCancelNegotiationStepper } from '../use-cancel-negotiation-stepper';
import { CounterpartyForm } from './CounterpartyForm';
import { DiscontinueNegotiations } from './DiscontinueNegotiations';
import { PlatformFeedbackForm } from './PlatformFeedbackForm';

const useStyles = createStyles((theme) => ({
  themeIcon: {
    width: 40,
    height: 40,
    borderRadius: theme.radius.md,
  },
  icon: {
    color: theme.colors.orange[9],
    fontSize: 20,
  },
}));

export interface CancelModalProps {
  opened: boolean;
  cancelType: 'CANCEL' | 'REJECT';
  toggleCancelNegotiationModal: VoidFunction;
}

export function CancelModal(props: CancelModalProps) {
  const { opened, cancelType, toggleCancelNegotiationModal } = props;
  const [{ negotiationId }] = useQueryParams({ negotiationId: StringParam });
  const negotiation = useGetCargoNegotiationHistory(negotiationId, { query: { select: (resp) => resp.data } });
  const status = negotiation.data?.negotiationHistory?.[0]?.status;
  const { state, formState, onBack, onNext } = useCancelNegotiationStepper({
    toggleCancelNegotiationModal,
    negotiationId,
    status,
    cancelType,
  });
  const { t } = useTranslation('myCargos');
  const { classes } = useStyles();
  const headers: Omit<CancelNegotiationsHeaders, CancelNegotiationState.CANCEL_NEGOTIATION_SUCCESS> = {
    [CancelNegotiationState.CANCEL_NEGOTIATION]: t('modals.cancel.headers.discontinueNegotiations'),
    [CancelNegotiationState.SEND_MESSAGE_TO_COUNTERPARTY]: t('modals.cancel.headers.messageCounterparty'),
    [CancelNegotiationState.SEND_FEEDBACK_FOR_PLATFORM]: t('modals.cancel.headers.platformFeedback'),
    [CancelNegotiationState.SUBMITTING]: t('modals.cancel.headers.platformFeedback'),
    [CancelNegotiationState.SINGLE_STEP_CANCEL_NEGOTIATION]: t('modals.cancel.headers.discontinueNegotiations'),
  };

  return (
    <BaseModal
      icon={
        <ThemeIcon className={classes.themeIcon} color="orange.0">
          <RiErrorWarningFill className={classes.icon} />
        </ThemeIcon>
      }
      header={headers[state]}
      opened={opened}
      content={
        {
          [CancelNegotiationState.CANCEL_NEGOTIATION]: (
            <DiscontinueNegotiations onBackClick={onBack} onNextClick={onNext} />
          ),
          [CancelNegotiationState.SINGLE_STEP_CANCEL_NEGOTIATION]: (
            <DiscontinueNegotiations onBackClick={onBack} onNextClick={onNext} />
          ),
          [CancelNegotiationState.SEND_MESSAGE_TO_COUNTERPARTY]: (
            <CounterpartyForm onBackClick={onBack} onNextClick={onNext} formState={formState} />
          ),
          [CancelNegotiationState.SEND_FEEDBACK_FOR_PLATFORM]: (
            <PlatformFeedbackForm onBackClick={onBack} onNextClick={onNext} formState={formState} submitting={false} />
          ),
          [CancelNegotiationState.SUBMITTING]: (
            <PlatformFeedbackForm onBackClick={onBack} onNextClick={onNext} formState={formState} submitting={true} />
          ),
        }[state]
      }
      close={toggleCancelNegotiationModal}
    />
  );
}

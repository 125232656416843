/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoTenderNegotiationStatusParticipationApproved =
  (typeof CargoTenderNegotiationStatusParticipationApproved)[keyof typeof CargoTenderNegotiationStatusParticipationApproved];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoTenderNegotiationStatusParticipationApproved = {
  PARTICIPATION_APPROVED: 'PARTICIPATION_APPROVED',
} as const;

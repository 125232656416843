import { Badge, DefaultMantineColor, Tooltip } from '@mantine/core';
import { CargoShipmentStatus } from 'api/generated/model';
import { useTranslation } from 'next-i18next';

import { truncateString } from '@/core/helpers/truncateString';

export interface CargoShipmentStatusBadgeProps {
  status: CargoShipmentStatus | null;
}

export function CargoShipmentStatusBadge(props: CargoShipmentStatusBadgeProps) {
  const { status } = props;
  const { t } = useTranslation('common');
  let color: DefaultMantineColor;
  const translation = status
    ? t(`badges.cargoShipmentStatus.${status}`)
    : t('badges.cargoShipmentStatus.NOT_AVAILABLE');

  switch (status) {
    case CargoShipmentStatus.FIXED:
    case CargoShipmentStatus.LAYCAN_DECLARED:
    case CargoShipmentStatus.NOMINATED:
    case CargoShipmentStatus.WAITING_AT_LOAD_PORT:
    case CargoShipmentStatus.LOADING:
      color = 'grape';
      break;
    case CargoShipmentStatus.SAILING:
    case CargoShipmentStatus.WAITING_AT_DISCHARGE_PORT:
    case CargoShipmentStatus.DISCHARGING:
      color = 'blue';
      break;
    case CargoShipmentStatus.IN_TRANSIT_TO_CONSUMER:
    case CargoShipmentStatus.AT_CONSUMER:
    case CargoShipmentStatus.COMPLETED:
      color = 'green';
      break;
    default:
      color = 'gray';
  }

  return translation.length > 13 ? (
    <Tooltip label={status ? translation : t('labels.linkFreight')} withArrow>
      <Badge color={color} size="xs" data-testid="cargoShipmentStatus-badge" sx={{ textAlign: 'start' }}>
        {truncateString(translation, 10)}
      </Badge>
    </Tooltip>
  ) : (
    <Tooltip label={status === null && t('labels.linkFreight')} withArrow>
      <Badge color={color} size="xs" data-testid="cargoShipmentStatus-badge" sx={{ textAlign: 'start' }}>
        {translation}
      </Badge>
    </Tooltip>
  );
}

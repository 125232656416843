import { createStyles, Paper } from '@mantine/core';

import { FreightEstimateValue } from '@/views/freight-details/components/FreightEstimateValue';
import { getMiddleValueBetweenDates } from '@/core/helpers/dateHelpers';

const useStyles = createStyles((theme) => ({
  paper: {
    background: theme.colors.blue[0],
    borderColor: theme.colors.blue[1],
  },
}));

export interface FreightEstimateAdpaterProps {
  loadingPortUnlocode?: string;
  dischargingPortUnlocode?: string;
  loadingRate?: number;
  dischargingRate?: number;
  quantity?: number;
  toleranceMinPercentage?: number;
  toleranceMaxPercentage?: number;
  laycanMin?: string;
  laycanMax?: string;
  productName?: string;
}

export function FreightEstimateAdpater(props: FreightEstimateAdpaterProps) {
  const { classes } = useStyles();

  const {
    loadingPortUnlocode,
    dischargingPortUnlocode,
    loadingRate,
    dischargingRate,
    quantity,
    toleranceMinPercentage,
    toleranceMaxPercentage,
    laycanMin,
    laycanMax,
    productName,
  } = props ?? {};

  return (
    <Paper withBorder p="md" radius="md" className={classes.paper}>
      <FreightEstimateValue
        loadingPort={loadingPortUnlocode}
        dischargingPort={dischargingPortUnlocode}
        loadingRate={loadingRate}
        dischargingRate={dischargingRate}
        quantity={quantity}
        minRange={toleranceMinPercentage}
        maxRange={toleranceMaxPercentage}
        laycanDate={getMiddleValueBetweenDates(laycanMin, laycanMax)}
        product={productName?.toUpperCase() || ''}
      />
    </Paper>
  );
}

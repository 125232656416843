import { ActionIcon, Button, createStyles, Group, Indicator } from '@mantine/core';
import { CellContext, ColumnDefTemplate } from '@tanstack/react-table';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { RiChat3Line } from 'react-icons/ri';
import { useGetChatGroupsUnreadMessages } from 'api/generated/chat/chat';
import { AvailableFeatureFlags, BookedCargoDTO, UserRole } from 'api/generated/model';

import { formatDate } from '@/common/format-date';
import { routes } from '@/common/routes';
import { ActionMenu, CargoWorkflowGlobalEventBadge, ENABLE_SENDBIRD, HideForbiddenElement, Text } from '@/components';
import { useMediaQuery } from '@/core/hooks/use-media-query';
import { useAuthRoles, useFeature } from '@/core';
import { useEnablePrivateDataForCSM } from '@/common/use-enable-private-data-for-csm';

import { BookedCargoTableActionFunction } from '../../types';
import { CargoIdRefIdCell, CompanyNameCell, LinkedEntitiesCell, VesselNameCell } from './TableCells';

const useStyles = createStyles(() => ({
  indicator: {
    transform: 'translate(7px, -7px) !important',
  },
}));

type MyCargosBookedTableCell = ColumnDefTemplate<CellContext<BookedCargoDTO, string>>;

export const BookedCargoIdRefIdCell: MyCargosBookedTableCell = ({ row }) => {
  return (
    <CargoIdRefIdCell
      workflowId={row.original.id}
      shortId={row.original.shortId}
      externalRefId={row.original.externalRefId}
    />
  );
};

export const BookedCompanyNameCell: MyCargosBookedTableCell = ({ row }) => {
  return (
    <CompanyNameCell
      companyId={row.original.counterPartyCompany?.id}
      companyName={row.original.counterPartyCompany?.name}
    />
  );
};

export const BookedVesselNameCell: MyCargosBookedTableCell = ({ row }) => {
  return <VesselNameCell vesselIMO={row.original.vesselIMO} vesselName={row.original.vesselName} />;
};

export const NextEventDateCell: MyCargosBookedTableCell = ({ row }) => {
  const { t } = useTranslation('myCargos');

  return (
    <Group spacing={8}>
      {row.original.nextEvent ? (
        <>
          <CargoWorkflowGlobalEventBadge event={row.original.nextEvent.type} />
          <Text color="gray.9" weight={500}>
            {formatDate(row.original.nextEvent.date)}
          </Text>
        </>
      ) : (
        <Text color="gray.6" italic>
          {t('labels.na')}
        </Text>
      )}
    </Group>
  );
};

export const BookedLinkedEntitiesCell: MyCargosBookedTableCell = ({ row }) => {
  return (
    <LinkedEntitiesCell
      linkedWorkflowId={row.original.linkedWorkflowId}
      linkedWorkflowShortId={row.original.linkedWorkflowShortId}
    />
  );
};

interface MyCargosBookedTableActionCellProps extends CellContext<BookedCargoDTO, string> {
  onRowClick: BookedCargoTableActionFunction;
  onLinkCargoClick: BookedCargoTableActionFunction;
  onArchiveCargoClick: BookedCargoTableActionFunction;
  onDuplicateCargoClick: BookedCargoTableActionFunction;
}

export const MyCargosBookedTableActionCell: ColumnDefTemplate<MyCargosBookedTableActionCellProps> = ({
  onRowClick,
  onLinkCargoClick,
  onArchiveCargoClick,
  onDuplicateCargoClick,
  row,
}) => {
  const { t } = useTranslation('myCargos');
  const { classes, theme } = useStyles();
  const router = useRouter();
  const isMobile = useMediaQuery(theme.breakpoints.md);
  const { onEnablePrivateDataForCSM } = useEnablePrivateDataForCSM();
  const isNewLinkingCargosEnabled = useFeature(AvailableFeatureFlags.newLinkingCargosEnabled);
  const { isServiceProvider } = useAuthRoles();
  const unreadMessageTotalCount = useGetChatGroupsUnreadMessages({
    query: {
      select: ({ data }) => data.length,
      enabled: ENABLE_SENDBIRD,
    },
  });

  const actions = !isServiceProvider
    ? [
        { label: t('table.menu.cmsEnablePrivacyData'), onClick: () => onEnablePrivateDataForCSM(row) },
        ...[{ label: t('table.menu.archiveCargo'), onClick: () => onArchiveCargoClick(row) }],
        { label: t('table.menu.duplicateCargo'), onClick: () => onDuplicateCargoClick(row) },
      ]
    : [];

  return (
    <Group spacing={12} position="apart" noWrap>
      {ENABLE_SENDBIRD && !isMobile && !isServiceProvider && (
        <Indicator
          classNames={classes}
          inline
          disabled={(unreadMessageTotalCount.isLoading || 0) === 0}
          label={unreadMessageTotalCount.data}
          color={theme.colors.red[9]}
          size={20}
          radius="xl"
          onClick={() => router.push(routes.CargoWorkflowChat(row.original.id))}
          sx={{ lineHeight: '16px' }}
        >
          <ActionIcon variant="transparent">
            <RiChat3Line size={20} color={theme.fn.primaryColor()} />
          </ActionIcon>
        </Indicator>
      )}

      <Button variant="outline" onClick={() => onRowClick(row)}>
        {isNewLinkingCargosEnabled ? t('table.buttons.viewInCCT') : t('table.buttons.viewCargo')}
      </Button>

      <HideForbiddenElement
        allowUnverifiedCompany
        allowedRoles={[UserRole.BUYER, UserRole.SELLER, UserRole.OPERATIONS, UserRole.SERVICES, UserRole.FINANCE]}
      >
        {!isServiceProvider && <ActionMenu position="bottom-end" actions={actions} withinPortal />}
      </HideForbiddenElement>
    </Group>
  );
};

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type DemurrageEvents = (typeof DemurrageEvents)[keyof typeof DemurrageEvents];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DemurrageEvents = {
  TO_BE_ADVISED_WITH_VESSEL_NOMINATION: 'TO_BE_ADVISED_WITH_VESSEL_NOMINATION',
  FIXED_AT_RECAP: 'FIXED_AT_RECAP',
} as const;

const path = require('path');

const ssrPath = path.resolve('./public/locales');

/**
 * @type {import('next-i18next/dist/types/types').UserConfig}
 **/
module.exports = {
  i18n: {
    defaultLocale: 'en-US',
    locales: ['en-US'],
  },
  fallbackLng: 'en-US',
  debug: false,
  reloadOnPrerender: process.env.NODE_ENV !== 'production',
  localePath: typeof window === 'undefined' ? ssrPath : '/locales',
};

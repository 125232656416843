import { Box, Paper, PaperProps } from '@mantine/core';

const MAX_WIDTH = 1232; // number is set based on usage in the app

export function PaperContainer({ children, ...restProps }: PaperProps) {
  return (
    <Paper px={24} {...restProps}>
      <Box maw={MAX_WIDTH} mx="auto">
        {children}
      </Box>
    </Paper>
  );
}

import { Stepper as MnStepper, Group, createStyles } from '@mantine/core';
import { RefObject, createRef, useEffect, useRef } from 'react';
import { IconType } from 'react-icons/lib';
import { RiCheckLine } from 'react-icons/ri';

const useStyles = createStyles((theme) => ({
  stepperContainer: {
    paddingTop: 24,
  },
  root: {
    width: '100%',
    overflowX: 'auto',
    paddingTop: 20,
    paddingBottom: 20,
  },
  steps: {
    alignItems: 'flex-start',
    flex: 1,
    width: '100%',
  },
  step: {
    position: 'relative',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 150,
    flex: 1,

    '&[data-completed] > div': {
      '&::after': {
        backgroundColor: theme.colors.primary[1],
      },
      '&::before': {
        backgroundColor: theme.colors.primary[1],
      },
    },

    '&[data-progress] > div': {
      '&::before': {
        backgroundColor: theme.colors.primary[1],
      },
    },

    '&:first-of-type > div::before': {
      display: 'none',
    },

    '&:last-child > div::after': {
      display: 'none',
    },
  },
  stepWrapper: {
    zIndex: 2,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '50%',
      transform: 'translateY(-50%)',
      height: 4,
      backgroundColor: theme.colors.gray[3],
      zIndex: 1,
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      right: '50%',
      width: '50%',
      transform: 'translateY(-50%)',
      height: 4,
      backgroundColor: theme.colors.gray[3],
      zIndex: 1,
    },
  },
  stepLabel: {
    textAlign: 'center',
    marginTop: 20,
  },
  inactiveStepLabel: {
    color: theme.colors.gray[6],
    fontSize: theme.fontSizes.xs,
  },
  stepDescription: {
    textAlign: 'center',
    fontSize: theme.fontSizes.sm,
  },
  stepIcon: {
    borderWidth: 0,
    backgroundColor: theme.colors.gray[3],
    zIndex: 2,
    height: 24,
    width: 24,
    minWidth: 24,

    '&[data-completed]': {
      display: 'flex',
    },
    '&[data-progress]': {
      display: 'flex',
      backgroundColor: theme.colors.primary[1],
      '&::after': {
        content: '""',
        position: 'absolute',
        boxShadow: `0 0 0 8px rgba(0, 90, 158, 0.05), 0 0 0 16px rgba(0, 90, 158, 0.05)`,
        borderRadius: '50%',
        width: '100%',
        height: '100%',
      },
    },
  },
  separator: {
    display: 'none',
  },
  halfFilledSeparator: {
    position: 'relative',
    '&::after': {
      backgroundColor: theme.colors.primary[1],
    },
  },
}));

export interface StepItem {
  /**
   * Label text for the step.
   */
  label: string;
  /**
   * Optional description for the step.
   */
  description?: string;
  /**
   * Indicates if the step is currently active
   */
  isActive: boolean;
}

export interface StepperProps {
  /**
   * Array of step items to be displayed in the stepper.
   * Each step item represents a step in the process.
   */
  steps: StepItem[];
  /**
   * Optional icon to be displayed in the progress state.
   * Accepts a React icon component.
   */
  progressIcon?: IconType;
  /**
   * Indicates whether the steps are clickable.
   * If true, steps can be selected interactively.
   */
  clickable: boolean;
  /**
   * Callback function triggered on step click.
   * Receives the index of the clicked step.
   */
  onStepClick?: (index: number) => void;
}

export function Stepper(props: StepperProps) {
  const { steps, clickable, progressIcon: Icon, onStepClick } = props;
  const { classes, cx } = useStyles();

  const stepperRef = useRef<HTMLDivElement>(null);
  const stepRefs = useRef<Array<RefObject<HTMLButtonElement>>>([]);

  const activeStepIndex = steps.findIndex((step) => step.isActive);

  const isHalfFilled = steps.length === 2 && activeStepIndex === 0;

  const stepIcon = Icon ? <Icon size={14} color="white" /> : <RiCheckLine size={14} color="white" />;

  const handleStepClick = (index: number) => {
    if (!clickable || !onStepClick) return;
    onStepClick(index);
  };

  const centerActiveStep = () => {
    const activeStepEl = stepRefs.current[activeStepIndex]?.current;
    if (activeStepEl && stepperRef.current) {
      const containerWidth = stepperRef.current.offsetWidth;
      const childWidth = activeStepEl.offsetWidth;
      const childLeft = activeStepEl.offsetLeft;

      const scrollLeft = childLeft - containerWidth / 2 + childWidth / 2;

      stepperRef.current.scrollTo({
        left: scrollLeft,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (stepRefs.current.length !== steps.length) {
      stepRefs.current = steps.map(() => createRef<HTMLButtonElement>());
    }
    centerActiveStep();
  }, [steps]);

  return (
    <Group position="center" px="xl" w="100%" className={classes.stepperContainer}>
      <MnStepper
        ref={stepperRef}
        active={activeStepIndex}
        onStepClick={handleStepClick}
        classNames={{
          ...classes,
          stepWrapper: cx(classes.stepWrapper, { [classes.halfFilledSeparator]: isHalfFilled }),
        }}
      >
        {steps.map((step, index) => (
          <MnStepper.Step
            key={`${step.label}-${index}`}
            ref={stepRefs.current[index]}
            label={
              <span className={cx(classes.stepLabel, { [classes.inactiveStepLabel]: index !== activeStepIndex })}>
                {step.label}
              </span>
            }
            description={step.description}
            completedIcon={index <= activeStepIndex ? stepIcon : <div></div>}
            icon={index <= activeStepIndex ? stepIcon : <div></div>}
          ></MnStepper.Step>
        ))}
      </MnStepper>
    </Group>
  );
}

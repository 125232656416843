/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CounterpartyContactNewDTOType =
  (typeof CounterpartyContactNewDTOType)[keyof typeof CounterpartyContactNewDTOType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CounterpartyContactNewDTOType = {
  NEW: 'NEW',
} as const;

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type BerthConditions = (typeof BerthConditions)[keyof typeof BerthConditions];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BerthConditions = {
  AAAA: 'AAAA',
  AA: 'AA',
  NAABSA: 'NAABSA',
} as const;

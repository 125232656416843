/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoTenderFreightConditionsDTONorTendering =
  (typeof CargoTenderFreightConditionsDTONorTendering)[keyof typeof CargoTenderFreightConditionsDTONorTendering];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoTenderFreightConditionsDTONorTendering = {
  ATDN_PLUS_SSHINC: 'ATDN_PLUS_SSHINC',
  FSHINC: 'FSHINC',
  SSHEX_EIU: 'SSHEX_EIU',
  SSHEX_UU: 'SSHEX_UU',
  SATNOONSHEX_EIU: 'SATNOONSHEX_EIU',
  SATNOONSHEX_UU: 'SATNOONSHEX_UU',
  SHEX_EIU: 'SHEX_EIU',
  SHEX_UU: 'SHEX_UU',
} as const;

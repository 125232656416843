/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';

import type {
  FreightRecapFormTemplateListDTO,
  CreateFreightRecapFormTemplateDTO,
  FreightRecapFormTemplateDTO,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getFreightNegotiationContractTemplateList = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<FreightRecapFormTemplateListDTO>> => {
  return axios.get(`/api/v1/freight/templates`, options);
};

export const getGetFreightNegotiationContractTemplateListQueryKey = () => [`/api/v1/freight/templates`];

export type GetFreightNegotiationContractTemplateListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFreightNegotiationContractTemplateList>>
>;
export type GetFreightNegotiationContractTemplateListQueryError = AxiosError<void>;

export const useGetFreightNegotiationContractTemplateList = <
  TData = Awaited<ReturnType<typeof getFreightNegotiationContractTemplateList>>,
  TError = AxiosError<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getFreightNegotiationContractTemplateList>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFreightNegotiationContractTemplateListQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFreightNegotiationContractTemplateList>>> = ({ signal }) =>
    getFreightNegotiationContractTemplateList({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getFreightNegotiationContractTemplateList>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const createFreightNegotiationContractTemplate = (
  createFreightRecapFormTemplateDTO: CreateFreightRecapFormTemplateDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/v1/freight/templates`, createFreightRecapFormTemplateDTO, options);
};

export type CreateFreightNegotiationContractTemplateMutationResult = NonNullable<
  Awaited<ReturnType<typeof createFreightNegotiationContractTemplate>>
>;
export type CreateFreightNegotiationContractTemplateMutationBody = CreateFreightRecapFormTemplateDTO;
export type CreateFreightNegotiationContractTemplateMutationError = AxiosError<unknown>;

export const useCreateFreightNegotiationContractTemplate = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createFreightNegotiationContractTemplate>>,
    TError,
    { data: CreateFreightRecapFormTemplateDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createFreightNegotiationContractTemplate>>,
    { data: CreateFreightRecapFormTemplateDTO }
  > = (props) => {
    const { data } = props ?? {};

    return createFreightNegotiationContractTemplate(data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof createFreightNegotiationContractTemplate>>,
    TError,
    { data: CreateFreightRecapFormTemplateDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const getFreightNegotiationContractTemplate = (
  templateId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<FreightRecapFormTemplateDTO>> => {
  return axios.get(`/api/v1/freight/templates/${templateId}`, options);
};

export const getGetFreightNegotiationContractTemplateQueryKey = (templateId: string) => [
  `/api/v1/freight/templates/${templateId}`,
];

export type GetFreightNegotiationContractTemplateQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFreightNegotiationContractTemplate>>
>;
export type GetFreightNegotiationContractTemplateQueryError = AxiosError<void>;

export const useGetFreightNegotiationContractTemplate = <
  TData = Awaited<ReturnType<typeof getFreightNegotiationContractTemplate>>,
  TError = AxiosError<void>
>(
  templateId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getFreightNegotiationContractTemplate>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFreightNegotiationContractTemplateQueryKey(templateId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFreightNegotiationContractTemplate>>> = ({ signal }) =>
    getFreightNegotiationContractTemplate(templateId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getFreightNegotiationContractTemplate>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!templateId, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const deleteFreightNegotiationContractTemplate = (
  templateId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/v1/freight/templates/${templateId}`, options);
};

export type DeleteFreightNegotiationContractTemplateMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteFreightNegotiationContractTemplate>>
>;

export type DeleteFreightNegotiationContractTemplateMutationError = AxiosError<unknown>;

export const useDeleteFreightNegotiationContractTemplate = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFreightNegotiationContractTemplate>>,
    TError,
    { templateId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteFreightNegotiationContractTemplate>>,
    { templateId: string }
  > = (props) => {
    const { templateId } = props ?? {};

    return deleteFreightNegotiationContractTemplate(templateId, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteFreightNegotiationContractTemplate>>,
    TError,
    { templateId: string },
    TContext
  >(mutationFn, mutationOptions);
};

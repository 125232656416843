import { Grid, Group } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Currency, CargoTransportation } from 'api/generated/model';

import { Text } from '@/components';
import { useRecapFormContext } from '@/views/recap-form';
import { formatCurrency } from '@/common/format-currency';
import { getFixedNumberProps } from '@/common/get-fixed-number-props';

import { Select, NumberInput } from './RecapFormElements';

export const PricingFormGroup = () => {
  const { t, i18n } = useTranslation('recapForm');
  const { form, getInputProps, selectedIncoterm, offeredTransport, isIncotermFreezed, setSelectedIncoterm } =
    useRecapFormContext();

  const { price, currency } = form.values.price;
  const { quantity } = form.values.product.cargo;

  const totalPrice = price * quantity;
  const isCif = selectedIncoterm === CargoTransportation.CIF;

  return (
    <>
      <Grid gutter={8} mb={12}>
        <Grid.Col span={12} md={4}>
          <Select
            value={selectedIncoterm}
            disabled={isIncotermFreezed}
            label={t('labels.incoterms')}
            data={Object.values(CargoTransportation)}
            highlighted={selectedIncoterm !== offeredTransport}
            onChange={(value: CargoTransportation) => setSelectedIncoterm(value)}
            description={
              <>
                <Text color="gray.9">{t('labels.incotermsText', { incoterms: selectedIncoterm })}</Text>
                {selectedIncoterm !== offeredTransport && (
                  <Text weight={500} color="gray.7">
                    <RiErrorWarningLine style={{ transform: 'translateY(2px)' }} />{' '}
                    {t('formSentences.buyerRequestedIncoterm', { incoterm: offeredTransport })}
                  </Text>
                )}
                {isCif && <Text color="gray.9">{t('labels.insuranceText')}</Text>}
              </>
            }
          />
        </Grid.Col>
        <Grid.Col span={12} md={8} />
        <Grid.Col span={8} md={4}>
          <NumberInput
            {...getFixedNumberProps(2)}
            {...getInputProps('price.price')}
            min={0}
            key={selectedIncoterm}
            data-testid={'recapPrice'}
            withAsterisk={true}
            placeholder={t('placeholders.price')}
            label={t('labels.price', { offeredTransport: selectedIncoterm })}
          />
        </Grid.Col>
        <Grid.Col span={4} md={2}>
          <Select
            {...getInputProps('price.currency')}
            defaultValue="USD"
            withAsterisk={true}
            label={t('labels.currency')}
            data-testid={'recapCurrency'}
            data={Object.values(Currency).sort()}
          />
        </Grid.Col>
      </Grid>
      {price && quantity && (
        <Group spacing={16}>
          <Text size="XL" weight={600}>
            {t('labels.total')}
          </Text>
          <Text size="XL" weight={600}>
            {formatCurrency(totalPrice, currency, i18n.language, 2, 2)}
          </Text>
        </Group>
      )}
    </>
  );
};

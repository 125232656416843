/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type PriceType = (typeof PriceType)[keyof typeof PriceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PriceType = {
  FLAT: 'FLAT',
  VARIABLE: 'VARIABLE',
  ON_REQUEST: 'ON_REQUEST',
} as const;

import { createStyles, Flex, Select, Stack } from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import { useTranslation } from 'next-i18next';

import { useContainedLabelStyles } from '@/common/use-contained-label-styles';
import { Text } from '@/components';

import { SELECTS } from '../data';
import { useMyCargosFiltersForm } from './state/use-my-cargos-filters-form';

const useStyles = createStyles((theme) => ({
  flex: {
    paddingTop: 12,
    flexWrap: 'nowrap',
    gap: 24,

    [theme.fn.smallerThan('md')]: {
      flexDirection: 'column',
    },
  },
}));

export interface MyCargosFiltersProps {
  className?: string;
}

export function MyCargosFilters(props: MyCargosFiltersProps) {
  const { className } = props;
  const { classes } = useStyles();
  const { t } = useTranslation('myCargos');
  const { classes: containedClasses } = useContainedLabelStyles();
  const { form } = useMyCargosFiltersForm();
  const data = [
    { label: 'Item 1', value: 'Item 1' },
    { label: 'Item 2', value: 'Item 2' },
  ];

  return (
    <Stack className={className} spacing={8}>
      <Text>{t('labels.filterBy')}</Text>
      <form>
        <Flex className={classes.flex}>
          {SELECTS.map((translationKey) => {
            if (translationKey === 'eta') {
              return (
                <DateRangePicker
                  classNames={containedClasses}
                  label={t(`labels.${translationKey}`)}
                  placeholder={t(`placeholders.${translationKey}`)}
                  minDate={new Date()}
                  key={translationKey}
                  {...form.getInputProps(translationKey)}
                />
              );
            }
            return (
              <Select
                classNames={containedClasses}
                key={translationKey}
                label={t(`labels.${translationKey}`)}
                placeholder={t(`placeholders.${translationKey}`)}
                data={data}
                {...form.getInputProps(translationKey)}
              />
            );
          })}
        </Flex>
      </form>
    </Stack>
  );
}

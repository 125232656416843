import { useCallback } from 'react';
import { Button, createStyles, Group, Highlight, Loader, Stack, Tabs } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CellContext, ColumnDef, ColumnDefTemplate, createColumnHelper } from '@tanstack/react-table';
import {
  useGetCargoNegotiationHistory,
  useGetCargoNegotiationHistoryStep,
} from 'api/generated/cargo-negotiation/cargo-negotiation';
import {
  CargoNegotiationExpiryExtendRequestStatus,
  CargoNegotiationStepDTO,
  CargoTransportation,
  ChatType,
  CompanyRole,
  NegotiationStatus,
} from 'api/generated/model';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import { createEnumParam, useQueryParam } from 'use-query-params';

import {
  isActionStatus,
  isCancelAvailable,
  isCancelNegotiationStatus,
  isFinalStatus,
  isIgnoredNegotiationStatus,
  isRfqStatus,
  isTimerVisible,
} from '@/common/negotiation-status';
import { routes } from '@/common/routes';
import { DateCell, ENABLE_SENDBIRD, ExpireBadgeWithButton, Table, Title } from '@/components';
import { useStatusNegotiationModal } from '@/views/cargo-cancelation/state/CancelModalContext';
import { SendbirdChat } from '@/views/chat/SendbirdChat';
import { NegotiationRecapModal } from '@/views/recap-form/components/NegotiationRecapModal';
import { CancelActions, PreviewActions, ReviewFinalStepActions } from '@/views/recap-form/components/RecapPreviewModal';
import { useHasPermission } from '@/core';

import { useGetChatGroup } from '../../../api/generated/chat/chat';
import { Avatar } from '../../../components/avatar/Avatar';
import { NegotiationDetailsTab, OfferType } from '../types';
import { NegotiationBadge } from './NegotiationBadge';
import { NegotiationButton } from './NegotiationButton';
import { OfferDetailsModal } from './OfferDetailsModal';

const useStyles = createStyles((theme) => ({
  group: {
    [theme.fn.smallerThan('xs')]: {
      width: 'clamp(300px, 100%, 400px)',
      '& > *': {
        flex: '1 1 0px',
        padding: '4px 6px',
      },
    },
  },
  chatPanel: {
    overflow: 'scroll',
  },
  companyLink: {
    cursor: 'pointer',
    h2: {
      cursor: 'pointer',
    },
  },
  tabsRoot: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
}));

type OnNegotiationActionClick = (negotiationId: string) => void;

const NegotiationStatusBadgeCell: ColumnDefTemplate<CellContext<CargoNegotiationStepDTO, NegotiationStatus>> = ({
  getValue,
}) => {
  return <NegotiationBadge status={getValue()} hasDraft={false} />;
};

interface ActionsCellProps extends CellContext<CargoNegotiationStepDTO, string> {
  onActionClick: (row: CargoNegotiationStepDTO) => void;
}

const ActionsCell: ColumnDefTemplate<ActionsCellProps> = (props) => {
  const { t } = useTranslation('myCargos');
  const { onActionClick, row } = props;

  return (
    isCancelNegotiationStatus(row.original.status) || (
      <Button data-testid={'actionButton'} onClick={() => onActionClick(row.original)}>
        {t('buttons.seeDetails')}
      </Button>
    )
  );
};

const columnHelper = createColumnHelper<CargoNegotiationStepDTO>();

function useColumns(onActionClick: (row: CargoNegotiationStepDTO) => void) {
  const { t } = useTranslation('myCargos');
  const columns: ColumnDef<CargoNegotiationStepDTO>[] = useMemo(
    () => [
      columnHelper.accessor('status', {
        header: t('table.headers.status'),
        enableSorting: true,
        cell: NegotiationStatusBadgeCell,
      }),
      columnHelper.accessor('createdAt', {
        header: t('table.headers.date'),
        enableSorting: true,
        cell: DateCell,
      }),
      columnHelper.accessor('id', {
        header: t('table.headers.actions'),
        enableSorting: false,
        cell: (props) => <ActionsCell {...props} onActionClick={onActionClick} />,
      }),
    ],
    [t, onActionClick]
  );

  return columns;
}

interface NegotiationDetailsOptions {
  hideChat?: boolean;
  hideActions?: boolean;
}

interface NegotiationDetailsProps {
  negotiationId: string;
  lookingFor: CargoTransportation;
  offerType: OfferType;
  onCancelNegotiationsClick?: OnNegotiationActionClick;
  onRejectNegotiationsClick?: OnNegotiationActionClick;
  onExtendNegotiationsClick?: OnNegotiationActionClick;
  options?: NegotiationDetailsOptions;
}

export function NegotiationDetails(props: NegotiationDetailsProps) {
  const {
    negotiationId,
    lookingFor,
    offerType,
    onCancelNegotiationsClick = () => null,
    onRejectNegotiationsClick = () => null,
    onExtendNegotiationsClick = () => null,
    options = {},
  } = props;
  const { t } = useTranslation('myCargos');
  const { classes } = useStyles();
  const router = useRouter();
  const [selectedRow, setSelectedRow] = useState<CargoNegotiationStepDTO>();

  const { data, isLoading } = useGetCargoNegotiationHistory(negotiationId, {
    query: { select: (response) => response.data, staleTime: 0 },
  });
  const { data: SendbirdChannelId, isLoading: chatIsLoading } = useGetChatGroup(
    ChatType.CARGO_NEGOTIATION,
    negotiationId,
    {
      query: { select: (response) => response.data.id, staleTime: 0 },
    }
  );

  const [activeTab, setActiveTab] = useQueryParam('activeDetailsTab', {
    ...createEnumParam(Object.values(NegotiationDetailsTab)),
    default: NegotiationDetailsTab.HISTORY,
  });

  const otherCompany =
    offerType === 'request'
      ? { name: data?.sellerCompanyName, id: data?.sellerCompanyId, logo: data?.sellerCompanyLogo }
      : { name: data?.buyerCompanyName, id: data?.buyerCompanyId, logo: data?.buyerCompanyLogo };

  const companyData = {
    logo: otherCompany.logo,
    name: otherCompany.name,
  };

  const negotiationHistoryStatus = data?.negotiationHistory[0]?.status;
  const [isRfq, setIsRfq] = useState<boolean>(isRfqStatus(negotiationHistoryStatus));

  const [opened, { toggle }] = useDisclosure(false);
  const onActionClick = useCallback(
    (row: CargoNegotiationStepDTO) => {
      setIsRfq(isRfqStatus(row.status));
      toggle();
      setSelectedRow(row);
    },
    [toggle]
  );

  const columns = useColumns(onActionClick);

  const historyStep = useGetCargoNegotiationHistoryStep(negotiationId, selectedRow?.id, {
    query: { select: (response) => response.data },
  });

  const onCompanyClick = () => router.push(routes.CompanyProfile(otherCompany.id));

  const { toggleAcceptModal } = useStatusNegotiationModal();

  const { hasAllowedCompanyRoles } = useHasPermission();
  const canFinalizeNegotiation = hasAllowedCompanyRoles(CompanyRole.ALLOWED_TO_FINALIZE_NEGOTIATION);
  const isExpiryExtensionRequested =
    data?.expiryExtendRequest?.at(-1)?.status === CargoNegotiationExpiryExtendRequestStatus.REQUESTED;
  const isFirst = data?.negotiationHistory[0]?.id === historyStep?.data?.id;
  const isFinalStep = isFinalStatus(negotiationHistoryStatus);
  const isButtonAvailable = !isIgnoredNegotiationStatus(negotiationHistoryStatus);
  const isActionToBeTaken = isActionStatus(negotiationHistoryStatus);

  let actions: JSX.Element | undefined = undefined;
  if (isFirst && isButtonAvailable) {
    actions = (
      <>
        {isActionToBeTaken && isFinalStep && (
          <ReviewFinalStepActions
            onAccept={() => {
              toggle();
              toggleAcceptModal(negotiationId);
            }}
          />
        )}
        {isActionToBeTaken && !isFinalStep && (
          <PreviewActions
            onAccept={() => {
              toggle();
              toggleAcceptModal(negotiationId);
            }}
            onReject={() => {
              toggle();
              onCancelNegotiationsClick(negotiationId);
            }}
            negotiationId={negotiationId}
          />
        )}
        {!isActionToBeTaken && (
          <CancelActions
            onCancel={() => {
              toggle();
              onRejectNegotiationsClick(negotiationId);
            }}
          />
        )}
      </>
    );
  }
  return (
    <Stack spacing={24} sx={{ height: '100%', flex: 1 }}>
      <Group position="apart" spacing={8}>
        <Group className={classes.companyLink} onClick={onCompanyClick}>
          <Avatar src={companyData.logo} alt="Company logo" size={40} radius="sm" />
          <Title order={2} size="M" color="deepBlue.6" weight={600}>
            {companyData.name}
          </Title>
        </Group>
        {!isLoading && data && <NegotiationBadge status={negotiationHistoryStatus} hasDraft={data.hasDraft} />}
      </Group>
      <Group position="apart">
        <Highlight
          highlight={lookingFor}
          color="gray.7"
          highlightStyles={{ backgroundColor: 'transparent', fontWeight: 600 }}
        >
          {t('negotiation.cargoTransportation', { cargoTransportation: lookingFor })}
        </Highlight>
        {data?.negotiationHistory[0]?.expiresAt && isTimerVisible(data?.negotiationHistory[0].status) && (
          <ExpireBadgeWithButton
            expiresAt={data?.negotiationHistory[0]?.expiresAt}
            isSeller={offerType === 'offer'}
            onClick={() => onExtendNegotiationsClick(negotiationId)}
            isRequested={isExpiryExtensionRequested}
          />
        )}
      </Group>
      {!options.hideActions && (
        <Group className={classes.group} spacing={8} data-testid="negotiation-actions">
          {isButtonAvailable && negotiationHistoryStatus && (
            <NegotiationButton
              size="md"
              variant="filled"
              status={negotiationHistoryStatus}
              onDetailsClick={() => {
                setSelectedRow(data.negotiationHistory[0]);
                toggle();
              }}
            />
          )}
          {canFinalizeNegotiation && isCancelAvailable(negotiationHistoryStatus) && (
            <Button variant="outline" size="md" onClick={() => onCancelNegotiationsClick(negotiationId)}>
              {t('table.menu.cancelNegotiations')}
            </Button>
          )}
        </Group>
      )}
      <Tabs
        value={activeTab}
        onTabChange={(tab: NegotiationDetailsTab) => setActiveTab(tab)}
        classNames={{ panel: classes.chatPanel, root: classes.tabsRoot }}
        keepMounted={false}
      >
        {!options.hideChat && (
          <Tabs.List>
            <Tabs.Tab value={NegotiationDetailsTab.HISTORY}>{t('negotiation.negotiationHistory')}</Tabs.Tab>
            <Tabs.Tab value={NegotiationDetailsTab.CHAT}>{t('negotiation.chat')}</Tabs.Tab>
          </Tabs.List>
        )}
        <Tabs.Panel value={NegotiationDetailsTab.HISTORY}>
          <Table.Card>
            <Table
              withBorder={options.hideChat}
              data={data?.negotiationHistory || []}
              columns={columns}
              isLoading={isLoading}
            />
          </Table.Card>
        </Tabs.Panel>
        {ENABLE_SENDBIRD && (
          <Tabs.Panel
            value={NegotiationDetailsTab.CHAT}
            sx={{
              flex: 1,
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'auto',
            }}
          >
            {chatIsLoading && <Loader size={48} sx={{ transform: 'translateY(-28px)' }} />}
            {SendbirdChannelId && (
              <SendbirdChat
                otherCompanyName={otherCompany.name}
                channelURL={SendbirdChannelId}
                sx={{ width: '100%', height: '100%', position: 'absolute' }}
              />
            )}
          </Tabs.Panel>
        )}
      </Tabs>
      {isRfq ? (
        <OfferDetailsModal
          negotiationId={negotiationId}
          opened={opened}
          toggle={toggle}
          isHistoryStep={data?.negotiationHistory.length > 1}
        />
      ) : (
        <NegotiationRecapModal
          negotiationId={negotiationId}
          stepId={selectedRow?.id}
          opened={opened}
          toggle={toggle}
          actions={actions}
          offerType={offerType}
          onExtendNegotiationsClick={onExtendNegotiationsClick}
        />
      )}
    </Stack>
  );
}

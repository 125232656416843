/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';

import type { ApiErrorResponse, CreateCargoDraftDTO, CargoDraft } from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const postDraftNegotiation = (
  createCargoDraftDTO: CreateCargoDraftDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/v1/negotiations/draft`, createCargoDraftDTO, options);
};

export type PostDraftNegotiationMutationResult = NonNullable<Awaited<ReturnType<typeof postDraftNegotiation>>>;
export type PostDraftNegotiationMutationBody = CreateCargoDraftDTO;
export type PostDraftNegotiationMutationError = AxiosError<ApiErrorResponse>;

export const usePostDraftNegotiation = <TError = AxiosError<ApiErrorResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postDraftNegotiation>>,
    TError,
    { data: CreateCargoDraftDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postDraftNegotiation>>,
    { data: CreateCargoDraftDTO }
  > = (props) => {
    const { data } = props ?? {};

    return postDraftNegotiation(data, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof postDraftNegotiation>>, TError, { data: CreateCargoDraftDTO }, TContext>(
    mutationFn,
    mutationOptions
  );
};
export const getDraftNegotiation = (
  negotiationId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CargoDraft>> => {
  return axios.get(`/api/v1/negotiations/${negotiationId}/draft`, options);
};

export const getGetDraftNegotiationQueryKey = (negotiationId: string) => [
  `/api/v1/negotiations/${negotiationId}/draft`,
];

export type GetDraftNegotiationQueryResult = NonNullable<Awaited<ReturnType<typeof getDraftNegotiation>>>;
export type GetDraftNegotiationQueryError = AxiosError<void>;

export const useGetDraftNegotiation = <
  TData = Awaited<ReturnType<typeof getDraftNegotiation>>,
  TError = AxiosError<void>
>(
  negotiationId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getDraftNegotiation>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDraftNegotiationQueryKey(negotiationId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDraftNegotiation>>> = ({ signal }) =>
    getDraftNegotiation(negotiationId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getDraftNegotiation>>, TError, TData>(queryKey, queryFn, {
    enabled: !!negotiationId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

import { ReactNode } from 'react';
import { createStyles, Indicator, IndicatorProps } from '@mantine/core';

const useStyles = createStyles(() => ({
  common: {
    position: 'static',
    transform: 'none',
  },
}));

interface StaticIndicatorProps extends Omit<IndicatorProps, 'position'> {
  children: ReactNode;
}

export function StaticIndicator({ classNames, ...restProps }: StaticIndicatorProps) {
  const { classes } = useStyles();

  return <Indicator classNames={{ ...classes, ...classNames }} {...restProps} />;
}

import { Button, ButtonProps, createPolymorphicComponent } from '@mantine/core';
import { forwardRef } from 'react';

const _SmallButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { children, ...rest } = props;

  return (
    <Button component="button" ref={ref} size="sm" variant="outline" px={8} py={1} h="auto" fz={14} lh="22px" {...rest}>
      {children}
    </Button>
  );
});

_SmallButton.displayName = 'SmallButton';

export const SmallButton = createPolymorphicComponent<'button', ButtonProps>(_SmallButton);

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type Jurisdiction = (typeof Jurisdiction)[keyof typeof Jurisdiction];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Jurisdiction = {
  ENGLISH_JURISDICTION: 'ENGLISH_JURISDICTION',
  OTHER: 'OTHER',
} as const;

import { Text } from '@/components';

interface ToleranceLabelProps {
  description: string;
  minRange: number | string;
  maxRange: number | string;
}

export function ToleranceLabel(props: ToleranceLabelProps) {
  const { description, minRange, maxRange } = props;

  return (
    <Text size="S" color="gray.8" weight={500}>
      {description}
      <Text color="background.2" weight={400} size="S" span pl={2}>
        (+{maxRange}% / -{minRange}%)
      </Text>
    </Text>
  );
}

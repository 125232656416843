import { useTranslation } from 'next-i18next';
import { Button, Group, Stack } from '@mantine/core';

import { Text } from '@/components';

import { StepFunction } from '../types';

export interface DiscontinueNegotiationsProps {
  onBackClick: StepFunction;
  onNextClick: StepFunction;
}

export function DiscontinueNegotiations(props: DiscontinueNegotiationsProps) {
  const { onBackClick, onNextClick } = props;
  const { t } = useTranslation('myCargos');

  return (
    <Stack>
      <Text sx={{ color: '#535A60' }} align="center" size="S">
        {t('modals.cancel.descriptions.cannotUndo')}
      </Text>
      <Group grow>
        <Button onClick={() => onBackClick()} variant="outline">
          {t('modals.cancel.buttons.close')}
        </Button>
        <Button onClick={() => onNextClick()}>{t('modals.cancel.buttons.discontinue')}</Button>
      </Group>
    </Stack>
  );
}

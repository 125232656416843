/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type WorkflowCompanyStatus = (typeof WorkflowCompanyStatus)[keyof typeof WorkflowCompanyStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkflowCompanyStatus = {
  SHORTLISTED: 'SHORTLISTED',
  PASSIVE: 'PASSIVE',
  ONBOARDING: 'ONBOARDING',
  verified: 'verified',
  unverified: 'unverified',
  KYC_VERIFIED: 'KYC_VERIFIED',
  PRIVATE: 'PRIVATE',
  UNKNOWN: 'UNKNOWN',
  SERVICE_PROVIDER: 'SERVICE_PROVIDER',
} as const;

import { ButtonProps } from '@mantine/core';

import { ThemeComponent } from './types';

export const Button: ThemeComponent<ButtonProps> = {
  styles: (theme, params) => ({
    root: {
      ...(params.size === 'sm' && {
        height: 32,
        fontSize: 16,
        lineHeight: '24px',
        padding: '4px 12px',
        fontWeight: 600,
      }),

      ...(params.size === 'md' && {
        height: 40,

        [theme.fn.smallerThan('sm')]: {
          height: 32,
          fontSize: 16,
          lineHeight: '24px',
          padding: '4px 12px',
          fontWeight: 600,
        },
      }),

      ...(params.variant === 'subtle' && {
        '&:hover': {
          backgroundColor: 'unset',
        },
      }),
    },
    icon: {
      transform: 'translateY(1px)',
    },
  }),
};

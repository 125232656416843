import { z } from 'zod';

export type FreightRateTerms =
  | 'SSHINC'
  | 'FSHINC'
  | 'SSHEX_EIU'
  | 'SSHEX_UU'
  | 'SatNoonSHEX_EIU'
  | 'SatNoonSHEX_UU'
  | 'SHEX_EIU'
  | 'SHEX_UU';

const freightSchema = z.object({
  safePortNumber: z.number().optional(),
  safeBerthNumber: z.number().optional(),
  berthConditions: z.enum(['AAAA', 'AA', 'NAABSA']).optional(),
  freightRate: z.number(),
  freightRateTerms: z.enum([
    'SSHINC',
    'FSHINC',
    'SSHEX_EIU',
    'SSHEX_UU',
    'SatNoonSHEX_EIU',
    'SatNoonSHEX_UU',
    'SHEX_EIU',
    'SHEX_UU',
    'SHINC',
  ]),
  freightPortPeriodTerms: z.enum(['PWWD', 'PER_WORKING_HOUR']).optional(),
  freightNORTendering: z
    .enum([
      'atdn + SSHINC',
      'FSHINC',
      'SSHEX EIU',
      'SSHEX UU',
      'SatNoonSHEX EIU',
      'SatNoonSHEX UU',
      'SHEX EIU, SHEX UU',
    ])
    .optional(),
  freightTurnTime: z.number(),
  freightTurnTimeTerms: z.enum(['EVEN_IF_USED', 'UNLESS_USED']).optional(),
  freightExclusionFormLaytime: z
    .array(
      z.enum([
        'INITIAL_DRAFT_SURVEY',
        'INTERMEDIARY_DRAFT_SURVEY',
        'FINAL_DRAFT_SURVEY',
        'FIRST_SHIFTING',
        'INTERMEDIARY_SHIFTING',
        'OPENING_OF_HATCHES',
        'WARPING',
        'SWELL',
        'CUSTOM_CLEARANCE',
        'OTHERS',
      ])
    )
    .optional(),
  freightExclusionFormLaytimeOther: z.string().optional(),
  freightCargoPDA: z.enum(['AT_OWNERS_COST', 'AT_CHARTERERS_COST']).optional(),
  freightVesselPDA: z.enum(['AT_OWNERS_COST', 'AT_CHARTERERS_COST']).optional(),
  freightPDAOther: z.string().optional(),
});

export const freightRecapFormSchema = z.object({
  price: z.object({
    price: z.number(),
    currency: z.enum(['EUR', 'USD']),
    comments: z.string().optional(),
  }),
  product: z.object({
    name: z.string(),
    grade: z.string(),
    packaging: z.string(),
    quantity: z.number(),
    minTolerance: z.number(),
    maxTolerance: z.number(),
    option: z.enum(['IN_OWNERS_OPTION', 'IN_CHARTERERS_OPTION']),
    loadingPort: z.string(),
    laycan: z.tuple([z.string().datetime(), z.string().datetime()]),
    loadingPortGuaranteedDraft: z.number(),
    loadingPortGuaranteedLOA: z.number(),
    loadingPortGuaranteedBeam: z.number(),
    dischargingPort: z.string(),
    dischargingPortGuaranteedDraft: z.number(),
    dischargingPortGuaranteedLOA: z.number(),
    dischargingPortGuaranteedBeam: z.number(),
  }),
  vessel: z.object({
    type: z.enum(['TBN', 'NOMINATED_VESSEL']),
    maxAge: z.number().optional(),
    nominationDays: z.number().optional(),
    IMO: z.string().optional(),
    ownerName: z.string(),
    ownerAddress: z.string().optional(),
    ownerVat: z.string().optional(),
    gears: z.enum(['GEARLESS', 'GEARED']).optional(),
    cranesNumber: z.number().optional(),
    cranesCapacity: z.number().optional(),
    grabs: z.enum(['GRABLESS', 'GRABBER']).optional(),
    grabsNumber: z.number().optional(),
    grabCapacity: z.number().optional(),
  }),
  loadingConditions: freightSchema,
  dischargingConditions: freightSchema,
  nomination: z.object({
    agentAtLoadingPort: z.string().optional(),
    agentAtLoadingPortConditions: z.enum(['AT_OWNERS_COST', 'AT_CHARTERERS_COST']).optional(),
    agentAtDischargingPort: z.string().optional(),
    agentAtDischargingPortConditions: z.enum(['AT_OWNERS_COST', 'AT_CHARTERERS_COST']).optional(),
  }),
  payment: z.object({
    laytimeCalculation: z.array(z.enum(['BILATERAL_AGREEMENT', 'TRUSTED_PARTY'])).optional(),
    rate: z.number().optional(),
    rateType: z.enum(['MAX', 'FLAT']).optional(),
    event: z.enum(['TO_BE_ADVISED_WITH_VESSEL_NOMINATION', 'FIXED_AT_RECAP']).optional(),
    type: z.enum(['OPEN_ACCOUNT', 'PROVISION_TO_OWNERS', 'PROVISION_TO_TRUSTED_PARTY']).optional(),
    openAccountMaxDays: z.number().optional(),
    conditions: z.object({
      TTPrepayment: z
        .object({
          percentagePrepayement: z.number().optional(),
          daysPrepayment: z.number().optional(),
          eventPrepayment: z
            .enum(['AFTER_VESSEL_NOMINATION', 'BEFORE_FIRST_DAY_OF_LAY_CAN', 'BEFORE_START_OF_LOADING'])
            .optional(),
        })
        .optional(),
      TTAfterLoading: z
        .object({
          percentageAfterLoading: z.number().optional(),
          daysAfterLoading: z.number().optional(),
        })
        .optional(),
      TTBeforeBreakingBulk: z
        .object({
          daysBeforeBreakingBulk: z.number().optional(),
          percentageBeforeBreakingBulk: z.number().optional(),
        })
        .optional(),
      TTOpenTerms: z
        .object({
          percentageOpenTerms: z.number().optional(),
          daysOpenTerms: z.number().optional(),
          EventOpenTerms: z
            .enum(['AFTER_BILL_OF_LADING_DATE', 'BEFORE_START_OF_DISCHARGING', 'AFTER_DISCHARGING_IS_COMPLETED'])
            .optional(),
        })
        .optional(),
      other: z.string().optional(),
    }),
  }),
  comments: z.string().optional(),
});

export type FreightRecapForm = z.infer<typeof freightRecapFormSchema>;

export const freightRecapFormPartialSchema = freightRecapFormSchema.deepPartial();

export type FreightRecapFormPartial = z.infer<typeof freightRecapFormPartialSchema>;

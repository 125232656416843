/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type NegotiationSide = (typeof NegotiationSide)[keyof typeof NegotiationSide];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NegotiationSide = {
  BUYER: 'BUYER',
  SELLER: 'SELLER',
} as const;

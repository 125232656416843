import { useState, useEffect } from 'react';
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import SessionHandler from '@sendbird/uikit-react/handlers/SessionHandler';

import { getChatTokenForUser } from '../../api/generated/chat/chat';

const SENDBIRD_APP_ID = process.env.NEXT_PUBLIC_CHAT_SENDBIRD_APP_ID;
export const ENABLE_SENDBIRD = process.env.NEXT_PUBLIC_ENABLE_SENDBIRD === 'true';
const TEST_DEPLOYMENT_PROD = true;

export function ChatSessionWrapper({ children }) {
  const [session, setSession] = useState({ userId: '', token: '' });

  const acquireToken = async () => {
    const { data } = await getChatTokenForUser();
    const { userId, token } = data;
    setSession({ userId, token });
  };

  const configureSession = () => {
    const sessionHandler = new SessionHandler();
    sessionHandler.onSessionTokenRequired = acquireToken;
    sessionHandler.onSessionExpired = acquireToken;
    return sessionHandler;
  };

  const colorSet = {
    '--sendbird-light-primary-500': '#002744',
    '--sendbird-light-primary-400': '#004071',
    '--sendbird-light-primary-300': '#005A9E',
    '--sendbird-light-primary-200': '#03ACF7',
    '--sendbird-light-primary-100': '#B3E6FD',
  };

  useEffect(() => {
    if (!session.token && ENABLE_SENDBIRD) {
      acquireToken();
    }
  }, [session.token]);

  if (!ENABLE_SENDBIRD) {
    return children;
  }

  return (
    <SendbirdProvider
      appId={SENDBIRD_APP_ID}
      userId={session.userId}
      accessToken={session.token}
      configureSession={session.token ? configureSession : undefined}
      colorSet={colorSet}
    >
      {children}
    </SendbirdProvider>
  );
}

import { Gears, Grabs, VesselType } from '../../enums';
import { VesselInformationSchema } from './vessel-information';
import { VesselTypeNominatedSchema } from './vessel-type-nominated';
import { VesselTypeTBNSchema } from './vessel-type-tbn';

export const VESSEL_TYPE_NOMINATED_INITIAL_VALUES: Partial<VesselTypeNominatedSchema> = {
  imoNumber: '',
};

export const VESSEL_TYPE_TBN_INITIAL_VALUES: Partial<VesselTypeTBNSchema> = {
  maxAge: 0,
  days: 0,
};

export const VESSEL_INFORMATION_INITIAL_VALUES: Partial<VesselInformationSchema> = {
  type: null,
  vesselNominated: VESSEL_TYPE_NOMINATED_INITIAL_VALUES,
  vesselTBN: VESSEL_TYPE_TBN_INITIAL_VALUES,
  gears: Gears.GEARED,
  grabs: Grabs.GRABBED,
};

//Empty values
export const VESSEL_INFORMATION_EMPTY_VALUES = {
  type: null,
  vesselNominated: {
    maxAge: undefined,
    days: undefined,
  },
  vesselTBN: {
    imoNumber: '',
  },
  gears: null,
  cranesNumber: undefined,
  cranesCapacity: undefined,
  grabCapacity: undefined,
  grabs: null,
  grabsNumber: undefined,
};

import { NegotiationStatus } from 'api/generated/model';
import { Button, ButtonProps } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import React from 'react';

interface GetNegotiationButtonProps {
  label: string;
  variant: ButtonProps['variant'];
}

function getNegotiationButtonProps(status: NegotiationStatus): GetNegotiationButtonProps {
  switch (status) {
    case 'RFQ_SENT':
    case 'OFFER_SENT':
    case 'COUNTER_OFFER_SENT':
    case 'REVIEW_SENT':
    case 'DRAFT':
      return {
        label: 'seeDetails',
        variant: 'outline',
      };
    case 'RFQ_RECEIVED':
    case 'OFFER_RECEIVED':
    case 'COUNTER_OFFER_RECEIVED':
      return {
        label: 'seeDetails',
        variant: 'filled',
      };
    case 'REVIEW_RECEIVED':
      return {
        label: 'reviewFinal',
        variant: 'filled',
      };
    case 'EXPIRED':
    case 'REJECTED':
    case 'CANCELED':
    case 'SELLER_CANCELED':
    case 'BUYER_CANCELED':
    case 'INVALID_BUYER':
    case 'INVALID_SELLER':
    case 'REJECTED_BUYER':
    case 'REJECTED_SELLER':
    case 'BUYER_ACCEPTED':
    case 'SELLER_ACCEPTED':
      return {
        label: 'seeHistory',
        variant: 'outline',
      };
    default:
      throw new Error(`Invalid negotiation status`);
  }
}

export interface NegotiationButtonProps extends ButtonProps {
  status: NegotiationStatus;
  onDetailsClick: () => void;
  onHistoryClick?: () => void;
}

export function NegotiationButton(props: NegotiationButtonProps) {
  const { status, onDetailsClick, onHistoryClick, ...mantineProps } = props;
  const { label, ...negotiationButtonProps } = getNegotiationButtonProps(status);
  const { t } = useTranslation('myCargos');

  return (
    <Button
      onClick={label === 'seeHistory' && onHistoryClick ? onHistoryClick : onDetailsClick}
      data-testid="negotiation-button"
      {...negotiationButtonProps}
      {...mantineProps}
    >
      {t(`buttons.${label}`)}
    </Button>
  );
}

import { Badge, BadgeProps } from '@mantine/core';
import {
  CargoStatus,
  CargoTenderContractStatus,
  CargoTenderStatus,
  ContractCargoDeclarationStatus,
} from 'api/generated/model';
import { useTranslation } from 'next-i18next';

import { BADGE_COLORS } from '@/views/dashboard/data';

export interface CargoStatusBadgeProps extends BadgeProps {
  value: CargoStatus | CargoTenderStatus | CargoTenderContractStatus | ContractCargoDeclarationStatus;
}

export function CargoStatusBadge(props: CargoStatusBadgeProps) {
  const { value, ...rest } = props;
  const { t } = useTranslation('common');

  return (
    <Badge
      sx={(theme) => ({
        [theme.fn.smallerThan('xs')]: {
          width: '100%',
        },
      })}
      color={BADGE_COLORS[value]}
      {...rest}
    >
      {t(`badges.${value}`)}
    </Badge>
  );
}

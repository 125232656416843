/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type FreightNegotiationStepStatusLabel =
  (typeof FreightNegotiationStepStatusLabel)[keyof typeof FreightNegotiationStepStatusLabel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FreightNegotiationStepStatusLabel = {
  RFQ_SENT: 'RFQ_SENT',
  RFQ_RECEIVED: 'RFQ_RECEIVED',
  OFFER_RECEIVED: 'OFFER_RECEIVED',
  OFFER_SENT: 'OFFER_SENT',
  COUNTER_OFFER_RECEIVED: 'COUNTER_OFFER_RECEIVED',
  COUNTER_OFFER_SENT: 'COUNTER_OFFER_SENT',
  REVIEW_RECEIVED: 'REVIEW_RECEIVED',
  REVIEW_SENT: 'REVIEW_SENT',
  CHARTERER_ACCEPTED: 'CHARTERER_ACCEPTED',
  TRADER_ACCEPTED: 'TRADER_ACCEPTED',
  CHARTERER_CANCELED: 'CHARTERER_CANCELED',
  TRADER_CANCELED: 'TRADER_CANCELED',
  INVALID_TRADER: 'INVALID_TRADER',
  INVALID_CHARTERER: 'INVALID_CHARTERER',
  EXPIRED: 'EXPIRED',
  CANCELED: 'CANCELED',
  DRAFT: 'DRAFT',
} as const;

import { Group, Stack } from '@mantine/core';
import { DateRangePicker, DateRangePickerValue } from '@mantine/dates';
import { useTranslation } from 'next-i18next';
import { RiCalendar2Line } from 'react-icons/ri';
import { CargoTransportation } from 'api/generated/model';
import { useGetStandardPlants, useGetStandardPorts } from 'api/generated/masterdata/masterdata';
import { useEffect, useMemo } from 'react';

import { Text, TwoColumnsLayout, SearchableSelector } from '@/components';
import { useRecapFormContext } from '@/views/recap-form';
import { getThousandSeparatedNumberProps } from '@/common/get-thousand-seperated-number';

import { getBasePercentageProps, NumberInput, Select } from './RecapFormElements';
import { ProductSpecificationWrapper } from './ProductSpecificationWrapper';

export interface ProductCargoFieldsProps {
  offeredTransportation: CargoTransportation;
}

export function ProductCargoFields({ offeredTransportation }: ProductCargoFieldsProps) {
  const { t } = useTranslation('recapForm');
  const { standardData, masterdata, form, getInputProps, sellerCompanyId } = useRecapFormContext();
  const laycanFormValue = form.values.product.cargo.laycan || [null, null];
  const { productName, originPlantCountry, productId, productQuality, originPlantId } = form.values.product.cargo;
  const laycanInputValue: DateRangePickerValue = [
    laycanFormValue[0] ? new Date(laycanFormValue[0]) : null,
    laycanFormValue[1] ? new Date(laycanFormValue[1]) : null,
  ];
  const isFob = offeredTransportation === CargoTransportation.FOB;

  const itemMaper = (item) => {
    return {
      value: item.id,
      label: item.name,
    };
  };
  const plantsSelectItems = useGetStandardPlants({
    query: { select: (res) => res.data.items },
  });

  const allPlants = useMemo(() => {
    return (plantsSelectItems.data || []).sort((a, b) => a.name.localeCompare(b.name)).map(itemMaper);
  }, [plantsSelectItems.data]);

  const portSelectItems = useGetStandardPorts({
    query: { select: (res) => res.data.items },
  });

  const allPorts = useMemo(() => {
    return (portSelectItems.data || []).sort((a, b) => a.name.localeCompare(b.name)).map(itemMaper);
  }, [portSelectItems.data]);

  useEffect(() => {
    // Check if the form doesn't already have a value set for this field to avoid overriding user input
    if (!form.values.product.cargo.toleranceOption) {
      form.setFieldValue('product.cargo.toleranceOption', 'IN_SELLERS_OPTION');
    }
  }, []); //

  return (
    <TwoColumnsLayout>
      <Stack spacing="md">
        <Select
          label={
            <>
              {t('labels.commodity')}: <span style={{ fontWeight: 400 }}>{productName}</span>
            </>
          }
          data={standardData.data.packagings}
          withAsterisk={true}
          horizontal
          grow={false}
          {...getInputProps('product.cargo.productPackaging')}
        />
        <Select
          label={t('labels.qualityGrade')}
          data={standardData.data.grades}
          withAsterisk={true}
          horizontal
          grow={false}
          {...getInputProps('product.cargo.productQuality')}
        />
        <Stack w={{ md: '50%' }}>
          <ProductSpecificationWrapper
            productId={productId}
            gradeName={productQuality}
            plantId={originPlantId}
            companyId={sellerCompanyId}
          />
          <NumberInput
            label={t('labels.quantity')}
            min={0}
            rightSection="mts"
            {...getThousandSeparatedNumberProps()}
            {...getInputProps('product.cargo.quantity')}
          />
        </Stack>
        <Group align="flex-end">
          <NumberInput
            label={t('labels.minTolerance')}
            min={0}
            w={{ md: 136 }}
            {...getBasePercentageProps()}
            {...getInputProps('product.cargo.minTolerance')}
          />
          <NumberInput
            label={t('labels.maxTolerance')}
            min={0}
            w={{ md: 136 }}
            {...getBasePercentageProps()}
            {...getInputProps('product.cargo.maxTolerance')}
          />
          <Select
            data={masterdata.toleranceOptions}
            defaultValue={'IN_SELLERS_OPTION'}
            {...getInputProps('product.cargo.toleranceOption')}
          />
        </Group>
      </Stack>
      <Stack>
        <>
          <Text>
            <span style={{ fontWeight: 500 }}>{t('labels.origin')}</span> {originPlantCountry}
          </Text>
          <SearchableSelector
            label={t('labels.originPlant')}
            placeholder={t('labels.selectPlaceholder')}
            withAsterisk={true}
            data={allPlants}
            {...getInputProps('product.cargo.originPlantId')}
            data-testid={'originPlant'}
          />

          <SearchableSelector
            label={t('labels.loadingPort')}
            placeholder={t('labels.selectPlaceholder')}
            withAsterisk={true}
            data={allPorts}
            {...getInputProps('product.cargo.loadingPortId')}
            data-testid={'loadingPort'}
          />
        </>
        {!isFob && (
          <>
            <SearchableSelector
              label="Destination plant"
              placeholder={t('labels.selectPlaceholder')}
              withAsterisk={true}
              data={allPlants}
              {...getInputProps('product.cargo.destinationPlantId')}
              data-testid={'destinationPlant'}
            />
            <SearchableSelector
              label={t('labels.dischargingPort')}
              placeholder={t('labels.selectPlaceholder')}
              withAsterisk={true}
              data={allPorts}
              {...getInputProps('product.cargo.dischargingPortId')}
              data-testid={'dischargingPort'}
            />
          </>
        )}
        <DateRangePicker
          icon={<RiCalendar2Line />}
          minDate={new Date()}
          label={t('labels.loadingLaycan')}
          size="md"
          value={laycanInputValue}
          onChange={(value) =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            getInputProps('product.cargo.laycan' as any).onChange([value[0]?.toISOString(), value[1]?.toISOString()])
          }
        />
      </Stack>
    </TwoColumnsLayout>
  );
}

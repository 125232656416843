import { createContext, useContext, useState } from 'react';
import { BooleanParam, createEnumParam, StringParam, useQueryParams } from 'use-query-params';

import { ModalType } from '../types';

type ToggleModalFunction = (negotiationId: string, modalType: ModalType) => void;
export interface IStatusNegotiationModal {
  isOpened: boolean;
  modalType: ModalType | null;
  negotiationId: string;
  toggleModal: ToggleModalFunction;
}

export const StatusNegotiationModalContext = createContext<IStatusNegotiationModal>({
  isOpened: false,
  modalType: null,
  negotiationId: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleModal: () => {},
});

export const StatusNegotiationProvider = ({ children }) => {
  const [query, setQuery] = useQueryParams({
    negotiationId: StringParam,
    showModal: BooleanParam,
    modalType: {
      ...createEnumParam(Object.values(ModalType)),
    },
  });
  const { negotiationId, showModal } = query;
  const [isOpened, setIsOpened] = useState(negotiationId !== '' && showModal);
  const toggleModal: ToggleModalFunction = (negotiationId, modalType) => {
    setIsOpened((prev) => {
      if (prev) {
        setQuery({ showModal: false, modalType: undefined });
        return false;
      }
      setQuery({ negotiationId, showModal: true, modalType });
      return true;
    });
  };

  const value: IStatusNegotiationModal = {
    isOpened,
    modalType: query.modalType,
    negotiationId,
    toggleModal,
  };

  return <StatusNegotiationModalContext.Provider value={value}>{children}</StatusNegotiationModalContext.Provider>;
};

export function useStatusNegotiationModal() {
  const { modalType, toggleModal, negotiationId } = useContext(StatusNegotiationModalContext);
  const isAcceptModalOpened = modalType === ModalType.ACCEPT;
  const isRequestExtensionModalOpened = modalType === ModalType.REQUEST_EXTENSION;
  const isExtendModalOpened = modalType === ModalType.EXTEND;
  const toggleAcceptModal = (negotiationId: string) => toggleModal(negotiationId, ModalType.ACCEPT);
  const toggleRequestExtension = (negotiationId: string) => toggleModal(negotiationId, ModalType.REQUEST_EXTENSION);
  const toggleExtend = (negotiationId: string) => toggleModal(negotiationId, ModalType.EXTEND);
  return {
    modalType,
    negotiationId,
    isAcceptModalOpened,
    isRequestExtensionModalOpened,
    isExtendModalOpened,
    toggleAcceptModal,
    toggleRequestExtension,
    toggleExtend,
  };
}

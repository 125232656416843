import countriesJSON from './countries.json';

interface PhonePrefixEntry {
  alpha2: string;
  name: (typeof countriesJSON)[0]['name'];
  phonePrefix: string;
}

function toPhonePrefixEntries(country: (typeof countriesJSON)[0]): PhonePrefixEntry[] {
  return country.phonePrefix.map((phonePrefix) => ({
    alpha2: country.alpha2,
    name: country.name,
    phonePrefix,
  }));
}

export function getCountriesPhonePrefixes(): PhonePrefixEntry[] {
  return countriesJSON.reduce((acc, country) => [...acc, ...toPhonePrefixEntries(country)], [] as PhonePrefixEntry[]);
}

export function getAllPhonePrefixes(): string[] {
  return countriesJSON.reduce((acc, country) => [...acc, ...country.phonePrefix], [] as string[]);
}

export function getCountryNameByAlpha2(alpha2: string) {
  return countriesJSON.find((country) => country.alpha2 === alpha2)?.name.en;
}

export function getAlpha2ByPhonePrefix(phonePrefix: string) {
  const alpha2 = countriesJSON.find((country) => country.phonePrefix.includes(phonePrefix))?.alpha2;
  return `${phonePrefix}_${alpha2}`;
}

export const countries = countriesJSON;

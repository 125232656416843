import { Button, createStyles, Flex, Group, Menu, Modal, ModalProps } from '@mantine/core';
import NextLink from 'next/link';
import { CargoNegotiationExpiryExtendRequestStatus, CompanyRole, NegotiationStatus } from 'api/generated/model';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';
import { RiFileAddLine, RiMore2Fill } from 'react-icons/ri';

import { routes } from '@/common/routes';
import { ExpireBadgeWithButton, Text } from '@/components';
import { OfferType } from '@/views/my-cargos';
import { isTimerVisible } from '@/common/negotiation-status';
import { useIsMobile } from '@/common/use-is-mobile';
import { useHasPermission } from '@/core';

const useStyles = createStyles((theme) => ({
  chat: {
    backgroundColor: theme.fn.primaryColor(),
  },
  header: {
    padding: 24,
    backgroundColor: theme.colors.gray[1],
    margin: 0,
    height: 72,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    padding: 0,
    overflow: 'hidden',
    flexGrow: 1,
  },
  inner: {
    overflowY: 'hidden',
  },
  root: {
    maxWidth: 814,
    marginLeft: 'auto',
    marginRight: 'auto',
    bottom: 24,
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
  },
  title: {
    width: '100%',
  },
}));

export interface PreviewActionsProps {
  negotiationId: string;
  onAccept: VoidFunction;
  onReject: VoidFunction;
}

export function PreviewActions(props: PreviewActionsProps) {
  const { negotiationId, onAccept, onReject } = props;
  const { t } = useTranslation('recapForm');
  const isMobile = useIsMobile();
  const { hasAllowedCompanyRoles } = useHasPermission();
  const canFinalizeNegotiation = hasAllowedCompanyRoles(CompanyRole.ALLOWED_TO_FINALIZE_NEGOTIATION);

  return (
    <>
      {isMobile ? (
        <Menu>
          <Menu.Target>
            <Button variant="outline" size="md">
              <RiMore2Fill />
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item icon={<RiFileAddLine />} href={routes.CounterofferRecapForm(negotiationId)} component={NextLink}>
              {t('preview.buttons.counteroffer')}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      ) : (
        <Group>
          <Button
            href={routes.CounterofferRecapForm(negotiationId)}
            data-testid={'sendCounteroffer'}
            component={NextLink}
            variant="subtle"
            leftIcon={<RiFileAddLine />}
          >
            {t('preview.buttons.counteroffer')}
          </Button>
        </Group>
      )}
      {canFinalizeNegotiation && (
        <Group>
          <Button onClick={onReject} data-testid={'reject'} variant="outline" size="md">
            {t('preview.buttons.reject')}
          </Button>
          <Button onClick={onAccept} data-testid={'accept'} size="md">
            {t('preview.buttons.accept')}
          </Button>
        </Group>
      )}
    </>
  );
}

export interface ReviewFinalStepActionsProps {
  onAccept: VoidFunction;
}

export function ReviewFinalStepActions(props: ReviewFinalStepActionsProps) {
  const { onAccept } = props;
  const { t } = useTranslation('recapForm');
  const { hasAllowedCompanyRoles } = useHasPermission();

  const canFinalizeNegotiation = hasAllowedCompanyRoles(CompanyRole.ALLOWED_TO_FINALIZE_NEGOTIATION);

  return canFinalizeNegotiation ? (
    <Button onClick={onAccept} size="md" ml={'auto'}>
      {t('preview.buttons.accept')}
    </Button>
  ) : null;
}

interface EditorActionsProps {
  onBackToEdit: VoidFunction;
  onSend: VoidFunction;
}

export function EditorActions(props: EditorActionsProps) {
  const { onBackToEdit, onSend } = props;
  const { t } = useTranslation('recapForm');

  return (
    <Group ml={'auto'}>
      <Button variant="outline" size="md" onClick={onBackToEdit}>
        {t('preview.buttons.backToEdit')}
      </Button>
      <Button size="md" onClick={onSend}>
        {t('buttons.send')}
      </Button>
    </Group>
  );
}

interface CancelActionInterface {
  onCancel: VoidFunction;
}

export function CancelActions(props: CancelActionInterface) {
  const { onCancel } = props;
  const { t } = useTranslation('recapForm');
  const { hasAllowedCompanyRoles } = useHasPermission();

  const canFinalizeNegotiation = hasAllowedCompanyRoles(CompanyRole.ALLOWED_TO_FINALIZE_NEGOTIATION);

  return canFinalizeNegotiation ? (
    <Button onClick={onCancel} data-testid={'cancel'} variant="outline">
      {t('preview.buttons.cancelNegotiations')}
    </Button>
  ) : null;
}

export function RecapPreviewDefaultTitle() {
  const { t } = useTranslation('recapForm');
  return <Text weight={600}>{t('preview.preview')}</Text>;
}

export interface RecapPreviewMostRecentTitleProps {
  expiresAt: string;
  offerType: OfferType;
  onExtendNegotiationsClick: VoidFunction;
  expiryExtendRequestStatus: CargoNegotiationExpiryExtendRequestStatus;
  status: NegotiationStatus;
}
export function RecapPreviewMostRecentTitle(props: RecapPreviewMostRecentTitleProps) {
  const { expiresAt, offerType, onExtendNegotiationsClick, expiryExtendRequestStatus, status } = props;
  const { t } = useTranslation(['recapForm', 'common', 'myCargos']);
  const isRequested = expiryExtendRequestStatus === CargoNegotiationExpiryExtendRequestStatus.REQUESTED;

  return (
    <Group position="apart">
      <Text weight={600}>{t('recapForm:preview.preview')}</Text>

      {isTimerVisible(status) && (
        <ExpireBadgeWithButton
          isSeller={offerType === 'offer'}
          expiresAt={expiresAt}
          isRequested={isRequested}
          onClick={onExtendNegotiationsClick}
        />
      )}
    </Group>
  );
}

export interface RecapPreviewModalProps extends ModalProps {
  opened: boolean;
  actions: ReactNode;
  title: ReactNode;
  recapForm?: ReactNode;
}

export function RecapPreviewModal(props: RecapPreviewModalProps) {
  const { opened, actions, title, recapForm, ...rest } = props;
  const { classes } = useStyles();

  return (
    <Modal
      opened={opened}
      padding={0}
      size="100%"
      classNames={{
        header: classes.header,
        body: classes.body,
        inner: classes.inner,
        root: classes.root,
        modal: classes.modal,
        title: classes.title,
      }}
      title={title}
      {...rest}
    >
      <>
        {recapForm}
        <Flex
          align="center"
          justify="space-between"
          py={20}
          px={24}
          data-testid="recap-preview-actions"
          sx={(theme) => ({ borderTop: `1px solid ${theme.colors.gray[2]}` })}
        >
          {actions}
        </Flex>
      </>
    </Modal>
  );
}

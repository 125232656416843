import { useMantineTheme, Anchor } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'next-i18next';

import { Text } from '@/components';

import { AddExternalRefModal } from '../../modals/AddExternalRefModal/AddExternalRefModal';

export const AddExternalRefIdButton = ({ workflowId }: { workflowId: string }) => {
  const theme = useMantineTheme();
  const { t } = useTranslation('myCargos');
  const [isModalOpen, { open, close }] = useDisclosure(false);

  const handleClick = (e) => {
    e.stopPropagation();
    open();
  };

  return (
    <>
      <Anchor onClick={handleClick}>
        <Text size="XS" color={theme.fn.primaryColor()} weight={900}>
          {t('labels.addExternalRef')}
        </Text>
      </Anchor>
      <AddExternalRefModal isOpen={isModalOpen} close={close} workflowId={workflowId} />
    </>
  );
};

import { Trans, useTranslation } from 'next-i18next';
import { CargoTransportation } from 'api/generated/model';
import { Flex, Grid, Stack, Group, Radio, Divider } from '@mantine/core';

import { useRecapFormContext } from '@/views/recap-form';
import { getFixedNumberProps } from '@/common/get-fixed-number-props';
import { Text, FormCardSection, TwoColumnsLayout } from '@/components';

import { ProductCargoFields } from './ProductCargoFields';
import {
  WeightLoadportFieldGroup,
  SamplingAnalysisFieldGroup,
  SamplingSurveyorNamesFieldGroup,
  WeightDischargeSurveyorsFieldGroup,
  SamplingSurveyorsAtDischargeFieldGroup,
} from './ProductInsertableFieldGroups';
import { Select, Checkbox, TextInput, NumberInput, CheckboxGroup, InsertableFieldGroup } from './RecapFormElements';

export const ProductFormGroup = () => {
  const { t } = useTranslation('recapForm');
  const {
    form,
    masterdata,
    getInputProps,
    initialValues,
    samplingAnalysis,
    selectedIncoterm,
    samplingSurveyorsNames,
    weightLoadportSurveyor,
    weightDischargeSurveyor,
    samplingSurveyorsAtDischarge,
  } = useRecapFormContext();

  const { freightConditions, vesselInformation } = form.values.product;
  const { exclusionFromLayTime, laytimeCalculations } = freightConditions;

  const clearNominatedVesselFields = () => form.setFieldValue('product.vesselInformation.imoNumber', undefined);

  const clearTbnFields = () => {
    form.setFieldValue('product.vesselInformation.maxAge', undefined);
    form.setFieldValue('product.vesselInformation.vesselsNominationDays', undefined);
    form.setFieldValue('product.vesselInformation.vesselNominationEvent', undefined);
  };

  const isExclusionFromLayTimeCheckboxDirty = (value: string) => {
    const isChecked = (exclusionFromLayTime || []).includes(value);
    const isInitiallyChecked = initialValues.product.freightConditions.exclusionFromLayTime?.includes(value) || false;

    return isInitiallyChecked !== isChecked;
  };

  const isLaytimeCalculationCheckboxDirty = (value: string) => {
    const isChecked = (laytimeCalculations || []).includes(value);
    const isInitiallyChecked = initialValues.product.freightConditions.laytimeCalculations?.includes(value) || false;

    return isInitiallyChecked !== isChecked;
  };

  const isFob = selectedIncoterm === CargoTransportation.FOB;

  return (
    <Stack spacing={24}>
      <ProductCargoFields offeredTransportation={selectedIncoterm} />
      <FormCardSection title={t('sections.weight')}>
        <TwoColumnsLayout>
          <Stack>
            <Text>{t('sections.surveyourAtLoadport')}</Text>
            <InsertableFieldGroup
              group={weightLoadportSurveyor}
              addLabel={t('buttons.addSurveyour')}
              removeLabel={t('buttons.removeSurveyour')}
              fieldGroupComponent={WeightLoadportFieldGroup}
            />
          </Stack>
          <Stack>
            <Text>{t('sections.surveyorAtDischargePort')}</Text>
            <InsertableFieldGroup
              group={weightDischargeSurveyor}
              addLabel={t('buttons.addSurveyourDischarge')}
              removeLabel={t('buttons.removeSurveyourDischarge')}
              fieldGroupComponent={WeightDischargeSurveyorsFieldGroup}
            />
          </Stack>
        </TwoColumnsLayout>
        <TextInput
          {...getInputProps('product.weight.otherSurveyor')}
          withAsterisk={false}
          label={t('labels.otherSurveyor')}
        />
      </FormCardSection>
      <FormCardSection title={t('sections.samplingAndAnalysis')}>
        <TwoColumnsLayout>
          <Stack>
            <Text>{t('sections.surveyourAtLoadport')}</Text>
            <InsertableFieldGroup
              group={samplingSurveyorsNames}
              addLabel={t('buttons.addSurveyour')}
              removeLabel={t('buttons.removeSurveyour')}
              fieldGroupComponent={SamplingSurveyorNamesFieldGroup}
            />
          </Stack>
          <Stack>
            <Text>{t('sections.surveyorAtDischargePort')}</Text>
            <InsertableFieldGroup
              group={samplingSurveyorsAtDischarge}
              addLabel={t('buttons.addSurveyourDischarge')}
              removeLabel={t('buttons.removeSurveyourDischarge')}
              fieldGroupComponent={SamplingSurveyorsAtDischargeFieldGroup}
            />
          </Stack>
        </TwoColumnsLayout>
        <TwoColumnsLayout>
          <Stack>
            <Text weight={600} color="gray.7">
              {t('sections.analysis')}
            </Text>
            <InsertableFieldGroup
              group={samplingAnalysis}
              addLabel={t('buttons.addAnalysis')}
              removeLabel={t('buttons.removeAnalysis')}
              fieldGroupComponent={SamplingAnalysisFieldGroup}
            />
          </Stack>
          <Stack>
            <Text weight={600} color="gray.7">
              {t('sections.arbitrationAnalysis')}
            </Text>
            <TextInput
              {...getInputProps('product.samplingAndAnalysis.arbitrationAnalysis.name')}
              withAsterisk={false}
              label={t('labels.name')}
              placeholder={t('placeholders.name')}
            />
            <TextInput
              {...getInputProps('product.samplingAndAnalysis.arbitrationAnalysis.conditions')}
              withAsterisk={false}
              label={t('labels.conditions')}
              placeholder={t('placeholders.conditions')}
            />
          </Stack>
        </TwoColumnsLayout>
        <TextInput
          {...getInputProps('product.samplingAndAnalysis.others')}
          withAsterisk={false}
          label={t('labels.others')}
          placeholder={t('placeholders.conditions')}
        />
      </FormCardSection>
      <FormCardSection title={t('sections.vesselInformation')}>
        <Radio.Group name="type" orientation="vertical" {...getInputProps('product.vesselInformation.type')}>
          <Stack spacing={8}>
            <Radio value="TBN" label={t('labels.TBN')} onClick={clearNominatedVesselFields} />
            {vesselInformation.type === 'TBN' && (
              <Group>
                <Trans t={t} i18nKey="labels.tbnMaxAge">
                  Max age <NumberInput w={52} {...getInputProps('product.vesselInformation.maxAge')} /> years
                </Trans>
                <Divider orientation="vertical" />
                <Trans
                  t={t}
                  i18nKey="labels.vesselsToBeNominated"
                  components={{
                    bold: <strong />,
                    input: <NumberInput w={52} {...getInputProps('product.vesselInformation.vesselsNominationDays')} />,
                    event: (
                      <Select
                        {...getInputProps('product.vesselInformation.vesselsNominationEvent')}
                        grow={false}
                        data={masterdata.vesselNominationEvents}
                      />
                    ),
                  }}
                />
              </Group>
            )}
          </Stack>
          <Stack spacing={8}>
            <Radio onClick={clearTbnFields} value="NOMINATED_VESSEL" label={t('labels.NOMINATED_VESSEL')} />
            {vesselInformation.type === 'NOMINATED_VESSEL' && (
              <TextInput
                {...getInputProps('product.vesselInformation.imoNumber')}
                horizontal
                withAsterisk={false}
                label={t('labels.imoNumber')}
                placeholder={t('placeholders.id')}
              />
            )}
          </Stack>
        </Radio.Group>
        <Divider />
        <Grid>
          <Grid.Col span={6} md={4} lg={2}>
            <Select
              {...getInputProps('product.vesselInformation.gears')}
              withAsterisk={false}
              data={masterdata.gears}
              label={t('labels.gears')}
            />
          </Grid.Col>
          <Grid.Col span={6} md={4} lg={2}>
            <NumberInput
              {...getInputProps('product.vesselInformation.cranesNumber')}
              withAsterisk={false}
              label={t('labels.cranesNumber')}
            />
          </Grid.Col>
          <Grid.Col span={6} md={4} lg={2}>
            <NumberInput
              {...getFixedNumberProps(1)}
              {...getInputProps('product.vesselInformation.cranesCapacity')}
              min={0}
              rightSection="mts"
              withAsterisk={false}
              label={t('labels.cranesCapacity')}
            />
          </Grid.Col>
          <Grid.Col span={6} md={4} lg={2}>
            <Select
              {...getInputProps('product.vesselInformation.grabs')}
              withAsterisk={false}
              data={masterdata.grabs}
              label={t('labels.grabs')}
            />
          </Grid.Col>
          <Grid.Col span={6} md={4} lg={2}>
            <NumberInput
              {...getInputProps('product.vesselInformation.grabsNumber')}
              withAsterisk={false}
              label={t('labels.grabsNumber')}
            />
          </Grid.Col>
          <Grid.Col span={6} md={4} lg={2}>
            <NumberInput
              {...getFixedNumberProps(1)}
              {...getInputProps('product.vesselInformation.grabCapacity')}
              min={0}
              rightSection="cbm"
              withAsterisk={false}
              label={t('labels.grabCapacity')}
            />
          </Grid.Col>
        </Grid>
      </FormCardSection>
      <FormCardSection title={isFob ? t('sections.loadingConditions') : t('sections.dischargingConditions')}>
        <Grid>
          <Grid.Col span={12} md={5}>
            <TextInput
              {...getInputProps('product.freightConditions.safePortNo')}
              w="100%"
              horizontal
              data-testid={'noOfSafePort'}
              label={t('labels.noOfSafePort')}
              placeholder={t('placeholders.no')}
            />
          </Grid.Col>
          <Grid.Col span={12} md={7}>
            <Flex direction={{ base: 'column', md: 'row' }} gap={8}>
              <TextInput
                {...getInputProps('product.freightConditions.safeBerthNo')}
                horizontal
                data-testid={'noOfSafeBerth'}
                label={t('labels.noOfSafeBerth')}
                placeholder={t('placeholders.no')}
              />
              <Select
                {...getInputProps('product.freightConditions.berthConditions')}
                defaultValue="AAAA"
                data={masterdata.berthConditions}
              />
            </Flex>
          </Grid.Col>
          <Grid.Col span={12}>
            <Divider />
          </Grid.Col>
          <Grid.Col span={12} md={5}>
            <NumberInput
              {...getFixedNumberProps(1)}
              {...getInputProps('product.freightConditions.freightRate')}
              min={0}
              w="100%"
              horizontal
              placeholder={t('placeholders.quantity')}
              label={isFob ? t('labels.loadingRateMts') : t('labels.dischargingRateMts')}
            />
          </Grid.Col>
          <Grid.Col span={12} md={7}>
            <Flex direction={{ base: 'column', md: 'row' }} gap={8}>
              <Select
                {...getInputProps('product.freightConditions.freightTerms')}
                horizontal
                grow={false}
                data={masterdata.freightTerms}
                label={isFob ? t('labels.loadingTerms') : t('labels.dischargingTerms')}
              />
              <Select
                {...getInputProps('product.freightConditions.freightPeriodTerms')}
                data={masterdata.freightPeriodTerms}
              />
            </Flex>
          </Grid.Col>
          <Grid.Col span={12}>
            <Divider />
          </Grid.Col>
          <Grid.Col span={12}>
            <Select
              {...getInputProps('product.freightConditions.norTendering')}
              horizontal
              data-testid={'recapNor'}
              data={masterdata.norTendering}
              defaultValue="atdn + SSHINC"
              label={t('labels.norTendering')}
              placeholder={t('placeholders.nor')}
              description={t('hints.norTendering')}
            />
          </Grid.Col>
          <Grid.Col span={12} md={6}>
            <Flex direction={{ base: 'column', md: 'row' }} gap={8}>
              <NumberInput
                {...getInputProps('product.freightConditions.turnTime')}
                horizontal
                w={{ md: 175 }}
                label={t('labels.turnTimeH')}
                data-testid={'recapTurnTimeValue'}
              />
              <Select
                {...getInputProps('product.freightConditions.turnTimeTerms')}
                data={masterdata.turnTimes}
                data-testid={'recapTurnTimeTerms'}
              />
            </Flex>
          </Grid.Col>
          <Grid.Col span={12}>
            <Divider />
          </Grid.Col>
          <Grid.Col span={12}>
            <CheckboxGroup
              {...getInputProps('product.freightConditions.exclusionFromLayTime')}
              spacing={12}
              withAsterisk={false}
              orientation="vertical"
              label={t('labels.exclusionFromLayTime')}
            >
              {masterdata.exclusionFromLaytime.map((option) => (
                <Checkbox
                  key={option.value}
                  value={option.value}
                  label={option.label}
                  isDirty={isExclusionFromLayTimeCheckboxDirty(option.value)}
                />
              ))}
            </CheckboxGroup>
            {(exclusionFromLayTime || []).includes('OTHERS') && (
              <TextInput
                {...getInputProps('product.freightConditions.exclusionFromLayTimeOtherExclusion')}
                mt={8}
                w={{ md: '40%' }}
                placeholder={t('placeholders.exclusion')}
              />
            )}
          </Grid.Col>
          <Grid.Col span={12}>
            <CheckboxGroup
              {...getInputProps('product.freightConditions.laytimeCalculations')}
              spacing={12}
              withAsterisk={false}
              orientation="vertical"
              label={t('labels.laytimeCalculations')}
            >
              {masterdata.laytimeCalculations.map((option) => (
                <Checkbox
                  key={option.value}
                  value={option.value}
                  label={option.label}
                  isDirty={isLaytimeCalculationCheckboxDirty(option.value)}
                />
              ))}
            </CheckboxGroup>
          </Grid.Col>
          <Grid.Col span={12}>
            <Divider />
          </Grid.Col>
          <Grid.Col span={12} md={4}>
            <Select
              {...getInputProps('product.freightConditions.cargoPda')}
              data={masterdata.pdas}
              label={t('labels.cargoPda')}
            />
          </Grid.Col>
          <Grid.Col span={12} md={4}>
            <Select
              {...getInputProps('product.freightConditions.vesselPda')}
              data={masterdata.pdas}
              label={t('labels.vesselPda')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              {...getInputProps('product.freightConditions.other')}
              withAsterisk={false}
              label={t('labels.other')}
            />
          </Grid.Col>
        </Grid>
      </FormCardSection>
      <FormCardSection title={isFob ? t('sections.agentsInLoadingPort') : t('sections.agentsInDischargingPort')}>
        <TwoColumnsLayout>
          <Stack>
            <Select
              {...getInputProps('product.agentsInDischargingPort.mainAgendConditions')}
              withAsterisk={true}
              data={masterdata.mainAgentConditions}
              label={t('labels.mainAgentConditions')}
            />
            <TextInput
              {...getInputProps('product.agentsInDischargingPort.mainAgentName')}
              withAsterisk={false}
              label={t('labels.mainAgentName')}
              placeholder={t('placeholders.agent')}
            />
          </Stack>
          <Stack>
            <Select
              {...getInputProps('product.agentsInDischargingPort.protectiveAgendConditions')}
              withAsterisk={true}
              data={masterdata.protectiveAgentConditions}
              label={t('labels.protectiveAgentConditions')}
            />
            <TextInput
              {...getInputProps('product.agentsInDischargingPort.protectiveAgentName')}
              withAsterisk={false}
              label={t('labels.proactiveAgentName')}
              placeholder={t('placeholders.agent')}
            />
          </Stack>
        </TwoColumnsLayout>
      </FormCardSection>
    </Stack>
  );
};

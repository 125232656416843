import { SelectItem } from '@mantine/core';
import { useTranslation } from 'next-i18next';

export function useGetSelectDataWithTranslations(selectData: Record<string, string>): SelectItem[] {
  const { t } = useTranslation('tenderNegotiation');

  return Object.values(selectData).map((value) => ({
    value: value,
    label: t(`labels.${value}`),
  }));
}

import { TextInputProps } from '@mantine/core';

import { ThemeComponent } from './types';

export const TextInput: ThemeComponent<TextInputProps> = {
  styles: (_theme, params) => ({
    root: {
      height: 80,

      ...(params.size === 'sm' && {
        height: 72,
      }),
    },
    input: {
      height: 48,

      ...(params.size === 'sm' && {
        height: 40,
      }),
    },
    label: {
      fontSize: 16,
      marginBottom: 8,
      fontWeight: 500,
    },
    error: {
      position: 'relative',
      marginTop: 0,
    },
  }),
};

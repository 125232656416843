import { useEffect, useState } from 'react';

import { isSystemNotificationSupported } from '@/core/helpers/is-system-notification-supported';

export const useSystemNotifications = () => {
  const [isNotificationPermissionGranted, setIsNotificationPermissionGranted] = useState(false);

  const isPushNotificationEnabled = true && isSystemNotificationSupported();

  useEffect(() => {
    if (!isPushNotificationEnabled) return;

    if (Notification.permission === 'granted') setIsNotificationPermissionGranted(true);
  }, []);

  const requestNotificationPermission = async (): Promise<boolean> => {
    if (!isPushNotificationEnabled) return false;

    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      setIsNotificationPermissionGranted(true);
      return true;
    }

    setIsNotificationPermissionGranted(false);
    return false;
  };

  return {
    isNotificationPermissionGranted,
    requestNotificationPermission,
  };
};

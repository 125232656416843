import { useTranslation } from 'next-i18next';
import { Grid, Group, Image, Paper, Stack } from '@mantine/core';

import { Text, Title } from '@/components';

interface ComingSoonProps {
  description?: string;
  vertical?: boolean;
}

export function ComingSoon({ description, vertical = false }: ComingSoonProps) {
  const { t } = useTranslation('common');
  description = description || t('comingSoon.default');

  return (
    <Grid>
      <Grid.Col span={vertical ? 12 : 5} md={vertical ? 12 : 6} p={24}>
        <Image src="/images/coming-soon.svg" alt="Empty cargos" />
      </Grid.Col>

      <Grid.Col
        span={vertical ? 12 : 7}
        md={vertical ? 12 : 6}
        sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
      >
        <Stack spacing={16} align="flex-start">
          <Title size="XL" color="gray.8" weight={600}>
            {t('comingSoon.title')}
          </Title>

          <Text color="gray.8">{description}</Text>
        </Stack>
      </Grid.Col>
    </Grid>
  );
}

export function CommingSoonCompact({ description }: ComingSoonProps) {
  const { t } = useTranslation('common');
  description = description || t('comingSoon.default');

  return (
    <Paper p={20}>
      <Group spacing={24} align="flex-start">
        <Image src="/images/coming-soon.svg" alt="Empty cargos" height={65} width={72} />
        <Stack spacing={8} align="flex-start" sx={{ flex: 1 }}>
          <Title size="XL" color="gray.8" weight={600}>
            {t('comingSoon.title')}
          </Title>
          <Text color="gray.8">{description}</Text>
        </Stack>
      </Group>
    </Paper>
  );
}

import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useSetActiveCompanyContext } from 'api/generated/company-user/company-user';

import { UserCompanyContextDto } from '../../api/generated/model';

export const useChangeCompanyContext = () => {
  const { mutate, isSuccess } = useSetActiveCompanyContext();
  const router = useRouter();
  const [redirectTo, setRedirectTo] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (isSuccess) {
      if (redirectTo) {
        window.location.assign(window.location.origin + redirectTo);
      } else {
        // NOTE: reloads the page with default tabs setup for the current page by removing search params.
        window.location.assign(router.pathname);
      }
    }
  }, [isSuccess, redirectTo, router]);

  return (company: UserCompanyContextDto, options: { redirectTo?: string } = {}) => {
    setRedirectTo(options.redirectTo);
    mutate({
      data: {
        companyContextId: company.companyContextId,
      },
    });
  };
};

import { z } from 'zod';

import { cargoTenderAnalysisSchema } from './cargo-tender-analysis';
import { CostsTerms } from '../types';

export const cargoTenderSamplingAnalysisSchema = z.object({
  other: z.string().optional(),
  surveyorAtLoadingPortCost: z.nativeEnum(CostsTerms),
  surveyorAtLoadingPortName: z.string().optional(),
  surveyorAtLoadingPortConditions: z.string().optional(),
  surveyorAtDischargePortCost: z.nativeEnum(CostsTerms),
  surveyorAtDischargePortName: z.string().optional(),
  surveyorAtDischargePortConditions: z.string().optional(),
  artbitrationAnalysisConditions: z.string().optional(),
  artbitrationAnalysisName: z.string().optional(),
  analysis: z.array(cargoTenderAnalysisSchema).min(1),
});

export type CargoTenderSamplingAnalysisSchema = z.infer<typeof cargoTenderSamplingAnalysisSchema>;

import { z } from 'zod';

import { CostsTermsWithNA } from '../../types';

export const agentsInPort = z.object({
  mainAgentConditions: z.nativeEnum(CostsTermsWithNA).optional().nullable(),
  mainAgentName: z.string().optional(),
  protectiveAgentConditions: z.nativeEnum(CostsTermsWithNA).optional().nullable(),
  protectiveAgentName: z.string().optional(),
});

export type AgentsInPortSchema = z.infer<typeof agentsInPort>;

import { Button, createStyles, Drawer, Flex, Stack, Tabs } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { RiAddCircleFill } from 'react-icons/ri';
import { createEnumParam, StringParam, useQueryParams } from 'use-query-params';
import { getGetDraftNegotiationQueryKey } from 'api/generated/negotiaiton-draft/negotiaiton-draft';
import {
  ArchivedCargoDTO,
  BookedCargoDTO,
  CanceledCargoDTO,
  CargoNeedNegotiationDTO,
  CargoNegotiationDTO,
  CargoOfferNegotiationDTO,
  CargoStatus,
  CompanyRole,
  Incoterm,
  UserRole,
} from 'api/generated/model';
import { useGetChatGroupsUnreadMessages } from 'api/generated/chat/chat';
import {
  getGetCargoNegotiationHistoryQueryKey,
  updateCSMUnlock,
  useAcceptExtendCargoExpiryRequest,
  useRequestCargoExpiryExtension,
} from 'api/generated/cargo-negotiation/cargo-negotiation';
import { ConfirmationModal } from 'components/confirmation-modal/ConfirmationModal';
import { useCancelPromotedCargoModal } from 'v2/shared/hooks/modals/use-cancel-promoted-cargo-modal/use-cancel-promoted-modal';

import { isActionStatus, isFinalStatus, isRfqStatus } from '@/common/negotiation-status';
import { routes } from '@/common/routes';
import { GrantExtendModal, HideForbiddenElement, RequestExtendModal } from '@/components';
import { useAuthRoles, useHasPermission } from '@/core';
import { CancelModal } from '@/views/cargo-cancelation';
import { AcceptModal } from '@/views/cargo-cancelation/components/AcceptModal';
import { useDisclosureWithRowIdQueryParam } from '@/views/cargo-cancelation/components/useDisclosureWithRowIdQueryParam.hook';
import { useStatusNegotiationModal } from '@/views/cargo-cancelation/state/CancelModalContext';
import { ExpireBadgeFunction } from '@/views/cargo-cancelation/types';
import { useNegotiationsColumns } from '@/views/cargo-details/state/use-negotiations-columns';
import { NegotiationTableFunction } from '@/views/cargo-details/types';
import { MyCargosBookedTable, MyCargosProspectTable, MyCargoTab } from '@/views/my-cargos';
import { MyCargosCanceledTable } from '@/views/my-cargos/components/MyCargosTable/MyCargosCanceledTable';
import { NegotiationRecapModal } from '@/views/recap-form/components/NegotiationRecapModal';
import { CancelActions, PreviewActions, ReviewFinalStepActions } from '@/views/recap-form/components/RecapPreviewModal';
import { useArchivingModal } from '@/core/hooks/use-archiving-modal';
import { ArchiveMutationData } from '@/core/hooks/use-archive-mutation';
import { UnarchiveMutationData } from '@/core/hooks/use-unarchive-mutation';

import { NegotiationDetails } from '../components/NegotiationDetails';
import { OfferDetailsModal } from '../components/OfferDetailsModal';
import { useExtendDeadlineErrors } from '../components/state/use-extend-deadline-errors';
import { NegotiationDetailsTab, OfferType, TypeCargo, TypeCargoTab } from '../types';
import { MyCargosArchivedTable } from '../components/MyCargosTable/MyCargosArchivedTable';
import { useRedirectAfterWorkflow } from '../hooks/use-redirect-after-workflow';

const useStyles = createStyles((theme) => ({
  pillsTabsList: {
    [theme.fn.smallerThan('md')]: {
      margin: '0 -16px',
      padding: 8,
      width: '100vw',
      borderRadius: 0,
      justifyContent: 'space-between',
    },
  },
  pillsTab: {
    [theme.fn.smallerThan('md')]: {
      padding: '1px 12px',
    },
  },
  hideOnMobile: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
      pointerEvents: 'none',
    },
  },
  hideOnDesktop: {
    [theme.fn.largerThan('md')]: {
      display: 'none',
      pointerEvents: 'none',
    },
  },
  scroll: {
    overflowY: 'scroll',
  },
  header: {
    height: 0,
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: 16,
    top: 16,
    color: theme.fn.primaryColor(),

    svg: {
      width: 32,
      height: 32,
    },
  },
  filters: {
    flexDirection: 'column',
    padding: 24,
  },
  drawer: {
    width: '70%',
    [theme.fn.smallerThan('md')]: {
      width: '100%',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  drawerBody: {
    height: 'calc(100% - 44px)',
  },
  addBookedCargoButton: {
    marginTop: theme.spacing.xs,
    [theme.fn.smallerThan('md')]: {
      flex: 1,
    },
  },
}));

enum DrawerType {
  NegotiationHistory = 'negotiation-history',
}
type MyCargosProspectTableContainerProps<T> = {
  toggleAddBookedCargoModal: VoidFunction;
  isLoading: boolean;
  type: OfferType;
  data: T | undefined;
  bookedCargos: BookedCargoDTO[];
  canceledCargos: CanceledCargoDTO[];
  activeTab: MyCargoTab;
  typeCargo: TypeCargo;
  archiveCallback: VoidFunction;
  unarchiveCallback: VoidFunction;
  cancelCargoCallback: VoidFunction;
  archivedCargos: ArchivedCargoDTO[];
};
type CargoDataType = (CargoNeedNegotiationDTO | CargoOfferNegotiationDTO)[];

const getProspectData = <T extends CargoDataType>(data: T) =>
  data.filter((cargo) => cargo.status !== CargoStatus.BOOKED);

export function MyCargosProspectTableContainer<T extends CargoDataType>(props: MyCargosProspectTableContainerProps<T>) {
  const { t } = useTranslation('myCargos');

  const { redirectToAfterWorkflow } = useRedirectAfterWorkflow();

  const {
    data,
    isLoading,
    type,
    bookedCargos,
    canceledCargos,
    toggleAddBookedCargoModal,
    activeTab,
    typeCargo,
    archiveCallback,
    cancelCargoCallback,
    archivedCargos,
    unarchiveCallback,
  } = props;

  const router = useRouter();

  const [queryParam, setQueryParams] = useQueryParams({
    drawer: {
      ...createEnumParam(Object.values(DrawerType)),
    },
    negotiationId: StringParam,
    activeDetailsTab: {
      ...createEnumParam(Object.values(NegotiationDetailsTab)),
    },
    activeTabCargo: {
      ...createEnumParam(Object.values(TypeCargoTab)),
    },
  });

  const [negotiationOpened, { toggle: toggleNegotiation }] = useDisclosure(false);
  const [linkCargosOpened, { close: closeLinkCargos, open: openLinkCargos }] = useDisclosure(false);
  const [selectedRow, setSelectedRow] = useState<CargoNegotiationDTO>();
  const [selectedWorkflowId, setSelectedWorkflowId] = useState<string>(null);
  const onNegotiationHistoryClick: NegotiationTableFunction = (row) => {
    setSelectedRow(row.original);
    setQueryParams({
      ...queryParam,
      drawer: DrawerType.NegotiationHistory,
      negotiationId: row.original.id,
      activeDetailsTab: NegotiationDetailsTab.HISTORY,
    });
  };

  const [cargoTabActive, setActiveTab] = useState<TypeCargoTab>(queryParam.activeTabCargo as TypeCargoTab);

  useEffect(() => {
    setActiveTab(queryParam.activeTabCargo as TypeCargoTab);
  }, [queryParam.activeTabCargo]);

  const handleCargoTabChange = (value: TypeCargoTab | any) => {
    setActiveTab(value);
    setQueryParams({ ...queryParam, activeTabCargo: value });
  };

  const toggleNegotiationModal: NegotiationTableFunction = (row) => {
    setSelectedRow(row.original);
    queryClient.invalidateQueries(getGetCargoNegotiationHistoryQueryKey(row.original.id));
    toggleNegotiation();
  };
  const queryClient = useQueryClient();

  const onNegotiationsDetailsClick: NegotiationTableFunction = (row) => {
    if (row.original.hasDraft) {
      queryClient.invalidateQueries(getGetDraftNegotiationQueryKey(row.original.id));
      router.push(
        routes.RecapForm({
          needId: row.original.cargoNeedId,
          offerId: row.original.cargoOfferId,
          offeredTransport: row.original.lookingFor,
          negotiationId: row.original.id,
        })
      );
      return;
    }
    toggleNegotiationModal(row);
  };

  const onCancelNegotiationsClick: NegotiationTableFunction = (row) => {
    setSelectedRow(row.original);
    toggleCancelModal(row.original.id);
  };

  const onExtendClick: NegotiationTableFunction = (row) => {
    toggleExtend(row.original.id);
  };

  const onRequestExtensionClick: NegotiationTableFunction = (row) => {
    toggleRequestExtension(row.original.id);
  };

  const onExpireBadgeClick: ExpireBadgeFunction = ({ type, negotiationId }) => {
    type === 'request' ? toggleRequestExtension(negotiationId) : toggleExtend(negotiationId);
  };

  const onChatIconClick: NegotiationTableFunction = (row) => {
    setQueryParams({
      drawer: DrawerType.NegotiationHistory,
      negotiationId: row.original.id,
      activeDetailsTab: NegotiationDetailsTab.CHAT,
    });
  };

  const onExtendSuccess: ExpireBadgeFunction = ({ type, negotiationId }) => {
    showNotification({
      color: 'green',
      message: type === 'request' ? t('notifications.requestCargoExtension') : t('notifications.cargoDeadlineExtended'),
      title: undefined,
    });
    queryClient.invalidateQueries(getGetCargoNegotiationHistoryQueryKey(negotiationId));
    onExpireBadgeClick({ type, negotiationId });
  };

  const requestCargoExpiryExtension = useRequestCargoExpiryExtension({
    mutation: {
      onSuccess: () => onExtendSuccess({ type, negotiationId: selectedRow?.id }),
      onError: (error) => {
        extendRequestError(error);
        toggleRequestExtension(selectedRow?.id);
      },
    },
  });

  const extendCargoExpiryDate = useAcceptExtendCargoExpiryRequest({
    mutation: {
      onSuccess: () => onExtendSuccess({ type, negotiationId: selectedRow?.id }),
      onError: (error) => {
        extendRequestError(error);
        toggleExtend(selectedRow?.id);
      },
    },
  });

  const onEnablePrivateDataForCSM = async (row) => {
    try {
      await updateCSMUnlock(row.original.id);
      showNotification({
        color: 'green',
        message: t('notifications.CSMNegotiationPrivacyDataEnabled'),
      });
    } catch (e) {
      showNotification({
        color: 'red',
        message: e.message,
      });
    }
  };

  const {
    isAcceptModalOpened,
    isRequestExtensionModalOpened,
    isExtendModalOpened,
    toggleAcceptModal,
    toggleRequestExtension,
    toggleExtend,
  } = useStatusNegotiationModal();

  const [isCancelModalOpened, toggleCancelModal] = useDisclosureWithRowIdQueryParam();
  const [isRejectModalOpened, toggleRejectModal] = useDisclosureWithRowIdQueryParam();
  const { useProspectColumns } = useNegotiationsColumns();
  const { extendRequestError } = useExtendDeadlineErrors();

  const { Modal: CancelPromotedCargoModal, toggleModal: toggleCancelPromotedCargoModal } = useCancelPromotedCargoModal({
    onCancelSuccess: cancelCargoCallback,
  });

  const [shortId, setShortId] = useState('');

  const {
    openModal,
    closeModal,
    archiveCargo,
    unarchiveCargo,
    isArchivedModalVisible,
    isUnarchiveModalVisible,
    isProcessingArchiveCargo,
    isProcessingUnarchiveCargo,
  } = useArchivingModal({
    archiveCallback: () => {
      archiveCallback();
      closeModal('archive');
    },
    unarchiveCallback: () => {
      unarchiveCallback();
      closeModal('unarchive');
    },
  });

  const onCancelClick = (id: string, type: OfferType) => {
    toggleCancelPromotedCargoModal({
      cargoId: id,
      isCreator: true,
      cargoShortId: shortId,
      cargoType: type === 'offer' ? 'OFFER' : 'NEED',
    });
  };

  const onArchiveCargoClick = (shortId: string, workflowId: string) => {
    const archiveMutationData: ArchiveMutationData = {
      shortId,
      id: workflowId,
    };

    setShortId(shortId);
    openModal('archive', archiveMutationData);
  };

  const onUnarchiveCargoClick = (shortId: string, workflowId: string) => {
    const unarchiveMutationData: UnarchiveMutationData = {
      shortId,
      id: workflowId,
    };

    setShortId(shortId);
    openModal('unarchive', unarchiveMutationData);
  };

  const onDuplicateCargoClick = (row) => {
    router.push(routes.CargoBookedAddParamsCargo(Incoterm.CFR, activeTab, row.original.id));
  };

  const { data: chatGroupUnreadMessages } = useGetChatGroupsUnreadMessages({
    query: { select: (data) => data?.data, staleTime: 0 },
  });

  const closeArchiveModal = () => {
    closeModal('archive');
  };

  const closeUnarchiveModal = () => {
    closeModal('unarchive');
  };

  const prospectColumns = useProspectColumns({
    type,
    onCancelNegotiationsClick,
    onNegotiationHistoryClick,
    onNegotiationsDetailsClick,
    onExtendClick,
    onRequestExtensionClick,
    onExpireBadgeClick,
    onChatIconClick,
    onEnablePrivateDataForCSM,
    chatGroupUnreadMessages: chatGroupUnreadMessages || [],
  });
  const isFinalStep = isFinalStatus(selectedRow?.lastStep.status);
  const isActionToBeTaken = isActionStatus(selectedRow?.lastStep.status);
  const isRfq = isRfqStatus(selectedRow?.lastStep.status);
  const { isServiceProvider } = useAuthRoles();

  const { classes } = useStyles();

  const { hasUserRolePermission, hasAllowedCompanyRoles } = useHasPermission();

  const canFinalizeNegotiation = hasAllowedCompanyRoles(CompanyRole.ALLOWED_TO_FINALIZE_NEGOTIATION);

  const tabDefaultValue =
    typeCargo === TypeCargo.Booked
      ? 'booked'
      : hasUserRolePermission([UserRole.BUYER, UserRole.SELLER]) &&
        hasAllowedCompanyRoles(CompanyRole.ALLOWED_TO_NEGOTIATE)
      ? 'prospect'
      : 'booked';

  return (
    <>
      <Tabs
        value={cargoTabActive}
        onTabChange={(value) => handleCargoTabChange(value)}
        classNames={{ tabsList: classes.pillsTabsList, tab: classes.pillsTab }}
        defaultValue={tabDefaultValue}
        variant="pills"
        mt={24}
        data-testid={'cargos-table-' + activeTab}
      >
        <Tabs.List>
          <HideForbiddenElement
            allowedCompanyRoles={CompanyRole.ALLOWED_TO_NEGOTIATE}
            allowUnverifiedCompany
            allowedRoles={[UserRole.BUYER, UserRole.SELLER]}
          >
            <Tabs.Tab value="prospect" data-testid={'prospect'}>
              {t('tabs.prospect')}
            </Tabs.Tab>
          </HideForbiddenElement>
          <HideForbiddenElement
            allowUnverifiedCompany
            allowedRoles={[UserRole.BUYER, UserRole.SELLER, UserRole.OPERATIONS, UserRole.SERVICES, UserRole.FINANCE]}
          >
            <Tabs.Tab value="booked" data-testid={'booked'}>
              {t('tabs.booked')}
            </Tabs.Tab>
            {!isServiceProvider && (
              <Tabs.Tab value="archived" data-testid={'archived'}>
                {t('tabs.archived')}
              </Tabs.Tab>
            )}
            {!isServiceProvider && (
              <Tabs.Tab value="canceled" data-testid={'canceled'}>
                {t('tabs.canceled')}
              </Tabs.Tab>
            )}
          </HideForbiddenElement>
        </Tabs.List>

        <Tabs.Panel value={TypeCargoTab.PROSPECT}>
          <Stack spacing={24} mt={12}>
            <MyCargosProspectTable
              data={getProspectData(data || [])}
              negotiationColumns={prospectColumns}
              isLoading={isLoading}
              type={type}
              onRowClick={(row) => row.toggleExpanded()}
              onCancelListing={onCancelClick}
            />
          </Stack>
        </Tabs.Panel>

        <Tabs.Panel value={TypeCargoTab.BOOKED}>
          <HideForbiddenElement
            allowUnverifiedCompany
            allowedRoles={[UserRole.ADMIN, UserRole.BUYER, UserRole.SELLER, UserRole.OPERATIONS, UserRole.FINANCE]}
          >
            <Flex justify="flex-end">
              {!isServiceProvider && (
                <Button
                  className={classes.addBookedCargoButton}
                  data-testid={'addBookedCargoButton'}
                  onClick={() => toggleAddBookedCargoModal()}
                  leftIcon={<RiAddCircleFill />}
                  size="md"
                >
                  {t('tabs.panels.booked')}
                </Button>
              )}
            </Flex>
          </HideForbiddenElement>

          <Stack spacing={24} mt={12}>
            <MyCargosBookedTable
              data={bookedCargos || []}
              isLoading={isLoading}
              onRowClick={(row) => redirectToAfterWorkflow(row.original.id)}
              onLinkCargoClick={(row) => {
                setSelectedWorkflowId(row.original.id);
                openLinkCargos();
              }}
              onArchiveCargoClick={(row) => onArchiveCargoClick(row.original.shortId, row.original.id)}
              onDuplicateCargoClick={(row) => onDuplicateCargoClick(row)}
            />
          </Stack>
        </Tabs.Panel>

        <Tabs.Panel value={TypeCargoTab.ARCHIVED}>
          <Stack spacing={24} mt={12}>
            <MyCargosArchivedTable
              activeTab={activeTab}
              isLoading={isLoading}
              cargos={archivedCargos || []}
              onRowClick={(row) => redirectToAfterWorkflow(row.original.id)}
              onUnarchiveCargoClick={(row) => onUnarchiveCargoClick(row.original.shortId, row.original.id)}
              onLinkCargoClick={(row) => {
                setSelectedWorkflowId(row.original.id);
                openLinkCargos();
              }}
            />
          </Stack>
        </Tabs.Panel>

        <Tabs.Panel value={TypeCargoTab.CANCELLED}>
          <Stack spacing={24} mt={12}>
            <MyCargosCanceledTable activeTab={activeTab} data={canceledCargos || []} isLoading={isLoading} />
          </Stack>
        </Tabs.Panel>
      </Tabs>

      {isRfq ? (
        <OfferDetailsModal
          negotiationId={selectedRow?.id}
          opened={negotiationOpened}
          toggle={toggleNegotiation}
          isHistoryStep={selectedRow?.lastStep.status !== 'RFQ_RECEIVED'}
        />
      ) : (
        <NegotiationRecapModal
          negotiationId={selectedRow?.id}
          opened={negotiationOpened}
          toggle={toggleNegotiation}
          offerType={type}
          onExtendNegotiationsClick={(negotiationId) => onExpireBadgeClick({ type, negotiationId })}
          actions={
            <>
              {isActionToBeTaken && isFinalStep && (
                <ReviewFinalStepActions
                  onAccept={() => {
                    toggleNegotiation();
                    toggleAcceptModal(selectedRow?.id);
                  }}
                />
              )}
              {isActionToBeTaken && !isFinalStep && (
                <PreviewActions
                  onAccept={() => {
                    toggleNegotiation();
                    toggleAcceptModal(selectedRow?.id);
                  }}
                  onReject={() => {
                    toggleNegotiation();
                    toggleRejectModal(selectedRow?.id);
                  }}
                  negotiationId={selectedRow?.id}
                />
              )}
              {!isActionToBeTaken && (
                <CancelActions
                  onCancel={() => {
                    toggleNegotiation();
                    toggleCancelModal(selectedRow?.id);
                  }}
                />
              )}
            </>
          }
        />
      )}

      <Drawer
        opened={queryParam.drawer === DrawerType.NegotiationHistory && !!queryParam.negotiationId}
        onClose={() => setQueryParams({ drawer: undefined, negotiationId: undefined })}
        position="right"
        classNames={{
          root: classes.drawer,
          body: classes.drawerBody,
        }}
        padding={24}
        overlayOpacity={0.5}
      >
        <NegotiationDetails
          negotiationId={queryParam.negotiationId}
          lookingFor={selectedRow?.lookingFor}
          offerType={type}
          onCancelNegotiationsClick={toggleCancelModal}
          onRejectNegotiationsClick={toggleRejectModal}
          onExtendNegotiationsClick={(negotiationId) => onExpireBadgeClick({ type, negotiationId })}
        />
      </Drawer>

      {canFinalizeNegotiation && (
        <>
          <AcceptModal opened={isAcceptModalOpened} toggle={() => toggleAcceptModal(selectedRow?.id)} />

          <CancelModal
            opened={isCancelModalOpened}
            cancelType={'CANCEL'}
            toggleCancelNegotiationModal={() => toggleCancelModal(selectedRow?.id)}
          />

          <CancelModal
            opened={isRejectModalOpened}
            cancelType={'REJECT'}
            toggleCancelNegotiationModal={() => toggleRejectModal(selectedRow?.id)}
          />
        </>
      )}

      <RequestExtendModal
        opened={isRequestExtensionModalOpened}
        toggle={() => toggleRequestExtension(queryParam.negotiationId)}
        onConfirm={() => requestCargoExpiryExtension.mutate({ negotiationId: queryParam.negotiationId })}
        loading={requestCargoExpiryExtension.isLoading}
      />

      <GrantExtendModal
        opened={isExtendModalOpened}
        toggle={() => toggleExtend(queryParam.negotiationId)}
        loading={extendCargoExpiryDate.isLoading}
        onConfirm={() => extendCargoExpiryDate.mutate({ negotiationId: queryParam.negotiationId })}
      />

      <ConfirmationModal
        onSubmit={archiveCargo}
        onCancel={closeArchiveModal}
        opened={isArchivedModalVisible}
        isLoading={isProcessingArchiveCargo}
        labels={{
          confirmation: t('modals.archiveCargo.archive.actionButton'),
          title: t('modals.archiveCargo.archive.title', { cargoId: shortId }),
          descriptions: [
            t('modals.archiveCargo.archive.description', { cargoId: shortId }),
            t('modals.archiveCargo.archive.additionalDescription'),
          ],
        }}
      />

      <ConfirmationModal
        onSubmit={unarchiveCargo}
        onCancel={closeUnarchiveModal}
        opened={isUnarchiveModalVisible}
        isLoading={isProcessingUnarchiveCargo}
        labels={{
          title: t('modals.archiveCargo.unarchive.title', { cargoId: shortId }),
          confirmation: t('modals.archiveCargo.unarchive.actionButton', { cargoId: shortId }),
          descriptions: [t('modals.archiveCargo.unarchive.description', { cargoId: shortId })],
        }}
      />

      <CancelPromotedCargoModal />
    </>
  );
}

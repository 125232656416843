import { useTranslation } from 'next-i18next';

import { useRecapFormContext } from '@/views/recap-form';

import { FieldGroupComponent, Select, TextInput } from './RecapFormElements';

export const WeightLoadportFieldGroup: FieldGroupComponent = ({ index }) => {
  const { t } = useTranslation('recapForm');
  const { getInputProps, masterdata } = useRecapFormContext();

  return (
    <>
      <TextInput
        label={t('labels.surveyorName')}
        withAsterisk={false}
        placeholder={t('placeholders.name')}
        {...getInputProps(`product.weight.loadportSurveyors.${index}.name`)}
      />
      <Select
        label={t('labels.surveyorCost')}
        data={masterdata.surveyorCosts}
        defaultValue="AT_SELLERS_COST"
        grow={false}
        {...getInputProps(`product.weight.loadportSurveyors.${index}.cost`)}
      />
      <TextInput
        label={t('labels.conditions')}
        placeholder={t('placeholders.conditions')}
        withAsterisk={false}
        {...getInputProps(`product.weight.loadportSurveyors.${index}.conditions`)}
      />
    </>
  );
};

export const WeightDischargeSurveyorsFieldGroup: FieldGroupComponent = ({ index }) => {
  const { t } = useTranslation('recapForm');
  const { getInputProps, masterdata } = useRecapFormContext();

  return (
    <>
      <TextInput
        label={t('labels.surveyorName')}
        placeholder={t('placeholders.name')}
        withAsterisk={false}
        {...getInputProps(`product.weight.dischargeSurveyors.${index}.name`)}
      />
      <Select
        label={t('labels.surveyorCost')}
        data={masterdata.surveyorCosts}
        defaultValue="AT_SELLERS_COST"
        grow={false}
        {...getInputProps(`product.weight.dischargeSurveyors.${index}.cost`)}
      />
      <TextInput
        label={t('labels.conditions')}
        placeholder={t('placeholders.conditions')}
        withAsterisk={false}
        {...getInputProps(`product.weight.dischargeSurveyors.${index}.conditions`)}
      />
    </>
  );
};

export const SamplingSurveyorNamesFieldGroup: FieldGroupComponent = ({ index }) => {
  const { t } = useTranslation('recapForm');
  const { getInputProps, masterdata } = useRecapFormContext();

  return (
    <>
      <TextInput
        label={t('labels.surveyorName')}
        placeholder={t('placeholders.name')}
        withAsterisk={false}
        {...getInputProps(`product.samplingAndAnalysis.surveyorNames.${index}.name`)}
      />
      <Select
        label={t('labels.surveyorCost')}
        data={masterdata.surveyorCosts}
        grow={false}
        {...getInputProps(`product.samplingAndAnalysis.surveyorNames.${index}.cost`)}
      />
      <TextInput
        label={t('labels.conditions')}
        placeholder={t('placeholders.conditions')}
        withAsterisk={false}
        {...getInputProps(`product.samplingAndAnalysis.surveyorNames.${index}.conditions`)}
      />
    </>
  );
};

export const SamplingSurveyorsAtDischargeFieldGroup: FieldGroupComponent = ({ index }) => {
  const { t } = useTranslation('recapForm');
  const { getInputProps, masterdata } = useRecapFormContext();

  return (
    <>
      <TextInput
        label={t('labels.surveyorName')}
        placeholder={t('placeholders.name')}
        withAsterisk={false}
        {...getInputProps(`product.samplingAndAnalysis.surveyorsAtDischargePort.${index}.name`)}
      />
      <Select
        label={t('labels.surveyorCost')}
        data={masterdata.surveyorCosts}
        grow={false}
        {...getInputProps(`product.samplingAndAnalysis.surveyorsAtDischargePort.${index}.cost`)}
      />
      <TextInput
        label={t('labels.conditions')}
        placeholder={t('placeholders.conditions')}
        withAsterisk={false}
        {...getInputProps(`product.samplingAndAnalysis.surveyorsAtDischargePort.${index}.conditions`)}
      />
    </>
  );
};

export const SamplingAnalysisFieldGroup: FieldGroupComponent = ({ index }) => {
  const { t } = useTranslation('recapForm');
  const { getInputProps, masterdata } = useRecapFormContext();

  return (
    <>
      <Select
        label={t('labels.type')}
        data={masterdata.analysisTypes}
        grow={false}
        defaultValue="MANUFACTURING_PLANT_LABORATORY"
        {...getInputProps(`product.samplingAndAnalysis.analysis.${index}.type`)}
      />
      <TextInput
        label={t('labels.name')}
        placeholder={t('placeholders.name')}
        withAsterisk={false}
        {...getInputProps(`product.samplingAndAnalysis.analysis.${index}.name`)}
      />
      <TextInput
        label={t('labels.conditions')}
        placeholder={t('placeholders.conditions')}
        withAsterisk={false}
        {...getInputProps(`product.samplingAndAnalysis.analysis.${index}.conditions`)}
      />
    </>
  );
};

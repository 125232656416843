import { z } from 'zod';

export const RateTerms = z.enum([
  'SSHINC',
  'FSHINC',
  'SSHEX_EIU',
  'SSHEX_UU',
  'SatNoonSHEX_EIU',
  'SatNoonSHEX_UU',
  'SHEX_EIU',
  'SHEX_UU',
  'SSHEX_EUI',
  'SHINC',
]);

export enum OfferType {
  FIRM = 'FIRM',
  TENTATIVE = 'TENTATIVE',
}
export const offerType = z.nativeEnum(OfferType);

export enum OfferPurpose {
  BUY = 'BUY',
  SELL = 'SELL',
}
export const offerPurpose = z.nativeEnum(OfferPurpose);

export enum OfferIncoterm {
  FOB = 'FOB',
  CFR = 'CFR',
}
export const offerIncoterm = z.nativeEnum(OfferIncoterm);

export const freightRequestFormSchema = z.object({
  priceEstimate: z.number().optional(),
  priceTarget: z.number().optional(),
  paymentConditions: z.string(),
  priceComments: z.string().optional(),
  productName: z.string(),
  productGrade: z.string().optional(),
  productPackaging: z.string().optional(),
  quantity: z.number(),
  toleranceMinPercentage: z.number(),
  toleranceMaxPercentage: z.number(),
  etaMin: z.string().datetime().optional(),
  etaMax: z.string().datetime().optional(),
  laycanMin: z.string().datetime().optional(),
  laycanMax: z.string().datetime().optional(),
  loadingPort: z.string(),
  loadingPortLocode: z.string(),
  loadingPortUnlocode: z.string(),
  loadingRate: z.number(),
  loadingGuaranteedDraft: z.number(),
  loadingGuaranteedLOA: z.number(),
  loadingGuaranteedBeam: z.number(),
  loadingTerms: RateTerms,
  loadingPortOtherRestrictions: z.string().optional(),
  dischargingPort: z.string(),
  dischargingPortLocode: z.string(),
  dischargingPortUnlocode: z.string(),
  dischargingRate: z.number(),
  dischargingGuaranteedDraft: z.number(),
  dischargingGuaranteedLOA: z.number(),
  dischargingGuaranteedBeam: z.number(),
  dischargingTerms: RateTerms,
  dischargingPortOtherRestrictions: z.string().optional(),
  offerType: offerType.optional(),
  offerPurpose: offerPurpose.optional(),
  offerIncoterm: offerIncoterm.optional(),
});

export type FreightRequestForm = z.infer<typeof freightRequestFormSchema>;

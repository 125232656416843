import { IconType } from 'react-icons';
import { ThemeIcon, MantineColor, ThemeIconProps } from '@mantine/core';

import { useIconWithBackgroundStyle } from './IconWithBackground.style';

interface IconWithBackgroundProps extends Partial<ThemeIconProps> {
  icon: IconType;
  color: MantineColor;
}

export const IconWithBackground = ({ icon: Icon, color, size, ...rest }: IconWithBackgroundProps) => {
  const { classes } = useIconWithBackgroundStyle({ color });

  return (
    <ThemeIcon className={classes.themeIcon} size={size} {...rest}>
      <Icon className={classes.icon} size={size} />
    </ThemeIcon>
  );
};

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';

import type {
  CreateDetailedOfferResponseDataDto,
  CreateCargoNeedAndOfferRequestDataDto,
  GetAllDetailedOffersInvitedResponseItemDto,
  UpdateDetailOfferParticipationResponseDTO,
  UpdateDetailOfferParticipationRequestDTO,
  GetDetailedOfferResponseDataDto,
  SaveDetailedOfferTemplateResponseDataDto,
  SaveDetailedOfferTemplateRequestDataDto,
  GetDetailedOfferTemplateResponseDto,
  GetDetailedOfferTemplateByIdResponseDto,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const createDetailOffer = (
  createCargoNeedAndOfferRequestDataDto: CreateCargoNeedAndOfferRequestDataDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CreateDetailedOfferResponseDataDto>> => {
  return axios.post(`/api/v2/detail-offer`, createCargoNeedAndOfferRequestDataDto, options);
};

export type CreateDetailOfferMutationResult = NonNullable<Awaited<ReturnType<typeof createDetailOffer>>>;
export type CreateDetailOfferMutationBody = CreateCargoNeedAndOfferRequestDataDto;
export type CreateDetailOfferMutationError = AxiosError<void>;

export const useCreateDetailOffer = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createDetailOffer>>,
    TError,
    { data: CreateCargoNeedAndOfferRequestDataDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createDetailOffer>>,
    { data: CreateCargoNeedAndOfferRequestDataDto }
  > = (props) => {
    const { data } = props ?? {};

    return createDetailOffer(data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof createDetailOffer>>,
    TError,
    { data: CreateCargoNeedAndOfferRequestDataDto },
    TContext
  >(mutationFn, mutationOptions);
};
export const getDetailOffersIAmInvitedTo = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetAllDetailedOffersInvitedResponseItemDto[]>> => {
  return axios.get(`/api/v2/detail-offer`, options);
};

export const getGetDetailOffersIAmInvitedToQueryKey = () => [`/api/v2/detail-offer`];

export type GetDetailOffersIAmInvitedToQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDetailOffersIAmInvitedTo>>
>;
export type GetDetailOffersIAmInvitedToQueryError = AxiosError<void>;

export const useGetDetailOffersIAmInvitedTo = <
  TData = Awaited<ReturnType<typeof getDetailOffersIAmInvitedTo>>,
  TError = AxiosError<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getDetailOffersIAmInvitedTo>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDetailOffersIAmInvitedToQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDetailOffersIAmInvitedTo>>> = ({ signal }) =>
    getDetailOffersIAmInvitedTo({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getDetailOffersIAmInvitedTo>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const updateDetailOfferParticipation = (
  detailOfferId: string,
  updateDetailOfferParticipationRequestDTO: UpdateDetailOfferParticipationRequestDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<UpdateDetailOfferParticipationResponseDTO>> => {
  return axios.patch(
    `/api/v2/detail-offer/participation/${detailOfferId}`,
    updateDetailOfferParticipationRequestDTO,
    options
  );
};

export type UpdateDetailOfferParticipationMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateDetailOfferParticipation>>
>;
export type UpdateDetailOfferParticipationMutationBody = UpdateDetailOfferParticipationRequestDTO;
export type UpdateDetailOfferParticipationMutationError = AxiosError<void>;

export const useUpdateDetailOfferParticipation = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateDetailOfferParticipation>>,
    TError,
    { detailOfferId: string; data: UpdateDetailOfferParticipationRequestDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateDetailOfferParticipation>>,
    { detailOfferId: string; data: UpdateDetailOfferParticipationRequestDTO }
  > = (props) => {
    const { detailOfferId, data } = props ?? {};

    return updateDetailOfferParticipation(detailOfferId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateDetailOfferParticipation>>,
    TError,
    { detailOfferId: string; data: UpdateDetailOfferParticipationRequestDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const getDetailOfferById = (
  detailOfferId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetDetailedOfferResponseDataDto>> => {
  return axios.get(`/api/v2/detail-offer/${detailOfferId}`, options);
};

export const getGetDetailOfferByIdQueryKey = (detailOfferId: string) => [`/api/v2/detail-offer/${detailOfferId}`];

export type GetDetailOfferByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getDetailOfferById>>>;
export type GetDetailOfferByIdQueryError = AxiosError<void>;

export const useGetDetailOfferById = <
  TData = Awaited<ReturnType<typeof getDetailOfferById>>,
  TError = AxiosError<void>
>(
  detailOfferId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getDetailOfferById>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDetailOfferByIdQueryKey(detailOfferId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDetailOfferById>>> = ({ signal }) =>
    getDetailOfferById(detailOfferId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getDetailOfferById>>, TError, TData>(queryKey, queryFn, {
    enabled: !!detailOfferId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const saveDetailOfferTemplate = (
  saveDetailedOfferTemplateRequestDataDto: SaveDetailedOfferTemplateRequestDataDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<SaveDetailedOfferTemplateResponseDataDto>> => {
  return axios.post(`/api/v2/detailed-offer-template`, saveDetailedOfferTemplateRequestDataDto, options);
};

export type SaveDetailOfferTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof saveDetailOfferTemplate>>>;
export type SaveDetailOfferTemplateMutationBody = SaveDetailedOfferTemplateRequestDataDto;
export type SaveDetailOfferTemplateMutationError = AxiosError<void>;

export const useSaveDetailOfferTemplate = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveDetailOfferTemplate>>,
    TError,
    { data: SaveDetailedOfferTemplateRequestDataDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof saveDetailOfferTemplate>>,
    { data: SaveDetailedOfferTemplateRequestDataDto }
  > = (props) => {
    const { data } = props ?? {};

    return saveDetailOfferTemplate(data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof saveDetailOfferTemplate>>,
    TError,
    { data: SaveDetailedOfferTemplateRequestDataDto },
    TContext
  >(mutationFn, mutationOptions);
};
export const getDetailOfferTemplate = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetDetailedOfferTemplateResponseDto[]>> => {
  return axios.get(`/api/v2/detailed-offer-template`, options);
};

export const getGetDetailOfferTemplateQueryKey = () => [`/api/v2/detailed-offer-template`];

export type GetDetailOfferTemplateQueryResult = NonNullable<Awaited<ReturnType<typeof getDetailOfferTemplate>>>;
export type GetDetailOfferTemplateQueryError = AxiosError<void>;

export const useGetDetailOfferTemplate = <
  TData = Awaited<ReturnType<typeof getDetailOfferTemplate>>,
  TError = AxiosError<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getDetailOfferTemplate>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDetailOfferTemplateQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDetailOfferTemplate>>> = ({ signal }) =>
    getDetailOfferTemplate({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getDetailOfferTemplate>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getDetailOfferTemplateById = (
  templateId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetDetailedOfferTemplateByIdResponseDto>> => {
  return axios.get(`/api/v2/detailed-offer-template/${templateId}`, options);
};

export const getGetDetailOfferTemplateByIdQueryKey = (templateId: string) => [
  `/api/v2/detailed-offer-template/${templateId}`,
];

export type GetDetailOfferTemplateByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getDetailOfferTemplateById>>>;
export type GetDetailOfferTemplateByIdQueryError = AxiosError<void>;

export const useGetDetailOfferTemplateById = <
  TData = Awaited<ReturnType<typeof getDetailOfferTemplateById>>,
  TError = AxiosError<void>
>(
  templateId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getDetailOfferTemplateById>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDetailOfferTemplateByIdQueryKey(templateId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDetailOfferTemplateById>>> = ({ signal }) =>
    getDetailOfferTemplateById(templateId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getDetailOfferTemplateById>>, TError, TData>(queryKey, queryFn, {
    enabled: !!templateId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

import { Button, Group, Stack, TextInput } from '@mantine/core';
import { useForm, UseFormReturnType, zodResolver } from '@mantine/form';
import { useTranslation } from 'next-i18next';
import { RiFileAddLine } from 'react-icons/ri';
import { z } from 'zod';

import { BackgroundIcon, BaseModal, Text } from '@/components';
import { DetailedOfferSchema, RecapForm } from '@/blink-forms';
import { useFreightRecapFormState } from '@/views/freight-recap-form';

import { useRecapFormContext } from '../state/RecapFormProvider';

const icon = <BackgroundIcon color="blue" icon={RiFileAddLine} size={20} />;

const schema = z.object({
  templateName: z.string().min(1),
});

type TemplateForm = z.infer<typeof schema>;

interface ContentProps {
  toggle: VoidFunction;
  onSaveTemplate: (values: TemplateForm) => void;
  isDetailedOffer: boolean;
}

const Content = (props: ContentProps) => {
  const { onSaveTemplate, toggle, isDetailedOffer } = props;
  const { t } = useTranslation('recapForm');
  const form = useForm<TemplateForm>({
    validate: zodResolver(schema),
  });

  const handleSubmitTemplate = (event) => {
    event.preventDefault();
    event.stopPropagation(); // This stops the event from bubbling up further to the parent form

    const { hasErrors } = form.validate();

    if (hasErrors) {
      return;
    }
    onSaveTemplate(form.values);
  };

  return (
    <form onSubmit={handleSubmitTemplate} noValidate>
      <Stack spacing={24}>
        <Text size="S" color="gray.6">
          {isDetailedOffer ? t('modals.saveTemplate.descriptionDetailedOffer') : t('modals.saveTemplate.description')}
        </Text>

        <TextInput
          label={t('labels.templateName')}
          placeholder={t('placeholders.templateName')}
          {...form.getInputProps('templateName')}
        />

        <Group grow>
          <Button onClick={toggle} variant="outline" size="md">
            {t('modals.saveTemplate.discard')}
          </Button>

          <Button type="button" size="md" onClick={handleSubmitTemplate}>
            {t('modals.saveTemplate.saveTemplate')}
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

interface SaveTemplateModalProps<T> {
  opened: boolean;
  form: UseFormReturnType<T>;
  toggle: VoidFunction;
  onSaveTemplate: (values: TemplateForm, recapForm: RecapForm) => void;
  isDetailedOffer?: boolean;
}

function SaveTemplateModal<T>(props: SaveTemplateModalProps<T>) {
  const { opened, form, toggle, onSaveTemplate, isDetailedOffer } = props;
  const { t } = useTranslation('recapForm');

  return (
    <BaseModal
      opened={opened}
      close={toggle}
      icon={icon}
      header={t('modals.saveTemplate.heading')}
      content={
        <Content
          toggle={toggle}
          onSaveTemplate={(values) => onSaveTemplate(values, form.values)}
          isDetailedOffer={isDetailedOffer}
        />
      }
    />
  );
}

export function SaveCargoTemplateModal<T>(props: Omit<SaveTemplateModalProps<T>, 'form'>) {
  const { form } = useRecapFormContext();

  return <SaveTemplateModal isDetailedOffer={false} {...props} form={form} />;
}

export function SaveFreightTemplateModal<T>(props: Omit<SaveTemplateModalProps<T>, 'form'>) {
  const { form } = useFreightRecapFormState();

  return <SaveTemplateModal isDetailedOffer={false} {...props} form={form} />;
}

export function SaveDetailedOfferTemplateModal<T>(props: Omit<SaveTemplateModalProps<T>, 'form'>) {
  const form = useForm<DetailedOfferSchema>();

  return <SaveTemplateModal isDetailedOffer={true} {...props} form={form} />;
}

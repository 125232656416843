import { Avatar as MdAvatar, AvatarProps as MdAvatarProps } from '@mantine/core';

interface AvatarProps extends Partial<MdAvatarProps> {
  src?: string;
}

export const Avatar = (props: AvatarProps) => {
  const { src, color, ...rest } = props;
  return !color ? (
    <MdAvatar src={src || '/images/company-placeholder-logo-new.svg'} {...rest} />
  ) : (
    <MdAvatar color={color} {...rest}>
      {' '}
    </MdAvatar>
  );
};

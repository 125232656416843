import { z } from 'zod';

import { FreightPeriodTerms, FreightTerms } from '../../types';
import { loadingDischargingConditionsGeneralInfo } from './loading-discharging-conditions-general-info';

export const dischargingConditions = loadingDischargingConditionsGeneralInfo.merge(
  z.object({
    dischargingRate: z.number(),
    dischargingTerms: z.object({
      terms: z.nativeEnum(FreightTerms).optional().nullable(),
      event: z.nativeEnum(FreightPeriodTerms).optional().nullable(),
    }),
  })
);

export type DischargingConditionsSchema = z.infer<typeof dischargingConditions>;

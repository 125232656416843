import { createStyles, Group } from '@mantine/core';
import { Dropzone, DropzoneProps } from '@mantine/dropzone';
import { ReactElement } from 'react';
import { RiCloseLine, RiFileLine, RiUpload2Line } from 'react-icons/ri';

const useStyles = createStyles((theme) => ({
  dropzone: {
    backgroundColor: theme.colors.gray[0],
    border: `1px dashed ${theme.colors.gray[4]}`,

    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },

    '&[data-accept]': {
      backgroundColor: theme.colors.blue[0],
      borderColor: theme.colors.blue[3],
    },

    '&[data-reject]': {
      backgroundColor: theme.colors.red[0],
      borderColor: theme.colors.red[3],
    },
  },
}));

export interface FilesDropzoneProps extends Omit<DropzoneProps, 'children' | 'content'> {
  content?: ReactElement;
  iconSize: number;
  height: number;
}

export function FilesDropzone(props: FilesDropzoneProps) {
  const { content, iconSize, height, ...rest } = props;
  const { classes, theme } = useStyles();

  return (
    <Dropzone className={classes.dropzone} radius="md" mt={8} padding={12} {...rest}>
      <Group position="center" spacing={16} sx={{ minHeight: height, pointerEvents: 'none' }}>
        <Dropzone.Accept>
          <RiUpload2Line size={iconSize} color={theme.colors.blue[5]} />
        </Dropzone.Accept>

        <Dropzone.Reject>
          <RiCloseLine size={iconSize} color={theme.colors.red[5]} />
        </Dropzone.Reject>

        <Dropzone.Idle>
          <RiFileLine size={iconSize} color={theme.colors.gray[5]} />
        </Dropzone.Idle>

        {content}
      </Group>
    </Dropzone>
  );
}

import { showNotification } from '@mantine/notifications';
import { FormErrors } from '@mantine/form';
import { useTranslation } from 'next-i18next';

interface ScrollToFirstErrorOptions {
  errors: FormErrors;
}
/**
 * Hook to provide a function to scroll to the first form error after validation.
 *
 * @param {string[]} additionalErrorClassnames Optional array of classnames for non-input error elements.
 * @returns  A function to invoke scrolling to the first detected error after form submission.
 */
export function useScrollToFirstError(additionalErrorClassnames = []) {
  const { t } = useTranslation('common');

  const scrollToFirstError = ({ errors }: ScrollToFirstErrorOptions) => {
    if (Object.keys(errors).length === 0) {
      return; // Exit if there are no errors.
    }

    // Wait for the DOM to be updated with error messages.
    setTimeout(() => {
      let firstVisibleError = document.querySelector('[aria-invalid="true"]');

      // If no invalid field found, try additional classnames.
      if (!firstVisibleError) {
        for (const className of additionalErrorClassnames) {
          firstVisibleError = document.querySelector(`.${className}`);
          if (firstVisibleError) break; // Found an error element.
        }
      }

      if (firstVisibleError) {
        firstVisibleError.scrollIntoView();
        window.scrollBy({ top: -240 }); // Optional adjustment.
      }
      showNotification({
        color: 'red',
        title: t('notifications.fixErrors'),
      });
    }, 0); // setTimeout with 0 to wait for the next tick when the DOM updates.
  };

  return { scrollToFirstError };
}

import { useTranslation } from 'next-i18next';
import { TextInput } from '@mantine/core';

import { GetInputProps } from '@/common/types';

interface ArbitrationAnalysisFieldGroupProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getInputProps: GetInputProps<any>;
  data?: any;
  masterdata?: any;
  namePath: string;
  conditionsPath: string;
}

export function ArbitrationAnalysisFieldGroup({
  getInputProps,
  namePath,
  conditionsPath,
}: ArbitrationAnalysisFieldGroupProps) {
  const { t } = useTranslation('tenderNegotiation');

  return (
    <>
      <TextInput
        label={t('labels.conditions')}
        placeholder={t('placeholders.conditions')}
        withAsterisk={false}
        {...getInputProps(conditionsPath)}
      />
      <TextInput
        label={t('labels.name')}
        placeholder={t('placeholders.name')}
        withAsterisk={false}
        {...getInputProps(namePath)}
      />
    </>
  );
}

import { z } from 'zod';

import { cargoTenderFormVolumeSchema } from './cargo-tender-volume';
import { cargoTenderFormGroupCommonSchema } from './cargo-tender-group';
import {
  DEFAULT_MIN_NUMBER_OF_DAYS_TENDER_CONTRACT_PERIOD,
  DEFAULT_MAX_NUMBER_OF_DAYS_TENDER_CONTRACT_PERIOD,
} from '../consts';
import { zDateRange } from '../types';
import { isDatePeriodInDaysRange } from '../validators';

export const cargoTenderParticipationGroupSchema = z
  .object({
    pricePreference: z.number().nullable(),
    totalVolumeForDateRange: z.number(),
  })
  .merge(cargoTenderFormGroupCommonSchema);

export const cargoTenderParticipationSchema = z
  .object({
    volumes: z.array(cargoTenderFormVolumeSchema),
    groups: z.array(cargoTenderParticipationGroupSchema),
    contractPeriod: zDateRange,
    offerSubmissionDeadline: z.date(),
    offerValidity: z.date(),
  })
  .refine(
    (data) => {
      if (data.contractPeriod[0] < data.offerSubmissionDeadline) {
        return false;
      }
      return true;
    },
    {
      message: 'Invalid date range: offerSubmissionDeadline  must be less than contractPeriodMin',
      path: ['offerSubmissionDeadline'],
    }
  )
  .refine(
    (data) => {
      if (data.contractPeriod[0] < data.offerValidity) {
        return false;
      }
      return true;
    },
    {
      message: 'Invalid date range: offerValidity must be less than contractPeriodMin',
      path: ['offerValidity'],
    }
  )
  .refine(
    (data) => {
      const [contractPeriodMin, contractPeriodMax] = data.contractPeriod ?? [];
      if (
        !isDatePeriodInDaysRange(
          contractPeriodMin,
          contractPeriodMax,
          DEFAULT_MIN_NUMBER_OF_DAYS_TENDER_CONTRACT_PERIOD,
          DEFAULT_MAX_NUMBER_OF_DAYS_TENDER_CONTRACT_PERIOD
        )
      ) {
        return false;
      }

      return true;
    },
    {
      message: 'Contract period has to be between 90 and 366 days',
      path: ['contractPeriod'],
    }
  );

export type CargoTenderParticipationFormGroupSchema = z.infer<typeof cargoTenderParticipationGroupSchema>;
export type CargoTenderParticipationForm = z.infer<typeof cargoTenderParticipationSchema>;

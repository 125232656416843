import { CompanyStatus } from 'api/generated/model';
import { BadgeProps } from '@mantine/core';

import { KYCVerifiedBadge } from './KYCVerifiedBadge';
import { OnboardingBadge } from './OnboardingBagde';
import { ShortlistedBadge } from './ShortlistedBadge';
import { PassiveBadge } from './PassiveBadge';
import { PrivateBadge } from './PrivateBadge';

interface StatusBlinkBadgeProps extends BadgeProps {
  status: CompanyStatus | string;
  maw?: BadgeProps['maw'];
  isShortlistedLabelShort?: boolean;
}

const mapCompanyStatus = (companyStatus) => {
  if (typeof companyStatus === 'string') {
    switch (companyStatus) {
      case 'KYC_VERIFIED':
      case 'verified':
        return CompanyStatus.verified;
      case 'unverified':
      case 'ONBOARDING':
        return CompanyStatus.unverified;
      case 'passive':
      case 'PASSIVE':
        return CompanyStatus.PASSIVE;
      case 'shortlisted':
      case 'SHORTLISTED':
        return CompanyStatus.SHORTLISTED;
      case 'private':
      case 'PRIVATE':
        return CompanyStatus.PRIVATE;
      default:
        return CompanyStatus.UNKNOWN;
    }
  }
  return companyStatus;
};

export function StatusBlinkBadge({ status, maw, isShortlistedLabelShort }: StatusBlinkBadgeProps) {
  const statusToCheck = mapCompanyStatus(status);
  switch (statusToCheck) {
    case CompanyStatus.verified:
      return <KYCVerifiedBadge />;
    case CompanyStatus.unverified:
      return <OnboardingBadge />;
    case CompanyStatus.PASSIVE:
      return <PassiveBadge />;
    case CompanyStatus.PRIVATE:
      return <PrivateBadge />;
    case CompanyStatus.SHORTLISTED:
      return <ShortlistedBadge maw={maw} shortLabel={isShortlistedLabelShort} />;
  }
}

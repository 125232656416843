import { z } from 'zod';

import { zDateRange } from '../../types';
import { MAX_MIN_TOLERANCE } from '../../consts';

export const productGeneralInfo = z.object({
  product: z.string().nonempty(),
  quantity: z.number().gt(0),
  minTolerance: z.number().gt(0).lt(MAX_MIN_TOLERANCE),
  maxTolerance: z.number().gt(0),
  originPlant: z.string().nonempty(),
  loadingPort: z.string().nonempty(),
  destinationPlant: z.string().nonempty(),
  dischargingPort: z.string().nonempty(),
  laycan: zDateRange.refine((val) => val !== undefined, {
    message: 'This field is required',
  }),
  productName: z.string().nullable(),
  originPlantName: z.string().optional(),
  loadingPortName: z.string().optional(),
  destinationPlantName: z.string().optional(),
  dischargingPortName: z.string().optional(),
});

export type ProductGeneralInfoSchema = z.infer<typeof productGeneralInfo>;

export enum DemurrageRateCondition {
  MAX = 'MAX',
  MIN = 'MIN',
}

export enum DemurrageRateEvent {
  FIXED_AT_RECAP = 'FIXED_AT_RECAP',
  TO_BE_ADVISED_WITH_VESSEL_NOMINATION = 'TO_BE_ADVISED_WITH_VESSEL_NOMINATION',
}

export enum DemurrageType {
  OPEN_ACCOUNT = 'OPEN_ACCOUNT',
  PROVISION_TO_SELLER = 'PROVISION_TO_SELLER',
  PROVISION_TO_TRUSTED_PARTY = 'PROVISION_TO_TRUSTED_PARTY',
}

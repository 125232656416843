import { z } from 'zod';
export const CargoWorkflowGlobalEventType = {
  FIXTURE_DATE: 'FIXTURE_DATE',
  FIRST_DAY_OF_LAYCAN: 'FIRST_DAY_OF_LAYCAN',
  LOADING_ARRIVAL_TIME: 'LOADING_ARRIVAL_TIME',
  LOADING_BERTHING_TIME: 'LOADING_BERTHING_TIME',
  LOADING_COMPLETION_TIME: 'LOADING_COMPLETION_TIME',
  DISCHARGING_ARRIVAL_TIME: 'DISCHARGING_ARRIVAL_TIME',
  DISCHARGING_BERTHING_TIME: 'DISCHARGING_BERTHING_TIME',
  DISCHARGING_COMPLETION_TIME: 'DISCHARGING_COMPLETION_TIME',
} as const;

export const cargoWorkflowGlobalEventType = z.nativeEnum(CargoWorkflowGlobalEventType);

export const WorkflowDocumentThresholdCondition = {
  BEFORE: 'BEFORE',
  AFTER: 'AFTER',
} as const;

export const workflowDocumentThresholdCondition = z.nativeEnum(WorkflowDocumentThresholdCondition);

export const taskThresholdConditionSchema = z.object({
  threshold: z.number().min(0).nullable().optional(),
  thresholdCondition: workflowDocumentThresholdCondition.nullable().optional(),
  eventCondition: cargoWorkflowGlobalEventType.nullable().optional(),
});

export const baseTaskSchema = taskThresholdConditionSchema.extend({
  name: z.string().min(1),
  shared: z.string().default('false'),
  threshold: z.number().min(0).nullable().optional(),
  thresholdCondition: workflowDocumentThresholdCondition.nullable().optional(),
  eventCondition: cargoWorkflowGlobalEventType.nullable().optional(),
  fileId: z.string().nullable().optional(),
  fileName: z.string().nullable().optional(),
  financiallySensitive: z.boolean(),
  assignedTo: z.string().nullable().optional(),
});

export type WorkflowDocumentForm = z.infer<typeof baseTaskSchema>;

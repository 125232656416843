import { Box, createStyles } from '@mantine/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { BookedCargoDTO } from 'api/generated/model';
import { CargoShipmentNextEventBadge } from 'components/cargo-shipment-status-badge/CargoShipmentNextEventBadge';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { Table } from '@/components';

import { BookedCargoTableActionFunction } from '../../types';
import {
  BookedCargoIdRefIdCell,
  BookedCompanyNameCell,
  BookedLinkedEntitiesCell,
  MyCargosBookedTableActionCell,
  NextEventDateCell,
  BookedVesselNameCell,
} from './MyCargosBookedTableCells';

const useStyles = createStyles((theme) => ({
  box: {
    position: 'relative',
    backgroundColor: theme.white,
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[2]}`,
  },
}));

const columnHelper = createColumnHelper<BookedCargoDTO>();

interface UseColumnsProps {
  onRowClick: BookedCargoTableActionFunction;
  onLinkCargoClick: BookedCargoTableActionFunction;
  onArchiveCargoClick: BookedCargoTableActionFunction;
  onDuplicateCargoClick: BookedCargoTableActionFunction;
}

function useColumns({ onRowClick, onLinkCargoClick, onArchiveCargoClick, onDuplicateCargoClick }: UseColumnsProps) {
  const { t } = useTranslation('myCargos');

  const columns: ColumnDef<BookedCargoDTO>[] = useMemo(
    () => [
      columnHelper.accessor('shortId', {
        header: t('table.headers.cargoIdRefId'),
        enableSorting: true,
        cell: BookedCargoIdRefIdCell,
      }),
      columnHelper.accessor('vesselName', {
        header: t('table.headers.vesselName'),
        enableSorting: true,
        cell: BookedVesselNameCell,
      }),
      columnHelper.accessor('counterpartyName', {
        header: t('table.headers.companyName'),
        enableSorting: true,
        cell: BookedCompanyNameCell,
      }),
      columnHelper.accessor('globalEventsTransactionSide.cargoShipmentStatus', {
        header: t('table.headers.status'),
        enableSorting: true,
        cell: ({ getValue }) => <CargoShipmentNextEventBadge status={getValue()} />,
      }),
      // @TODO: remove this dummy check after fixing/migrating all cargos without "GlobalEvents"
      columnHelper.accessor((cargo) => (cargo.nextEvent?.date ? 'nextEvent.date' : null), {
        header: t('table.headers.nextEventDate'),
        enableSorting: true,
        sortingFn: (a, b) => {
          const timeA = a.original.nextEvent?.date ? new Date(a.original.nextEvent?.date).getTime() : 0;
          const timeB = b.original.nextEvent?.date ? new Date(b.original.nextEvent?.date).getTime() : 0;

          if (timeA > timeB) return 1;
          if (timeA < timeB) return -1;

          return 0;
        },
        cell: NextEventDateCell,
      }),
      columnHelper.accessor('linkedWorkflowShortId', {
        header: t('table.headers.linkedEntities'),
        enableSorting: true,
        cell: BookedLinkedEntitiesCell,
      }),
      columnHelper.accessor('id', {
        header: '',
        enableSorting: false,
        cell: (props) => (
          <MyCargosBookedTableActionCell
            {...props}
            onRowClick={onRowClick}
            onLinkCargoClick={onLinkCargoClick}
            onArchiveCargoClick={onArchiveCargoClick}
            onDuplicateCargoClick={onDuplicateCargoClick}
          />
        ),
      }),
    ],
    [t, onRowClick, onLinkCargoClick]
  );

  return columns;
}
export interface MyCargosTableBookedProps {
  data: BookedCargoDTO[];
  isLoading: boolean;
  onRowClick: BookedCargoTableActionFunction;
  onLinkCargoClick: BookedCargoTableActionFunction;
  onArchiveCargoClick: BookedCargoTableActionFunction;
  onDuplicateCargoClick: BookedCargoTableActionFunction;
}

export function MyCargosBookedTable(props: MyCargosTableBookedProps) {
  const { classes } = useStyles();
  const { data, isLoading, onRowClick, onLinkCargoClick, onArchiveCargoClick, onDuplicateCargoClick } = props;
  const columns = useColumns({ onRowClick, onLinkCargoClick, onArchiveCargoClick, onDuplicateCargoClick });

  return (
    <Box className={classes.box}>
      <Table.Card isWideOnMobile>
        <Table data={data} columns={columns} isLoading={isLoading} onRowClick={onRowClick} />
      </Table.Card>
    </Box>
  );
}

import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  stepContainer: {
    borderRadius: theme.radius.md,
    backgroundColor: theme.white,
    padding: 24,
    width: '100%',
  },
  text: {
    alignSelf: 'flex-end',
    color: theme.colors.gray[7],
  },
  companyText: {
    color: theme.colors.gray[7],
    marginBottom: 24,
  },
  stack: {
    width: '100%',
    gap: 28,
  },
  anchor: {
    fontWeight: 600,
    paddingBottom: 4,
    borderBottom: '1px solid',
  },
  termsAndCondition: {
    fontWeight: 600,
  },
  button: {
    marginTop: 24,
  },
  backButton: {
    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },
}));

export function useSharedRegistrationStyles() {
  return useStyles();
}

export enum MarketIntelligenceTab {
  //TODO: Uncomment the following once we develop the overview --> OVERVIEW = 'OVERVIEW',
  DATA_HUB = 'DATA_HUB',
  NEWS_AND_INSIGHTS = 'NEWS_AND_INSIGHTS',
  REPORTS_AND_ANALYSES = 'REPORTS_AND_ANALYSES',
}

export enum MarketReportSectionType {
  SUBTITLE = 'SUBTITLE',
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
}

import { ReactNode } from 'react';
import { Box, createStyles } from '@mantine/core';

const useDrawerStyles = createStyles({
  drawer: {
    paddingBottom: '0 !important', // mantine overrides value, even if it is not set via props
  },
  body: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

export function useDrawerWithFooterClasses() {
  return useDrawerStyles().classes;
}

export interface DrawerFooterProps {
  children: ReactNode;
  drawerPadding?: number;
}

/**
 * To correclty use this component,
 * you need to set use `useDrawerWithFooterClasses` hook and add its classes to Drawer component
 **/
export function DrawerFooter(props: DrawerFooterProps) {
  const { children, drawerPadding } = props;

  return (
    <Box
      sx={(theme) => ({
        position: 'sticky',
        bottom: 0,
        marginTop: 'auto',
        marginLeft: -drawerPadding,
        marginRight: -drawerPadding,
        backgroundColor: '#fff',
        padding: `12px ${drawerPadding}px`,
        zIndex: 1000,
        borderTop: `solid 1px ${theme.colors.gray[2]}`,
      })}
    >
      {children}
    </Box>
  );
}

import { Stack } from '@mantine/core';
import { CellContext, ColumnDefTemplate } from '@tanstack/react-table';

import { Text } from '@/components';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface DischargingDetailsCellProps extends CellContext<any, number> {
  rate: number;
  terms: string;
}

export const DischargingDetailsCell: ColumnDefTemplate<DischargingDetailsCellProps> = ({ rate, terms }) => {
  return (
    <Stack spacing={2}>
      <Text size="S" color="gray.7">
        {`${rate} mts/day`}
      </Text>
      <Text size="S" color="gray.7">
        {terms}
      </Text>
    </Stack>
  );
};

import { Stack } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { ConsumptionPaymentDTO } from 'api/generated/model';

import { Text } from '@/components';
import { decimalToPercentage } from '@/views/tenders/utils/percentage';

interface ConsumptionPaymentProps {
  consumptionPayment: ConsumptionPaymentDTO;
}

export const ConsumptionPaymentLabel = ({ consumptionPayment }: ConsumptionPaymentProps) => {
  const { t } = useTranslation('tenderContract');

  return (
    <Stack spacing={0}>
      <Text weight="bold">{t(`labels.consumptionPayment`)}</Text>
      <ConsumptionPaymentText consumptionPayment={consumptionPayment} />
    </Stack>
  );
};

export const ConsumptionPaymentText = ({ consumptionPayment }: ConsumptionPaymentProps) => {
  const { t } = useTranslation('tenderContract');

  return (
    <Text>
      {t(`valuesFormatted.consumptionPaymentValue`, {
        days: consumptionPayment.days,
        event: t(`valuesFormatted.${consumptionPayment.event}`),
        numberOfInstallments: consumptionPayment.numberOfInstallments,
        percentage: decimalToPercentage(consumptionPayment.percentage),
      })}
    </Text>
  );
};

import { Badge, BadgeProps } from '@mantine/core';
import { forwardRef } from 'react';

import { useIsMobile } from '@/common/use-is-mobile';

export interface ResponsiveBadgeProps extends BadgeProps {
  mobileSize?: BadgeProps['size'];
}

export const ResponsiveBadge = forwardRef<HTMLDivElement, ResponsiveBadgeProps>(function ResponsiveBadge(props, ref) {
  const { size, mobileSize = 'xs', ...rest } = props;
  const isMobile = useIsMobile('sm');

  return <Badge ref={ref} size={isMobile ? mobileSize : size} {...rest} />;
});

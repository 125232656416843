import { NumberInput, NumberInputProps } from '@mantine/core';

function isNumeric(n: string | undefined) {
  return n !== undefined && n !== '' && !isNaN(+n) && !n.endsWith('.');
}

function getBasePercentageProps(precision = 1) {
  const toFraction = (value: string) => `${+value / 100}`;

  const toPercentage = (value: string, precision: number) => {
    const percentage = (+value * 100).toFixed(precision);
    return parseFloat(percentage).toString();
  };

  return {
    parser: (value: string | undefined) => {
      const number = value?.replace(',', '.');
      return isNumeric(number) ? toFraction(number) : number;
    },
    formatter: (value: string | undefined) => {
      const number = value?.replace(',', '.');
      return isNumeric(number) ? toPercentage(number, precision) : number;
    },
    precision: precision + 2,
    step: 10 ** (-1 * precision),
    rightSection: '%',
  };
}

export function PercentageField(props: NumberInputProps) {
  return <NumberInput {...getBasePercentageProps()} w={70} {...props} />;
}

import React, { useState, ReactElement } from 'react';
import { createStyles, TableProps as MantineTableProps } from '@mantine/core';
import {
  Row,
  ColumnDef,
  SortingState,
  TableOptions,
  useReactTable,
  ExpandedState,
  getCoreRowModel,
  getSortedRowModel,
  getExpandedRowModel,
} from '@tanstack/react-table';

import { BaseTable, BaseTableCard, RenderBasicRow } from './BaseTable';

const useStyles = createStyles((theme) => ({
  hiddenOnMobile: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
      pointerEvents: 'none',
    },
  },
  rowWithTable: {
    backgroundColor: theme.colors.blue[1],
    width: '100%',
    transformOrigin: '0 0',
    padding: '24px !important',

    [theme.fn.smallerThan('md')]: {
      padding: '8px !important',
    },

    'thead tr': {
      height: 'unset',
      backgroundColor: theme.colors.blue[0],
      borderBottom: `1px solid ${theme.colors.blue[2]}`,
    },
  },
  rowExpandedNoHighlight: {
    borderTop: 'none !important',
    backgroundColor: theme.colors.white,
  },
}));

export interface TableProps<TData, TValue> extends MantineTableProps {
  data: TData[];
  isLoading: boolean;
  bgColorRow?: string;
  hideHeader?: boolean;
  disableHover?: boolean;
  isUniqueRowId?: boolean;
  bgColorActiveRow?: string;
  bgColorExpandedRow?: string;
  paddingExpandedRow?: string;
  rowsExpanded?: ExpandedState;
  rowExpandedNoHighlight?: boolean;
  columns: ColumnDef<TData, TValue>[];
  onRowClick?: (row: Row<TData>) => void;
  renderExpandedRow?: (row: Row<TData>) => ReactElement;
  tableOptions?: Omit<TableOptions<TData>, 'data' | 'columns' | 'getCoreRowModel'>;
}

export function Table<TData extends { id: string }, TValue>(props: TableProps<TData, TValue>) {
  const { classes } = useStyles();
  const {
    data,
    columns,
    isLoading,
    onRowClick,
    bgColorRow,
    tableOptions,
    isUniqueRowId,
    bgColorActiveRow,
    rowsExpanded = {},
    paddingExpandedRow,
    bgColorExpandedRow,
    hideHeader = false,
    disableHover = false,
    rowExpandedNoHighlight,
    renderExpandedRow: RenderExpandedRow,
    ...mantineTableProps
  } = props;

  const [sorting, setSorting] = useState<SortingState>([]);
  const [expanded, setExpanded] = useState<ExpandedState>(rowsExpanded);

  const getRowIdConfig = isUniqueRowId
    ? {
        getRowId: (row) => row.id,
      }
    : {};

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
      sorting,
    },
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    ...(tableOptions || {}),
    ...getRowIdConfig,
  });

  return (
    <BaseTable
      table={table}
      isLoading={isLoading}
      hideHeader={hideHeader}
      renderRow={({ row }) => (
        <>
          <RenderBasicRow
            row={row}
            data-hover={!disableHover}
            disableHover={disableHover}
            onClick={() => onRowClick && onRowClick(row)}
            rowExpandedNoHighlight={rowExpandedNoHighlight}
            style={{ backgroundColor: bgColorRow || (row.getIsExpanded() && bgColorActiveRow) }}
          />
          {RenderExpandedRow && row.getIsExpanded() && (
            <tr>
              <td
                colSpan={columns.length}
                style={{ backgroundColor: bgColorExpandedRow, padding: paddingExpandedRow }}
                className={rowExpandedNoHighlight ? classes.rowExpandedNoHighlight : classes.rowWithTable}
              >
                <RenderExpandedRow key={`${row.id}.expanded`} {...row} />
              </td>
            </tr>
          )}
        </>
      )}
      {...mantineTableProps}
    />
  );
}

Table.Card = BaseTableCard;

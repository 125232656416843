import { Group, MantineColor, Paper, Stack, ThemeIcon, createStyles } from '@mantine/core';
import { ReactElement } from 'react';
import { IconType } from 'react-icons/lib';

import { Text } from '@/components';

const useStyles = createStyles((theme, params: Pick<CardInfoProps, 'iconColor'>) => ({
  themeIcon: {
    borderRadius: theme.radius.md,
    backgroundColor: params.iconColor === 'gray' ? theme.colors.gray[2] : theme.colors[params.iconColor][1],
    border: `1px solid ${params.iconColor === 'gray' ? theme.colors.gray[4] : theme.colors[params.iconColor][2]}`,
    color: params.iconColor === 'gray' ? theme.colors.gray[8] : theme.colors[params.iconColor][6],
  },
}));

export interface CardInfoProps {
  cardColor: MantineColor;
  iconColor: MantineColor;
  text: ReactElement | string;
  icon: IconType;
  actionComponent: ReactElement;
  subtext?: string;
}

export function CardInfo(props: CardInfoProps) {
  const { iconColor, text, cardColor, icon: Icon, actionComponent, subtext } = props;
  const { classes } = useStyles({ iconColor });

  return (
    <Paper
      sx={(theme) => ({
        backgroundColor: theme.colors[cardColor][0],
        border: `1px solid ${theme.colors[cardColor][1]}`,
      })}
      px={8}
      py={12}
    >
      <Group noWrap>
        <ThemeIcon className={classes.themeIcon} w={40} h={40} sx={{ flexShrink: 0 }}>
          <Icon size={20} />
        </ThemeIcon>
        <Stack spacing={0}>
          {typeof text === 'string' ? (
            <Text size="XS" weight={600} color="gray.9" sx={{ flexGrow: 1 }}>
              {text}
            </Text>
          ) : (
            text
          )}
          {subtext && (
            <Text size="XS" color="gray.7">
              {subtext}
            </Text>
          )}
        </Stack>
        {actionComponent}
      </Group>
    </Paper>
  );
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoWorkflowGlobalEventsUpdateSide =
  (typeof CargoWorkflowGlobalEventsUpdateSide)[keyof typeof CargoWorkflowGlobalEventsUpdateSide];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoWorkflowGlobalEventsUpdateSide = {
  BLINK: 'BLINK',
  SELF: 'SELF',
  BUYER: 'BUYER',
  SELLER: 'SELLER',
  AIS: 'AIS',
} as const;

import { Button, Group, ThemeIcon, Tooltip, useMantineTheme } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { RiMailSendLine, RiTimeLine, RiTimerFlashLine } from 'react-icons/ri';

import { Text } from '@/components';
import { formatDate, formatTime } from '@/common/format-date';
import { useCalculateExpireTimeInInterval } from '@/common/use-expire-time-left';

export interface ExpireBadgeProps {
  expiresAt: string;
  onClick: VoidFunction;
  isSeller: boolean;
  isRequested: boolean;
}

export function ExpireBadge(props: ExpireBadgeProps) {
  const { expiresAt, onClick, isRequested, isSeller } = props;
  const { t } = useTranslation('common');
  const theme = useMantineTheme();
  const { expired, expiresSoon, timeLeft } = useCalculateExpireTimeInInterval(expiresAt);

  let backgroundColor = 'transparent';
  let icon = <RiTimeLine color={theme.colors.gray[5]} />;
  if (expiresSoon) {
    backgroundColor = theme.colors.red[0];
    icon = <RiTimerFlashLine color={theme.colors.red[9]} />;
  }
  if (expired) {
    backgroundColor = theme.colors.gray[1];
    icon = <RiTimeLine color={theme.colors.gray[6]} />;
  }

  const isDisabled = isRequested && !isSeller;

  const tooltipLabel = expired
    ? t('labels.expiredButtonTooltip')
    : t(`labels.${isSeller ? 'extendButtonTooltip' : 'expireButtonTooltip'}`, {
        date: formatDate(expiresAt),
        time: formatTime(expiresAt),
      });

  return (
    <Group spacing={4}>
      <Tooltip withArrow arrowSize={8} multiline width={240} label={tooltipLabel} disabled={isDisabled}>
        <Button
          size="xs"
          variant="subtle"
          leftIcon={icon}
          px={8}
          sx={(theme) => ({
            height: 'auto',
            color: theme.colors.gray[7],
            fontSize: 14,
            lineHeight: '22px',
            fontWeight: 600,
            backgroundColor,
            '&:hover': {
              backgroundColor,
            },
          })}
          styles={{ label: { height: 'auto' } }}
          disabled={isDisabled}
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
        >
          <Text
            sx={{
              overflowWrap: 'break-word',
              whiteSpace: 'break-spaces',
              width: 'fit-content',
            }}
          >
            {expired ? t('labels.expired') : t('labels.expiresIn', { timeLeft })}
          </Text>
        </Button>
      </Tooltip>
      {isRequested && (
        <Tooltip label={t('tooltips.extensionRequested')} withArrow>
          <ThemeIcon color="orange" variant="light">
            <RiMailSendLine color="orange.6" />
          </ThemeIcon>
        </Tooltip>
      )}
    </Group>
  );
}

/* eslint-disable no-restricted-imports */
// eslint-disable-next-line no-restricted-imports
import { MantineTheme, createStyles } from '@mantine/core';

import { TypographySize } from './types';

const getTextStyles = (size: TypographySize, theme: MantineTheme) => {
  switch (size) {
    case 'XXL':
      return {
        fontSize: 24,
        lineHeight: '32px',
      };
    case 'XL':
      return {
        fontSize: 20,
        lineHeight: '28px',
      };
    case 'L': {
      return {
        fontSize: 18,
        lineHeight: '26px',
      };
    }
    case 'M':
      return {
        fontSize: 16,
        lineHeight: '24px',

        [theme.fn.smallerThan('xs')]: {
          fontSize: 14,
          lineHeight: '22px',
        },
      };
    case 'S':
      return {
        fontSize: 14,
        lineHeight: '22px',

        [theme.fn.smallerThan('xs')]: {
          fontSize: 12,
          lineHeight: '18px',
        },
      };
    case 'XS':
      return {
        fontSize: 12,
        lineHeight: '18px',
      };
  }
};

interface UseTypographyStyleProps {
  size: TypographySize;
}

export const useTypographyStyle = createStyles((theme, { size }: UseTypographyStyleProps) => ({
  text: {
    cursor: 'inherit',
    ...getTextStyles(size, theme),
  },
}));

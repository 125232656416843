import { ReactNode } from 'react';
import { Box, Flex, createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  container: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 101,
    backgroundColor: theme.white,
    borderTop: `1px solid ${theme.colors.gray[3]}`,

    [theme.fn.largerThan('md')]: {
      left: 'var(--mantine-navbar-width, 56px)',
    },
  },
}));

export interface FixedActionBarProps {
  children: ReactNode;
}

export function FixedActionBar({ children }: FixedActionBarProps) {
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      <Flex maw={1232} py={12} px={24} wrap="wrap" gap={24} mx="auto">
        {children}
      </Flex>
    </Box>
  );
}

import { Box, createStyles } from '@mantine/core';
import { ReactNode } from 'react';

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',

    [theme.fn.largerThan('md')]: {
      gridTemplateColumns: '1fr auto 1fr',
    },
  },
  divider: {
    border: 0,
    borderTopWidth: 1,
    borderColor: theme.colors.gray[1],
    borderStyle: 'solid',
    margin: '24px 0',

    [theme.fn.largerThan('md')]: {
      height: 'auto',
      borderTopWidth: 0,
      borderLeftWidth: 1,
      margin: '0 24px',
    },
  },
}));

interface TwoColumnsLayoutProps {
  children: [ReactNode, ReactNode];
}

export function TwoColumnsLayout(props: TwoColumnsLayoutProps) {
  const { children } = props;
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      {children[0]}
      <Box className={classes.divider} />
      {children[1]}
    </Box>
  );
}

import { ReactNode } from 'react';
import { RiAlertLine } from 'react-icons/ri';

import { Text } from '@/components';

export interface InlineErrorProps {
  children: ReactNode;
}

export function InlineError({ children }: InlineErrorProps) {
  return (
    <Text color="red">
      <RiAlertLine display="inline-block" style={{ transform: 'translateY(2px)' }} /> {children}
    </Text>
  );
}

import { Badge, Button, createStyles, Flex, Group, Loader, Modal, Stack } from '@mantine/core';
import { useGetCargoNegotiationHistory } from 'api/generated/cargo-negotiation/cargo-negotiation';
import { useGetCargoNeed } from 'api/generated/cargo/cargo';
import { CargoNeedDTO, CargoTransportation, NegotiationStatus } from 'api/generated/model';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { formatDate } from '@/common/format-date';
import { formatterThousand } from '@/common/get-thousand-seperated-number';
import { transformToPercentage } from '@/common/percentage-transform';
import { routes } from '@/common/routes';
import { Text, Title } from '@/components';

const useStyles = createStyles((theme) => ({
  header: {
    backgroundColor: theme.colors.gray[1],
    borderBottom: '1px solid',
    borderColor: theme.colors.gray[2],
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    margin: 0,
    padding: 24,
    height: 72,
  },
  body: {
    maxWidth: 814,
    padding: 24,
  },
}));

const ReviewRequestTitle = () => {
  return (
    <Title size="M" weight={600} color="gray.9">
      New request
    </Title>
  );
};

export interface LabeledValueProps {
  label: string;
  value: string | number;
}

const LabeledValue = (props: LabeledValueProps) => {
  const { label, value } = props;
  const { t } = useTranslation('myCargos');
  return (
    <Group spacing={4}>
      <Text size="M" weight={600}>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {t(`negotiationDetails.${label}` as any)}
      </Text>
      <Text size="M">{value}</Text>
    </Group>
  );
};

interface RequestNegotiationRequestProps {
  data: Partial<CargoNeedDTO>;
  navigateToRecapForm: VoidFunction;
  offeredTransport: CargoTransportation;
  hideOfferSendButton: boolean;
}

const RequestNegotiationContent = (props: RequestNegotiationRequestProps) => {
  const { data, navigateToRecapForm, offeredTransport, hideOfferSendButton } = props;
  const { t, i18n } = useTranslation('myCargos');

  return (
    <>
      <Stack>
        <Group spacing={8}>
          <Text size="M" weight={600}>
            {t(`negotiationDetails.incoterm`)}
          </Text>
          <Badge size="sm" color="green">
            {offeredTransport}
          </Badge>
        </Group>
        <LabeledValue label="companyName" value={data.companyName} />
        <LabeledValue label="commodity" value={`${data.productName} in ${data.productPackaging}`} />
        <LabeledValue label="quality" value={data.productQuality} />
        <LabeledValue label="quantity" value={formatterThousand(data.quantity)} />
        <LabeledValue
          label="tolerance"
          value={`+${transformToPercentage(data.toleranceMaxPercentage)}%, -${transformToPercentage(
            data.toleranceMinPercentage
          )}%`}
        />
        <LabeledValue label="destinationPlant" value={data.plantName} />
        <LabeledValue label="dischargingPort" value={data.dischargingPortName} />
        <LabeledValue label="dischargingRate" value={data.loadingRate} />
        <LabeledValue label="dischargingTerms" value={data.loadingRateTerm} />
        <LabeledValue label="eta" value={`${formatDate(data.etaMin)} - ${formatDate(data.etaMax)}`} />
        {/* TODO: Add voyage duration from tracking api */}
        <LabeledValue label="voyageDuration" value={t('negotiationDetails.voyageInDays', { voyageDuration: 10 })} />
      </Stack>
      <Flex>
        {!hideOfferSendButton && (
          <Button
            size="md"
            mt={36}
            data-testid={'createOffer'}
            sx={{ marginLeft: 'auto' }}
            onClick={navigateToRecapForm}
          >
            {t('negotiationDetails.createOffer')}
          </Button>
        )}
      </Flex>
    </>
  );
};

export interface OfferDetailsModalProps {
  negotiationId: string;
  opened: boolean;
  toggle: VoidFunction;
  isHistoryStep: boolean;
}

export function OfferDetailsModal(props: OfferDetailsModalProps) {
  const { opened, toggle, negotiationId, isHistoryStep } = props;
  const { classes } = useStyles();
  const router = useRouter();
  const negotiation = useGetCargoNegotiationHistory(negotiationId, {
    query: { select: (response) => response.data },
  });
  const status = negotiation.data?.negotiationHistory?.[0].status;
  const offeredTransport = negotiation.data?.lookingFor;
  const needId = negotiation.data?.needId;
  const offerId = negotiation.data?.offerId;

  const navigateToRecapForm = () => router.push(routes.RecapForm({ needId, offerId, offeredTransport, negotiationId }));
  const cargoNeed = useGetCargoNeed(needId, { query: { select: (response) => response.data } });

  return (
    <Modal
      opened={opened}
      title={<ReviewRequestTitle />}
      onClose={toggle}
      padding={0}
      size="100%"
      sx={{ maxWidth: 814, marginLeft: 'auto', marginRight: 'auto' }}
      classNames={{ header: classes.header, body: classes.body }}
    >
      {cargoNeed.isLoading || negotiation.isLoading ? (
        <Stack align="center">
          <Loader size="xl" my={24} mx="auto" data-testid={'loader'} />
        </Stack>
      ) : (
        <RequestNegotiationContent
          data={cargoNeed.data}
          hideOfferSendButton={status === NegotiationStatus.RFQ_SENT || isHistoryStep}
          offeredTransport={offeredTransport}
          navigateToRecapForm={navigateToRecapForm}
        />
      )}
    </Modal>
  );
}

import { Group } from '@mantine/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import {
  CargoNegotiationDTO,
  CargoNegotiationExpiryExtendRequestStatus,
  ChatGroupUnreadMessagesDTO,
} from 'api/generated/model';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { CargoIdCell, ExpireBadge, NegotiationActionsCell, NegotiationTableBadge } from '@/components';
import { OnExpireBadgeFunction } from '@/views/cargo-cancelation/types';
import { OfferType } from '@/views/my-cargos';
import { isTimerVisible } from '@/common/negotiation-status';

import { ProspectNegotiationActions } from '../types';

interface UseProspectColumnsProps extends ProspectNegotiationActions {
  type: OfferType;
  onExpireBadgeClick: OnExpireBadgeFunction;
  chatGroupUnreadMessages: ChatGroupUnreadMessagesDTO[];
}
export const useNegotiationsColumns = () => {
  const { t } = useTranslation('cargoDetails');
  const columnHelper = createColumnHelper<CargoNegotiationDTO>();

  const useProspectColumns = ({
    type,
    onCancelNegotiationsClick,
    onNegotiationHistoryClick,
    onNegotiationsDetailsClick,
    onExtendClick,
    onRequestExtensionClick,
    onExpireBadgeClick,
    onChatIconClick,
    onEnablePrivateDataForCSM,
    chatGroupUnreadMessages,
  }: UseProspectColumnsProps) => {
    const columns: ColumnDef<CargoNegotiationDTO>[] = useMemo(
      () => [
        columnHelper.accessor('companyName', {
          header: t('table.headers.companyName'),
          enableSorting: true,
          cell: CargoIdCell,
        }),
        columnHelper.accessor('lookingFor', {
          header: t('table.headers.lookingFor'),
          enableSorting: true,
        }),
        columnHelper.accessor('lastStep.status', {
          header: t('table.headers.status'),
          enableSorting: true,
          cell: (props) => <NegotiationTableBadge {...props} />,
        }),
        columnHelper.accessor('id', {
          header: t('table.headers.expires'),
          enableSorting: true,
          cell: ({ row }) => {
            const isRequested =
              row.original.expiryExtendRequest[0]?.status === CargoNegotiationExpiryExtendRequestStatus.REQUESTED;
            return (
              row.original.lastStep.expiresAt &&
              isTimerVisible(row.original.lastStep.status) && (
                <Group spacing={4}>
                  <ExpireBadge
                    expiresAt={row.original.lastStep.expiresAt}
                    isRequested={isRequested}
                    onClick={() => onExpireBadgeClick({ type, negotiationId: row.original.id, isRequested })}
                    isSeller={type === 'offer'}
                  />
                </Group>
              )
            );
          },
        }),
        columnHelper.accessor('cargoNeedId', {
          header: '',
          enableSorting: false,
          cell: (props) => (
            <NegotiationActionsCell
              offerType={type}
              onNegotiationHistoryClick={onNegotiationHistoryClick}
              onNegotiationsDetailsClick={onNegotiationsDetailsClick}
              onCancelNegotiationsClick={onCancelNegotiationsClick}
              onExtendClick={onExtendClick}
              onRequestExtensionClick={onRequestExtensionClick}
              onChatIconClick={onChatIconClick}
              onEnablePrivateDataForCSM={onEnablePrivateDataForCSM}
              chatGroupUnreadMessages={chatGroupUnreadMessages}
              {...props}
            />
          ),
        }),
      ],
      [
        type,
        onNegotiationHistoryClick,
        onNegotiationsDetailsClick,
        onCancelNegotiationsClick,
        onChatIconClick,
        onExpireBadgeClick,
        onExtendClick,
        onRequestExtensionClick,
        onEnablePrivateDataForCSM,
        chatGroupUnreadMessages,
      ]
    );

    return columns;
  };

  return {
    useProspectColumns,
  };
};

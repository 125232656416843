import { FlexProps } from '@mantine/core';

import { BaseError } from './BaseError';

export function Error500(props: FlexProps) {
  return <BaseError code={500} {...props} />;
}

export function Error404(props: FlexProps) {
  return <BaseError code={404} {...props} />;
}

export function Error403(props: FlexProps) {
  return <BaseError code={403} {...props} />;
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type GetAllCargoOfferServicesForSellersSubscribed =
  (typeof GetAllCargoOfferServicesForSellersSubscribed)[keyof typeof GetAllCargoOfferServicesForSellersSubscribed];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAllCargoOfferServicesForSellersSubscribed = {
  true: true,
  false: false,
} as const;

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoTenderOtherContractClauseDTOClauseType =
  (typeof CargoTenderOtherContractClauseDTOClauseType)[keyof typeof CargoTenderOtherContractClauseDTOClauseType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoTenderOtherContractClauseDTOClauseType = {
  OTHER: 'OTHER',
  NOTICES: 'NOTICES',
  LIABILITY: 'LIABILITY',
  FORCE_MAJEURE: 'FORCE_MAJEURE',
  CONFIDENTIALITY: 'CONFIDENTIALITY',
  TAXES_AND_DUTIES: 'TAXES_AND_DUTIES',
  FINAL_HOLD_CLEANING: 'FINAL_HOLD_CLEANING',
  IMPORT_EXPORT_LICENSES: 'IMPORT_EXPORT_LICENSES',
  ANTIBRIVERY_COMPLIANCE: 'ANTIBRIVERY_COMPLIANCE',
  INSURANCE_TITLE_AND_RISK: 'INSURANCE_TITLE_AND_RISK',
  MEDIATION_AND_ARBITRATION: 'MEDIATION_AND_ARBITRATION',
  AMENDMENT_WAIVER_ASSIGNMENT: 'AMENDMENT_WAIVER_ASSIGNMENT',
  EARLY_TERMINATION_AND_EFFECTS: 'EARLY_TERMINATION_AND_EFFECTS',
  WARRANTIES_AND_LIMITATION_OF_WARRANTIES: 'WARRANTIES_AND_LIMITATION_OF_WARRANTIES',
} as const;

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoOfferOfferType = (typeof CargoOfferOfferType)[keyof typeof CargoOfferOfferType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoOfferOfferType = {
  SHARE_NEED: 'SHARE_NEED',
  DETAILED_OFFER: 'DETAILED_OFFER',
  SHARE_AVAILABILITY: 'SHARE_AVAILABILITY',
} as const;

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type GetWorkflowTaskResponseDtoMilestone =
  (typeof GetWorkflowTaskResponseDtoMilestone)[keyof typeof GetWorkflowTaskResponseDtoMilestone];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetWorkflowTaskResponseDtoMilestone = {
  FIXTURE: 'FIXTURE',
  ORIGIN_COUNTRY: 'ORIGIN_COUNTRY',
  SAILING: 'SAILING',
  DESTINATION_COUNTRY: 'DESTINATION_COUNTRY',
} as const;

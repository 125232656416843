import { useEffect, useRef } from 'react';

function preventDefault(e) {
  // allow to scroll messages
  if (!e.target?.closest || !e.target?.closest('.sendbird-conversation__messages')) {
    e.preventDefault();
  }
}

export function usePreventScrolling() {
  const timeOutRef = useRef<ReturnType<typeof setTimeout>>(null);

  const lock = () => {
    // hide elements that take space
    document.querySelectorAll('[data-chat-hide]').forEach((element: HTMLElement) => {
      element.style['display'] = 'none';
    });

    // prevent scrollbar scrolling
    window.addEventListener('scroll', preventDefault);

    timeOutRef.current = setTimeout(() => {
      // with delay (let keyboard to be open):
      document.querySelectorAll('[data-chat-ui]').forEach((element: HTMLElement) => {
        // 1. Set height chat ui to current viewport
        element.style['height'] = `${window.innerHeight}px`;

        // 2. Lock chat ui in fixed position
        element.style['position'] = 'fixed';
        element.style['bottom'] = '0';
        element.style['top'] = '0';
        element.style['flex'] = '0';

        // 3. Set height of body to viewport and hidden overflow - shrink scrollable space
        document.body.style['height'] = `${window.innerHeight}px`;
        document.body.style['overflow'] = 'hidden';

        // 4. Block touch moving event to avoid overflowed iphone spaces
        element.addEventListener('touchmove', preventDefault);
        element.addEventListener('pointermove', preventDefault);

        // 5. Make sure that chat ui is in viewport
        window.scrollTo(0, 0);
      });
    }, 200);
  };

  const unlock = () => {
    // 1. Reset all styling and remove listeners
    document.querySelectorAll('[data-chat-hide]').forEach((element: HTMLElement) => {
      element.style['display'] = null;
    });

    document.querySelectorAll('[data-chat-ui]').forEach((element: HTMLElement) => {
      element.style['height'] = null;
      element.style['position'] = null;
      element.style['bottom'] = null;
      element.style['top'] = null;
      element.style['flex'] = null;

      document.body.style['height'] = null;
      document.body.style['overflow'] = null;

      element.removeEventListener('touchmove', preventDefault);
      element.removeEventListener('pointermove', preventDefault);
    });

    window.removeEventListener('scroll', preventDefault);

    // 2. Reset scroll to top
    window.scrollTo(0, 0);
    clearTimeout(timeOutRef.current);
  };

  useEffect(
    () => () => {
      unlock();
    },
    []
  );

  return [lock, unlock];
}

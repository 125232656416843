import { z } from 'zod';

import { FreightPeriodTerms, FreightTerms } from '../../types';
import { loadingDischargingConditionsGeneralInfo } from './loading-discharging-conditions-general-info';

export const loadingConditions = loadingDischargingConditionsGeneralInfo.merge(
  z.object({
    loadingRate: z.number(),
    loadingTerms: z.object({
      terms: z.nativeEnum(FreightTerms).optional().nullable(),
      event: z.nativeEnum(FreightPeriodTerms).optional().nullable(),
    }),
  })
);

export type LoadingConditionsSchema = z.infer<typeof loadingConditions>;

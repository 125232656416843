import { Box, Button, createStyles } from '@mantine/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { CanceledCargoDTO, UserRole } from 'api/generated/model';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { useRouter } from 'next/router';

import { EtaCell, HideForbiddenElement, QuantityCell, Table, Text } from '@/components';
import { routes } from '@/common/routes';
import { MyCargoTab } from '@/views/my-cargos';

const useStyles = createStyles((theme) => ({
  box: {
    position: 'relative',
    backgroundColor: theme.white,
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[2]}`,
  },
}));

const columnHelper = createColumnHelper<CanceledCargoDTO>();

function useColumns(activeTab: MyCargoTab) {
  const router = useRouter();
  const { t } = useTranslation('myCargos');

  const columns: ColumnDef<CanceledCargoDTO>[] = useMemo(
    () => [
      columnHelper.accessor('shortId', {
        header: t('table.headers.cargoId'),
        enableSorting: true,
      }),
      columnHelper.accessor('material', {
        header: t('table.headers.commodity'),
        enableSorting: true,
      }),
      columnHelper.accessor('quantity', {
        header: t('table.headers.quantity'),
        enableSorting: true,
        cell: QuantityCell,
      }),
      activeTab === MyCargoTab.Sales
        ? columnHelper.accessor('loadingPort', {
            header: t('table.headers.loadingPort'),
            enableSorting: true,
          })
        : columnHelper.accessor('dischargingPort', {
            header: t('table.headers.dischargingPort'),
            enableSorting: true,
          }),
      columnHelper.accessor('laycanMin', {
        header: t('table.headers.laycan'),
        enableSorting: true,
        cell: (props) =>
          props.row.original.laycanMin && props.row.original.laycanMax ? (
            <EtaCell {...props} minDate={props.row.original.laycanMin} maxDate={props.row.original.laycanMax} />
          ) : (
            <Text>-</Text>
          ),
      }),
      columnHelper.accessor('laycanMax', {
        header: '',
        enableSorting: false,
        cell: (props) => (
          <HideForbiddenElement allowedRoles={[UserRole.ADMIN, UserRole.BUYER, UserRole.SELLER]}>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                router.push(
                  props.row.original.type === 'request'
                    ? routes.CargoNeedDetails(props.row.original.id)
                    : routes.CargoOfferDetails(props.row.original.id)
                );
              }}
              variant="outline"
            >
              {t('table.buttons.viewCargo')}
            </Button>
          </HideForbiddenElement>
        ),
      }),
    ],
    [t]
  );

  return columns;
}

export interface MyCargosCanceledTableProps {
  data: CanceledCargoDTO[];
  isLoading: boolean;
  activeTab: MyCargoTab;
}

export function MyCargosCanceledTable(props: MyCargosCanceledTableProps) {
  const { classes } = useStyles();
  const { data, isLoading, activeTab } = props;
  const columns = useColumns(activeTab);

  return (
    <Box className={classes.box}>
      <Table.Card>
        <Table data={data} columns={columns} isLoading={isLoading} />
      </Table.Card>
    </Box>
  );
}

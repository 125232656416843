import { z } from 'zod';
import { useForm, zodResolver } from '@mantine/form';
import { RiErrorWarningFill } from 'react-icons/ri';
import { Radio, Stack, Textarea } from '@mantine/core';

import { useTranslation } from '@/common/i18n';

import { Alert } from '../../alerts/Alert/Alert';
import { Typography } from '../../typography/Typography/Typography';
import { IconWithBackground } from '../../icons/IconWithBackground/IconWithBackground';
import { FlexibleConfirmationModal } from '../shared/FlexibleConfirmationModal/FlexibleConfirmationModal';

export enum CancelReasonEnum {
  OTHER = 'OTHER',
  MARKETPLACE = 'MARKETPLACE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  OTHER_CHANNELS = 'OTHER_CHANNELS',
  TECHNICAL_ISSUES = 'TECHNICAL_ISSUES',
}

const creatorCancelPromotedCargoModalSchema = z.object({
  otherReason: z.string().optional().nullable(),
  reason: z.union([z.nativeEnum(CancelReasonEnum), z.null()]),
});

export type CreatorCancelPromotedCargoModalSchema = z.infer<typeof creatorCancelPromotedCargoModalSchema>;

export interface CreatorCancelPromotedCargoModalProps {
  opened: boolean;
  onClose: VoidFunction;
  onConfirm: (params: { reason: string; otherReason: string }) => void;
}

export const CreatorCancelPromotedCargoModal = ({
  opened,
  onClose,
  onConfirm,
}: CreatorCancelPromotedCargoModalProps) => {
  const { t } = useTranslation('common');

  const handleClose = () => {
    onClose();
  };

  const form = useForm<CreatorCancelPromotedCargoModalSchema>({
    validate: zodResolver(creatorCancelPromotedCargoModalSchema),
    initialValues: {
      reason: null,
      otherReason: null,
    },
  });

  const { values } = form;
  const { reason, otherReason } = values;

  const handleSubmit = ({ reason, otherReason }: CreatorCancelPromotedCargoModalSchema) => {
    onConfirm({ reason, otherReason });
  };

  const cancelPromotedCargoModalContent = () => {
    return (
      <Stack spacing={25} align="center" justify="center">
        <Alert
          icon={RiErrorWarningFill}
          content={<Typography size="S">{t('modal.cargos.cancelCargoModal.descriptionCreator')}</Typography>}
        />
        <form style={{ width: '100%' }} onSubmit={form.onSubmit(handleSubmit)}>
          <Stack spacing="md">
            <Radio.Group
              required
              value={reason}
              onChange={(value) => form.setFieldValue('reason', value as CancelReasonEnum)}
            >
              <Stack>
                <Radio value="MARKETPLACE" label={t('modal.cargos.cancelCargoModal.reasons.marketplace')} />
                <Radio value="OTHER_CHANNELS" label={t('modal.cargos.cancelCargoModal.reasons.otherChannels')} />
                <Radio value="NOT_AVAILABLE" label={t('modal.cargos.cancelCargoModal.reasons.notAvailable')} />
                <Radio value="TECHNICAL_ISSUES" label={t('modal.cargos.cancelCargoModal.reasons.technicalIssues')} />
                <Radio value="OTHER" label={t('modal.cargos.cancelCargoModal.reasons.other')} />
              </Stack>
            </Radio.Group>
            {reason === 'OTHER' && (
              <Textarea
                {...form.getInputProps('otherReason')}
                required
                placeholder={t('modal.cargos.cancelCargoModal.reasons.otherReason')}
              />
            )}
          </Stack>
        </form>
      </Stack>
    );
  };

  const disableConfirmButton =
    reason === null ||
    (reason === 'OTHER' && (otherReason === null || (otherReason !== null && otherReason.trim() === '')));

  return (
    <FlexibleConfirmationModal
      size="lg"
      opened={opened}
      titleCentralized
      onCancel={handleClose}
      cancelLabel={t('buttons.cancel')}
      confirmLabel={t('buttons.cancelCargo')}
      onConfirmDisabled={disableConfirmButton}
      content={cancelPromotedCargoModalContent()}
      onConfirm={() => form.onSubmit(handleSubmit)()}
      header={t('modal.cargos.cancelCargoModal.titleCreator')}
      icon={<IconWithBackground size={50} color="orange" icon={RiErrorWarningFill} />}
    />
  );
};

import { Anchor, Button, Container, Radio, Stack, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { Trans, useTranslation } from 'next-i18next';
import { ReactNode, useMemo } from 'react';

import { NeedHelp, PhoneNumber, Text } from '@/components';
import { getSelectPhonePrefixData, radios, stepZeroInputs } from '@/views/registration/data';

import { RegisterForm } from '../types';
import { useSharedRegistrationStyles } from './use-shared-registration-styles';

export interface StepZeroProps {
  form: UseFormReturnType<RegisterForm, undefined>;
  next: VoidFunction;
}

export function StepZero(props: StepZeroProps) {
  const { t, i18n } = useTranslation('register');
  const { form, next } = props;
  const { classes } = useSharedRegistrationStyles();

  const phonePrefixes = useMemo(() => getSelectPhonePrefixData(i18n.language), [i18n.language]);

  return (
    <Container className={classes.stepContainer}>
      <form
        noValidate={true}
        onSubmit={form.onSubmit(() => {
          if (form.validate().hasErrors) return;
          next();
        })}
      >
        <Stack className={classes.stack}>
          <Text className={classes.text} size="S">
            <Trans t={t} i18nKey="labels.alreadyRegistered">
              Already have an account?
              <Anchor className={classes.anchor} href="/auth/signin" underline={false} />
            </Trans>
          </Text>

          {stepZeroInputs.map(({ label, placeholder }) => (
            <TextInput
              size="md"
              key={label}
              label={t(`labels.${label}`) as ReactNode}
              placeholder={t(`placeholders.${placeholder}`)}
              data-testid={label}
              withAsterisk
              {...form.getInputProps(label, { withError: true })}
            />
          ))}

          <PhoneNumber
            label={t('labels.contactPhoneNumber')}
            placeholder={t('placeholders.contactPhoneNumber')}
            withAsterisk
            data={phonePrefixes}
            countrySelectorProps={{
              placeholder: t('placeholders.phoneNumberPrefix'),
              ...form.getInputProps('contactPhoneNumberPrefix', { withError: true }),
              size: 'md',
            }}
            controlTextInput={{
              ...form.getInputProps('contactPhoneNumber'),
              size: 'md',
            }}
          />

          <Radio.Group
            spacing="xl"
            withAsterisk
            name="companyType"
            label={t('labels.radio.label')}
            {...form.getInputProps('companyType')}
          >
            {radios.map(({ value, translationKey }) => (
              <Radio key={value} value={value} label={t(`labels.radio.${translationKey}`)} />
            ))}
          </Radio.Group>

          <Button className={classes.button} type="submit" size="lg" fullWidth>
            {t('labels.next')}
          </Button>

          <NeedHelp />
        </Stack>
      </form>
    </Container>
  );
}

import { ActionIcon, Button, Group, useMantineTheme } from '@mantine/core';
import { CellContext, ColumnDef, ColumnDefTemplate, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { RiDeleteBin7Line } from 'react-icons/ri';
import { ContractTemplateListItemDTO } from 'api/generated/model';

import { formatDate, formatTime } from '@/common/format-date';
import { CargoIdCell, Table, Text } from '@/components';

import { ActionsFunction } from '../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DateCell: ColumnDefTemplate<CellContext<any, string>> = ({ getValue }) => {
  return <Text size="M" color="gray.7">{`${formatDate(getValue())} at ${formatTime(getValue())}`}</Text>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ActionsCellProps extends CellContext<any, string> {
  onDelete: ActionsFunction;
  onUseTemplate: ActionsFunction;
}

const ActionsCell: ColumnDefTemplate<ActionsCellProps> = ({ row, onDelete, onUseTemplate }) => {
  const { t } = useTranslation('recapForm');
  const theme = useMantineTheme();

  return (
    <Group spacing={16}>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          onUseTemplate(row);
        }}
      >
        {t('buttons.useTemplate')}
      </Button>
      <ActionIcon
        variant="transparent"
        onClick={(e) => {
          e.stopPropagation();
          onDelete(row);
        }}
      >
        <RiDeleteBin7Line color={theme.colors.gray[6]} />
      </ActionIcon>
    </Group>
  );
};

const columnHelper = createColumnHelper<ContractTemplateListItemDTO>();

interface useColumnsProps {
  onDelete: ActionsFunction;
  onUseTemplate: ActionsFunction;
}

function useColumns({ onDelete, onUseTemplate }: useColumnsProps) {
  const { t } = useTranslation('recapForm');

  const columns: ColumnDef<ContractTemplateListItemDTO>[] = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: t('modals.loadTemplate.table.templateName'),
        cell: CargoIdCell,
      }),
      columnHelper.accessor('createdAt', {
        header: t('modals.loadTemplate.table.createdAt'),
        cell: DateCell,
      }),
      columnHelper.accessor('id', {
        header: '',
        enableSorting: false,
        cell: (props) => <ActionsCell {...props} onDelete={onDelete} onUseTemplate={onUseTemplate} />,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return columns;
}

export interface LoadTemplateTableProps {
  data: ContractTemplateListItemDTO[];
  isLoading: boolean;
  onDelete: ActionsFunction;
  onUseTemplate: ActionsFunction;
}

export function LoadTemplateTable(props: LoadTemplateTableProps) {
  const { data, isLoading, onDelete, onUseTemplate } = props;
  const columns = useColumns({ onDelete, onUseTemplate });

  return (
    <Table.Card bordered>
      <Table columns={columns} data={data} isLoading={isLoading} />
    </Table.Card>
  );
}

import { useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { useDisclosure } from '@mantine/hooks';
import { CargoTransportation } from 'api/generated/model';
import { RiFileAddLine, RiFileMarkLine } from 'react-icons/ri';
import { Box, Group, Button, Accordion, createStyles } from '@mantine/core';

import { useRecapFormContext } from '@/views/recap-form';
import { useScrollToFirstError } from '@/common/use-scroll-to-first-error';
import { Title, Avatar, FixedActionBar } from '@/components';

import { FrontendRecapForm } from '../types';
import {
  EditorActions,
  OtherFormGroup,
  PaymentFormGroup,
  PricingFormGroup,
  ProductFormGroup,
  RecapPreviewModal,
  RecapPreviewModalContent,
  RecapPreviewDefaultTitle,
  LawJurisdictionFormGroup,
} from '../components';
import { RecapFromPromotionExtension } from './RecapFormPromotingExtension';

const useStyles = createStyles((theme) => ({
  smHidden: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },
  smGrow: {
    [theme.fn.smallerThan('sm')]: {
      flexGrow: 1,
    },
  },
}));

interface RecapFormContainerProps {
  buyer: string;
  seller: string;
  companyLogo: string;
  companyName: string;
  submitLabel?: string;
  isSubmitting: boolean;
  sellerCompanyId: string;
  buyerCompanyId?: string;
  onSaveDraft?: VoidFunction;
  subheader?: React.ReactNode;
  isNewCargoBehavior?: boolean;
  disabledSubmiting?: boolean;
  prevForm?: FrontendRecapForm;
  onSaveTemplate?: VoidFunction;
  mapFormValues?: (form: FrontendRecapForm) => FrontendRecapForm;
  onSubmit: (form: FrontendRecapForm, selectedIncoterm: CargoTransportation) => void;
}

export function RecapFormContainer(props: RecapFormContainerProps) {
  const { t } = useTranslation('recapForm');

  const {
    buyer,
    seller,
    onSubmit,
    prevForm,
    subheader,
    companyLogo,
    companyName,
    onSaveDraft,
    isSubmitting,
    buyerCompanyId,
    onSaveTemplate,
    sellerCompanyId,
    disabledSubmiting,
    isNewCargoBehavior = false,
    mapFormValues = (form) => form,
    submitLabel = t('buttons.send'),
  } = props;

  const isDraftable = Boolean(onSaveDraft);
  const areTemplatesAvailable = Boolean(onSaveTemplate);

  const { classes } = useStyles();
  const { scrollToFirstError } = useScrollToFirstError(['paymentConditionsError']);
  const { form, selectedIncoterm } = useRecapFormContext();
  const [previewOpened, { toggle: togglePreviewOpened }] = useDisclosure(false);

  const submitBtn = useRef<HTMLButtonElement>();

  const handleSaveDraft = () => {
    if (form.isDirty() && onSaveDraft) {
      onSaveDraft();
    }
  };

  /**
   * Handles form submission with validation and conditional data transformation.
   *
   * Transforms empty payment condition objects to `undefined` for validation, reverting them if validation fails.
   * This ensures optional fields do not cause validation errors, while maintaining form state consistency.
   *
   * @param {Event} event - The form submission event.
   * @remarks Necessary to balance form field optionality with consistency across different form implementations.
   * @note It's required to perform this transformation manually to avoid causing errors in other schemas or forms
   * that use this provider, ensuring compatibility and preventing unintended side effects.
   */
  const handleSubmit = async (event) => {
    event.preventDefault();

    const transformedKeys = [];

    const transformPaymentConditions = (conditions) => {
      Object.entries(conditions).forEach(([key, value]) => {
        if (value && Object.keys(value).length === 0) {
          conditions[key] = undefined;
          transformedKeys.push(key);
        }
      });
    };

    if (form.values.payment && form.values.payment.conditions) {
      transformPaymentConditions(form.values.payment.conditions);
    }

    const { errors, hasErrors } = form.validate();

    if (!hasErrors) {
      const recapFormValues = mapFormValues(form.values);
      return onSubmit(recapFormValues, selectedIncoterm);
    } else {
      transformedKeys.forEach((key) => {
        if (form.values.payment && form.values.payment.conditions) {
          form.values.payment.conditions[key] = {};
        }
      });
    }

    scrollToFirstError({ errors });
  };

  return (
    <>
      <form id="recapForm" onSubmit={handleSubmit}>
        <Group spacing={24}>
          <Avatar size={72} radius="sm" src={companyLogo} alt="Company logo" />
          <Title size="2XL">{companyName}</Title>
        </Group>
        {subheader}
        <Accordion
          mt={40}
          multiple
          variant="separated"
          defaultValue={['pricing', 'product', 'payment', 'law', 'other']}
        >
          <Accordion.Item value="pricing">
            <Accordion.Control>{t('sections.pricing')}</Accordion.Control>
            <Accordion.Panel>
              <PricingFormGroup />
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="product">
            <Accordion.Control>{t('sections.product')}</Accordion.Control>
            <Accordion.Panel>
              <ProductFormGroup />
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="payment">
            <Accordion.Control>{t('sections.payment')}</Accordion.Control>
            <Accordion.Panel>
              <PaymentFormGroup />
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="law">
            <Accordion.Control>{t('sections.lawJurisdiction')}</Accordion.Control>
            <Accordion.Panel>
              <LawJurisdictionFormGroup />
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="other">
            <Accordion.Control>{t('sections.other')}</Accordion.Control>
            <Accordion.Panel>
              <OtherFormGroup />
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <RecapFromPromotionExtension isNewCargoBehavior={isNewCargoBehavior} />

        <FixedActionBar>
          {isDraftable && (
            <Button
              p={0}
              variant="subtle"
              onClick={handleSaveDraft}
              className={classes.smHidden}
              leftIcon={<RiFileMarkLine />}
            >
              {t('buttons.saveAsDraft')}
            </Button>
          )}
          {areTemplatesAvailable && (
            <Button
              p={0}
              variant="subtle"
              onClick={onSaveTemplate}
              className={classes.smHidden}
              leftIcon={<RiFileAddLine />}
            >
              {t('buttons.saveAsTemplate')}
            </Button>
          )}
          <Button variant="outline" className={classes.smGrow} onClick={togglePreviewOpened}>
            {t('buttons.checkPreview')}
          </Button>
          <Button
            type="submit"
            ref={submitBtn}
            data-testid={'recapSend'}
            className={classes.smGrow}
            sx={{ marginLeft: 'auto' }}
            disabled={disabledSubmiting}
            loading={Boolean(isSubmitting)}
          >
            {submitLabel}
          </Button>
        </FixedActionBar>
      </form>
      <RecapPreviewModal
        opened={previewOpened}
        onClose={togglePreviewOpened}
        title={<RecapPreviewDefaultTitle />}
        actions={
          <EditorActions
            onBackToEdit={togglePreviewOpened}
            onSend={() => {
              togglePreviewOpened();
              submitBtn.current.click();
            }}
          />
        }
        recapForm={
          <Box
            p={24}
            sx={{
              flexGrow: 1,
              maxHeight: '100%',
              overflowY: 'scroll',
            }}
          >
            <RecapPreviewModalContent
              buyer={buyer}
              seller={seller}
              prevForm={prevForm}
              buyerCompanyId={buyerCompanyId}
              sellerCompanyId={sellerCompanyId}
              offeredTransport={selectedIncoterm}
              currentForm={mapFormValues(form.values)}
            />
          </Box>
        }
      />
    </>
  );
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import type { UseMutationOptions, MutationFunction } from '@tanstack/react-query';

import type { ApiErrorResponse, RegisterDTO, ConfirmEmailDto, UserRegistrationDto } from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const register = (registerDTO: RegisterDTO, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/v1/registration`, registerDTO, options);
};

export type RegisterMutationResult = NonNullable<Awaited<ReturnType<typeof register>>>;
export type RegisterMutationBody = RegisterDTO;
export type RegisterMutationError = AxiosError<ApiErrorResponse>;

export const useRegister = <TError = AxiosError<ApiErrorResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof register>>, TError, { data: RegisterDTO }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof register>>, { data: RegisterDTO }> = (props) => {
    const { data } = props ?? {};

    return register(data, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof register>>, TError, { data: RegisterDTO }, TContext>(
    mutationFn,
    mutationOptions
  );
};
export const confirmEmail = (
  confirmEmailDto: ConfirmEmailDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/v1/registration/email-confirmation`, confirmEmailDto, options);
};

export type ConfirmEmailMutationResult = NonNullable<Awaited<ReturnType<typeof confirmEmail>>>;
export type ConfirmEmailMutationBody = ConfirmEmailDto;
export type ConfirmEmailMutationError = AxiosError<unknown>;

export const useConfirmEmail = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof confirmEmail>>, TError, { data: ConfirmEmailDto }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof confirmEmail>>, { data: ConfirmEmailDto }> = (props) => {
    const { data } = props ?? {};

    return confirmEmail(data, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof confirmEmail>>, TError, { data: ConfirmEmailDto }, TContext>(
    mutationFn,
    mutationOptions
  );
};
export const postSignUpUser = (
  userRegistrationDto: UserRegistrationDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<unknown>> => {
  return axios.post(`/api/v1/registration/user`, userRegistrationDto, options);
};

export type PostSignUpUserMutationResult = NonNullable<Awaited<ReturnType<typeof postSignUpUser>>>;
export type PostSignUpUserMutationBody = UserRegistrationDto;
export type PostSignUpUserMutationError = AxiosError<ApiErrorResponse>;

export const usePostSignUpUser = <TError = AxiosError<ApiErrorResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSignUpUser>>,
    TError,
    { data: UserRegistrationDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postSignUpUser>>, { data: UserRegistrationDto }> = (
    props
  ) => {
    const { data } = props ?? {};

    return postSignUpUser(data, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof postSignUpUser>>, TError, { data: UserRegistrationDto }, TContext>(
    mutationFn,
    mutationOptions
  );
};

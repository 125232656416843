import { Button, Group } from '@mantine/core';
import { BaseModal } from 'components/base-modal/BaseModal';
import { useTranslation } from 'next-i18next';
import { RiTimeLine } from 'react-icons/ri';

import { BackgroundIcon, Text } from '@/components';

export interface BaseExtensionActionsProps {
  outlineLabel: string;
  primaryLabel: string;
  outlineAction: VoidFunction;
  primaryAction: VoidFunction;
  loading?: boolean;
}

const BaseExtensionActions = (props: BaseExtensionActionsProps) => {
  const { outlineLabel, outlineAction, primaryLabel, primaryAction, loading } = props;
  return (
    <Group grow position="apart">
      <Button variant="outline" onClick={outlineAction}>
        {outlineLabel}
      </Button>
      <Button onClick={primaryAction} loading={loading}>
        {primaryLabel}
      </Button>
    </Group>
  );
};

export interface ExtensionContentProps {
  toggle: VoidFunction;
  onConfirm: VoidFunction;
  loading: boolean;
  onReject?: VoidFunction;
  isRequested?: boolean;
}

const RequestExtensionContent = (props: ExtensionContentProps) => {
  const { t } = useTranslation('common');
  const { toggle, onConfirm, loading } = props;

  return (
    <>
      <Text color="gray.7" size="M" align="center">
        {t('modals.extend.descriptions.request')}
      </Text>
      <BaseExtensionActions
        outlineLabel={t('modals.extend.labels.cancel')}
        primaryLabel={t('modals.extend.labels.confirm')}
        outlineAction={toggle}
        loading={loading}
        primaryAction={onConfirm}
      />
    </>
  );
};

const GrantExtensionContent = (props: ExtensionContentProps) => {
  const { t } = useTranslation('common');
  const { toggle, onConfirm, loading, onReject, isRequested } = props;
  const outlineAction = () => {
    if (onReject) {
      onReject();
    }
    toggle();
  };

  return (
    <>
      <Text color="gray.7" size="M" align="center">
        {t('modals.extend.descriptions.grant')}
      </Text>
      <BaseExtensionActions
        outlineLabel={isRequested ? t('modals.extend.labels.reject') : t('modals.extend.labels.cancel')}
        primaryLabel={t('modals.extend.labels.accept')}
        outlineAction={() => (isRequested ? outlineAction() : toggle())}
        primaryAction={onConfirm}
        loading={loading}
      />
    </>
  );
};

export interface ExtendModalProps {
  opened: boolean;
  toggle: VoidFunction;
  loading: boolean;
  onConfirm: VoidFunction;
  isRequested?: boolean;
  onReject?: VoidFunction;
}

export function RequestExtendModal(props: ExtendModalProps) {
  const { opened, toggle, onConfirm, loading } = props;
  const { t } = useTranslation('common');

  return (
    <BaseModal
      icon={<BackgroundIcon icon={RiTimeLine} color={'blue'} size={20} />}
      opened={opened}
      close={toggle}
      header={t('modals.extend.headers.request')}
      content={<RequestExtensionContent toggle={toggle} onConfirm={onConfirm} loading={loading} />}
      zIndex={1000}
    />
  );
}

export function GrantExtendModal(props: ExtendModalProps) {
  const { opened, toggle, onConfirm, loading, onReject, isRequested } = props;
  const { t } = useTranslation('common');

  return (
    <BaseModal
      zIndex={1000}
      icon={<BackgroundIcon icon={RiTimeLine} color={'blue'} size={20} />}
      opened={opened}
      close={toggle}
      header={t('modals.extend.headers.grant')}
      content={
        <GrantExtensionContent
          toggle={toggle}
          onConfirm={onConfirm}
          loading={loading}
          onReject={onReject}
          isRequested={isRequested}
        />
      }
    />
  );
}

import { Box, Indicator, useMantineTheme } from '@mantine/core';
import { BiMessageRounded } from 'react-icons/bi';

export interface ChatIconProps {
  onChatIconClick: () => void;
  unreadMessages: number;
}

export function ChatIcon(props: ChatIconProps) {
  const { onChatIconClick, unreadMessages } = props;
  const theme = useMantineTheme();
  const hasUnreadMessages = unreadMessages > 0;

  return (
    <Indicator
      label={unreadMessages}
      size={20}
      color={hasUnreadMessages ? 'red.9' : 'gray.8'}
      sx={{ lineHeight: '18px', zIndex: 0 }}
    >
      <Box sx={{ transform: 'translateY(2px)' }}>
        <BiMessageRounded
          color={hasUnreadMessages ? theme.colors.primary[1] : theme.colors.gray[5]}
          size={32}
          onClick={onChatIconClick}
        />
      </Box>
    </Indicator>
  );
}

import { Anchor, Box, createStyles, Stack } from '@mantine/core';
import NextLink from 'next/link';
import { ColumnDef, createColumnHelper, Row } from '@tanstack/react-table';
import { CargoStatus } from 'api/generated/model';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { routes } from '@/common/routes';
import { CargoIdCell, CargoStatusBadge, HideForbiddenElement, Table } from '@/components';
import { NegotiationsTable } from '@/views/cargo-details';
import { NegotiationColumns } from '@/views/cargo-details/types';
import { NON_PASSIVE_NON_PRIVATE_COMPANY_STATUS } from '@/common/utils/company-status';
import { useShouldShowMarketplace } from '@/core/hooks/use-should-show-marketplace';

import { MyCargoRowData, OfferType } from '../../types';
import {
  CargoPromotionCell,
  DescriptionCell,
  ModularizationCargoPromotionCell,
  ModularizationDetailedOfferCargoCell,
  NegotiationCell,
} from './MyCargosTableCells';

const useStyles = createStyles((theme) => ({
  box: {
    position: 'relative',
    backgroundColor: theme.white,
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[2]}`,
  },
}));

const CargoPromotedDetailOfferBadge = (value: any) => {
  const { value: data } = value;
  if (data.isModularizationDetailedOffer) return <ModularizationDetailedOfferCargoCell />;
  if (data.isModularizationPromoted) return <ModularizationCargoPromotionCell />;
  if (data.cargoPromotion) return <CargoPromotionCell networkName={data.networkName} />;
};

const columnHelper = createColumnHelper<MyCargoRowData>();

function useColumns(type: OfferType, onCancel: (id: string, type: OfferType) => void) {
  const { t } = useTranslation('myCargos');

  const columns: ColumnDef<MyCargoRowData>[] = useMemo(
    () => [
      columnHelper.accessor('shortId', {
        header: t('table.headers.cargoId'),
        enableSorting: true,
        cell: CargoIdCell,
      }),
      columnHelper.accessor('cargoPromotion', {
        header: '',
        enableSorting: true,
        cell: ({ row }) => <CargoPromotedDetailOfferBadge value={row.original} />,
      }),
      columnHelper.accessor('id', {
        header: t('table.headers.description'),
        enableSorting: true,
        cell: DescriptionCell,
      }),
      columnHelper.accessor('status', {
        header: t('table.headers.status'),
        enableSorting: true,
        cell: ({ getValue }) => <CargoStatusBadge value={getValue()} />,
      }),
      columnHelper.accessor('negotiationsTotal', {
        header: t('table.headers.negotiations'),
        enableSorting: true,
        cell: (props) => <NegotiationCell {...props} type={type} onCancel={onCancel} />,
      }),
    ],
    [onCancel, t, type]
  );

  return columns;
}
export interface MyCargosTableProspectProps {
  data: MyCargoRowData[];
  isLoading: boolean;
  type: OfferType;
  negotiationColumns: NegotiationColumns;
  onRowClick: (row: Row<MyCargoRowData>) => void;
  onCancelListing: (id: string, type: OfferType) => void;
}

export function MyCargosProspectTable(props: MyCargosTableProspectProps) {
  const { t } = useTranslation('myCargos');
  const { classes } = useStyles();
  const { data, type, onRowClick, onCancelListing, isLoading, negotiationColumns } = props;

  const columns = useColumns(type, onCancelListing);

  const shouldShowMarketplace = useShouldShowMarketplace();

  const RenderTable = (row: Row<MyCargoRowData>) => (
    <Stack align="center" spacing={8}>
      <NegotiationsTable id={row.original.id} type={type} columns={negotiationColumns} />
      {row.original.status !== CargoStatus.BOOKED &&
        !row.original.isModularizationDetailedOffer &&
        !row.original.shouldHideFromMarketplace &&
        shouldShowMarketplace && (
          <HideForbiddenElement key={row.original.id} allowedCompanyStatus={NON_PASSIVE_NON_PRIVATE_COMPANY_STATUS}>
            <Anchor
              weight={600}
              underline={false}
              component={NextLink}
              href={
                type === 'request'
                  ? routes.CargoSearchOffersForNeed(row.original.id)
                  : routes.CargoSearchNeedsForOffer(row.original.id)
              }
            >
              {t('table.links.moreResults')}
            </Anchor>
          </HideForbiddenElement>
        )}
    </Stack>
  );

  return (
    <Box className={classes.box}>
      <Table.Card isWideOnMobile>
        <Table
          data={data}
          columns={columns}
          isLoading={isLoading}
          onRowClick={onRowClick}
          renderExpandedRow={RenderTable}
        />
      </Table.Card>
    </Box>
  );
}

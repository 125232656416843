import { AGENTS_IN_PORT_INITIAL_VALUES } from '../agents-in-port/initial-values';
import { DEMURRAGE_INITIAL_VALUES } from '../demurrage/initial-values';
import { LAW_AND_JURISDICTION_INITIAL_VALUES } from '../law-and-jurisdiction/initial-values';
import {
  DISCHARGING_CONDITIONS_INITIAL_VALUES,
  LOADING_CONDITIONS_INITIAL_VALUES,
} from '../loading-discharging-conditions/initial-values';
import {
  PAYMENT_CONDITION_INITIAL_VALUES,
  PRICING_AND_PAYMENT_TERMS_INITIAL_VALUES,
} from '../pricing-and-payment/initial-values';
import { PRODUCT_GENERAL_INFO_INITIAL_VALUES } from '../product/initital-values';
import { SAMPLING_AND_ANALYSIS_INITIAL_VALUES } from '../sampling-and-analysis/initial-values';
import { VESSEL_INFORMATION_INITIAL_VALUES } from '../vessel-information/initial-values';
import { WEIGHT_INITIAL_VALUES } from '../weight/initial-values';
import { DetailedOfferSchema } from './detailed-offer-form';

export const DETAILED_OFFER_INITIAL_VALUES: DetailedOfferSchema = {
  pricingAndPaymentTerms: PRICING_AND_PAYMENT_TERMS_INITIAL_VALUES,
  productGeneralInfo: PRODUCT_GENERAL_INFO_INITIAL_VALUES,
  weight: WEIGHT_INITIAL_VALUES,
  samplingAndAnalysis: SAMPLING_AND_ANALYSIS_INITIAL_VALUES,
  vesselInformation: VESSEL_INFORMATION_INITIAL_VALUES,
  dischargingConditions: DISCHARGING_CONDITIONS_INITIAL_VALUES,
  loadingConditions: LOADING_CONDITIONS_INITIAL_VALUES,
  paymentConditions: PAYMENT_CONDITION_INITIAL_VALUES,
  demurrage: DEMURRAGE_INITIAL_VALUES,
  agentsInLoadingPort: AGENTS_IN_PORT_INITIAL_VALUES,
  agentsInDischargingPort: AGENTS_IN_PORT_INITIAL_VALUES,
  lawAndJurisdiction: LAW_AND_JURISDICTION_INITIAL_VALUES,
  other: '',
};

export const DETAILED_OFFER_EMPTY_INITIAL_VALUES = {};

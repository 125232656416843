import { CompanyType, UserRole } from 'api/generated/model';
import { ReactNode } from 'react';

import { AppPageContentContainer } from '@/components';
import { useHasPermission } from '@/core';
import { FreightRecapFormOtherSideInfo } from '@/views/freight-recap-form';
import { RecapFormSkeleton } from '@/views/recap-form';

export interface FreightRecapFormLayoutProps {
  allowedRoles: UserRole[];
  allowedCompanyTypes?: CompanyType[];
  additionalHeader?: ReactNode;
  children: ReactNode;
  createdAt: string;
  companyName: string;
  isLoading: boolean;
  onLoadTemplate: VoidFunction;
}

export function FreightRecapFormLayout(props: FreightRecapFormLayoutProps) {
  const {
    allowedRoles,
    additionalHeader,
    allowedCompanyTypes = Object.values(CompanyType),
    children,
    createdAt,
    companyName,
    isLoading,
  } = props;
  const { hasUserRolePermission } = useHasPermission();

  return (
    <AppPageContentContainer
      allowedRoles={allowedRoles}
      allowedCompanyTypes={allowedCompanyTypes}
      additionalHeader={additionalHeader}
      headersSize={72}
    >
      {isLoading ? (
        <RecapFormSkeleton />
      ) : (
        <>
          <FreightRecapFormOtherSideInfo
            dateReceived={createdAt}
            companyName={companyName}
            side={hasUserRolePermission([UserRole.CHARTERING_MANAGER]) ? 'charterer' : 'trader'}
          />
          {children}
        </>
      )}
    </AppPageContentContainer>
  );
}

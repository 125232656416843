import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { showNotification } from '@mantine/notifications';
import { useArchiveCargoWorkflow } from 'api/generated/workflow/workflow';

export type ArchiveMutationData = {
  id: string;
  shortId?: string;
};

export const useArchiveMutation = (archiveMutationData: null | ArchiveMutationData, archiveCallback: () => void) => {
  const { t } = useTranslation('myCargos');
  const [isProcessing, setIsProcessing] = useState(false);

  const { mutate: archiveCargoWorkflowMutation } = useArchiveCargoWorkflow({
    mutation: {
      onSuccess: () => {
        setIsProcessing(false);
        showNotification({
          color: 'green',
          title: t('modals.archiveCargo.archive.successMessage', { shortId: archiveMutationData?.shortId }),
        });
        archiveCallback();
      },
      onError: () => {
        setIsProcessing(false);
        showNotification({ color: 'red', title: t('modals.archiveCargo.archive.errorMessage') });
      },
    },
  });

  const archiveCargo = () => {
    if (!archiveMutationData) return;

    setIsProcessing(true);
    archiveCargoWorkflowMutation({
      workflowId: archiveMutationData.id,
    });
  };

  return { archiveCargo, isProcessing };
};

import { ContainerProps } from '@mantine/core';

import { ThemeComponent } from './types';

export const Container: ThemeComponent<ContainerProps> = {
  defaultProps: {
    sizes: {
      xl: 1280,
      lg: 1280,
      md: 604,
      sm: 394,
      xs: 394,
    },
  },
  styles: (theme) => ({
    root: {
      padding: 16,

      [theme.fn.largerThan('md')]: {
        padding: 24,
      },
    },
  }),
};

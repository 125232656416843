import { showNotification } from '@mantine/notifications';
import { useTranslation } from 'next-i18next';

import { isAxiosError, isClientError } from '@/common/error-guards';
import { useToastNotifications } from '@/common/use-toast-notifications';

export const useExtendDeadlineErrors = () => {
  const { showUnexpectedErrorNotification } = useToastNotifications();
  const { t } = useTranslation('common');

  const extendRequestError = (error: unknown) => {
    if (isAxiosError(error) && isClientError(error)) {
      if (error.response.status === 409) {
        showNotification({
          color: 'red',
          message: t('notifications.REQUEST_EXTENSION_ERROR.message'),
          autoClose: false,
        });
      }
    } else {
      showUnexpectedErrorNotification();
    }
  };

  return {
    extendRequestError,
  };
};

import { Stack } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { CashAgainstDocumentsDTO } from 'api/generated/model';

import { Text } from '@/components';
import { decimalToPercentage } from '@/views/tenders/utils/percentage';

interface CashAgainstDocumentsProps {
  cashAgainstDocuments: CashAgainstDocumentsDTO;
}

export const CashAgainstDocumentsLabel = ({ cashAgainstDocuments }: CashAgainstDocumentsProps) => {
  const { t } = useTranslation('tenderContract');

  return (
    <Stack spacing={0}>
      <Text weight="bold">{t(`labels.cashAgainstDocuments`)}</Text>
      <CashAgainstDocumentsText cashAgainstDocuments={cashAgainstDocuments} />
    </Stack>
  );
};

export const CashAgainstDocumentsText = ({ cashAgainstDocuments }: CashAgainstDocumentsProps) => {
  const { t } = useTranslation('tenderContract');

  return (
    <Text>
      {t(`valuesFormatted.cashAgainstDocumentsConditions`, {
        days: cashAgainstDocuments.days,
        copies: cashAgainstDocuments.numberOfCopies,
        percentage: decimalToPercentage(cashAgainstDocuments.percentage),
      })}
    </Text>
  );
};

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';

import type {
  CurrentUserDTO,
  CurrentUserHubspotIdentificationDTO,
  CompanyInvite,
  ChangeUserCompanyContextDto,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getMe = (options?: AxiosRequestConfig): Promise<AxiosResponse<CurrentUserDTO>> => {
  return axios.get(`/api/v1/company-user/me`, options);
};

export const getGetMeQueryKey = () => [`/api/v1/company-user/me`];

export type GetMeQueryResult = NonNullable<Awaited<ReturnType<typeof getMe>>>;
export type GetMeQueryError = AxiosError<void>;

export const useGetMe = <TData = Awaited<ReturnType<typeof getMe>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMe>>> = ({ signal }) => getMe({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getMe>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const identifyCurrentUser = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CurrentUserHubspotIdentificationDTO>> => {
  return axios.post(`/api/v1/company-user/identify`, undefined, options);
};

export type IdentifyCurrentUserMutationResult = NonNullable<Awaited<ReturnType<typeof identifyCurrentUser>>>;

export type IdentifyCurrentUserMutationError = AxiosError<void>;

export const useIdentifyCurrentUser = <TError = AxiosError<void>, TVariables = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof identifyCurrentUser>>, TError, TVariables, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof identifyCurrentUser>>, TVariables> = () => {
    return identifyCurrentUser(axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof identifyCurrentUser>>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions
  );
};
export const saveUserLoginAttempt = (options?: AxiosRequestConfig): Promise<AxiosResponse<CurrentUserDTO>> => {
  return axios.post(`/api/v1/company-user/login`, undefined, options);
};

export type SaveUserLoginAttemptMutationResult = NonNullable<Awaited<ReturnType<typeof saveUserLoginAttempt>>>;

export type SaveUserLoginAttemptMutationError = AxiosError<void>;

export const useSaveUserLoginAttempt = <TError = AxiosError<void>, TVariables = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof saveUserLoginAttempt>>, TError, TVariables, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveUserLoginAttempt>>, TVariables> = () => {
    return saveUserLoginAttempt(axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof saveUserLoginAttempt>>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions
  );
};
export const getUserInvites = (options?: AxiosRequestConfig): Promise<AxiosResponse<CompanyInvite[]>> => {
  return axios.get(`/api/v1/company-user/invites`, options);
};

export const getGetUserInvitesQueryKey = () => [`/api/v1/company-user/invites`];

export type GetUserInvitesQueryResult = NonNullable<Awaited<ReturnType<typeof getUserInvites>>>;
export type GetUserInvitesQueryError = AxiosError<void>;

export const useGetUserInvites = <
  TData = Awaited<ReturnType<typeof getUserInvites>>,
  TError = AxiosError<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserInvites>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserInvitesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserInvites>>> = ({ signal }) =>
    getUserInvites({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getUserInvites>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const userControllerAcceptCompanyInvite = (options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/v1/company-user/invites/accept`, undefined, options);
};

export type UserControllerAcceptCompanyInviteMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerAcceptCompanyInvite>>
>;

export type UserControllerAcceptCompanyInviteMutationError = AxiosError<unknown>;

export const useUserControllerAcceptCompanyInvite = <
  TError = AxiosError<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerAcceptCompanyInvite>>,
    TError,
    TVariables,
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerAcceptCompanyInvite>>,
    TVariables
  > = () => {
    return userControllerAcceptCompanyInvite(axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof userControllerAcceptCompanyInvite>>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions
  );
};
export const setActiveCompanyContext = (
  changeUserCompanyContextDto: ChangeUserCompanyContextDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/v1/company-user/active-company-context`, changeUserCompanyContextDto, options);
};

export type SetActiveCompanyContextMutationResult = NonNullable<Awaited<ReturnType<typeof setActiveCompanyContext>>>;
export type SetActiveCompanyContextMutationBody = ChangeUserCompanyContextDto;
export type SetActiveCompanyContextMutationError = AxiosError<unknown>;

export const useSetActiveCompanyContext = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setActiveCompanyContext>>,
    TError,
    { data: ChangeUserCompanyContextDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setActiveCompanyContext>>,
    { data: ChangeUserCompanyContextDto }
  > = (props) => {
    const { data } = props ?? {};

    return setActiveCompanyContext(data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof setActiveCompanyContext>>,
    TError,
    { data: ChangeUserCompanyContextDto },
    TContext
  >(mutationFn, mutationOptions);
};

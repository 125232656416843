import { useTranslation as useNextI18nextTranslation } from 'next-i18next';

type ExtractValue<T, Path extends string> = Path extends keyof T
  ? T[Path]
  : Path extends `${infer K}.${infer Rest}`
  ? K extends keyof T
    ? ExtractValue<T[K], Rest>
    : { _error: `Keys-path is incorrect` }
  : { _error: `Keys-path is incorrect` };

export type TranslationFn<Key extends keyof TranslationResources> = <Path extends string>(
  path: Path,
  values?: Record<string, unknown>
) => ExtractValue<TranslationResources[Key], Path>;

/**
 * Temporary simplified (in terms of type) version of next-i18next's useTranslation hook.
 * Reasons for this are in `migration.md` file.
 *
 * ns - namespace name
 *
 * Example usage:
 * ```
 * const { t } = useTranslation('common');
 *
 * // it won't be error there:
 * const correctPlaceholder = t('placeholder'); // string
 * const wrongPlaceholder = t('placelder'); // { _error: `Keys-path is incorrect` }
 *
 * <input placeholder={t('placeholder')} /> // no error - correct key
 * <input placeholder={t('placelder')} /> // Type `{ _error: `Keys-path is incorrect` }` is not assignable to type `string`
 * ```
 *
 * We can say that { _error: `Keys-path is incorrect` } is a "branded" `never` type.
 */
export function useTranslation<T extends TranslationNamespaceName>(ns: T) {
  const { t } = useNextI18nextTranslation(ns);

  return { t: t as TranslationFn<T> };
}

export type SimplifiedTFunction = (key: string, values?: Record<string, unknown>) => string;

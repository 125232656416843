import { CellContext, ColumnDefTemplate } from '@tanstack/react-table';

import { Text } from '@/components';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BoldTextCell: ColumnDefTemplate<CellContext<any, string | number>> = ({ getValue }) => {
  return (
    <Text size="M" color="gray.7" weight={600}>
      {getValue()}
    </Text>
  );
};

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type GetWorkflowTasksForTemplateUseResponseDtoMilestone =
  (typeof GetWorkflowTasksForTemplateUseResponseDtoMilestone)[keyof typeof GetWorkflowTasksForTemplateUseResponseDtoMilestone];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetWorkflowTasksForTemplateUseResponseDtoMilestone = {
  FIXTURE: 'FIXTURE',
  ORIGIN_COUNTRY: 'ORIGIN_COUNTRY',
  SAILING: 'SAILING',
  DESTINATION_COUNTRY: 'DESTINATION_COUNTRY',
} as const;

import { StringParam, useQueryParams } from 'use-query-params';
import { useState } from 'react';

export const useDisclosureWithRowIdQueryParam = () => {
  const [_, setQuery] = useQueryParams({
    negotiationId: StringParam,
  });
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = (selectedRowId?: string) => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
    setQuery({ negotiationId: selectedRowId });
  };

  return [isOpen, toggleIsOpen] as const;
};

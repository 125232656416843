import { Flex } from '@mantine/core';
import { RiErrorWarningFill } from 'react-icons/ri';

import { useTranslation } from '@/common/i18n';

import { Typography } from '../../typography/Typography/Typography';
import { FlexibleConfirmationModal } from '../shared/FlexibleConfirmationModal/FlexibleConfirmationModal';

export interface RecipientCancelPromotedCargoModalProps {
  opened: boolean;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
}

export const RecipientCancelPromotedCargoModal = ({
  opened,
  onClose,
  onConfirm,
}: RecipientCancelPromotedCargoModalProps) => {
  const { t } = useTranslation('common');

  const handleClose = () => {
    onClose();
  };

  const cancelPromotedCargoModalContent = () => {
    return (
      <Flex align="center" justify="center">
        <Typography>{t('modal.cargos.cancelCargoModal.descriptionRecipient')}</Typography>
      </Flex>
    );
  };

  return (
    <FlexibleConfirmationModal
      opened={opened}
      titleCentralized
      onConfirm={onConfirm}
      onCancel={handleClose}
      cancelLabel={t('buttons.cancel')}
      confirmLabel={t('buttons.cancelCargo')}
      content={cancelPromotedCargoModalContent()}
      icon={<RiErrorWarningFill size={50} color="orange" />}
      header={t('modal.cargos.cancelCargoModal.titleRecipient')}
    />
  );
};

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type AcceptCompanyPlatformInvitationNewUserDTOType =
  (typeof AcceptCompanyPlatformInvitationNewUserDTOType)[keyof typeof AcceptCompanyPlatformInvitationNewUserDTOType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AcceptCompanyPlatformInvitationNewUserDTOType = {
  NEW: 'NEW',
} as const;

import { CheckboxProps, Grid, Group, NumberInputProps, Stack } from '@mantine/core';
import { Trans, useTranslation } from 'next-i18next';
import { ReactNode } from 'react';

import { FormCardSection } from '@/components';
import {
  Checkbox,
  CheckboxGroup,
  getBasePercentageProps,
  NumberInput,
  Radio,
  Select,
  TextInput,
} from '@/views/recap-form';
import { getFixedNumberProps } from '@/common/get-fixed-number-props';
import { FreightRecapForm } from '@/blink-forms';

import { useFreightRecapFormState } from '../state/FreightRecapFormProvider';
import { FreightPaymentConditionType } from '../types';

type LaytimeCalculations = FreightRecapForm['payment']['laytimeCalculation'][number];

function PercentageField(props: NumberInputProps) {
  return <NumberInput {...getBasePercentageProps()} w={70} {...props} />;
}

interface PaymentConditionCheckboxProps extends CheckboxProps {
  conditionKey: FreightPaymentConditionType;
  children: ReactNode;
}

function PaymentConditionCheckbox(props: PaymentConditionCheckboxProps) {
  const { conditionKey, children, ...other } = props;
  const { t } = useTranslation('freightRecapForm');
  const { selectedPaymentConditions, togglePaymentCondition } = useFreightRecapFormState();

  return (
    <>
      <Checkbox
        label={t(`labels.${conditionKey}`)}
        {...selectedPaymentConditions[conditionKey]}
        onChange={() => togglePaymentCondition(conditionKey)}
        size="sm"
        {...other}
      />
      {selectedPaymentConditions[conditionKey].checked && children}
    </>
  );
}

export function FreightRecapFormPaymentGroup() {
  const { t } = useTranslation('freightRecapForm');
  const { masterdata, form, initialValues, getInputProps } = useFreightRecapFormState();
  const type = form.values.payment?.type;

  const isLaytimeCalculationCheckboxDirty = (value: LaytimeCalculations) => {
    const isInitiallyChecked = initialValues.payment.laytimeCalculation?.includes(value) || false;
    const isChecked = form.values.payment.laytimeCalculation.includes(value);
    return isInitiallyChecked !== isChecked;
  };

  const isDemurrageRadioDirty = (value: string) => form.isDirty('payment.type') && type === value;

  return (
    <Stack spacing={24}>
      <FormCardSection title={t('labels.laytimeCalculations')}>
        <CheckboxGroup orientation="vertical" spacing={12} {...getInputProps('payment.laytimeCalculation')}>
          {masterdata.freightLaytimeCalculations.map((option: { label: string; value: LaytimeCalculations }) => (
            <Checkbox
              key={option.value}
              value={option.value}
              label={option.label}
              isDirty={isLaytimeCalculationCheckboxDirty(option.value)}
              data-testid={`payment-paymentConditions-${option.value}`}
            />
          ))}
        </CheckboxGroup>
      </FormCardSection>

      <FormCardSection title={t('labels.demurrage')}>
        <Grid align="end">
          <Grid.Col span={12} md={6}>
            <NumberInput
              label={t('labels.rate')}
              withAsterisk={false}
              {...getFixedNumberProps(1)}
              data-testid="payment-rate"
              {...getInputProps('payment.rate')}
            />
          </Grid.Col>
          <Grid.Col span={12} md={2}>
            <Select
              data={masterdata.demurrageRateConditions}
              data-testid="payment-rateType"
              {...getInputProps('payment.rateType')}
            />
          </Grid.Col>
          <Grid.Col span={12} md={4}>
            <Select
              data={masterdata.demurrageEvents}
              data-testid="payment-rateEvent"
              {...getInputProps('payment.event')}
            />
          </Grid.Col>
        </Grid>

        <Radio.Group orientation="vertical" {...getInputProps('payment.type')}>
          <Group align="flex-start">
            <Radio
              label={t('labels.openAccount')}
              value="OPEN_ACCOUNT"
              mt={4}
              onClick={() => form.setFieldValue('payment.type', 'OPEN_ACCOUNT')}
              isDirty={isDemurrageRadioDirty('OPEN_ACCOUNT')}
              data-testid="payment-openAccount"
            />
            {type === 'OPEN_ACCOUNT' && (
              <Group align="flex-start">
                <NumberInput
                  label={t('labels.maxDays')}
                  description={t('hints.openAccountMaxDays')}
                  min={0}
                  data-testid="payment-openAccount-maxDays"
                  {...getInputProps('payment.openAccountMaxDays')}
                />
              </Group>
            )}
          </Group>

          <Radio
            label={t('labels.provisionToOwner')}
            value="PROVISION_TO_OWNERS"
            onClick={() => form.setFieldValue('payment.type', 'PROVISION_TO_OWNERS')}
            isDirty={isDemurrageRadioDirty('PROVISION_TO_OWNERS')}
            data-testid="payment-provisionToOwners"
          />

          <Radio
            label={t('labels.provisionToTrustedParty')}
            value="PROVISION_TO_TRUSTED_PARTY"
            onClick={() => form.setFieldValue('payment.type', 'PROVISION_TO_TRUSTED_PARTY')}
            isDirty={isDemurrageRadioDirty('PROVISION_TO_TRUSTED_PARTY')}
            data-testid="payment-provisionToTrustedParty"
          />
        </Radio.Group>
      </FormCardSection>

      <FormCardSection title={t('labels.paymentConditions')}>
        <PaymentConditionCheckbox conditionKey="TTPrepayment" data-testid="payment-prepayment">
          <Group>
            <Trans
              t={t}
              i18nKey="formSentences.prepayment"
              components={{
                percentage: (
                  <PercentageField
                    data-testid="payment-prepayment-percentage"
                    {...getInputProps('payment.conditions.TTPrepayment.percentagePrepayement')}
                  />
                ),
                days: (
                  <NumberInput
                    w={56}
                    min={0}
                    data-testid="payment-prepayment-days"
                    {...getInputProps('payment.conditions.TTPrepayment.daysPrepayment')}
                  />
                ),
                select: (
                  <Select
                    data={masterdata.prepaymentEvents}
                    grow={false}
                    data-testid="payment-prepayment-event"
                    {...getInputProps('payment.conditions.TTPrepayment.eventPrepayment')}
                  />
                ),
              }}
            />
          </Group>
        </PaymentConditionCheckbox>

        <PaymentConditionCheckbox conditionKey="TTAfterLoading" data-testid="payment-afterLoading">
          <Group>
            <Trans
              t={t}
              i18nKey="formSentences.afterLoading"
              components={{
                percentage: (
                  <PercentageField
                    data-testid="payment-afterLoading-percentage"
                    {...getInputProps('payment.conditions.TTAfterLoading.percentageAfterLoading')}
                  />
                ),
                days: (
                  <NumberInput
                    w={56}
                    min={0}
                    data-testid="payment-afterLoading-days"
                    {...getInputProps('payment.conditions.TTAfterLoading.daysAfterLoading')}
                  />
                ),
              }}
            />
          </Group>
        </PaymentConditionCheckbox>

        <PaymentConditionCheckbox conditionKey="TTBeforeBreakingBulk" data-testid="payment-beforeBreakingBulk">
          <Group>
            <Trans
              t={t}
              i18nKey="formSentences.beforeBreaking"
              components={{
                percentage: (
                  <PercentageField
                    data-testid="payment-beforeBreakingBulk-percentage"
                    {...getInputProps('payment.conditions.TTBeforeBreakingBulk.percentageBeforeBreakingBulk')}
                  />
                ),
                days: (
                  <NumberInput
                    w={56}
                    min={0}
                    data-testid="payment-beforeBreakingBulk-days"
                    {...getInputProps('payment.conditions.TTBeforeBreakingBulk.daysBeforeBreakingBulk')}
                  />
                ),
              }}
            />
          </Group>
        </PaymentConditionCheckbox>

        <PaymentConditionCheckbox conditionKey="TTOpenTerms" data-testid="payment-openTerms">
          <Group>
            <Trans
              t={t}
              i18nKey="formSentences.openTerms"
              components={{
                percentage: (
                  <PercentageField
                    data-testid="payment-openTerms-percentage"
                    {...getInputProps('payment.conditions.TTOpenTerms.percentageOpenTerms')}
                  />
                ),
                days: (
                  <NumberInput
                    w={56}
                    min={0}
                    data-testid="payment-openTerms-days"
                    {...getInputProps('payment.conditions.TTOpenTerms.daysOpenTerms')}
                  />
                ),
                select: (
                  <Select
                    data={masterdata.openAccountEvents}
                    grow={false}
                    data-testid="payment-openTerms-select"
                    {...getInputProps('payment.conditions.TTOpenTerms.EventOpenTerms')}
                  />
                ),
              }}
            />
          </Group>
        </PaymentConditionCheckbox>

        <PaymentConditionCheckbox conditionKey="other" data-testid="payment-other">
          <TextInput data-testid="payment-other-comments" {...getInputProps('payment.conditions.other')} />
        </PaymentConditionCheckbox>
      </FormCardSection>
    </Stack>
  );
}

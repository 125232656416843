import { useTranslation } from 'next-i18next';
import { RiErrorWarningFill } from 'react-icons/ri';
import { Stack, Group, Button, createStyles, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconType } from 'react-icons/lib';
import { ReactElement } from 'react';

import { Text, BaseModal, BackgroundIcon } from '@/components';

const useStyles = createStyles((theme) => ({
  button: {
    fontSize: '14px',
    [theme.fn.smallerThan('xs')]: {
      fontSize: '12px',
    },
  },
  baseModal: {
    [theme.fn.smallerThan('xs')]: {
      maxHeight: '100vh',
    },
  },
  inner: {
    [theme.fn.smallerThan('xs')]: {
      bottom: 0,
      top: 'auto',
      padding: 0,
      maxHeight: '100vh',
    },
  },
  modal: {
    [theme.fn.smallerThan('xs')]: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  stackBody: {
    [theme.fn.smallerThan('xs')]: {
      textAlign: 'left',
    },

    [theme.fn.largerThan('xs')]: {
      textAlign: 'center',
    },
  },
}));

interface ConfirmationModalProps {
  opened: boolean;
  isLoading?: boolean;
  icon?: ReactElement;
  onCancel: VoidFunction;
  onSubmit: VoidFunction;
  labels: {
    title: string;
    confirmation: string;
    descriptions: string[];
    close?: string;
  };
}

export function ConfirmationModal({
  opened,
  icon: customIcon,
  onCancel,
  onSubmit,
  isLoading,
  labels: { title, descriptions, confirmation, close },
}: ConfirmationModalProps) {
  const { t } = useTranslation('common');
  const theme = useMantineTheme();
  const { classes } = useStyles();

  const isNotXsScreen = useMediaQuery(`(min-width: ${theme.breakpoints.xs}px)`);

  const icon = customIcon ? customIcon : <BackgroundIcon icon={RiErrorWarningFill} color="red" size={20} />;

  if (!close) {
    close = t('modals.extend.labels.cancel');
  }

  const content = (
    <Stack spacing={24} className={classes.stackBody}>
      {descriptions.map((description, index) => (
        <Text key={index} color="gray.6" size="S">
          {description}
        </Text>
      ))}
      <Group grow>
        <Button className={classes.button} onClick={onCancel} variant="outline" size="md" disabled={isLoading}>
          {close}
        </Button>
        {confirmation && (
          <Button className={classes.button} onClick={onSubmit} size="md" disabled={isLoading}>
            {confirmation}
          </Button>
        )}
      </Group>
    </Stack>
  );

  return (
    <BaseModal
      classNames={{ inner: classes.inner, root: classes.baseModal, modal: classes.modal }}
      icon={icon}
      header={title}
      opened={opened}
      centered={isNotXsScreen}
      close={onCancel}
      content={content}
      hideContact={true}
      notCenterTitle={true}
    />
  );
}

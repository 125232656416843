import { z } from 'zod';

import { CostsTerms } from '../../types';

const baseSurveyor = {
  name: z.string(),
  conditions: z.string(),
  surveyorCost: z.nativeEnum(CostsTerms).nullable(),
};

function createSurveyorSchema({ nameOptional = false, conditionsOptional = false, surveyorCostOptional = false } = {}) {
  const schema = z.object({
    name: nameOptional ? baseSurveyor.name.optional() : baseSurveyor.name.nonempty(),
    conditions: conditionsOptional ? baseSurveyor.conditions.optional() : baseSurveyor.conditions.nonempty(),
    surveyorCost: surveyorCostOptional
      ? baseSurveyor.surveyorCost.optional()
      : baseSurveyor.surveyorCost.refine((val) => val !== null, {
          message: 'This field is required',
        }),
  });

  return schema;
}

export const surveyorOptional = createSurveyorSchema({
  nameOptional: true,
  conditionsOptional: true,
  surveyorCostOptional: true,
});
export const surveyorRequired = createSurveyorSchema(); // Aquí, name y conditions tendrán la validación .nonempty() aplicada

export type SurveyorSchema = z.infer<typeof surveyorRequired>;

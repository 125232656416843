import { ZodEffects } from 'zod';
import { useForm, zodResolver } from '@mantine/form';

import { RecapFormWithPromoting, recapFormSchemaWithPromoting } from '@/blink-forms';

export type RecapFormSchema = typeof recapFormSchemaWithPromoting;

export type RefineSchemaFn = (x: RecapFormSchema) => RecapFormSchema | ZodEffects<RecapFormSchema>;

export function useRecapForm(
  initialValues: RecapFormWithPromoting,
  refineSchema: RefineSchemaFn = (x: RecapFormSchema) => x
) {
  return useForm<RecapFormWithPromoting>({
    initialValues,
    validateInputOnBlur: true,
    validate: zodResolver(refineSchema(recapFormSchemaWithPromoting)),
  });
}

import { z } from 'zod';

export const prepayment = z.object({
  percentage: z.number(),
  days: z.number(),
  event: z.string(),
});

export const cashAgainstDocuments = z.object({
  percentage: z.number(),
  days: z.number(),
  numberOfCopies: z.number(),
});

export const irrevocableLetter = z.object({
  percentage: z.number(),
  days: z.number(),
  event: z.string(),
  bankName: z.string(),
  swiftCode: z.string(),
});

export const documentCollection = z.object({
  percentage: z.number(),
  days: z.number(),
  event: z.string(),
});

export const openAccount = z.object({
  percentage: z.number(),
  days: z.number(),
  event: z.string(),
});

export const consumptionPayment = z.object({
  percentage: z.number(),
  days: z.number(),
  event: z.string(),
  numberOfInstallments: z.number(),
});

export const other = z.object({
  percentage: z.number(),
  methodOfPayment: z.string(),
});

export const paymentCondition = z.object({
  key: z.string().optional(),
  name: z.string().nonempty(),
  prepayment: prepayment.optional(),
  cashAgainstDocuments: cashAgainstDocuments.optional(),
  irrevocableLetter: irrevocableLetter.optional(),
  documentCollection: documentCollection.optional(),
  openAccount: openAccount.optional(),
  consumptionPayment: consumptionPayment.optional(),
  other: other.optional(),
});

export type PaymentConditionSchema = z.infer<typeof paymentCondition>;
export type PaymentCondition = z.infer<typeof paymentCondition>;

export const cargoTenderPaymentConditionsSchema = z.object({
  allocation: z.string().optional(),
  conditions: z.array(paymentCondition).min(1),
});

export type CargoTenderPaymentConditions = z.infer<typeof cargoTenderPaymentConditionsSchema>;

export type CheckboxCondition = {
  label: string;
  checked: boolean;
};

export type PaymentConditionCheck = {
  key?: string;
  name?: string;
  prepayment?: PrepaymentConditionCheckSchema;
  cashAgainstDocuments?: CashAgainstDocumentsCheckSchema;
  irrevocableLetter?: IrrevocableLetterCheckSchema;
  documentCollection?: DocumentCollectionCheckSchema;
  openAccount?: OpenAccountCheckSchema;
  consumptionPayment?: ConsumptionPaymentCheckSchema;
  other?: OtherPaymentCheckSchema;
};

export type PrepaymentConditionSchema = z.infer<typeof prepayment>;
export type PrepaymentConditionCheckSchema = PrepaymentConditionSchema & CheckboxCondition;

export type CashAgainstDocumentsSchema = z.infer<typeof cashAgainstDocuments>;
export type CashAgainstDocumentsCheckSchema = CashAgainstDocumentsSchema & CheckboxCondition;

export type IrrevocableLetterSchema = z.infer<typeof irrevocableLetter>;
export type IrrevocableLetterCheckSchema = IrrevocableLetterSchema & CheckboxCondition;

export type DocumentCollectionSchema = z.infer<typeof documentCollection>;
export type DocumentCollectionCheckSchema = DocumentCollectionSchema & CheckboxCondition;

export type OpenAccountSchema = z.infer<typeof openAccount>;
export type OpenAccountCheckSchema = OpenAccountSchema & CheckboxCondition;

export type ConsumptionPaymentSchema = z.infer<typeof consumptionPayment>;
export type ConsumptionPaymentCheckSchema = ConsumptionPaymentSchema & CheckboxCondition;

export type OtherPaymentSchema = z.infer<typeof other>;
export type OtherPaymentCheckSchema = OtherPaymentSchema & CheckboxCondition;

import { showNotification } from '@mantine/notifications';
import { useQueryClient } from '@tanstack/react-query';
import {
  getGetContractTemplatesQueryKey,
  useCreateContractTemplate,
} from 'api/generated/contract-template/contract-template';
import { useTranslation } from 'next-i18next';

import { useHandleFormError } from '@/common/use-handle-form-error';
import { useAuthRoles } from '@/core';

type UseCreateTemplateProps = Omit<Parameters<typeof useCreateContractTemplate>[0]['mutation'], 'onError'>;

export function useCreateTemplate(props: UseCreateTemplateProps) {
  const { t } = useTranslation('recapForm');
  const { companyId } = useAuthRoles();
  const queryClient = useQueryClient();

  const handleError = useHandleFormError({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    translatePath: (path) => t(`errors.${path}` as any),
    overrideMessage: (status) => (status === 409 ? t('modals.saveDraft.duplicateName') : undefined),
  });
  return useCreateContractTemplate({
    mutation: {
      ...props,
      onSuccess(response, variables, context) {
        showNotification({
          color: 'green',
          title: t('notifications.templateSaved'),
        });
        queryClient.invalidateQueries(getGetContractTemplatesQueryKey(companyId));
        props.onSuccess && props.onSuccess(response, variables, context);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  });
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoTenderContractCargoType =
  (typeof CargoTenderContractCargoType)[keyof typeof CargoTenderContractCargoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoTenderContractCargoType = {
  FIXED: 'FIXED',
  OPTIONAL: 'OPTIONAL',
  EXERCISED: 'EXERCISED',
} as const;

import { RadioProps } from '@mantine/core';

import { ThemeComponent } from './types';

export const Radio: ThemeComponent<RadioProps> = {
  styles: {
    root: {
      position: 'relative',
    },
    body: {
      position: 'relative',
    },
    label: {
      fontSize: 16,
    },
  },
};

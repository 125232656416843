import { useSession } from 'next-auth/react';

import { setBearerToken } from '@/common/set-bearer-token';
import { ChatSessionWrapper } from '@/components';

export function PageComponent({ children }) {
  const { data: session } = useSession();
  if (session) {
    setBearerToken(session);
  }

  if (session && session.me.activeCompany) {
    return <ChatSessionWrapper>{children}</ChatSessionWrapper>;
  }

  return <>{children}</>;
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type OfferType = (typeof OfferType)[keyof typeof OfferType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OfferType = {
  FIRM: 'FIRM',
  TENTATIVE: 'TENTATIVE',
} as const;

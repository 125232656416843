/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type GetAllCargoOfferServicesForSellersCargoTransportation =
  (typeof GetAllCargoOfferServicesForSellersCargoTransportation)[keyof typeof GetAllCargoOfferServicesForSellersCargoTransportation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAllCargoOfferServicesForSellersCargoTransportation = {
  CFR: 'CFR',
  FOB: 'FOB',
  CIF: 'CIF',
} as const;

import { ReactNode } from 'react';
import { UserRole, CompanyRole, CompanyType, UserCompanyStatus } from 'api/generated/model';

import { useHasPermission, useHasAnyUserCompanyPermission } from '@/core';

export interface HideForbiddenElementProps {
  children: ReactNode;
  allowedRoles?: UserRole[];
  unallowedRoles?: UserRole[];
  hiddenForMIOnlyUsers?: boolean;
  allowUnverifiedCompany?: boolean;
  hiddenForPrivateCompanies?: boolean;
  allowedCompanyTypes?: CompanyType[];
  allowedCompanyStatus?: UserCompanyStatus[];
  hiddenForServiceProviderCompanies?: boolean;
  allowedCompanyRoles?: CompanyRole | CompanyRole[];
}

export function HideForbiddenElement(props: HideForbiddenElementProps) {
  const {
    children,
    allowedCompanyRoles,
    hiddenForMIOnlyUsers,
    hiddenForPrivateCompanies,
    allowUnverifiedCompany = false,
    allowedRoles = Object.values(UserRole),
    hiddenForServiceProviderCompanies = false,
    allowedCompanyTypes = Object.values(CompanyType),
    allowedCompanyStatus = Object.values(UserCompanyStatus),
  } = props;

  const {
    isCompanyVerified,
    isMIOnlyUser,
    isPrivate,
    hasCompanyPermission,
    hasUserRolePermission,
    hasAllowedCompanyRoles,
    hasAllowedCompanyStatus,
    isServiceProvider,
  } = useHasPermission();

  const isRoleAllowed = hasUserRolePermission(allowedRoles);
  const isCompanyAllowed = hasCompanyPermission(allowedCompanyTypes);

  const isCompanyStatusAllowed = hasAllowedCompanyStatus(allowedCompanyStatus);

  const allowUnverifiedCompanyAllowed = allowUnverifiedCompany || isCompanyVerified;
  const isCompanyRoleAllowed = !allowedCompanyRoles || hasAllowedCompanyRoles(allowedCompanyRoles);

  const shouldRender =
    isCompanyAllowed &&
    isCompanyStatusAllowed &&
    isRoleAllowed &&
    allowUnverifiedCompanyAllowed &&
    isCompanyRoleAllowed &&
    !(hiddenForMIOnlyUsers && isMIOnlyUser) &&
    !(hiddenForPrivateCompanies && isPrivate) &&
    !(hiddenForServiceProviderCompanies && isServiceProvider);

  return shouldRender && <>{children}</>;
}

export function HideForbiddenElementForAllUserCompanies(props: HideForbiddenElementProps) {
  const { children, allowedRoles, unallowedRoles = [], allowedCompanyTypes = Object.values(CompanyType) } = props;

  const { hasUserRolePermission, hasCompanyTypePermission } = useHasAnyUserCompanyPermission();

  const isCompanyAllowed = hasCompanyTypePermission(allowedCompanyTypes);
  const isRoleAllowed = hasUserRolePermission(allowedRoles, unallowedRoles);

  const shouldRender = isCompanyAllowed && isRoleAllowed;
  return shouldRender && <>{children}</>;
}

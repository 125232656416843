/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoTenderDemurrageDTORateCondition =
  (typeof CargoTenderDemurrageDTORateCondition)[keyof typeof CargoTenderDemurrageDTORateCondition];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoTenderDemurrageDTORateCondition = {
  MAX: 'MAX',
  MIN: 'MIN',
} as const;

import { AnalysisTypes } from '../../types';
import { MainAnalysisSchema } from './main-analysis';
import { ArbitrationAnalysisSchema } from './arbitration-analysis';
import { SamplingAndAnalysisSchema } from './sampling-and-analysis';
import { SURVEYOR_INITIAL_VALUES } from '../surveyor/initial-values';

export const MAIN_ANALYSIS_INITIAL_VALUES: Partial<MainAnalysisSchema> = {
  type: AnalysisTypes.MANUFACTURING_PLANT_LABORATORY,
};

export const ARBITRATION_ANALYSIS_INITIAL_VALUES: Partial<ArbitrationAnalysisSchema> = {};

export const SAMPLING_AND_ANALYSIS_INITIAL_VALUES: Partial<SamplingAndAnalysisSchema> = {
  surveyorAtLoadport: [SURVEYOR_INITIAL_VALUES],
  surveyorAtDischargeport: [SURVEYOR_INITIAL_VALUES],
  mainAnalysis: [MAIN_ANALYSIS_INITIAL_VALUES],
  arbitrationAnalysis: ARBITRATION_ANALYSIS_INITIAL_VALUES,
};

//Empty values
export const samplingAndAnalysisEmptyValues = (
  surveyorAtLoadportLength = 1,
  surveyorAtDischargeportLength = 1,
  mainAnalysisLength = 1
) => {
  return {
    surveyorAtLoadport: Array.from({ length: surveyorAtLoadportLength }, () => ({
      name: '',
      surveyorCost: null,
      conditions: '',
    })),
    surveyorAtDischargeport: Array.from({ length: surveyorAtDischargeportLength }, () => ({
      name: '',
      surveyorCost: null,
      conditions: '',
    })),
    mainAnalysis: Array.from({ length: mainAnalysisLength }, () => ({
      name: '',
      type: null,
      conditions: '',
    })),
    arbitrationAnalysis: {
      name: '',
      conditions: '',
    },
    other: '',
  };
};

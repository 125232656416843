import { Badge, Stack } from '@mantine/core';
import { CellContext, ColumnDefTemplate } from '@tanstack/react-table';

import { Text } from '@/components';
import { formatDate } from '@/common/format-date';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface EtaCellProps extends CellContext<any, string> {
  minDate: string | null;
  maxDate: string | null;
}

export const EtaCell: ColumnDefTemplate<EtaCellProps> = ({ minDate, maxDate }) => {
  const etaMin = formatDate(minDate);
  const etaMax = formatDate(maxDate);

  const isSameDate = etaMin === etaMax;

  return (
    <Badge
      color="gray"
      py={2}
      sx={(theme) => ({
        borderColor: theme.colors.gray[2],
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '22px',
      })}
    >
      <Stack spacing={0}>
        {isSameDate ? (
          <Text weight={600} color="background.2">
            {etaMin}
          </Text>
        ) : (
          <>
            <Text weight={600} color="background.2">
              {etaMin} -
            </Text>
            <Text weight={600} color="background.2">
              {etaMax}
            </Text>
          </>
        )}
      </Stack>
    </Badge>
  );
};

import { AccordionProps } from '@mantine/core';

import { ThemeComponent } from './types';

export const Accordion: ThemeComponent<AccordionProps> = {
  styles: (theme, params) => ({
    label: {
      fontSize: 18,
      lineHeight: '26px',
      fontWeight: 600,
    },
    chevron: {
      color: theme.fn.primaryColor(),
    },
    content: {
      padding: '24px 16px',
    },
    control: {
      ...(params.variant === 'separated' && {
        '&, &[data-active]': {
          background: theme.colors.background[1],
          borderColor: `1px solid ${theme.colors.gray[2]}`,
        },
        borderRadius: theme.radius.md,
        '&[data-active]': {
          borderRadius: '8px 8px 0 0',
        },
      }),
    },
    item: {
      ...(params.variant === 'separated' && {
        border: `1px solid ${theme.colors.gray[2]}`,
        borderRadius: theme.radius.md,
      }),
    },
  }),
};

import { ActionIcon, createStyles, Group, Menu } from '@mantine/core';
import { CellContext, ColumnDefTemplate } from '@tanstack/react-table';
import { useTranslation } from 'next-i18next';
import { RiMore2Fill } from 'react-icons/ri';
import { CargoNegotiationDTO, ChatGroupUnreadMessagesDTO, CompanyRole } from 'api/generated/model';

import { isCancelAvailable, isDraft, isExtendAvailable } from '@/common/negotiation-status';
import { NegotiationActions, NegotiationButton, OfferType } from '@/views/my-cargos';
import { NegotiationTableFunction } from '@/views/cargo-details/types';
import { useMediaQuery } from '@/core/hooks/use-media-query';
import { useHasPermission } from '@/core';

import { ChatIcon } from './ChatIcon';

const useStyles = createStyles((theme) => ({
  icon: {
    color: theme.fn.primaryColor(),
    fontSize: 24,
  },
  menuItem: {
    ':hover': {
      backgroundColor: theme.colors.indigo[0],
    },
  },
  timeLineIcon: {
    color: theme.colors.gray[5],
    marginTop: 2,
  },
}));

export interface NegotiationActionsCellProps extends CellContext<CargoNegotiationDTO, string> {
  onNegotiationHistoryClick: NegotiationTableFunction;
  onNegotiationsDetailsClick: NegotiationTableFunction;
  onCancelNegotiationsClick: NegotiationTableFunction;
  offerType: OfferType;
  onRequestExtensionClick?: NegotiationTableFunction;
  onExtendClick?: NegotiationTableFunction;
  onChatIconClick?: NegotiationTableFunction;
  onEnablePrivateDataForCSM?: NegotiationTableFunction;
  chatGroupUnreadMessages: ChatGroupUnreadMessagesDTO[];
}

export const NegotiationActionsCell: ColumnDefTemplate<NegotiationActionsCellProps> = ({
  row,
  offerType,
  onNegotiationHistoryClick,
  onNegotiationsDetailsClick,
  onCancelNegotiationsClick,
  onRequestExtensionClick,
  onExtendClick,
  onChatIconClick,
  onEnablePrivateDataForCSM,
  chatGroupUnreadMessages,
}) => {
  const { t } = useTranslation('cargoDetails');
  const { classes, theme } = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.md);
  const negotiationStatus = row.original.lastStep.status;

  const { hasAllowedCompanyRoles } = useHasPermission();
  const canFinalizeNegotiation = hasAllowedCompanyRoles(CompanyRole.ALLOWED_TO_FINALIZE_NEGOTIATION);

  const menuItemsProps = {
    [NegotiationActions.SEE_HISTORY]: {
      label: 'negotiationsHistory',
      onClick: onNegotiationHistoryClick,
    },
    [NegotiationActions.CANCEL_NEGOTIATION]: {
      label: 'cancelNegotiations',
      onClick: onCancelNegotiationsClick,
    },
    [NegotiationActions.REQUEST_EXTENSION]: {
      label: 'requestExtension',
      onClick: onRequestExtensionClick,
    },
    [NegotiationActions.CSM_ENABLE_PRIVATE_DATA]: {
      label: 'cmsEnablePrivacyData',
      onClick: onEnablePrivateDataForCSM,
    },
    [NegotiationActions.EXTEND]: {
      label: 'extendNegotiations',
      onClick: onExtendClick,
    },
    [NegotiationActions.CHAT]: {
      label: 'chat',
      onClick: onChatIconClick,
    },
    [NegotiationActions.SEE_DETAILS]: {
      label: 'seeDetails',
      onClick: onNegotiationsDetailsClick,
    },
  };
  const baseActions = isMobile
    ? [NegotiationActions.SEE_HISTORY, NegotiationActions.CHAT, NegotiationActions.SEE_DETAILS]
    : [NegotiationActions.SEE_HISTORY];

  let availableActions = baseActions;
  if (canFinalizeNegotiation && isCancelAvailable(negotiationStatus)) {
    availableActions = [...baseActions, NegotiationActions.CANCEL_NEGOTIATION];
  }
  if (isDraft(negotiationStatus)) {
    availableActions = [NegotiationActions.CANCEL_NEGOTIATION];
  }
  if (isExtendAvailable(negotiationStatus)) {
    if (row.original.expiryExtendRequest[0]?.status !== 'REQUESTED' && offerType === 'request') {
      availableActions = [...availableActions, NegotiationActions.REQUEST_EXTENSION];
    }
    if (offerType === 'offer') {
      availableActions = [...availableActions, NegotiationActions.EXTEND];
    }
  }
  if (onEnablePrivateDataForCSM) {
    availableActions = [...availableActions, NegotiationActions.CSM_ENABLE_PRIVATE_DATA];
  }

  const channelId = row.original.id;
  const unreadMessages =
    chatGroupUnreadMessages.find((groupChat) => groupChat.id === channelId)?.unreadMessageCount || 0;

  return (
    <Group position="right">
      <NegotiationButton
        status={negotiationStatus}
        onDetailsClick={() => onNegotiationsDetailsClick(row)}
        onHistoryClick={() => onNegotiationHistoryClick(row)}
      />
      <ChatIcon onChatIconClick={() => onChatIconClick(row)} unreadMessages={unreadMessages} />
      <Menu classNames={{ item: classes.menuItem }}>
        <Menu.Target data-testid="action-target">
          <ActionIcon component="button" className={classes.icon} variant="transparent" aria-label="More actions">
            <RiMore2Fill />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          {availableActions.map((negotiationAction) => (
            <Menu.Item
              key={`${row.original.id}_${negotiationAction}`}
              onClick={(e) => {
                e.stopPropagation();
                menuItemsProps[negotiationAction].onClick(row);
              }}
            >
              {t(`table.menu.${menuItemsProps[negotiationAction].label}`)}
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
};

import { Incoterm, TransactionSide } from 'api/generated/model';

import { CargoTypeTab } from '@/views/freight/types';
import { MarketIntelligenceTab } from '@/views/market-intelligence/types';
import { MyCargoTab, NegotiationDetailsTab } from '@/views/my-cargos';

export interface RecapFormRouteQuery {
  needId: string;
  offerId: string;
  offeredTransport: string;
  negotiationId: string | undefined;
}

export interface TenderParticipationFormRouteQuery {
  tenderId: string;
}

function routeWithQuery<T extends string | number | symbol>(
  path: string,
  search: Record<T, string | boolean | undefined>
) {
  const nonUndefinedSearch = Object.fromEntries(
    Object.entries(search).filter(([_key, value]) => value !== undefined)
  ) as Record<T, string>;
  return `${path}?${new URLSearchParams(nonUndefinedSearch)}`;
}

export const routes = {
  Root: '/',
  SignIn: '/auth/signin',
  SignOut: '/auth/signout',
  SignUp: '/auth/signup', // Mantained for retro compatibility with company invites
  SignUpPage: '/auth/signup', // Mantained for retro compatibility with company invites
  MagicLink: (challenge: string) => `/auth/login/${challenge}`,
  App: '/app',
  HelpCenter: '/app/help-center',
  HelpCenterArticle: (slug: string) => `/app/help-center/${slug}`,
  Cookies: 'https://blinkmaterials.com/cookie-policy',
  PrivacyPolicy: 'https://blinkmaterials.com/privacy',
  TermsOfUse: 'https://blinkmaterials.com/terms-of-use',
  CompanyAccount: '/app/company-account',
  UserManagement: '/app/user-management',
  UserSettings: '/app/user-settings',
  UserProfile: '/app/user-profile',
  SignInPage: '/auth/signin',
  Chat: '/app/chat',
  // cargo
  MyCargos: (type?: 'purchases' | 'sales', typeCargo?: 'booked') =>
    `/app/cargo${
      type || typeCargo
        ? `?${type ? `activeTab=${type}` : ''}${type && typeCargo ? '&' : ''}${
            typeCargo ? `typeCargo=${typeCargo}` : ''
          }`
        : ''
    }`,
  MyCargosInvitations: `/app/cargo?invitations`,
  CargoNeedDetails: (needId: string) => `/app/cargo/need/${needId}`,
  CargoOfferDetails: (offerId: string) => `/app/cargo/offer/${offerId}`,
  CargoNeedSummary: (needId: string) => `/app/cargo/need/${needId}/summary`,
  CargoOfferSummary: (offerId: string) => `/app/cargo/offer/${offerId}/summary`,
  CreateCargoNeeed: '/app/cargo/need/create',
  CreateCargoOffer: '/app/cargo/offer/create',
  CargoSearchNeedsForOffer: (offerId: string) => `/app/cargo/search/needs-for-offer/${offerId}`,
  CargoSearchOffersForNeed: (needId: string) => `/app/cargo/search/offers-for-need/${needId}`,
  CargoBookedAdd: '/app/cargo/booked/add',
  CargoBookedAddParams: (incoterm: Incoterm, typeCargo?: string) =>
    `/app/cargo/booked/add?incoterm=${incoterm}&bookedCargoType=${typeCargo}`,
  CargoBookedAddParamsCargo: (incoterm: Incoterm, typeCargo?: string, cargoId?: string) =>
    `/app/cargo/booked/add?incoterm=${incoterm}&bookedCargoType=${typeCargo}&cargoId=${cargoId}`,
  RecapForm: (query: RecapFormRouteQuery) => routeWithQuery('/app/cargo/recap-form', query),
  PromotedCargoRecapForm: (query: Pick<RecapFormRouteQuery, 'needId' | 'offeredTransport'>) =>
    routeWithQuery('/app/cargo/promoted-recap-form', query),
  CounterofferRecapForm: (negotiationId: string) => `/app/cargo/negotiation/${negotiationId}/counteroffer`,
  CargoNegotiationHistory: (negotiationId: string, activeDetailsTab?: NegotiationDetailsTab, activeTab?: MyCargoTab) =>
    `/app/cargo?drawer=negotiation-history&negotiationId=${negotiationId}${
      activeDetailsTab ? `&activeDetailsTab=${activeDetailsTab}` : ''
    }${activeTab ? `&activeTab=${activeTab}` : ''}`,
  CargoWorkflowSummary: (workflowId: string) => `/app/cargo/workflow/${workflowId}/summary`,
  CargoWorkflowPending: (workflowId: string, invitationId: string, source?: string) =>
    `/app/cargo/workflow/${workflowId}/pending/${invitationId}${source ? `?source=${source}` : ''}`,
  CargoWorkflowServices: (workflowId: string) => `/app/cargo/workflow/${workflowId}/services`,
  CargoWorkflowOperations: (workflowId: string) => `/app/cargo/workflow/${workflowId}/operations`,
  CargoWorkflowToolkit: (workflowId: string) => `/app/cargo/workflow/${workflowId}/toolkit`,
  TraderWorkflowOperations: (workflowId: string) => `/app/cargo/workflow/${workflowId}/trader/operations`,
  CargoWorkflowChat: (workflowId: string) => `/app/cargo/workflow/${workflowId}/chat`,
  CargoWorkflowTracking: (workflowId: string) => `/app/cargo/workflow/${workflowId}/tracking`,
  CargoWorkflowNegotiationHistory: (workflowId: string) => `/app/cargo/workflow/${workflowId}/negotiation-history`,
  MarketIntelligence: (activeTab?: MarketIntelligenceTab) =>
    `/app/market-intelligence?activeTab=${activeTab || MarketIntelligenceTab.DATA_HUB}`,
  MarketIntelligenceReport: (id: string) => `/app/market-intelligence/reports/${id}?activeTab=REPORTS_AND_ANALYSES`,
  MarketIntelligenceArticle: (id: string) => `/app/market-intelligence/news/${id}`,
  MyFreights: (tab?: CargoTypeTab) => `/app/freight${tab ? `?activeTab=${tab}` : ''}`,
  MyTenders: (tab?: 'purchases' | 'sales') => `/app/tender${tab ? `?activeTab=${tab}` : ''}`,
  MyTendersWithQueryParams: (params?: Record<string, string | boolean | undefined>) =>
    routeWithQuery(`/app/tender`, params || {}),
  CargoTenderParticipation: (query: TenderParticipationFormRouteQuery) =>
    routeWithQuery(`/app/tender/participation-form`, query),
  CargoTenderNegotiation: (tenderId: string, negotiationId: string) =>
    `/app/tender/${tenderId}/negotiation/${negotiationId}`,
  CargoTenderNegotiationGroup: (tenderId: string, negotiationId: string, groupId: string) =>
    `/app/tender/${tenderId}/negotiation/${negotiationId}/group/${groupId}`,
  CargoTenderContractDetails: (contractId: string, transactionSide: TransactionSide) =>
    `/app/tender/contract/${contractId}?transactionSide=${transactionSide}`,
  CargoTenderDetails: (tenderId: string) => `/app/tender/${tenderId}`,
  CargoTenderMarketplace: '/app/tender/marketplace',
  FreightRecapForm: (negotiationId: string) => `/app/freight/negotiation/${negotiationId}/recap-form`,
  FreightCounterOffer: (negotiationId: string) => `/app/freight/negotiation/${negotiationId}/counteroffer`,
  FreightDetails: (negotiationId: string) => `/app/freight/negotiation/${negotiationId}`,
  CompanyProfile: (companyId: string) => `/app/company-profile/${companyId}`,
  MessageCenter: (chatId: string) => `/app/chat?active=${chatId}`,
  FreightRequest: `/app/freight/request`,
  PromotedCargoNeed: (needId: string) => `/app/cargo/need/${needId}/summary`,
  PromotedCargoOffer: (offerId: string) => `/app/cargo/offer/${offerId}/summary`,
  CreateDetailedOffer: `/app/cargo/create-detailed-offer`,
  DetailedOffer: (offerId: string) => `/app/cargo/detailed-offer/${offerId}`,
  Marketplace: () => `/app/marketplace/`,

  //errors
  NotFound: '/404',
  ServerError: '/500',
  Forbidden: '/403',
};

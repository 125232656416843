import { useTranslation } from 'next-i18next';
import { ArchivedCargoDTO } from 'api/generated/model';
import { Box, Image, createStyles } from '@mantine/core';
import { EmptyTable } from 'components/empty-table/EmptyTable';

import { Table } from '@/components';

import { useColumns } from './columns/MycargosArchivedTableColumns';
import { MyCargoTab, ArchivedCargoTableActionFunction } from '../../types';

const useStyles = createStyles((theme) => ({
  box: {
    position: 'relative',
    backgroundColor: theme.white,
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[2]}`,
  },
}));

export interface MyCargosArchivedTableProps {
  isLoading: boolean;
  activeTab: MyCargoTab;
  cargos: ArchivedCargoDTO[];
  onRowClick: ArchivedCargoTableActionFunction;
  onLinkCargoClick: ArchivedCargoTableActionFunction;
  onUnarchiveCargoClick: ArchivedCargoTableActionFunction;
}

export function MyCargosArchivedTable({
  cargos,
  isLoading,
  activeTab,
  onRowClick,
  onLinkCargoClick,
  onUnarchiveCargoClick,
}: MyCargosArchivedTableProps) {
  const { classes } = useStyles();
  const { t } = useTranslation('myCargos');
  const columns = useColumns({ activeTab, onRowClick, onLinkCargoClick, onUnarchiveCargoClick });

  if (cargos.length === 0) {
    return (
      <EmptyTable
        title={t('table.empty.archived.title')}
        description={t('table.empty.archived.description')}
        img={<Image src="/images/empty-table.svg" alt="Empty cargos" />}
      />
    );
  }

  return (
    <Box className={classes.box}>
      <Table.Card isWideOnMobile>
        <Table data={cargos} columns={columns} isLoading={isLoading} onRowClick={onRowClick} />
      </Table.Card>
    </Box>
  );
}

import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { ArchivedCargoDTO } from 'api/generated/model';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { CargoShipmentNextEventBadge } from 'components/cargo-shipment-status-badge/CargoShipmentNextEventBadge';

import { MyCargoTab, ArchivedCargoTableActionFunction, isMyCargoPurchaseTab } from '../../../types';
import {
  BuyerArchivedDateCell,
  SellerArchivedDateCell,
  ArchivedVesselNameCell,
  ArchivedCompanyNameCell,
  ArchivedCargoIdRefIdCell,
  ArchivedLinkedEntitiesCell,
  MyCargosArchivedTableActionCell,
} from '../MyCargosArchivedTableCells';

const columnHelper = createColumnHelper<ArchivedCargoDTO>();

interface UseColumnsProps {
  activeTab: MyCargoTab;
  onRowClick: ArchivedCargoTableActionFunction;
  onLinkCargoClick: ArchivedCargoTableActionFunction;
  onUnarchiveCargoClick: ArchivedCargoTableActionFunction;
}

export const useColumns = ({ activeTab, onRowClick, onLinkCargoClick, onUnarchiveCargoClick }: UseColumnsProps) => {
  const { t } = useTranslation('myCargos');

  const columns: ColumnDef<ArchivedCargoDTO>[] = useMemo(
    () => [
      columnHelper.accessor('shortId', {
        enableSorting: true,
        cell: ArchivedCargoIdRefIdCell,
        header: t('table.headers.cargoIdRefId'),
      }),
      isMyCargoPurchaseTab(activeTab)
        ? columnHelper.accessor('buyerArchivedDate', {
            enableSorting: true,
            cell: BuyerArchivedDateCell,
            header: t('table.headers.archivedDate'),
          })
        : columnHelper.accessor('sellerArchivedDate', {
            enableSorting: true,
            cell: SellerArchivedDateCell,
            header: t('table.headers.archivedDate'),
          }),
      columnHelper.accessor('vesselName', {
        enableSorting: true,
        cell: ArchivedVesselNameCell,
        header: t('table.headers.vesselName'),
      }),
      columnHelper.accessor('companyName', {
        enableSorting: true,
        cell: ArchivedCompanyNameCell,
        header: t('table.headers.companyName'),
      }),
      columnHelper.accessor('globalEventsTransactionSide.cargoShipmentStatus', {
        enableSorting: true,
        header: t('table.headers.status'),
        cell: ({ getValue }) => <CargoShipmentNextEventBadge status={getValue()} />,
      }),
      columnHelper.accessor('linkedWorkflowShortId', {
        enableSorting: true,
        cell: ArchivedLinkedEntitiesCell,
        header: t('table.headers.linkedEntities'),
      }),
      columnHelper.accessor('id', {
        header: '',
        enableSorting: false,
        cell: (props) => (
          <MyCargosArchivedTableActionCell
            {...props}
            onRowClick={onRowClick}
            onLinkCargoClick={onLinkCargoClick}
            onUnarchiveCargoClick={onUnarchiveCargoClick}
          />
        ),
      }),
    ],
    [t, onRowClick, onLinkCargoClick]
  );

  return columns;
};

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import type { UseQueryOptions, QueryFunction, UseQueryResult, QueryKey } from '@tanstack/react-query';

import type {
  StandardDataSelectOptionsDTO,
  StandardProductDTO,
  StandardPortsDTO,
  StandardPortDTO,
  StandardPlantsDTO,
  StandardPlantDTO,
  DocumentTemplatesDTO,
  GetDocumentTemplatesParams,
  ProductGradeDTO,
  SearchProductByNameParams,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getStandarDataSelectOptions = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<StandardDataSelectOptionsDTO>> => {
  return axios.get(`/api/v1/masterdata`, options);
};

export const getGetStandarDataSelectOptionsQueryKey = () => [`/api/v1/masterdata`];

export type GetStandarDataSelectOptionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStandarDataSelectOptions>>
>;
export type GetStandarDataSelectOptionsQueryError = AxiosError<void>;

export const useGetStandarDataSelectOptions = <
  TData = Awaited<ReturnType<typeof getStandarDataSelectOptions>>,
  TError = AxiosError<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getStandarDataSelectOptions>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetStandarDataSelectOptionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStandarDataSelectOptions>>> = ({ signal }) =>
    getStandarDataSelectOptions({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getStandarDataSelectOptions>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getStandardProducts = (options?: AxiosRequestConfig): Promise<AxiosResponse<StandardProductDTO[]>> => {
  return axios.get(`/api/v1/masterdata/products`, options);
};

export const getGetStandardProductsQueryKey = () => [`/api/v1/masterdata/products`];

export type GetStandardProductsQueryResult = NonNullable<Awaited<ReturnType<typeof getStandardProducts>>>;
export type GetStandardProductsQueryError = AxiosError<void>;

export const useGetStandardProducts = <
  TData = Awaited<ReturnType<typeof getStandardProducts>>,
  TError = AxiosError<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getStandardProducts>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetStandardProductsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStandardProducts>>> = ({ signal }) =>
    getStandardProducts({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getStandardProducts>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getStandardProductById = (
  productId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<StandardProductDTO>> => {
  return axios.get(`/api/v1/masterdata/products/${productId}`, options);
};

export const getGetStandardProductByIdQueryKey = (productId: string) => [`/api/v1/masterdata/products/${productId}`];

export type GetStandardProductByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getStandardProductById>>>;
export type GetStandardProductByIdQueryError = AxiosError<void>;

export const useGetStandardProductById = <
  TData = Awaited<ReturnType<typeof getStandardProductById>>,
  TError = AxiosError<void>
>(
  productId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getStandardProductById>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetStandardProductByIdQueryKey(productId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStandardProductById>>> = ({ signal }) =>
    getStandardProductById(productId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getStandardProductById>>, TError, TData>(queryKey, queryFn, {
    enabled: !!productId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getStandardPorts = (options?: AxiosRequestConfig): Promise<AxiosResponse<StandardPortsDTO>> => {
  return axios.get(`/api/v1/masterdata/ports`, options);
};

export const getGetStandardPortsQueryKey = () => [`/api/v1/masterdata/ports`];

export type GetStandardPortsQueryResult = NonNullable<Awaited<ReturnType<typeof getStandardPorts>>>;
export type GetStandardPortsQueryError = AxiosError<void>;

export const useGetStandardPorts = <
  TData = Awaited<ReturnType<typeof getStandardPorts>>,
  TError = AxiosError<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getStandardPorts>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetStandardPortsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStandardPorts>>> = ({ signal }) =>
    getStandardPorts({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getStandardPorts>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getStandardPortById = (
  portId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<StandardPortDTO>> => {
  return axios.get(`/api/v1/masterdata/ports/${portId}`, options);
};

export const getGetStandardPortByIdQueryKey = (portId: string) => [`/api/v1/masterdata/ports/${portId}`];

export type GetStandardPortByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getStandardPortById>>>;
export type GetStandardPortByIdQueryError = AxiosError<void>;

export const useGetStandardPortById = <
  TData = Awaited<ReturnType<typeof getStandardPortById>>,
  TError = AxiosError<void>
>(
  portId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getStandardPortById>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetStandardPortByIdQueryKey(portId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStandardPortById>>> = ({ signal }) =>
    getStandardPortById(portId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getStandardPortById>>, TError, TData>(queryKey, queryFn, {
    enabled: !!portId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getStandardPlants = (options?: AxiosRequestConfig): Promise<AxiosResponse<StandardPlantsDTO>> => {
  return axios.get(`/api/v1/masterdata/plants`, options);
};

export const getGetStandardPlantsQueryKey = () => [`/api/v1/masterdata/plants`];

export type GetStandardPlantsQueryResult = NonNullable<Awaited<ReturnType<typeof getStandardPlants>>>;
export type GetStandardPlantsQueryError = AxiosError<void>;

export const useGetStandardPlants = <
  TData = Awaited<ReturnType<typeof getStandardPlants>>,
  TError = AxiosError<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getStandardPlants>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetStandardPlantsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStandardPlants>>> = ({ signal }) =>
    getStandardPlants({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getStandardPlants>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getStandardPlantById = (
  plantId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<StandardPlantDTO>> => {
  return axios.get(`/api/v1/masterdata/plants/${plantId}`, options);
};

export const getGetStandardPlantByIdQueryKey = (plantId: string) => [`/api/v1/masterdata/plants/${plantId}`];

export type GetStandardPlantByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getStandardPlantById>>>;
export type GetStandardPlantByIdQueryError = AxiosError<void>;

export const useGetStandardPlantById = <
  TData = Awaited<ReturnType<typeof getStandardPlantById>>,
  TError = AxiosError<void>
>(
  plantId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getStandardPlantById>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetStandardPlantByIdQueryKey(plantId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStandardPlantById>>> = ({ signal }) =>
    getStandardPlantById(plantId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getStandardPlantById>>, TError, TData>(queryKey, queryFn, {
    enabled: !!plantId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getDocumentTemplates = (
  params?: GetDocumentTemplatesParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DocumentTemplatesDTO[]>> => {
  return axios.get(`/api/v1/masterdata/tasks`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetDocumentTemplatesQueryKey = (params?: GetDocumentTemplatesParams) => [
  `/api/v1/masterdata/tasks`,
  ...(params ? [params] : []),
];

export type GetDocumentTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof getDocumentTemplates>>>;
export type GetDocumentTemplatesQueryError = AxiosError<void>;

export const useGetDocumentTemplates = <
  TData = Awaited<ReturnType<typeof getDocumentTemplates>>,
  TError = AxiosError<void>
>(
  params?: GetDocumentTemplatesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getDocumentTemplates>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDocumentTemplatesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDocumentTemplates>>> = ({ signal }) =>
    getDocumentTemplates(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getDocumentTemplates>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getProductGradesWithProperties = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ProductGradeDTO[]>> => {
  return axios.get(`/api/v1/masterdata/product-grades`, options);
};

export const getGetProductGradesWithPropertiesQueryKey = () => [`/api/v1/masterdata/product-grades`];

export type GetProductGradesWithPropertiesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProductGradesWithProperties>>
>;
export type GetProductGradesWithPropertiesQueryError = AxiosError<void>;

export const useGetProductGradesWithProperties = <
  TData = Awaited<ReturnType<typeof getProductGradesWithProperties>>,
  TError = AxiosError<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getProductGradesWithProperties>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProductGradesWithPropertiesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductGradesWithProperties>>> = ({ signal }) =>
    getProductGradesWithProperties({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getProductGradesWithProperties>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getProductGradeProperties = (
  gradeId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ProductGradeDTO>> => {
  return axios.get(`/api/v1/masterdata/product-grades/${gradeId}`, options);
};

export const getGetProductGradePropertiesQueryKey = (gradeId: string) => [
  `/api/v1/masterdata/product-grades/${gradeId}`,
];

export type GetProductGradePropertiesQueryResult = NonNullable<Awaited<ReturnType<typeof getProductGradeProperties>>>;
export type GetProductGradePropertiesQueryError = AxiosError<void>;

export const useGetProductGradeProperties = <
  TData = Awaited<ReturnType<typeof getProductGradeProperties>>,
  TError = AxiosError<void>
>(
  gradeId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProductGradeProperties>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProductGradePropertiesQueryKey(gradeId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductGradeProperties>>> = ({ signal }) =>
    getProductGradeProperties(gradeId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getProductGradeProperties>>, TError, TData>(queryKey, queryFn, {
    enabled: !!gradeId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const searchProductByName = (
  params: SearchProductByNameParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<StandardProductDTO>> => {
  return axios.get(`/api/v1/masterdata/product-search`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getSearchProductByNameQueryKey = (params: SearchProductByNameParams) => [
  `/api/v1/masterdata/product-search`,
  ...(params ? [params] : []),
];

export type SearchProductByNameQueryResult = NonNullable<Awaited<ReturnType<typeof searchProductByName>>>;
export type SearchProductByNameQueryError = AxiosError<void>;

export const useSearchProductByName = <
  TData = Awaited<ReturnType<typeof searchProductByName>>,
  TError = AxiosError<void>
>(
  params: SearchProductByNameParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof searchProductByName>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSearchProductByNameQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchProductByName>>> = ({ signal }) =>
    searchProductByName(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof searchProductByName>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

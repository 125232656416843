import { Box, Group } from '@mantine/core';
import { RiArrowRightLine } from 'react-icons/ri';
import { Trans } from 'next-i18next';
import { createContext, ReactNode, useContext } from 'react';

import { SimplifiedTFunction } from '@/common/i18n';

interface HighlightProviderContextState {
  highlight: boolean;
}

const HighlightProviderContext = createContext<HighlightProviderContextState>({ highlight: false });

interface HighlightProviderProps extends HighlightProviderContextState {
  children: ReactNode;
}

export function HighlightProvider(props: HighlightProviderProps) {
  const { children, ...rest } = props;
  return <HighlightProviderContext.Provider value={rest}>{children}</HighlightProviderContext.Provider>;
}

interface HighlightSentenceDiffProps {
  prevArgs: Record<string, string | number>;
  currentArgs: Record<string, string | number>;
  i18nKey: string;
  t: SimplifiedTFunction;
}

export function HighlightSentenceDiff(props: HighlightSentenceDiffProps) {
  const { prevArgs, currentArgs, i18nKey, t } = props;
  const { highlight } = useContext(HighlightProviderContext);
  const allArgKeys = [...new Set([...Object.keys(currentArgs || {}), ...Object.keys(prevArgs || {})])];
  const components = Object.fromEntries(
    allArgKeys.map((key) => [
      key,
      <HighlightSingleDiff key={key} prev={prevArgs?.[key]} current={currentArgs?.[key]} />,
    ])
  );

  const isPrevArgs =
    !!prevArgs && Object.values(prevArgs).some((value) => typeof value !== 'undefined' && value !== null);
  const isCurrentArgs =
    !!currentArgs && Object.values(currentArgs).some((value) => typeof value !== 'undefined' && value !== null);

  if (!isPrevArgs && !isCurrentArgs) {
    return null;
  }

  if (!highlight) {
    return (
      <span>
        {/* TODO: Find better way to handle TS2589: Type instantiation is excessively deep and possibly infinite. */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Trans t={t} i18nKey={i18nKey} components={components} />
      </span>
    );
  }

  if (isPrevArgs && !isCurrentArgs) {
    return (
      <Box component="span" bg="red.0">
        {/* TODO: Find better way to handle TS2589: Type instantiation is excessively deep and possibly infinite. */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Trans t={t} i18nKey={i18nKey} components={components} />
      </Box>
    );
  }

  if (!isPrevArgs && isCurrentArgs) {
    return (
      <Box component="span" bg="green.0">
        {/* TODO: Find better way to handle TS2589: Type instantiation is excessively deep and possibly infinite. */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Trans t={t} i18nKey={i18nKey} components={components} />
      </Box>
    );
  }
  return (
    <span>
      {/* TODO: Find better way to handle TS2589: Type instantiation is excessively deep and possibly infinite. */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Trans t={t} i18nKey={i18nKey} components={components} />
    </span>
  );
}

interface HighlightSingleDiffProps {
  prev?: string | number;
  current?: string | number;
}

export function HighlightSingleDiff(props: HighlightSingleDiffProps) {
  const { prev, current } = props;
  const { highlight } = useContext(HighlightProviderContext);

  if (typeof prev === 'undefined' && typeof current === 'undefined') {
    return null;
  }

  if (prev === current || !highlight) {
    return <>{current}</>;
  }

  if (typeof current === 'undefined') {
    return (
      <Box component="span" bg="yellow.0">
        {prev}
      </Box>
    );
  }

  if (typeof prev === 'undefined') {
    return (
      <Box component="span" bg="green.0">
        {current}
      </Box>
    );
  }

  if (typeof prev !== 'undefined' && (typeof current === 'undefined' || current === '')) {
    return (
      <Box component="span" bg="red.1">
        {prev}
      </Box>
    );
  }

  return (
    <Group spacing={4} sx={{ display: 'inline-flex', margin: 0 }}>
      <Box bg="yellow.0">{prev}</Box>
      <RiArrowRightLine />
      <Box bg="green.0">{current}</Box>
    </Group>
  );
}

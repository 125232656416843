/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type WorkflowSubscriptionStatus = (typeof WorkflowSubscriptionStatus)[keyof typeof WorkflowSubscriptionStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkflowSubscriptionStatus = {
  REQUESTED: 'REQUESTED',
  PRICE_REQUESTED: 'PRICE_REQUESTED',
  PRICE_AVAILABLE: 'PRICE_AVAILABLE',
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED',
} as const;

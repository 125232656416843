import { Button, Group } from '@mantine/core';

type ToggleButtonProps = {
  options: { key: string; value: string }[];
  selected: string;
  onClick: (key: string) => void;
};

export const ToggleButton = ({ options, selected, onClick }: ToggleButtonProps) => {
  return (
    <Group bg="gray.3" display="inline-flex" spacing={0} py={{ base: 4 }} px={{ base: 2 }} style={{ borderRadius: 6 }}>
      {options.map((option) => (
        <Button
          key={option.key}
          onClick={() => onClick(option.key)}
          bg={selected === option.key ? 'deepBlue.5' : 'gray.3'}
          variant={selected === option.key ? 'filled' : 'subtle'}
        >
          {option.value}
        </Button>
      ))}
    </Group>
  );
};

import { Group, Stack } from '@mantine/core';
import { DateRangePicker, DateRangePickerValue } from '@mantine/dates';
import { useTranslation } from 'next-i18next';
import { RiCalendar2Line } from 'react-icons/ri';
import { UserRole } from 'api/generated/model';

import { TwoColumnsLayout } from '@/components';
import { useStandardData } from '@/views/freight-recap-form';
import { getBasePercentageProps, NumberInput, Select, SelectWithoutMemo } from '@/views/recap-form';
import { getFixedNumberProps } from '@/common/get-fixed-number-props';
import { useHasPermission } from '@/core';
import { getThousandSeparatedNumberProps } from '@/common/get-thousand-seperated-number';

import { useFreightRecapFormState } from '../state/FreightRecapFormProvider';

export function FreightRecapFormProductGroup() {
  const { t } = useTranslation('freightRecapForm');
  const { masterdata, form, getInputProps } = useFreightRecapFormState();
  const { data, isLoading } = useStandardData();
  const { hasUserRolePermission } = useHasPermission();
  const laycanFormValue = form.values.product.laycan || [null, null];
  const laycanInputValue: DateRangePickerValue = [
    laycanFormValue?.[0] ? new Date(laycanFormValue[0]) : null,
    laycanFormValue?.[1] ? new Date(laycanFormValue[1]) : null,
  ];

  return (
    <>
      {!isLoading && (
        <TwoColumnsLayout>
          <Stack justify="space-between">
            <Group spacing={8} noWrap>
              <SelectWithoutMemo
                horizontal
                label={t('labels.commodity')}
                data={data.dropdowns.productNames}
                data-testid="product-name"
                disabled={!hasUserRolePermission([UserRole.BUYER, UserRole.SELLER])}
                {...getInputProps('product.name')}
                onChange={(value) => {
                  form.setFieldValue('product.packaging', '');
                  form.setFieldValue('product.grade', '');
                  getInputProps('product.name').onChange(value);
                }}
              />

              <SelectWithoutMemo
                horizontal
                label={t('labels.packaging')}
                data={form.values.product.name ? data.dropdowns.packagingsByProduct[form.values.product.name] : []}
                data-testid="product-packaging"
                disabled={!hasUserRolePermission([UserRole.BUYER, UserRole.SELLER]) || !form.values.product.name}
                {...getInputProps('product.packaging')}
              />
            </Group>

            <SelectWithoutMemo
              horizontal
              label={t('labels.quality')}
              data={form.values.product.name ? data.dropdowns.gradesByProduct[form.values.product.name] : []}
              data-testid="product-grade"
              disabled={!hasUserRolePermission([UserRole.BUYER, UserRole.SELLER]) || !form.values.product.name}
              {...getInputProps('product.grade')}
            />

            <NumberInput
              label={t('labels.quantity')}
              min={0}
              rightSection="mts"
              {...getThousandSeparatedNumberProps()}
              data-testid="product-quantity"
              {...getInputProps('product.quantity')}
            />

            <Group noWrap align="flex-end">
              <NumberInput
                min={0}
                label={t('labels.minTolerance')}
                data-testid="product-minTolerance"
                {...getBasePercentageProps()}
                {...getInputProps('product.minTolerance')}
              />
              <NumberInput
                label={t('labels.maxTolerance')}
                min={0}
                data-testid="product-maxTolerance"
                {...getBasePercentageProps()}
                {...getInputProps('product.maxTolerance')}
              />
              <Select
                data={masterdata.freightToleranceOptions}
                withAsterisk={false}
                data-testid="product-toleranceOption"
                {...getInputProps('product.option')}
              />
            </Group>

            <DateRangePicker
              key="laycan"
              icon={<RiCalendar2Line />}
              minDate={new Date()}
              label={t('labels.estimatedLoadingLaycan')}
              value={laycanInputValue}
              data-testid="estimatedLaycan"
              onChange={(value) => {
                if (value.every((date) => !date)) {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  getInputProps('product.laycan' as any).onChange(undefined);
                  form.setFieldError('product.laycan', t('errors.required'));
                  return;
                }
                form.setFieldError('product.laycan.0', undefined);
                form.setFieldError('product.laycan.1', undefined);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                getInputProps('product.laycan' as any).onChange([value[0]?.toISOString(), value[1]?.toISOString()]);
              }}
              error={form.errors['product.laycan'] || form.errors['product.laycan.0']}
            />
          </Stack>

          <Stack>
            <Select
              data={data.ports}
              label={t('labels.loadingPort')}
              size="md"
              grow={false}
              data-testid="product-loadingPortName"
              {...getInputProps('product.loadingPort')}
            />
            <Group noWrap spacing={8} mb={16}>
              <NumberInput
                rightSection="m"
                min={0}
                label={t('labels.guaranteedDraft')}
                data-testid="product-loadingPortDraft"
                {...getInputProps('product.loadingPortGuaranteedDraft')}
              />
              <NumberInput
                rightSection="m"
                min={0}
                label={t('labels.guaranteedLoa')}
                data-testid="product-loadingPortLOA"
                {...getInputProps('product.loadingPortGuaranteedLOA')}
              />
              <NumberInput
                rightSection="m"
                min={0}
                label={t('labels.guaranteedBeam')}
                data-testid="product-loadingPortBeam"
                {...getInputProps('product.loadingPortGuaranteedBeam')}
              />
            </Group>

            <Select
              data={data.ports}
              label={t('labels.dischargingPort')}
              size="md"
              grow={false}
              data-testid="product-dischargingPortName"
              {...getInputProps('product.dischargingPort')}
            />
            <Group noWrap spacing={8}>
              <NumberInput
                rightSection="m"
                min={0}
                label={t('labels.guaranteedDraft')}
                data-testid="product-dischargingPortDraft"
                {...getInputProps('product.dischargingPortGuaranteedDraft')}
              />
              <NumberInput
                rightSection="m"
                min={0}
                label={t('labels.guaranteedLoa')}
                data-testid="product-dischargingPortLOA"
                {...getInputProps('product.dischargingPortGuaranteedLOA')}
              />
              <NumberInput
                rightSection="m"
                min={0}
                label={t('labels.guaranteedBeam')}
                data-testid="product-dischargingPortBeam"
                {...getInputProps('product.dischargingPortGuaranteedBeam')}
              />
            </Group>
          </Stack>
        </TwoColumnsLayout>
      )}
    </>
  );
}

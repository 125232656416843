import { BadgeProps } from '@mantine/core';

import { ThemeComponent } from './types';

export const Badge: ThemeComponent<BadgeProps> = {
  styles: (theme, params) => ({
    root: {
      cursor: 'default',
      borderRadius: theme.radius.sm,
      fontWeight: 400,
      height: 'auto',
      fontSize: 16,
      lineHeight: '24px',
      padding: '4px 8px',
      color: theme.colors.gray[8],
      textTransform: 'none',
      background: params.color ? theme.colors[params.color][0] : theme.colors.yellow[0],
      borderColor: params.color ? theme.colors[params.color][1] : theme.colors.yellow[1],

      ...(params.size === 'xs' && {
        padding: '2px 8px',
        fontSize: 14,
        lineHeight: '18px',
        fontWeight: 500,
        color: theme.colors.gray[7],
      }),

      [theme.fn.smallerThan('xs')]: {
        fontSize: 12,
        lineHeight: '18px',
        padding: '2px 8px 4px 8px',
      },
    },
    inner: {
      [theme.fn.smallerThan('xs')]: {
        textAlign: 'center',
        textOverflow: 'unset',
        overflowWrap: 'break-word',
        whiteSpace: 'break-spaces',
      },
    },
  }),
};

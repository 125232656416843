import { FreightNegotiationStepStatusLabel, NegotiationStatus } from 'api/generated/model';

export function isRequestFreightDetailsAvailable(status: FreightNegotiationStepStatusLabel) {
  const allowedStatuses: FreightNegotiationStepStatusLabel[] = [
    FreightNegotiationStepStatusLabel.RFQ_RECEIVED,
    FreightNegotiationStepStatusLabel.RFQ_SENT,
  ];

  return allowedStatuses.includes(status);
}

export function isCounterOfferAvailable(status: FreightNegotiationStepStatusLabel) {
  const allowedStatuses: FreightNegotiationStepStatusLabel[] = [
    FreightNegotiationStepStatusLabel.OFFER_RECEIVED,
    FreightNegotiationStepStatusLabel.COUNTER_OFFER_RECEIVED,
    FreightNegotiationStepStatusLabel.DRAFT,
  ];

  return allowedStatuses.includes(status);
}

export function isAcceptOfferAvailable(status: FreightNegotiationStepStatusLabel) {
  const allowedStatuses: FreightNegotiationStepStatusLabel[] = [
    FreightNegotiationStepStatusLabel.REVIEW_RECEIVED,
    FreightNegotiationStepStatusLabel.COUNTER_OFFER_RECEIVED,
    FreightNegotiationStepStatusLabel.OFFER_RECEIVED,
  ];

  return allowedStatuses.includes(status);
}

export function isCreateOfferAvailable(status: FreightNegotiationStepStatusLabel) {
  const allowedStatuses: FreightNegotiationStepStatusLabel[] = [
    FreightNegotiationStepStatusLabel.RFQ_RECEIVED,
    FreightNegotiationStepStatusLabel.DRAFT,
  ];

  return allowedStatuses.includes(status);
}

export function isCancelNegotiationAvailable(status: FreightNegotiationStepStatusLabel) {
  const allowedStatuses: FreightNegotiationStepStatusLabel[] = [
    FreightNegotiationStepStatusLabel.COUNTER_OFFER_RECEIVED,
    FreightNegotiationStepStatusLabel.COUNTER_OFFER_SENT,
    FreightNegotiationStepStatusLabel.DRAFT,
    FreightNegotiationStepStatusLabel.OFFER_RECEIVED,
    FreightNegotiationStepStatusLabel.OFFER_SENT,
    FreightNegotiationStepStatusLabel.REVIEW_RECEIVED,
    FreightNegotiationStepStatusLabel.RFQ_RECEIVED,
    FreightNegotiationStepStatusLabel.RFQ_SENT,
  ];

  return allowedStatuses.includes(status);
}

export function isPreviewAvailable(status: FreightNegotiationStepStatusLabel) {
  const allowedStatuses: FreightNegotiationStepStatusLabel[] = [
    FreightNegotiationStepStatusLabel.COUNTER_OFFER_RECEIVED,
    FreightNegotiationStepStatusLabel.COUNTER_OFFER_SENT,
    FreightNegotiationStepStatusLabel.OFFER_RECEIVED,
    FreightNegotiationStepStatusLabel.OFFER_SENT,
    FreightNegotiationStepStatusLabel.REVIEW_RECEIVED,
    FreightNegotiationStepStatusLabel.REVIEW_SENT,
    FreightNegotiationStepStatusLabel.RFQ_RECEIVED,
    FreightNegotiationStepStatusLabel.RFQ_SENT,
    FreightNegotiationStepStatusLabel.TRADER_ACCEPTED,
    FreightNegotiationStepStatusLabel.CHARTERER_ACCEPTED,
  ];

  return allowedStatuses.includes(status);
}

export function isFreightOfferAccepted(status: FreightNegotiationStepStatusLabel) {
  const allowedStatuses: FreightNegotiationStepStatusLabel[] = [
    FreightNegotiationStepStatusLabel.TRADER_ACCEPTED,
    FreightNegotiationStepStatusLabel.CHARTERER_ACCEPTED,
    FreightNegotiationStepStatusLabel.REVIEW_RECEIVED,
    FreightNegotiationStepStatusLabel.REVIEW_SENT,
  ];

  return allowedStatuses.includes(status);
}

export const isTimerVisible = (status: FreightNegotiationStepStatusLabel) =>
  (
    [
      FreightNegotiationStepStatusLabel.REVIEW_RECEIVED,
      FreightNegotiationStepStatusLabel.REVIEW_SENT,
      FreightNegotiationStepStatusLabel.OFFER_RECEIVED,
      FreightNegotiationStepStatusLabel.OFFER_SENT,
      FreightNegotiationStepStatusLabel.COUNTER_OFFER_RECEIVED,
      FreightNegotiationStepStatusLabel.COUNTER_OFFER_SENT,
      FreightNegotiationStepStatusLabel.DRAFT,
    ] as FreightNegotiationStepStatusLabel[]
  ).includes(status);

export const getIsSingleStep = (status: FreightNegotiationStepStatusLabel) => {
  return (
    [
      NegotiationStatus.DRAFT,
      NegotiationStatus.OFFER_SENT,
      NegotiationStatus.OFFER_RECEIVED,
      NegotiationStatus.RFQ_SENT,
      NegotiationStatus.RFQ_RECEIVED,
    ] as FreightNegotiationStepStatusLabel[]
  ).includes(status);
};

import { CompanyWithContactAndProduct, UserCompanyStatus } from 'api/generated/model';

export const NON_PASSIVE_COMPANY_STATUS = Object.values(UserCompanyStatus).filter(
  (status) => status !== UserCompanyStatus.PASSIVE
);

export const NON_PASSIVE_NON_PRIVATE_COMPANY_STATUS = Object.values(UserCompanyStatus).filter(
  (status) => status !== UserCompanyStatus.PASSIVE && status != UserCompanyStatus.PRIVATE
);

/**
 * Checks if there are any companies with the status of PRIVATE, SHORTLISTED, or PASSIVE.
 * @param companies Array of CompanyWithContact objects to check.
 * @returns True if at least one company with the specified status exists; otherwise, false.
 */
export function hasCompaniesByPrivateAndShortlistedStatus(companies: CompanyWithContactAndProduct[]): boolean {
  return companies.some(
    (company) =>
      company.status === UserCompanyStatus.PRIVATE ||
      company.status === UserCompanyStatus.SHORTLISTED ||
      company.status === UserCompanyStatus.PASSIVE
  );
}

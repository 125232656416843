import { createContext, useContext, useState, ReactNode } from 'react';

interface PageDataContextType {
  pageData: any | null;
  setPageData: (data: any) => void;
}

const PageDataContext = createContext<PageDataContextType | null>(null);

export function usePageDataContext() {
  return useContext(PageDataContext);
}

export const PageDataProvider = ({ children }: { children: ReactNode }) => {
  const [pageData, setPageData] = useState(null);

  return <PageDataContext.Provider value={{ pageData, setPageData }}>{children}</PageDataContext.Provider>;
};

import { ActionIcon, Group, MantineColor, Paper, Popover, PopoverProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ReactElement, cloneElement } from 'react';
import { RiCheckFill, RiCloseFill } from 'react-icons/ri';
import { IconType } from 'react-icons/lib';

import { BackgroundIcon, Text } from '@/components';

export interface ConfirmationPopoverProps extends PopoverProps {
  onConfirm: VoidFunction;
  children: ReactElement;
  icon: IconType;
  iconColor: MantineColor;
  content: ReactElement | string;
  color?: MantineColor;
}

export function ConfirmationPopover(props: ConfirmationPopoverProps) {
  const { children, onConfirm, icon, iconColor, content, color, ...rest } = props;
  const [opened, { close, open }] = useDisclosure(false);
  const handleConfirm = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    onConfirm();
  };
  const handleCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    close();
  };

  return (
    <>
      <Popover
        position="bottom"
        shadow="md"
        opened={opened}
        onClose={close}
        withArrow
        trapFocus
        returnFocus={true}
        styles={{ dropdown: { left: '0 !important', backgroundColor: color } }}
        {...rest}
      >
        <Popover.Target>{cloneElement(children, { onClick: open })}</Popover.Target>
        <Popover.Dropdown p={12} data-ignore-outside-clicks>
          <Paper>
            <Group noWrap sx={{ backgroundColor: color }}>
              <BackgroundIcon
                sx={{ flexShrink: 0, alignSelf: 'flex-start' }}
                icon={icon}
                color={iconColor}
                w={40}
                h={40}
                size={20}
              />
              {typeof content === 'string' ? (
                <Text size="XS" weight={600} color="gray.7" sx={{ flexGrow: 1 }}>
                  {content}
                </Text>
              ) : (
                content
              )}
              <Group ml="auto" noWrap sx={{ alignSelf: 'flex-end' }}>
                <ActionIcon color="red.8" size={24} onClick={handleCancel} variant="filled">
                  <RiCloseFill />
                </ActionIcon>
                <ActionIcon color="green.8" size={24} onClick={handleConfirm} variant="filled">
                  <RiCheckFill />
                </ActionIcon>
              </Group>
            </Group>
          </Paper>
        </Popover.Dropdown>
      </Popover>
    </>
  );
}

import { Button, Group, Stack, Textarea } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';

import { Title } from '@/components';

export const schemaCounterpartyFeedback = z.object({
  cancelMessage: z.string(),
});

export type CounterpartyFeedback = z.infer<typeof schemaCounterpartyFeedback>;

export interface CounterpartyFeedbackFormProps {
  onBackClick: (values: CounterpartyFeedback) => void;
  onNextClick: (values: CounterpartyFeedback) => void;
  initialFormValues: CounterpartyFeedback;
}

export function CounterpartyFeedbackForm(props: CounterpartyFeedbackFormProps) {
  const { onBackClick, onNextClick, initialFormValues } = props;
  const { t } = useTranslation('common');
  const form = useForm({
    initialValues: initialFormValues,
    validate: zodResolver(schemaCounterpartyFeedback),
  });

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onNextClick(values);
      })}
    >
      <Stack spacing={24}>
        <Title color="gray.6" size="S" align="center">
          {t('feedbackToCounterparty.title')}
        </Title>
        <Textarea placeholder={t('feedbackToCounterparty.placeholder')} {...form.getInputProps('cancelMessage')} />
        <Group grow>
          <Button onClick={() => onBackClick(form.values)} variant="outline">
            {t('buttons.back')}
          </Button>
          <Button type="submit" data-testid="next-btn-cancel-cargo-popup">
            {t('buttons.next')}
          </Button>
        </Group>
      </Stack>
    </form>
  );
}

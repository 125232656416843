import { MantineThemeOverride } from '@mantine/core';

export const globalStyles: MantineThemeOverride['globalStyles'] = () => ({
  body: {
    letterSpacing: '-0.02em',
    whiteSpace: 'pre-line',
  },
  a: {
    textDecoration: 'unset',
    color: 'inherit',
  },
});

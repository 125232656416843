// eslint-disable-next-line no-restricted-imports
import { Title as MnTitle, TitleProps as MnTitleProps, createStyles, MantineTheme } from '@mantine/core';
import { forwardRef } from 'react';

type TitleSize = 'S' | 'M' | 'L' | 'XL' | '2XL' | '3XL' | '4XL' | '5XL';

const getNewHeaderStyles = (size: TitleSize, theme: MantineTheme) => {
  switch (size) {
    case '5XL':
      return {
        fontSize: '48px',
        lineHeight: '56px',
        [theme.fn.smallerThan('xs')]: {
          fontSize: '40px',
          lineHeight: '48px',
        },
      };
    case '4XL':
      return {
        fontSize: '40px',
        lineHeight: '48px',
        [theme.fn.smallerThan('xs')]: {
          fontSize: '20px',
          lineHeight: '24px',
        },
      };
    case '3XL':
      return {
        fontSize: '32px',
        lineHeight: '38px',
        [theme.fn.smallerThan('xs')]: {
          fontSize: '20px',
          lineHeight: '24px',
        },
      };
    case '2XL':
      return {
        fontSize: '28px',
        lineHeight: '36px',
        [theme.fn.smallerThan('xs')]: {
          fontSize: '16px',
          lineHeight: '24px',
        },
      };
    case 'XL':
      return {
        fontSize: '24px',
        lineHeight: '32px',
        [theme.fn.smallerThan('xs')]: {
          fontSize: '16px',
          lineHeight: '24px',
        },
      };
    case 'L':
      return {
        fontSize: '18px',
        lineHeight: '26px',
      };
    case 'M':
      return {
        fontSize: '16px',
        lineHeight: '24px',
      };
    case 'S':
      return {
        fontSize: '14px',
        lineHeight: '22px',
      };
    default:
      return;
  }
};

export interface TitleProps extends MnTitleProps {
  size?: TitleSize;
}

const useNewStyles = createStyles((theme, params: TitleProps) => ({
  headline: {
    ...getNewHeaderStyles(params.size, theme),
  },
}));

export const Title = forwardRef<HTMLHeadingElement, TitleProps>(function Title(props, ref) {
  const { children, className, classNames, ...rest } = props;
  const { classes, cx } = useNewStyles({ size: props.size, order: props.order });

  return (
    <MnTitle ref={ref} className={cx(classes.headline, className, classNames)} {...rest}>
      {children}
    </MnTitle>
  );
});

import { z } from 'zod';

import {
  CargoTenderShipmentFrequency,
  CargoTransportation,
  TransactionType,
  zDateRange,
  zTolerancePercentage,
} from '../types';

export const cargoTenderProductDestinationSchema = z.object({
  key: z.string().optional(),
  managedPlantId: z.string(),
  companyPortId: z.string(),
  preferredDestinations: z.string().optional(),
  pricePreference: z.number().optional(),
});

export const cargoTenderProductSchema = z.object({
  key: z.string().optional(),
  companyProductId: z.string(),
  // Fixed quantity
  fixedQuantity: z.object({
    totalQuantity: z.number(),
    shipmentToleranceMin: zTolerancePercentage,
    shipmentToleranceMax: zTolerancePercentage,
    shipmentsNumberMin: z.number(),
    shipmentsNumberMax: z.number(),
    shipmentsFrequency: z.nativeEnum(CargoTenderShipmentFrequency),
  }),
  // Optional quantity
  optionalQuantity: z.object({
    totalQuantity: z.number(),
    shipmentToleranceMin: zTolerancePercentage,
    shipmentToleranceMax: zTolerancePercentage,
    shipmentsNumberMax: z.number(),
  }),
  // Port / Plant destinations
  destinations: z.array(cargoTenderProductDestinationSchema),
});

export const cargoTenderSchema = z.object({
  contractPeriod: zDateRange,
  transportation: z.nativeEnum(CargoTransportation),

  // Products
  products: z.array(cargoTenderProductSchema),

  // Due dates
  offerSubmissionDeadline: z.date(),
  offerDecisionDeadline: z.date(),

  transactionSide: z.nativeEnum(TransactionType),
  companyNetworkId: z.string().optional(),
});

export type CargoTenderProductDestination = z.infer<typeof cargoTenderProductDestinationSchema>;
export type CargoTenderProduct = z.infer<typeof cargoTenderProductSchema>;
export type CargoTenderForm = z.infer<typeof cargoTenderSchema>;

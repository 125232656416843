import { Anchor, Flex, FlexProps, Stack } from '@mantine/core';
// eslint-disable-next-line no-restricted-imports
import { Image, Text as MantineText } from '@mantine/core';
import NextLink from 'next/link';
import { Trans, useTranslation } from 'next-i18next';

import { MAIL_TO_CONTACT_URL } from '@/common/consts';
import { Text, Title } from '@/components';

export interface BaseErrorProps extends FlexProps {
  code: 403 | 404 | 500;
}

export function BaseError(props: BaseErrorProps) {
  const { code, ...rest } = props;
  const { t } = useTranslation('common');

  const transKey = `error.${code}.message`;

  return (
    <Flex justify="center" align="center" {...rest}>
      <MantineText color="gray.1" size={320} sx={{ position: 'absolute' }}>
        {code}
      </MantineText>
      <Stack sx={{ zIndex: 1, maxWidth: 400 }} align="center" justify="center" spacing={8}>
        <Title color="primary.1" size="5XL" weight={600}>
          {code}
        </Title>
        <Title color="gray.8" size="3XL" weight={600}>
          {/* TODO: Add proper translations. Currently _error page doesn't load the translations file */}
          {code === 500 ? 'Internal Server Error' : t(`error.${code}.title`)}
        </Title>
        {/* TODO: Add proper translations. Currently _error page doesn't load the translations file */}
        {code === 500 ? (
          <Text color="gray.8" align="center">
            Something went wrong. Try to refresh this page or feel free to{' '}
            <Anchor component={NextLink} href={MAIL_TO_CONTACT_URL}>
              contact us
            </Anchor>{' '}
            if the problem persist.
          </Text>
        ) : (
          <Text color="gray.8" align="center">
            <Trans
              t={t}
              i18nKey={transKey}
              components={{
                contact: <Anchor component={NextLink} href={MAIL_TO_CONTACT_URL} />,
              }}
            />
          </Text>
        )}
        {code === 403 ? <Image src="/images/403.png" alt="403" /> : <Image src="/images/404.png" alt="404" />}
        {code === 403 && (
          <Text color="gray.8" size="M" mt={104}>
            <Trans t={t} i18nKey="assistance">
              Need assistance?
              {
                <Anchor
                  href={MAIL_TO_CONTACT_URL}
                  sx={(theme) => ({ fontWeight: 600, color: theme.fn.primaryColor() })}
                  key="link"
                />
              }
            </Trans>
          </Text>
        )}
      </Stack>
    </Flex>
  );
}

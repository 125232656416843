import { NegotiationStatus } from 'api/generated/model';

export const isRfqStatus = (status: NegotiationStatus) =>
  ([NegotiationStatus.RFQ_RECEIVED, NegotiationStatus.RFQ_SENT] as NegotiationStatus[]).includes(status);

export const isFinalStatus = (status: NegotiationStatus) =>
  ([NegotiationStatus.REVIEW_RECEIVED] as NegotiationStatus[]).includes(status);

export const isActionStatus = (status: NegotiationStatus) =>
  (
    [
      NegotiationStatus.OFFER_RECEIVED,
      NegotiationStatus.COUNTER_OFFER_RECEIVED,
      NegotiationStatus.REVIEW_RECEIVED,
    ] as NegotiationStatus[]
  ).includes(status);

export const isIgnoredNegotiationStatus = (status: NegotiationStatus) =>
  (
    [
      NegotiationStatus.CANCELED,
      NegotiationStatus.BUYER_CANCELED,
      NegotiationStatus.SELLER_CANCELED,
      NegotiationStatus.INVALID_BUYER,
      NegotiationStatus.INVALID_SELLER,
      NegotiationStatus.EXPIRED,
      NegotiationStatus.REJECTED,
      NegotiationStatus.REJECTED_BUYER,
      NegotiationStatus.REJECTED_SELLER,
      NegotiationStatus.BUYER_ACCEPTED,
      NegotiationStatus.SELLER_ACCEPTED,
    ] as NegotiationStatus[]
  ).includes(status);

export const isCancelAvailable = (status: NegotiationStatus) =>
  !(
    [
      NegotiationStatus.CANCELED,
      NegotiationStatus.BUYER_CANCELED,
      NegotiationStatus.SELLER_CANCELED,
      NegotiationStatus.INVALID_BUYER,
      NegotiationStatus.INVALID_SELLER,
      NegotiationStatus.EXPIRED,
      NegotiationStatus.REJECTED,
      NegotiationStatus.REJECTED_BUYER,
      NegotiationStatus.REJECTED_SELLER,
      NegotiationStatus.BUYER_ACCEPTED,
      NegotiationStatus.SELLER_ACCEPTED,
    ] as NegotiationStatus[]
  ).includes(status);

export const isCancelNegotiationStatus = (status: NegotiationStatus) =>
  (
    [
      NegotiationStatus.CANCELED,
      NegotiationStatus.BUYER_CANCELED,
      NegotiationStatus.SELLER_CANCELED,
    ] as NegotiationStatus[]
  ).includes(status);

export const isDraft = (status: NegotiationStatus) => status === NegotiationStatus.DRAFT;

export const isTimerVisible = (status: NegotiationStatus) =>
  (
    [
      NegotiationStatus.REVIEW_RECEIVED,
      NegotiationStatus.REVIEW_SENT,
      NegotiationStatus.OFFER_RECEIVED,
      NegotiationStatus.OFFER_SENT,
      NegotiationStatus.COUNTER_OFFER_RECEIVED,
      NegotiationStatus.COUNTER_OFFER_SENT,
    ] as NegotiationStatus[]
  ).includes(status);

export const isExtendAvailable = (status: NegotiationStatus) =>
  (
    [
      NegotiationStatus.OFFER_SENT,
      NegotiationStatus.OFFER_RECEIVED,
      NegotiationStatus.COUNTER_OFFER_SENT,
      NegotiationStatus.COUNTER_OFFER_RECEIVED,
      NegotiationStatus.REVIEW_SENT,
      NegotiationStatus.REVIEW_RECEIVED,
    ] as NegotiationStatus[]
  ).includes(status);

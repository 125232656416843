/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoTenderFreightConditionsDTOFreightPeriodTerms =
  (typeof CargoTenderFreightConditionsDTOFreightPeriodTerms)[keyof typeof CargoTenderFreightConditionsDTOFreightPeriodTerms];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoTenderFreightConditionsDTOFreightPeriodTerms = {
  PWWD: 'PWWD',
  PER_WORKING_HOUR: 'PER_WORKING_HOUR',
} as const;

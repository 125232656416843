import { FreightRecapForm } from '@/blink-forms';

import { SelectedFreightPaymentConditions } from '../types';

export const emptyFreightRecapFormValues = (): FreightRecapForm => ({
  price: {
    currency: 'USD',
    comments: undefined,
  },
  product: {
    option: 'IN_CHARTERERS_OPTION',
    laycan: [undefined, undefined],
  },
  vessel: {
    type: 'TBN',
    gears: 'GEARLESS',
    grabs: 'GRABLESS',
    cranesNumber: 0,
    cranesCapacity: 0,
    ownerName: undefined,
    ownerAddress: undefined,
    ownerVat: undefined,
  },
  loadingConditions: {
    safePortNumber: 1,
    safeBerthNumber: 1,
    berthConditions: 'AAAA',
    freightRateTerms: 'SSHINC',
    freightPortPeriodTerms: 'PWWD',
    freightNORTendering: 'atdn + SSHINC',
    freightTurnTime: 12,
    freightTurnTimeTerms: 'UNLESS_USED',
    freightCargoPDA: 'AT_CHARTERERS_COST',
    freightVesselPDA: 'AT_OWNERS_COST',
    freightExclusionFormLaytime: [],
    freightRate: undefined,
    freightExclusionFormLaytimeOther: undefined,
    freightPDAOther: undefined,
  },
  dischargingConditions: {
    safePortNumber: 1,
    safeBerthNumber: 1,
    berthConditions: 'AAAA',
    freightRateTerms: 'SSHINC',
    freightPortPeriodTerms: 'PWWD',
    freightNORTendering: 'atdn + SSHINC',
    freightTurnTime: 12,
    freightTurnTimeTerms: 'UNLESS_USED',
    freightCargoPDA: 'AT_CHARTERERS_COST',
    freightVesselPDA: 'AT_OWNERS_COST',
    freightExclusionFormLaytime: [],
    freightExclusionFormLaytimeOther: undefined,
    freightRate: undefined,
    freightPDAOther: undefined,
  },
  nomination: {
    agentAtLoadingPort: undefined,
    agentAtDischargingPort: undefined,
  },
  payment: {
    conditions: {},
    laytimeCalculation: [],
  },
});

export const emptySelectedFreightPaymentConditions = (
  paymentConditions: FreightRecapForm['payment']['conditions']
): SelectedFreightPaymentConditions => {
  const initialValues = {
    TTPrepayment: { checked: false, isDirty: false },
    TTAfterLoading: { checked: false, isDirty: false },
    TTBeforeBreakingBulk: { checked: false, isDirty: false },
    TTOpenTerms: { checked: false, isDirty: false },
    other: { checked: false, isDirty: false },
  };

  const values = Object.entries(initialValues).reduce((acc, [key, value]) => {
    const condition = paymentConditions[key] ? { checked: true, isDirty: false } : value;
    return { ...acc, [key]: condition };
  }, {} as SelectedFreightPaymentConditions);

  return values;
};

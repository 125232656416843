/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CostsTermsWithoutShared = (typeof CostsTermsWithoutShared)[keyof typeof CostsTermsWithoutShared];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CostsTermsWithoutShared = {
  AT_BUYERS_COST: 'AT_BUYERS_COST',
  AT_SELLERS_COST: 'AT_SELLERS_COST',
} as const;

import { useQuery } from '@tanstack/react-query';

export function useLazyUrl(url: string | (() => Promise<string>), fileKey: string, filename: string) {
  const {
    data: fileUrl,
    isFetching,
    isStale,
    refetch,
  } = useQuery({
    queryFn: typeof url === 'string' ? () => url : url,
    queryKey: [filename, fileKey],
    initialData: typeof url === 'string' ? url : undefined,
    enabled: false,
  });

  const fetchUrl = async () => {
    if (!fileUrl || isStale) {
      const { data: url } = await refetch();
      return url;
    }

    return fileUrl;
  };

  return { fileUrl, isFetching, fetchUrl };
}

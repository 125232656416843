import { Button, ButtonProps } from '@mantine/core';

interface TextButtonProps extends Omit<ButtonProps, 'variant'> {
  onClick: () => void;
  children: React.ReactNode;
  fontSize?: number;
}

export function TextButton({ onClick, children, fontSize = 14, sx = [], ...rest }: TextButtonProps) {
  const sxArray = Array.isArray(sx) ? sx : [sx];
  const sxDefault = [{ fontSize }, ...sxArray];

  return (
    <Button size={'sm'} variant={'subtle'} px={5} sx={sxDefault} onClick={onClick} {...rest}>
      {children}
    </Button>
  );
}

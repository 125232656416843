import { Box, BoxProps, Skeleton } from '@mantine/core';
import { ReactNode } from 'react';

import { Text } from '@/components';

export interface LabeledProps extends BoxProps {
  label: string;
  children: ReactNode;
}

export function Labeled(props: LabeledProps) {
  const { label, children, ...boxProps } = props;
  const isTextChildren = typeof children === 'string' || typeof children === 'number';

  return (
    <Box {...boxProps}>
      <Text color="gray.6" size="S" mb={2} role="term">
        {label}
      </Text>
      {isTextChildren ? (
        <Text color="gray.8" weight={500} role="definition" sx={{ overflowWrap: 'break-word' }}>
          {children}
        </Text>
      ) : (
        children
      )}
    </Box>
  );
}

function Loading(props: BoxProps) {
  return (
    <Box {...props}>
      <Skeleton width={100} height={24} mb={2} />
      <Skeleton width={150} height={24} />
    </Box>
  );
}

Labeled.Loading = Loading;

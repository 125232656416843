import { z } from 'zod';

// TODO:
// Context:
// FreightPaymentTerms is copied from `apps/frontend/api/generated/model/freightPaymentTerms.ts`
// It is antipattern, and it hard to maintain should be imported from somewhere, but first we have to figure out how to do it.
// Proposal: Generating Orval to separated library, not to frontend

export const FreightPaymentTerms = {
  TT_OPEN_TERMS: 'TT_OPEN_TERMS',
  TT_BEFORE_BREAKING_BULK: 'TT_BEFORE_BREAKING_BULK',
  TT_PREPAYMENT: 'TT_PREPAYMENT',
  OPEN_ACCOUNT: 'OPEN_ACCOUNT',
} as const;

export const requestFreightFieldsSchema = z.object({
  paymentConditions: z.nativeEnum(FreightPaymentTerms),
  priceComments: z.string().optional(),
  priceTarget: z.number().optional(),
});

export type RequestFreightFields = z.infer<typeof requestFreightFieldsSchema>;

import { formatDistanceStrict, addDays, format } from 'date-fns';

export function getMiddleValueBetweenDates(minDateString: string, maxDateString: string) {
  try {
    const min = Date.parse(minDateString);
    const max = Date.parse(maxDateString);
    if (min > max) return getMiddleValueBetweenDates(maxDateString, minDateString);
    const intervalInDaysString = formatDistanceStrict(max, min, {
      unit: 'day',
    }).split(' ')[0];
    const interval = parseInt(intervalInDaysString) / 2;
    return format(addDays(min, interval), 'yyyy-MM-dd');
  } catch (e) {
    console.log(`Error parsing dates ${e}`);
    return null;
  }
}

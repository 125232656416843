import { z } from 'zod';

import { requestFreightFieldsSchema } from './request-freight-fields';

export const offerVisibilitySchema = z.object({
  isOnlyVisibleToCompany: z.boolean().optional(),
});

export const conditionalRequestFreightFullSchema = z
  .object({
    requestFreightDetails: z.literal(true),
  })
  .merge(requestFreightFieldsSchema);

export const conditionalRequestFreightSchema = z.discriminatedUnion('requestFreightDetails', [
  conditionalRequestFreightFullSchema,
  z.object({
    requestFreightDetails: z.literal(false),
  }),
]);

export type ConditionalRequestFreightSchema = z.infer<typeof conditionalRequestFreightSchema>;
export type ConditionalRequestFreightFullSchema = z.infer<typeof conditionalRequestFreightFullSchema>;

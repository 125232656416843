/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CounterpartyContactExistingDTOType =
  (typeof CounterpartyContactExistingDTOType)[keyof typeof CounterpartyContactExistingDTOType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CounterpartyContactExistingDTOType = {
  EXISTING: 'EXISTING',
} as const;

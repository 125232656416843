import { showNotification } from '@mantine/notifications';
import { useTranslation } from 'next-i18next';

import { hasErrorMessage, isAxiosError } from './error-guards';

export const useToastNotifications = () => {
  const { t } = useTranslation('common');
  const showUnexpectedErrorNotification = () => {
    showNotification({
      disallowClose: false,
      autoClose: 8000,
      title: t('error.unexpectedError.title'),
      message: t('error.unexpectedError.message'),
      color: 'red',
    });
  };

  const displayApiError = (error: unknown) => {
    if (isAxiosError(error) && hasErrorMessage(error.response.data)) {
      showNotification({
        color: 'red',
        title: error.response.data.message,
      });
    } else {
      showUnexpectedErrorNotification();
    }
  };

  return {
    showUnexpectedErrorNotification,
    displayApiError,
  };
};

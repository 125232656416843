import { useRegister } from 'api/generated/registration/registration';
import { useState } from 'react';
import { LooseKeys } from '@mantine/form/lib/types';

import { useToastNotifications } from '@/common/use-toast-notifications';
import { assertState } from '@/common/types';
import { isAxiosError, isClientError, isRestError, isUniqueCoinstraintError } from '@/common/error-guards';

import { CompanyRegistrationState, RegisterForm } from '../types';

interface UseRegistrationStepperProps {
  onUniqueConstraintError: (paths: LooseKeys<RegisterForm>[]) => void;
}

export const useRegistrationStepper = (props: UseRegistrationStepperProps) => {
  const { onUniqueConstraintError } = props;
  const [state, setState] = useState<CompanyRegistrationState>({
    type: 'FILL_REGISTRATION_FORM',
    currentStep: 0,
  });
  const { showUnexpectedErrorNotification } = useToastNotifications();

  const register = useRegister();

  const goToStepOne = () => {
    assertState(state, 'FILL_REGISTRATION_FORM');
    setState({ ...state, currentStep: 1 });
  };

  const goToStepZero = () => {
    assertState(state, 'FILL_REGISTRATION_FORM');
    setState({ ...state, currentStep: 0 });
  };

  const submitRegisterCompany = (form: RegisterForm) => {
    assertState(state, 'FILL_REGISTRATION_FORM');
    setState({ ...state });
    setState({ type: 'SUBMITTING' });
    register.mutate(
      { data: form },
      {
        onSuccess: () => {
          setState({ type: 'REGISTER_COMPANY_SUCCESS' });
        },
        onError: (e: unknown) => {
          if (!isAxiosError(e)) {
            showUnexpectedErrorNotification();
            return setState({ type: 'FILL_REGISTRATION_FORM', currentStep: state.currentStep });
          }
          if (!isClientError(e)) {
            showUnexpectedErrorNotification();
            return setState({ type: 'FILL_REGISTRATION_FORM', currentStep: state.currentStep });
          }

          if (isRestError(e.response.data) && isUniqueCoinstraintError(e)) {
            const errors = e.response.data.subErrors;
            const affectedPaths = errors.map(({ path }) => path);
            const hasStepZeroField = affectedPaths.includes('contactEmail');
            setState({ type: 'FILL_REGISTRATION_FORM', currentStep: hasStepZeroField ? 0 : 1, errors });
            onUniqueConstraintError(affectedPaths);
          }
        },
      }
    );
  };

  return {
    submitRegisterCompany,
    goToStepOne,
    goToStepZero,
    state,
  };
};

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type GetSharedCargoItemResponseDtoCargoInformationDtoCargoOfferDtoCurrency =
  (typeof GetSharedCargoItemResponseDtoCargoInformationDtoCargoOfferDtoCurrency)[keyof typeof GetSharedCargoItemResponseDtoCargoInformationDtoCargoOfferDtoCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSharedCargoItemResponseDtoCargoInformationDtoCargoOfferDtoCurrency = {
  EUR: 'EUR',
  USD: 'USD',
} as const;

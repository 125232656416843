/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type WorkflowTaskVisibilityCommonDtoStatus =
  (typeof WorkflowTaskVisibilityCommonDtoStatus)[keyof typeof WorkflowTaskVisibilityCommonDtoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkflowTaskVisibilityCommonDtoStatus = {
  OPEN: 'OPEN',
  DUE_SOON: 'DUE_SOON',
  DUE: 'DUE',
  OVERDUE: 'OVERDUE',
  CLOSED: 'CLOSED',
} as const;

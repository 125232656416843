import { ReactNode } from 'react';
import { Grid, Input, InputWrapperProps, NumberInput } from '@mantine/core';
import { GetInputProps } from '@mantine/form/lib/types';
import { useTranslation } from 'next-i18next';

import { useContainedLabelStyles } from '@/common/use-contained-label-styles';
import { Text } from '@/components';
import { getBasePercentageProps } from '@/views/recap-form';

export interface RangeInputProps<Values> extends Omit<InputWrapperProps, 'children'> {
  percentage?: boolean;
  controlMinInput?: ReturnType<GetInputProps<Values>>;
  controlMaxInput?: ReturnType<GetInputProps<Values>>;
  minLabel: string;
  maxLabel: string;
  min?: number;
  max?: number;
  allowsNegative?: boolean;
  rightSection?: ReactNode;
  precision?: number;
  withContainedLabel?: boolean;
  formatter?: (value: string | undefined) => string;
  parser?: (value: string | undefined) => string;
}

const RightSection = () => {
  return (
    <Text color="gray.6" size="S">
      %
    </Text>
  );
};

export function RangeInput(props: RangeInputProps<string>) {
  const { t } = useTranslation('common');
  const { classes: contained } = useContainedLabelStyles();
  const {
    percentage,
    precision = 2,
    min = 0,
    max,
    controlMinInput,
    controlMaxInput,
    minLabel,
    maxLabel,
    withAsterisk,
    allowsNegative,
    rightSection = null,
    placeholder,
    withContainedLabel = true,
    formatter,
    parser,
    ...inputWrapperProps
  } = props;

  const inputsPlaceholder = placeholder ?? (percentage ? t('form.percent') : t('form.value'));
  const minValue = allowsNegative ? null : min;
  const containedClassName = withContainedLabel && contained;

  const formatterParserProps = {
    ...(formatter && { formatter }),
    ...(parser && { parser }),
  };

  return (
    <Input.Wrapper {...inputWrapperProps} classNames={containedClassName}>
      <Grid gutter="md">
        <Grid.Col span={12} xs={6}>
          <NumberInput
            label={minLabel}
            placeholder={inputsPlaceholder}
            min={minValue}
            max={max}
            precision={precision}
            data-testid={'minTolerance'}
            {...(percentage && getBasePercentageProps())}
            rightSection={percentage ? <RightSection /> : rightSection}
            classNames={containedClassName}
            withAsterisk={withAsterisk}
            {...formatterParserProps}
            {...controlMinInput}
          />
        </Grid.Col>
        <Grid.Col span={12} xs={6}>
          <NumberInput
            label={maxLabel}
            placeholder={inputsPlaceholder}
            min={minValue}
            max={max}
            precision={precision}
            data-testid={'maxTolerance'}
            {...(percentage && getBasePercentageProps())}
            rightSection={percentage ? <RightSection /> : rightSection}
            classNames={containedClassName}
            withAsterisk={withAsterisk}
            {...formatterParserProps}
            {...controlMaxInput}
          />
        </Grid.Col>
      </Grid>
    </Input.Wrapper>
  );
}

import { createContext, useContext, useEffect, useCallback } from 'react';
import { useSession } from 'next-auth/react';

import { useSystemNotifications } from '@/core/hooks';
import { isPushNotificationSupported } from '@/core/helpers';

import { subscribeServiceWorkerToPushManager } from '../helpers';

interface IPushNotificationSubscriptionContext {
  isNotificationPermissionGranted: boolean;
  requestPermissionAndSubscribe: () => Promise<boolean>;
}

export const PushNotificationSubscriptionContext = createContext<IPushNotificationSubscriptionContext>(undefined);

export const PushNotificationSubscriptionProvider = ({ children }) => {
  const isPushNotificationEnabled = useCallback(() => isPushNotificationSupported(), []);

  const { isNotificationPermissionGranted, requestNotificationPermission } = useSystemNotifications();

  useRequestNotificationPermissionEffect(isPushNotificationEnabled, requestNotificationPermission);
  useSubscribeServiceWorkerToPushManagerEffect(isPushNotificationEnabled, isNotificationPermissionGranted);

  const value: IPushNotificationSubscriptionContext = {
    isNotificationPermissionGranted,
    requestPermissionAndSubscribe: requestNotificationPermission,
  };

  return (
    <PushNotificationSubscriptionContext.Provider value={value}>
      {children}
    </PushNotificationSubscriptionContext.Provider>
  );
};

export const usePushNotificationSubscriptionContext = () => useContext(PushNotificationSubscriptionContext);

const useSubscribeServiceWorkerToPushManagerEffect = (
  isPushNotificationEnabled: () => boolean,
  isNotificationPermissionGranted: boolean
) => {
  useEffect(() => {
    if (isPushNotificationEnabled() && isNotificationPermissionGranted) subscribeServiceWorkerToPushManager();
  }, [isPushNotificationEnabled, isNotificationPermissionGranted]);
};

const useRequestNotificationPermissionEffect = (
  isPushNotificationEnabled: () => boolean,
  requestNotificationPermission: () => void
) => {
  const { data: session } = useSession();

  useEffect(() => {
    if (isPushNotificationEnabled() && session?.me?.id) requestNotificationPermission();
  }, [isPushNotificationEnabled, session, requestNotificationPermission]);
};

import { RiLink } from 'react-icons/ri';
import { useTranslation } from 'next-i18next';
import { Group, Stack, Anchor, useMantineTheme } from '@mantine/core';
import { AddExternalRefIdButton } from 'v2/components/shared/buttons/AddExternalRefIdButton/AddExternalRefIdButton';

import { routes } from '@/common/routes';
import { LinkWithIcon, Text } from '@/components';

interface CargoIdRefIdCellProps {
  shortId: string;
  workflowId: string;
  externalRefId?: string;
  isArchived?: boolean;
}

export const CargoIdRefIdCell = ({ shortId, workflowId, externalRefId, isArchived }: CargoIdRefIdCellProps) => {
  const { t } = useTranslation('myCargos');

  return (
    <Stack spacing={0}>
      <Text color="primary.1" weight={600}>
        {shortId}
      </Text>
      {externalRefId ? (
        <Text size="S" color="gray.7">
          {t('labels.ref', { ref: externalRefId })}
        </Text>
      ) : (
        !isArchived && <AddExternalRefIdButton workflowId={workflowId} />
      )}
    </Stack>
  );
};

interface CompanyNameCellProps {
  companyId?: string;
  companyName: string;
}

export const CompanyNameCell = ({ companyId, companyName }: CompanyNameCellProps) => {
  const { t } = useTranslation('myCargos');
  const theme = useMantineTheme();

  return companyId ? (
    <Anchor href={routes.CompanyProfile(companyId)} onClick={(e) => e.stopPropagation()}>
      <Text size="M" weight={600} color={theme.fn.primaryColor()}>
        {companyName}
      </Text>
    </Anchor>
  ) : (
    <Text color="gray.6" italic={!companyName}>
      {companyName ?? t('labels.na')}
    </Text>
  );
};

interface VesselNameCellProps {
  vesselIMO?: string;
  vesselName: string;
}

export const VesselNameCell = ({ vesselIMO, vesselName }: VesselNameCellProps) => {
  const { t } = useTranslation('myCargos');

  return (
    <Stack spacing={0}>
      {vesselIMO ? (
        <>
          {vesselName ? (
            <Text color="gray.9" weight={500}>
              {vesselName}
            </Text>
          ) : (
            <Text color="gray.6" italic>
              {t('labels.na')}
            </Text>
          )}
          <Text size="S" color="gray.7">
            {t('labels.imo', { imo: vesselIMO })}
          </Text>
        </>
      ) : (
        <Text color="gray.6" italic>
          {t('labels.na')}
        </Text>
      )}
    </Stack>
  );
};

interface LinkedEntitiesCellProps {
  linkedWorkflowId?: string;
  linkedWorkflowShortId: string;
}

export const LinkedEntitiesCell = ({ linkedWorkflowId, linkedWorkflowShortId }: LinkedEntitiesCellProps) => {
  const theme = useMantineTheme();
  const { t } = useTranslation('myCargos');

  return linkedWorkflowId ? (
    <LinkWithIcon
      href={routes.CargoWorkflowSummary(linkedWorkflowId)}
      icon={<RiLink size={20} color={theme.colors.primary[1]} />}
    >
      {linkedWorkflowShortId}
    </LinkWithIcon>
  ) : (
    <Group spacing={4}>
      <RiLink size={20} color={theme.colors.gray[6]} />
      <Text color="gray.6">{t('labels.none')}</Text>
    </Group>
  );
};

import { Drawer, Paper } from '@mantine/core';
import { useGetDetailOfferTemplate } from 'api/generated/detail-offer/detail-offer';
import { CargoTransportation, NegotiationSide } from 'api/generated/model';
import { useTranslation } from 'next-i18next';

import { Title } from '@/components';
import { EmptyResults } from '@/views/recap-form';

import { DetailedOfferTemplateActionsFunction } from '../types';
import { LoadDetailedOfferTemplateTable } from './LoadDetailedOfferTemplateTable';

export interface LoadDetailedOfferTemplateDrawerProps {
  opened: boolean;
  negotiationSide: NegotiationSide;
  offeredTransportation: CargoTransportation;
  toggle: VoidFunction;
  onUseTemplate: DetailedOfferTemplateActionsFunction;
  onDeleteTemplate: DetailedOfferTemplateActionsFunction;
}

export function LoadDetailedOfferTemplateDrawer(props: LoadDetailedOfferTemplateDrawerProps) {
  const { opened, negotiationSide, offeredTransportation, toggle, onUseTemplate, onDeleteTemplate } = props;
  const { data, isLoading } = useGetDetailOfferTemplate({
    query: {
      select: (response) => response.data,
      staleTime: 0,
    },
  });
  const { t } = useTranslation('recapForm');
  const filteredTemplates = (data || []).filter(
    (template) => template.negotiationSide === negotiationSide && template.transportation === offeredTransportation
  );

  return (
    <Drawer opened={opened} onClose={toggle} position="right" padding={24}>
      <Title size="XL" mb={24}>
        {t('modals.loadTemplate.heading')}
      </Title>
      {filteredTemplates.length > 0 ? (
        <LoadDetailedOfferTemplateTable
          data={filteredTemplates}
          onUseTemplate={onUseTemplate}
          onDelete={onDeleteTemplate}
          isLoading={isLoading}
        />
      ) : (
        <Paper>
          <EmptyResults isDetailedOffer={true} />
        </Paper>
      )}
    </Drawer>
  );
}

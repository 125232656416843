/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoTenderNegotiationStatusRejectOffer =
  (typeof CargoTenderNegotiationStatusRejectOffer)[keyof typeof CargoTenderNegotiationStatusRejectOffer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoTenderNegotiationStatusRejectOffer = {
  PARTICIPANT_REJECTED: 'PARTICIPANT_REJECTED',
  CREATOR_REJECTED: 'CREATOR_REJECTED',
} as const;

import { Group, Stack } from '@mantine/core';
import { randomId } from '@mantine/hooks';

import { Text } from '../typography/Text';

export interface InactiveInputProps {
  label: string;
  value: React.ReactNode | undefined;
  suffix?: string;
  h?: number;
}

export function InactiveInput({ label, value, suffix = '', h = 38 }: InactiveInputProps) {
  const id = randomId();

  return (
    <Stack spacing={8}>
      <Text component="label" htmlFor={id} weight={500} display="inline-block">
        {label}
      </Text>
      <Group align="center" h={h} id={id}>
        <Text size="S">
          {value ? value : '-'} {value && suffix}
        </Text>
      </Group>
    </Stack>
  );
}

import { createStyles, MantineSize } from '@mantine/core';

export const useContainedLabelStyles = (size?: MantineSize) => {
  const useStyles = createStyles((theme) => ({
    root: {
      position: 'relative',
      height: 'auto',
    },

    input: {
      height: 'auto',
      paddingTop: 18,

      ...(size === 'sm' && {
        lineHeight: '28px',
      }),

      [theme.fn.smallerThan('xs')]: {
        paddingLeft: 8,
        minHeight: 28,
        lineHeight: '28px',
      },
    },

    label: {
      position: 'absolute',
      pointerEvents: 'none',
      fontSize: theme.fontSizes.xs,
      paddingLeft: theme.spacing.sm,
      zIndex: 1,
      fontWeight: 600,

      [theme.fn.smallerThan('xs')]: {
        fontSize: 10,
        paddingLeft: 8,
        margin: 0,
      },
    },
    rightSection: {
      paddingTop: 16,
    },

    icon: {
      ...(size === 'sm' && {
        transform: 'translateY(-3px)',
      }),
    },

    missingDataLabel: {
      paddingTop: 26,
      textAlign: 'center',
    },
  }));

  return useStyles();
};

import { useRouter } from 'next/router';
import { signIn, useSession } from 'next-auth/react';
import {
  UserRole,
  CompanyRole,
  CompanyType,
  CurrentUserDTO,
  UserCompanyStatus,
  UserCompanyContextDto,
} from 'api/generated/model';

import { Session } from 'inspector';

interface UseAuthRolesResponse {
  id: string;
  isOps: boolean;
  isBuyer: boolean;
  companyId: string;
  roles: UserRole[];
  isSeller: boolean;
  me: CurrentUserDTO;
  isPassive: boolean;
  companyLogo: string;
  companyName: string;
  isShortlisted: boolean;
  companyType: CompanyType;
  companyContextId: string;
  isCompanyPrivate: boolean;
  isCompanyVerified: boolean;
  companyRoles: CompanyRole[];
  isServiceProvider?: boolean;
  isSeparatedMarketplace: boolean;
  companyStatus: UserCompanyStatus;
}

export function hasRole(user: CurrentUserDTO, role: UserRole) {
  return user.activeCompany.companyRoles.includes(role);
}

export function hasAllowedRole(user: CurrentUserDTO, allowedRoles: UserRole[] | UserRole) {
  const allowedRolesArray = Array.isArray(allowedRoles) ? allowedRoles : [allowedRoles];
  return user.activeCompany.companyRoles.some((role) => [...allowedRolesArray, UserRole.ADMIN].includes(role));
}

export function hasOnlyMIRole(user: CurrentUserDTO) {
  return user.activeCompany.companyRoles?.length == 1 && hasRole(user, UserRole.MI_USER);
}

export function hasAllowedCompanyType(user: CurrentUserDTO, allowedCompanyTypes: CompanyType[] | CompanyType) {
  const allowedCompanyTypesArray = Array.isArray(allowedCompanyTypes) ? allowedCompanyTypes : [allowedCompanyTypes];
  return allowedCompanyTypesArray.includes(user.activeCompany.companyType);
}

export function hasAllowedCompanyRoles(company: UserCompanyContextDto, allowedRoles: CompanyRole[] | CompanyRole) {
  const allowedRolesArray = Array.isArray(allowedRoles) ? allowedRoles : [allowedRoles];
  return company.roles.some((role) => allowedRolesArray.includes(role));
}

export function hasAllowedCompanyStatus(allowedStatus: UserCompanyStatus[], companyStatus: UserCompanyStatus): boolean {
  const allowedRolesArray = Array.isArray(allowedStatus) ? allowedStatus : [allowedStatus];
  return allowedRolesArray.includes(companyStatus);
}

export function isPrivate(user: CurrentUserDTO): boolean {
  return user.activeCompany.status === UserCompanyStatus.PRIVATE;
}

export function isServiceProvider(user: CurrentUserDTO): boolean {
  return user.activeCompany.companyType === CompanyType.SERVICE_PROVIDER;
}

export function isShortlisted(user: CurrentUserDTO): boolean {
  return user.activeCompany.status === UserCompanyStatus.SHORTLISTED;
}

export function isPassive(user: CurrentUserDTO): boolean {
  return user.activeCompany.status === UserCompanyStatus.PASSIVE;
}

export function isSeparatedMarketplace(user: CurrentUserDTO): boolean {
  return user.activeCompany.hasMarketplaceSeparated;
}

function showErrorInCaseLackOfSession(status: 'loading' | 'authenticated' | 'unauthenticated', session: Session) {
  if (status === 'loading' && !session && process.env.NODE_ENV === 'development') {
    // ---... are added to be more visible in terminal (it won't be visible in browser console)
    console.warn('-----------------------------------------');
    console.warn(
      'Probably on this page you forgot add `getAppServerSideProps` (it provides session data) to this page root component'
    );
    console.warn('-----------------------------------------');
  }
}

export function useAuthRoles(): UseAuthRolesResponse {
  const router = useRouter();
  const { status, data: session } = useSession();

  if (status !== 'authenticated') {
    signIn('credentials', { callbackUrl: router.pathname });
    showErrorInCaseLackOfSession(status, session);
    return;
  }

  const userCompanyData = session.me.activeCompany;

  return {
    me: session.me,
    id: session.me.id,
    isPassive: isPassive(session.me),
    roles: userCompanyData.companyRoles,
    companyRoles: userCompanyData.roles,
    companyId: userCompanyData.companyId,
    companyStatus: userCompanyData.status,
    isCompanyPrivate: isPrivate(session.me),
    isShortlisted: isShortlisted(session.me),
    companyType: userCompanyData.companyType,
    companyName: userCompanyData.companyName,
    companyLogo: userCompanyData.companyLogo,
    isBuyer: hasRole(session.me, UserRole.BUYER),
    isSeller: hasRole(session.me, UserRole.SELLER),
    isCompanyVerified: !!userCompanyData.verifiedAt,
    isServiceProvider: isServiceProvider(session.me),
    companyContextId: userCompanyData.companyContextId,
    isSeparatedMarketplace: isSeparatedMarketplace(session.me),
    isOps: hasRole(session.me, UserRole.OPERATIONS) || hasRole(session.me, UserRole.FINANCE),
  };
}

export function useIsMIUser(): boolean {
  const { data: session, status } = useSession();

  const router = useRouter();

  if (status !== 'authenticated') {
    signIn('credentials', { callbackUrl: router.pathname });
    showErrorInCaseLackOfSession(status, session);
    return;
  }

  return hasOnlyMIRole(session.me);
}

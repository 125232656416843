import { Button, Flex, Group, Radio, Stack, Textarea, useMantineTheme } from '@mantine/core';
import { useTranslation, I18n } from 'next-i18next';
import { useForm, zodResolver } from '@mantine/form';
import { RiEyeOffFill } from 'react-icons/ri';
import { z, ZodErrorMap, ZodIssueCode } from 'zod';

import { Title } from '@/components';

interface Reason {
  label: string;
  value: string;
}

export const PLATFORM_FEEDBACK_OTHER_REASON = 'OTHER_REASON' as const;

export function getSchemaPlatformFeedback(reasons: Reason[], errorMap: ZodErrorMap) {
  return z.discriminatedUnion(
    'cancelReason',
    [
      z.object({
        cancelReason: z.enum(reasons.map(({ value }) => value) as [string, ...string[]]),
        cancelMessage: z.string().optional(),
      }),
      z.object({
        cancelReason: z.literal(PLATFORM_FEEDBACK_OTHER_REASON),
        cancelMessage: z.string(),
      }),
    ],
    { errorMap }
  );
}

function getCustomErrorMap(t: I18n['t']) {
  return ((issue, ctx) => {
    switch (issue.code) {
      case ZodIssueCode.invalid_union_discriminator:
        return { message: t('sendFeedbackToPlatform.error.reason') };
      default:
        return { message: ctx.defaultError };
    }
  }) as ZodErrorMap;
}

export type PlatformFeedback = z.infer<ReturnType<typeof getSchemaPlatformFeedback>>;

export interface PlatformFeedbackFormProps {
  reasons: Reason[];
  onBackClick: (values?: PlatformFeedback) => void;
  onNextClick: (values?: PlatformFeedback) => void;
  initialFormValues: PlatformFeedback;
  alwaysWithMessage?: boolean;
}

export function PlatformFeedbackForm({
  reasons,
  onBackClick,
  onNextClick,
  initialFormValues,
  alwaysWithMessage = false,
}: PlatformFeedbackFormProps) {
  const { t } = useTranslation('common');
  const theme = useMantineTheme();

  const form = useForm<PlatformFeedback>({
    initialValues: initialFormValues,
    validate: zodResolver(getSchemaPlatformFeedback(reasons, getCustomErrorMap(t))),
  });

  const comments = alwaysWithMessage && (
    <Stack sx={{ marginTop: theme.spacing.sm }}>
      <Title size="L">
        {t('sendFeedbackToPlatform.comments.title')} <span style={{ color: 'red' }}>{'*'}</span>
      </Title>
      <Textarea
        placeholder={t('sendFeedbackToPlatform.comments.placeholder')}
        {...form.getInputProps('cancelMessage')}
      />
    </Stack>
  );

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onNextClick(values);
      })}
    >
      <Flex
        sx={{
          backgroundColor: theme.colors.gray[0],
          border: '1px solid',
          borderColor: theme.colors.gray[1],
          padding: theme.spacing.sm,
        }}
      >
        <RiEyeOffFill size={24} color={theme.colors.red[9]} />
        <Title sx={{ marginLeft: theme.spacing.sm }} color="gray.6" size="S">
          {t('sendFeedbackToPlatform.info')}
        </Title>
      </Flex>
      <Radio.Group mt="sm" {...form.getInputProps('cancelReason')}>
        <Stack mb="sm">
          {reasons && reasons.map(({ label, value }) => <Radio key={value} label={label} value={value} />)}
          <Radio label={t('sendFeedbackToPlatform.other.label')} value={PLATFORM_FEEDBACK_OTHER_REASON} />
        </Stack>
      </Radio.Group>
      {form.values.cancelReason === 'OTHER_REASON' ? (
        <Stack mt="sm">
          <Textarea
            defaultValue=""
            placeholder={t('sendFeedbackToPlatform.other.placeholder')}
            {...form.getInputProps('cancelMessage')}
          />
        </Stack>
      ) : (
        comments
      )}
      <Group grow mt="xl">
        <Button variant="outline" onClick={() => onBackClick()}>
          {t('buttons.back')}
        </Button>
        <Button type="submit">{t('buttons.confirm')}</Button>
      </Group>
    </form>
  );
}

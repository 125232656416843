import { ReactNode } from 'react';
import { Box } from '@mantine/core';
import {
  useGetCargoNegotiationHistory,
  useGetCargoNegotiationHistoryStep,
} from 'api/generated/cargo-negotiation/cargo-negotiation';
import { NegotiationStatus } from 'api/generated/model';

import { OfferType } from '@/views/my-cargos';

import { RecapPreviewModal, RecapPreviewMostRecentTitle } from './RecapPreviewModal';
import { RecapPreviewModalContent } from './RecapPreviewModalContent';

export interface NegotiationRecapModalProps {
  negotiationId: string;
  stepId?: string;
  opened: boolean;
  toggle: VoidFunction;
  actions?: ReactNode;
  offerType: OfferType;
  onExtendNegotiationsClick?: (negotiationId: string) => void;
}

export function NegotiationRecapModal(props: NegotiationRecapModalProps) {
  const {
    negotiationId,
    opened,
    toggle,
    actions = null,
    stepId,
    offerType,
    onExtendNegotiationsClick = () => null,
  } = props;
  const negotiation = useGetCargoNegotiationHistory(negotiationId, {
    query: { select: (response) => response.data, staleTime: 0 },
  });
  const currentStepId = stepId || negotiation.data?.negotiationHistory?.[0]?.id;
  const currentStepIndex = negotiation.data?.negotiationHistory.findIndex((step) => step.id === currentStepId);
  const previousStepId =
    currentStepIndex !== undefined ? negotiation.data?.negotiationHistory?.[currentStepIndex + 1]?.id : undefined;
  const stepToPreview = useGetCargoNegotiationHistoryStep(negotiationId, stepId || currentStepId, {
    query: {
      select: (response) => ({
        recapForm: response.data.recapForm,
        expiresAt: response.data.expiresAt,
        status: response.data.status,
        offeredTransportation: response.data.offeredTransportation,
      }),
    },
  });
  const lastStep = useGetCargoNegotiationHistoryStep(negotiationId, previousStepId, {
    query: { select: (response) => response.data },
  });

  return (
    <RecapPreviewModal
      opened={opened}
      onClose={toggle}
      recapForm={
        <Box p={24} sx={{ overflowY: 'scroll', maxHeight: '100%', flexGrow: 1 }}>
          <RecapPreviewModalContent
            currentForm={
              stepToPreview.data?.status === NegotiationStatus.EXPIRED
                ? lastStep.data?.recapForm
                : stepToPreview.data?.recapForm
            }
            prevForm={lastStep.data?.recapForm}
            buyer={negotiation.data?.buyerCompanyName}
            seller={negotiation.data?.sellerCompanyName}
            offeredTransport={stepToPreview.data?.offeredTransportation}
            sellerCompanyId={negotiation.data?.sellerCompanyId}
            buyerCompanyId={negotiation.data?.buyerCompanyId}
          />
        </Box>
      }
      actions={actions}
      title={
        !negotiation.isLoading && (
          <RecapPreviewMostRecentTitle
            expiresAt={negotiation.data?.negotiationHistory[0]?.expiresAt}
            status={negotiation.data?.negotiationHistory[0]?.status}
            offerType={offerType}
            expiryExtendRequestStatus={negotiation.data?.expiryExtendRequest[0]?.status}
            onExtendNegotiationsClick={() => onExtendNegotiationsClick(negotiationId)}
          />
        )
      }
    />
  );
}

import { WorkflowDTO } from 'api/generated/model';

import {
  SelectedPaymentConditions,
  ExternalFrontendRecapForm,
  FrontendRecapForm,
  PaymentConditionType,
} from './../types';

export const emptyRecapFormValue: () => FrontendRecapForm = () =>
  ({
    price: { currency: 'USD' },
    payment: {
      conditions: {
        prepayment: {},
        cashAgainstDocuments: {},
        irrevocableLetter: {},
        documentCollection: {},
        openAccount: {},
        consumptionPayment: {},
        other: {},
      },
      demurrage: {
        maxDays: 20,
        rateCondition: 'MAX',
      },
    },
    lawJurisdiction: {
      law: 'ENGLISH_LAW',
      jurisdiction: 'ENGLISH_JURISDICTION',
    },
    product: {
      cargo: {
        minTolerance: 0.1,
        maxTolerance: 0.1,
        toleranceOption: 'IN_SELLERS_OPTION',
        laycan: ['', ''],
      },
      vesselInformation: {
        gears: 'GEARLESS',
        grabs: 'GRAB',
      },
      freightConditions: {
        safePortNo: '1',
        safeBerthNo: '1',
        norTendering: 'atdn + SSHINC',
        turnTimeTerms: 'EVEN_IF_USED',
        exclusionFromLayTime: [],
        laytimeCalculations: [],
        berthConditions: 'AAAA',
        freightTerms: 'SSHINC',
        freightPeriodTerms: 'PWWD',
        turnTime: 45,
        cargoPda: 'AT_SELLERS_COST',
        vesselPda: 'AT_SELLERS_COST',
      },
      agentsInDischargingPort: {
        mainAgendConditions: 'NA',
        protectiveAgendConditions: 'NA',
      },
      samplingAndAnalysis: {
        surveyorNames: [
          {
            cost: 'AT_SELLERS_COST',
          },
        ],
        surveyorsAtDischargePort: [
          {
            cost: 'AT_SELLERS_COST',
          },
        ],
        analysis: [
          {
            type: 'MANUFACTURING_PLANT_LABORATORY',
          },
        ],
        arbitrationAnalysis: {},
      },
      weight: {
        loadportSurveyors: [
          {
            cost: 'AT_SELLERS_COST',
          },
        ],
        dischargeSurveyors: [
          {
            cost: 'AT_SELLERS_COST',
          },
        ],
      },
    },
    isOnlyVisibleToCompany: false,
    requestFreightDetails: false,
  } as FrontendRecapForm);

export const emptyBookedRecapFormValue: () => ExternalFrontendRecapForm = () =>
  ({
    counterparty: {
      id: '',
      name: '',
      country: '',
      status: undefined,
      contactPersons: '',
      email: '',
    },
    price: { currency: 'USD' },
    payment: {
      conditions: {},
      demurrage: {
        maxDays: 20,
        rateCondition: 'MAX',
      },
    },
    lawJurisdiction: {
      law: 'ENGLISH_LAW',
      jurisdiction: 'ENGLISH_JURISDICTION',
    },
    product: {
      cargo: {
        minTolerance: 0.1,
        maxTolerance: 0.1,
        toleranceOption: 'IN_SELLERS_OPTION',
      },
      vesselInformation: {
        gears: 'GEARLESS',
        grabs: 'GRAB',
      },
      freightConditions: {
        loading: {
          exclusionFromLayTime: [],
          laytimeCalculations: [],
          berthConditions: 'AAAA',
          freightTerms: 'SSHINC',
          freightPeriodTerms: 'PWWD',
          turnTime: 45,
          cargoPda: 'AT_SELLERS_COST',
          vesselPda: 'AT_SELLERS_COST',
        },
        discharging: {
          exclusionFromLayTime: [],
          laytimeCalculations: [],
          berthConditions: 'AAAA',
          freightTerms: 'SSHINC',
          freightPeriodTerms: 'PWWD',
          turnTime: 45,
          cargoPda: 'AT_SELLERS_COST',
          vesselPda: 'AT_SELLERS_COST',
        },
      },
      agentsInDischargingPort: {
        mainAgendConditions: 'N/A',
        protectiveAgendConditions: 'N/A',
      },
      samplingAndAnalysis: {
        surveyorNames: [
          {
            cost: 'AT_SELLERS_COST',
          },
        ],
        surveyorsAtDischargePort: [
          {
            cost: 'AT_SELLERS_COST',
          },
        ],
        analysis: [
          {
            type: 'MANUFACTURING_PLANT_LABORATORY',
          },
        ],
        arbitrationAnalysis: {},
      },
      weight: {
        loadportSurveyors: [
          {
            cost: 'AT_SELLERS_COST',
          },
        ],
        dischargeSurveyors: [
          {
            cost: 'AT_SELLERS_COST',
          },
        ],
      },
    },
  } as ExternalFrontendRecapForm);

export const mapCargoWorkflowData = (
  cargoWorkflowInitialData: WorkflowDTO,
  companyId: string
): ExternalFrontendRecapForm => {
  if (!cargoWorkflowInitialData) {
    return emptyBookedRecapFormValue();
  }

  const buyerCompanyId = cargoWorkflowInitialData.buyerCompanyId || '';
  const sellerCompanyId = cargoWorkflowInitialData.sellerCompanyId || '';

  return {
    counterparty: {
      id: companyId === buyerCompanyId ? sellerCompanyId : buyerCompanyId,
      name: '',
      country: '',
      status: undefined,
      contactPersons: '',
      email: '',
    },
    price: {
      price: cargoWorkflowInitialData.contractData?.price?.price || undefined,
      currency: cargoWorkflowInitialData.contractData?.price?.currency || 'USD',
    },
    payment: {
      conditions: cargoWorkflowInitialData.contractData?.payment?.conditions || {},
      demurrage: {
        openAccount: cargoWorkflowInitialData.contractData?.payment?.demurrage?.openAccount
          ? {
              maxDays: cargoWorkflowInitialData.contractData?.payment?.demurrage?.openAccount?.maxDays || undefined,
              event: cargoWorkflowInitialData.contractData?.payment?.demurrage?.openAccount?.event || '',
            }
          : {},
        rate: cargoWorkflowInitialData.contractData?.payment?.demurrage?.rate || undefined,
        rateCondition: cargoWorkflowInitialData.contractData?.payment?.demurrage?.rateCondition || 'MAX',
        type: cargoWorkflowInitialData.contractData?.payment?.demurrage?.type || undefined,
        event: cargoWorkflowInitialData.contractData?.payment?.demurrage?.event || '',
      },
    },
    lawJurisdiction: {
      law: cargoWorkflowInitialData.contractData?.lawJurisdiction?.law || 'ENGLISH_LAW',
      jurisdiction: cargoWorkflowInitialData.contractData?.lawJurisdiction?.jurisdiction || 'ENGLISH_JURISDICTION',
    },
    product: {
      cargo: {
        minTolerance: cargoWorkflowInitialData.contractData?.product?.cargo?.minTolerance || 0.1,
        maxTolerance: cargoWorkflowInitialData.contractData?.product?.cargo?.maxTolerance || 0.1,
        toleranceOption: cargoWorkflowInitialData.contractData?.product?.cargo?.toleranceOption || 'IN_SELLERS_OPTION',
        productName: cargoWorkflowInitialData.contractData?.product?.cargo?.productName || '',
        productPackaging: cargoWorkflowInitialData.contractData?.product?.cargo?.productPackaging || '',
        productQuality: cargoWorkflowInitialData.contractData?.product?.cargo?.productQuality || '',
        quantity: cargoWorkflowInitialData.contractData?.product?.cargo?.quantity || undefined,
        loadingPortId: cargoWorkflowInitialData.contractData?.product?.cargo?.loadingPortId || '',
        loadingRate: cargoWorkflowInitialData.contractData?.product?.cargo?.loadingRate || undefined,
        loadingTerms: cargoWorkflowInitialData.contractData?.product?.cargo?.loadingTerms || '',
        dischargingPortId: cargoWorkflowInitialData.contractData?.product?.cargo?.dischargingPortId || '',
        destinationPlantId: cargoWorkflowInitialData.contractData?.product?.cargo?.destinationPlantId || '',
        dischargingRate: cargoWorkflowInitialData.contractData?.product?.cargo?.dischargingRate || undefined,
        dischargingTerms: cargoWorkflowInitialData.contractData?.product?.cargo?.dischargingTerms || '',
        laycan: cargoWorkflowInitialData.contractData?.product?.cargo?.laycan,
        originCountry: cargoWorkflowInitialData.contractData?.product?.cargo?.originCountry || undefined,
        originPlantId: cargoWorkflowInitialData.contractData?.product?.cargo?.originPlantId || undefined,
      },
      vesselInformation: {
        type: cargoWorkflowInitialData.contractData?.product?.vesselInformation?.type || undefined,
        maxAge: cargoWorkflowInitialData.contractData?.product?.vesselInformation?.maxAge || undefined,
        vesselsNominationDays:
          cargoWorkflowInitialData.contractData?.product?.vesselInformation?.vesselsNominationDays || undefined,
        vesselsNominationEvent:
          cargoWorkflowInitialData.contractData?.product?.vesselInformation?.vesselsNominationEvent || '',
        imoNumber: cargoWorkflowInitialData.contractData?.product?.vesselInformation?.imoNumber || '',
        cranesCapacity: cargoWorkflowInitialData.contractData?.product?.vesselInformation?.cranesCapacity || undefined,
        cranesNumber: cargoWorkflowInitialData.contractData?.product?.vesselInformation?.cranesNumber || undefined,
        gears: cargoWorkflowInitialData.contractData?.product?.vesselInformation?.gears || 'GEARLESS',
        grabs: cargoWorkflowInitialData.contractData?.product?.vesselInformation?.grabs || 'GRAB',
        grabsNumber: cargoWorkflowInitialData.contractData?.product?.vesselInformation?.grabsNumber || undefined,
        grabCapacity: cargoWorkflowInitialData.contractData?.product?.vesselInformation?.grabCapacity || undefined,
      },
      freightConditions: {
        loading: {
          safePortNo:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.loading?.safePortNo || undefined,
          safeBerthNo:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.loading?.safeBerthNo || undefined,
          freightRate:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.loading?.freightRate || undefined,
          norTendering:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.loading?.norTendering || 'atdn + SSHINC',
          exclusionFromLayTime:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.loading?.exclusionFromLayTime || [],
          laytimeCalculations:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.loading?.laytimeCalculations || [],
          berthConditions:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.loading?.berthConditions || 'AAAA',
          freightTerms:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.loading?.freightTerms || 'SSHINC',
          freightPeriodTerms:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.loading?.freightPeriodTerms || 'PWWD',
          turnTime: cargoWorkflowInitialData.contractData?.product?.freightConditions?.loading?.turnTime || 45,
          turnTimeTerms:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.loading?.turnTimeTerms || 'EVEN_IF_USED',
          cargoPda:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.loading?.cargoPda || 'AT_SELLERS_COST',
          vesselPda:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.loading?.vesselPda || 'AT_SELLERS_COST',
          other: cargoWorkflowInitialData.contractData?.product?.freightConditions?.loading?.other || '',
          exclusionFromLayTimeOtherExclusion:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.loading
              ?.exclusionFromLayTimeOtherExclusion || undefined,
        },
        discharging: {
          safePortNo:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.discharging?.safePortNo || undefined,
          safeBerthNo:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.discharging?.safeBerthNo || undefined,
          freightRate:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.discharging?.freightRate || undefined,
          norTendering:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.discharging?.norTendering ||
            'atdn + SSHINC',
          exclusionFromLayTime:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.discharging?.exclusionFromLayTime || [],
          laytimeCalculations:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.discharging?.laytimeCalculations || [],
          berthConditions:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.discharging?.berthConditions || 'AAAA',
          freightTerms:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.discharging?.freightTerms || 'SSHINC',
          freightPeriodTerms:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.discharging?.freightPeriodTerms ||
            'PWWD',
          turnTime: cargoWorkflowInitialData.contractData?.product?.freightConditions?.discharging?.turnTime || 45,
          turnTimeTerms:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.discharging?.turnTimeTerms ||
            'EVEN_IF_USED',
          cargoPda:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.discharging?.cargoPda ||
            'AT_SELLERS_COST',
          vesselPda:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.discharging?.vesselPda ||
            'AT_SELLERS_COST',
          other: cargoWorkflowInitialData.contractData?.product?.freightConditions?.discharging?.other || '',
          exclusionFromLayTimeOtherExclusion:
            cargoWorkflowInitialData.contractData?.product?.freightConditions?.discharging
              ?.exclusionFromLayTimeOtherExclusion || undefined,
        },
      },
      agentsInDischargingPort: {
        mainAgendConditions:
          cargoWorkflowInitialData.contractData?.product?.agentsInDischargingPort?.mainAgendConditions || 'N/A',
        protectiveAgendConditions:
          cargoWorkflowInitialData.contractData?.product?.agentsInDischargingPort?.protectiveAgendConditions || 'N/A',
        mainAgentName: cargoWorkflowInitialData.contractData?.product?.agentsInDischargingPort?.mainAgentName || '',
        protectiveAgentName:
          cargoWorkflowInitialData.contractData?.product?.agentsInDischargingPort?.protectiveAgentName || '',
      },
      samplingAndAnalysis: {
        surveyorNames: cargoWorkflowInitialData.contractData?.product?.samplingAndAnalysis?.surveyorNames || [
          { cost: 'AT_SELLERS_COST' },
        ],
        surveyorsAtDischargePort: cargoWorkflowInitialData.contractData?.product?.samplingAndAnalysis
          ?.surveyorsAtDischargePort || [{ cost: 'AT_SELLERS_COST' }],
        analysis: cargoWorkflowInitialData.contractData?.product?.samplingAndAnalysis?.analysis || [
          { type: 'MANUFACTURING_PLANT_LABORATORY' },
        ],
        arbitrationAnalysis:
          cargoWorkflowInitialData.contractData?.product?.samplingAndAnalysis?.arbitrationAnalysis || {},
        others: cargoWorkflowInitialData.contractData?.product?.samplingAndAnalysis?.others || undefined,
      },
      weight: {
        loadportSurveyors: cargoWorkflowInitialData.contractData?.product?.weight?.loadportSurveyors || [
          { cost: 'AT_SELLERS_COST' },
        ],
        dischargeSurveyors: cargoWorkflowInitialData.contractData?.product?.weight?.dischargeSurveyors || [
          { cost: 'AT_SELLERS_COST' },
        ],
        otherSurveyor: cargoWorkflowInitialData.contractData?.product?.weight?.otherSurveyor || undefined,
      },
    },
    other: cargoWorkflowInitialData.contractData?.other || '',
  } as ExternalFrontendRecapForm;
};

export const initializeSelectedPaymentConditions = (
  cargoWorkflowInitialData: WorkflowDTO
): SelectedPaymentConditions => {
  const initialState: SelectedPaymentConditions = JSON.parse(JSON.stringify(emptySelectedPaymentConditions));
  const conditions = cargoWorkflowInitialData?.contractData?.payment?.conditions || {};

  Object.keys(initialState).forEach((key) => {
    if (conditions[key]) {
      initialState[key as PaymentConditionType].checked = true;
    }
  });

  return initialState;
};

export const emptySelectedPaymentConditions: SelectedPaymentConditions = {
  prepayment: {
    checked: false,
    isDirty: false,
  },
  cashAgainstDocuments: {
    checked: false,
    isDirty: false,
  },
  irrevocableLetter: {
    checked: false,
    isDirty: false,
  },
  documentCollection: {
    checked: false,
    isDirty: false,
  },
  openAccount: {
    checked: false,
    isDirty: false,
  },
  consumptionPayment: {
    checked: false,
    isDirty: false,
  },
  other: {
    checked: false,
    isDirty: false,
  },
};

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type ContractCargoDeclarationStatus =
  (typeof ContractCargoDeclarationStatus)[keyof typeof ContractCargoDeclarationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContractCargoDeclarationStatus = {
  NOT_DECLARED: 'NOT_DECLARED',
  DECLARED: 'DECLARED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
} as const;

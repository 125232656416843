import { useFilteredCompanyPorts, UseFilteredCompanyPortsFilters } from '@/common/api-utils/use-filter-company-ports';
import { useMapPortsToSelectData } from '@/common/mapping-helpers';

export function useGetCompanyPortsFilteredSelectItems(
  companyId: string,
  filters: UseFilteredCompanyPortsFilters,
  useProductFilter = true
) {
  const { byPlantId, byType, byProductId } = filters;
  const { data: filteredPorts } = useFilteredCompanyPorts(
    companyId,
    {
      byPlantId,
      byType,
      byProductId,
    },
    useProductFilter
  );

  return useMapPortsToSelectData(filteredPorts);
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type GetFilteredCargosTransactionSide =
  (typeof GetFilteredCargosTransactionSide)[keyof typeof GetFilteredCargosTransactionSide];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetFilteredCargosTransactionSide = {
  BUYER: 'BUYER',
  SELLER: 'SELLER',
} as const;

import { ReactNode } from 'react';
import { Paper, createStyles } from '@mantine/core';
import { CompanyStatus } from 'api/generated/model';

import { CompanyHeader } from '../company-header/CompanyHeader';

const useStyles = createStyles((theme) => ({
  paper: {
    border: `1px solid ${theme.colors.gray[1]}`,
  },
}));

interface BaseCardProps {
  children: ReactNode;
  headerChildren?: ReactNode;
  companyName: string;
  companyStatus?: CompanyStatus;
  companyId: string;
  logoSrc?: string;
}

export function BaseCargoCard({
  children,
  companyName,
  companyStatus,
  logoSrc,
  companyId,
  headerChildren,
}: BaseCardProps) {
  const { classes } = useStyles();

  return (
    <Paper p="md" className={classes.paper} radius={8} withBorder>
      <CompanyHeader companyName={companyName} companyStatus={companyStatus} logoSrc={logoSrc} companyId={companyId}>
        {headerChildren}
      </CompanyHeader>
      {children}
    </Paper>
  );
}

import { Jurisdiction, Laws } from '../../types';
import { LawAndJurisdictionSchema } from './law-and-jurisdiction';

export const LAW_AND_JURISDICTION_INITIAL_VALUES: LawAndJurisdictionSchema = {
  law: Laws.ENGLISH_LAW,
  jurisdiction: Jurisdiction.ENGLISH_JURISDICTION,
};

//Empty values
export const LAW_AND_JURISDICTION_EMPTY_VALUES = {
  law: null,
  jurisdiction: null,
};

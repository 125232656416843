import Script from 'next/script';
import { useSession } from 'next-auth/react';
import { identifyCurrentUser } from 'api/generated/company-user/company-user';
import { useEffect, useState } from 'react';
import { Session } from 'next-auth/core/types';

declare global {
  interface Window {
    hsConversationsSettings: {
      loadImmediately?: boolean;
      identificationEmail?: string;
      identificationToken?: string;
    };
    HubSpotConversations?: any;
    hsConversationsOnReady?: any;
  }
}

const hotjarSiteId = process.env.NEXT_PUBLIC_HOTJAR_SITE_ID;

function displayChat() {
  window.HubSpotConversations.widget.load();
}

function initChat(session: Session) {
  identifyCurrentUser().then((response) => {
    window.hsConversationsSettings = {
      identificationEmail: session.me.email,
      identificationToken: response.data.token,
    };
    if (window.HubSpotConversations) displayChat();
    else window.hsConversationsOnReady = [() => displayChat()];
  });
}

export function ThirdPartyScripts(): JSX.Element {
  const { data: session, status } = useSession();
  const [cookieConsent, setCookieConsent] = useState(false);
  const isDevEnv = process.env.NEXT_PUBLIC_COOKIE_CONSENT_TRUE === 'true';

  if (cookieConsent && session && status === 'authenticated') initChat(session);

  useEffect(() => {
    if (isDevEnv) {
      setCookieConsent(true);
    } else {
      setCookieConsent(document.cookie.indexOf('cookieyes-consent') > -1);
    }
    window.hsConversationsSettings = {
      loadImmediately: false,
    };
  }, []);

  return (
    <>
      {cookieConsent && (
        <>
          {hotjarSiteId && (
            <Script id="hotjar" data-cookieyes="cookieyes-analytics" strategy="afterInteractive">
              {`(function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:${hotjarSiteId},hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
            </Script>
          )}
          <Script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="https://js-eu1.hs-scripts.com/26934241.js"
            data-cookieyes="cookieyes-functional"
            strategy="afterInteractive"
          />
        </>
      )}
      {!isDevEnv && (
        // eslint-disable-next-line @next/next/no-before-interactive-script-outside-document
        <Script
          id="cookieyes"
          type="text/javascript"
          src="https://cdn-cookieyes.com/client_data/c2656d5c7ad37a8b88e63dca/script.js"
          strategy="beforeInteractive"
        />
      )}
    </>
  );
}

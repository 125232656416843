/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type StepperAction = (typeof StepperAction)[keyof typeof StepperAction];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StepperAction = {
  movetoprevious: 'move.to.previous',
  movetonext: 'move.to.next',
} as const;

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * market-intelligence-api
 * Market Intelligence API
 * OpenAPI spec version: v1
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';

import type {
  GetAllArticlesResponse,
  GetArticlesParams,
  GetAllReportsResponse,
  GetReportsParams,
  GetReportResponse,
  EChartDataResponse,
  UnathorizedResponse,
  CargoPricesRequest,
  CargoVolumesRequest,
  GetAllRegionsResponse,
  GetAllCountriesResponse,
  GetAllPortsResponse,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Returns all articles from Strapi
 * @summary Get all articles
 */
export const getArticles = (
  params?: GetArticlesParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetAllArticlesResponse>> => {
  return axios.get(`/articles`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetArticlesQueryKey = (params?: GetArticlesParams) => [`/articles`, ...(params ? [params] : [])];

export type GetArticlesQueryResult = NonNullable<Awaited<ReturnType<typeof getArticles>>>;
export type GetArticlesQueryError = AxiosError<unknown>;

export const useGetArticles = <TData = Awaited<ReturnType<typeof getArticles>>, TError = AxiosError<unknown>>(
  params?: GetArticlesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getArticles>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetArticlesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getArticles>>> = ({ signal }) =>
    getArticles(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getArticles>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Returns an article from Strapi
 * @summary Get one article by Id
 */
export const getArticleById = (id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<unknown>> => {
  return axios.get(`/articles/${id}`, options);
};

export const getGetArticleByIdQueryKey = (id: string) => [`/articles/${id}`];

export type GetArticleByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getArticleById>>>;
export type GetArticleByIdQueryError = AxiosError<unknown>;

export const useGetArticleById = <TData = Awaited<ReturnType<typeof getArticleById>>, TError = AxiosError<unknown>>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getArticleById>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetArticleByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getArticleById>>> = ({ signal }) =>
    getArticleById(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getArticleById>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Returns all reports from Strapi
 * @summary Get all reports
 */
export const getReports = (
  params?: GetReportsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetAllReportsResponse>> => {
  return axios.get(`/reports`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetReportsQueryKey = (params?: GetReportsParams) => [`/reports`, ...(params ? [params] : [])];

export type GetReportsQueryResult = NonNullable<Awaited<ReturnType<typeof getReports>>>;
export type GetReportsQueryError = AxiosError<unknown>;

export const useGetReports = <TData = Awaited<ReturnType<typeof getReports>>, TError = AxiosError<unknown>>(
  params?: GetReportsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getReports>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReports>>> = ({ signal }) =>
    getReports(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getReports>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Returns a report from Strapi
 * @summary Get one report by Id
 */
export const getReportById = (id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<GetReportResponse>> => {
  return axios.get(`/reports/${id}`, options);
};

export const getGetReportByIdQueryKey = (id: string) => [`/reports/${id}`];

export type GetReportByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getReportById>>>;
export type GetReportByIdQueryError = AxiosError<unknown>;

export const useGetReportById = <TData = Awaited<ReturnType<typeof getReportById>>, TError = AxiosError<unknown>>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getReportById>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportById>>> = ({ signal }) =>
    getReportById(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getReportById>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Returns chart data for cargo prices
 * @summary Cargo Prices
 */
export const cargoPrices = (
  cargoPricesRequest: CargoPricesRequest,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<EChartDataResponse>> => {
  return axios.post(`/cargo-prices`, cargoPricesRequest, options);
};

export type CargoPricesMutationResult = NonNullable<Awaited<ReturnType<typeof cargoPrices>>>;
export type CargoPricesMutationBody = CargoPricesRequest;
export type CargoPricesMutationError = AxiosError<UnathorizedResponse>;

export const useCargoPrices = <TError = AxiosError<UnathorizedResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cargoPrices>>,
    TError,
    { data: CargoPricesRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof cargoPrices>>, { data: CargoPricesRequest }> = (
    props
  ) => {
    const { data } = props ?? {};

    return cargoPrices(data, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof cargoPrices>>, TError, { data: CargoPricesRequest }, TContext>(
    mutationFn,
    mutationOptions
  );
};
/**
 * Returns chart data for cargo volumes
 * @summary Cargo Volumes
 */
export const cargoVolumes = (
  cargoVolumesRequest: CargoVolumesRequest,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<EChartDataResponse>> => {
  return axios.post(`/cargo-volumes`, cargoVolumesRequest, options);
};

export type CargoVolumesMutationResult = NonNullable<Awaited<ReturnType<typeof cargoVolumes>>>;
export type CargoVolumesMutationBody = CargoVolumesRequest;
export type CargoVolumesMutationError = AxiosError<UnathorizedResponse>;

export const useCargoVolumes = <TError = AxiosError<UnathorizedResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cargoVolumes>>,
    TError,
    { data: CargoVolumesRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof cargoVolumes>>, { data: CargoVolumesRequest }> = (
    props
  ) => {
    const { data } = props ?? {};

    return cargoVolumes(data, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof cargoVolumes>>, TError, { data: CargoVolumesRequest }, TContext>(
    mutationFn,
    mutationOptions
  );
};
/**
 * Returns all regions with market-intelligence data on cargo volumes
 * @summary Get all regions with data on cargo volumes
 */
export const getCargoVolumesRegions = (options?: AxiosRequestConfig): Promise<AxiosResponse<GetAllRegionsResponse>> => {
  return axios.get(`/cargo-volumes/regions`, options);
};

export const getGetCargoVolumesRegionsQueryKey = () => [`/cargo-volumes/regions`];

export type GetCargoVolumesRegionsQueryResult = NonNullable<Awaited<ReturnType<typeof getCargoVolumesRegions>>>;
export type GetCargoVolumesRegionsQueryError = AxiosError<unknown>;

export const useGetCargoVolumesRegions = <
  TData = Awaited<ReturnType<typeof getCargoVolumesRegions>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getCargoVolumesRegions>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCargoVolumesRegionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCargoVolumesRegions>>> = ({ signal }) =>
    getCargoVolumesRegions({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCargoVolumesRegions>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Returns all countries with market-intelligence data on cargo volumes
 * @summary Get all countries with data on cargo volumes
 */
export const getCargoVolumesCountries = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetAllCountriesResponse>> => {
  return axios.get(`/cargo-volumes/countries`, options);
};

export const getGetCargoVolumesCountriesQueryKey = () => [`/cargo-volumes/countries`];

export type GetCargoVolumesCountriesQueryResult = NonNullable<Awaited<ReturnType<typeof getCargoVolumesCountries>>>;
export type GetCargoVolumesCountriesQueryError = AxiosError<unknown>;

export const useGetCargoVolumesCountries = <
  TData = Awaited<ReturnType<typeof getCargoVolumesCountries>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getCargoVolumesCountries>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCargoVolumesCountriesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCargoVolumesCountries>>> = ({ signal }) =>
    getCargoVolumesCountries({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCargoVolumesCountries>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Returns all ports with market-intelligence data on cargo volumes
 * @summary Get all ports with data on cargo volumes
 */
export const getCargoVolumesPorts = (options?: AxiosRequestConfig): Promise<AxiosResponse<GetAllPortsResponse>> => {
  return axios.get(`/cargo-volumes/ports`, options);
};

export const getGetCargoVolumesPortsQueryKey = () => [`/cargo-volumes/ports`];

export type GetCargoVolumesPortsQueryResult = NonNullable<Awaited<ReturnType<typeof getCargoVolumesPorts>>>;
export type GetCargoVolumesPortsQueryError = AxiosError<unknown>;

export const useGetCargoVolumesPorts = <
  TData = Awaited<ReturnType<typeof getCargoVolumesPorts>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getCargoVolumesPorts>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCargoVolumesPortsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCargoVolumesPorts>>> = ({ signal }) =>
    getCargoVolumesPorts({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCargoVolumesPorts>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

import { ActionIcon, Box, Button, createStyles, Flex, Group, Menu, Tooltip } from '@mantine/core';
import NextLink from 'next/link';
import { CellContext, ColumnDefTemplate, Row } from '@tanstack/react-table';
import { UserRole } from 'api/generated/model';
import { useTranslation } from 'next-i18next';
import { RiMore2Fill, RiStarLine, RiLogoutCircleRLine } from 'react-icons/ri';
import { useGetDetailOfferById } from 'api/generated/detail-offer/detail-offer';

import { routes } from '@/common/routes';
import { ButtonExpand, HideForbiddenElement, Text } from '@/components';
import { useGetCargoDescription } from '@/views/my-cargos/utils/use-get-cargo-description';

import { MyCargoRowData, OfferType } from '../../types';

const useStyles = createStyles((theme) => ({
  icon: {
    color: theme.fn.primaryColor(),
    fontSize: 24,
  },
  hiddenOnMobile: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },
  group: {
    gap: 24,

    [theme.fn.smallerThan('md')]: {
      gap: 0,
    },
  },
  outlineButton: {
    [theme.fn.smallerThan('md')]: {
      padding: '1px 4px',
      fontSize: 14,
      lineHeight: '22px',
      height: 24,
    },
  },
  descriptionCell: {
    [theme.fn.smallerThan('md')]: {
      '&::after': {
        content: '""',
        width: 200,
        display: 'block',
      },
    },
  },
}));

interface NegotationCellProps extends CellContext<MyCargoRowData, number> {
  type: OfferType;
  onCancel: (id: string, type: OfferType) => void;
}

export const NegotiationCell: ColumnDefTemplate<NegotationCellProps> = ({ getValue, row, type, onCancel }) => {
  const { t } = useTranslation('myCargos');
  const { classes, theme } = useStyles();
  const { data } = useGetDetailOfferById(row.original.detailedOfferId, {
    query: {
      select: ({ data }) => data,
      enabled: !!row.original.detailedOfferId,
    },
  });

  const isDetailedNeed = !!data?.cargoNeedId;
  const isCargoDetailOffer = !!row.original.detailedOfferId;
  const isCargoSharedAvailability = !!row.original?.isModularizationPromoted;

  return (
    <Group className={classes.group} noWrap>
      <Group className={classes.hiddenOnMobile} w={104}>
        <Text w={24}>{getValue()}</Text>
      </Group>
      <Flex w="100%" justify="space-between" align="center">
        <HideForbiddenElement allowedRoles={[UserRole.ADMIN, UserRole.BUYER, UserRole.SELLER]}>
          {!row.original.isModularizationDetailedOffer && (
            <Button
              onClick={(e) => e.stopPropagation()}
              className={classes.outlineButton}
              component={NextLink}
              href={
                type === 'request'
                  ? routes.CargoNeedDetails(row.original.id)
                  : routes.CargoOfferDetails(row.original.id)
              }
              variant="outline"
            >
              {isCargoSharedAvailability
                ? t('table.buttons.viewCargoSharedAvailability')
                : t('table.buttons.viewCargo')}
            </Button>
          )}
          {row.original.isModularizationDetailedOffer && data && (
            <Button
              onClick={(e) => e.stopPropagation()}
              className={classes.outlineButton}
              component={NextLink}
              href={routes.DetailedOffer(row.original.detailedOfferId)}
              variant="outline"
            >
              {isDetailedNeed ? t('table.buttons.viewDetailedOfferNeed') : t('table.buttons.viewDetailedOffer')}
            </Button>
          )}
        </HideForbiddenElement>
        <Flex align="center">
          <ButtonExpand
            onClick={(e) => {
              e.stopPropagation();
              row.toggleExpanded();
            }}
            isExpanded={row.getIsExpanded()}
          >
            {t('table.buttons.viewNegotations')}
          </ButtonExpand>
          <Menu>
            <Menu.Target>
              <ActionIcon
                component="button"
                variant="transparent"
                aria-label="More actions"
                data-testid="more-actions"
                onClick={(event) => event.stopPropagation()}
              >
                <RiMore2Fill fontSize={24} color={theme.fn.primaryColor()} />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                disabled={isCargoDetailOffer}
                onClick={(e) => {
                  e.stopPropagation();
                  onCancel(row.original.id, type);
                }}
              >
                {t(`buttons.cancelCargo`)}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Flex>
      </Flex>
    </Group>
  );
};

export const DescriptionCell: ColumnDefTemplate<CellContext<MyCargoRowData, string>> = ({ row }) => {
  const { classes } = useStyles();
  const cargoDescription = useGetCargoDescription(row.original);

  return <span className={classes.descriptionCell}>{cargoDescription}</span>;
};

interface ViewCargoCellProps extends CellContext<MyCargoRowData, number> {
  onClick: (row: Row<MyCargoRowData>) => void;
}

export const ViewCargoCell: ColumnDefTemplate<ViewCargoCellProps> = ({ row, onClick }) => {
  const { t } = useTranslation('myCargos');
  const { classes } = useStyles();

  return (
    <HideForbiddenElement allowedRoles={[UserRole.ADMIN, UserRole.BUYER, UserRole.SELLER]}>
      <Button onClick={() => onClick(row)} className={classes.outlineButton} variant="outline">
        {t('table.buttons.viewCargo')}
      </Button>
    </HideForbiddenElement>
  );
};

interface CargoPromotionProps {
  networkName?: string;
}

export const CargoPromotionCell: ColumnDefTemplate<CargoPromotionProps> = ({ networkName }) => {
  const { theme } = useStyles();
  const { t } = useTranslation('myCargos');
  const promotedLabel = t('table.labels.promotedToGroup', { group: networkName });

  return (
    <Tooltip label={promotedLabel} disabled={!networkName}>
      <Box>
        <RiStarLine style={{ display: 'block' }} fontSize={16} color={theme.fn.primaryColor()} />
      </Box>
    </Tooltip>
  );
};

export const ModularizationDetailedOfferCargoCell: ColumnDefTemplate<CargoPromotionProps> = () => {
  const { theme } = useStyles();

  return (
    <Box>
      <RiLogoutCircleRLine style={{ display: 'block' }} fontSize={16} color={theme.fn.primaryColor()} />
    </Box>
  );
};

export const ModularizationCargoPromotionCell: ColumnDefTemplate<CargoPromotionProps> = () => {
  const { theme } = useStyles();

  return (
    <Box>
      <RiStarLine style={{ display: 'block' }} fontSize={16} color={theme.fn.primaryColor()} />
    </Box>
  );
};

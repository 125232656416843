/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoTenderShipmentFrequency =
  (typeof CargoTenderShipmentFrequency)[keyof typeof CargoTenderShipmentFrequency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoTenderShipmentFrequency = {
  EVENLY_SPREAD: 'EVENLY_SPREAD',
  BY_DATES: 'BY_DATES',
} as const;

import { Button, ButtonProps, createStyles } from '@mantine/core';
import React, { ReactNode } from 'react';
import { RiArrowDropUpLine } from 'react-icons/ri';

const useStyles = createStyles((theme) => ({
  subtleButtonLabel: {
    [theme.fn.smallerThan('md')]: {
      whiteSpace: 'break-spaces',
      fontSize: 12,
      lineHeight: '16px',
      paddingLeft: 12,
    },
  },
  arrowIcon: {
    fontSize: 32, // Set a fixed size for the icon
    width: 24, // Add fixed width
    height: 24, // Add fixed height
  },
  iconUp: {
    transition: 'transform 200ms ease-in-out',
    transform: 'translateY(2px) rotate(0deg)',
  },
  iconDown: {
    transition: 'transform 200ms ease-in-out',
    transform: 'translateY(2px) rotate(-180deg)',
  },
}));

export interface ButtonExpandProps extends ButtonProps {
  children?: ReactNode;
  isExpanded: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export function ButtonExpand(props: ButtonExpandProps) {
  const { children, isExpanded, ...rest } = props;
  const { classes, cx } = useStyles();

  return (
    <Button classNames={{ label: classes.subtleButtonLabel }} p={0} variant="subtle" {...rest}>
      {children}
      <RiArrowDropUpLine
        className={cx(isExpanded ? classes.iconUp : classes.iconDown, classes.arrowIcon)}
        size={props.size}
      />
    </Button>
  );
}

import { z } from 'zod';

import { CommercialActivity } from '../types';

const contactSchema = z.object({
  id: z.string().optional(),
  private: z.boolean().optional(),
  withContext: z.boolean().optional(),
  name: z.string().nonempty(),
  surname: z.string().nonempty(),
  email: z.string().email(),
  phoneNumber: z
    .string()
    .min(7)
    .max(20)
    .regex(/^\+?\d+((-|\s)\d+)*$/, 'Invalid phone number')
    .nonempty(),
});

const commercialActivitySchema = z.object({
  id: z.string().optional(),
  productId: z.string().nonempty(),
  activity: z.nativeEnum(CommercialActivity),
});

export const myPrivateCompanySchema = z.object({
  id: z.string().optional(),
  name: z.string().max(120).nonempty(),
  country: z.string().nonempty(),
  commercialActivities: z.array(commercialActivitySchema),
  tradingContacts: z.array(contactSchema),
  operationsContacts: z.array(contactSchema),
});

export type MyPrivateCompany = z.infer<typeof myPrivateCompanySchema>;

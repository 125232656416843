/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type GetAllDetailedOffersInvitedResponseItemDtoCargoOfferDtoStatus =
  (typeof GetAllDetailedOffersInvitedResponseItemDtoCargoOfferDtoStatus)[keyof typeof GetAllDetailedOffersInvitedResponseItemDtoCargoOfferDtoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAllDetailedOffersInvitedResponseItemDtoCargoOfferDtoStatus = {
  FIRM: 'FIRM',
  TENTATIVE: 'TENTATIVE',
  BOOKED: 'BOOKED',
  ARCHIVED: 'ARCHIVED',
  CANCELED: 'CANCELED',
} as const;

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CompanyPlatformInvitationStatus =
  (typeof CompanyPlatformInvitationStatus)[keyof typeof CompanyPlatformInvitationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyPlatformInvitationStatus = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
} as const;

import { useTranslation } from 'next-i18next';
import { TextInput } from '@mantine/core';

import { GetInputProps } from '@/common/types';

interface OtherConditionsFieldGroupProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getInputProps: GetInputProps<any>;
  data?: any;
  masterdata?: any;
  conditionsPath: string;
}

export function OtherConditionsFieldGroup({ getInputProps, conditionsPath }: OtherConditionsFieldGroupProps) {
  const { t } = useTranslation('tenderNegotiation');

  return (
    <>
      <TextInput
        label={t('labels.others')}
        withAsterisk={false}
        placeholder={t('placeholders.conditions')}
        {...getInputProps(conditionsPath)}
      />
    </>
  );
}

import { Box, createStyles, LoadingOverlay, Stack } from '@mantine/core';
import { useGetCompanyPort } from 'api/generated/companies/companies';
import { useGetStandardPlantById } from 'api/generated/masterdata/masterdata';
import { CargoTransportation } from 'api/generated/model';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';

import { formatDate } from '@/common/format-date';
import { Text } from '@/components';
import { RecapForm } from '@/blink-forms';

import { useRecapFormMasterdata } from '../state/use-recap-form-masterdata';
import * as argsSelector from '../utils/recap-preview-data-selectors';
import { HighlightProvider, HighlightSentenceDiff, HighlightSingleDiff } from './HighlightDiff';
import { ProductSpecificationByQueryDiff } from './ProductSpecificationByQueryDiff';

const useStyles = createStyles(() => ({
  labeledValue: {
    '& > *': {
      marginRight: '4px',
    },
  },
}));

interface LabeledProps {
  label: string;
  children: ReactNode;
}

function Labeled(props: LabeledProps) {
  const { label, children } = props;
  const { classes } = useStyles();

  return (
    <Box>
      <Text component="span" weight={600} sx={{ flexShrink: 0 }}>
        {label}:&nbsp;
      </Text>
      <span className={classes.labeledValue}>
        {typeof children === 'object' ? children : <Text component="span">{children}</Text>}
      </span>
    </Box>
  );
}

export interface RecapPreviewModalContentProps {
  prevForm: RecapForm | undefined;
  currentForm: RecapForm;
  seller: string;
  buyer: string;
  offeredTransport: CargoTransportation;
  sellerCompanyId: string;
  buyerCompanyId?: string;
}

export function RecapPreviewModalContent(props: RecapPreviewModalContentProps) {
  const { prevForm, currentForm, seller, buyer, offeredTransport, sellerCompanyId, buyerCompanyId } = props;
  const { t } = useTranslation('recapForm');
  const masterdata = useRecapFormMasterdata();
  const origin = currentForm?.product.cargo.originPlantCountry;
  const originPlantId = currentForm?.product.cargo.originPlantId;
  const loadingOriginPlantName = useGetStandardPlantById(originPlantId, {
    query: { select: (response) => response.data.name },
  });
  const destinationPlantId = currentForm?.product.cargo.destinationPlantId;
  const destinationPlantName = useGetStandardPlantById(destinationPlantId, {
    query: { select: (response) => response.data.name },
  });
  const currentLaycan = currentForm ? currentForm?.product.cargo.laycan : undefined;
  const prevLaycan = prevForm ? prevForm?.product.cargo.laycan : undefined;
  const isFob = offeredTransport === 'FOB';
  const portId = isFob ? currentForm?.product.cargo.loadingPortId : currentForm?.product.cargo.dischargingPortId;
  const currentLoadingPortId = currentForm?.product.cargo.loadingPortId;
  const prevLoadingPortId = prevForm?.product.cargo.loadingPortId;
  const currentDischargingPortId = currentForm?.product.cargo.dischargingPortId;
  const prevDischargingPortId = prevForm?.product.cargo.dischargingPortId;

  const port = useGetCompanyPort(sellerCompanyId, portId, { query: { select: (response) => response.data } });

  const currentLoadingPortName = useGetCompanyPort(sellerCompanyId, currentLoadingPortId, {
    query: { select: (response) => response.data.name },
  });

  const prevLoadingPortName = useGetCompanyPort(sellerCompanyId, prevLoadingPortId, {
    query: { select: (response) => response.data.name },
  });

  const currentDischargingPortName = useGetCompanyPort(buyerCompanyId, currentDischargingPortId, {
    query: { select: (response) => response.data.name },
  });

  const prevDischargingPortName = useGetCompanyPort(buyerCompanyId, prevDischargingPortId, {
    query: { select: (response) => response.data.name },
  });

  const currentLaycanFormatted = currentLaycan
    ? `${formatDate(currentLaycan[0])} - ${formatDate(currentLaycan[1])}`
    : undefined;
  const prevLaycanFormatted = prevLaycan ? `${formatDate(prevLaycan[0])} - ${formatDate(prevLaycan[1])}` : undefined;

  if (masterdata.translations.isLoading) {
    return null;
  }

  const showSpinner =
    (originPlantId && loadingOriginPlantName.isLoading) ||
    (currentLoadingPortId && currentLoadingPortName.isLoading) ||
    (prevLoadingPortId && prevLoadingPortName.isLoading) ||
    (portId && port.isLoading) ||
    !currentForm;

  const masterdataTranslations = masterdata.translations.data;

  return (
    <Stack spacing={16}>
      <LoadingOverlay visible={showSpinner} data-testid={'loader'} />
      <HighlightProvider highlight={!!prevForm}>
        <Labeled label={t('preview.labels.seller')}>{seller}</Labeled>
        <Labeled label={t('preview.labels.buyer')}>{buyer}</Labeled>
        <Labeled label={t('preview.labels.price')}>
          <HighlightSingleDiff
            prev={argsSelector.toPriceArgs(prevForm)?.totalPrice}
            current={argsSelector.toPriceArgs(currentForm)?.totalPrice}
          />
        </Labeled>
        {loadingOriginPlantName?.data && (
          <Labeled label={t('preview.labels.origin')}>
            {t('preview.labels.originPlant', { origin, plantName: loadingOriginPlantName?.data })}
          </Labeled>
        )}
        <Labeled label={t('preview.labels.product')}>
          <HighlightSentenceDiff
            t={t}
            prevArgs={argsSelector.toProductArgs(prevForm)}
            currentArgs={argsSelector.toProductArgs(currentForm)}
            i18nKey="preview.sentences.product"
          />
        </Labeled>
        <Labeled label={t('preview.labels.qualitySpecification')}>
          <ProductSpecificationByQueryDiff
            companyId={sellerCompanyId}
            prevSpecificationQuery={argsSelector.toProductSpecificationArgs(prevForm)}
            specificationQuery={argsSelector.toProductSpecificationArgs(currentForm)}
          />
        </Labeled>
        <Labeled label={t('preview.labels.quantity')}>
          <HighlightSentenceDiff
            t={t}
            prevArgs={argsSelector.toQuantityArgs(prevForm, masterdataTranslations)}
            currentArgs={argsSelector.toQuantityArgs(currentForm, masterdataTranslations)}
            i18nKey="preview.sentences.quantity"
          />
        </Labeled>
        <Labeled label={t('preview.labels.incoterms')}>
          {`${offeredTransport}, `}
          {offeredTransport == 'FOB' ? (
            <HighlightSingleDiff prev={prevLoadingPortName?.data} current={currentLoadingPortName?.data} />
          ) : (
            <HighlightSingleDiff prev={prevDischargingPortName?.data} current={currentDischargingPortName?.data} />
          )}
        </Labeled>
        {destinationPlantName?.data && (
          <Labeled label={t('preview.labels.destinationPlant')}>{destinationPlantName?.data}</Labeled>
        )}
        {offeredTransport === CargoTransportation.CIF && (
          <Labeled label={t('preview.labels.insurance')}>{t('preview.sentences.insuranceText')}</Labeled>
        )}
        <Labeled label={t('preview.labels.laycan')}>
          <HighlightSingleDiff prev={prevLaycanFormatted} current={currentLaycanFormatted} />
        </Labeled>
        <Labeled label={t('preview.labels.weight')}>
          <HighlightSentenceDiff
            t={t}
            prevArgs={argsSelector.toLoadportWeightArgs(prevForm, masterdataTranslations)}
            currentArgs={argsSelector.toLoadportWeightArgs(currentForm, masterdataTranslations)}
            i18nKey="preview.sentences.weight"
          />
          <HighlightSentenceDiff
            t={t}
            prevArgs={argsSelector.toLoadportWeightExtraArgs(prevForm, masterdataTranslations)}
            currentArgs={argsSelector.toLoadportWeightExtraArgs(currentForm, masterdataTranslations)}
            i18nKey="preview.sentences.weightExtraSurveyor"
          />
          <HighlightSentenceDiff
            t={t}
            prevArgs={argsSelector.toDischargeportWeightArgs(prevForm, masterdataTranslations)}
            currentArgs={argsSelector.toDischargeportWeightArgs(currentForm, masterdataTranslations)}
            i18nKey="preview.sentences.weightDischargeSurveyor"
          />
          <HighlightSentenceDiff
            t={t}
            prevArgs={argsSelector.toDischargeportWeightExtraArgs(prevForm, masterdataTranslations)}
            currentArgs={argsSelector.toDischargeportWeightExtraArgs(currentForm, masterdataTranslations)}
            i18nKey="preview.sentences.weightExtraSurveyor"
          />
          <HighlightSingleDiff
            prev={prevForm?.product.weight.otherSurveyor}
            current={currentForm?.product.weight.otherSurveyor}
          />
        </Labeled>
        <Labeled label={t('preview.labels.samplingAnalysis')}>
          <HighlightSentenceDiff
            t={t}
            prevArgs={argsSelector.toSamplingAnalysisArgs(prevForm, masterdataTranslations)}
            currentArgs={argsSelector.toSamplingAnalysisArgs(currentForm, masterdataTranslations)}
            i18nKey="preview.sentences.samplingAnalysis"
          />
          <HighlightSentenceDiff
            t={t}
            prevArgs={argsSelector.toSamplingAnalysisExtraArgs(prevForm, masterdataTranslations)}
            currentArgs={argsSelector.toSamplingAnalysisExtraArgs(currentForm, masterdataTranslations)}
            i18nKey="preview.sentences.samplingAnalysisExtraSurveyor"
          />
          <HighlightSentenceDiff
            t={t}
            prevArgs={argsSelector.toSurveyorAtDischagePortArgs(prevForm, masterdataTranslations)}
            currentArgs={argsSelector.toSurveyorAtDischagePortArgs(currentForm, masterdataTranslations)}
            i18nKey="preview.sentences.surveyorDischargePort"
          />
          <HighlightSentenceDiff
            t={t}
            prevArgs={argsSelector.toSamplingAnalysisMainCertificate(prevForm, masterdataTranslations)}
            currentArgs={argsSelector.toSamplingAnalysisMainCertificate(currentForm, masterdataTranslations)}
            i18nKey="preview.sentences.samplingAnalysisDetails.mainCertificate"
          />
          <HighlightSentenceDiff
            t={t}
            prevArgs={argsSelector.toArbitrationAnalysisCertificate(prevForm, masterdataTranslations)}
            currentArgs={argsSelector.toArbitrationAnalysisCertificate(currentForm, masterdataTranslations)}
            i18nKey="preview.sentences.samplingAnalysisDetails.arbitrationCertificate"
          />
          <HighlightSingleDiff
            prev={prevForm?.product?.samplingAndAnalysis?.others}
            current={currentForm?.product?.samplingAndAnalysis?.others}
          />
        </Labeled>
        <Labeled label={t('preview.labels.vesselInformation')}>
          <HighlightSentenceDiff
            t={t}
            prevArgs={argsSelector.toTbnArgs(prevForm, masterdataTranslations)}
            currentArgs={argsSelector.toTbnArgs(currentForm, masterdataTranslations)}
            i18nKey="preview.sentences.tbn"
          />
          <HighlightSentenceDiff
            t={t}
            prevArgs={argsSelector.toNominatedVesselArgs(prevForm, masterdataTranslations)}
            currentArgs={argsSelector.toNominatedVesselArgs(currentForm, masterdataTranslations)}
            i18nKey="preview.sentences.nominatedVessel"
          />
          <HighlightSentenceDiff
            t={t}
            prevArgs={argsSelector.toVesselGearsAndCranesArgs(prevForm, masterdataTranslations)}
            currentArgs={argsSelector.toVesselGearsAndCranesArgs(currentForm, masterdataTranslations)}
            i18nKey="preview.sentences.vesselGearsAndCranes"
          />
        </Labeled>
        <Labeled
          label={
            offeredTransport === 'FOB'
              ? t('preview.labels.loadingConditions')
              : t('preview.labels.dischargingConditions')
          }
        >
          <HighlightSentenceDiff
            t={t}
            prevArgs={argsSelector.toFreighConditionsArgs(prevForm, masterdataTranslations, port.data)}
            currentArgs={argsSelector.toFreighConditionsArgs(currentForm, masterdataTranslations, port.data)}
            i18nKey={
              offeredTransport === 'FOB'
                ? 'preview.sentences.loadingConditions'
                : 'preview.sentences.dischargingConditions'
            }
          />
          {currentForm?.product?.freightConditions?.other ? `${currentForm.product.freightConditions.other}. ` : ''}
          {currentForm?.product?.agentsInDischargingPort?.mainAgentName ? (
            <HighlightSentenceDiff
              t={t}
              prevArgs={argsSelector.toFreighConditionsArgs(prevForm, masterdataTranslations, port.data)}
              currentArgs={argsSelector.toFreighConditionsArgs(currentForm, masterdataTranslations, port.data)}
              i18nKey={
                offeredTransport === 'FOB' ? 'preview.sentences.mainAgent' : 'preview.sentences.mainAgentDischarging'
              }
            />
          ) : null}
          {currentForm?.product?.agentsInDischargingPort?.protectiveAgentName ? (
            <HighlightSentenceDiff
              t={t}
              prevArgs={argsSelector.toFreighConditionsArgs(prevForm, masterdataTranslations, port.data)}
              currentArgs={argsSelector.toFreighConditionsArgs(currentForm, masterdataTranslations, port.data)}
              i18nKey={
                offeredTransport === 'FOB'
                  ? 'preview.sentences.protectiveAgent'
                  : 'preview.sentences.protectiveAgentDischarging'
              }
            />
          ) : null}
        </Labeled>

        <Labeled label={t('preview.labels.payment')}>
          <HighlightSentenceDiff
            t={t}
            prevArgs={argsSelector.toPrepaymentArgs(prevForm, masterdataTranslations)}
            currentArgs={argsSelector.toPrepaymentArgs(currentForm, masterdataTranslations)}
            i18nKey="preview.sentences.paymentTerms.prepayment"
          />
          <HighlightSentenceDiff
            t={t}
            prevArgs={argsSelector.toCashAgainstDocumentsArgs(prevForm, masterdataTranslations)}
            currentArgs={argsSelector.toCashAgainstDocumentsArgs(currentForm, masterdataTranslations)}
            i18nKey="preview.sentences.paymentTerms.cashAgainstDocuments"
          />
          <HighlightSentenceDiff
            t={t}
            prevArgs={argsSelector.toIrrevocableLetterArgs(prevForm, masterdataTranslations)}
            currentArgs={argsSelector.toIrrevocableLetterArgs(currentForm, masterdataTranslations)}
            i18nKey="preview.sentences.paymentTerms.irrevocableLetter"
          />
          <HighlightSentenceDiff
            t={t}
            prevArgs={argsSelector.toDocumentaryCollectionArgs(prevForm, masterdataTranslations)}
            currentArgs={argsSelector.toDocumentaryCollectionArgs(currentForm, masterdataTranslations)}
            i18nKey="preview.sentences.paymentTerms.documentaryCollection"
          />
          <HighlightSentenceDiff
            t={t}
            prevArgs={argsSelector.toOpenAccountArgs(prevForm, masterdataTranslations)}
            currentArgs={argsSelector.toOpenAccountArgs(currentForm, masterdataTranslations)}
            i18nKey="preview.sentences.paymentTerms.openAccount"
          />
          <HighlightSentenceDiff
            t={t}
            prevArgs={argsSelector.toConsumptionArgs(prevForm, masterdataTranslations)}
            currentArgs={argsSelector.toConsumptionArgs(currentForm, masterdataTranslations)}
            i18nKey="preview.sentences.paymentTerms.consumption"
          />
          <HighlightSentenceDiff
            t={t}
            prevArgs={argsSelector.toOtherPaymentArgs(prevForm)}
            currentArgs={argsSelector.toOtherPaymentArgs(currentForm)}
            i18nKey="preview.sentences.paymentTerms.other"
          />
        </Labeled>
        <Labeled label={t('preview.labels.demurrage')}>
          <HighlightSentenceDiff
            t={t}
            prevArgs={argsSelector.toDemurrageArgs(prevForm, masterdataTranslations)}
            currentArgs={argsSelector.toDemurrageArgs(currentForm, masterdataTranslations)}
            i18nKey="preview.sentences.demurrage"
          />
        </Labeled>
        <Labeled label={t('preview.labels.lawJurisdiction')}>
          <HighlightSentenceDiff
            t={t}
            prevArgs={argsSelector.toLawJurisdictionArgs(prevForm, masterdataTranslations)}
            currentArgs={argsSelector.toLawJurisdictionArgs(currentForm, masterdataTranslations)}
            i18nKey="preview.sentences.lawJurisdiction"
          />
        </Labeled>
        <Labeled label={t('preview.labels.other')}>
          <HighlightSingleDiff prev={prevForm?.other} current={currentForm?.other} />
        </Labeled>
      </HighlightProvider>
    </Stack>
  );
}

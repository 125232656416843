export enum Incoterm {
  FOB = 'FOB',
  CFR = 'CFR',
  CIF = 'CIF',
}

export enum PaymentTerms {
  PRE_PAYMENT = 'PRE_PAYMENT',
  CONSUMPTION = 'CONSUMPTION',
  CASH_AGAINS_DOCUMENTS = 'CASH_AGAINS_DOCUMENTS',
  OPEN_ACCOUNT = 'OPEN_ACCOUNT',
  LETTER_OF_CREDIT_AT_SIGHT = 'LETTER_OF_CREDIT_AT_SIGHT',
  LETTER_OF_CREDIT_DEFERRED = 'LETTER_OF_CREDIT_DEFERRED',
  DOCUMENTARY_COLLECTION = 'DOCUMENTARY_COLLECTION',
  OTHER = 'OTHER',
}

export enum VesselType {
  TBN = 'TBN',
  NOMINATED_VESSEL = 'NOMINATED_VESSEL',
}

export enum Gears {
  GEARLESS = 'GEARLESS',
  GEARED = 'GEARED',
}

export enum Grabs {
  GRABLESS = 'GRABLESS',
  GRABBED = 'GRABBED',
}

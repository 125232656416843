/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';

import type {
  CargosDTO,
  CanceledCargoDTO,
  GetCanceledCargosParams,
  CompanyCargoStats,
  ApiErrorResponse,
  CreateCargoNeedDTO,
  CreateCargoOfferDTO,
  CargoNeedDTO,
  CreateCargoOfferWithRequestDTO,
  CargoPromotionDTO,
  CargoOfferDTO,
  CreateCargoNeedWithRequestDTO,
  CancelCargoOfferDTO,
  CargoOfferSearchesDTO,
  CargoNeedSearchesDTO,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getAllUserCompaniesCargos = (options?: AxiosRequestConfig): Promise<AxiosResponse<CargosDTO>> => {
  return axios.get(`/api/v1/cargos`, options);
};

export const getGetAllUserCompaniesCargosQueryKey = () => [`/api/v1/cargos`];

export type GetAllUserCompaniesCargosQueryResult = NonNullable<Awaited<ReturnType<typeof getAllUserCompaniesCargos>>>;
export type GetAllUserCompaniesCargosQueryError = AxiosError<void>;

export const useGetAllUserCompaniesCargos = <
  TData = Awaited<ReturnType<typeof getAllUserCompaniesCargos>>,
  TError = AxiosError<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllUserCompaniesCargos>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllUserCompaniesCargosQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllUserCompaniesCargos>>> = ({ signal }) =>
    getAllUserCompaniesCargos({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getAllUserCompaniesCargos>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getCanceledCargos = (
  params?: GetCanceledCargosParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CanceledCargoDTO[]>> => {
  return axios.get(`/api/v1/cargos/canceled`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetCanceledCargosQueryKey = (params?: GetCanceledCargosParams) => [
  `/api/v1/cargos/canceled`,
  ...(params ? [params] : []),
];

export type GetCanceledCargosQueryResult = NonNullable<Awaited<ReturnType<typeof getCanceledCargos>>>;
export type GetCanceledCargosQueryError = AxiosError<void>;

export const useGetCanceledCargos = <TData = Awaited<ReturnType<typeof getCanceledCargos>>, TError = AxiosError<void>>(
  params?: GetCanceledCargosParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCanceledCargos>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCanceledCargosQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCanceledCargos>>> = ({ signal }) =>
    getCanceledCargos(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCanceledCargos>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getCompanyCargosStats = (options?: AxiosRequestConfig): Promise<AxiosResponse<CompanyCargoStats>> => {
  return axios.get(`/api/v1/cargos/stats`, options);
};

export const getGetCompanyCargosStatsQueryKey = () => [`/api/v1/cargos/stats`];

export type GetCompanyCargosStatsQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyCargosStats>>>;
export type GetCompanyCargosStatsQueryError = AxiosError<void>;

export const useGetCompanyCargosStats = <
  TData = Awaited<ReturnType<typeof getCompanyCargosStats>>,
  TError = AxiosError<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getCompanyCargosStats>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCompanyCargosStatsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyCargosStats>>> = ({ signal }) =>
    getCompanyCargosStats({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCompanyCargosStats>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const postCargoNeed = (
  createCargoNeedDTO: CreateCargoNeedDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  const formData = new FormData();
  if (createCargoNeedDTO.numberOfShipments !== undefined) {
    formData.append('numberOfShipments', createCargoNeedDTO.numberOfShipments.toString());
  }
  if (createCargoNeedDTO.contractPeriodFrom !== undefined) {
    formData.append('contractPeriodFrom', createCargoNeedDTO.contractPeriodFrom);
  }
  if (createCargoNeedDTO.contractPeriodTo !== undefined) {
    formData.append('contractPeriodTo', createCargoNeedDTO.contractPeriodTo);
  }
  if (createCargoNeedDTO.tenderingRules !== undefined) {
    formData.append('tenderingRules', createCargoNeedDTO.tenderingRules);
  }
  if (createCargoNeedDTO.attachment !== undefined) {
    formData.append('attachment', createCargoNeedDTO.attachment);
  }
  if (createCargoNeedDTO.companyNetworkId !== undefined) {
    formData.append('companyNetworkId', createCargoNeedDTO.companyNetworkId);
  }
  if (createCargoNeedDTO.price !== undefined) {
    formData.append('price', createCargoNeedDTO.price.toString());
  }
  formData.append('toleranceMinPercentage', createCargoNeedDTO.toleranceMinPercentage.toString());
  formData.append('toleranceMaxPercentage', createCargoNeedDTO.toleranceMaxPercentage.toString());
  formData.append('etaMin', createCargoNeedDTO.etaMin);
  formData.append('etaMax', createCargoNeedDTO.etaMax);
  formData.append('quantity', createCargoNeedDTO.quantity.toString());
  formData.append('status', createCargoNeedDTO.status);
  formData.append('transportation', createCargoNeedDTO.transportation);
  formData.append('dischargingPortId', createCargoNeedDTO.dischargingPortId);
  formData.append('companyProductId', createCargoNeedDTO.companyProductId);
  formData.append('plantId', createCargoNeedDTO.plantId);
  if (createCargoNeedDTO.hasFreight !== undefined) {
    formData.append('hasFreight', createCargoNeedDTO.hasFreight.toString());
  }
  if (createCargoNeedDTO.loadingPortId !== undefined) {
    formData.append('loadingPortId', createCargoNeedDTO.loadingPortId);
  }
  if (createCargoNeedDTO.loadingRate !== undefined) {
    formData.append('loadingRate', createCargoNeedDTO.loadingRate.toString());
  }
  if (createCargoNeedDTO.loadingRateTerm !== undefined) {
    formData.append('loadingRateTerm', createCargoNeedDTO.loadingRateTerm);
  }
  if (createCargoNeedDTO.freightLevel !== undefined) {
    formData.append('freightLevel', createCargoNeedDTO.freightLevel.toString());
  }

  return axios.post(`/api/v1/cargos/needs`, formData, options);
};

export type PostCargoNeedMutationResult = NonNullable<Awaited<ReturnType<typeof postCargoNeed>>>;
export type PostCargoNeedMutationBody = CreateCargoNeedDTO;
export type PostCargoNeedMutationError = AxiosError<ApiErrorResponse>;

export const usePostCargoNeed = <TError = AxiosError<ApiErrorResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCargoNeed>>,
    TError,
    { data: CreateCargoNeedDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCargoNeed>>, { data: CreateCargoNeedDTO }> = (
    props
  ) => {
    const { data } = props ?? {};

    return postCargoNeed(data, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof postCargoNeed>>, TError, { data: CreateCargoNeedDTO }, TContext>(
    mutationFn,
    mutationOptions
  );
};
export const postCargoOffer = (
  createCargoOfferDTO: CreateCargoOfferDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  const formData = new FormData();
  if (createCargoOfferDTO.numberOfShipments !== undefined) {
    formData.append('numberOfShipments', createCargoOfferDTO.numberOfShipments.toString());
  }
  if (createCargoOfferDTO.contractPeriodFrom !== undefined) {
    formData.append('contractPeriodFrom', createCargoOfferDTO.contractPeriodFrom);
  }
  if (createCargoOfferDTO.contractPeriodTo !== undefined) {
    formData.append('contractPeriodTo', createCargoOfferDTO.contractPeriodTo);
  }
  if (createCargoOfferDTO.tenderingRules !== undefined) {
    formData.append('tenderingRules', createCargoOfferDTO.tenderingRules);
  }
  if (createCargoOfferDTO.attachment !== undefined) {
    formData.append('attachment', createCargoOfferDTO.attachment);
  }
  if (createCargoOfferDTO.companyNetworkId !== undefined) {
    formData.append('companyNetworkId', createCargoOfferDTO.companyNetworkId);
  }
  if (createCargoOfferDTO.price !== undefined) {
    formData.append('price', createCargoOfferDTO.price.toString());
  }
  formData.append('toleranceMinPercentage', createCargoOfferDTO.toleranceMinPercentage.toString());
  formData.append('toleranceMaxPercentage', createCargoOfferDTO.toleranceMaxPercentage.toString());
  formData.append('laycanMin', createCargoOfferDTO.laycanMin);
  formData.append('laycanMax', createCargoOfferDTO.laycanMax);
  formData.append('quantity', createCargoOfferDTO.quantity.toString());
  formData.append('status', createCargoOfferDTO.status);
  formData.append('transportation', createCargoOfferDTO.transportation);
  formData.append('loadingPortId', createCargoOfferDTO.loadingPortId);
  formData.append('companyProductId', createCargoOfferDTO.companyProductId);
  formData.append('plantId', createCargoOfferDTO.plantId);
  formData.append('currency', createCargoOfferDTO.currency);
  formData.append('paymentTerms', createCargoOfferDTO.paymentTerms);
  if (createCargoOfferDTO.hasFreight !== undefined) {
    formData.append('hasFreight', createCargoOfferDTO.hasFreight.toString());
  }
  if (createCargoOfferDTO.dischargingPortId !== undefined) {
    formData.append('dischargingPortId', createCargoOfferDTO.dischargingPortId);
  }
  if (createCargoOfferDTO.dischargingRate !== undefined) {
    formData.append('dischargingRate', createCargoOfferDTO.dischargingRate.toString());
  }
  if (createCargoOfferDTO.dischargingRateTerms !== undefined) {
    formData.append('dischargingRateTerms', createCargoOfferDTO.dischargingRateTerms);
  }
  if (createCargoOfferDTO.freightLevel !== undefined) {
    formData.append('freightLevel', createCargoOfferDTO.freightLevel.toString());
  }

  return axios.post(`/api/v1/cargos/offers`, formData, options);
};

export type PostCargoOfferMutationResult = NonNullable<Awaited<ReturnType<typeof postCargoOffer>>>;
export type PostCargoOfferMutationBody = CreateCargoOfferDTO;
export type PostCargoOfferMutationError = AxiosError<ApiErrorResponse>;

export const usePostCargoOffer = <TError = AxiosError<ApiErrorResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCargoOffer>>,
    TError,
    { data: CreateCargoOfferDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCargoOffer>>, { data: CreateCargoOfferDTO }> = (
    props
  ) => {
    const { data } = props ?? {};

    return postCargoOffer(data, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof postCargoOffer>>, TError, { data: CreateCargoOfferDTO }, TContext>(
    mutationFn,
    mutationOptions
  );
};
export const getCargoNeed = (needId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<CargoNeedDTO>> => {
  return axios.get(`/api/v1/cargos/needs/${needId}`, options);
};

export const getGetCargoNeedQueryKey = (needId: string) => [`/api/v1/cargos/needs/${needId}`];

export type GetCargoNeedQueryResult = NonNullable<Awaited<ReturnType<typeof getCargoNeed>>>;
export type GetCargoNeedQueryError = AxiosError<void>;

export const useGetCargoNeed = <TData = Awaited<ReturnType<typeof getCargoNeed>>, TError = AxiosError<void>>(
  needId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCargoNeed>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCargoNeedQueryKey(needId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCargoNeed>>> = ({ signal }) =>
    getCargoNeed(needId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCargoNeed>>, TError, TData>(queryKey, queryFn, {
    enabled: !!needId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const postCargoOfferWithRequest = (
  needId: string,
  createCargoOfferWithRequestDTO: CreateCargoOfferWithRequestDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<string>> => {
  return axios.post(`/api/v1/cargos/needs/${needId}/request`, createCargoOfferWithRequestDTO, options);
};

export type PostCargoOfferWithRequestMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCargoOfferWithRequest>>
>;
export type PostCargoOfferWithRequestMutationBody = CreateCargoOfferWithRequestDTO;
export type PostCargoOfferWithRequestMutationError = AxiosError<ApiErrorResponse | void>;

export const usePostCargoOfferWithRequest = <
  TError = AxiosError<ApiErrorResponse | void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCargoOfferWithRequest>>,
    TError,
    { needId: string; data: CreateCargoOfferWithRequestDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCargoOfferWithRequest>>,
    { needId: string; data: CreateCargoOfferWithRequestDTO }
  > = (props) => {
    const { needId, data } = props ?? {};

    return postCargoOfferWithRequest(needId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof postCargoOfferWithRequest>>,
    TError,
    { needId: string; data: CreateCargoOfferWithRequestDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const getCargoNeedPromotion = (
  needId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CargoPromotionDTO>> => {
  return axios.post(`/api/v1/cargos/needs/${needId}/promotion`, undefined, options);
};

export type GetCargoNeedPromotionMutationResult = NonNullable<Awaited<ReturnType<typeof getCargoNeedPromotion>>>;

export type GetCargoNeedPromotionMutationError = AxiosError<void>;

export const useGetCargoNeedPromotion = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getCargoNeedPromotion>>,
    TError,
    { needId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof getCargoNeedPromotion>>, { needId: string }> = (
    props
  ) => {
    const { needId } = props ?? {};

    return getCargoNeedPromotion(needId, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof getCargoNeedPromotion>>, TError, { needId: string }, TContext>(
    mutationFn,
    mutationOptions
  );
};
export const getCargoOffer = (offerId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<CargoOfferDTO>> => {
  return axios.get(`/api/v1/cargos/offers/${offerId}`, options);
};

export const getGetCargoOfferQueryKey = (offerId: string) => [`/api/v1/cargos/offers/${offerId}`];

export type GetCargoOfferQueryResult = NonNullable<Awaited<ReturnType<typeof getCargoOffer>>>;
export type GetCargoOfferQueryError = AxiosError<void>;

export const useGetCargoOffer = <TData = Awaited<ReturnType<typeof getCargoOffer>>, TError = AxiosError<void>>(
  offerId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCargoOffer>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCargoOfferQueryKey(offerId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCargoOffer>>> = ({ signal }) =>
    getCargoOffer(offerId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCargoOffer>>, TError, TData>(queryKey, queryFn, {
    enabled: !!offerId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const postCargoNeedWithRequest = (
  offerId: string,
  createCargoNeedWithRequestDTO: CreateCargoNeedWithRequestDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<string>> => {
  return axios.post(`/api/v1/cargos/offer/${offerId}/request`, createCargoNeedWithRequestDTO, options);
};

export type PostCargoNeedWithRequestMutationResult = NonNullable<Awaited<ReturnType<typeof postCargoNeedWithRequest>>>;
export type PostCargoNeedWithRequestMutationBody = CreateCargoNeedWithRequestDTO;
export type PostCargoNeedWithRequestMutationError = AxiosError<ApiErrorResponse | void>;

export const usePostCargoNeedWithRequest = <
  TError = AxiosError<ApiErrorResponse | void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCargoNeedWithRequest>>,
    TError,
    { offerId: string; data: CreateCargoNeedWithRequestDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCargoNeedWithRequest>>,
    { offerId: string; data: CreateCargoNeedWithRequestDTO }
  > = (props) => {
    const { offerId, data } = props ?? {};

    return postCargoNeedWithRequest(offerId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof postCargoNeedWithRequest>>,
    TError,
    { offerId: string; data: CreateCargoNeedWithRequestDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const getCargoOfferPromotion = (
  offerId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CargoPromotionDTO>> => {
  return axios.post(`/api/v1/cargos/offers/${offerId}/promotion`, undefined, options);
};

export type GetCargoOfferPromotionMutationResult = NonNullable<Awaited<ReturnType<typeof getCargoOfferPromotion>>>;

export type GetCargoOfferPromotionMutationError = AxiosError<void>;

export const useGetCargoOfferPromotion = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getCargoOfferPromotion>>,
    TError,
    { offerId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof getCargoOfferPromotion>>, { offerId: string }> = (
    props
  ) => {
    const { offerId } = props ?? {};

    return getCargoOfferPromotion(offerId, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof getCargoOfferPromotion>>, TError, { offerId: string }, TContext>(
    mutationFn,
    mutationOptions
  );
};
export const cancelCargoOffer = (
  offerId: string,
  cancelCargoOfferDTO: CancelCargoOfferDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/v1/cargos/offers/${offerId}/cancel`, cancelCargoOfferDTO, options);
};

export type CancelCargoOfferMutationResult = NonNullable<Awaited<ReturnType<typeof cancelCargoOffer>>>;
export type CancelCargoOfferMutationBody = CancelCargoOfferDTO;
export type CancelCargoOfferMutationError = AxiosError<unknown>;

export const useCancelCargoOffer = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelCargoOffer>>,
    TError,
    { offerId: string; data: CancelCargoOfferDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cancelCargoOffer>>,
    { offerId: string; data: CancelCargoOfferDTO }
  > = (props) => {
    const { offerId, data } = props ?? {};

    return cancelCargoOffer(offerId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof cancelCargoOffer>>,
    TError,
    { offerId: string; data: CancelCargoOfferDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const cancelCargoNeed = (
  needId: string,
  cancelCargoOfferDTO: CancelCargoOfferDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/v1/cargos/needs/${needId}/cancel`, cancelCargoOfferDTO, options);
};

export type CancelCargoNeedMutationResult = NonNullable<Awaited<ReturnType<typeof cancelCargoNeed>>>;
export type CancelCargoNeedMutationBody = CancelCargoOfferDTO;
export type CancelCargoNeedMutationError = AxiosError<unknown>;

export const useCancelCargoNeed = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelCargoNeed>>,
    TError,
    { needId: string; data: CancelCargoOfferDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cancelCargoNeed>>,
    { needId: string; data: CancelCargoOfferDTO }
  > = (props) => {
    const { needId, data } = props ?? {};

    return cancelCargoNeed(needId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof cancelCargoNeed>>,
    TError,
    { needId: string; data: CancelCargoOfferDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const getCargoOfferSearches = (
  cargoNeedId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CargoOfferSearchesDTO>> => {
  return axios.get(`/api/v1/cargos/search/offers/${cargoNeedId}`, options);
};

export const getGetCargoOfferSearchesQueryKey = (cargoNeedId: string) => [
  `/api/v1/cargos/search/offers/${cargoNeedId}`,
];

export type GetCargoOfferSearchesQueryResult = NonNullable<Awaited<ReturnType<typeof getCargoOfferSearches>>>;
export type GetCargoOfferSearchesQueryError = AxiosError<void>;

export const useGetCargoOfferSearches = <
  TData = Awaited<ReturnType<typeof getCargoOfferSearches>>,
  TError = AxiosError<void>
>(
  cargoNeedId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCargoOfferSearches>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCargoOfferSearchesQueryKey(cargoNeedId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCargoOfferSearches>>> = ({ signal }) =>
    getCargoOfferSearches(cargoNeedId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCargoOfferSearches>>, TError, TData>(queryKey, queryFn, {
    enabled: !!cargoNeedId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getCargoNeedSearches = (
  cargoOfferId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CargoNeedSearchesDTO>> => {
  return axios.get(`/api/v1/cargos/search/needs/${cargoOfferId}`, options);
};

export const getGetCargoNeedSearchesQueryKey = (cargoOfferId: string) => [
  `/api/v1/cargos/search/needs/${cargoOfferId}`,
];

export type GetCargoNeedSearchesQueryResult = NonNullable<Awaited<ReturnType<typeof getCargoNeedSearches>>>;
export type GetCargoNeedSearchesQueryError = AxiosError<void>;

export const useGetCargoNeedSearches = <
  TData = Awaited<ReturnType<typeof getCargoNeedSearches>>,
  TError = AxiosError<void>
>(
  cargoOfferId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCargoNeedSearches>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCargoNeedSearchesQueryKey(cargoOfferId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCargoNeedSearches>>> = ({ signal }) =>
    getCargoNeedSearches(cargoOfferId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCargoNeedSearches>>, TError, TData>(queryKey, queryFn, {
    enabled: !!cargoOfferId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

import { AnalysisTypesType, CostsTermsType, CostsTermsWithoutSharedType } from '@/blink-forms';

export const CONTRACT_AND_CLAUSES_VALUE_LENGTH = 300;

export const DEFAULT_AGENT_CONDITIONS_VALUE: CostsTermsType = 'AT_SELLERS_COST';
export const DEFAULT_ANALYSIS_TYPE_VALUE: AnalysisTypesType = 'MANUFACTURING_PLANT_LABORATORY';
export const DEFAULT_SURVEYOR_OR_AT_LOADING_PORT_COST_VALUE: CostsTermsType = 'AT_SELLERS_COST';
export const DEFAULT_SURVEYOR_OR_AT_DISCHARGE_PORT_COST_VALUE: CostsTermsType = 'AT_SELLERS_COST';
export const DEFAULT_DISCHARGING_CONDITIONS_CARGO_PDA_VALUE: CostsTermsType = 'AT_SELLERS_COST';
export const DEFAULT_DISCHARGING_CONDITIONS_VESSEL_PDA_VALUE: CostsTermsWithoutSharedType = 'AT_SELLERS_COST';

import { z } from 'zod';

import { baseRecapFormSchema, cargoSchema, productSchema } from './recap-form';

const CompanyStatus = {
  verified: 'verified',
  unverified: 'unverified',
  shortlisted: 'shortlisted',
} as const;

const isEmpty = (value: string | undefined | null) => {
  return !value || value.trim().length === 0;
};

const freightConditions = z.object({
  safePortNo: z.number().min(1).optional(),
  safeBerthNo: z.number().min(1).optional(),
  freightRate: z.number().min(1).optional(),
  freightTerms: z.string().min(1).optional(),
  freightPeriodTerms: z.string().min(1).optional(),
  norTendering: z.string().min(1).optional(),
  turnTime: z.number().min(1).optional(),
  turnTimeTerms: z.string().min(1).optional(),
  cargoPda: z.string().min(1).optional(),
  vesselPda: z.string().min(1).optional(),
  berthConditions: z.string().min(1).optional(),
  exclusionFromLayTime: z.array(z.string()).optional(),
  exclusionFromLayTimeOtherExclusion: z.string().min(1).optional(),
  laytimeCalculations: z.array(z.string()).optional(),
  other: z.string().optional(),
});

export const externalCargoRecapFormSchema = baseRecapFormSchema.extend({
  counterparty: z
    .object({
      id: z.string().min(1),
      companyName: z.string().optional(),
      name: z.string().optional(),
      country: z.string().optional(),
      status: z.string().optional(),
      contactPerson: z.string().optional(),
      email: z.string().optional(),
    })
    .refine(
      (counterparty) => {
        return counterparty?.id?.length > 0;
      },
      {
        message: 'Please select a counterparty',
      }
    )
    .superRefine((obj, ctx) => {
      if (obj.status === CompanyStatus.shortlisted) {
        if (isEmpty(obj.contactPerson)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Select contact person',
            fatal: true,
            path: ['contactPerson'],
          });
        }
        if (obj.contactPerson === 'Other') {
          if (isEmpty(obj.name)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Enter counterparty name',
              fatal: true,
              path: ['name'],
            });
          }
          if (isEmpty(obj.email)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Enter counterparty email',
              fatal: true,
              path: ['email'],
            });
          }
        }
      }
      if (obj.id === 'Other') {
        if (isEmpty(obj.name)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Enter counterparty name',
            fatal: true,
            path: ['name'],
          });
        }
        if (isEmpty(obj.email)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Enter counterparty email',
            fatal: true,
            path: ['email'],
          });
        }
        if (isEmpty(obj.companyName)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Enter company name',
            fatal: true,
            path: ['companyName'],
          });
        }
      }
    }),
  product: productSchema.extend({
    cargo: cargoSchema.extend({
      originPlantId: z.string().min(1).optional(),
      originPlantName: z.string().min(1).optional(),
      originPlantCountry: z.string().min(1).optional(),
      originId: z.string().min(1).optional(),
      originName: z.string().min(1).optional(),
      originCountry: z.string().min(1).optional(),
      productId: z.string().min(1).optional(),
      productName: z.string().min(1),
      loadingRate: z.number().min(1),
      loadingTerms: z.string().min(1),
      dischargingRate: z.number().min(1),
      dischargingTerms: z.string().min(1),
      laycan: z.tuple([z.string().datetime(), z.string().datetime()]),
      destinationPlantId: z.string().min(1).optional(),
    }),
    freightConditions: z.object({
      loading: freightConditions,
      discharging: freightConditions,
    }),
    agentsInDischargingPort: z.object({
      mainAgendConditions: z.string().min(1).optional(),
      protectiveAgendConditions: z.string().min(1).optional(),
      mainAgentName: z.string().optional().optional(),
      protectiveAgentName: z.string().optional().optional(),
    }),
  }),
  sendCustomMessage: z.boolean().optional(),
  customMessage: z.string().optional(),
});

export type ExternalCargoRecapForm = z.infer<typeof externalCargoRecapFormSchema>;

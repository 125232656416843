import { createStyles, Popover, PopoverProps } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  dropdown: {
    [theme.fn.smallerThan('xs')]: {
      maxWidth: '100%',
      minWidth: '100%',
    },
    overflow: 'auto',
    maxHeight: 'calc(100vh - 80px)',
  },
}));

export function ResponsivePopover({ classNames, ...restProps }: PopoverProps) {
  const { classes } = useStyles();

  const dropdownClasses = `${classNames?.dropdown ?? ''} ${classes.dropdown}`;

  return <Popover classNames={{ ...classes, ...classNames, dropdown: dropdownClasses }} {...restProps} />;
}

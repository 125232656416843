import { useTranslation } from 'next-i18next';
import { Box, Group, Tooltip, useMantineTheme } from '@mantine/core';
import { useSession } from 'next-auth/react';
import { RiInformationLine } from 'react-icons/ri';

import { Text } from '../typography/Text';
import { TransactionSide } from '../../api/generated/model';

export const CompanyInfo = ({ transactionSide }: { transactionSide: TransactionSide }) => {
  const { t } = useTranslation('cargo');
  const theme = useMantineTheme();
  const companyName = useSession().data.me.activeCompany.companyName;

  const text = {
    [TransactionSide.BUYER]: t('create.purchaseCompanyInfo'),
    [TransactionSide.SELLER]: t('create.salesCompanyInfo'),
  }[transactionSide];

  return (
    <Group spacing={8}>
      <Text size={'L'}>{`${text} ${companyName}`}</Text>
      <Tooltip label={t('create.companyInfoTooltip')} multiline width={254} withArrow>
        <Box sx={{ transform: 'translateY(4px)' }}>
          <RiInformationLine color={theme.fn.primaryColor()} />
        </Box>
      </Tooltip>
    </Group>
  );
};

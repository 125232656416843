import { forwardRef } from 'react';
import { Button, createStyles, Grid } from '@mantine/core';
import NextLink from 'next/link';
import { RiExternalLinkLine } from 'react-icons/ri';
import { CompanyStatus } from 'api/generated/model';

import { Text, StatusBlinkBadge } from '@/components';
import { routes } from '@/common/routes';
import { useIsMobile } from '@/common/use-is-mobile';

import { CompanyItem } from './types';

const useStyles = createStyles({
  button: {
    '[data-selected="true"] &': {
      color: '#fff',
    },
  },
});

const VIEW_PROFILE_ALLOWED_STATUSES: Array<string> = ['ONBOARDING', 'KYC_VERIFIED', 'PASSIVE'];

type CounterpartySelectorItemProps = CompanyItem & React.ComponentPropsWithoutRef<'div'>;

export type { CompanyItem };

export const CompanySelectorItem = forwardRef<HTMLDivElement, CounterpartySelectorItemProps>(
  ({ name, id, status, onMouseDown, ...others }: CounterpartySelectorItemProps, ref) => {
    const { classes } = useStyles();

    const isMobile = useIsMobile('sm');

    return (
      <div
        ref={ref}
        onMouseDown={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          // prevent click on link from triggering onMouseDown
          if ((event.target as HTMLDivElement).closest('a') === null) {
            onMouseDown(event);
          }
        }}
        {...others}
      >
        <Grid columns={18} align="center">
          <Grid.Col span={9} sm={7}>
            <Text weight={600}>{name}</Text>
          </Grid.Col>
          <Grid.Col span={9} sm={5}>
            {status && <StatusBlinkBadge status={status} />}
          </Grid.Col>
          {!isMobile && (
            <Grid.Col span="content" ml="auto" sm={0}>
              {VIEW_PROFILE_ALLOWED_STATUSES.includes(status) && (
                <Button
                  size="sm"
                  className={classes.button}
                  variant="subtle"
                  component={NextLink}
                  href={routes.CompanyProfile(id)}
                  rightIcon={<RiExternalLinkLine />}
                >
                  view profile
                </Button>
              )}
            </Grid.Col>
          )}
        </Grid>
      </div>
    );
  }
);

CompanySelectorItem.displayName = 'CounterpartySelectorItem';

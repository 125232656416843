import { z } from 'zod';

import { baseTaskSchema } from './control-tower-form';

export const addTaskSchema = baseTaskSchema.extend({
  documentShared: z.boolean(),
  financiallySensitive: z.boolean(),
  sharedWith: z.array(z.string()).optional(),
});

export type AddTaskForm = z.infer<typeof addTaskSchema>;

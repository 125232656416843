import { useTranslation } from 'next-i18next';
import { Grid, NumberInput, Select } from '@mantine/core';

import { GetInputProps } from '@/common/types';
import { getFixedPositiveNumberProps } from '@/common/get-fixed-number-props';
import { DemurrageEvents, DemurrageRateConditions } from '@/blink-forms/types';
import { useGetSelectDataWithTranslations } from '@/views/tenders/utils/use-get-select-data-with-translations';

interface DemurrageRateFieldGroupProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getInputProps: GetInputProps<any>;
  data?: any;
  rateConditionsPath: string;
  ratePath: string;
  eventPath: string;
}

export function DemurrageRateFieldGroup({
  getInputProps,
  ratePath,
  rateConditionsPath,
  eventPath,
}: DemurrageRateFieldGroupProps) {
  const { t } = useTranslation('tenderNegotiation');

  const DemurrageRateConditionsData = useGetSelectDataWithTranslations(DemurrageRateConditions);
  const DemurrageEventsData = useGetSelectDataWithTranslations(DemurrageEvents);

  return (
    <Grid align="end">
      <Grid.Col span={12} md={6}>
        <NumberInput
          min={0}
          {...getFixedPositiveNumberProps(1)}
          label={t('labels.rateDay')}
          {...getInputProps(ratePath)}
          withAsterisk={false}
        />
      </Grid.Col>
      <Grid.Col span={12} md={2}>
        <Select
          label={<>&nbsp;</>}
          withAsterisk={false}
          defaultValue="MAX"
          data={DemurrageRateConditionsData}
          {...getInputProps(rateConditionsPath)}
        />
      </Grid.Col>
      <Grid.Col span={12} md={4}>
        <Select label={<>&nbsp;</>} withAsterisk={false} data={DemurrageEventsData} {...getInputProps(eventPath)} />
      </Grid.Col>
    </Grid>
  );
}

import { ActionIcon, createStyles, Group, Indicator, List, Popover, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'next-i18next';
import { RiCloseLine, RiNotification2Line } from 'react-icons/ri';
import sendbirdSelectors from '@sendbird/uikit-react/sendbirdSelectors';
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import GroupChannelHandler from '@sendbird/uikit-react/handlers/GroupChannelHandler';
import UserEventHandler from '@sendbird/uikit-react/handlers/UserEventHandler';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useQueryClient } from '@tanstack/react-query';
import {
  useGetNewNotificationsCounter,
  useReadNotification,
  useResetNewNotificationsCounter,
} from 'api/generated/notifications/notifications';

import { ResponsivePopover, Title } from '@/components';
import { routes } from '@/common/routes';
import { NegotiationDetailsTab } from '@/views/my-cargos';

import { getGetChatGroupsUnreadMessagesQueryKey } from '../../../api/generated/chat/chat';
import { useOnNotificationClick } from '../use-on-notification-click';
import { ResolvedNotification, useGetNotifications } from '../state/use-get-notifications';
import { GrantNotificationPermissionsBanner } from '../componenets';
import { NotificationItem } from './NotificationItem';

const SYNC_INTERVAL = 1000 * 30; // 30 seconds

const usePopoverStyles = createStyles((theme) => ({
  dropdown: {
    padding: '14px 16px',
    boxShadow: `0px 12px 24px 0px ${theme.colors.gray[4]}`,
    border: `solid 1px ${theme.colors.gray[3]}`,
  },
}));

interface NotificationPopoverContentProps {
  onNotificationClick: (notification: ResolvedNotification) => void;
  onClose: VoidFunction;
}

function NotificationPopoverContent(props: NotificationPopoverContentProps) {
  const { onNotificationClick, onClose } = props;
  const notifications = useGetNotifications();

  return (
    <>
      <Group position="apart">
        <Title size="S" mb={6} weight={600}>
          Notifications
        </Title>
        <ActionIcon mt={-5} color="primary.1" variant="transparent" onClick={onClose}>
          <RiCloseLine />
        </ActionIcon>
      </Group>

      <GrantNotificationPermissionsBanner />

      <List listStyleType="none">
        {notifications.data?.map((notification) => (
          <List.Item key={notification.id}>
            <NotificationItem notification={notification} onNotificationClick={onNotificationClick} />
          </List.Item>
        ))}
      </List>
    </>
  );
}

export function NotificationIndicator() {
  const { theme, classes } = usePopoverStyles();
  const { t } = useTranslation('common');
  const [opened, { toggle: togglePopover }] = useDisclosure(false);
  const { mutate: resetCounter } = useResetNewNotificationsCounter();
  const { mutate: markAsRead } = useReadNotification({ mutation: { onSuccess: () => resetCounter() } });
  const newNotificationsCounter = useGetNewNotificationsCounter({
    query: {
      select: (response) => response.data,
      refetchInterval: SYNC_INTERVAL,
      staleTime: 0,
    },
  });
  const onNotificationClick = useOnNotificationClick();

  const sendbirdContext = useSendbirdStateContext();
  const sdkInstance = sendbirdSelectors.getSdk(sendbirdContext);

  const router = useRouter();
  const queryClient = useQueryClient();

  const sendNotification = async (message) => {
    const isCurrentChatOpen = () => {
      const { pathname, query } = router;
      const isPageVisible = document.visibilityState === 'visible';
      const urlMatchesChat =
        pathname === '/app/cargo' && query.activeTab === 'chat' && query.negotiationId === message.channelUrl;

      return isPageVisible && urlMatchesChat;
    };
    if (Notification.permission !== 'granted') {
      await Notification.requestPermission();
    }

    if (isCurrentChatOpen()) {
      return;
    }

    const notification = new Notification(message.sender?.nickname ?? 'Blink', {
      body: message.message,
    });

    notification.onclick = (e) => {
      e.preventDefault();
      window.focus();
      setTimeout(() => {
        if (!isCurrentChatOpen()) {
          router.push(routes.CargoNegotiationHistory(message.channelUrl, NegotiationDetailsTab.CHAT));
        }
      }, 200);
    };
  };

  useEffect(() => {
    const handlerID = `blink-${Date.now()}`;

    if (sdkInstance?.groupChannel?.addGroupChannelHandler) {
      const channelHandler = new GroupChannelHandler({
        onMessageReceived: (_, message) => sendNotification(message),
      });
      sdkInstance.groupChannel.addGroupChannelHandler(handlerID, channelHandler);
    }

    if (sdkInstance?.addUserEventHandler) {
      const userEventHandler = new UserEventHandler({
        onTotalUnreadMessageCountUpdated: () => {
          queryClient.invalidateQueries(getGetChatGroupsUnreadMessagesQueryKey());
        },
      });
      sdkInstance.addUserEventHandler(handlerID, userEventHandler);
    }
    return () => {
      if (sdkInstance?.groupChannel?.removeChannelHandler) {
        sdkInstance.groupChannel.removeChannelHandler(handlerID);
      }
      if (sdkInstance?.removeUserEventHandler) {
        sdkInstance.removeUserEventHandler(handlerID);
      }
    };
  }, [sdkInstance, queryClient, sendNotification]);

  return (
    <ResponsivePopover
      opened={opened}
      width={300}
      radius="md"
      position="bottom"
      offset={14}
      shadow="md"
      classNames={classes}
      onChange={() => resetCounter()}
      onClose={togglePopover}
      closeOnClickOutside
      data-testid="notificationButton"
    >
      <Popover.Target>
        <Indicator
          inline
          disabled={!newNotificationsCounter.data}
          label={newNotificationsCounter.data}
          color={theme.colors.red[9]}
          size={20}
          radius="xl"
          onClick={togglePopover}
          sx={{ lineHeight: '16px' }}
        >
          <Tooltip label={t('nav.notifications')}>
            <ActionIcon size={'md'} variant="transparent">
              <RiNotification2Line size={20} color="white" />
            </ActionIcon>
          </Tooltip>
        </Indicator>
      </Popover.Target>
      <Popover.Dropdown>
        <NotificationPopoverContent
          onClose={togglePopover}
          onNotificationClick={(notification) => {
            if (!notification.readAt) {
              markAsRead({ id: notification.id });
            }
            togglePopover();
            onNotificationClick(notification);
          }}
        />
      </Popover.Dropdown>
    </ResponsivePopover>
  );
}

import { createContext, JSXElementConstructor, MutableRefObject, ReactElement, ReactNode, useContext } from 'react';

interface ReplacableContextState {
  elements: Record<string, MutableRefObject<HTMLDivElement>>;
  setElement: (elementKey: string, ref: MutableRefObject<HTMLDivElement>) => void;
  isElementReplaced: (elementKey: string) => boolean;
  getDebugInfo: (elementKey: string) => {
    replacement?: Exclude<ReactNode, ReactElement> | JSXElementConstructor<unknown>;
  };
}

export const ReplacableContext = createContext<ReplacableContextState>({
  elements: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function -- placeholder
  setElement: () => {},
  isElementReplaced: () => false,
  getDebugInfo: () => ({}),
});

export const useReplacableContext = () => {
  return useContext(ReplacableContext);
};

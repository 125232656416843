import { RecapForm } from '@/blink-forms';
import { transformToPercentage, fractionToPercentage } from '@/common/percentage-transform';
import { formatterThousand } from '@/common/get-thousand-seperated-number';
import { formatCurrency } from '@/common/format-currency';
import { capitalizeFirstLetter } from '@/common/utils/capitalize-first-letter';

import { RecapFormMasterdataTranslations } from '../state/use-recap-form-masterdata';

const selector =
  <T>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectorFn: (form: RecapForm, masterdataTranslations?: RecapFormMasterdataTranslations, additionalData?: any) => T
  ) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (form: RecapForm | undefined, masterdataTranslations?: RecapFormMasterdataTranslations, additionalData?: any) =>
    form ? selectorFn(form, masterdataTranslations, additionalData) : undefined;

export const toPriceArgs = selector(({ price }) => ({
  totalPrice: `${formatCurrency(price.price, price.currency, 'en-US', 2)} pmt`,
}));

export const toProductArgs = selector(({ product: { cargo } }) => ({
  productName: cargo.productName,
  packaging: cargo.productPackaging,
  quality: cargo.productQuality,
}));

export const toProductSpecificationArgs = selector(({ product: { cargo } }) => ({
  productId: cargo.productId,
  plantId: cargo.originPlantId,
  gradeName: cargo.productQuality,
}));

export const toQuantityArgs = selector(({ product: { cargo } }, translations) => ({
  quantity: formatterThousand(cargo.quantity),
  maxTolerance: transformToPercentage(cargo.maxTolerance),
  minTolerance: transformToPercentage(cargo.minTolerance),
  toleranceOption: translations.toleranceOptions[cargo.toleranceOption],
}));

interface SurveyorValues {
  cost?: string;
  name?: string;
  conditions?: string;
}

function toSurveyorsArgs(values: SurveyorValues, translations: RecapFormMasterdataTranslations) {
  return {
    whoseCost: translations.surveyorCosts[values.cost]?.toLocaleLowerCase(),
    name: capitalizeFirstLetter(values.name),
    conditions: values.conditions,
  };
}

export const toLoadportWeightArgs = selector(
  (
    {
      product: {
        weight: { loadportSurveyors },
      },
    },
    translations
  ) => toSurveyorsArgs(loadportSurveyors[0], translations)
);

export const toDischargeportWeightArgs = selector(
  (
    {
      product: {
        weight: { dischargeSurveyors },
      },
    },
    translations
  ) => toSurveyorsArgs(dischargeSurveyors[0], translations)
);

export const toDischargeportWeightExtraArgs = selector(
  (
    {
      product: {
        weight: { dischargeSurveyors },
      },
    },
    translations
  ) => (dischargeSurveyors.length < 2 ? undefined : toSurveyorsArgs(dischargeSurveyors[1], translations))
);

export const toLoadportWeightExtraArgs = selector(
  (
    {
      product: {
        weight: { loadportSurveyors },
      },
    },
    translations
  ) => (loadportSurveyors.length < 2 ? undefined : toSurveyorsArgs(loadportSurveyors[1], translations))
);

export const toSamplingAnalysisArgs = selector(
  (
    {
      product: {
        samplingAndAnalysis: { surveyorNames },
      },
    },
    translations
  ) => toSurveyorsArgs(surveyorNames[0], translations)
);

export const toSamplingAnalysisExtraArgs = selector(
  (
    {
      product: {
        samplingAndAnalysis: { surveyorNames },
      },
    },
    translations
  ) => (surveyorNames.length < 2 ? undefined : { ...toSurveyorsArgs(surveyorNames[1], translations) })
);

export const toSurveyorAtDischagePortArgs = selector(
  (
    {
      product: {
        samplingAndAnalysis: { surveyorsAtDischargePort },
      },
    },
    translations
  ) => toSurveyorsArgs(surveyorsAtDischargePort[0], translations)
);

export const toSurveyorAtDischagePortExtraArgs = selector(
  (
    {
      product: {
        samplingAndAnalysis: { surveyorsAtDischargePort },
      },
    },
    translations
  ) => (surveyorsAtDischargePort.length < 2 ? undefined : toSurveyorsArgs(surveyorsAtDischargePort[1], translations))
);

export const toMainAnalysisArgs = selector(
  (
    {
      product: {
        samplingAndAnalysis: { analysis },
      },
    },
    translations
  ) => toSurveyorsArgs(analysis[0], translations)
);

export const toMainAnalysisExtraArgs = selector(
  (
    {
      product: {
        samplingAndAnalysis: { analysis },
      },
    },
    translations
  ) => (analysis.length < 2 ? undefined : toSurveyorsArgs(analysis[1], translations))
);

export const toArbitrationAnalysisArgs = selector(
  ({
    product: {
      samplingAndAnalysis: { arbitrationAnalysis },
    },
  }) => ({
    name: capitalizeFirstLetter(arbitrationAnalysis.name),
    conditions: arbitrationAnalysis.conditions,
  })
);

export const toTbnArgs = selector(
  (
    {
      product: {
        vesselInformation: { type, vesselsNominationDays, vesselsNominationEvent, maxAge },
      },
    },
    translations
  ) =>
    type === 'TBN'
      ? {
          days: vesselsNominationDays ? 'Vessel to be nominated ' + vesselsNominationDays + ' days ' : undefined,
          event: vesselsNominationEvent
            ? translations.vesselNominationEvents[vesselsNominationEvent]?.toLocaleLowerCase() + '. '
            : undefined,
          maxAge: maxAge ? 'Vessel to be maximum ' + maxAge + ' years old.' : undefined,
        }
      : undefined
);

export const toNominatedVesselArgs = selector(
  ({
    product: {
      vesselInformation: { type, imoNumber },
    },
  }) =>
    type === 'NOMINATED_VESSEL'
      ? {
          imoNumber,
        }
      : undefined
);

export const toVesselGearsAndCranesArgs = selector(
  (
    {
      product: {
        vesselInformation: { gears, grabs, cranesNumber, cranesCapacity, grabsNumber, grabCapacity },
      },
    },
    translations
  ) => ({
    gears: translations.gears ? 'Vessel to be equipped ' + translations.gears[gears] : undefined,
    grabs: translations.grabs ? ' and ' + translations.grabs[grabs] : undefined,
    cranesNumber: cranesNumber ? 'with ' + cranesNumber + ' crane(s) ' : undefined,
    cranesCapacity: cranesCapacity ? 'with capacity of ' + cranesCapacity + ' mt ' : undefined,
    grabsNumber: grabsNumber ? 'and  ' + grabsNumber + ' grab(s) ' : undefined,
    grabCapacity: grabCapacity ? 'with capacity of ' + grabCapacity + ' mt. ' : undefined,
  })
);

function formatTurnTimeTerms(turnTimeTerm: string | undefined) {
  return turnTimeTerm ? `, ${turnTimeTerm.toLowerCase()}` : undefined;
}

export const toFreighConditionsArgs = selector(
  (
    {
      product: {
        freightConditions: {
          safePortNo,
          safeBerthNo,
          berthConditions,
          freightRate,
          freightTerms,
          freightPeriodTerms,
          turnTime,
          turnTimeTerms,
          norTendering,
          exclusionFromLayTime,
          exclusionFromLayTimeOtherExclusion,
          laytimeCalculations,
          cargoPda,
          vesselPda,
        },
        agentsInDischargingPort: { mainAgendConditions, protectiveAgendConditions, mainAgentName, protectiveAgentName },
      },
    },
    translations,
    port: {
      name;
      freshWaterSaltWater;
      guaranteedBeam;
      guaranteedDraft;
      guaranteedLOA;
    }
  ) => ({
    safePortNo,
    safeBerthNo,
    berthConditions,
    freightRate,
    freightPeriodTerms: translations.freightPeriodTerms[freightPeriodTerms],
    freightTerms: translations.freightTerms[freightTerms],
    turnTime,
    turnTimeTerms: formatTurnTimeTerms(translations.turnTimes[turnTimeTerms]),
    norTendering,
    exclusion: exclusionFromLayTime?.map((value) => translations.exclusionFromLaytime[value]).join(', '),
    laytimeCalculations: laytimeCalculations?.length
      ? 'Laytime calculations based on: ' +
        laytimeCalculations?.map((value) => translations.laytimeCalculations[value]).join(', ') +
        '.'
      : undefined,
    other: exclusionFromLayTimeOtherExclusion,
    portName: port?.name,
    freshWaterSaltWater: translations.freshWaterSaltWater[port?.freshWaterSaltWater],
    portBeam: port?.guaranteedBeam,
    portDraft: port?.guaranteedDraft,
    portLoa: port?.guaranteedLOA,
    cargoPda: translations.surveyorCosts[cargoPda]?.toLocaleLowerCase(),
    vesselPda: translations.surveyorCosts[vesselPda]?.toLocaleLowerCase(),
    mainAgentConditions: translations.surveyorCosts[mainAgendConditions]?.toLocaleLowerCase(),
    protectiveAgentConditions: translations.surveyorCosts[protectiveAgendConditions]?.toLocaleLowerCase(),
    mainAgentName: capitalizeFirstLetter(mainAgentName),
    protectiveAgentName: capitalizeFirstLetter(protectiveAgentName),
  })
);

export const toPrepaymentArgs = selector(
  (
    {
      payment: {
        conditions: { prepayment },
      },
    },
    translations
  ) =>
    prepayment
      ? {
          percentage: fractionToPercentage(prepayment.percentage),
          days: prepayment.days,
          event: translations.prepaymentEvents[prepayment.event]?.toLocaleLowerCase(),
        }
      : undefined
);

export const toCashAgainstDocumentsArgs = selector(
  ({
    payment: {
      conditions: { cashAgainstDocuments },
    },
  }) =>
    cashAgainstDocuments
      ? {
          percentage: fractionToPercentage(cashAgainstDocuments.percentage),
          days: cashAgainstDocuments.days,
          numberOfCopies: cashAgainstDocuments.numberOfCopies,
        }
      : undefined
);

export const toIrrevocableLetterArgs = selector(
  (
    {
      payment: {
        conditions: { irrevocableLetter },
      },
    },
    translations
  ) =>
    irrevocableLetter
      ? {
          percentage: fractionToPercentage(irrevocableLetter.percentage),
          days: irrevocableLetter.days,
          confirmationCostAt: translations.irrevocableLetter[irrevocableLetter.event]?.toLocaleLowerCase(),
          bankName: irrevocableLetter.bankName,
          swiftCode: irrevocableLetter.swiftCode,
        }
      : undefined
);

export const toSamplingAnalysisMainCertificate = selector(
  (
    {
      product: {
        samplingAndAnalysis: { analysis },
      },
    },
    translations
  ) =>
    analysis
      ? {
          mainType: translations.analysisTypes[analysis[0].type],
          mainName: analysis[0].name,
          mainConditions: analysis[0].conditions ? `Conditions: ${analysis[0].conditions}.` : undefined,
        }
      : undefined
);

export const toArbitrationAnalysisCertificate = selector(
  ({
    product: {
      samplingAndAnalysis: { arbitrationAnalysis },
    },
  }) => {
    return arbitrationAnalysis
      ? {
          arbitrationName: arbitrationAnalysis.name,
          arbitrationConditions: arbitrationAnalysis.conditions
            ? `Conditions: ${arbitrationAnalysis.conditions}.`
            : undefined,
        }
      : undefined;
  }
);

export const toDocumentaryCollectionArgs = selector(
  (
    {
      payment: {
        conditions: { documentCollection },
      },
    },
    translations
  ) =>
    documentCollection
      ? {
          percentage: fractionToPercentage(documentCollection.percentage),
          days: documentCollection.days,
          event: translations.documentaryCollectionEvents[documentCollection.event]?.toLocaleLowerCase(),
        }
      : undefined
);

export const toOpenAccountArgs = selector(
  (
    {
      payment: {
        conditions: { openAccount },
      },
    },
    translations
  ) =>
    openAccount
      ? {
          percentage: fractionToPercentage(openAccount.percentage),
          days: openAccount.days,
          event: translations.openAccountEvents[openAccount.event]?.toLocaleLowerCase(),
        }
      : undefined
);

export const toOtherPaymentArgs = selector(
  ({
    payment: {
      conditions: { other },
    },
  }) =>
    other
      ? {
          percentage: fractionToPercentage(other.percentage),
          text: other.methodOfPayment,
        }
      : undefined
);

export const toConsumptionArgs = selector(
  (
    {
      payment: {
        conditions: { consumptionPayment },
      },
    },
    translations
  ) =>
    consumptionPayment
      ? {
          percentage: fractionToPercentage(consumptionPayment.percentage),
          days: consumptionPayment.days,
          installmentsNumber: consumptionPayment.numberOfInstallments,
          event: translations.consumptionEvents[consumptionPayment.event]?.toLocaleLowerCase(),
        }
      : undefined
);

export const toDemurrageArgs = selector(
  (
    {
      payment: {
        demurrage: { event, rateCondition, rate, type, openAccount },
      },
    },
    masterdata
  ) => ({
    event: masterdata.demurrageEvents[event]
      ? 'Demurrage ' + masterdata.demurrageEvents[event]?.toLocaleLowerCase()
      : undefined,
    rateCondition: masterdata.demurrageRateConditions[rateCondition]?.toLocaleLowerCase(),
    rate: rate ? rate + ' USD per day pro rata.' : undefined,
    type: masterdata.demurrageTypes[type],
    maxDays: openAccount?.maxDays ? `max ${openAccount.maxDays} days` : undefined,
    openAccountEvent: openAccount?.event
      ? masterdata.demurrageOpenAccountTypes[openAccount.event]?.toLocaleLowerCase()
      : undefined,
  })
);

export const toLawJurisdictionArgs = selector(({ lawJurisdiction: { law, jurisdiction } }, translations) => ({
  law: translations.law[law],
  jurisdiction: translations.jurisdiction[jurisdiction],
}));

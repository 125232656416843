/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoNeedRecipientType = (typeof CargoNeedRecipientType)[keyof typeof CargoNeedRecipientType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoNeedRecipientType = {
  ALL_RELEVANT_COMPANIES: 'ALL_RELEVANT_COMPANIES',
  DISTINCT_COMPANIES_OR_NETWORKS: 'DISTINCT_COMPANIES_OR_NETWORKS',
} as const;

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type GetArchivedCargosTransactionSide =
  (typeof GetArchivedCargosTransactionSide)[keyof typeof GetArchivedCargosTransactionSide];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetArchivedCargosTransactionSide = {
  BUYER: 'BUYER',
  SELLER: 'SELLER',
} as const;

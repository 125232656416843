/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type WorkflowTemplateDocumentType =
  (typeof WorkflowTemplateDocumentType)[keyof typeof WorkflowTemplateDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkflowTemplateDocumentType = {
  GLOBAL: 'GLOBAL',
  SELLER: 'SELLER',
  BUYER: 'BUYER',
} as const;

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type LoadWorkflowTemplateInputMergeStrategy =
  (typeof LoadWorkflowTemplateInputMergeStrategy)[keyof typeof LoadWorkflowTemplateInputMergeStrategy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LoadWorkflowTemplateInputMergeStrategy = {
  MERGE: 'MERGE',
  OVERWRITE: 'OVERWRITE',
} as const;

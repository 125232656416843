import { Avatar, Box, createStyles, Group, Paper, Stack } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { RiBuildingLine } from 'react-icons/ri';

import { formatDate } from '@/common/format-date';
import { Text, Title } from '@/components';

const useStyles = createStyles((theme) => ({
  container: {
    backgroundColor: theme.white,
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.background[0]}`,
  },
  header: {
    backgroundColor: theme.colors.background[0],
    borderRadius: '8px 8px 0 0',
    padding: 16,
  },
  dateBox: {
    padding: '2px 8px',
    backgroundColor: theme.white,
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[2]}`,
    width: 'fit-content',
  },
}));

export interface FreightRecapFormOtherSideInfoProps {
  dateReceived: string;
  companyName: string;
  side: 'charterer' | 'trader';
  hideLabel?: boolean;
}

export function FreightRecapFormOtherSideInfo(props: FreightRecapFormOtherSideInfoProps) {
  const { dateReceived, companyName, side, hideLabel = false } = props;
  const { t } = useTranslation('freightRecapForm');
  const { classes } = useStyles();

  return (
    <Paper className={classes.container}>
      <Box className={classes.header}>
        <Box className={classes.dateBox}>{formatDate(dateReceived)}</Box>
      </Box>

      <Box p={16}>
        <Group position="apart" spacing={8}>
          <Stack spacing={8}>
            {!hideLabel && (
              <Text size="S" weight={500} color="gray.8">
                {t(side === 'trader' ? 'labels.from' : 'labels.for')}
              </Text>
            )}

            <Group w={'100%'}>
              <Avatar size={72} radius="sm" src={null} alt={`The logo of ${companyName}`} color="blue">
                {side === 'charterer' && <RiBuildingLine />}
              </Avatar>

              <Title size="2XL" color="gray.9" weight={600}>
                {companyName}
              </Title>
            </Group>
          </Stack>
        </Group>
      </Box>
    </Paper>
  );
}

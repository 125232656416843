import { differenceInDays } from 'date-fns';
import { z } from 'zod';

export function isDatePeriodInDaysRange(
  dateFrom: number | Date,
  dateTo: number | Date,
  minDays: number,
  maxDays = Infinity
) {
  if (!dateFrom || !dateTo) {
    return false;
  }
  const absDifferenceInDays = Math.abs(differenceInDays(dateTo, dateFrom));
  return absDifferenceInDays <= maxDays && absDifferenceInDays >= minDays;
}

export const maxPercentageValidator =
  (max: number, displayMax = max * 100) =>
  (value: number, ctx: z.RefinementCtx) => {
    if (value > max) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_big,
        maximum: displayMax,
        type: 'number',
        inclusive: true,
      });
    }
  };

export const minPercentageValidator =
  (min: number, displayMin = min * 100) =>
  (value: number, ctx: z.RefinementCtx) => {
    if (value < min) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        type: 'number',
        minimum: displayMin,
        inclusive: true,
      });
    }
  };

import { Select, SelectProps } from '@mantine/core';

import { useGetCompanyPlantsSelectItems } from '../state/use-get-company-plants-select-items';

export interface PlantSelectProps extends Omit<SelectProps, 'data'> {
  companyId: string;
}

export function PlantSelect({ companyId, ...props }: PlantSelectProps) {
  const plantSelectItems = useGetCompanyPlantsSelectItems(companyId);

  return <Select data={plantSelectItems} disabled={plantSelectItems.length === 0} {...props} />;
}

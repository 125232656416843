import { ActionIcon, Box, Button, createStyles, Flex, Menu, Group, useMantineTheme } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { RiCloseLine, RiFileAddLine, RiFileMarkLine, RiMore2Fill } from 'react-icons/ri';
import { useState } from 'react';

import { useMediaQuery } from '@/core/hooks/use-media-query';
import { FreightRecapFormActionItem } from '@/views/freight-recap-form';

const useStyles = createStyles((theme) => ({
  container: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 101,
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    padding: '12px 24px',
    background: theme.white,
    display: 'flex',

    [theme.fn.largerThan('md')]: {
      left: 'var(--mantine-navbar-width, 56px)',
    },
  },
  hideOnMobile: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
      pointerEvents: 'none',
    },
  },
  hideOnDesktop: {
    [theme.fn.largerThan('md')]: {
      display: 'none',
      pointerEvents: 'none',
    },
  },
}));

export interface FreightRecapFormFooterProps {
  isSubmitting: boolean;
  isFormDirty: boolean;
  isDraftSaving: boolean;
  onSaveDraft?: VoidFunction;
  onSaveTemplate: VoidFunction;
  onPreview: VoidFunction;
}

export function FreightRecapFormFooter(props: FreightRecapFormFooterProps) {
  const { isSubmitting, onSaveDraft, onPreview } = props;
  const { t } = useTranslation('freightRecapForm');
  const { classes, theme } = useStyles();
  const isDraftable = !!onSaveDraft;
  const isMobile = useMediaQuery(theme.breakpoints.sm);

  return (
    <Box className={classes.container}>
      <Flex wrap="wrap" gap={16} w="100%" maw={1232} mx="auto">
        {isMobile && <MobileButtons isDraftable={isDraftable} {...props} />}

        {!isMobile && <DesktopButtons isDraftable={isDraftable} {...props} />}

        <Group sx={{ marginLeft: 'auto' }}>
          <Button variant="outline" onClick={onPreview}>
            {t('buttons.preview')}
          </Button>

          <Button type="submit" data-testid={'send'} loading={!!isSubmitting}>
            {t('buttons.send')}
          </Button>
        </Group>
      </Flex>
    </Box>
  );
}

interface ButtonsProps {
  isDraftable: boolean;
  isFormDirty: boolean;
  isDraftSaving: boolean;
  onSaveDraft?: VoidFunction;
  onSaveTemplate: VoidFunction;
}

function DesktopButtons(props: ButtonsProps) {
  const { isDraftable, isFormDirty, isDraftSaving, onSaveDraft, onSaveTemplate } = props;
  const { t } = useTranslation('freightRecapForm');

  return (
    <>
      {isDraftable && (
        <Button
          variant="subtle"
          leftIcon={<RiFileMarkLine />}
          disabled={!isFormDirty}
          loading={isDraftSaving}
          onClick={() => isFormDirty && onSaveDraft()}
        >
          {t('buttons.saveDraft')}
        </Button>
      )}
      <Button variant="subtle" leftIcon={<RiFileAddLine />} onClick={onSaveTemplate}>
        {t('buttons.saveAsTemplate')}
      </Button>
    </>
  );
}

function MobileButtons(props: ButtonsProps) {
  const { isDraftable, isFormDirty, onSaveDraft, onSaveTemplate } = props;
  const { t } = useTranslation('freightRecapForm');
  const theme = useMantineTheme();
  const [showCloseIcon, setShowCloseIcon] = useState(false);

  return (
    <Menu width="100vw" onChange={setShowCloseIcon}>
      <Menu.Target>
        <ActionIcon variant="outline" color="primary.1">
          {showCloseIcon ? <RiCloseLine size={20} /> : <RiMore2Fill size={20} />}
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        {isDraftable && isFormDirty && (
          <Menu.Item onClick={() => isFormDirty && onSaveDraft()}>
            <FreightRecapFormActionItem
              label={t('buttons.saveDraft')}
              description={t('labels.saveAsDraft')}
              icon={<RiFileMarkLine size={16} color={theme.colors.gray[6]} />}
            />
          </Menu.Item>
        )}

        <Menu.Item onClick={onSaveTemplate}>
          <FreightRecapFormActionItem
            label={t('buttons.saveAsTemplate')}
            description={t('labels.saveAsTemplate')}
            icon={<RiFileAddLine size={16} color={theme.colors.gray[6]} />}
          />
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

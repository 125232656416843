import { Badge, BadgeProps, Group, Image } from '@mantine/core';
import { RiCloseFill } from 'react-icons/ri';

import { SmallButton, Text } from '@/components';

interface BlinkBadgeProps {
  children: string;
  showLogo?: boolean;
  color: BadgeProps['color'];
  maw?: BadgeProps['maw'];
  styles?: BadgeProps['styles'];
  onRemove?: () => void;
}

export function BlinkBadge({ children, showLogo = true, color, maw, styles, onRemove }: BlinkBadgeProps) {
  return (
    <Badge color={color} px={8} py={5} radius={2} maw={maw} styles={styles}>
      <Group position="center" spacing={8} noWrap>
        {showLogo && <Image src="/images/blink-mini.svg" alt="" aria-hidden="true" width={14} height={14} />}
        <Text size="S" transform="uppercase" weight="600" lh={1}>
          {children}
        </Text>
        {onRemove && (
          <SmallButton w={20} variant="subtle" rightIcon={<RiCloseFill color="gray" />} onClick={onRemove} />
        )}
      </Group>
    </Badge>
  );
}

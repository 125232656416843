import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { NotificationType } from 'api/generated/model';
import { getGetCargoWorkflowSubscribedServicesQueryKey } from 'api/generated/services/services';

import { routes } from '@/common/routes';

import { ResolvedNotification } from './state/use-get-notifications';
import { getGetCargoWorkflowTasksQueryKey } from '../../api/generated/workflow-documents/workflow-documents';
import { NegotiationDetailsTab } from '../my-cargos';

function getNotificationRoute(notification: ResolvedNotification): string | undefined {
  const {
    detailedOfferId,
    negotiationId,
    cargoWorkflowId,
    cargoInvitationId,
    cargoNeedId,
    cargoOfferId,
    cargoId,
    cargoTenderId,
    cargoTenderNegotiationId,
    cargoTenderContractId,
    transactionSide,
  } = notification.data;

  switch (notification.type) {
    case NotificationType.NEGOTIATION_STATUS_CHANGED:
    case NotificationType.CARGO_OFFER_CANCELED:
    case NotificationType.CARGO_NEED_CANCELED:
      return routes.CargoNegotiationHistory(negotiationId);
    case NotificationType.WORKFLOW_SERVICE_SUBSCRPTION_STATUS_CHANGED:
      return routes.CargoWorkflowServices(cargoWorkflowId);
    case NotificationType.WORKFLOW_DOCUMENT_SHARED_CHANGED:
    case NotificationType.WORKFLOW_DOCUMENT_FILE_DELETED:
    case NotificationType.WORKFLOW_DOCUMENT_FILE_UPLOADED:
    case NotificationType.WORKFLOW_DUE_DOCUMENTS_REMINDER_SENT:
    case NotificationType.WORKFLOW_GLOBAL_EVENTS_UPDATED:
    case NotificationType.EXTERNAL_CARGO_INVITATION_ACCEPTED:
    case NotificationType.EXTERNAL_CARGO_INVITATION_REJECTED:
    case NotificationType.WORKFLOW_TASK_STATUS_CHANGED:
      return routes.CargoWorkflowOperations(cargoWorkflowId);
    case NotificationType.NEGOTIATION_EXPIRY_EXTEND_REQUESTED:
      return routes.CargoNegotiationHistory(negotiationId, NegotiationDetailsTab.HISTORY);
    case NotificationType.NEGOTIATION_EXPIRY_EXTENDED:
      return routes.CargoNegotiationHistory(negotiationId, NegotiationDetailsTab.HISTORY);
    case NotificationType.NEGOTIATION_EXPIRY_DUE:
      return routes.CargoNegotiationHistory(negotiationId);
    case NotificationType.FREIGHT_NEGOTIATION_STATUS_CHANGED:
    case NotificationType.FREIGHT_NEGOTIATION_EXPIRING_SOON:
    case NotificationType.FREIGHT_NEGOTIATION_EXPIRY_DATE_EXTENSION_REQUESTED:
    case NotificationType.FREIGHT_NEGOTIATION_EXPIRY_DATE_EXTENSION_REQUEST_ACCEPTED:
    case NotificationType.FREIGHT_NEGOTIATION_EXPIRY_DATE_EXTENSION_REQUEST_REJECTED:
      return routes.FreightDetails(negotiationId);
    case NotificationType.WOKRFLOW_TASK_COMPLETED:
    case NotificationType.WORKFLOW_TASK_SHARED:
    case NotificationType.WORKFLOW_DOCUMENT_SHARED:
    case NotificationType.OPPOSING_COMPANY_TEMPLATE_LOADED:
      return routes.CargoWorkflowOperations(cargoWorkflowId);
    case NotificationType.EXTERNAL_CARGO_CREATED:
      return routes.CargoWorkflowPending(cargoWorkflowId, cargoInvitationId, 'notification');
    case NotificationType.CARGO_NEED_CREATED:
    case NotificationType.CARGO_NEED_PROMOTED:
      return routes.PromotedCargoNeed(cargoNeedId ?? cargoId);
    case NotificationType.CARGO_OFFER_CREATED:
    case NotificationType.CARGO_OFFER_PROMOTED:
      return routes.PromotedCargoOffer(cargoOfferId ?? cargoId);
    case NotificationType.CARGO_DETAILED_OFFER_NEED_CREATED:
    case NotificationType.CARGO_DETAILED_OFFER_AVAILABILITY_CREATED:
      return routes.DetailedOffer(detailedOfferId);
    case NotificationType.CARGO_TENDER_POSTED_TO_NETWORK:
      return routes.CargoTenderDetails(cargoTenderId);
    case NotificationType.CARGO_TENDER_PARTICIPATION_REQUESTED:
      return routes.MyTendersWithQueryParams({
        tab: 'RESPONDED_TENDERS',
        tenderId: cargoTenderId,
        negotiationId: cargoTenderNegotiationId,
      });
    case NotificationType.CARGO_TENDER_NEGOTIATION_OFFER_INITIAL:
      return routes.MyTendersWithQueryParams({
        tab: 'MY_TENDERS',
        tenderId: cargoTenderId,
        negotiationId: cargoTenderNegotiationId,
      });
    case NotificationType.CARGO_TENDER_NEGOTIATION_COUNTEROFFER_CREATOR:
    case NotificationType.CARGO_TENDER_NEGOTIATION_OFFER_ACCEPTED_BY_CREATOR:
    case NotificationType.CARGO_TENDER_NEGOTIATION_OFFER_REJECTED_BY_CREATOR:
      return routes.MyTendersWithQueryParams({
        tab: 'RESPONDED_TENDERS',
        tenderId: cargoTenderId,
        negotiationId: cargoTenderNegotiationId,
      });
    case NotificationType.CARGO_TENDER_NEGOTIATION_COUNTEROFFER_PARTICIPANT:
    case NotificationType.CARGO_TENDER_NEGOTIATION_OFFER_ACCEPTED_BY_PARTICIPANT:
    case NotificationType.CARGO_TENDER_NEGOTIATION_OFFER_REJECTED_BY_PARTICIPANT:
      return routes.MyTendersWithQueryParams({
        tab: 'MY_TENDERS',
        tenderId: cargoTenderId,
        negotiationId: cargoTenderNegotiationId,
      });
    case NotificationType.CARGO_TENDER_NEGOTIATION_CONTRACTED:
      return routes.CargoTenderContractDetails(cargoTenderContractId, transactionSide);
    case NotificationType.DETAILED_OFFER_PARTICIPANT_ACCEPT:
      return routes.CargoNegotiationHistory(negotiationId);
    case NotificationType.SERVICE_PROVIDER_INVITED_TO_WORKFLOW:
      return routes.CargoWorkflowOperations(cargoWorkflowId);
    default:
      return undefined;
  }
}

function useOnNotificationClickEffect() {
  const queryClient = useQueryClient();

  return (notification: ResolvedNotification): void => {
    const { cargoWorkflowId } = notification.data;

    switch (notification.type) {
      case NotificationType.WORKFLOW_SERVICE_SUBSCRPTION_STATUS_CHANGED:
        queryClient.invalidateQueries(getGetCargoWorkflowSubscribedServicesQueryKey(cargoWorkflowId));
        break;
      case NotificationType.WORKFLOW_DOCUMENT_FILE_DELETED:
      case NotificationType.WORKFLOW_DOCUMENT_FILE_UPLOADED:
      case NotificationType.WORKFLOW_DOCUMENT_SHARED_CHANGED:
      case NotificationType.WORKFLOW_GLOBAL_EVENTS_UPDATED:
        queryClient.invalidateQueries(getGetCargoWorkflowTasksQueryKey(cargoWorkflowId));
        break;
      default:
        break;
    }
  };
}

export function useOnNotificationClick() {
  const router = useRouter();
  const onClickEffect = useOnNotificationClickEffect();

  return (notification: ResolvedNotification) => {
    const route = getNotificationRoute(notification);
    if (route) {
      router.push(route);
    }
    onClickEffect(notification);
  };
}

import { AxiosResponse } from 'axios';
import { useTranslation } from 'next-i18next';
import { NotificationDTO } from 'api/generated/model';
import { NotificationType } from 'api/generated/model/notificationType';
import { useGetNotifications as useApiGetNotifications } from 'api/generated/notifications/notifications';

export interface ResolvedNotification extends NotificationDTO {
  title: string;
  message: string;
}

function useResolveNotifications() {
  const { t } = useTranslation('common');

  return (response: AxiosResponse<NotificationDTO[]>): ResolvedNotification[] =>
    response.data.map((notification) => {
      let scope = `notifications.${notification.type}`;

      switch (notification.type) {
        case NotificationType.NEGOTIATION_STATUS_CHANGED:
          scope = `${scope}.${notification.data.newStatus}` as string;
          break;
        case NotificationType.WORKFLOW_DOCUMENT_SHARED_CHANGED:
          scope = notification.data.shared ? `${scope}.SHARED` : `${scope}.UNSHARED`;
          break;
        case NotificationType.FREIGHT_NEGOTIATION_STATUS_CHANGED:
          scope = `${scope}.${notification.data.status}` as string;
          break;
      }

      return {
        ...notification,
        title: t(`${scope}.title`, notification.data) as string,
        message: t(`${scope}.message`, notification.data) as string,
      };
    });
}

export function useGetNotifications() {
  const resolveNotifications = useResolveNotifications();

  return useApiGetNotifications({}, { query: { select: resolveNotifications, staleTime: 0 } });
}

import { z } from 'zod';

import { zDateRange } from '../types';

export const cargoTenderGeneralInformationSchema = z.object({
  contractPeriod: zDateRange,
  offerSubmissionDeadline: z.date(),
  offerValidity: z.date(),
});

export type CargoTenderGeneralInformationSchema = z.infer<typeof cargoTenderGeneralInformationSchema>;

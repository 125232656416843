import { z } from 'zod';

import { maxPercentageValidator } from './validators';
import { RecapForm } from './lib/recap-form';
import { ExternalCargoRecapForm } from './lib/external-cargo-recap-form';

export const MAX_TOLARANCE = 0.4;

export const zTolerancePercentage = z.number().min(0).superRefine(maxPercentageValidator(MAX_TOLARANCE));

// @TODO change it to zod tuple. Now it causes problems - it infers type as `[Date, Date, ...unknown[]]` instead of `[Date, Date]`
// it seems to be issue with old version of zod - latest version works fine
export const zDateRange = z.array(z.date()).length(2);

export type ContractData = RecapForm | ExternalCargoRecapForm;

export type AnalysisTypesType = (typeof AnalysisTypes)[keyof typeof AnalysisTypes];
export type CostsTermsType = (typeof CostsTerms)[keyof typeof CostsTerms];
export type CostsTermsWithoutSharedType = (typeof CostsTermsWithoutShared)[keyof typeof CostsTermsWithoutShared];
export type ClauseTypeType = (typeof ClauseType)[keyof typeof ClauseType];
export type TransactionTypeType = (typeof TransactionType)[keyof typeof TransactionType];

export type WithContractData<
  T,
  RecapFormType extends RecapForm | ExternalCargoRecapForm | undefined = undefined
> = Omit<T, 'contractData'> & { contractData?: RecapFormType extends undefined ? ContractData : RecapFormType };

export const CargoTransportation = {
  CFR: 'CFR',
  FOB: 'FOB',
  CIF: 'CIF',
} as const;

export const CargoTenderShipmentFrequency = {
  EVENLY_SPREAD: 'EVENLY_SPREAD',
  BY_DATES: 'BY_DATES',
} as const;

export const TransactionType = {
  BUY: 'BUY',
  SELL: 'SELL',
} as const;

export const Currency = {
  USD: 'USD',
  EUR: 'EUR',
} as const;

export const CostsTerms = {
  SHARED_COST: 'SHARED_COST',
  AT_BUYERS_COST: 'AT_BUYERS_COST',
  AT_SELLERS_COST: 'AT_SELLERS_COST',
} as const;

export const CostsTermsWithoutShared = {
  AT_BUYERS_COST: 'AT_BUYERS_COST',
  AT_SELLERS_COST: 'AT_SELLERS_COST',
} as const;

export const CostsTermsWithNA = {
  NA: 'NA',
  AT_BUYERS_COST: 'AT_BUYERS_COST',
  AT_SELLERS_COST: 'AT_SELLERS_COST',
} as const;

export const ClauseType = {
  INSURANCE_TITLE_AND_RISK: 'INSURANCE_TITLE_AND_RISK',
  TAXES_AND_DUTIES: 'TAXES_AND_DUTIES',
  NOTICES: 'NOTICES',
  FORCE_MAJEURE: 'FORCE_MAJEURE',
  EARLY_TERMINATION_AND_EFFECTS: 'EARLY_TERMINATION_AND_EFFECTS',
  CONFIDENTIALITY: 'CONFIDENTIALITY',
  ANTIBRIVERY_COMPLIANCE: 'ANTIBRIVERY_COMPLIANCE',
  AMENDMENT_WAIVER_ASSIGNMENT: 'AMENDMENT_WAIVER_ASSIGNMENT',
  WARRANTIES_AND_LIMITATION_OF_WARRANTIES: 'WARRANTIES_AND_LIMITATION_OF_WARRANTIES',
  MEDIATION_AND_ARBITRATION: 'MEDIATION_AND_ARBITRATION',
  LIABILITY: 'LIABILITY',
  IMPORT_EXPORT_LICENSES: 'IMPORT_EXPORT_LICENSES',
  FINAL_HOLD_CLEANING: 'FINAL_HOLD_CLEANING',
  OTHER: 'OTHER',
} as const;

export const AnalysisTypes = {
  MANUFACTURING_PLANT_LABORATORY: 'MANUFACTURING_PLANT_LABORATORY',
  INDEPENDENT_LABORATORY: 'INDEPENDENT_LABORATORY',
} as const;

export const DemurrageRateConditions = {
  MAX: 'MAX',
  MIN: 'MIN',
} as const;

export const DemurrageEvents = {
  TO_BE_ADVISED_WITH_VESSEL_NOMINATION: 'TO_BE_ADVISED_WITH_VESSEL_NOMINATION',
  FIXED_AT_RECAP: 'FIXED_AT_RECAP',
} as const;

export const OpenAccountEvents = {
  AFTER_BILL_OF_LADING_DATE: 'AFTER_BILL_OF_LADING_DATE',
  BEFORE_START_OF_DISCHARGING: 'BEFORE_START_OF_DISCHARGING',
  AFTER_DISCHARGING_IS_COMPLETED: 'AFTER_DISCHARGING_IS_COMPLETED',
} as const;

export const DemurrageType = {
  OPEN_ACCOUNT: 'OPEN_ACCOUNT',
  PROVISION_TO_SELLER: 'PROVISION_TO_SELLER',
  PROVISION_TO_TRUSTED_PARTY: 'PROVISION_TO_TRUSTED_PARTY',
} as const;

export const PrepaymentEvents = {
  BEFORE_VESSEL_ARRIVAL: 'BEFORE_VESSEL_ARRIVAL',
  AFTER_VESSEL_NOMINATION: 'AFTER_VESSEL_NOMINATION',
  BEFORE_START_OF_LOADING: 'BEFORE_START_OF_LOADING',
} as const;

export const IrrevocableLetterEvents = {
  NA: 'N/A',
  AT_SELLER_S_ACCOUNT: 'AT_SELLER_S_ACCOUNT',
  AT_BUYER_S_ACCOUNT: 'AT_BUYER_S_ACCOUNT',
  SHARED_COST: 'SHARED_COST',
};

export const DocumentaryEvents = {
  AFTER_BILL_OF_LADING_DATE: 'AFTER_BILL_OF_LADING_DATE',
  AFTER_INVOICE_DATE: 'AFTER_INVOICE_DATE',
  AFTER_DISCHARGING_IS_COMPLETED: 'AFTER_DISCHARGING_IS_COMPLETED',
} as const;

export const ConsumptionEvents = {
  AFTER_BILL_OF_LADING_DATE: 'AFTER_BILL_OF_LADING_DATE',
  AFTER_INVOICE_DATE: 'AFTER_INVOICE_DATE',
  AFTER_DISCHARGING: 'AFTER_DISCHARGING',
} as const;

export enum BerthConditions {
  AAAA = 'AAAA',
  AA = 'AA',
  NAABSA = 'NAABSA',
}

export enum BannerContentType {
  DETAILED_OFFERS = 'DETAILED_OFFERS',
  SHARED_CARGOS = 'SHARED_CARGOS',
  PENDING_CARGOS = 'PENDING_CARGOS',
}

export enum ExclusionFromLayTime {
  INITIAL_DRAFT_SURVEY = 'INITIAL_DRAFT_SURVEY',
  INTERMEDIARY_DRAFT_SURVEY = 'INTERMEDIARY_DRAFT_SURVEY',
  FINAL_DRAFT_SURVEY = 'FINAL_DRAFT_SURVEY',
  FIRST_SHIFTING = 'FIRST_SHIFTING',
  INTERMEDIARY_SHIFTING = 'INTERMEDIARY_SHIFTING',
  OPENING_OF_HATCHES = 'OPENING_OF_HATCHES',
  WARPING = 'WARPING',
  SWELL = 'SWELL',
  CUSTOM_CLEARANCE = 'CUSTOM_CLEARANCE',
  OTHERS = 'OTHERS',
}

export enum LaytimeCalculations {
  BILATERAL_AGREEMENT = 'BILATERAL_AGREEMENT',
  SELLER_CALCULATION = 'SELLER_CALCULATION',
  BUYER_CALCULATION = 'BUYER_CALCULATION',
  TRUSTED_PARTY = 'TRUSTED_PARTY',
}

export enum FreightTerms {
  SSHINC = 'SSHINC',
  FSHINC = 'FSHINC',
  SSHEX_EIU = 'SSHEX_EIU',
  SSHEX_UU = 'SSHEX_UU',
  SatNoonSHEX_EIU = 'SatNoonSHEX_EIU',
  SatNoonSHEX_UU = 'SatNoonSHEX_UU',
  SHEX_EIU = 'SHEX_EIU',
  SHEX_UU = 'SHEX_UU',
  SHINC = 'SHINC',
}

export enum NorTendering {
  ATDN_PLUS_SSHINC = 'ATDN_PLUS_SSHINC',
  FSHINC = 'FSHINC',
  SSHEX_EIU = 'SSHEX_EIU',
  SSHEX_UU = 'SSHEX_UU',
  SATNOONSHEX_EIU = 'SATNOONSHEX_EIU',
  SATNOONSHEX_UU = 'SATNOONSHEX_UU',
  SHEX_EIU = 'SHEX_EIU',
  SHEX_UU = 'SHEX_UU',
}

export enum FreightPeriodTerms {
  PWWD = 'PWWD',
  PER_WORKING_HOUR = 'PER_WORKING_HOUR',
}

export enum TurnTimeTerms {
  EVEN_IF_USED = 'EVEN_IF_USED',
  UNLESS_USED = 'UNLESS_USED',
}

// TODO: Add more options after agreement
export enum Laws {
  ENGLISH_LAW = 'ENGLISH_LAW',
  OTHER = 'OTHER',
}

export enum Jurisdiction {
  ENGLISH_JURISDICTION = 'ENGLISH_JURISDICTION',
  OTHER = 'OTHER',
}

export enum DeclarationStatus {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
}

export enum CommercialActivity {
  IMPORT = 'IMPORT',
  EXPORT = 'EXPORT',
  IMPORT_AND_EXPORT = 'IMPORT_AND_EXPORT',
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoSide = (typeof CargoSide)[keyof typeof CargoSide];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoSide = {
  BUY: 'BUY',
  SELL: 'SELL',
} as const;

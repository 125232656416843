import { Stack } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { OpenAccountDTO } from 'api/generated/model';

import { Text } from '@/components';
import { decimalToPercentageRound } from '@/views/tenders/utils/percentage';

interface OpenAccountProps {
  openAccount: OpenAccountDTO;
}

export const OpenAccountLabel = ({ openAccount }: OpenAccountProps) => {
  const { t } = useTranslation('tenderContract');

  return (
    <Stack spacing={0}>
      <Text weight="bold">{t(`labels.openAccount`)}</Text>
      <OpenAccountText openAccount={openAccount} />
    </Stack>
  );
};

export const OpenAccountText = ({ openAccount }: OpenAccountProps) => {
  const { t } = useTranslation('tenderContract');

  return (
    <Text>
      {t(`valuesFormatted.openAccountValue`, {
        days: openAccount.days,
        event: t(`valuesFormatted.${openAccount.event}`),
        percentage: decimalToPercentageRound(openAccount.percentage),
      })}
    </Text>
  );
};

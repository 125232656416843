import { Anchor, Group, Skeleton, Stack } from '@mantine/core';
import { useGetCompany } from 'api/generated/companies/companies';

import { getFullCompanyAddress } from '@/common/get-full-company-address';
import { Text, Title, Avatar } from '@/components';
import { routes } from '@/common/routes';

interface CompanyDetailsProps {
  companyName: string;
  companyId: string;
}

export function CompanyDetails({ companyName, companyId }: CompanyDetailsProps) {
  const { data, isLoading } = useGetCompany(companyId, {
    query: { select: ({ data }) => data, enabled: Boolean(companyId) },
  });

  return (
    <Group>
      {isLoading ? <Skeleton width={40} height={40} /> : <Avatar src={data?.logoUrl} />}
      <Stack spacing={0}>
        <Title order={1} size="L" color="primary">
          {data?.registered ? <Anchor href={routes.CompanyProfile(companyId)}>{companyName}</Anchor> : companyName}
        </Title>
        {isLoading && <Skeleton width={200} height="0.75em" />}
        {data?.registered && (
          <Text size="S" color="grey">
            {getFullCompanyAddress(data)}
          </Text>
        )}
      </Stack>
    </Group>
  );
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type RateTerms = (typeof RateTerms)[keyof typeof RateTerms];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RateTerms = {
  SSHINC: 'SSHINC',
  FSHINC: 'FSHINC',
  SSHEX_EIU: 'SSHEX_EIU',
  SSHEX_UU: 'SSHEX_UU',
  SatNoonSHEX_EIU: 'SatNoonSHEX_EIU',
  SatNoonSHEX_UU: 'SatNoonSHEX_UU',
  SHEX_EIU: 'SHEX_EIU',
  SHEX_UU: 'SHEX_UU',
  SSHEX_EUI: 'SSHEX_EUI',
  SHINC: 'SHINC',
} as const;

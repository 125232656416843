/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type GetAllCargoOfferServicesForSellersCargoType =
  (typeof GetAllCargoOfferServicesForSellersCargoType)[keyof typeof GetAllCargoOfferServicesForSellersCargoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAllCargoOfferServicesForSellersCargoType = {
  OFFER: 'OFFER',
  NEED: 'NEED',
  WORKFLOW: 'WORKFLOW',
} as const;

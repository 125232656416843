import { Stack } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { OtherDTO } from 'api/generated/model';

import { Text } from '@/components';
import { decimalToPercentage } from '@/views/tenders/utils/percentage';

interface OtherProps {
  other: OtherDTO;
}

export const OtherLabel = ({ other }: OtherProps) => {
  const { t } = useTranslation('tenderContract');

  return (
    <Stack spacing={0}>
      <Text weight="bold">{t(`labels.other`)}</Text>
      <OtherText other={other} />
    </Stack>
  );
};

export const OtherText = ({ other }: OtherProps) => {
  const { t } = useTranslation('tenderContract');

  return (
    <Text>
      {t(`valuesFormatted.other`, {
        methodOfPayment: other.methodOfPayment,
        percentage: decimalToPercentage(other.percentage),
      })}
    </Text>
  );
};

import { z } from 'zod';

import { OfferType } from '../my-cargos';

export const schemaCounterpartyMessage = z.object({
  unfinishedReason: z.string().optional(),
});

export type CounterpartyMessageForm = z.infer<typeof schemaCounterpartyMessage>;

export interface CancelNegotiationsActions {
  back: StepFunction;
  next: StepFunction;
}

export enum CancelNegotiationState {
  CANCEL_NEGOTIATION = 'CANCEL_NEGOTIATION',
  SEND_MESSAGE_TO_COUNTERPARTY = 'SEND_MESSAGE_TO_COUNTERPARTY',
  SEND_FEEDBACK_FOR_PLATFORM = 'SEND_FEEDBACK_FOR_PLATFORM',
  SUBMITTING = 'SUBMITTING',
  SINGLE_STEP_CANCEL_NEGOTIATION = 'SINGLE_STEP_CANCEL_NEGOTIATION',
  CANCEL_NEGOTIATION_SUCCESS = 'CANCEL_NEGOTIATION_SUCCESS',
}

export type ExpireBadgeFunction = (props: { type: OfferType; negotiationId: string }) => void;

export type OnExpireBadgeFunction = (props: { type: OfferType; negotiationId: string; isRequested: boolean }) => void;

export type ExtendNegotiationSuccess = (props: { type: OfferType; negotiationId: string; offerId: string }) => void;

export type CancelNegotiationsActionsState = Record<CancelNegotiationState, CancelNegotiationsActions>;

export type CancelNegotiationsHeaders = Record<CancelNegotiationState, string>;

export enum FeedbackReason {
  CARGO_SOLD_ON_MARKETPLACE = 'CARGO_SOLD_ON_MARKETPLACE',
  CARGO_SOLD_VIA_OTHER = 'CARGO_SOLD_VIA_OTHER',
  CARGO_NOT_AVAILABLE = 'CARGO_NOT_AVAILABLE',
  TECHNICAL_ISSUES = 'TECHNICAL_ISSUES',
  OTHER_REASON = 'OTHER_REASON',
}

export enum ModalType {
  CANCEL = 'CANCEL',
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
  // TODO: Wrong modal types
  REQUEST_EXTENSION = 'REQUEST_EXTENSION',
  EXTEND = 'EXTEND',
}

export const schemaPlatformFeedback = z.object({
  feedbackType: z.nativeEnum(FeedbackReason),
  feedbackComment: z.string(),
});

export type PlatformFeedbackFormValues = z.infer<typeof schemaPlatformFeedback>;

export type StepFunction = (values?: CounterpartyMessageForm | PlatformFeedbackFormValues) => void;

import { z } from 'zod';

import { CargoTransportation, zDateRange } from '../types';

export const cargoTenderFormGroupCommonSchema = z.object({
  key: z.string().optional(),
  groupName: z.string(),
  companyProductId: z.string(),
  originPlantId: z.string().nullable(),
  originPlantName: z.string().nullable().optional(),
  originPortId: z.string().nullable(),
  originPortName: z.string().nullable().optional(),
  destinationPlantId: z.string().nullable(),
  destinationPlantName: z.string().nullable().optional(),
  destinationPortId: z.string().nullable(),
  destinationPortName: z.string().nullable().optional(),
  dateRange: zDateRange,
  incoterm: z.nativeEnum(CargoTransportation),
  totalVolume: z.number().optional(),
});

export const cargoTenderFormGroupQuantitySchema = z.object({
  fixedTotalQuantityMin: z.number(),
  fixedTotalQuantityMax: z.number(),
  fixedShipmentsNumberMin: z.number(),
  fixedShipmentsNumberMax: z.number(),
  optionalTotalQuantityMin: z.number(),
  optionalTotalQuantityMax: z.number(),
  optionalShipmentsNumberMax: z.number(),
});

export type CargoTenderFormGroupQuantitySchema = z.infer<typeof cargoTenderFormGroupQuantitySchema>;

export const cargoTenderFormGroupSchema = cargoTenderFormGroupCommonSchema.merge(cargoTenderFormGroupQuantitySchema);

export type CargoTenderFormGroupSchema = z.infer<typeof cargoTenderFormGroupSchema>;

import { Button, createStyles, Menu, MenuItemProps } from '@mantine/core';
import { ReactElement } from 'react';
import { RiArrowDownSLine } from 'react-icons/ri';

const useStyles = createStyles((theme) => ({
  rightIcon: {
    marginLeft: 16,
    '&::before': {
      content: '""',
      top: -1,
      bottom: -1,
      right: 40,
      position: 'absolute',
      borderLeft: '1px solid white',
    },
  },
  buttonInner: {
    gap: 16,
  },
  menuDropdown: {
    boxShadow: '0px 4px 6px rgba(173, 181, 189, 0.12)',
  },
  menuItem: {
    lineHeight: '22px',
    fontWeight: 500,
    minWidth: 192,
    borderRadius: 0,
    padding: '12px 16px 12px 16px',

    '&:hover': {
      backgroundColor: theme.colors.indigo[0],
      color: theme.fn.primaryColor(),
    },
  },
}));

interface MenuButtonProps {
  children: ReactElement<MenuButtonProps> | ReactElement<MenuButtonProps>[];
  label: string;
  testId: string;
}

export function MenuButton(props: MenuButtonProps) {
  const { children, label, testId } = props;
  const { classes } = useStyles();

  return (
    <Menu transition="pop" position="bottom-end" radius="sm">
      <Menu.Target>
        <Button
          classNames={{ rightIcon: classes.rightIcon, inner: classes.buttonInner }}
          data-testid={testId}
          rightIcon={<RiArrowDownSLine />}
          size="md"
        >
          {label}
        </Button>
      </Menu.Target>
      <Menu.Dropdown className={classes.menuDropdown} p={0}>
        {children}
      </Menu.Dropdown>
    </Menu>
  );
}

type MenuButtonItemProps = MenuItemProps & Pick<React.HTMLProps<HTMLButtonElement>, 'onClick'>;

function MenuButtonItem(props: MenuButtonItemProps) {
  const { children, className, ...rest } = props;
  const { classes, cx } = useStyles();

  return (
    <Menu.Item className={cx(classes.menuItem, className)} {...rest}>
      {children}
    </Menu.Item>
  );
}

MenuButton.Item = MenuButtonItem;

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';

import type {
  WorkflowTaskListDTO,
  GetCargoWorkflowTasksParams,
  CreateWorkflowTaskDTO,
  MilestoneStatsDTO,
  WorkflowTaskWithDocumentsDTO,
  WorkflowDocumentsUploadFileDto,
  WorkflowTaskDTO,
  UpdateWorkflowPatchDTO,
  ShareWorkflowTaskDTO,
  WorkflowDocumentDownloadDTO,
  WorkflowDocumentDTO,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getCargoWorkflowTasks = (
  workflowId: string,
  params?: GetCargoWorkflowTasksParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<WorkflowTaskListDTO>> => {
  return axios.get(`/api/v1/workflow/${workflowId}/tasks`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetCargoWorkflowTasksQueryKey = (workflowId: string, params?: GetCargoWorkflowTasksParams) => [
  `/api/v1/workflow/${workflowId}/tasks`,
  ...(params ? [params] : []),
];

export type GetCargoWorkflowTasksQueryResult = NonNullable<Awaited<ReturnType<typeof getCargoWorkflowTasks>>>;
export type GetCargoWorkflowTasksQueryError = AxiosError<void>;

export const useGetCargoWorkflowTasks = <
  TData = Awaited<ReturnType<typeof getCargoWorkflowTasks>>,
  TError = AxiosError<void>
>(
  workflowId: string,
  params?: GetCargoWorkflowTasksParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCargoWorkflowTasks>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCargoWorkflowTasksQueryKey(workflowId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCargoWorkflowTasks>>> = ({ signal }) =>
    getCargoWorkflowTasks(workflowId, params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCargoWorkflowTasks>>, TError, TData>(queryKey, queryFn, {
    enabled: !!workflowId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const createWorkflowTask = (
  workflowId: string,
  createWorkflowTaskDTO: CreateWorkflowTaskDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  const formData = new FormData();
  formData.append('name', createWorkflowTaskDTO.name);
  formData.append('assignedTo', createWorkflowTaskDTO.assignedTo);
  formData.append('milestone', createWorkflowTaskDTO.milestone);
  formData.append('categoryId', createWorkflowTaskDTO.categoryId);
  if (createWorkflowTaskDTO.threshold !== undefined) {
    formData.append('threshold', createWorkflowTaskDTO.threshold.toString());
  }
  if (createWorkflowTaskDTO.thresholdCondition !== undefined) {
    formData.append('thresholdCondition', createWorkflowTaskDTO.thresholdCondition);
  }
  if (createWorkflowTaskDTO.eventCondition !== undefined) {
    formData.append('eventCondition', createWorkflowTaskDTO.eventCondition);
  }
  if (createWorkflowTaskDTO.sharedWith !== undefined) {
    createWorkflowTaskDTO.sharedWith.forEach((value) => formData.append('sharedWith', value));
  }
  if (createWorkflowTaskDTO.documentSharedWith !== undefined) {
    createWorkflowTaskDTO.documentSharedWith.forEach((value) => formData.append('documentSharedWith', value));
  }
  if (createWorkflowTaskDTO.file !== undefined) {
    formData.append('file', createWorkflowTaskDTO.file);
  }
  if (createWorkflowTaskDTO.financiallySensitive !== undefined) {
    formData.append('financiallySensitive', createWorkflowTaskDTO.financiallySensitive.toString());
  }

  return axios.post(`/api/v1/workflow/${workflowId}/tasks`, formData, options);
};

export type CreateWorkflowTaskMutationResult = NonNullable<Awaited<ReturnType<typeof createWorkflowTask>>>;
export type CreateWorkflowTaskMutationBody = CreateWorkflowTaskDTO;
export type CreateWorkflowTaskMutationError = AxiosError<unknown>;

export const useCreateWorkflowTask = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createWorkflowTask>>,
    TError,
    { workflowId: string; data: CreateWorkflowTaskDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createWorkflowTask>>,
    { workflowId: string; data: CreateWorkflowTaskDTO }
  > = (props) => {
    const { workflowId, data } = props ?? {};

    return createWorkflowTask(workflowId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof createWorkflowTask>>,
    TError,
    { workflowId: string; data: CreateWorkflowTaskDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const getCargoWorkflowTasksStatistics = (
  workflowId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<MilestoneStatsDTO[]>> => {
  return axios.get(`/api/v1/workflow/${workflowId}/tasks/stats`, options);
};

export const getGetCargoWorkflowTasksStatisticsQueryKey = (workflowId: string) => [
  `/api/v1/workflow/${workflowId}/tasks/stats`,
];

export type GetCargoWorkflowTasksStatisticsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCargoWorkflowTasksStatistics>>
>;
export type GetCargoWorkflowTasksStatisticsQueryError = AxiosError<void>;

export const useGetCargoWorkflowTasksStatistics = <
  TData = Awaited<ReturnType<typeof getCargoWorkflowTasksStatistics>>,
  TError = AxiosError<void>
>(
  workflowId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCargoWorkflowTasksStatistics>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCargoWorkflowTasksStatisticsQueryKey(workflowId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCargoWorkflowTasksStatistics>>> = ({ signal }) =>
    getCargoWorkflowTasksStatistics(workflowId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCargoWorkflowTasksStatistics>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!workflowId, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getCargoWorkflowTask = (
  workflowId: string,
  taskId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<WorkflowTaskWithDocumentsDTO>> => {
  return axios.get(`/api/v1/workflow/${workflowId}/tasks/${taskId}`, options);
};

export const getGetCargoWorkflowTaskQueryKey = (workflowId: string, taskId: string) => [
  `/api/v1/workflow/${workflowId}/tasks/${taskId}`,
];

export type GetCargoWorkflowTaskQueryResult = NonNullable<Awaited<ReturnType<typeof getCargoWorkflowTask>>>;
export type GetCargoWorkflowTaskQueryError = AxiosError<void>;

export const useGetCargoWorkflowTask = <
  TData = Awaited<ReturnType<typeof getCargoWorkflowTask>>,
  TError = AxiosError<void>
>(
  workflowId: string,
  taskId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCargoWorkflowTask>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCargoWorkflowTaskQueryKey(workflowId, taskId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCargoWorkflowTask>>> = ({ signal }) =>
    getCargoWorkflowTask(workflowId, taskId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCargoWorkflowTask>>, TError, TData>(queryKey, queryFn, {
    enabled: !!(workflowId && taskId),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const uploadWorkflowDocument = (
  workflowId: string,
  taskId: string,
  workflowDocumentsUploadFileDto: WorkflowDocumentsUploadFileDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  const formData = new FormData();
  formData.append('file', workflowDocumentsUploadFileDto.file);

  return axios.put(`/api/v1/workflow/${workflowId}/tasks/${taskId}`, formData, options);
};

export type UploadWorkflowDocumentMutationResult = NonNullable<Awaited<ReturnType<typeof uploadWorkflowDocument>>>;
export type UploadWorkflowDocumentMutationBody = WorkflowDocumentsUploadFileDto;
export type UploadWorkflowDocumentMutationError = AxiosError<unknown>;

export const useUploadWorkflowDocument = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadWorkflowDocument>>,
    TError,
    { workflowId: string; taskId: string; data: WorkflowDocumentsUploadFileDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadWorkflowDocument>>,
    { workflowId: string; taskId: string; data: WorkflowDocumentsUploadFileDto }
  > = (props) => {
    const { workflowId, taskId, data } = props ?? {};

    return uploadWorkflowDocument(workflowId, taskId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof uploadWorkflowDocument>>,
    TError,
    { workflowId: string; taskId: string; data: WorkflowDocumentsUploadFileDto },
    TContext
  >(mutationFn, mutationOptions);
};
export const updateWorkflowTask = (
  workflowId: string,
  taskId: string,
  updateWorkflowPatchDTO: UpdateWorkflowPatchDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<WorkflowTaskDTO>> => {
  return axios.patch(`/api/v1/workflow/${workflowId}/tasks/${taskId}`, updateWorkflowPatchDTO, options);
};

export type UpdateWorkflowTaskMutationResult = NonNullable<Awaited<ReturnType<typeof updateWorkflowTask>>>;
export type UpdateWorkflowTaskMutationBody = UpdateWorkflowPatchDTO;
export type UpdateWorkflowTaskMutationError = AxiosError<void>;

export const useUpdateWorkflowTask = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateWorkflowTask>>,
    TError,
    { workflowId: string; taskId: string; data: UpdateWorkflowPatchDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateWorkflowTask>>,
    { workflowId: string; taskId: string; data: UpdateWorkflowPatchDTO }
  > = (props) => {
    const { workflowId, taskId, data } = props ?? {};

    return updateWorkflowTask(workflowId, taskId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateWorkflowTask>>,
    TError,
    { workflowId: string; taskId: string; data: UpdateWorkflowPatchDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const deleteTask = (
  workflowId: string,
  taskId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/v1/workflow/${workflowId}/tasks/${taskId}`, options);
};

export type DeleteTaskMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTask>>>;

export type DeleteTaskMutationError = AxiosError<unknown>;

export const useDeleteTask = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTask>>,
    TError,
    { workflowId: string; taskId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTask>>, { workflowId: string; taskId: string }> = (
    props
  ) => {
    const { workflowId, taskId } = props ?? {};

    return deleteTask(workflowId, taskId, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof deleteTask>>, TError, { workflowId: string; taskId: string }, TContext>(
    mutationFn,
    mutationOptions
  );
};
export const quickShareTask = (
  workflowId: string,
  taskId: string,
  shareWorkflowTaskDTO: ShareWorkflowTaskDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/v1/workflow/${workflowId}/tasks/${taskId}/quick-share`, shareWorkflowTaskDTO, options);
};

export type QuickShareTaskMutationResult = NonNullable<Awaited<ReturnType<typeof quickShareTask>>>;
export type QuickShareTaskMutationBody = ShareWorkflowTaskDTO;
export type QuickShareTaskMutationError = AxiosError<unknown>;

export const useQuickShareTask = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof quickShareTask>>,
    TError,
    { workflowId: string; taskId: string; data: ShareWorkflowTaskDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof quickShareTask>>,
    { workflowId: string; taskId: string; data: ShareWorkflowTaskDTO }
  > = (props) => {
    const { workflowId, taskId, data } = props ?? {};

    return quickShareTask(workflowId, taskId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof quickShareTask>>,
    TError,
    { workflowId: string; taskId: string; data: ShareWorkflowTaskDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const deleteFileDocument = (
  workflowId: string,
  taskId: string,
  documentId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/v1/workflow/${workflowId}/tasks/${taskId}/documents/${documentId}`, options);
};

export type DeleteFileDocumentMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFileDocument>>>;

export type DeleteFileDocumentMutationError = AxiosError<unknown>;

export const useDeleteFileDocument = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFileDocument>>,
    TError,
    { workflowId: string; taskId: string; documentId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteFileDocument>>,
    { workflowId: string; taskId: string; documentId: string }
  > = (props) => {
    const { workflowId, taskId, documentId } = props ?? {};

    return deleteFileDocument(workflowId, taskId, documentId, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteFileDocument>>,
    TError,
    { workflowId: string; taskId: string; documentId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const getCargoWorkflowDocumentURL = (
  workflowId: string,
  taskId: string,
  documentId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<WorkflowDocumentDownloadDTO>> => {
  return axios.get(`/api/v1/workflow/${workflowId}/tasks/${taskId}/documents/${documentId}/download`, options);
};

export const getGetCargoWorkflowDocumentURLQueryKey = (workflowId: string, taskId: string, documentId: string) => [
  `/api/v1/workflow/${workflowId}/tasks/${taskId}/documents/${documentId}/download`,
];

export type GetCargoWorkflowDocumentURLQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCargoWorkflowDocumentURL>>
>;
export type GetCargoWorkflowDocumentURLQueryError = AxiosError<void>;

export const useGetCargoWorkflowDocumentURL = <
  TData = Awaited<ReturnType<typeof getCargoWorkflowDocumentURL>>,
  TError = AxiosError<void>
>(
  workflowId: string,
  taskId: string,
  documentId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCargoWorkflowDocumentURL>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCargoWorkflowDocumentURLQueryKey(workflowId, taskId, documentId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCargoWorkflowDocumentURL>>> = ({ signal }) =>
    getCargoWorkflowDocumentURL(workflowId, taskId, documentId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCargoWorkflowDocumentURL>>, TError, TData>(queryKey, queryFn, {
    enabled: !!(workflowId && taskId && documentId),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const shareWorkflowTaskDocument = (
  workflowId: string,
  taskId: string,
  documentId: string,
  shareWorkflowTaskDTO: ShareWorkflowTaskDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<WorkflowDocumentDTO>> => {
  return axios.post(
    `/api/v1/workflow/${workflowId}/tasks/${taskId}/documents/${documentId}/share`,
    shareWorkflowTaskDTO,
    options
  );
};

export type ShareWorkflowTaskDocumentMutationResult = NonNullable<
  Awaited<ReturnType<typeof shareWorkflowTaskDocument>>
>;
export type ShareWorkflowTaskDocumentMutationBody = ShareWorkflowTaskDTO;
export type ShareWorkflowTaskDocumentMutationError = AxiosError<void>;

export const useShareWorkflowTaskDocument = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shareWorkflowTaskDocument>>,
    TError,
    { workflowId: string; taskId: string; documentId: string; data: ShareWorkflowTaskDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shareWorkflowTaskDocument>>,
    { workflowId: string; taskId: string; documentId: string; data: ShareWorkflowTaskDTO }
  > = (props) => {
    const { workflowId, taskId, documentId, data } = props ?? {};

    return shareWorkflowTaskDocument(workflowId, taskId, documentId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof shareWorkflowTaskDocument>>,
    TError,
    { workflowId: string; taskId: string; documentId: string; data: ShareWorkflowTaskDTO },
    TContext
  >(mutationFn, mutationOptions);
};

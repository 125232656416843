import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { useQueryClient } from '@tanstack/react-query';
import {
  getGetContractTemplatesQueryKey,
  useDeleteContractTemplate,
  useGetContractTemplate,
} from 'api/generated/contract-template/contract-template';
import { CargoTransportation, ContractTemplateListItemDTO, NegotiationSide } from 'api/generated/model';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { useAuthRoles } from '@/core';
import { RecapForm } from '@/blink-forms';
import { DeleteTemplateModal, LoadTemplateDrawer, LoadContractTemplateModal } from '@/views/recap-form';
import { useToastNotifications } from '@/common/use-toast-notifications';

import { populateCargo } from '../state/populate-init-state';
import { CargoTemplate, InitialOfferInitialValues } from '../types';

export function offerDataToCargo(offer: InitialOfferInitialValues | null) {
  return offer ? populateCargo(offer) : undefined;
}

export function useLoadTemplateHelpers(cargo?: RecapForm['product']['cargo']) {
  const [opened, { toggle }] = useDisclosure(false);
  const [template, setTemplate] = useState<CargoTemplate>();
  const loadTemplate = (templateId: string, template: RecapForm) =>
    setTemplate({
      id: templateId,
      recapForm: {
        ...template,
        product: {
          ...template.product,
          cargo: cargo
            ? { ...cargo, dischargingPortId: template.product.cargo.dischargingPortId }
            : template.product.cargo,
        },
      },
    });

  return { opened, template, loadTemplate, toggle };
}

interface LoadTemplateDrawerContainerProps {
  opened: boolean;
  toggle: VoidFunction;
  onTemplateLoaded: (templateId: string, template: RecapForm) => void;
  negotiationSide: NegotiationSide;
  offeredTransportation: CargoTransportation;
}

export function LoadTemplateDrawerContainer(props: LoadTemplateDrawerContainerProps) {
  const { opened, toggle, onTemplateLoaded, negotiationSide, offeredTransportation } = props;
  const { t } = useTranslation('recapForm');
  const { companyId } = useAuthRoles();
  const [loadTemplateModalOpened, { toggle: toggleLoadTemplateModal }] = useDisclosure(false);
  const [deleteTemplateModalOpened, { toggle: toggleDeleteTemplateModal }] = useDisclosure(false);
  const queryClient = useQueryClient();
  const { showUnexpectedErrorNotification } = useToastNotifications();

  const [selectedTemplate, setSelectedTemplate] = useState<ContractTemplateListItemDTO>();

  const contractTemplate = useGetContractTemplate(companyId, selectedTemplate?.id, {
    query: {
      select: (response) => response.data.recapForm,
      onSuccess: (response) => {
        toggleLoadTemplateModal();
        toggle();
        showNotification({
          color: 'green',
          title: t('notifications.templateLoaded'),
        });
        onTemplateLoaded(selectedTemplate.id, response);
      },
      onError: () => {
        showUnexpectedErrorNotification();
      },
      enabled: false,
    },
  });

  const deleteContractTemplate = useDeleteContractTemplate({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetContractTemplatesQueryKey(companyId));
        toggleDeleteTemplateModal();
        toggle();
        showNotification({
          color: 'green',
          title: t('notifications.templateDeleted'),
        });
      },
      onError: () => {
        showUnexpectedErrorNotification();
      },
    },
  });

  return (
    <>
      <LoadTemplateDrawer
        opened={opened}
        toggle={toggle}
        negotiationSide={negotiationSide}
        offeredTransportation={offeredTransportation}
        onUseTemplate={(row) => {
          setSelectedTemplate(row.original);
          toggleLoadTemplateModal();
        }}
        onDeleteTemplate={(row) => {
          setSelectedTemplate(row.original);
          toggleDeleteTemplateModal();
        }}
      />

      <LoadContractTemplateModal
        opened={loadTemplateModalOpened}
        isLoading={contractTemplate.isFetching}
        toggle={toggleLoadTemplateModal}
        onLoadTemplate={() => contractTemplate.refetch()}
      />

      <DeleteTemplateModal
        opened={deleteTemplateModalOpened}
        isLoading={deleteContractTemplate.isLoading}
        toggle={toggleDeleteTemplateModal}
        onDeleteTemplate={() => deleteContractTemplate.mutate({ companyId, templateId: selectedTemplate.id })}
      />
    </>
  );
}

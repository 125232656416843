import { CargoNeedDTO, CargoTransportation } from 'api/generated/model';

import {
  CounterOfferInitialValues,
  FrontendRecapForm,
  InitialOfferInitialValues,
  InitialValues,
  SelectedPaymentConditions,
} from './../types';
import { emptyRecapFormValue, emptySelectedPaymentConditions } from './empty-form-value';

interface PopulatedInitState {
  initialValues: FrontendRecapForm;
  selectedPaymentConditions: SelectedPaymentConditions;
}

export function isCounterOffer(initialValues: InitialValues): initialValues is CounterOfferInitialValues {
  return !!(initialValues as CounterOfferInitialValues).form;
}

export function populateCargo(initialValues: InitialOfferInitialValues): FrontendRecapForm['product']['cargo'] {
  return {
    productId: initialValues.productId,
    productName: initialValues.productName,
    productPackaging: initialValues.productPackaging,
    productQuality: initialValues.productQuality,
    dischargingPortId: initialValues.dischargingPortId,
    destinationPlantId: initialValues.destinationPlantId,
    laycan: [initialValues.laycanMin, initialValues.laycanMax],
    loadingPortId: initialValues.loadingPortId,
    minTolerance: initialValues.toleranceMinPercentage,
    maxTolerance: initialValues.toleranceMaxPercentage,
    originPlantId: initialValues.plantId,
    originPlantCountry: initialValues.plantCountry,
    quantity: initialValues.quantity,
    toleranceOption: 'IN_SELLERS_OPTION',
  };
}

export function populateInitStateFromSavedState(savedState: FrontendRecapForm): PopulatedInitState {
  const selectedPaymentConditions = Object.entries(savedState.payment.conditions)
    .filter(([, value]) => !!value)
    .reduce((acc, [key]) => ({ ...acc, [key]: { checked: true, isDirty: false } }), emptySelectedPaymentConditions);

  // TODO: remove this when recap form will generic
  savedState.isOnlyVisibleToCompany = false;
  savedState.requestFreightDetails = false;
  if (!savedState.product.cargo.dischargingPortId) savedState.product.cargo.dischargingPortId = '';

  return {
    initialValues: savedState,
    selectedPaymentConditions,
  };
}

export function populateInitStateFromOffer(
  initialOfferValues: InitialOfferInitialValues | undefined,
  offeredTransport: CargoTransportation
): PopulatedInitState {
  const initialValues = emptyRecapFormValue();
  const isFob = offeredTransport === CargoTransportation.FOB;

  if (initialOfferValues) {
    initialValues.product.cargo = populateCargo(initialOfferValues);
    initialValues.price.price = isFob
      ? initialOfferValues.price
      : initialOfferValues.price + (initialOfferValues.freightLevel || 0);
    initialValues.product.freightConditions.freightRate = isFob
      ? initialOfferValues.loadingRate
      : initialOfferValues.dischargingRate;
    initialValues.product.freightConditions.freightTerms = isFob
      ? initialOfferValues.loadingTerms
      : initialOfferValues.dischargingRateTerm;
  }

  return {
    initialValues,
    selectedPaymentConditions: emptySelectedPaymentConditions,
  };
}

export function populateInitStateFromNeed(need: CargoNeedDTO | undefined): PopulatedInitState {
  const initialValues = emptyRecapFormValue();

  if (need) {
    initialValues.price.price = need?.price;
    initialValues.product.cargo.productPackaging = need?.productPackaging;
    initialValues.product.cargo.productQuality = need?.productQuality;
    initialValues.product.cargo.quantity = need?.quantity;
    initialValues.product.cargo.productName = need.productName;
    initialValues.product.cargo.dischargingPortId = need.dischargingPortId;
    initialValues.product.cargo.destinationPlantId = need.plantId;
    initialValues.product.cargo.laycan = [need?.etaMin, need?.etaMax];
    initialValues.product.freightConditions.freightRate = need.dischargingRate;
    initialValues.product.freightConditions.freightTerms = need.dischargingRateTerm;
  }

  return {
    initialValues,
    selectedPaymentConditions: emptySelectedPaymentConditions,
  };
}

export function populateInitState(initialValues: InitialValues, offeredTransport: CargoTransportation) {
  const { initialValues: initialFormValues, selectedPaymentConditions: initialSelectedPaymentConditions } =
    isCounterOffer(initialValues)
      ? populateInitStateFromSavedState(initialValues.form)
      : populateInitStateFromOffer(initialValues, offeredTransport);

  return {
    initialValues: initialFormValues,
    initialSelectedPaymentConditions,
  };
}

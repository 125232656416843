import { useGetStandarDataSelectOptions } from 'api/generated/masterdata/masterdata';
import { SelectItem } from '@mantine/core';

import { mapPlantsToSelectData } from '@/common/mapping-helpers';

export function useStandardData() {
  const standardData = useGetStandarDataSelectOptions({
    query: {
      select: ({ data }) => {
        const ports = data.ports.map((port) => port.name);
        const plants: SelectItem[] = mapPlantsToSelectData(data.plants);
        const productNames = [...new Set(data.products.map((product) => product.name))];

        const packagingsByProduct = productNames.reduce(
          (prev, productName) => ({
            ...prev,
            [productName]: [
              ...new Set(data.products.flatMap((product) => (productName === product.name ? product.packaging : []))),
            ],
          }),
          {}
        );

        const gradesByProduct = productNames.reduce(
          (prev, productName) => ({
            ...prev,
            [productName]: [
              ...new Set(data.products.flatMap((product) => (productName === product.name ? product.grade?.name : []))),
            ],
          }),
          {}
        );

        return {
          ports,
          plants,
          dropdowns: {
            productNames,
            packagingsByProduct,
            gradesByProduct,
          },
        };
      },
    },
  });

  return standardData;
}

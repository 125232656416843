/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type Laws = (typeof Laws)[keyof typeof Laws];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Laws = {
  ENGLISH_LAW: 'ENGLISH_LAW',
  OTHER: 'OTHER',
} as const;

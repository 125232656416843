import { createStyles, Drawer } from '@mantine/core';
import { ReactNode, cloneElement } from 'react';

const useStyles = createStyles((theme) => ({
  hideOnMobile: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
      pointerEvents: 'none',
    },
  },
  hideOnDesktop: {
    [theme.fn.largerThan('md')]: {
      display: 'none',
      pointerEvents: 'none',
    },
  },
  header: {
    height: 0,
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: 16,
    top: 16,
    color: theme.fn.primaryColor(),

    svg: {
      width: 32,
      height: 32,
    },
  },
  scroll: {
    overflowY: 'scroll',
  },
}));

export interface ResponsiveDrawerWrapperProps {
  openingButton: ReactNode;
  children: ReactNode;
  opened?: boolean;
  toggle: VoidFunction;
}

export function ResponsiveDrawerWrapper({ children, openingButton, toggle, opened = false }) {
  const { classes } = useStyles();

  return (
    <>
      <div className={classes.hideOnDesktop}>{openingButton}</div>
      <div className={classes.hideOnMobile}>{children}</div>
      <Drawer
        className={classes.scroll}
        classNames={{ header: classes.header, closeButton: classes.closeButton }}
        size="100vh"
        opened={opened}
        position="bottom"
        onClose={toggle}
      >
        {children}
      </Drawer>
    </>
  );
}

import { CellContext, ColumnDefTemplate } from '@tanstack/react-table';

import { Text } from '@/components';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PriceCell: ColumnDefTemplate<CellContext<any, string | number>> = ({ getValue }) => {
  const price = getValue();
  return (
    <Text size="M" color="gray.7" weight={600}>
      {price !== null ? `$${price} pmt` : '-'}
    </Text>
  );
};

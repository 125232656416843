import { BadgeColors, RowData } from './types';

export const BADGE_COLORS: BadgeColors = {
  FIRM: 'orange',
  TENTATIVE: 'cyan',
  INDICATIVE_PROSPECT: 'cyan',
  BOOKED: 'blue',
  FIRM_PROSPECT: 'orange',
  INTEGRATED: 'yellow',
  GRINDING: 'indigo',
  PURCHASES: 'yellow',
  SALES: 'cyan',
  ARCHIVED: 'gray',
  CANCELED: 'red',
  OPEN: 'green',
  CLOSED: 'gray',
  CFR: 'indigo',
  CIF: 'orange',
  FOB: 'green',
  DECLARED: 'green',
  NOT_DECLARED: 'gray',
  PENDING: 'yellow',
  REJECTED: 'red',
};

export const MOBILE_COLUMNS: Array<keyof RowData> = ['shortId', 'material', 'eta', 'status'];

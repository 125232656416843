import { useEffect } from 'react';
import { Paper } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import { RequestFreight } from '@/components/forms';
import { RecapFormWithPromoting, ConditionalRequestFreightFullSchema } from '@/blink-forms';

import { useRecapFormContext } from '../state/RecapFormProvider';

interface RecapFromPromotionExtensionProps {
  isNewCargoBehavior: boolean;
}

const formValuesToCargoOfferData = (values: RecapFormWithPromoting) => {
  const { laycan, quantity, productName, maxTolerance, minTolerance } = values.product.cargo;

  const [laycanMin, laycanMax] = laycan ?? [];

  return {
    quantity,
    laycanMin,
    laycanMax,
    productName,
    toleranceMinPercentage: minTolerance,
    toleranceMaxPercentage: maxTolerance,
  };
};

export const RecapFromPromotionExtension = ({ isNewCargoBehavior }: RecapFromPromotionExtensionProps) => {
  const { form } = useRecapFormContext();

  useEffect(() => {
    if (isNewCargoBehavior) {
      form.setFieldValue('isOnlyVisibleToCompany', true);
    }
  }, [isNewCargoBehavior]);

  return (
    <>
      <Paper p="md" withBorder mt="md">
        <RequestFreight
          cargoOfferData={formValuesToCargoOfferData(form.values)}
          form={form as UseFormReturnType<ConditionalRequestFreightFullSchema>}
        />
      </Paper>
    </>
  );
};

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type TurnTimeTerms = (typeof TurnTimeTerms)[keyof typeof TurnTimeTerms];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TurnTimeTerms = {
  EVEN_IF_USED: 'EVEN_IF_USED',
  UNLESS_USED: 'UNLESS_USED',
} as const;

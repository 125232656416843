import { DividerProps } from '@mantine/core';

import { ThemeComponent } from './types';

export const Divider: ThemeComponent<DividerProps> = {
  defaultProps: {
    size: 'xs',
    color: 'gray.1',
  },
};

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type FreightNegotiationExpiryExtensionAction =
  (typeof FreightNegotiationExpiryExtensionAction)[keyof typeof FreightNegotiationExpiryExtensionAction];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FreightNegotiationExpiryExtensionAction = {
  ACCEPT: 'ACCEPT',
  REJECT: 'REJECT',
} as const;

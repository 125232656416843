import { createStyles } from '@mantine/core';

export function inputWithAdjustedHeight<TProps>(Component: React.ComponentType<TProps>, height: number) {
  const useStyles = createStyles({
    input: {
      height,
    },
  });

  return function InputWithAdjustedHeight(props: TProps) {
    const { classes } = useStyles();

    return <Component classNames={classes} {...props} />;
  };
}

export function inputWithoutSpecifiedHeight<TProps>(Component: React.ComponentType<TProps>) {
  const useStyles = createStyles({
    root: {
      height: 'auto',
    },
  });

  return function InputWithoutSpecifiedHeight(props: TProps) {
    const { classes } = useStyles();

    return <Component classNames={classes} {...props} />;
  };
}

import { ReactNode } from 'react';
import { Grid, Paper, Stack } from '@mantine/core';

import { Text, Title } from '@/components';

interface EmptyTableProps {
  title: string;
  img: ReactNode;
  description?: string;
}

export const EmptyTable = ({ img, title, description }: EmptyTableProps) => {
  return (
    <Paper p={20}>
      <Grid>
        <Grid.Col
          md={6}
          sm={12}
          orderMd={2}
          orderSm={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Stack align="flex-start" spacing={16}>
            <Title size="XL" color="gray.8" weight={600}>
              {title}
            </Title>
            <Text color="gray.8">{description}</Text>
          </Stack>
        </Grid.Col>
        <Grid.Col md={6} sm={12} orderMd={1} orderSm={2}>
          {img}
        </Grid.Col>
      </Grid>
    </Paper>
  );
};

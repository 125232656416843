/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';

import type {
  ProductSpecificationDTO,
  GetCompanyProductSpecificationParams,
  ProductSpecificationUrlDTO,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getCompanyProductSpecification = (
  params: GetCompanyProductSpecificationParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ProductSpecificationDTO>> => {
  return axios.get(`/api/v1/specifications/specification`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetCompanyProductSpecificationQueryKey = (params: GetCompanyProductSpecificationParams) => [
  `/api/v1/specifications/specification`,
  ...(params ? [params] : []),
];

export type GetCompanyProductSpecificationQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompanyProductSpecification>>
>;
export type GetCompanyProductSpecificationQueryError = AxiosError<unknown>;

export const useGetCompanyProductSpecification = <
  TData = Awaited<ReturnType<typeof getCompanyProductSpecification>>,
  TError = AxiosError<unknown>
>(
  params: GetCompanyProductSpecificationParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCompanyProductSpecification>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCompanyProductSpecificationQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyProductSpecification>>> = ({ signal }) =>
    getCompanyProductSpecification(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCompanyProductSpecification>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getCompanyProductSpecificationDownloadUrl = (
  id: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ProductSpecificationUrlDTO>> => {
  return axios.post(`/api/v1/specifications/${id}/download`, undefined, options);
};

export type GetCompanyProductSpecificationDownloadUrlMutationResult = NonNullable<
  Awaited<ReturnType<typeof getCompanyProductSpecificationDownloadUrl>>
>;

export type GetCompanyProductSpecificationDownloadUrlMutationError = AxiosError<unknown>;

export const useGetCompanyProductSpecificationDownloadUrl = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getCompanyProductSpecificationDownloadUrl>>,
    TError,
    { id: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getCompanyProductSpecificationDownloadUrl>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return getCompanyProductSpecificationDownloadUrl(id, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof getCompanyProductSpecificationDownloadUrl>>,
    TError,
    { id: string },
    TContext
  >(mutationFn, mutationOptions);
};

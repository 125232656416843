import { Box, Stack, Checkbox, CheckboxProps } from '@mantine/core';

type CheckboxLabeledProps = Omit<CheckboxProps, 'label'> & {
  header: React.ReactNode;
  subheader?: React.ReactNode;
};

export const CheckboxLabeled = ({ header, subheader, ...props }: CheckboxLabeledProps) => {
  return (
    <Stack sx={{ flexDirection: 'row', alignItems: subheader ? 'flex-start' : 'center' }}>
      <Checkbox mt={subheader ? 5 : 0} {...props} />
      <Stack spacing={1}>
        <Box>{header}</Box>
        <Box>{subheader}</Box>
      </Stack>
    </Stack>
  );
};

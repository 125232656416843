import { GetInputProps as MantineGetInputProps } from '@mantine/form/lib/types';

export type StateMember<TType extends string> = {
  type: TType;
};

export function assertState<TType extends string>(
  state: { type: string },
  ...expectedTypes: TType[]
): asserts state is StateMember<TType> {
  if (!expectedTypes.includes(state.type as TType)) {
    throw new Error(`Invalid state ${state.type} (expected one of: ${expectedTypes})`);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DTO = Record<string, any>;
type LiteralType = string | number | boolean | bigint | Date;

type GetDirtyPaths<O extends DTO, TArrayIndexes extends number> = {
  [K in keyof O]-?: NonNullable<O[K]> extends Array<infer A>
    ? NonNullable<A> extends LiteralType
      ? K
      : K extends string
      ? GetDirtyPaths<NonNullable<A>, TArrayIndexes> extends infer NK
        ? NK extends string
          ? `${K}.${TArrayIndexes}.${NK}`
          : never
        : never
      : never
    : NonNullable<O[K]> extends LiteralType
    ? K
    : K extends string
    ? GetDirtyPaths<NonNullable<O[K]>, TArrayIndexes> extends infer NK
      ? NK extends string
        ? `${K}.${NK}`
        : never
      : never
    : never;
}[keyof O];
type AllPaths = string | number | symbol;
type TrashPaths = `${string}.undefined` | number | symbol;
type ExcludeTrashPaths<O extends AllPaths> = O extends TrashPaths ? never : O;

export type GetPaths<O extends DTO, TArrayIndexes extends number = number> = ExcludeTrashPaths<
  GetDirtyPaths<O, TArrayIndexes>
>;

export type CargoTradeType = 'import' | 'export';

// https://gist.github.com/esamattis/70e9c780e08937cb0b016e04a7422010
type NotNill<T> = T extends null | undefined ? never : T;

type Primitive = undefined | null | boolean | string | number | ((...args: unknown[]) => unknown);

export type DeepRequired<T> = T extends Primitive
  ? NotNill<T>
  : {
      [P in keyof T]-?: T[P] extends Array<infer U>
        ? Array<DeepRequired<U>>
        : T[P] extends ReadonlyArray<infer U2>
        ? Array<DeepRequired<U2>>
        : DeepRequired<T[P]>;
    };

export type GetInputProps<TForm> = (name: GetPaths<TForm>) => ReturnType<MantineGetInputProps<unknown>>;
export type ClearInput<TForm> = (name: GetPaths<TForm>) => void;
export type SetFieldValue<TForm> = (name: GetPaths<TForm>, value: string | number | boolean | object) => void;

export enum ModalType {
  CANCEL = 'CANCEL',
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
  REQUEST_EXTENSION = 'REQUEST_EXTENSION',
  EXTEND = 'EXTEND',
}

export enum CompanyStatus {
  KYC_VERIFIED = 'KYC_VERIFIED',
  UNKNOWN = 'UNKNOWN',
  UNVERIFIED = 'UNVERIFIED',
  SHORTLISTED = 'SHORTLISTED',
  PRIVATE = 'PRIVATE',
  PASSIVE = 'PASSIVE',
  ONBOARDING = 'ONBOARDING',
}

export enum MyCompanies {
  AVAILABLE_COMPANIES = 'AVAILABLE_COMPANIES',
  MY_NETWORK = 'MY_NETWORK',
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type WorkflowTemplateScope = (typeof WorkflowTemplateScope)[keyof typeof WorkflowTemplateScope];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkflowTemplateScope = {
  GLOBAL: 'GLOBAL',
  WORKFLOW: 'WORKFLOW',
} as const;

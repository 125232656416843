/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type InvitationStatus = (typeof InvitationStatus)[keyof typeof InvitationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvitationStatus = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
} as const;

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoNegotiationExpiryExtendRequestStatus =
  (typeof CargoNegotiationExpiryExtendRequestStatus)[keyof typeof CargoNegotiationExpiryExtendRequestStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoNegotiationExpiryExtendRequestStatus = {
  REQUESTED: 'REQUESTED',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
} as const;

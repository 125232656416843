/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoTenderFreightConditionsDTOExclusionFromLayTimeItem =
  (typeof CargoTenderFreightConditionsDTOExclusionFromLayTimeItem)[keyof typeof CargoTenderFreightConditionsDTOExclusionFromLayTimeItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoTenderFreightConditionsDTOExclusionFromLayTimeItem = {
  INITIAL_DRAFT_SURVEY: 'INITIAL_DRAFT_SURVEY',
  INTERMEDIARY_DRAFT_SURVEY: 'INTERMEDIARY_DRAFT_SURVEY',
  FINAL_DRAFT_SURVEY: 'FINAL_DRAFT_SURVEY',
  FIRST_SHIFTING: 'FIRST_SHIFTING',
  INTERMEDIARY_SHIFTING: 'INTERMEDIARY_SHIFTING',
  OPENING_OF_HATCHES: 'OPENING_OF_HATCHES',
  WARPING: 'WARPING',
  SWELL: 'SWELL',
  CUSTOM_CLEARANCE: 'CUSTOM_CLEARANCE',
  OTHERS: 'OTHERS',
} as const;

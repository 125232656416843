import { Stack, Image, ImageProps } from '@mantine/core';

import { Text } from '@/components';

export interface ImageWithCaptionProps extends ImageProps {
  caption: string;
}

export function ImageWithCaption(props: ImageWithCaptionProps) {
  const { caption, src, alt, ...rest } = props;

  return (
    <Stack spacing={8}>
      <Image radius="md" src={src} alt={alt} {...rest} />
      <Text size="S" color="gray.7" align="center">
        {caption}
      </Text>
    </Stack>
  );
}

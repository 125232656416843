/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoTenderContractGroupCargoAction =
  (typeof CargoTenderContractGroupCargoAction)[keyof typeof CargoTenderContractGroupCargoAction];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoTenderContractGroupCargoAction = {
  ACCEPT: 'ACCEPT',
  REJECT: 'REJECT',
  DECLARE: 'DECLARE',
} as const;

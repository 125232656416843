import { createPortal } from 'react-dom';
import { useTranslation } from 'next-i18next';
import { showNotification } from '@mantine/notifications';
import { useState, useCallback, ReactElement } from 'react';
import { useCancelCargoNeedV2, useCancelCargoOfferV2, useCancelCargoPromotion } from 'api/generated/cargos/cargos';
import { CreatorCancelPromotedCargoModal } from 'v2/components/shared/modals/CreatorCancelPromotedCargoModal/CreatorCancelPromotedCargoModal';
import { RecipientCancelPromotedCargoModal } from 'v2/components/shared/modals/RecipientCancelPromotedCargoModal/RecipientCancelPromotedCargoModal';

type ToggleModalParams = {
  cargoId: string;
  isCreator: boolean;
  cargoShortId: string;
  cargoType: 'NEED' | 'OFFER';
};

interface UseCancelPromotedCargoModalParams {
  onCancelSuccess?: VoidFunction;
}

type UseCancelPromotedCargoModalReturn = {
  Modal: () => ReactElement | null;
  toggleModal: (params: ToggleModalParams) => void;
};

export const useCancelPromotedCargoModal = ({
  onCancelSuccess,
}: UseCancelPromotedCargoModalParams): UseCancelPromotedCargoModalReturn => {
  const [modalOpened, setModalOpened] = useState(false);
  const [modalParams, setModalParams] = useState<ToggleModalParams | null>(null);

  const { t } = useTranslation('common');

  const onMutationsSuccess = () => {
    setModalOpened(false);
    showNotification({
      color: 'green',
      message: t('notifications.cargos.cargoCancelled', { shortId: modalParams?.cargoShortId }),
    });

    if (onCancelSuccess) {
      onCancelSuccess();
    }
  };

  const { mutate: mutateCancelCargoPromotion } = useCancelCargoPromotion({
    mutation: {
      onSuccess: onMutationsSuccess,
    },
  });

  const { mutate: mutateCancelCargoNeed } = useCancelCargoNeedV2({
    mutation: {
      onSuccess: onMutationsSuccess,
    },
  });

  const { mutate: mutateCancelCargoOffer } = useCancelCargoOfferV2({
    mutation: {
      onSuccess: onMutationsSuccess,
    },
  });

  const toggleModal = useCallback(
    ({ cargoId, isCreator, cargoType, cargoShortId }: ToggleModalParams) => {
      setModalParams({ cargoId, isCreator, cargoType, cargoShortId });
      setModalOpened(!modalOpened);
    },
    [modalOpened]
  );

  const handleClose = () => {
    setModalOpened(false);
  };

  const handleRecipientConfirm = () => {
    if (!modalParams) return;
    mutateCancelCargoPromotion({ cargoId: modalParams.cargoId });
  };

  const handleCreatorConfirm = ({ reason, otherReason }: { reason: string; otherReason: string }) => {
    if (!modalParams) return;

    const { cargoId, cargoType } = modalParams;

    switch (cargoType) {
      case 'NEED':
        mutateCancelCargoNeed({ cargoId, data: { reason, message: otherReason } });
        break;
      case 'OFFER':
        mutateCancelCargoOffer({ cargoId, data: { reason, message: otherReason } });
        break;
    }
  };

  const Modal = useCallback(() => {
    if (!modalOpened || !modalParams) return null;

    const { isCreator } = modalParams;

    if (isCreator) {
      return createPortal(
        <CreatorCancelPromotedCargoModal opened={modalOpened} onClose={handleClose} onConfirm={handleCreatorConfirm} />,
        document.body
      );
    } else {
      return createPortal(
        <RecipientCancelPromotedCargoModal
          opened={modalOpened}
          onClose={handleClose}
          onConfirm={handleRecipientConfirm}
        />,
        document.body
      );
    }
  }, [modalOpened, modalParams]);

  return { Modal, toggleModal };
};

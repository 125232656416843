import { Accordion, Group, Skeleton, Stack } from '@mantine/core';

export function RecapFormSkeleton() {
  return (
    <Stack>
      <Group spacing={24} mt={32}>
        <Skeleton width={72} height={72} radius="md" animate />
        <Skeleton width={300} height={30} animate />
      </Group>
      <Accordion variant="separated" defaultValue={['first', 'second']} mt={40} multiple>
        <Accordion.Item value="first">
          <Accordion.Control>
            <Skeleton width={150} height={18} animate />
          </Accordion.Control>
          <Accordion.Panel>
            <Group grow>
              <Stack>
                <Skeleton width={50} height={16} animate />
                <Skeleton width={'100%'} height={48} animate />
              </Stack>
              <Stack>
                <Skeleton width={100} height={16} animate />
                <Skeleton width={'100%'} height={48} animate />
              </Stack>
            </Group>
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="second">
          <Accordion.Control>
            <Skeleton width={150} height={18} animate />
          </Accordion.Control>
          <Accordion.Panel>
            <Stack>
              {[...Array(10)].map((x, i) => (
                <Group grow key={i}>
                  <Stack>
                    <Skeleton width={50} height={16} animate />
                    <Skeleton width={'100%'} height={48} animate />
                  </Stack>
                  <Stack>
                    <Skeleton width={100} height={16} animate />
                    <Skeleton width={'100%'} height={48} animate />
                  </Stack>
                </Group>
              ))}
            </Stack>
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="third">
          <Accordion.Control>
            <Skeleton width={150} height={18} animate />
          </Accordion.Control>
        </Accordion.Item>
        <Accordion.Item value="fourth">
          <Accordion.Control>
            <Skeleton width={150} height={18} animate />
          </Accordion.Control>
        </Accordion.Item>
        <Accordion.Item value="fifth">
          <Accordion.Control>
            <Skeleton width={150} height={18} animate />
          </Accordion.Control>
        </Accordion.Item>
      </Accordion>
    </Stack>
  );
}

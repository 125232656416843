import { z } from 'zod';

import { cargoTenderNegotiationFormPreagreementSchema } from './cargo-tender-negotiation-preagreement';
import { cargoTenderNegotiationFormGroupSchema } from './cargo-tender-negotiation-group';
import { cargoTenderNegotiationGeneralConditionsSchema } from './cargo-tender-negotiation-general-conditions';

export const cargoTenderNegotiationSchema = z.object({
  preagreement: cargoTenderNegotiationFormPreagreementSchema,
  groups: z.array(cargoTenderNegotiationFormGroupSchema),
  generalConditions: cargoTenderNegotiationGeneralConditionsSchema,
});

export type CargoTenderNegotiationForm = z.infer<typeof cargoTenderNegotiationSchema>;

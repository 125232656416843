import { ProductSpecificationDTO } from 'api/generated/model/productSpecificationDTO';
import { useTranslation } from 'next-i18next';
import { memo } from 'react';

import { DownloadFile, InlineError, Text } from '@/components';

import { useHandleSpecificationErrors, useSpecificationDownloadUrl } from './hooks';

export interface ProductSpecificationProps extends ProductSpecificationDTO {
  className?: string;
}

export function ProductSpecification({ specificationId, specificationFileName, className }: ProductSpecificationProps) {
  const { t } = useTranslation('common');
  const { handleSpecificationErrors } = useHandleSpecificationErrors();

  const { error, getUrl } = useSpecificationDownloadUrl(specificationId);

  if (!specificationId) {
    return (
      <Text color="gray.7" italic size="S" className={className}>
        {t('productSpecification.noFile')}
      </Text>
    );
  }

  if (error) {
    return <InlineError>{handleSpecificationErrors(error)}</InlineError>;
  }

  return <DownloadFile filename={specificationFileName} url={getUrl} className={className} fileKey={specificationId} />;
}

export const ProductSpecificationMemoed = memo(ProductSpecification);

import { Accordion, Box } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { useDisclosure } from '@mantine/hooks';

import {
  FreightRecapFormCommentsGroup,
  FreightRecapFormFooter,
  FreightRecapFormFreightGroup,
  FreightRecapFormNominationGroup,
  FreightRecapFormPaymentGroup,
  FreightRecapFormPricingGroup,
  FreightRecapFormProductGroup,
  FreightRecapFormVesselGroup,
  FreightRecapPreview,
  useFreightRecapFormState,
  FreightRecapFormPreviewTitle,
  FreightRecapFormPreviewActions,
} from '@/views/freight-recap-form';
import { FreightRecapForm, FreightRequestForm } from '@/blink-forms';
import { useScrollToFirstError } from '@/common/use-scroll-to-first-error';
import { RecapPreviewModal } from '@/views/recap-form';

interface FreightRecapFormContainerProps {
  isSubmitting: boolean;
  isDraftSaving: boolean;
  requestForm: FreightRequestForm;
  prevForm?: FreightRecapForm;
  onSubmit: (values: FreightRecapForm) => void;
  onSaveDraft?: VoidFunction;
  onSaveTemplate: VoidFunction;
}

export function FreightRecapFormContainer(props: FreightRecapFormContainerProps) {
  const { isSubmitting, isDraftSaving, requestForm, prevForm, onSubmit, onSaveDraft, onSaveTemplate } = props;
  const [previewModalOpened, { open: openPreviewModal, close: closePreviewModal }] = useDisclosure(false);
  const { t } = useTranslation('freightRecapForm');
  const { form, companyName } = useFreightRecapFormState();
  const { scrollToFirstError } = useScrollToFirstError();

  return (
    <>
      <form
        id="freight-recap-form"
        noValidate
        onSubmit={form.onSubmit(onSubmit, (errors) => setTimeout(() => scrollToFirstError({ errors }), 0))}
      >
        <Box pb={60}>
          <Accordion
            variant="separated"
            defaultValue={[
              'pricing',
              'product',
              'vesselInformation',
              'loadingConditions',
              'dischargingConditions',
              'nomination',
              'payment',
              'comments',
            ]}
            mt={40}
            multiple
            radius="md"
          >
            <Accordion.Item value="pricing">
              <Accordion.Control>{t('sections.pricing')}</Accordion.Control>
              <Accordion.Panel>
                <FreightRecapFormPricingGroup />
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="product">
              <Accordion.Control>{t('sections.product')}</Accordion.Control>
              <Accordion.Panel>
                <FreightRecapFormProductGroup />
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="vesselInformation">
              <Accordion.Control>{t('sections.vesselInformation')}</Accordion.Control>
              <Accordion.Panel>
                <FreightRecapFormVesselGroup />
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="loadingConditions">
              <Accordion.Control>{t('sections.loadingConditions')}</Accordion.Control>
              <Accordion.Panel>
                <FreightRecapFormFreightGroup portType="loading" />
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="dischargingConditions">
              <Accordion.Control>{t('sections.dischargingConditions')}</Accordion.Control>
              <Accordion.Panel>
                <FreightRecapFormFreightGroup portType="discharging" />
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="nomination">
              <Accordion.Control>{t('sections.nomination')}</Accordion.Control>
              <Accordion.Panel>
                <FreightRecapFormNominationGroup />
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="payment">
              <Accordion.Control>{t('sections.payment')}</Accordion.Control>
              <Accordion.Panel>
                <FreightRecapFormPaymentGroup />
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="comments">
              <Accordion.Control>{t('sections.comments')}</Accordion.Control>
              <Accordion.Panel>
                <FreightRecapFormCommentsGroup />
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>

          <FreightRecapFormFooter
            isSubmitting={isSubmitting}
            isDraftSaving={isDraftSaving}
            isFormDirty={form.isDirty()}
            onSaveDraft={onSaveDraft}
            onSaveTemplate={onSaveTemplate}
            onPreview={openPreviewModal}
          />
        </Box>
      </form>

      <RecapPreviewModal
        opened={previewModalOpened}
        onClose={closePreviewModal}
        actions={<FreightRecapFormPreviewActions onClose={closePreviewModal} isSubmitting={isSubmitting} />}
        title={<FreightRecapFormPreviewTitle />}
        recapForm={
          <Box p={24} sx={{ overflowY: 'scroll', maxHeight: '100%', flexGrow: 1 }}>
            <FreightRecapPreview
              companyName={companyName}
              currentForm={form.values}
              prevForm={prevForm}
              requestForm={requestForm}
            />
          </Box>
        }
      />
    </>
  );
}

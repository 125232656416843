/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type ChatType = (typeof ChatType)[keyof typeof ChatType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChatType = {
  CARGO_NEGOTIATION: 'CARGO_NEGOTIATION',
  CARGO_WORKFLOW: 'CARGO_WORKFLOW',
  COMPANY_TO_COMPANY: 'COMPANY_TO_COMPANY',
  FREIGHT_NEGOTIATION: 'FREIGHT_NEGOTIATION',
} as const;

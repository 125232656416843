import { DateRangePickerProps } from '@mantine/dates';

import { ThemeComponent } from './types';

export const DateRangePicker: ThemeComponent<DateRangePickerProps> = {
  styles: (_theme, params) => ({
    input: {
      height: 48,

      ...(params.size === 'sm' && {
        height: 40,
      }),
    },
    error: {
      position: 'relative',
      marginTop: 0,
    },
  }),
};

import { ModalProps } from '@mantine/core';

import { ThemeComponent } from './types';

export const Modal: ThemeComponent<ModalProps> = {
  defaultProps: {
    overflow: 'inside',
  },
  styles: {
    root: {
      top: 80,
      maxHeight: 'calc(100vh - 104px)',
    },
    inner: {
      padding: '0 16px',
    },
    body: {
      paddingBottom: 4,
    },
    close: {
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
};

import { Flex, NumberInput } from '@mantine/core';
import { Trans, useTranslation } from 'next-i18next';

import { GetInputProps } from '@/common/types';
import { PercentageField } from '@/components';
import { getThousandSeparatedNumberProps } from '@/common/get-thousand-seperated-number';

interface CashAgainstDocumentsGroupProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getInputProps: GetInputProps<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  percentagePath: string;
  daysPath: string;
  numberOfCopiesPath: string;
}

export function CashAgainstDocuments({
  getInputProps,
  percentagePath,
  daysPath,
  numberOfCopiesPath,
}: CashAgainstDocumentsGroupProps) {
  const { t } = useTranslation('tenderNegotiation');

  return (
    <Flex gap="md" justify="flex-start" align="center" direction="row" wrap="wrap" sx={{ paddingTop: 20 }}>
      <Trans
        t={t}
        i18nKey="formSentences.cashAgainstDocuments"
        components={{
          percentage: <PercentageField min={0} max={100} maxLength={3} h={'auto'} {...getInputProps(percentagePath)} />,
          days: <NumberInput h={'auto'} w={56} {...getInputProps(daysPath)} {...getThousandSeparatedNumberProps()} />,
          text: (
            <NumberInput h={'auto'} {...getInputProps(numberOfCopiesPath)} {...getThousandSeparatedNumberProps()} />
          ),
        }}
      />
    </Flex>
  );
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';

import type {
  CompanyWithStatusCommonDTO,
  CompanyWithContactAndProduct,
  CreatePrivateCompanyResponseDto,
  CreatePrivateCompanyRequestDto,
  UpdatePrivateCompanyResponseDto,
  UpdatePrivateCompanyRequestDto,
  UpdateShortlistedCompanyResponseDto,
  UpdateShorlistedCompanyRequestDto,
  CompaniesDTO,
  GetCompaniesParams,
  CompanyDetailsDTO,
  ProductsDTO,
  PortsDTO,
  PortDTO,
  PlantsDTO,
  ShipmentReferenceDTO,
  CompanyFinanceDTO,
  CompanyKycDocumentDTO,
  CompanyTaskListDTO,
  GetCompanyTasksParams,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getCurrentCompany = (options?: AxiosRequestConfig): Promise<AxiosResponse<CompanyWithStatusCommonDTO>> => {
  return axios.get(`/api/v2/companies/current-company`, options);
};

export const getGetCurrentCompanyQueryKey = () => [`/api/v2/companies/current-company`];

export type GetCurrentCompanyQueryResult = NonNullable<Awaited<ReturnType<typeof getCurrentCompany>>>;
export type GetCurrentCompanyQueryError = AxiosError<void>;

export const useGetCurrentCompany = <
  TData = Awaited<ReturnType<typeof getCurrentCompany>>,
  TError = AxiosError<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getCurrentCompany>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCurrentCompanyQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCurrentCompany>>> = ({ signal }) =>
    getCurrentCompany({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCurrentCompany>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getCompaniesWithContacts = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CompanyWithContactAndProduct[]>> => {
  return axios.get(`/api/v2/companies`, options);
};

export const getGetCompaniesWithContactsQueryKey = () => [`/api/v2/companies`];

export type GetCompaniesWithContactsQueryResult = NonNullable<Awaited<ReturnType<typeof getCompaniesWithContacts>>>;
export type GetCompaniesWithContactsQueryError = AxiosError<void>;

export const useGetCompaniesWithContacts = <
  TData = Awaited<ReturnType<typeof getCompaniesWithContacts>>,
  TError = AxiosError<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getCompaniesWithContacts>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCompaniesWithContactsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompaniesWithContacts>>> = ({ signal }) =>
    getCompaniesWithContacts({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCompaniesWithContacts>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const createPrivateCompany = (
  createPrivateCompanyRequestDto: CreatePrivateCompanyRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CreatePrivateCompanyResponseDto>> => {
  return axios.post(`/api/v2/companies/private`, createPrivateCompanyRequestDto, options);
};

export type CreatePrivateCompanyMutationResult = NonNullable<Awaited<ReturnType<typeof createPrivateCompany>>>;
export type CreatePrivateCompanyMutationBody = CreatePrivateCompanyRequestDto;
export type CreatePrivateCompanyMutationError = AxiosError<void>;

export const useCreatePrivateCompany = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPrivateCompany>>,
    TError,
    { data: CreatePrivateCompanyRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPrivateCompany>>,
    { data: CreatePrivateCompanyRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return createPrivateCompany(data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof createPrivateCompany>>,
    TError,
    { data: CreatePrivateCompanyRequestDto },
    TContext
  >(mutationFn, mutationOptions);
};
export const deletePrivateCompanyController = (
  id: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/v2/companies/private/${id}`, options);
};

export type DeletePrivateCompanyControllerMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePrivateCompanyController>>
>;

export type DeletePrivateCompanyControllerMutationError = AxiosError<unknown>;

export const useDeletePrivateCompanyController = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePrivateCompanyController>>,
    TError,
    { id: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePrivateCompanyController>>, { id: string }> = (
    props
  ) => {
    const { id } = props ?? {};

    return deletePrivateCompanyController(id, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof deletePrivateCompanyController>>, TError, { id: string }, TContext>(
    mutationFn,
    mutationOptions
  );
};
export const updatePrivateCompany = (
  id: string,
  updatePrivateCompanyRequestDto: UpdatePrivateCompanyRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<UpdatePrivateCompanyResponseDto>> => {
  return axios.put(`/api/v2/companies/private/${id}`, updatePrivateCompanyRequestDto, options);
};

export type UpdatePrivateCompanyMutationResult = NonNullable<Awaited<ReturnType<typeof updatePrivateCompany>>>;
export type UpdatePrivateCompanyMutationBody = UpdatePrivateCompanyRequestDto;
export type UpdatePrivateCompanyMutationError = AxiosError<void>;

export const useUpdatePrivateCompany = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePrivateCompany>>,
    TError,
    { id: string; data: UpdatePrivateCompanyRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePrivateCompany>>,
    { id: string; data: UpdatePrivateCompanyRequestDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return updatePrivateCompany(id, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof updatePrivateCompany>>,
    TError,
    { id: string; data: UpdatePrivateCompanyRequestDto },
    TContext
  >(mutationFn, mutationOptions);
};
export const updateShorlistedCompany = (
  id: string,
  updateShorlistedCompanyRequestDto: UpdateShorlistedCompanyRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<UpdateShortlistedCompanyResponseDto>> => {
  return axios.put(`/api/v2/companies/shortlisted/${id}`, updateShorlistedCompanyRequestDto, options);
};

export type UpdateShorlistedCompanyMutationResult = NonNullable<Awaited<ReturnType<typeof updateShorlistedCompany>>>;
export type UpdateShorlistedCompanyMutationBody = UpdateShorlistedCompanyRequestDto;
export type UpdateShorlistedCompanyMutationError = AxiosError<void>;

export const useUpdateShorlistedCompany = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateShorlistedCompany>>,
    TError,
    { id: string; data: UpdateShorlistedCompanyRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateShorlistedCompany>>,
    { id: string; data: UpdateShorlistedCompanyRequestDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateShorlistedCompany(id, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateShorlistedCompany>>,
    TError,
    { id: string; data: UpdateShorlistedCompanyRequestDto },
    TContext
  >(mutationFn, mutationOptions);
};
export const getCompanyWithContactsAndProducts = (
  companyId: unknown,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CompanyWithContactAndProduct>> => {
  return axios.get(`/api/v2/companies/${companyId}`, options);
};

export const getGetCompanyWithContactsAndProductsQueryKey = (companyId: unknown) => [`/api/v2/companies/${companyId}`];

export type GetCompanyWithContactsAndProductsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompanyWithContactsAndProducts>>
>;
export type GetCompanyWithContactsAndProductsQueryError = AxiosError<void>;

export const useGetCompanyWithContactsAndProducts = <
  TData = Awaited<ReturnType<typeof getCompanyWithContactsAndProducts>>,
  TError = AxiosError<void>
>(
  companyId: unknown,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCompanyWithContactsAndProducts>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCompanyWithContactsAndProductsQueryKey(companyId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyWithContactsAndProducts>>> = ({ signal }) =>
    getCompanyWithContactsAndProducts(companyId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCompanyWithContactsAndProducts>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!companyId, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getCompanies = (
  params?: GetCompaniesParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CompaniesDTO>> => {
  return axios.get(`/api/v1/companies`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetCompaniesQueryKey = (params?: GetCompaniesParams) => [
  `/api/v1/companies`,
  ...(params ? [params] : []),
];

export type GetCompaniesQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanies>>>;
export type GetCompaniesQueryError = AxiosError<void>;

export const useGetCompanies = <TData = Awaited<ReturnType<typeof getCompanies>>, TError = AxiosError<void>>(
  params?: GetCompaniesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCompanies>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCompaniesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanies>>> = ({ signal }) =>
    getCompanies(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCompanies>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getCompany = (id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<CompanyDetailsDTO>> => {
  return axios.get(`/api/v1/companies/${id}`, options);
};

export const getGetCompanyQueryKey = (id: string) => [`/api/v1/companies/${id}`];

export type GetCompanyQueryResult = NonNullable<Awaited<ReturnType<typeof getCompany>>>;
export type GetCompanyQueryError = AxiosError<void>;

export const useGetCompany = <TData = Awaited<ReturnType<typeof getCompany>>, TError = AxiosError<void>>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCompany>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCompanyQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompany>>> = ({ signal }) =>
    getCompany(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCompany>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getCompanyProducts = (id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ProductsDTO>> => {
  return axios.get(`/api/v1/companies/${id}/products`, options);
};

export const getGetCompanyProductsQueryKey = (id: string) => [`/api/v1/companies/${id}/products`];

export type GetCompanyProductsQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyProducts>>>;
export type GetCompanyProductsQueryError = AxiosError<void>;

export const useGetCompanyProducts = <
  TData = Awaited<ReturnType<typeof getCompanyProducts>>,
  TError = AxiosError<void>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCompanyProducts>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCompanyProductsQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyProducts>>> = ({ signal }) =>
    getCompanyProducts(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCompanyProducts>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getCompanyPorts = (id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PortsDTO>> => {
  return axios.get(`/api/v1/companies/${id}/ports`, options);
};

export const getGetCompanyPortsQueryKey = (id: string) => [`/api/v1/companies/${id}/ports`];

export type GetCompanyPortsQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyPorts>>>;
export type GetCompanyPortsQueryError = AxiosError<void>;

export const useGetCompanyPorts = <TData = Awaited<ReturnType<typeof getCompanyPorts>>, TError = AxiosError<void>>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCompanyPorts>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCompanyPortsQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyPorts>>> = ({ signal }) =>
    getCompanyPorts(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCompanyPorts>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getCompanyPort = (
  id: string,
  standardPortId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<PortDTO>> => {
  return axios.get(`/api/v1/companies/${id}/ports/${standardPortId}`, options);
};

export const getGetCompanyPortQueryKey = (id: string, standardPortId: string) => [
  `/api/v1/companies/${id}/ports/${standardPortId}`,
];

export type GetCompanyPortQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyPort>>>;
export type GetCompanyPortQueryError = AxiosError<void>;

export const useGetCompanyPort = <TData = Awaited<ReturnType<typeof getCompanyPort>>, TError = AxiosError<void>>(
  id: string,
  standardPortId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCompanyPort>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCompanyPortQueryKey(id, standardPortId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyPort>>> = ({ signal }) =>
    getCompanyPort(id, standardPortId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCompanyPort>>, TError, TData>(queryKey, queryFn, {
    enabled: !!(id && standardPortId),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getCompanyPlants = (id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PlantsDTO>> => {
  return axios.get(`/api/v1/companies/${id}/plants`, options);
};

export const getGetCompanyPlantsQueryKey = (id: string) => [`/api/v1/companies/${id}/plants`];

export type GetCompanyPlantsQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyPlants>>>;
export type GetCompanyPlantsQueryError = AxiosError<void>;

export const useGetCompanyPlants = <TData = Awaited<ReturnType<typeof getCompanyPlants>>, TError = AxiosError<void>>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCompanyPlants>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCompanyPlantsQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyPlants>>> = ({ signal }) =>
    getCompanyPlants(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCompanyPlants>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getCompanyShipmentReferences = (
  id: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ShipmentReferenceDTO[]>> => {
  return axios.get(`/api/v1/companies/${id}/shipment-references`, options);
};

export const getGetCompanyShipmentReferencesQueryKey = (id: string) => [`/api/v1/companies/${id}/shipment-references`];

export type GetCompanyShipmentReferencesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompanyShipmentReferences>>
>;
export type GetCompanyShipmentReferencesQueryError = AxiosError<void>;

export const useGetCompanyShipmentReferences = <
  TData = Awaited<ReturnType<typeof getCompanyShipmentReferences>>,
  TError = AxiosError<void>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCompanyShipmentReferences>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCompanyShipmentReferencesQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyShipmentReferences>>> = ({ signal }) =>
    getCompanyShipmentReferences(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCompanyShipmentReferences>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getCompanyFinancials = (
  id: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CompanyFinanceDTO[]>> => {
  return axios.get(`/api/v1/companies/${id}/financials`, options);
};

export const getGetCompanyFinancialsQueryKey = (id: string) => [`/api/v1/companies/${id}/financials`];

export type GetCompanyFinancialsQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyFinancials>>>;
export type GetCompanyFinancialsQueryError = AxiosError<void>;

export const useGetCompanyFinancials = <
  TData = Awaited<ReturnType<typeof getCompanyFinancials>>,
  TError = AxiosError<void>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCompanyFinancials>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCompanyFinancialsQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyFinancials>>> = ({ signal }) =>
    getCompanyFinancials(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCompanyFinancials>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getCompanyKycDocument = (
  id: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CompanyKycDocumentDTO>> => {
  return axios.post(`/api/v1/companies/${id}/kyc`, undefined, options);
};

export type GetCompanyKycDocumentMutationResult = NonNullable<Awaited<ReturnType<typeof getCompanyKycDocument>>>;

export type GetCompanyKycDocumentMutationError = AxiosError<void>;

export const useGetCompanyKycDocument = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof getCompanyKycDocument>>, TError, { id: string }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof getCompanyKycDocument>>, { id: string }> = (props) => {
    const { id } = props ?? {};

    return getCompanyKycDocument(id, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof getCompanyKycDocument>>, TError, { id: string }, TContext>(
    mutationFn,
    mutationOptions
  );
};
export const getCompanyTasks = (
  id: string,
  params?: GetCompanyTasksParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CompanyTaskListDTO>> => {
  return axios.get(`/api/v1/companies/${id}/tasks`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetCompanyTasksQueryKey = (id: string, params?: GetCompanyTasksParams) => [
  `/api/v1/companies/${id}/tasks`,
  ...(params ? [params] : []),
];

export type GetCompanyTasksQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyTasks>>>;
export type GetCompanyTasksQueryError = AxiosError<void>;

export const useGetCompanyTasks = <TData = Awaited<ReturnType<typeof getCompanyTasks>>, TError = AxiosError<void>>(
  id: string,
  params?: GetCompanyTasksParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCompanyTasks>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCompanyTasksQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyTasks>>> = ({ signal }) =>
    getCompanyTasks(id, params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCompanyTasks>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

import { ReactNode, useEffect, useRef } from 'react';

import { useReplacableContext } from './ReplacableContext';

interface ReplacableElementProps {
  children: ReactNode;
  elementKey: string;
  debug?: boolean;
}

function displayDebugInfo<T>(elementKey: string, replacement: T) {
  console.groupCollapsed(`%cReplacableElement: ${elementKey}`, 'color: orange');
  console.log(replacement);
  console.groupEnd();
}

/**
 * ReplacableElement - this component is used to wrap elements that can be replaced by other components.
 * It is used in conjunction with the `ReplacableContext` and `ReplacableProvider`.
 *
 * Rules:
 *  - to make it work you must wrap your subtree with the `ReplacableProvider`.
 *  - `elementKey` must be unique across choosen subtree application.
 *  - you don't have to replace all elements, it's optional.
 *
 * Usage:
 * ```tsx
 * <ReplacableProvider replacements={{ "myElementKey": <MyNewComponent /> }}>
 *    (...)
 *    <ReplacableElement elementKey="myElementKey">
 *      <MyComponent />
 *    </ReplacableElement>
 *   (...)
 * </ReplacableProvider>
 * ```
 * `MyComponent` with `ReplacableElement` can be used in deep nested components.
 */
export function ReplacableElement({ children, elementKey, debug }: ReplacableElementProps) {
  const { setElement, isElementReplaced, getDebugInfo } = useReplacableContext();
  const elRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setElement(elementKey, elRef);

    if (debug) {
      const { replacement } = getDebugInfo(elementKey);
      displayDebugInfo(elementKey, replacement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on mount
  }, []);

  return isElementReplaced(elementKey) ? <div ref={elRef} /> : <>{children}</>;
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoTenderNegotiationStatusParticipationRequested =
  (typeof CargoTenderNegotiationStatusParticipationRequested)[keyof typeof CargoTenderNegotiationStatusParticipationRequested];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoTenderNegotiationStatusParticipationRequested = {
  PARTICIPATION_REQUESTED: 'PARTICIPATION_REQUESTED',
} as const;

import { CellContext, ColumnDefTemplate } from '@tanstack/react-table';
import { useMantineTheme } from '@mantine/core';

import { Text } from '@/components';
import { truncateString } from '@/core/helpers/truncateString';
import { useMediaQuery } from '@/core/hooks/use-media-query';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CargoIdCell: ColumnDefTemplate<CellContext<any, string>> = ({ getValue }) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(theme.breakpoints.sm);

  return (
    <Text size="M" color="gray.8" weight={600} sx={{ whiteSpace: 'normal' }}>
      {isMobile ? truncateString(getValue(), 14) : getValue()}
    </Text>
  );
};

import { Button, Container, createStyles, Flex, Modal, Stack, ThemeIcon } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { RiErrorWarningFill } from 'react-icons/ri';

import { NeedHelp, Text, Title } from '@/components';
import { TextInput } from '@/views/recap-form';

const useStyles = createStyles((theme) => ({
  title: {
    marginBottom: 8,

    [theme.fn.smallerThan('xs')]: {
      marginBottom: 16,
    },
  },
  paper: {
    backgroundColor: theme.white,
    borderRadius: theme.radius.md,
    maxWidth: 604,
    width: '100%',
    padding: 24,

    [theme.fn.smallerThan('md')]: {
      padding: 16,
    },
  },
  container: {
    width: '100%',
  },
  text: {
    color: theme.colors.gray[7],
  },
  anchor: {
    fontWeight: 600,
    paddingBottom: 4,
    borderBottom: '1px solid',
  },
  helpText: {
    alignSelf: 'flex-end',
    color: theme.colors.gray[7],
  },
  icon: {
    backgroundColor: '#FFF4E6',
    color: '#D9480F',
    fontSize: 20,
  },
  buttons: {
    gap: 8,
    [theme.fn.smallerThan('md')]: {
      flexDirection: 'column',
    },
  },
  stack: {
    gap: 24,

    [theme.fn.smallerThan('xs')]: {
      gap: 20,
    },
  },
  label: {
    fontWeight: 600,
  },
}));

export interface SessionEndWarningCardProps {
  email: string;
  opened: boolean;
  onBackClick: VoidFunction;
  onNextClick: VoidFunction;
}

export function SessionEndWarningCard(props: SessionEndWarningCardProps) {
  const { t } = useTranslation('register');
  const { email, onBackClick, onNextClick, opened } = props;
  const { classes } = useStyles();

  return (
    <Modal opened={opened} onClose={onBackClick} centered={true} size={600}>
      <Stack className={classes.stack} align={'center'}>
        <ThemeIcon className={classes.icon} size={40} radius={8}>
          <RiErrorWarningFill />
        </ThemeIcon>
        <Title className={classes.title} order={1} size="XL" align={'center'} color="gray.8" weight={600}>
          {t('warning.header')}
        </Title>
        <Container className={classes.container}>
          <Stack spacing={16}>
            <Text className={classes.text} align={'center'}>
              {t('warning.paragraph')}
            </Text>
            <TextInput
              label={t('warning.input')}
              disabled
              value={email}
              withAsterisk={true}
              classNames={{ label: classes.label }}
            />
            <Flex className={classes.buttons}>
              <Button fullWidth onClick={() => onBackClick()} variant="outline">
                {t('warning.actions.later')}
              </Button>
              <Button fullWidth onClick={() => onNextClick()}>
                {t('warning.actions.continue')}
              </Button>
            </Flex>
            <NeedHelp />
          </Stack>
        </Container>
      </Stack>
    </Modal>
  );
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type Type = (typeof Type)[keyof typeof Type];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Type = {
  USER: 'USER',
  FILE: 'FILE',
} as const;

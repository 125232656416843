import { CellContext, ColumnDefTemplate } from '@tanstack/react-table';

import { Text } from '@/components';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BlueHighlightCell: ColumnDefTemplate<CellContext<any, string>> = ({ getValue }) => {
  return (
    <Text size="M" weight={600} color={'deepBlue'}>
      {getValue()}
    </Text>
  );
};

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type TransactionSide = (typeof TransactionSide)[keyof typeof TransactionSide];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionSide = {
  BUYER: 'BUYER',
  SELLER: 'SELLER',
} as const;

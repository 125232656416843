import { Button, Stack, TextInput } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { z, ZodError } from 'zod';
import { useQueryClient } from '@tanstack/react-query';
import {
  getGetBookedCargosQueryKey,
  getGetSelectedCargosWorkflowGlobalDateQueryKey,
  getGetV2BookedAndArchivedCargosQueryKey,
  useUpdateExternalRefId,
} from 'api/generated/workflow/workflow';
import { GetSelectedCargosWorkflowGlobalDateParams } from 'api/generated/model';

import { BaseModal, Text } from '@/components';

export function AddExternalRefModal({
  isOpen,
  close,
  workflowId,
}: {
  isOpen: boolean;
  close: () => void;
  workflowId: string;
}) {
  const { t } = useTranslation('myCargos');
  const [error, setError] = useState<string | undefined>();
  const [externalRefId, setExternalRefId] = useState<string | undefined>();

  const validateAndSet = (value: string) => {
    const { parse } = z.string();
    try {
      parse(value);
      setExternalRefId(value);
    } catch (error) {
      setError((error as ZodError).errors.map((e) => e.message).toString());
    }
  };

  const updateExternalRefId = useHandleUpdateExternalRefId(workflowId, close);

  return (
    <BaseModal
      opened={isOpen}
      header={<Text>{t('modals.addExternalRefId.header')}</Text>}
      close={close}
      content={
        <Stack>
          <Text>{t('modals.addExternalRefId.description')}</Text>
          <TextInput
            label={t('modals.addExternalRefId.label')}
            placeholder={t('modals.addExternalRefId.placeholder')}
            withAsterisk={false}
            onChange={(e) => validateAndSet(e.currentTarget.value)}
            error={error}
          />
          <Button onClick={() => updateExternalRefId(externalRefId)}>{t('modals.addExternalRefId.button')}</Button>
        </Stack>
      }
    />
  );
}

function useHandleUpdateExternalRefId(workflowId: string, closeModal: () => void) {
  const { t } = useTranslation('myCargos');
  const { mutate } = useUpdateExternalRefId();
  const queryClient = useQueryClient();

  return (externalRefId: string) =>
    mutate(
      { workflowId, data: { externalRefId } },
      {
        onSuccess: () => {
          closeModal();
          queryClient.invalidateQueries(getGetBookedCargosQueryKey());
          queryClient.invalidateQueries(getGetV2BookedAndArchivedCargosQueryKey());
          queryClient.invalidateQueries(
            getGetSelectedCargosWorkflowGlobalDateQueryKey({
              cargoIds: workflowId,
            } as GetSelectedCargosWorkflowGlobalDateParams)
          );
          showNotification({
            disallowClose: false,
            autoClose: 2000,
            title: t('modals.addExternalRefId.notifications.success'),
            color: 'green',
          });
        },
        onError: () => {
          closeModal();
          showNotification({
            disallowClose: false,
            autoClose: 2000,
            title: t('modals.addExternalRefId.notifications.error'),
            color: 'red',
          });
        },
      }
    );
}

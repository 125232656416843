/* eslint-disable @typescript-eslint/no-explicit-any */
import { showNotification } from '@mantine/notifications';
import { ApiErrorResponse } from 'api/generated/model';
import { AxiosError } from 'axios';

type ApiErrorWithMessage = { message: string };

export interface HttpErrorResponse {
  name?: string;
  message?: string;
  status: number;
}

export const isAxiosError = (e: any): e is AxiosError<unknown> =>
  Boolean(e.isAxiosError) && typeof e.config === 'object';

export const hasErrorCode = (status: number) => (error: any) =>
  isAxiosError(error) && error.response?.status === status;

export const isServerError = (e: AxiosError) => {
  if (!e.response) {
    return false;
  }
  const { status } = e.response;
  return status >= 500;
};

export const isClientError = (e: AxiosError) => {
  if (!e.response) {
    return false;
  }
  const { status } = e.response;
  return status >= 400 && status < 500;
};

export const isRestError = (errorData: unknown): errorData is ApiErrorResponse =>
  !!errorData &&
  typeof errorData === 'object' &&
  typeof (errorData as any).status === 'number' &&
  typeof (errorData as any).code === 'string' &&
  typeof (errorData as any).message === 'string';

export const isRestApiError = (errorData: unknown): errorData is ApiErrorResponse =>
  !!errorData &&
  typeof errorData === 'object' &&
  typeof (errorData as any).statusCode === 'number' &&
  typeof (errorData as any).error === 'string' &&
  typeof (errorData as any).message === 'string';

export const isHttpError = (errorData: unknown): errorData is HttpErrorResponse =>
  !!errorData &&
  typeof errorData === 'object' &&
  typeof (errorData as any).status === 'number' &&
  typeof (errorData as any).name === 'string' &&
  typeof (errorData as any).message === 'string';

export const hasErrorMessage = (errorData: unknown): errorData is ApiErrorWithMessage =>
  typeof errorData === 'object' && !!errorData && typeof (errorData as any).message === 'string';

export const isUniqueCoinstraintError = hasErrorCode(409);

// TODO: Think about how to type errorName, so it can be shared between backend and frontend
export const displayErrorMessage = (error: unknown, errorName: string, message: string) => {
  if (isAxiosError(error) && isHttpError(error.response.data)) {
    if (error.response.data.name === errorName) {
      showNotification({
        color: 'red',
        message: message,
      });
    }
  }
};

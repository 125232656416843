import { differenceInHours, differenceInMinutes } from 'date-fns';
import { useEffect, useState } from 'react';

export const calculateExpireTime = (expiresAt: string) => {
  const currentTime = new Date();
  const expires = new Date(expiresAt);
  let hoursLeft = differenceInHours(expires, currentTime);
  let minutesLeft = differenceInMinutes(expires, currentTime, { roundingMethod: 'ceil' }) - hoursLeft * 60;
  if (minutesLeft === 60) {
    minutesLeft = 0;
    hoursLeft++;
  }
  const expiresSoon = hoursLeft > 0 && hoursLeft < 6;
  const timeLeft = `${hoursLeft < 10 ? `0${hoursLeft}` : hoursLeft}:${
    minutesLeft < 10 ? `0${minutesLeft}` : minutesLeft
  }`;
  const expired = expires < currentTime;

  return {
    expiresSoon,
    expired,
    timeLeft,
  };
};

export const useCalculateExpireTimeInInterval = (expiresAt: string) => {
  const [{ expired, expiresSoon, timeLeft }, setExpireTime] = useState<ReturnType<typeof calculateExpireTime>>(
    calculateExpireTime(expiresAt)
  );
  useEffect(() => {
    setExpireTime(calculateExpireTime(expiresAt));
    const intervalId = setInterval(() => {
      setExpireTime(calculateExpireTime(expiresAt));
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiresAt]);

  return {
    expiresSoon,
    expired,
    timeLeft,
  };
};

import { DemurrageSchema } from './demurrage';

export const DEMURRAGE_INITIAL_VALUES: DemurrageSchema = {
  rate: undefined,
  rateCondition: undefined,
  rateEvent: undefined,
  type: undefined,
  openAccountMaxDays: undefined,
  openAccountMaxDaysEvent: undefined,
};

//Empty values
export const DEMURRAGE_EMPTY_VALUES = {
  rate: undefined,
  rateCondition: null,
  rateEvent: null,
  type: null,
  openAccountMaxDays: undefined,
  openAccountMaxDaysEvent: null,
};

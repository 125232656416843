import { Group, Select, SelectProps } from '@mantine/core';
import { forwardRef, useState } from 'react';

import { Text } from '../typography/Text';

export interface SearchableSelectorOptionProps {
  value: string;
  label: string;
  name?: string;
}

export function useSearchableSelectorOptionsFilter<T extends string>(filterFields: readonly T[]) {
  const getInitialState = () =>
    filterFields.reduce((initialState, field) => ({ ...initialState, [field]: '' }), {} as Record<T, string>);

  const [filter, setFilter] = useState<Record<T, string>>(getInitialState);

  return [
    filter,
    (newValues: Partial<Record<T, string>>) => setFilter((currentFilter) => ({ ...currentFilter, ...newValues })),
  ] as const;
}

function filterOptions(value: string, item: SearchableSelectorOptionProps) {
  const normalizedValue = value.toLowerCase();
  const { name, label } = item;

  return [name, label].map((prop) => prop?.toLowerCase()).some((prop) => prop?.includes(normalizedValue));
}

export const SearchableSelector = (props: SelectProps) => {
  const { ...selectProps } = props;

  return (
    <Select
      itemComponent={SearchableSelector.selectOption}
      searchable
      filter={filterOptions}
      sx={{ base: { height: 'auto', zIndex: 2 }, sm: { height: 'auto', zIndex: 2 } }}
      {...selectProps}
    />
  );
};

SearchableSelector.selectOption = forwardRef<HTMLDivElement, SearchableSelectorOptionProps>(function selectOption(
  props,
  ref
) {
  const { label, ...others } = props;
  return (
    <Group pl={4} pr={4} ref={ref} {...others} data-testid={'dropdown-option'}>
      <Text>{label}</Text>
    </Group>
  );
});

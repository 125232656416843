export enum FreightNegotiationStepStatusLabel {
  RFQ_SENT = 'RFQ_SENT',
  RFQ_RECEIVED = 'RFQ_RECEIVED',
  OFFER_RECEIVED = 'OFFER_RECEIVED',
  OFFER_SENT = 'OFFER_SENT',
  COUNTER_OFFER_RECEIVED = 'COUNTER_OFFER_RECEIVED',
  COUNTER_OFFER_SENT = 'COUNTER_OFFER_SENT',
  REVIEW_RECEIVED = 'REVIEW_RECEIVED',
  REVIEW_SENT = 'REVIEW_SENT',
  CHARTERER_ACCEPTED = 'CHARTERER_ACCEPTED',
  TRADER_ACCEPTED = 'TRADER_ACCEPTED',
  CHARTERER_CANCELED = 'CHARTERER_CANCELED',
  TRADER_CANCELED = 'TRADER_CANCELED',
  INVALID_TRADER = 'INVALID_TRADER',
  INVALID_CHARTERER = 'INVALID_CHARTERER',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
  DRAFT = 'DRAFT',
}

import { showNotification } from '@mantine/notifications';
import { useTranslation } from 'next-i18next';
import { useSaveDetailOfferTemplate } from 'api/generated/detail-offer/detail-offer';

import { useHandleFormError } from '@/common/use-handle-form-error';

type UseCreateDetailedOfferTemplateProps = Omit<
  Parameters<typeof useSaveDetailOfferTemplate>[0]['mutation'],
  'onError'
>;

export function useCreateDetailedOfferTemplate(props: UseCreateDetailedOfferTemplateProps) {
  const { t } = useTranslation('recapForm');

  const handleError = useHandleFormError({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    translatePath: (path) => t(`errors.${path}` as any),
    overrideMessage: (status) => (status === 409 ? t('modals.saveDraft.duplicateName') : undefined),
  });
  return useSaveDetailOfferTemplate({
    mutation: {
      ...props,
      onSuccess(response, variables, context) {
        showNotification({
          color: 'green',
          title: t('notifications.templateSaved'),
        });
        props.onSuccess?.(response, variables, context);
      },
      onError: (error: any) => {
        // Thw useHandleFormError hoook doesnt seem to catch this error
        error?.response?.status === 500
          ? showNotification({ color: 'red', message: t('modals.saveDraft.duplicateName') })
          : handleError(error);
      },
    },
  });
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';

import type {
  GetCargoInformationByCargoIdResponseDto,
  CreateCargoNeedResponseDto,
  CreateCargoNeedRequestDto,
  CreateCargoOfferResponseDto,
  CreateCargoOfferRequestDto,
  CancelCargoDTO,
  GetSharedCargoItemResponseDto,
  GetMarketplaceCargoItemResponseDto,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getCargoInformationByCargoId = (
  cargoId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetCargoInformationByCargoIdResponseDto>> => {
  return axios.get(`/api/v2/cargos/cargo-information/${cargoId}`, options);
};

export const getGetCargoInformationByCargoIdQueryKey = (cargoId: string) => [
  `/api/v2/cargos/cargo-information/${cargoId}`,
];

export type GetCargoInformationByCargoIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCargoInformationByCargoId>>
>;
export type GetCargoInformationByCargoIdQueryError = AxiosError<void>;

export const useGetCargoInformationByCargoId = <
  TData = Awaited<ReturnType<typeof getCargoInformationByCargoId>>,
  TError = AxiosError<void>
>(
  cargoId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCargoInformationByCargoId>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCargoInformationByCargoIdQueryKey(cargoId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCargoInformationByCargoId>>> = ({ signal }) =>
    getCargoInformationByCargoId(cargoId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCargoInformationByCargoId>>, TError, TData>(queryKey, queryFn, {
    enabled: !!cargoId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const createCargoNeed = (
  createCargoNeedRequestDto: CreateCargoNeedRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CreateCargoNeedResponseDto>> => {
  return axios.post(`/api/v2/cargos/cargo/create-cargo-need`, createCargoNeedRequestDto, options);
};

export type CreateCargoNeedMutationResult = NonNullable<Awaited<ReturnType<typeof createCargoNeed>>>;
export type CreateCargoNeedMutationBody = CreateCargoNeedRequestDto;
export type CreateCargoNeedMutationError = AxiosError<void>;

export const useCreateCargoNeed = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCargoNeed>>,
    TError,
    { data: CreateCargoNeedRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCargoNeed>>,
    { data: CreateCargoNeedRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return createCargoNeed(data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof createCargoNeed>>,
    TError,
    { data: CreateCargoNeedRequestDto },
    TContext
  >(mutationFn, mutationOptions);
};
export const createCargoOffer = (
  createCargoOfferRequestDto: CreateCargoOfferRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CreateCargoOfferResponseDto>> => {
  return axios.post(`/api/v2/cargos/cargo/create-cargo-offer`, createCargoOfferRequestDto, options);
};

export type CreateCargoOfferMutationResult = NonNullable<Awaited<ReturnType<typeof createCargoOffer>>>;
export type CreateCargoOfferMutationBody = CreateCargoOfferRequestDto;
export type CreateCargoOfferMutationError = AxiosError<void>;

export const useCreateCargoOffer = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCargoOffer>>,
    TError,
    { data: CreateCargoOfferRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCargoOffer>>,
    { data: CreateCargoOfferRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return createCargoOffer(data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof createCargoOffer>>,
    TError,
    { data: CreateCargoOfferRequestDto },
    TContext
  >(mutationFn, mutationOptions);
};
export const cancelCargoOfferV2 = (
  cargoId: string,
  cancelCargoDTO: CancelCargoDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/v2/cargos/offers/${cargoId}/cancel`, cancelCargoDTO, options);
};

export type CancelCargoOfferV2MutationResult = NonNullable<Awaited<ReturnType<typeof cancelCargoOfferV2>>>;
export type CancelCargoOfferV2MutationBody = CancelCargoDTO;
export type CancelCargoOfferV2MutationError = AxiosError<unknown>;

export const useCancelCargoOfferV2 = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelCargoOfferV2>>,
    TError,
    { cargoId: string; data: CancelCargoDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cancelCargoOfferV2>>,
    { cargoId: string; data: CancelCargoDTO }
  > = (props) => {
    const { cargoId, data } = props ?? {};

    return cancelCargoOfferV2(cargoId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof cancelCargoOfferV2>>,
    TError,
    { cargoId: string; data: CancelCargoDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const cancelCargoNeedV2 = (
  cargoId: string,
  cancelCargoDTO: CancelCargoDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/v2/cargos/needs/${cargoId}/cancel`, cancelCargoDTO, options);
};

export type CancelCargoNeedV2MutationResult = NonNullable<Awaited<ReturnType<typeof cancelCargoNeedV2>>>;
export type CancelCargoNeedV2MutationBody = CancelCargoDTO;
export type CancelCargoNeedV2MutationError = AxiosError<unknown>;

export const useCancelCargoNeedV2 = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelCargoNeedV2>>,
    TError,
    { cargoId: string; data: CancelCargoDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cancelCargoNeedV2>>,
    { cargoId: string; data: CancelCargoDTO }
  > = (props) => {
    const { cargoId, data } = props ?? {};

    return cancelCargoNeedV2(cargoId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof cancelCargoNeedV2>>,
    TError,
    { cargoId: string; data: CancelCargoDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const getSharedWithMeCargos = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetSharedCargoItemResponseDto[]>> => {
  return axios.get(`/api/v2/cargos/shared-with-me`, options);
};

export const getGetSharedWithMeCargosQueryKey = () => [`/api/v2/cargos/shared-with-me`];

export type GetSharedWithMeCargosQueryResult = NonNullable<Awaited<ReturnType<typeof getSharedWithMeCargos>>>;
export type GetSharedWithMeCargosQueryError = AxiosError<void>;

export const useGetSharedWithMeCargos = <
  TData = Awaited<ReturnType<typeof getSharedWithMeCargos>>,
  TError = AxiosError<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getSharedWithMeCargos>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSharedWithMeCargosQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSharedWithMeCargos>>> = ({ signal }) =>
    getSharedWithMeCargos({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getSharedWithMeCargos>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const cancelCargoPromotion = (cargoId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.patch(`/api/v2/cargos/reject-promotion/${cargoId}`, undefined, options);
};

export type CancelCargoPromotionMutationResult = NonNullable<Awaited<ReturnType<typeof cancelCargoPromotion>>>;

export type CancelCargoPromotionMutationError = AxiosError<unknown>;

export const useCancelCargoPromotion = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelCargoPromotion>>,
    TError,
    { cargoId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelCargoPromotion>>, { cargoId: string }> = (
    props
  ) => {
    const { cargoId } = props ?? {};

    return cancelCargoPromotion(cargoId, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof cancelCargoPromotion>>, TError, { cargoId: string }, TContext>(
    mutationFn,
    mutationOptions
  );
};
export const getMarketplaceCargos = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetMarketplaceCargoItemResponseDto[]>> => {
  return axios.get(`/api/v2/cargos/marketplace`, options);
};

export const getGetMarketplaceCargosQueryKey = () => [`/api/v2/cargos/marketplace`];

export type GetMarketplaceCargosQueryResult = NonNullable<Awaited<ReturnType<typeof getMarketplaceCargos>>>;
export type GetMarketplaceCargosQueryError = AxiosError<void>;

export const useGetMarketplaceCargos = <
  TData = Awaited<ReturnType<typeof getMarketplaceCargos>>,
  TError = AxiosError<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getMarketplaceCargos>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMarketplaceCargosQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMarketplaceCargos>>> = ({ signal }) =>
    getMarketplaceCargos({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getMarketplaceCargos>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

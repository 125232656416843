import { Group } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import { RequestFreightFields } from '@/blink-forms';

import { TargetFreightInput } from '../cargo/TargetFreightInput';
import { PrepaymentInput } from '../cargo/PrepaymentInput';
import { CommentsInput } from '../cargo/CommentsInput';

export interface RequestFreightFormFieldsProps<T> {
  form: UseFormReturnType<T>;
  borderColor?: string;
}

export function RequestFreightFormFields<T extends RequestFreightFields>({ form }: RequestFreightFormFieldsProps<T>) {
  return (
    <>
      <Group
        grow
        sx={{
          '>*': {
            flex: '1 1 300px',
            maxWidth: 'unset',
          },
        }}
      >
        <TargetFreightInput {...form.getInputProps('priceTarget')} />

        <PrepaymentInput {...form.getInputProps('paymentConditions')} />
      </Group>

      <CommentsInput {...form.getInputProps('priceComments')} />
    </>
  );
}

import { TableProps } from '@mantine/core';

import { ThemeComponent } from './types';

export const Table: ThemeComponent<TableProps> = {
  styles: (theme) => ({
    root: {
      'thead tr th': {
        padding: '8px 16px',
        fontWeight: 500,
        color: theme.colors.gray[9],

        '&:hover': {
          cursor: 'pointer',
        },
      },

      'tbody tr': {
        height: 56,

        '&[data-hover="true"]:hover': {
          backgroundColor: theme.colors.blue[0],
          cursor: 'pointer',
        },
      },

      [theme.fn.smallerThan('xs')]: {
        'tbody tr td, thead tr th': {
          padding: 8,
        },
      },
    },
  }),
};

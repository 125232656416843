import { useTranslation } from 'next-i18next';
import { Select, TextInput } from '@mantine/core';

import { GetInputProps } from '@/common/types';
import { useGetSelectDataWithTranslations } from '@/views/tenders/utils/use-get-select-data-with-translations';
import { CostsTerms } from '@/blink-forms/types';

interface SamplingSurveyorFieldGroupProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getInputProps: GetInputProps<any>;
  data?: any;
  namePath: string;
  costPath: string;
  conditionsPath: string;
}

export function SamplingSurveyorFieldGroup({
  getInputProps,
  namePath,
  costPath,
  conditionsPath,
}: SamplingSurveyorFieldGroupProps) {
  const { t } = useTranslation('tenderNegotiation');
  const surveyorCostSelectData = useGetSelectDataWithTranslations(CostsTerms);

  return (
    <>
      <TextInput
        label={t('labels.conditions')}
        placeholder={t('placeholders.conditions')}
        withAsterisk={false}
        {...getInputProps(conditionsPath)}
      />
      <TextInput
        label={t('labels.surveyorNameOrIndication')}
        placeholder={t('placeholders.name')}
        withAsterisk={false}
        {...getInputProps(namePath)}
      />
      <Select
        label={t('labels.surveyorCost')}
        data={surveyorCostSelectData}
        withAsterisk={true}
        {...getInputProps(costPath)}
      />
    </>
  );
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type GetCargoInvitesStatus = (typeof GetCargoInvitesStatus)[keyof typeof GetCargoInvitesStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCargoInvitesStatus = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
} as const;

import { useTranslation } from 'next-i18next';
import { Button } from '@mantine/core';

export interface FreightRecapFormPreviewActionsProps {
  onClose: VoidFunction;
  isSubmitting: boolean;
}

export function FreightRecapFormPreviewActions(props: FreightRecapFormPreviewActionsProps) {
  const { onClose, isSubmitting } = props;
  const { t } = useTranslation('freightRecapForm');

  return (
    <>
      <Button variant="outline" onClick={onClose}>
        {t('buttons.backToEdit')}
      </Button>
      <Button type="submit" form="freight-recap-form" loading={isSubmitting}>
        {t('buttons.send')}
      </Button>
    </>
  );
}

import { MantineThemeOverride } from '@mantine/core';
import { DefaultMantineColor, Tuple } from '@mantine/core';

import * as components from './components-override';
import { globalStyles } from './global-styles';

type ExtendedCustomColors = 'deepBlue' | 'primary' | 'secondary' | 'tertiary' | 'background' | DefaultMantineColor;

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, Tuple<string, 10>>;
  }
}

export const theme: MantineThemeOverride = {
  radius: {
    xl: 40,
  },
  colors: {
    white: ['#fff'],
    deepBlue: [
      '#E1F5FE',
      '#B3E6FD',
      '#50C5F9',
      '#03ACF7',
      '#018BD4',
      '#005A9E',
      '#004D87',
      '#004071',
      '#00335A',
      '#002744',
    ],
    primary: ['#4D9BF1', '#005A9E', '#233240'],
    secondary: ['#347355', '#45956F', '#56D5DA'],
    tertiary: ['#2C1A5E', '#816BD7'],
    background: ['#F3F4F6', '#F8F9FA', '#535A60'],
    chartsColor: [
      '#2F628A',
      '#A3BAC9',
      '#00335A',
      '#E1F5FE',
      '#B3E6FD',
      '#50C5F9',
      '#03ACF7',
      '#018BD4',
      '#005A9E',
      '#004D87',
    ],
    dashboardFooter: ['#495057'],
    gray65: ['#535A60'],
    colorSwatchGray: ['#CED4DA'],
    colorSwatchBlue: ['#8CE99B'],
    colorSwatchGreen: ['#A5D8FF'],
    backgroundTableColor: ['#F8FDFF', '#F0FAFF', '#F1F3F5'],
    warningBoxBackgroundColor: ['#FFF4E6'],
    warningBoxIconBackgroundColor: ['#F9E2C4'],
    warningBoxIconColor: ['#E99129'],
    warningBoxTextColor: ['#852D0B'],
  },
  primaryColor: 'deepBlue',
  primaryShade: 5,
  fontFamily: 'ibm-plex-sans, sans-serif',
  headings: {
    fontWeight: 500,
    fontFamily: 'ibm-plex-sans, sans-serif',
  },
  lineHeight: '24px',
  components,
  globalStyles,
};

import { Divider, Flex, Grid, Stack } from '@mantine/core';
import { useTranslation } from 'next-i18next';

import { Text, TwoColumnsLayout } from '@/components';
import { NumberInput, Select, TextInput, Checkbox, CheckboxGroup, Textarea } from '@/views/recap-form';
import { FreightRecapForm } from '@/blink-forms';
import { getFixedNumberProps } from '@/common/get-fixed-number-props';

import { useFreightRecapFormState } from '../state/FreightRecapFormProvider';

type ExclusionFromLaytime = FreightRecapForm['loadingConditions']['freightExclusionFormLaytime'][number];

export interface FreightRecapFormFreightGroupProps {
  portType: 'loading' | 'discharging';
}

export function FreightRecapFormFreightGroup(props: FreightRecapFormFreightGroupProps) {
  const { portType } = props;
  const { t } = useTranslation('freightRecapForm');
  const { masterdata, getInputProps, form, initialValues } = useFreightRecapFormState();

  const isExclusionFromLayTimeCheckboxDirty = (value: ExclusionFromLaytime) => {
    const isInitiallyChecked =
      initialValues[`${portType}Conditions`].freightExclusionFormLaytime?.includes(value) || false;
    const isChecked = form.values[`${portType}Conditions`].freightExclusionFormLaytime.includes(value);
    return isInitiallyChecked !== isChecked;
  };

  return (
    <>
      <Text weight={600} color="gray.9" mb={24}>
        {t(`labels.${portType}Conditions`)}
      </Text>

      <Grid>
        <Grid.Col span={12} md={5}>
          <NumberInput
            label={t('labels.noOfSafePort')}
            placeholder={t('placeholders.no')}
            horizontal
            w="100%"
            withAsterisk={false}
            data-testid={`${portType}Conditions-safePortNumber`}
            {...getInputProps(`${portType}Conditions.safePortNumber`)}
          />
        </Grid.Col>

        <Grid.Col span={12} md={7}>
          <Flex direction={{ base: 'column', md: 'row' }} gap={8}>
            <NumberInput
              label={t('labels.noOfSafeBerth')}
              placeholder={t('placeholders.no')}
              horizontal
              withAsterisk={false}
              data-testid={`${portType}Conditions-safeBerthNumber`}
              {...getInputProps(`${portType}Conditions.safeBerthNumber`)}
            />
            <Select
              data={masterdata.berthConditions}
              withAsterisk={false}
              data-testid={`${portType}Conditions-safeBerthConditions`}
              {...getInputProps(`${portType}Conditions.berthConditions`)}
            />
          </Flex>
        </Grid.Col>

        <Grid.Col span={12}>
          <Divider />
        </Grid.Col>

        <Grid.Col span={12} md={5}>
          <NumberInput
            label={t(`labels.${portType}Rate`)}
            min={0}
            placeholder={t('placeholders.mts')}
            horizontal
            w="100%"
            data-testid={`${portType}Conditions-${portType}Rate`}
            {...getFixedNumberProps(1)}
            {...getInputProps(`${portType}Conditions.freightRate`)}
          />
        </Grid.Col>

        <Grid.Col span={12} md={7}>
          <Flex direction={{ base: 'column', md: 'row' }} gap={8}>
            <Select
              label={t(`labels.${portType}Terms`)}
              horizontal
              data={masterdata.freightTerms}
              grow={false}
              data-testid={`${portType}Conditions-${portType}Terms`}
              {...getInputProps(`${portType}Conditions.freightRateTerms`)}
            />
            <Select
              data={masterdata.freightPeriodTerms}
              withAsterisk={false}
              data-testid={`${portType}Conditions-${portType}PeriodRate`}
              {...getInputProps(`${portType}Conditions.freightPortPeriodTerms`)}
            />
          </Flex>
        </Grid.Col>

        <Grid.Col span={12}>
          <Divider />
        </Grid.Col>

        <Grid.Col span={12}>
          <Select
            label={t('labels.norTendering')}
            placeholder={t('placeholders.nor')}
            data={masterdata.norTendering}
            description={t('hints.norTendering')}
            horizontal
            withAsterisk={false}
            data-testid={`${portType}Conditions-NORtendering`}
            {...getInputProps(`${portType}Conditions.freightNORTendering`)}
          />
        </Grid.Col>

        <Grid.Col span={12} md={6}>
          <Flex direction={{ base: 'column', md: 'row' }} gap={8}>
            <NumberInput
              label={t('labels.turnTime')}
              horizontal
              w={{ md: 175 }}
              data-testid={`${portType}Conditions-turnTime`}
              {...getInputProps(`${portType}Conditions.freightTurnTime`)}
            />
            <Select
              data={masterdata.turnTimes}
              placeholder={t('placeholders.turnTime')}
              data-testid={`${portType}Conditions-turnTimeTerms`}
              {...getInputProps(`${portType}Conditions.freightTurnTimeTerms`)}
            />
          </Flex>
        </Grid.Col>

        <Grid.Col span={12}>
          <Divider />
        </Grid.Col>

        <Grid.Col span={12}>
          <TwoColumnsLayout>
            <Stack>
              <CheckboxGroup
                label={t('labels.exclusionFromLayTime')}
                orientation="vertical"
                spacing={12}
                withAsterisk={false}
                {...getInputProps(`${portType}Conditions.freightExclusionFormLaytime`)}
              >
                {masterdata.exclusionFromLaytime.map((option: { label: string; value: ExclusionFromLaytime }) => (
                  <Checkbox
                    key={`${portType}-${option.value}`}
                    value={option.value}
                    label={option.label}
                    isDirty={isExclusionFromLayTimeCheckboxDirty(option.value)}
                    data-testid={`${portType}Conditions-exclusionFromLayTime-${option.value}`}
                  />
                ))}
              </CheckboxGroup>
              {form.values[`${portType}Conditions`].freightExclusionFormLaytime?.includes('OTHERS') && (
                <TextInput
                  mt={8}
                  w={{ md: '40%' }}
                  placeholder={t('placeholders.exclusion')}
                  data-testid={`${portType}Conditions-exclusionFromLayTime-otherComments`}
                  {...getInputProps(`${portType}Conditions.freightExclusionFormLaytimeOther`)}
                />
              )}
            </Stack>

            <Stack>
              <Select
                label={t('labels.cargoPda')}
                data={masterdata.freightPdas}
                withAsterisk={false}
                data-testid={`${portType}Conditions-cargoPDA`}
                {...getInputProps(`${portType}Conditions.freightCargoPDA`)}
              />

              <Select
                label={t('labels.vesselPda')}
                data={masterdata.freightPdas}
                withAsterisk={false}
                data-testid={`${portType}Conditions-vesselPDA`}
                {...getInputProps(`${portType}Conditions.freightVesselPDA`)}
              />

              <Textarea
                size="md"
                minRows={7}
                label={t('labels.other')}
                withAsterisk={false}
                placeholder={t('placeholders.otherFreightConditions')}
                data-testid={`${portType}Conditions-other`}
                {...getInputProps(`${portType}Conditions.freightPDAOther`)}
              />
            </Stack>
          </TwoColumnsLayout>
        </Grid.Col>
      </Grid>
    </>
  );
}

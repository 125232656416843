/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type GetAllDetailedOffersInvitedResponseItemDtoCargoDtoPortDtoFreshWaterSaltWater =
  (typeof GetAllDetailedOffersInvitedResponseItemDtoCargoDtoPortDtoFreshWaterSaltWater)[keyof typeof GetAllDetailedOffersInvitedResponseItemDtoCargoDtoPortDtoFreshWaterSaltWater];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAllDetailedOffersInvitedResponseItemDtoCargoDtoPortDtoFreshWaterSaltWater = {
  FRESHWATER: 'FRESHWATER',
  SALTWATER: 'SALTWATER',
} as const;

import { createStyles, Group, Image, Paper, Stack } from '@mantine/core';
import { useTranslation } from 'next-i18next';

import { NeedHelp, Text, Title } from '@/components';

const useStyles = createStyles((theme) => ({
  paper: {
    padding: '80px 104px 80px 56px',
    backgroundColor: theme.colors.gray[0],
    borderRadius: 8,

    [theme.fn.smallerThan('md')]: {
      padding: 16,
    },
  },
  group: {
    flexWrap: 'nowrap',
    gap: 80,

    [theme.fn.smallerThan('md')]: {
      flexWrap: 'wrap',
      gap: 20,
    },
  },
}));

export type EmptyResultsProps = {
  isDetailedOffer?: boolean;
};

export function EmptyResults(props: EmptyResultsProps) {
  const { t } = useTranslation('recapForm');
  const { classes } = useStyles();
  const { isDetailedOffer } = props;

  return (
    <Paper className={classes.paper}>
      <Group className={classes.group}>
        <Image src="/images/empty-results.svg" alt="Empty results" width={178} height={235} />

        <Stack spacing={16}>
          <Title size="XL" color="gray.8">
            {t('modals.loadTemplate.headingNoResults')}
          </Title>

          <Text size="M" color="gray.8">
            {!isDetailedOffer
              ? t('modals.loadTemplate.emptyDescription')
              : t('modals.loadTemplate.emptyDetailedOfferDescription')}
          </Text>

          <NeedHelp />
        </Stack>
      </Group>
    </Paper>
  );
}

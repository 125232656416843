/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CounterpartyNewDTOType = (typeof CounterpartyNewDTOType)[keyof typeof CounterpartyNewDTOType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CounterpartyNewDTOType = {
  NEW: 'NEW',
} as const;

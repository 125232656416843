/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type UpdatePrivateCompanyResponseDtoRolesItem =
  (typeof UpdatePrivateCompanyResponseDtoRolesItem)[keyof typeof UpdatePrivateCompanyResponseDtoRolesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdatePrivateCompanyResponseDtoRolesItem = {
  ALLOWED_TO_NEGOTIATE: 'ALLOWED_TO_NEGOTIATE',
  ALLOWED_TO_FINALIZE_NEGOTIATION: 'ALLOWED_TO_FINALIZE_NEGOTIATION',
} as const;

import { useEffect, useMemo, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import { useTranslation } from 'next-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { CreateCargoFeedbackDTO, NegotiationStatus } from 'api/generated/model';
import {
  getGetCargoNegotiationHistoryQueryKey,
  getGetCompanyCargoNegotiationsQueryKey,
  useDeleteCargoNegotiation,
  usePostCargoCancel,
  usePostCargoReject,
} from 'api/generated/cargo-negotiation/cargo-negotiation';

import { useToastNotifications } from '@/common/use-toast-notifications';

import { CounterpartyMessageForm, PlatformFeedbackFormValues, CancelNegotiationState, FeedbackReason } from './types';

export type CancelNegotiationForm = CounterpartyMessageForm & PlatformFeedbackFormValues;

interface UseCancelNegotiationStepperProps {
  toggleCancelNegotiationModal: VoidFunction;
  negotiationId: string;
  status: NegotiationStatus;
  cancelType?: 'CANCEL' | 'REJECT';
}

export const useCancelNegotiationStepper = (props: UseCancelNegotiationStepperProps) => {
  const { t } = useTranslation('myCargos');
  const { toggleCancelNegotiationModal, negotiationId, status, cancelType = 'CANCEL' } = props;
  const [state, setState] = useState<CancelNegotiationState>(CancelNegotiationState.CANCEL_NEGOTIATION);
  const [formState, setFormState] = useState<CreateCargoFeedbackDTO>({
    unfinishedReason: undefined,
    feedbackType: FeedbackReason.CARGO_SOLD_ON_MARKETPLACE,
    feedbackComment: undefined,
  });
  const reject = usePostCargoReject();
  const cancel = usePostCargoCancel();
  const deleteNegotiation = useDeleteCargoNegotiation();
  const feedbackMutation = useMemo(() => {
    switch (cancelType) {
      case 'CANCEL':
        return cancel;
      case 'REJECT':
        return reject;
    }
  }, [cancelType, cancel, reject]);
  const cancelMutation = status === NegotiationStatus.DRAFT ? deleteNegotiation : cancel;
  const { displayApiError } = useToastNotifications();
  const queryClient = useQueryClient();

  useEffect(() => {
    const isSingleStep = (
      [
        NegotiationStatus.DRAFT,
        NegotiationStatus.OFFER_SENT,
        NegotiationStatus.OFFER_RECEIVED,
        NegotiationStatus.RFQ_SENT,
        NegotiationStatus.RFQ_RECEIVED,
      ] as NegotiationStatus[]
    ).includes(status);
    setState(
      isSingleStep ? CancelNegotiationState.SINGLE_STEP_CANCEL_NEGOTIATION : CancelNegotiationState.CANCEL_NEGOTIATION
    );
  }, [status]);

  const goToCancelNegotiation = (values: CounterpartyMessageForm) => {
    setFormState((prevFormState) => ({ ...prevFormState, ...values }));
    setState(CancelNegotiationState.CANCEL_NEGOTIATION);
  };

  const goToMessageCounterparty = (values: PlatformFeedbackFormValues) => {
    setFormState((prevFormState) => ({ ...prevFormState, ...values }));
    setState(CancelNegotiationState.SEND_MESSAGE_TO_COUNTERPARTY);
  };

  const goToPlatformFeedback = (values: CounterpartyMessageForm) => {
    setFormState((prevFormState) => ({ ...prevFormState, ...values }));
    setState(CancelNegotiationState.SEND_FEEDBACK_FOR_PLATFORM);
  };

  const confirmPlatformFeedback = (values: PlatformFeedbackFormValues) => {
    const newState = { ...formState, ...values };
    setFormState(newState);
    setState(CancelNegotiationState.SUBMITTING);
    feedbackMutation.mutate(
      { negotiationId, data: newState },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(getGetCompanyCargoNegotiationsQueryKey());
          queryClient.invalidateQueries(getGetCargoNegotiationHistoryQueryKey(negotiationId));
          toggleCancelNegotiationModal();
          setState(CancelNegotiationState.CANCEL_NEGOTIATION_SUCCESS);
          showNotification({
            color: 'green',
            title: t('notifications.cancelNegotiation.title'),
            message: t('notifications.cancelNegotiation.message'),
          });
        },
        onError: (error) => {
          displayApiError(error);
          setState(CancelNegotiationState.SEND_FEEDBACK_FOR_PLATFORM);
        },
      }
    );
  };

  const confirmCancellation = () => {
    cancelMutation.mutate(
      { negotiationId, data: {} },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(getGetCompanyCargoNegotiationsQueryKey());
          queryClient.invalidateQueries(getGetCargoNegotiationHistoryQueryKey(negotiationId));
          showNotification({
            color: 'green',
            title: t('notifications.cancelNegotiation.title'),
            message: t('notifications.cancelNegotiation.message'),
          });
          toggleCancelNegotiationModal();
          setState(CancelNegotiationState.SINGLE_STEP_CANCEL_NEGOTIATION);
        },
        onError: (error) => {
          displayApiError(error);
          setState(CancelNegotiationState.SINGLE_STEP_CANCEL_NEGOTIATION);
        },
      }
    );
  };

  const onBack = useMemo(() => {
    switch (state) {
      case 'SINGLE_STEP_CANCEL_NEGOTIATION':
        return toggleCancelNegotiationModal;
      case 'CANCEL_NEGOTIATION':
        return toggleCancelNegotiationModal;
      case 'SEND_MESSAGE_TO_COUNTERPARTY':
        return goToCancelNegotiation;
      case 'SEND_FEEDBACK_FOR_PLATFORM':
        return goToMessageCounterparty;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const onNext = useMemo(() => {
    switch (state) {
      case 'SINGLE_STEP_CANCEL_NEGOTIATION':
        return confirmCancellation;
      case 'CANCEL_NEGOTIATION':
        return goToMessageCounterparty;
      case 'SEND_MESSAGE_TO_COUNTERPARTY':
        return goToPlatformFeedback;
      case 'SEND_FEEDBACK_FOR_PLATFORM':
        return confirmPlatformFeedback;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return {
    state,
    formState,
    onBack,
    onNext,
  };
};

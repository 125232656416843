import { faker } from '@faker-js/faker';

import { ServiceProviderContactForm } from './my-service-providers';

export const SERVICE_PROVIDER_CONTACT_INITIAL_VALUES: ServiceProviderContactForm[] = [
  {
    name: '',
    email: '',
    surname: '',
    mobilePhoneNumber: '',
    contactId: faker.datatype.uuid(),
  },
];

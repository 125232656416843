import { z } from 'zod';
import { differenceInDays } from 'date-fns';

import { Currency, zDateRange } from '../types';
import { cargoTenderFormVolumeSchema } from './cargo-tender-volume';
import { cargoTenderFormGroupSchema } from './cargo-tender-group';
import {
  DEFAULT_MIN_NUMBER_OF_DAYS_TENDER_CONTRACT_PERIOD,
  DEFAULT_MAX_NUMBER_OF_DAYS_TENDER_CONTRACT_PERIOD,
} from '../consts';
import { isDatePeriodInDaysRange } from '../validators';

export const cargoTenderNegotiationFormPreagreementGroupSchema = cargoTenderFormGroupSchema.extend({
  price: z.number().optional(),
  priceCurrency: z.nativeEnum(Currency).nullable(),
  checked: z.boolean().optional(),
});

export type CargoTenderNegotiationFormPreagreementGroupSchema = z.infer<
  typeof cargoTenderNegotiationFormPreagreementGroupSchema
>;

export const cargoTenderNegotiationFormPreagreementSchema = z
  .object({
    volumes: z.array(cargoTenderFormVolumeSchema),
    groups: z.array(cargoTenderNegotiationFormPreagreementGroupSchema),
    // Include general information properties directly here
    contractPeriod: zDateRange,
    offerSubmissionDeadline: z.date(),
    offerValidity: z.date(),
  })
  .superRefine((data, context) => {
    const [contractPeriodMin, contractPeriodMax] = data.contractPeriod ?? [];

    if (
      !isDatePeriodInDaysRange(
        contractPeriodMin,
        contractPeriodMax,
        DEFAULT_MIN_NUMBER_OF_DAYS_TENDER_CONTRACT_PERIOD,
        DEFAULT_MAX_NUMBER_OF_DAYS_TENDER_CONTRACT_PERIOD
      )
    ) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Contract period has to be between 90 and 366 days',
        path: ['contractPeriod'],
      });
    }

    if (differenceInDays(contractPeriodMin, data.offerSubmissionDeadline) < 1) {
      context.addIssue({
        code: z.ZodIssueCode.custom,

        message: 'Invalid date range: offerSubmissionDeadline  must be less than contractPeriodMin',
        path: ['offerSubmissionDeadline'],
      });
    }

    if (differenceInDays(contractPeriodMin, data.offerValidity) < 1) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid date range: offerValidity must be less than contractPeriodMin',
        path: ['offerValidity'],
      });
    }
  });

export type CargoTenderNegotiationFormPreagreementSchema = z.infer<typeof cargoTenderNegotiationFormPreagreementSchema>;

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CompanyNetworkType = (typeof CompanyNetworkType)[keyof typeof CompanyNetworkType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyNetworkType = {
  BUY: 'BUY',
  SELL: 'SELL',
} as const;

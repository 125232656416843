import { useTranslation } from 'next-i18next';
import { BadgeProps } from '@mantine/core';

import { BlinkBadge } from './BlinkBadge';

interface ShortlistedBadgeProps {
  maw?: BadgeProps['maw'];
  shortLabel?: boolean;
}

export function ShortlistedBadge(props: ShortlistedBadgeProps) {
  const { t } = useTranslation('common');

  return (
    <BlinkBadge
      color="gray"
      showLogo={false}
      styles={{
        inner: {
          textAlign: 'center',
          textOverflow: 'unset',
          overflowWrap: 'break-word',
          whiteSpace: 'break-spaces',
        },
      }}
      {...props}
    >
      {props.shortLabel ? t('badges.companyInfo.SHORTLISTED_SHORT') : t('badges.companyInfo.SHORTLISTED')}
    </BlinkBadge>
  );
}

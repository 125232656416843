import { NumberInput, NumberInputProps } from '@mantine/core';
import { useTranslation } from 'next-i18next';

import { getFixedNumberProps } from '@/common/get-fixed-number-props';

export function TargetFreightInput(props: NumberInputProps) {
  const { t } = useTranslation('cargoListings');

  return (
    <NumberInput
      size="md"
      hideControls
      label={t('labels.targetFreightRate')}
      placeholder={t('placeholders.targetFreightRate')}
      withAsterisk={false}
      icon="$"
      min={0}
      {...getFixedNumberProps(2)}
      styles={{
        withIcon: {
          paddingLeft: 32,
        },
      }}
      {...props}
    />
  );
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type Gears = (typeof Gears)[keyof typeof Gears];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Gears = {
  GEARLESS: 'GEARLESS',
  GEARED: 'GEARED',
} as const;

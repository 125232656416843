/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';

import type {
  NotificationDTO,
  GetNotificationsParams,
  ApiErrorResponse,
  NotificationSettingsDTO,
  NotificationSubscriptionDTO,
  NotificationUnSubscriptionDTO,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Mark notification as read
 */
export const readNotification = (id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/v1/notifications/${id}/read`, undefined, options);
};

export type ReadNotificationMutationResult = NonNullable<Awaited<ReturnType<typeof readNotification>>>;

export type ReadNotificationMutationError = AxiosError<unknown>;

export const useReadNotification = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof readNotification>>, TError, { id: string }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof readNotification>>, { id: string }> = (props) => {
    const { id } = props ?? {};

    return readNotification(id, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof readNotification>>, TError, { id: string }, TContext>(
    mutationFn,
    mutationOptions
  );
};
/**
 * Get list of notifications
 */
export const getNotifications = (
  params?: GetNotificationsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<NotificationDTO[]>> => {
  return axios.get(`/api/v1/notifications`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetNotificationsQueryKey = (params?: GetNotificationsParams) => [
  `/api/v1/notifications`,
  ...(params ? [params] : []),
];

export type GetNotificationsQueryResult = NonNullable<Awaited<ReturnType<typeof getNotifications>>>;
export type GetNotificationsQueryError = AxiosError<void>;

export const useGetNotifications = <TData = Awaited<ReturnType<typeof getNotifications>>, TError = AxiosError<void>>(
  params?: GetNotificationsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getNotifications>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetNotificationsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNotifications>>> = ({ signal }) =>
    getNotifications(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getNotifications>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Get number of new notifications
 */
export const getNewNotificationsCounter = (options?: AxiosRequestConfig): Promise<AxiosResponse<number>> => {
  return axios.get(`/api/v1/notifications/new`, options);
};

export const getGetNewNotificationsCounterQueryKey = () => [`/api/v1/notifications/new`];

export type GetNewNotificationsCounterQueryResult = NonNullable<Awaited<ReturnType<typeof getNewNotificationsCounter>>>;
export type GetNewNotificationsCounterQueryError = AxiosError<void>;

export const useGetNewNotificationsCounter = <
  TData = Awaited<ReturnType<typeof getNewNotificationsCounter>>,
  TError = AxiosError<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getNewNotificationsCounter>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetNewNotificationsCounterQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNewNotificationsCounter>>> = ({ signal }) =>
    getNewNotificationsCounter({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getNewNotificationsCounter>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Reset new counter
 */
export const resetNewNotificationsCounter = (options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/v1/notifications/new/reset`, undefined, options);
};

export type ResetNewNotificationsCounterMutationResult = NonNullable<
  Awaited<ReturnType<typeof resetNewNotificationsCounter>>
>;

export type ResetNewNotificationsCounterMutationError = AxiosError<unknown>;

export const useResetNewNotificationsCounter = <
  TError = AxiosError<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof resetNewNotificationsCounter>>, TError, TVariables, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof resetNewNotificationsCounter>>, TVariables> = () => {
    return resetNewNotificationsCounter(axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof resetNewNotificationsCounter>>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions
  );
};
export const updateNotificationSettings = (
  notificationSettingsDTO: NotificationSettingsDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.put(`/api/v1/notifications/settings`, notificationSettingsDTO, options);
};

export type UpdateNotificationSettingsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateNotificationSettings>>
>;
export type UpdateNotificationSettingsMutationBody = NotificationSettingsDTO;
export type UpdateNotificationSettingsMutationError = AxiosError<ApiErrorResponse>;

export const useUpdateNotificationSettings = <TError = AxiosError<ApiErrorResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateNotificationSettings>>,
    TError,
    { data: NotificationSettingsDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateNotificationSettings>>,
    { data: NotificationSettingsDTO }
  > = (props) => {
    const { data } = props ?? {};

    return updateNotificationSettings(data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateNotificationSettings>>,
    TError,
    { data: NotificationSettingsDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Subscribe client to receive push notifications
 */
export const subscribePushNotifications = (
  notificationSubscriptionDTO: NotificationSubscriptionDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/v1/notifications/push/subscribe`, notificationSubscriptionDTO, options);
};

export type SubscribePushNotificationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof subscribePushNotifications>>
>;
export type SubscribePushNotificationsMutationBody = NotificationSubscriptionDTO;
export type SubscribePushNotificationsMutationError = AxiosError<unknown>;

export const useSubscribePushNotifications = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof subscribePushNotifications>>,
    TError,
    { data: NotificationSubscriptionDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof subscribePushNotifications>>,
    { data: NotificationSubscriptionDTO }
  > = (props) => {
    const { data } = props ?? {};

    return subscribePushNotifications(data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof subscribePushNotifications>>,
    TError,
    { data: NotificationSubscriptionDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Unsubscribe client to not receive push notifications anymore
 */
export const unsubscribePushNotifications = (
  notificationUnSubscriptionDTO: NotificationUnSubscriptionDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/v1/notifications/push/unsubscribe`, notificationUnSubscriptionDTO, options);
};

export type UnsubscribePushNotificationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof unsubscribePushNotifications>>
>;
export type UnsubscribePushNotificationsMutationBody = NotificationUnSubscriptionDTO;
export type UnsubscribePushNotificationsMutationError = AxiosError<unknown>;

export const useUnsubscribePushNotifications = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof unsubscribePushNotifications>>,
    TError,
    { data: NotificationUnSubscriptionDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof unsubscribePushNotifications>>,
    { data: NotificationUnSubscriptionDTO }
  > = (props) => {
    const { data } = props ?? {};

    return unsubscribePushNotifications(data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof unsubscribePushNotifications>>,
    TError,
    { data: NotificationUnSubscriptionDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Unsubscribe all clients belonging to a user to not receive push notifications anymore
 */
export const unsubscribeAllPushNotifications = (options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/v1/notifications/push/unsubscribe/all`, undefined, options);
};

export type UnsubscribeAllPushNotificationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof unsubscribeAllPushNotifications>>
>;

export type UnsubscribeAllPushNotificationsMutationError = AxiosError<unknown>;

export const useUnsubscribeAllPushNotifications = <
  TError = AxiosError<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof unsubscribeAllPushNotifications>>,
    TError,
    TVariables,
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof unsubscribeAllPushNotifications>>, TVariables> = () => {
    return unsubscribeAllPushNotifications(axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof unsubscribeAllPushNotifications>>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions
  );
};

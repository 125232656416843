import { useEffect } from 'react';
import { useSession, signIn } from 'next-auth/react';

import { REFRESH_TIME_OFFSET } from '@/common/consts';
import { routes } from '@/common/routes';

export interface RefreshTokenHandlerProps {
  setRefreshInterval(interval: number): void;
}

export function RefreshTokenHandler({ setRefreshInterval }: RefreshTokenHandlerProps) {
  const { data: session } = useSession();
  const expiredAt = Number(session?.expires || 0);

  useEffect(() => {
    if (expiredAt > 0) {
      const shouldRefetchAt = expiredAt - REFRESH_TIME_OFFSET / 2;
      const remainingTime = Math.round((shouldRefetchAt - Date.now()) / 1000); // in seconds
      setRefreshInterval(remainingTime);
    }
  }, [expiredAt, setRefreshInterval]);

  useEffect(() => {
    if (session?.error === 'RefreshAccessTokenError') {
      signIn('credentials', { callbackUrl: routes.App });
    }
  }, [session]);

  return null;
}

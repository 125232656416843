import { Stack } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { DocumentCollectionDTO } from 'api/generated/model';

import { Text } from '@/components';
import { decimalToPercentage } from '@/views/tenders/utils/percentage';

interface DocumentsCollectionProps {
  documentCollection: DocumentCollectionDTO;
}

export const DocumentsCollectionLabel = ({ documentCollection }: DocumentsCollectionProps) => {
  const { t } = useTranslation('tenderContract');

  return (
    <Stack spacing={0}>
      <Text weight="bold">{t(`labels.documentCollection`)}</Text>
      <DocumentsCollectionText documentCollection={documentCollection} />
    </Stack>
  );
};

export const DocumentsCollectionText = ({ documentCollection }: DocumentsCollectionProps) => {
  const { t } = useTranslation('tenderContract');

  return (
    <Text>
      {t(`valuesFormatted.documentCollection`, {
        days: documentCollection.days,
        event: t(`valuesFormatted.${documentCollection.event}`),
        percentage: decimalToPercentage(documentCollection.percentage),
      })}
    </Text>
  );
};

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoTenderContractCargoGroupAction =
  (typeof CargoTenderContractCargoGroupAction)[keyof typeof CargoTenderContractCargoGroupAction];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoTenderContractCargoGroupAction = {
  DECLARE: 'DECLARE',
  EXERCISE: 'EXERCISE',
} as const;

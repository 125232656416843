import { TextProps } from '@mantine/core';

import { ThemeComponent } from './types';

export const Text: ThemeComponent<TextProps> = {
  styles: () => ({
    root: {
      cursor: 'default',
    },
  }),
};

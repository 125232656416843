import { Grid } from '@mantine/core';
import { useTranslation } from 'next-i18next';

import { Select, TextInput } from '@/views/recap-form';

import { useFreightRecapFormState } from '../state/FreightRecapFormProvider';

export function FreightRecapFormNominationGroup() {
  const { t } = useTranslation('freightRecapForm');
  const { getInputProps, masterdata } = useFreightRecapFormState();

  return (
    <Grid>
      <Grid.Col span={12} md={6}>
        <Select
          label={t('labels.lpAgentsConditions')}
          data={masterdata.freightAgentsConditions}
          withAsterisk={false}
          data-testid="nomination-agentLoadingPortConditions"
          {...getInputProps('nomination.agentAtLoadingPortConditions')}
        />
      </Grid.Col>

      <Grid.Col span={12} md={6}>
        <TextInput
          label={t('labels.agentsAtLoadingPort')}
          placeholder={t('placeholders.typeAgent')}
          withAsterisk={false}
          data-testid="nomination-agentLoadingPort"
          {...getInputProps('nomination.agentAtLoadingPort')}
        />
      </Grid.Col>

      <Grid.Col span={12} md={6}>
        <Select
          label={t('labels.dpAgentsConditions')}
          data={masterdata.freightAgentsConditions}
          withAsterisk={false}
          data-testid="nomination-agentDischargingPortConditions"
          {...getInputProps('nomination.agentAtDischargingPortConditions')}
        />
      </Grid.Col>

      <Grid.Col span={12} md={6}>
        <TextInput
          label={t('labels.agentsAtDischargingPort')}
          placeholder={t('placeholders.typeAgent')}
          withAsterisk={false}
          data-testid="nomination-agentDischargingPort"
          {...getInputProps('nomination.agentAtDischargingPort')}
        />
      </Grid.Col>
    </Grid>
  );
}

import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { showNotification } from '@mantine/notifications';
import { useUnarchiveCargoWorkflow } from 'api/generated/workflow/workflow';

export type UnarchiveMutationData = {
  id: string;
  shortId?: string;
};

export const useUnarchiveMutation = (
  unarchiveMutationData: null | UnarchiveMutationData,
  unarchiveCallback: () => void
) => {
  const { t } = useTranslation('myCargos');
  const [isProcessing, setIsProcessing] = useState(false);

  const { mutate: archiveCargoWorkflowMutation } = useUnarchiveCargoWorkflow({
    mutation: {
      onSuccess: () => {
        setIsProcessing(false);
        showNotification({
          color: 'green',
          title: t('modals.archiveCargo.unarchive.successMessage', { shortId: unarchiveMutationData?.shortId }),
        });
        unarchiveCallback();
      },
      onError: () => {
        setIsProcessing(false);
        showNotification({ color: 'red', title: t('modals.archiveCargo.unarchive.errorMessage') });
      },
    },
  });

  const unarchiveCargo = () => {
    if (!unarchiveMutationData) return;

    setIsProcessing(true);

    archiveCargoWorkflowMutation({
      workflowId: unarchiveMutationData.id,
    });
  };

  return { isProcessing, unarchiveCargo };
};

import { GroupProps } from '@mantine/core';

import { ThemeComponent } from './types';

export const Group: ThemeComponent<GroupProps> = {
  defaultProps: {
    spacing: 16,
    pt: 0,
  },
};

import { z } from 'zod';

import { AnalysisTypes } from '../../types';

export const mainAnalysis = z.object({
  name: z.string().optional(),
  conditions: z.string().optional(),
  type: z.nativeEnum(AnalysisTypes).optional().nullable(),
});

export type MainAnalysisSchema = z.infer<typeof mainAnalysis>;

import { Group, Radio } from '@mantine/core';

import { GetInputProps } from '@/common/types';

interface DemurrageOptionsFieldGroupProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getInputProps: GetInputProps<any>;
  data?: any;
  typePath: string;
  multiOptions: any[];
  selectedValue?: string;
  orientation?: 'vertical' | 'horizontal';
}

export function DemurrageOptionsFieldGroup({
  getInputProps,
  typePath,
  multiOptions,
  orientation = 'vertical',
  selectedValue,
}: DemurrageOptionsFieldGroupProps) {
  return (
    <Radio.Group orientation={orientation} {...getInputProps(typePath)} value={selectedValue} withAsterisk>
      {multiOptions.map((option) => (
        <Group key={option.value} align="flex-start">
          <Radio label={option.label} value={option.value} mt={4} onClick={() => option.onClick && option.onClick()} />
          {selectedValue === option.value && option.content}
        </Group>
      ))}
    </Radio.Group>
  );
}

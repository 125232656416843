import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';
import type { Session } from 'next-auth';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { QueryParamProvider } from 'use-query-params';
import { z } from 'zod';
import { IToggle } from '@unleash/nextjs/client';
import { theme } from 'theme';
import { PageDataProvider } from 'v2/shared/context/global/PageDataContext';

import { RouterTransition, SessionWithRefreshProvider, PageComponent } from '@/components';
import { FeatureFlagsContext, makeZodI18nMap, QueryParamProviderAdapter, ThirdPartyScripts } from '@/core';
import { setBearerToken } from '@/common/set-bearer-token';
import { PushNotificationSubscriptionProvider } from '@/core/providers/PushNotificationSubscriptionProvider';

import nextI18nextConfig from '../next-i18next.config';

import './stylesOverwrites.css';
import '@blinkmaterials/blink-design-system/styles.css';

if (
  typeof window !== 'undefined' &&
  process.env.NODE_ENV === 'development' &&
  process.env.NEXT_PUBLIC_USE_MSW === 'true'
) {
  import('../api/mocks/mocks-worker').then(({ browserWorker }) => {
    browserWorker.start();
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 10, // 10 mins
    },
  },
});

axios.defaults.baseURL = process.env.NEXT_PUBLIC_BASE_URL;

function CustomApp({ Component, pageProps }: AppProps<{ session: Session; toggles: IToggle[] }>) {
  const { toggles, session, ...props } = pageProps;
  const { t } = useTranslation('common');

  z.setErrorMap(makeZodI18nMap(t));
  if (session) {
    setBearerToken(session);
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const getLayout = (Component as any).getLayout || ((page) => page);

  return (
    <>
      <Head>
        <title>Building Tomorrow&apos;s supply chains today</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
      </Head>
      <main>
        <FeatureFlagsContext.Provider value={toggles}>
          <SessionWithRefreshProvider session={session}>
            <QueryClientProvider client={queryClient}>
              <QueryParamProvider adapter={QueryParamProviderAdapter}>
                <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
                  <PageDataProvider>
                    <PageComponent>
                      <NotificationsProvider position="top-right">
                        <PushNotificationSubscriptionProvider>
                          <RouterTransition />
                          {getLayout(<Component {...props} />)}
                          <ThirdPartyScripts />
                        </PushNotificationSubscriptionProvider>
                      </NotificationsProvider>
                    </PageComponent>
                  </PageDataProvider>
                </MantineProvider>
              </QueryParamProvider>
            </QueryClientProvider>
          </SessionWithRefreshProvider>
        </FeatureFlagsContext.Provider>
      </main>
    </>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default appWithTranslation(CustomApp as any, nextI18nextConfig);

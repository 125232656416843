import { Input, TextInput, TextInputProps, createStyles } from '@mantine/core';
import { useState } from 'react';

import { CountrySelector, CountrySelectorProps, SelectOptionProps } from '@/components';

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  label: {
    width: '100%',
  },
  select: {
    width: 104,
    marginRight: 4,
  },
  input: {
    flexGrow: 1,

    [theme.fn.smallerThan('xs')]: {
      width: 0,
    },
  },
  selectInput: {
    paddingRight: 24,
  },
  error: {
    maxWidth: 100,
  },
}));
export interface PhoneNumberProps extends TextInputProps {
  data: SelectOptionProps[];
  controlTextInput: TextInputProps;
  countrySelectorProps?: Partial<CountrySelectorProps>;
}

export function PhoneNumber(props: PhoneNumberProps) {
  const { countrySelectorProps, data, classNames, placeholder, controlTextInput, ...textInputProps } = props;
  const { onChange, ...selectProps } = countrySelectorProps;
  const [countryCode, setCountryCode] = useState(
    selectProps.value ? selectProps.value.split('_')[1].toLocaleLowerCase() : ''
  );
  const { classes } = useStyles();

  return (
    <Input.Wrapper
      classNames={{
        root: classes.wrapper,
        label: classes.label,
      }}
      id="phone-number"
      {...textInputProps}
    >
      <CountrySelector
        classNames={{
          ...classNames,
          root: classes.select,
          error: classes.error,
          input: classes.selectInput,
        }}
        data={data}
        countryCode={countryCode}
        itemComponent={CountrySelector.selectOption}
        searchable
        filterDataOnExactSearchMatch
        filter={(value, item: SelectOptionProps) =>
          item.name?.toLowerCase().includes(value) || item.label.includes(value)
        }
        onChange={(value) => {
          onChange(value);
          setCountryCode(value.split('_')[1].toLocaleLowerCase());
        }}
        data-testid="phoneNumberPrefix"
        sx={{ height: 'auto' }}
        {...selectProps}
      />
      <TextInput
        id="phone-number"
        placeholder={placeholder}
        type="number"
        data-testid="phoneNumber"
        classNames={{
          ...classNames,
          root: classes.input,
        }}
        sx={{ height: 'auto' }}
        {...controlTextInput}
      />
    </Input.Wrapper>
  );
}

import { useGetCompanyPlants } from 'api/generated/companies/companies';
import { PlantRelatedPorts } from 'api/generated/model';

export type UseFilteredCompanyPortsFilters =
  | {
      byProductId: string;
      byPlantId: string;
      byType: keyof PlantRelatedPorts;
    }
  | {
      byProductId?: never;
      byPlantId?: never;
      byType?: never;
    };

export function useFilteredCompanyPorts(
  companyId: string,
  { byPlantId, byType, byProductId }: UseFilteredCompanyPortsFilters,
  useProductFilter = true
) {
  return useGetCompanyPlants(companyId, {
    query: {
      select: ({ data }) =>
        data.items
          .filter((plant) => plant.id === byPlantId)
          .flatMap((plant) => plant.relatedPorts[byType])
          .filter((port) =>
            useProductFilter ? port.products.some((product) => product.id === byProductId) : port.products
          ),
    },
  });
}

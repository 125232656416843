import { z } from 'zod';

import { DemurrageRateConditions, DemurrageEvents, DemurrageType } from '../../types';

export const cargoTenderDemurrageSchema = z
  .object({
    rate: z.number().min(1).optional(),
    rateCondition: z.nativeEnum(DemurrageRateConditions).optional(),
    rateEvent: z.nativeEnum(DemurrageEvents).optional(),
    type: z.nativeEnum(DemurrageType),
    openAccountMaxDays: z.number().optional(),
    openAccountMaxDaysEvent: z.string().min(1).optional(),
  })
  .refine(
    (data) => {
      if (data.type == 'OPEN_ACCOUNT') {
        return data.openAccountMaxDays !== undefined;
      }
      return true;
    },
    {
      message: 'This field is required',
      path: ['openAccountMaxDays'],
    }
  )
  .refine(
    (data) => {
      if (data.type == 'OPEN_ACCOUNT') {
        return data.openAccountMaxDaysEvent !== undefined;
      }
      return true;
    },
    {
      message: 'This field is required',
      path: ['openAccountMaxDaysEvent'],
    }
  );

export type CargoTenderDemurrage = z.infer<typeof cargoTenderDemurrageSchema>;

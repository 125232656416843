import { Box, createStyles, Divider, Stack } from '@mantine/core';
import { Fragment, ReactElement } from 'react';

const useStyles = createStyles((theme) => ({
  divider: {
    border: 0,
    borderTopWidth: 1,
    borderColor: theme.colors.gray[1],
    borderStyle: 'solid',
    margin: '12px 0',

    [theme.fn.largerThan('md')]: {
      height: 'auto',
      borderTopWidth: 0,
      borderLeftWidth: 1,
      margin: '0 24px',
    },
  },
}));

function StackDivider() {
  const { classes } = useStyles();

  return <Box className={classes.divider} />;
}

interface StackWithDividerProps {
  children: ReactElement | ReactElement[];
}

export function StackWithDivider(props: StackWithDividerProps) {
  const { children } = props;

  const elements = Array.isArray(children) ? children : [children];

  return (
    <Stack spacing={12}>
      {elements.filter(Boolean).map((item, i, filteredArray) => (
        <Fragment key={i}>
          {item}
          {i !== filteredArray.length - 1 && <Divider color="gray.1" size="xs" />}
        </Fragment>
      ))}
    </Stack>
  );
}

StackWithDivider.Divider = StackDivider;

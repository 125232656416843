import NextLink from 'next/link';
import { Group, GroupProps, createStyles } from '@mantine/core';
import { useGetCompany } from 'api/generated/companies/companies';
import { CompanyStatus } from 'api/generated/model';
import { ReactNode } from 'react';

import { routes } from '@/common/routes';
import { StatusBlinkBadge, Title, Avatar } from '@/components';

const useStyles = createStyles((theme) => ({
  titleGroup: {
    maxWidth: '60%',
    cursor: 'pointer',
    h3: {
      cursor: 'pointer',
    },
    [theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
    },
  },
}));

interface CompanyHeaderProps extends GroupProps {
  companyName: string;
  companyStatus?: CompanyStatus;
  companyId: string;
  logoSrc?: string;
  children?: ReactNode;
}

export function CompanyHeader({
  children,
  companyName,
  companyStatus: companyStatusProp,
  logoSrc: logoSrcProp,
  companyId,
  ...restProps
}: CompanyHeaderProps) {
  const { classes } = useStyles();

  const isFallbacking = Boolean((!companyStatusProp || !logoSrcProp) && companyId);

  const { data: company } = useGetCompany(companyId, {
    query: { select: ({ data }) => data, enabled: isFallbacking },
  });

  const companyStatus = companyStatusProp ?? company?.status;
  const logoSrc = logoSrcProp ?? company?.logoUrl;

  return (
    <Group position="apart" {...restProps}>
      <NextLink href={routes.CompanyProfile(companyId)} className={classes.titleGroup}>
        <Group noWrap role="button">
          <Avatar size={40} src={logoSrc} alt={`${companyName} logo`} />
          <Title order={3} size="XL" color="deepBlue" sx={{ wordBreak: 'break-word' }}>
            {companyName}
          </Title>
          <StatusBlinkBadge status={companyStatus} />
        </Group>
      </NextLink>
      {children}
    </Group>
  );
}

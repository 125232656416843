import { Button, Paper } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useTranslation } from 'next-i18next';

import { Text } from '@/components';
import { usePushNotificationSubscriptionContext } from '@/core/providers';

export const GrantNotificationPermissionsBanner = () => {
  const { t } = useTranslation('common');
  const { isNotificationPermissionGranted, requestPermissionAndSubscribe } = usePushNotificationSubscriptionContext();

  const handleRequestNotificationPermission = async () => {
    const permissionsGranted = await requestPermissionAndSubscribe();

    if (!permissionsGranted) {
      showNotification({
        color: 'red',
        title: t('pushNotifications.error.permissionDenied'),
      });
    }
  };

  return isNotificationPermissionGranted ? null : (
    <Paper withBorder p={8}>
      <Text size={'XS'}>{t('pushNotifications.status')}</Text>
      <Button onClick={handleRequestNotificationPermission} p={0} variant="subtle" compact size={'xs'}>
        {t('pushNotifications.requestPermission')}
      </Button>
    </Paper>
  );
};

import { Loader } from '@mantine/core';
import { signOut as nextAuthSignOut } from 'next-auth/react';

export async function signOut() {
  return await nextAuthSignOut({
    redirect: true,
    callbackUrl: '/auth/signin',
  });
}

export default function Signout() {
  return <Loader size="lg" pos="fixed" left="50%" top="50%" transform="translate(-50%, -50%)" />;
}

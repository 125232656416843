import { Container, createStyles, Highlight, Stack, ThemeIcon } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { RiMailSendLine } from 'react-icons/ri';

import { NeedHelp, Title } from '@/components';

const useStyles = createStyles((theme) => ({
  title: {
    maxWidth: 270,
    marginBottom: 16,

    [theme.fn.smallerThan('xs')]: {
      marginBottom: 12,
    },
  },
  container: {
    backgroundColor: theme.white,
    borderRadius: theme.radius.md,
    padding: '32px 24px',
  },
  text: {
    color: theme.colors.gray[7],
  },
  anchor: {
    fontWeight: 600,
    paddingBottom: 4,
    borderBottom: '1px solid',
  },
  icon: {
    backgroundColor: 'transparent',
    color: theme.fn.primaryColor(),
    fontSize: 40,
  },
  stack: {
    gap: 24,

    [theme.fn.smallerThan('xs')]: {
      gap: 20,
    },
  },
}));

export interface AccountVerifiedProps {
  email: string;
}

export function AccountVerified(props: AccountVerifiedProps) {
  const { t } = useTranslation('register');
  const { email } = props;
  const { classes, theme } = useStyles();

  return (
    <Stack className={classes.stack} align={'center'}>
      <ThemeIcon className={classes.icon} size={40}>
        <RiMailSendLine />
      </ThemeIcon>
      <Title className={classes.title} order={1} size="2XL" align={'center'}>
        {t('verification.header')}
      </Title>
      <Container size="sm" className={classes.container}>
        <Stack spacing="xl">
          <Highlight
            className={classes.text}
            highlight={email}
            highlightStyles={{
              backgroundColor: 'transparent',
              color: theme.fn.primaryColor(),
              fontWeight: 600,
            }}
          >
            {t('verification.linkSent', { email })}
          </Highlight>
          <NeedHelp sx={{ alignSelf: 'flex-end' }} />
        </Stack>
      </Container>
    </Stack>
  );
}

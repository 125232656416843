import { z } from 'zod';

import { zDateRange, zTolerancePercentage } from '../types';
import {
  conditionalRequestFreightFullSchema,
  conditionalRequestFreightSchema,
  offerVisibilitySchema,
} from './promoting-extension';

export const requestPromotedOfferBaseSchema = z.object({
  quantity: z.number().min(0),
  product: z.string(),
  toleranceMinPercentage: zTolerancePercentage,
  toleranceMaxPercentage: zTolerancePercentage,
  plant: z.string(),
  port: z.string(),
  eta: zDateRange,
});

export const requestPromotedOfferFullSchema = conditionalRequestFreightFullSchema
  .and(requestPromotedOfferBaseSchema)
  .and(offerVisibilitySchema);

export const requestPromotedOfferSchema = conditionalRequestFreightSchema
  .and(requestPromotedOfferBaseSchema)
  .and(offerVisibilitySchema);

export type RequestPromotedOfferForm = z.infer<typeof requestPromotedOfferSchema>;
export type RequestPromotedOfferFullForm = z.infer<typeof requestPromotedOfferFullSchema>;

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoTenderDemurrageDTOType =
  (typeof CargoTenderDemurrageDTOType)[keyof typeof CargoTenderDemurrageDTOType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoTenderDemurrageDTOType = {
  OPEN_ACCOUNT: 'OPEN_ACCOUNT',
  PROVISION_TO_SELLER: 'PROVISION_TO_SELLER',
  PROVISION_TO_TRUSTED_PARTY: 'PROVISION_TO_TRUSTED_PARTY',
} as const;

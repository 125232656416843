/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type GetMarketplaceCargoItemResponseDtoCargoType =
  (typeof GetMarketplaceCargoItemResponseDtoCargoType)[keyof typeof GetMarketplaceCargoItemResponseDtoCargoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetMarketplaceCargoItemResponseDtoCargoType = {
  OFFER: 'OFFER',
  NEED: 'NEED',
  WORKFLOW: 'WORKFLOW',
} as const;

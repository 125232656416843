export const CONTACT_URL = process.env.NEXT_PUBLIC_CSM_EMAIL_ADDRESS || '';
export const MAIL_TO_CONTACT_URL = `mailto:${CONTACT_URL}`;
export const REDIRECT_AFTER_LOGIN_COOKIE_NAME = 'redirectAfterLogin';
export const REDIRECT_AFTER_LOGOUT_COOKIE_NAME = 'redirectAfterLogout';

export const REFRESH_TIME_OFFSET = parseInt(process.env.NEXTAUTH_REFRESH_TIME_OFFSET, 10);

export const DEFAULT_FREIGHT_ESTIMATE_COMMISION = 0.0125;

export const MAX_COMPANIES_TO_SELECT = 5;

export const STANDARD_DRAWER_WIDTH = 800;

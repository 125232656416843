import { Select, SelectProps } from '@mantine/core';
import { FreightPaymentTerms } from 'api/generated/model';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { sortSelectItems } from '@/common/arrays';
import { useContainedLabelStyles } from '@/common/use-contained-label-styles';

export interface PrepaymentInputProps extends Omit<SelectProps, 'data'> {
  containedLabel?: boolean;
}

export function PrepaymentInput(props: PrepaymentInputProps) {
  const { containedLabel = false } = props;

  const { t } = useTranslation('cargoListings');
  const { classes } = useContainedLabelStyles();

  const paymentConditions = useMemo(
    () =>
      sortSelectItems(
        Object.values(FreightPaymentTerms).map((value) => ({ label: t(`paymentTerms.${value}`), value }))
      ),
    [t]
  );

  return (
    <Select
      size="md"
      label={t('labels.prepayment')}
      data-testid={'prepayment'}
      placeholder={t('placeholders.preferredFreightPayment')}
      data={paymentConditions}
      classNames={containedLabel ? classes : undefined}
      {...props}
    />
  );
}

import { z } from 'zod';
import { TFunction } from 'next-i18next';

export const serviceProviderContactsSchema = z.object({
  contactId: z.string().optional(),
  email: z.string().email(),
  name: z.string().nonempty(),
  surname: z.string().nonempty(),
  mobilePhoneNumber: z.string().min(5, 'Phone number is too short').max(25, 'Phone number is too long').nullable(),
});

export const createServiceProviderSchema = (t?: TFunction) =>
  z
    .object({
      companyName: z.string().nonempty(),
      otherProviderType: z.string().nullable(),
      serviceProviderType: z.string().nonempty(),
      contacts: z.array(serviceProviderContactsSchema),
      operatingPorts: z.array(z.string().nonempty()).min(1),
      operatingCountries: z.array(z.string().nonempty()).min(1),
    })
    .refine(
      (data) => {
        if (data.serviceProviderType === 'OTHER') {
          return data.otherProviderType && data.otherProviderType.trim().length > 0;
        }
        return true;
      },
      {
        message: t ? t('errors.required') : 'This field is required',
        path: ['otherProviderType'],
      }
    );

export const createMyServiceProvidersSchema = (t?: TFunction) => {
  const serviceProviderSchema = createServiceProviderSchema(t);

  return z.object({
    serviceProviders: z.array(serviceProviderSchema).min(1),
  });
};

export type ServiceProviderFormSchema = z.infer<ReturnType<typeof createServiceProviderSchema>>;
export type MyServiceProviders = z.infer<ReturnType<typeof createMyServiceProvidersSchema>>;
export type ServiceProviderContactForm = z.infer<typeof serviceProviderContactsSchema>;

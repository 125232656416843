import { UseFormReturnType } from '@mantine/form';
import { CargoTransportation } from 'api/generated/model';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';

import { GetPaths } from '@/common/types';

import recapFormMasterdata from '../masterdata.json';
import {
  CargoTemplate,
  FrontendRecapForm,
  InsertableFormGroup,
  PaymentConditionType,
  SelectedPaymentConditions,
} from '../types';
import { useStandardData } from '../utils/use-standard-data';
import { useRecapForm, RefineSchemaFn } from './use-recap-form';

const RecapFormLogicProvider = createContext<RecapFormLogicProviderState>({} as RecapFormLogicProviderState);

export interface RecapFormProviderProps {
  children: ReactNode;
  sellerCompanyId: string;
  buyerCompanyId: string;
  offeredTransport: CargoTransportation;
  template?: CargoTemplate;

  initialValues: Partial<FrontendRecapForm>;
  initialSelectedPaymentConditions: SelectedPaymentConditions;
  isIncotermFreezed?: boolean;
  refineSchema?: RefineSchemaFn;
}

interface RecapFormLogicProviderState {
  selectedPaymentConditions: SelectedPaymentConditions;
  togglePaymentCondition: (condition: PaymentConditionType) => void;
  form: UseFormReturnType<FrontendRecapForm>;
  isIncotermFreezed: boolean;
  initialValues: FrontendRecapForm;
  offeredTransport: CargoTransportation;
  standardData: ReturnType<typeof useStandardData>;
  sellerCompanyId: string;
  selectedIncoterm: CargoTransportation;
  setSelectedIncoterm: Dispatch<SetStateAction<CargoTransportation>>;
}

export function RecapFormProvider(props: RecapFormProviderProps) {
  const {
    children,
    offeredTransport,
    sellerCompanyId,
    buyerCompanyId,
    template,
    initialValues,
    initialSelectedPaymentConditions,
    isIncotermFreezed,
    refineSchema,
  } = props;

  const { productName, originPlantCountry: origin } = initialValues.product.cargo;

  const form = useRecapForm(initialValues, refineSchema);

  const [selectedPaymentConditions, setSelectedPaymentConditions] = useState<SelectedPaymentConditions>(
    initialSelectedPaymentConditions
  );
  const [selectedIncoterm, setSelectedIncoterm] = useState<CargoTransportation>(offeredTransport);

  useEffect(() => {
    if (template && template.id) {
      form.setValues(initialValues);
      setSelectedPaymentConditions(initialSelectedPaymentConditions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  const togglePaymentCondition = (condition: PaymentConditionType) =>
    setSelectedPaymentConditions((state) => {
      form.setFieldValue(`payment.conditions.${condition}`, state[condition].checked ? undefined : {});
      const checked = !state[condition].checked;
      const isDirty = checked !== initialSelectedPaymentConditions[condition].checked;
      return { ...state, [condition]: { checked, isDirty } };
    });
  const standardData = useStandardData({ sellerCompanyId, buyerCompanyId }, { origin, productName });

  return (
    <RecapFormLogicProvider.Provider
      value={{
        form,
        selectedPaymentConditions,
        togglePaymentCondition,
        standardData,
        offeredTransport,
        isIncotermFreezed,
        initialValues,
        sellerCompanyId,
        selectedIncoterm,
        setSelectedIncoterm,
      }}
    >
      {children}
    </RecapFormLogicProvider.Provider>
  );
}

export type InsertableFormGroupIndex = 0 | 1;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function provideInsertableFormGroup<T extends any[]>(
  form: UseFormReturnType<FrontendRecapForm>,
  path: string,
  value: T
): InsertableFormGroup<T> {
  return {
    insert: () => form.insertListItem(path, {}),
    remove: (index: number) => form.removeListItem(path, index),
    canInsert: value?.length < 2,
    canRemove: value?.length === 2,
    value,
  };
}

export function useRecapFormContext() {
  const {
    selectedPaymentConditions,
    togglePaymentCondition,
    form,
    standardData,
    offeredTransport,
    isIncotermFreezed,
    initialValues,
    sellerCompanyId,
    selectedIncoterm,
    setSelectedIncoterm,
  } = useContext(RecapFormLogicProvider);
  const getInputProps = (path: GetPaths<FrontendRecapForm, InsertableFormGroupIndex>) => ({
    ...form.getInputProps(path),
    isDirty: form.isDirty(path),
  });
  const { weight, samplingAndAnalysis } = form.values.product;
  const weightLoadportSurveyor = provideInsertableFormGroup(
    form,
    'product.weight.loadportSurveyors',
    weight.loadportSurveyors
  );
  const weightDischargeSurveyor = provideInsertableFormGroup(
    form,
    'product.weight.dischargeSurveyors',
    weight.dischargeSurveyors
  );
  const samplingSurveyorsNames = provideInsertableFormGroup(
    form,
    'product.samplingAndAnalysis.surveyorNames',
    samplingAndAnalysis.surveyorNames
  );
  const samplingSurveyorsAtDischarge = provideInsertableFormGroup(
    form,
    'product.samplingAndAnalysis.surveyorsAtDischargePort',
    samplingAndAnalysis.surveyorsAtDischargePort
  );
  const samplingAnalysis = provideInsertableFormGroup(
    form,
    'product.samplingAndAnalysis.analysis',
    samplingAndAnalysis.analysis
  );

  return {
    form,
    getInputProps,
    selectedPaymentConditions,
    togglePaymentCondition,
    weightLoadportSurveyor,
    weightDischargeSurveyor,
    samplingSurveyorsNames,
    samplingSurveyorsAtDischarge,
    samplingAnalysis,
    masterdata: recapFormMasterdata,
    standardData,
    offeredTransport,
    isIncotermFreezed,
    initialValues,
    sellerCompanyId,
    selectedIncoterm,
    setSelectedIncoterm,
  };
}

import { ActionIcon, Button, Group, useMantineTheme } from '@mantine/core';
import { CellContext, ColumnDef, ColumnDefTemplate, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { GetDetailedOfferTemplateResponseDto } from 'api/generated/model';
import { RiDeleteBin7Line } from 'react-icons/ri';

import { CargoIdCell, DateCell, Table } from '@/components';

import { DetailedOfferTemplateActionsFunction } from '../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ActionsCellProps extends CellContext<any, string> {
  onDelete: DetailedOfferTemplateActionsFunction;
  onUseTemplate: DetailedOfferTemplateActionsFunction;
}

const ActionsCell: ColumnDefTemplate<ActionsCellProps> = ({ row, onDelete, onUseTemplate }) => {
  const { t } = useTranslation('recapForm');
  const theme = useMantineTheme();

  return (
    <Group spacing={16} position="right" sx={{ marginLeft: 'auto' }}>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          onUseTemplate(row);
        }}
      >
        {t('buttons.useTemplate')}
      </Button>
      <ActionIcon
        variant="transparent"
        onClick={(e) => {
          e.stopPropagation();
          onDelete(row);
        }}
      >
        <RiDeleteBin7Line color={theme.colors.gray[6]} />
      </ActionIcon>
    </Group>
  );
};

const columnHelper = createColumnHelper<GetDetailedOfferTemplateResponseDto>();

interface useColumnsProps {
  onDelete: DetailedOfferTemplateActionsFunction;
  onUseTemplate: DetailedOfferTemplateActionsFunction;
}

function useColumns({ onDelete, onUseTemplate }: useColumnsProps) {
  const { t } = useTranslation('recapForm');

  const columns: ColumnDef<GetDetailedOfferTemplateResponseDto>[] = useMemo(
    () => [
      columnHelper.accessor('templateName', {
        header: t('modals.loadTemplate.table.templateName'),
        cell: CargoIdCell,
      }),
      columnHelper.accessor('createdAt', {
        header: t('modals.loadTemplate.table.createdAt'),
        cell: DateCell,
      }),
      columnHelper.accessor('id', {
        header: '',
        enableSorting: false,
        cell: (props) => <ActionsCell {...props} onDelete={onDelete} onUseTemplate={onUseTemplate} />,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return columns;
}

export interface LoadDetailedOfferTemplateTableProps {
  data: GetDetailedOfferTemplateResponseDto[];
  isLoading: boolean;
  onDelete: DetailedOfferTemplateActionsFunction;
  onUseTemplate: DetailedOfferTemplateActionsFunction;
}

export function LoadDetailedOfferTemplateTable(props: LoadDetailedOfferTemplateTableProps) {
  const { data, isLoading, onDelete, onUseTemplate } = props;
  const columns = useColumns({ onDelete, onUseTemplate });

  return (
    <Table.Card bordered>
      <Table columns={columns} data={data} isLoading={isLoading} />
    </Table.Card>
  );
}

import { Button, createStyles, Group, Stack, ThemeIcon } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { RiErrorWarningFill } from 'react-icons/ri';
import { StringParam, useQueryParam } from 'use-query-params';
import { showNotification } from '@mantine/notifications';
import { useQueryClient } from '@tanstack/react-query';
import {
  getGetCompanyCargoNegotiationsQueryKey,
  getGetCargoNegotiationHistoryQueryKey,
  usePostCargoAccept,
} from 'api/generated/cargo-negotiation/cargo-negotiation';
import { getGetBookedCargosQueryKey } from 'api/generated/workflow/workflow';

import { Text, BaseModal } from '@/components';
import { useToastNotifications } from '@/common/use-toast-notifications';

const useStyles = createStyles((theme) => ({
  themeIcon: {
    width: 40,
    height: 40,
    borderRadius: theme.radius.md,
  },
  icon: {
    color: theme.colors.orange[5],
    fontSize: 20,
  },
}));

interface AcceptModalContentProps {
  onBackClick: VoidFunction;
  onNextClick: VoidFunction;
  loading: boolean;
}

const AcceptModalContent = (props: AcceptModalContentProps) => {
  const { onBackClick, onNextClick, loading } = props;
  const { t } = useTranslation('myCargos');

  return (
    <Stack>
      <Text sx={{ color: '#535A60' }} align="center" size="S">
        {t('modals.cancel.descriptions.cannotUndo')}
      </Text>
      <Group grow>
        <Button onClick={() => onBackClick()} variant="outline">
          {t('modals.cancel.buttons.close')}
        </Button>
        <Button loading={loading} onClick={() => onNextClick()}>
          {t('modals.cancel.buttons.confirm')}
        </Button>
      </Group>
    </Stack>
  );
};

export interface AcceptModalProps {
  opened: boolean;
  toggle: VoidFunction;
}

export function AcceptModal(props: AcceptModalProps) {
  const [negotiationId, _] = useQueryParam('negotiationId', StringParam);
  const { t } = useTranslation('myCargos');
  const { opened, toggle } = props;
  const { classes } = useStyles();
  const { mutate, isLoading } = usePostCargoAccept();
  const queryClient = useQueryClient();
  const { displayApiError } = useToastNotifications();
  const onNextClick = () =>
    mutate(
      { negotiationId },
      {
        onSuccess: () => {
          toggle();
          showNotification({
            color: 'green',
            title: t('notifications.acceptNegotiation.title'),
            message: t('notifications.acceptNegotiation.message'),
          });
          queryClient.invalidateQueries(getGetCompanyCargoNegotiationsQueryKey());
          queryClient.invalidateQueries(getGetCargoNegotiationHistoryQueryKey(negotiationId));
          queryClient.invalidateQueries(getGetBookedCargosQueryKey());
        },
        onError: displayApiError,
      }
    );

  return (
    <BaseModal
      icon={
        <ThemeIcon className={classes.themeIcon} color="orange.0">
          <RiErrorWarningFill className={classes.icon} />
        </ThemeIcon>
      }
      opened={opened}
      close={toggle}
      header={t('modals.accept.headers.acceptConfirm')}
      content={<AcceptModalContent onBackClick={toggle} onNextClick={onNextClick} loading={isLoading} />}
    />
  );
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * freight-algorithm-api
 * Freight algorithm calculator
 * OpenAPI spec version: v1
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import type { UseMutationOptions, MutationFunction } from '@tanstack/react-query';

import type { UnathorizedResponse, FreightAlgorithmRequest } from '.././model';

/**
 * Gets freight algorithm caculus based on input params
 * @summary Freight calculator
 */
export const calculator = (
  freightAlgorithmRequest: FreightAlgorithmRequest,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<unknown>> => {
  return axios.post(`/calculator`, freightAlgorithmRequest, options);
};

export type CalculatorMutationResult = NonNullable<Awaited<ReturnType<typeof calculator>>>;
export type CalculatorMutationBody = FreightAlgorithmRequest;
export type CalculatorMutationError = AxiosError<UnathorizedResponse>;

export const useCalculator = <TError = AxiosError<UnathorizedResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof calculator>>,
    TError,
    { data: FreightAlgorithmRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof calculator>>, { data: FreightAlgorithmRequest }> = (
    props
  ) => {
    const { data } = props ?? {};

    return calculator(data, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof calculator>>, TError, { data: FreightAlgorithmRequest }, TContext>(
    mutationFn,
    mutationOptions
  );
};

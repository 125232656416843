import { createStyles } from '@mantine/core';

export const useAlertStyle = createStyles((theme) => ({
  title: {
    marginBottom: 0,
  },
  icon: {
    marginTop: 15,
  },
  root: {
    border: '1px solid',
    borderColor: 'transparent',
  },
  message: {
    paddingTop: 1,
    lineHeight: 1.5,
    fontSize: theme.fontSizes.sm,
  },
}));

import { useSession } from 'next-auth/react';
import { UserRole, CompanyRole, CompanyType, UserCompanyStatus } from 'api/generated/model';

import {
  useAuthRoles,
  useIsMIUser,
  hasAllowedCompanyStatus as validateCompanyStatusPermission,
} from './use-auth-roles';

interface UseHasPermissionResponse {
  isCompanyVerified: boolean;
  isMIOnlyUser: boolean;
  isPassive: boolean;
  isShortlisted: boolean;
  isServiceProvider: boolean;
  isPrivate: boolean;
  hasAllPermissions: (allowedRoles: UserRole[]) => boolean;
  hasUserRolePermission: (allowedRoles: UserRole[]) => boolean;
  hasCompanyPermission: (allowedCompanyTypes: CompanyType[]) => boolean;
  hasAllowedCompanyStatus: (allowedStatus: UserCompanyStatus[]) => boolean;
  hasAllowedCompanyRoles: (allowedRoles: CompanyRole[] | CompanyRole) => boolean;
}

export const useHasPermission = (): UseHasPermissionResponse => {
  const {
    roles,
    companyType,
    companyRoles,
    companyStatus,
    isCompanyVerified,
    isPassive,
    isShortlisted,
    isCompanyPrivate,
    isServiceProvider,
  } = useAuthRoles();
  const isMIOnlyUser = useIsMIUser();

  const hasUserRolePermission = (allowedRoles: UserRole[]) => {
    return [UserRole.ADMIN, ...allowedRoles].some((role) => roles.includes(role));
  };

  const hasCompanyPermission = (allowedCompanyTypes: CompanyType[]) => {
    return allowedCompanyTypes.some((role) => role === companyType);
  };

  const hasAllowedCompanyRoles = (allowedRoles: CompanyRole[] | CompanyRole) => {
    const allowedRolesArray = Array.isArray(allowedRoles) ? allowedRoles : [allowedRoles];

    return companyRoles.some((role) => allowedRolesArray.includes(role));
  };

  const hasAllowedCompanyStatus = (allowedStatus: UserCompanyStatus[]) => {
    return validateCompanyStatusPermission(allowedStatus, companyStatus);
  };

  const hasAllPermissions = (allowedRoles: UserRole[]) => {
    return [...allowedRoles].every((role) => roles.includes(role));
  };

  return {
    isCompanyVerified,
    isMIOnlyUser,
    isPassive,
    isShortlisted,
    isPrivate: isCompanyPrivate,
    hasAllPermissions,
    hasCompanyPermission,
    hasUserRolePermission,
    hasAllowedCompanyRoles,
    hasAllowedCompanyStatus,
    isServiceProvider,
  };
};

export const useHasAnyUserCompanyPermission = () => {
  const {
    data: {
      me: { companies },
    },
  } = useSession();
  const allUserRoles = [...new Set(companies.flatMap(({ companyRoles }) => companyRoles, []))];

  const allCompaniesTypes = companies.reduce<CompanyType[]>((aggr, curr) => {
    if (aggr.includes(curr.companyType)) {
      return aggr;
    }

    return [...aggr, curr.companyType];
  }, []);

  const hasUserRolePermission = (allowedRoles: UserRole[], unallowedRoles: UserRole[] = []) => {
    return (
      [UserRole.ADMIN, ...allowedRoles].some((role) => allUserRoles.includes(role)) &&
      !unallowedRoles.some((role) => allUserRoles.includes(role)) // @TODO cover with tests
    );
  };

  const hasCompanyTypePermission = (allowedCompanyTypes: CompanyType[]) => {
    return allowedCompanyTypes.some((role) => allCompaniesTypes.includes(role));
  };

  const hasAccess = (allowedRoles: UserRole[], allowedCompanyTypes: CompanyType[]) => {
    return hasCompanyTypePermission(allowedCompanyTypes) && hasUserRolePermission(allowedRoles);
  };

  const isUserInCompany = (companyId: string) => {
    const userCompanies = companies.map(({ companyId }) => companyId);

    return userCompanies.some((id) => id === companyId);
  };

  return { hasUserRolePermission, hasCompanyTypePermission, hasAccess, isUserInCompany };
};

import { z } from 'zod';

import { AnalysisTypes } from '../types';

export const cargoTenderAnalysisSchema = z.object({
  mainAnalysisConditions: z.string().optional(),
  mainAnalysisName: z.string().optional(),
  mainAnalysisType: z.nativeEnum(AnalysisTypes),
});

export type CargoTenderAnalysisSchema = z.infer<typeof cargoTenderAnalysisSchema>;

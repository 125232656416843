import { TabsProps } from '@mantine/core';

import { ThemeComponent } from './types';

export const Tabs: ThemeComponent<TabsProps> = {
  styles: (theme, params) => ({
    tab: {
      color: theme.colors.gray[6],
      padding: '16px 24px',

      [theme.fn.smallerThan('md')]: {
        padding: '12px 8px',
      },

      '&[data-active]': {
        color: theme.fn.primaryColor(),
      },

      ...(params.variant === 'pills' && {
        padding: '4px 12px',
        color: theme.colors.gray[7],

        [theme.fn.smallerThan('md')]: {
          padding: '4px 8px',
        },

        '&[data-active="true"]': {
          color: theme.white,
        },

        '@media (hover: hover)': {
          '&:hover': {
            backgroundColor: 'unset',
          },
        },
      }),
    },
    tabLabel: {
      backgroundColor: 'transparent',
      fontSize: 16,
      lineHeight: '24px',
      textTransform: 'uppercase',
      fontWeight: 600,

      [theme.fn.smallerThan('md')]: {
        fontSize: 14,
        lineHeight: '22px',
      },
    },
    tabsList: {
      ...(params.variant === 'pills' && {
        padding: 2,
        width: 'fit-content',
        backgroundColor: theme.colors.gray[2],
        borderRadius: theme.radius.md,
      }),
    },
  }),
};

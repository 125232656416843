import { createStyles, Flex, FlexProps } from '@mantine/core';
import ChannelUI from '@sendbird/uikit-react/Channel/components/ChannelUI';
import { ChannelProvider } from '@sendbird/uikit-react/Channel/context';
import '@sendbird/uikit-react/dist/index.css';

import { SendbirdMessage } from './SendbirdMessage';
import { SendbirdMessageInput } from './SendbirdMessageInput';
import { SendbirdStyles } from './SendbirdStyles';

const useStyles = createStyles(() => ({
  chatContainer: {
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'scroll',
    zIndex: 101,
  },
}));

export interface SendbirdChatProps extends FlexProps {
  channelURL: string;
  otherCompanyName: string;
}

export function SendbirdChat(props: SendbirdChatProps) {
  const { channelURL, otherCompanyName, ...other } = props;
  const { classes } = useStyles();

  return (
    <Flex align="stretch" {...other} className={classes.chatContainer} data-chat-ui>
      <SendbirdStyles />
      <ChannelProvider channelUrl={channelURL}>
        <ChannelUI
          renderMessage={(args) => <SendbirdMessage {...args} otherCompanyName={otherCompanyName} />}
          renderChannelHeader={() => <></>}
          renderMessageInput={() => <SendbirdMessageInput channelURL={channelURL} />}
        />
      </ChannelProvider>
    </Flex>
  );
}

import { z } from 'zod';

import { fixedQuantitySchema, optionalQuantitySchema } from './cargo-tender-common';

export const cargoTenderFormVolumeSchema = z.object({
  key: z.string().optional(),
  name: z.string().optional(),
  companyProductId: z.string(),
  fixedQuantity: fixedQuantitySchema,
  optionalQuantity: optionalQuantitySchema,
});

export type CargoTenderFormVolumeSchema = z.infer<typeof cargoTenderFormVolumeSchema>;

import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  gradient: {
    background:
      'radial-gradient(141.80% 141.80% at 73.96% 141.67%, rgba(86, 213, 218, 0.75) 18.19%, rgba(86, 213, 218, 0.00) 100%), radial-gradient(84.37% 84.37% at -6.25% 41.67%, #816BD7 0%, rgba(129, 107, 215, 0.00) 100%), #233240',
  },
}));

export const useGradientBackgroundStyles = () => {
  const { classes } = useStyles();

  return classes.gradient;
};

// eslint-disable-next-line no-restricted-imports
import { createStyles, MantineTheme, Text as MnText, TextProps as MnTextProps } from '@mantine/core';
import { forwardRef } from 'react';

export type TextSize = 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL';

const getTextStyles = (size: TextSize, theme: MantineTheme) => {
  switch (size) {
    case 'XXL':
      return {
        fontSize: 24,
        lineHeight: '32px',
      };
    case 'XL':
      return {
        fontSize: 20,
        lineHeight: '28px',
      };
    case 'L': {
      return {
        fontSize: 18,
        lineHeight: '26px',
      };
    }
    case 'M':
      return {
        fontSize: 16,
        lineHeight: '24px',

        [theme.fn.smallerThan('xs')]: {
          fontSize: 14,
          lineHeight: '22px',
        },
      };
    case 'S':
      return {
        fontSize: 14,
        lineHeight: '22px',

        [theme.fn.smallerThan('xs')]: {
          fontSize: 12,
          lineHeight: '18px',
        },
      };
    case 'XS':
      return {
        fontSize: 12,
        lineHeight: '18px',
      };
  }
};

export interface TextProps extends Omit<MnTextProps, 'size'> {
  size?: TextSize;
  role?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any;
  id?: string;
  htmlFor?: string;
}

const useStyles = createStyles((theme, params: TextProps) => ({
  text: {
    cursor: 'inherit',
    ...getTextStyles(params.size, theme),
  },
}));

export const Text = forwardRef<HTMLHeadingElement, TextProps>(function Text(props, ref) {
  const { children, className, classNames, size, ...rest } = props;
  const { classes, cx } = useStyles({ size: size });

  return (
    <MnText ref={ref} className={cx(classes.text, className, classNames)} {...rest}>
      {children}
    </MnText>
  );
});

import { Button, createStyles, Group, Stack, ThemeIcon } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { RiErrorWarningFill } from 'react-icons/ri';

import { Text, BaseModal } from '@/components';

const useStyles = createStyles((theme) => ({
  themeIcon: {
    width: 40,
    height: 40,
    borderRadius: theme.radius.md,
  },
  icon: {
    color: theme.colors.orange[5],
    fontSize: 20,
  },
}));

interface CancelModalContentProps {
  onBackClick: VoidFunction;
  onNextClick: VoidFunction;
  loading: boolean;
}

const CancelModalContent = (props: CancelModalContentProps) => {
  const { onBackClick, onNextClick, loading } = props;
  const { t } = useTranslation('common');

  return (
    <Stack>
      <Text sx={{ color: '#535A60' }} align="center" size="S">
        {t('modals.cannotUndo')}
      </Text>
      <Group grow>
        <Button onClick={() => onBackClick()} variant="outline">
          {t('modals.cancel.buttons.close')}
        </Button>
        <Button loading={loading} onClick={() => onNextClick()}>
          {t('modals.cancel.buttons.discontinue')}
        </Button>
      </Group>
    </Stack>
  );
};

export interface CancelModalProps {
  opened: boolean;
  isLoading: boolean;
  toggle: VoidFunction;
  onCancelClick: () => void;
}

export function CancelModal(props: CancelModalProps) {
  const { t } = useTranslation('common');
  const { opened, isLoading, toggle, onCancelClick } = props;
  const { classes } = useStyles();

  return (
    <BaseModal
      icon={
        <ThemeIcon className={classes.themeIcon} color="orange.0">
          <RiErrorWarningFill className={classes.icon} />
        </ThemeIcon>
      }
      opened={opened}
      close={toggle}
      header={t('modals.cancel.headers.discontinueNegotiations')}
      content={<CancelModalContent onBackClick={toggle} onNextClick={onCancelClick} loading={isLoading} />}
    />
  );
}

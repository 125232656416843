import {
  BerthConditions,
  CostsTermsWithoutShared,
  FreightPeriodTerms,
  FreightTerms,
  NorTendering,
  TurnTimeTerms,
} from '../../types';
import { LoadingDischargingConditionsGeneralInfoSchema } from './loading-discharging-conditions-general-info';
import { LoadingConditionsSchema } from './loading-conditions';
import { DischargingConditionsSchema } from './discharging-conditions';

export const LOADING_DISCHARGING_CONDITIONS_GENERAL_INFO_INITIAL_VALUES: LoadingDischargingConditionsGeneralInfoSchema =
  {
    noOfSafePort: 1,
    noOfSafeBerth: 1,
    berthConditions: BerthConditions.AAAA,
    norTendering: NorTendering.ATDN_PLUS_SSHINC,
    turnTime: 45,
    turnTimeTerms: TurnTimeTerms.EVEN_IF_USED,
    exclusionFromLaytime: [],
    otherExclusionFormLaytime: undefined,
    laytimeCalculations: [],
    cargoPDA: CostsTermsWithoutShared.AT_SELLERS_COST,
    vesselPDA: CostsTermsWithoutShared.AT_SELLERS_COST,
    other: '',
  };

export const LOADING_CONDITIONS_INITIAL_VALUES: LoadingConditionsSchema = {
  ...LOADING_DISCHARGING_CONDITIONS_GENERAL_INFO_INITIAL_VALUES,
  loadingRate: 8000,
  loadingTerms: {
    terms: FreightTerms.SSHINC,
    event: FreightPeriodTerms.PWWD,
  },
};

export const DISCHARGING_CONDITIONS_INITIAL_VALUES: DischargingConditionsSchema = {
  ...LOADING_DISCHARGING_CONDITIONS_GENERAL_INFO_INITIAL_VALUES,
  dischargingRate: 8000,
  dischargingTerms: {
    terms: FreightTerms.SSHINC,
    event: FreightPeriodTerms.PWWD,
  },
};

//Empty values
export const LOADING_CONDITIONS_EMPTY_VALUES = {
  noOfSafeBerth: 1,
  noOfSafePort: 1,
  berthConditions: BerthConditions.AAAA,
  norTendering: NorTendering.ATDN_PLUS_SSHINC,
  turnTime: 45,
  turnTimeTerms: null,
  loadingRate: 8000,
  loadingTerms: {
    terms: null,
    event: null,
  },
  vesselPDA: CostsTermsWithoutShared.AT_SELLERS_COST,
  cargoPDA: CostsTermsWithoutShared.AT_SELLERS_COST,
  other: '',
  exclusionFromLaytime: [],
  laytimeCalculations: [],
  otherExclusionFormLaytime: '',
};

export const DISCHARGING_CONDITIONS_EMPTY_VALUES = {
  noOfSafeBerth: 1,
  noOfSafePort: 1,
  berthConditions: BerthConditions.AAAA,
  norTendering: NorTendering.ATDN_PLUS_SSHINC,
  turnTime: 45,
  turnTimeTerms: null,
  dischargingRate: 8000,
  dischargingTerms: {
    terms: FreightTerms.SSHINC,
    event: FreightPeriodTerms.PWWD,
  },
  vesselPDA: CostsTermsWithoutShared.AT_SELLERS_COST,
  cargoPDA: CostsTermsWithoutShared.AT_SELLERS_COST,
  other: '',
  exclusionFromLaytime: [],
  laytimeCalculations: [],
  otherExclusionFormLaytime: '',
};

import { ActionIcon, createStyles } from '@mantine/core';
import { useRouter } from 'next/router';
import { RiArrowLeftSLine } from 'react-icons/ri';

const useStyles = createStyles((theme) => ({
  icon: {
    color: theme.fn.primaryColor(),
    fontSize: 24,
    transform: 'translateY(2px)',
  },
}));

type BackButtonProps = {
  onClick?: () => void;
};

export function BackButton({ onClick }: BackButtonProps) {
  const { classes } = useStyles();
  const router = useRouter();

  // Define a default onClick function if none is provided
  const handleClick = onClick || (() => router.back());

  return (
    <ActionIcon variant="transparent" className={classes.icon} onClick={handleClick}>
      <RiArrowLeftSLine />
    </ActionIcon>
  );
}

import { Badge, BadgeProps } from '@mantine/core';
import { IconType } from 'react-icons/lib';

import { Text } from '@/components';

interface BaseBadgeProps extends Omit<BadgeProps, 'leftSection'> {
  icon: IconType;
}

export function BaseBadge({ icon: Icon, size = 'xs', ...props }: BaseBadgeProps) {
  return (
    <Badge py={5} lh={1} size={size} leftSection={<Icon style={{ display: 'block' }} />} {...props}>
      <Text size="S" lh={1}>
        {props.children}
      </Text>
    </Badge>
  );
}

/* eslint-disable no-restricted-imports */
// eslint-disable-next-line no-restricted-imports

import { forwardRef } from 'react';
import { Text as MnText, TextProps as MnTextProps } from '@mantine/core';

import { TypographySize } from './types';
import { useTypographyStyle } from './Typography.style';

export interface TypographyProps extends Omit<MnTextProps, 'size'> {
  id?: string;
  role?: string;
  component?: any;
  htmlFor?: string;
  size?: TypographySize;
}

export const Typography = forwardRef<HTMLHeadingElement, TypographyProps>(function Text(
  { children, className, classNames, size, ...rest },
  ref
) {
  const { classes, cx } = useTypographyStyle({ size: size });

  return (
    <MnText ref={ref} className={cx(classes.text, className, classNames)} {...rest}>
      {children}
    </MnText>
  );
});

import { Group, Stack } from '@mantine/core';
import { randomId } from '@mantine/hooks';

import { Text } from '@/components';

export interface FormFieldInfoProps {
  label: string;
  value: React.ReactNode | undefined;
}

export function FormFieldInfo({ label, value }: FormFieldInfoProps) {
  const id = randomId();

  return (
    <Stack spacing={4}>
      <Text component="label" color="gray.7" size="S" htmlFor={id} weight={400} display="inline-block">
        {label}
      </Text>
      <Group align="center" id={id}>
        <Text size="S" weight={600}>
          {value ? value : '-'}
        </Text>
      </Group>
    </Stack>
  );
}

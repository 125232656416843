import { useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import { showNotification } from '@mantine/notifications';
import { updateWorkflowCSMUnlock } from 'api/generated/workflow/workflow';

export const useEnablePrivateDataForCSM = () => {
  const { t } = useTranslation('myCargos');

  const onEnablePrivateDataForCSM = useCallback(
    async ({ id }: { id: string }) => {
      try {
        await updateWorkflowCSMUnlock(id);
        showNotification({
          color: 'green',
          message: t('notifications.CSMWorkflowPrivacyDataEnabled'),
        });
      } catch (error) {
        showNotification({
          color: 'red',
          message: t('notifications.CSMWorkflowPrivacyDataError'),
        });
      }
    },
    [t]
  );

  return { onEnablePrivateDataForCSM };
};

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type FreightPaymentTerms = (typeof FreightPaymentTerms)[keyof typeof FreightPaymentTerms];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FreightPaymentTerms = {
  TT_OPEN_TERMS: 'TT_OPEN_TERMS',
  TT_BEFORE_BREAKING_BULK: 'TT_BEFORE_BREAKING_BULK',
  TT_PREPAYMENT: 'TT_PREPAYMENT',
  OPEN_ACCOUNT: 'OPEN_ACCOUNT',
} as const;

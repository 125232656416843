import { useState } from 'react';
import {
  TextInput as MantineTextInput,
  Select as MantineSelect,
  MultiSelect as MantineMultiselect,
  Button as MantineButton,
  Flex,
  useMantineTheme,
} from '@mantine/core';
import { RiSearchLine } from 'react-icons/ri';
import { useTranslation } from 'next-i18next';

import { useMediaQuery } from '@/core/hooks/use-media-query';

export interface FilterConfig {
  type: 'input' | 'select' | 'multiselect' | 'radio' | 'multiselectForArray';
  key: string;
  placeholder?: string;
  leftIcon?: any;
  options?: Array<{ value: string; label: string }>;
  flex?: number;
}

export interface IBlinkFilterProps<T> {
  /**
   * Data to filter
   */
  data?: T[];
  /**
   * Filter fields configuration
   */
  filtersConfig: FilterConfig[];
  /**
   * Function to filter data
   * @param filteredData
   * @returns
   */
  onFilter?: (filteredData: T[]) => void;
  setFilteredValues?: any;
}

const getValueByPath = <T,>(object: T, path: string): any => {
  return path.split('.').reduce((o: any, p) => (o ? o[p] : undefined), object);
};

export function ListFilters<T>({ filtersConfig, data, onFilter, setFilteredValues }: IBlinkFilterProps<T>) {
  const { t } = useTranslation('common');
  const initialState = filtersConfig.reduce((acc, filter) => {
    switch (filter.type) {
      case 'multiselect':
      case 'multiselectForArray':
        acc[filter.key] = [];
        break;
      case 'select':
        acc[filter.key] = null;
        break;
      default:
        acc[filter.key] = '';
    }
    return acc;
  }, {} as Record<string, any>);

  const [filterValues, setFilterValues] = useState<Record<string, any>>(initialState);

  const handleFilterChange = (key: string, value: string | string[] | null) => {
    const newFilterValues = { ...filterValues, [key]: value };
    setFilterValues(newFilterValues);
    setFilteredValues?.(newFilterValues);
    applyFilters(newFilterValues);
  };

  const applyFilters = (filters: Record<string, any>) => {
    const filteredData = data?.filter((item: any) =>
      filtersConfig.every((filter) => {
        const itemValue = getValueByPath(item, filter.key);

        if (filter.type === 'multiselect') {
          const selectedValues = filters[filter.key] || [];
          return selectedValues.length === 0 || selectedValues.includes(itemValue);
        }

        if (filter.type === 'multiselectForArray') {
          const selectedValues = filters[filter.key] || [];
          return selectedValues.length === 0 || itemValue.some((p: any) => selectedValues.includes(p));
        }

        if (filter.type === 'select') {
          const selectedValue = filters[filter?.key];
          return selectedValue === null || selectedValue?.toLowerCase() === itemValue.toLowerCase();
        }

        if (filter.type === 'input') {
          return itemValue?.toString().toLowerCase().includes(filters[filter?.key].toLowerCase());
        }
      })
    );

    onFilter?.(filteredData);
  };

  const clearFilters = () => {
    setFilterValues(initialState);
    setFilteredValues?.(initialState);
    onFilter?.(data);
  };
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(theme.breakpoints.sm);
  return (
    <Flex direction={isMobile ? 'column' : 'row'} gap={isMobile ? 'xs' : 'md'}>
      {filtersConfig.map((filter) => {
        const value = filterValues[filter.key];

        if (filter.type == 'input')
          return (
            <MantineTextInput
              key={`${filter.type}-${filter.key}`}
              style={{ flex: filter.flex || 1 }}
              styles={{ input: { height: '40px' } }}
              sx={{ height: 'auto' }}
              placeholder={filter.placeholder}
              icon={<RiSearchLine />}
              value={value}
              onChange={(event) => handleFilterChange(filter.key, event.currentTarget.value)}
            />
          );
        if (filter.type == 'select')
          return (
            <MantineSelect
              key={`${filter.type}-${filter.key}`}
              searchable
              style={{ flex: filter.flex || 1 }}
              placeholder={filter.placeholder}
              data={filter.options || []}
              value={value ?? null}
              onChange={(value) => handleFilterChange(filter.key, value)}
            />
          );
        if (filter.type == 'multiselect' || filter.type == 'multiselectForArray')
          return (
            <MantineMultiselect
              key={`${filter.type}-${filter.key}`}
              searchable
              style={{ flex: filter.flex || 1 }}
              placeholder={filter.placeholder}
              data={filter.options || []}
              value={value}
              onChange={(value) => handleFilterChange(filter.key, value)}
            />
          );
        return <></>;
      })}
      {!isMobile && (
        <MantineButton variant="subtle" onClick={clearFilters}>
          {t('buttons.clear')}
        </MantineButton>
      )}
    </Flex>
  );
}

import { Flex, Group, Stack } from '@mantine/core';
import { formatDistanceToNow } from 'date-fns';
import Message from '@sendbird/uikit-react/Channel/components/Message';

import { Text } from '@/components';
import { useAuthRoles } from '@/core';

export interface MessageHeaderProps {
  companyName: string;
  user: string;
  postedAt: string;
}

const OtherCompanyMessageHeader = (props: MessageHeaderProps) => {
  const { companyName, user, postedAt } = props;

  return (
    <Group position="apart" noWrap>
      <Group spacing={4}>
        <Text color="gray.9" weight={600}>{`${companyName} -`}</Text>
        <Text color="gray.9" size="S">
          {user}
        </Text>
      </Group>
      <Text size="XS" color="gray.7">
        {formatDistanceToNow(new Date(postedAt ?? 0), { addSuffix: true })}
      </Text>
    </Group>
  );
};

const MyCompanyMessageHeader = (props: Omit<MessageHeaderProps, 'companyName'>) => {
  const { user, postedAt } = props;

  return (
    <Group position="apart" noWrap spacing={4}>
      {user && <Text weight={700} color="gray.6" size="XS">{`${user} -`}</Text>}
      <Text size="XS" color="gray.7">
        {formatDistanceToNow(new Date(postedAt ?? 0), { addSuffix: true })}
      </Text>
    </Group>
  );
};

interface MessageProps extends MessageHeaderProps {
  message: string;
}

const OtherCompanyMessage = ({ message, ...rest }: MessageProps) => {
  return (
    <Stack
      sx={(theme) => ({
        border: `1px solid ${theme.colors.gray[3]}`,
        borderRadius: theme.radius.lg,
        borderBottomLeftRadius: 0,
        width: '70%',
      })}
      p={16}
      spacing={8}
    >
      <OtherCompanyMessageHeader {...rest} />
      <Text color="gray.8">{message}</Text>
    </Stack>
  );
};

interface MyCompanyMessageProps extends Omit<MessageProps, 'companyName'> {
  isMyMessage: boolean;
  message: MessageProps['message'];
}

const MyCompanyMessage = ({ message, isMyMessage, ...rest }: MyCompanyMessageProps) => {
  return (
    <Stack
      sx={(theme) => ({
        border: `1px solid ${theme.colors.gray[3]}`,
        borderRadius: theme.radius.lg,
        borderBottomRightRadius: 0,
        backgroundColor: !isMyMessage ? theme.colors.gray[0] : theme.colors.indigo[0],
        width: '70%',
      })}
      p={16}
      spacing={8}
    >
      <Text color="gray.8">{message}</Text>
      <Flex justify="flex-end">
        <MyCompanyMessageHeader {...rest} />
      </Flex>
    </Stack>
  );
};

export function SendbirdMessage(props) {
  const { message, otherCompanyName } = props;
  const { companyContextId, companyId } = useAuthRoles();
  const sender = message?.sender;
  const isMyMessage = companyContextId === sender?.userId;
  const isMyCompany = isMyMessage ? true : sender?.metaData?.companyId === companyId;

  const justify = isMyCompany ? 'flex-end' : 'flex-start';

  if (!sender?.metaData?.companyId) {
    console.info('Message sender does not have a company ID', sender);
  }

  if (message.messageType == 'file') {
    return (
      <Flex justify={justify} my={8}>
        <Message {...props} />
      </Flex>
    );
  }

  return (
    <Flex justify={justify} my={8}>
      {!isMyCompany ? (
        <OtherCompanyMessage
          companyName={otherCompanyName}
          user={sender?.nickname}
          postedAt={message.createdAt}
          message={message.message}
        />
      ) : (
        <MyCompanyMessage
          user={!isMyMessage ? sender?.nickname : undefined}
          postedAt={message.createdAt}
          isMyMessage={isMyMessage}
          message={message.message}
        />
      )}
    </Flex>
  );
}

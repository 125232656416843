import {
  Box,
  BoxProps,
  Center,
  createStyles,
  Group,
  LoadingOverlay,
  Table as MantineTable,
  TableProps,
} from '@mantine/core';
import type { PolymorphicComponentProps } from '@mantine/utils';
import { flexRender, Row, Table } from '@tanstack/react-table';
import { useTranslation } from 'next-i18next';
import { ReactElement, ReactNode } from 'react';
import { RiArrowDownFill, RiArrowUpDownFill, RiArrowUpFill } from 'react-icons/ri';

import { Text } from '../typography/Text';

const useStyles = createStyles((theme) => ({
  header: {
    fontWeight: 500,
    color: theme.colors.gray[9],

    '&:hover': {
      cursor: 'pointer',
    },
  },
  group: {
    gap: 8,
    [theme.fn.smallerThan('md')]: {
      gap: 4,
    },
  },
  hiddenOnMobile: {
    [theme.fn.smallerThan('md')]: {
      pointerEvents: 'none',
      display: 'none',
    },
  },
  wideOnMobile: {
    [theme.fn.smallerThan('md')]: {
      width: 800,
    },
  },
  expandedRow: {
    backgroundColor: theme.colors.blue[1],
    borderRadius: theme.radius.md,

    '& td': {
      borderColor: `${theme.colors.blue[1]} !important`,
    },
  },
  rowExpandedNoHighlight: {
    backgroundColor: theme.colors.white,
  },
}));

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData, TValue> {
    mobileHidden?: boolean;
  }
}

interface RenderRowProps<TData> {
  row: Row<TData>;
  disableHover?: boolean;
}

export interface BaseTableProps<TData> extends TableProps {
  table: Table<TData>;
  isLoading: boolean;
  renderRow: (props: RenderRowProps<TData>) => ReactElement;
  disableHover?: boolean;
  hideHeader?: boolean;
}

export function BaseTable<TData extends { id: string }>(props: BaseTableProps<TData>) {
  const { classes, cx } = useStyles();
  const { table, isLoading, disableHover, hideHeader, renderRow: RenderRow, ...mantineTableProps } = props;
  const { t } = useTranslation('common');

  return (
    <>
      <LoadingOverlay visible={isLoading} data-testid={'loader'} />
      <MantineTable horizontalSpacing="md" verticalSpacing="xs" data-testid="table" {...mantineTableProps}>
        {!hideHeader && (
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    className={cx(classes.header, {
                      [classes.hiddenOnMobile]: header.column.columnDef.meta?.mobileHidden,
                    })}
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <Group className={classes.group} position="left" noWrap>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {header.column.getCanSort() && (
                        <>
                          {{
                            asc: <RiArrowUpFill className={classes.hiddenOnMobile} />,
                            desc: <RiArrowDownFill className={classes.hiddenOnMobile} />,
                          }[header.column.getIsSorted() as string] ?? (
                            <RiArrowUpDownFill className={classes.hiddenOnMobile} />
                          )}
                        </>
                      )}
                    </Group>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}
        <tbody data-testid="drawer-tbody">
          {table.getRowModel().rows.length === 0 && !isLoading && (
            <tr>
              <td colSpan={6}>
                <Center>
                  <Text>{t('table.noData')}</Text>
                </Center>
              </td>
            </tr>
          )}
          {table.getRowModel().rows.length !== 0 &&
            table.getRowModel().rows.map((row) => <RenderRow key={row.id} row={row} disableHover={disableHover} />)}
        </tbody>
      </MantineTable>
    </>
  );
}

interface BaseTableCardProps extends BoxProps {
  children: ReactNode;
  isWideOnMobile?: boolean;
  bordered?: boolean;
}

export function BaseTableCard(props: BaseTableCardProps) {
  const { classes } = useStyles();
  const { children, bordered, isWideOnMobile, ...rest } = props;
  const wrappedChildren = isWideOnMobile ? <Box className={classes.wideOnMobile}>{children}</Box> : children;

  return (
    <Box
      sx={(theme) => ({
        position: 'relative',
        backgroundColor: theme.white,
        borderRadius: theme.radius.md,
        border: bordered ? `1px solid ${theme.colors.gray[2]}` : undefined,
        overflow: 'auto',
      })}
      {...rest}
    >
      {wrappedChildren}
    </Box>
  );
}

interface RenderBasicRowProps<TData> extends PolymorphicComponentProps<'tr'> {
  row: Row<TData>;
  disableHover?: boolean;
  rowExpandedNoHighlight?: boolean;
}

export function RenderBasicRow<TData>(props: RenderBasicRowProps<TData>) {
  const { row, disableHover, rowExpandedNoHighlight, className, ...rest } = props;
  const { cx, classes } = useStyles();

  return (
    <tr
      {...rest}
      className={cx(
        {
          [classes.expandedRow]: row.getIsExpanded() && row.depth < 1 && !rowExpandedNoHighlight,
          [classes.rowExpandedNoHighlight]: row.getIsExpanded() && row.depth < 1 && rowExpandedNoHighlight,
        },
        className
      )}
      data-hover={!disableHover && !row.getIsExpanded()}
      data-testid={`recipient-${row.index}`}
    >
      {row.getVisibleCells().map((cell) => (
        <td
          className={cx({ [classes.hiddenOnMobile]: cell.column.columnDef.meta?.mobileHidden })}
          key={cell.id}
          data-testid={`${cell.column.columnDef.header}-${cell.row.index}`}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  );
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoTenderNegotiationStatusCreatorCounteroffer =
  (typeof CargoTenderNegotiationStatusCreatorCounteroffer)[keyof typeof CargoTenderNegotiationStatusCreatorCounteroffer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoTenderNegotiationStatusCreatorCounteroffer = {
  CREATOR_COUNTEROFFER: 'CREATOR_COUNTEROFFER',
} as const;

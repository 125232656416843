import { CompanyType } from 'api/generated/model';
import { SelectOptionProps } from 'components/country-selector/CountrySelector';

import { countries, getCountriesPhonePrefixes } from '@/static-data';

import { RadioOptions, RegisterLabels, RegisterPlaceholders } from './types';

export interface StepInput {
  label: RegisterLabels;
  placeholder: RegisterPlaceholders;
}

export const stepZeroInputs: StepInput[] = [
  {
    label: 'companyName',
    placeholder: 'companyName',
  },
  {
    label: 'firstName',
    placeholder: 'firstName',
  },
  {
    label: 'lastName',
    placeholder: 'lastName',
  },
  {
    label: 'contactEmail',
    placeholder: 'contactEmail',
  },
];

export const stepOneInputs: StepInput[] = [
  {
    label: 'address1',
    placeholder: 'address1',
  },
  {
    label: 'address2',
    placeholder: 'address2',
  },
  {
    label: 'city',
    placeholder: 'city',
  },
  {
    label: 'zipCode',
    placeholder: 'zipCode',
  },
];

export const radios: RadioOptions[] = [
  { translationKey: 'sell', value: CompanyType.SELL },
  { translationKey: 'buy', value: CompanyType.BUY },
  { translationKey: 'buyAndSell', value: CompanyType.BUY_AND_SELL },
];

function normalizeLang(lang: string) {
  // en-US -> en
  // en -> en
  const [normalizeLang] = lang.split('-');
  return normalizeLang;
}

export function getSelectPhonePrefixData(lang: string): SelectOptionProps[] {
  const normalizedLang = normalizeLang(lang);

  return getCountriesPhonePrefixes().map(({ alpha2, phonePrefix, name }) => ({
    value: `${phonePrefix}_${alpha2}`,
    imageLink: `https://flagcdn.com/24x18/${alpha2.toLowerCase()}.png`,
    alt: `Flag of ${name[normalizedLang]}`,
    label: phonePrefix,
    name: name[normalizedLang],
  }));
}

export function getSelectCountryData(lang: string): SelectOptionProps[] {
  const normalizedLang = normalizeLang(lang);

  return countries.map(({ name, alpha2 }) => ({
    value: alpha2,
    imageLink: `https://flagcdn.com/24x18/${alpha2.toLowerCase()}.png`,
    alt: `Flag of ${name[normalizedLang]}`,
    label: name[normalizedLang],
    name: name[normalizedLang],
  }));
}

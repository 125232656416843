import { BadgeProps } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { RiStarLine } from 'react-icons/ri';

import { BaseBadge } from './BaseBadge';

type PromotedBadgeProps = Pick<BadgeProps, 'sx' | 'className' | 'classNames' | 'mr' | 'ml' | 'mb' | 'mt'> & {
  ownPromotion?: boolean;
};

export function PromotedBadge({ ownPromotion, ...props }: PromotedBadgeProps) {
  const { t } = useTranslation('common');

  return (
    <BaseBadge icon={RiStarLine} data-testid="summary-promotion-badge" color="indigo" {...props}>
      {ownPromotion ? t('badges.PROMOTED') : t('badges.PROMOTED_TO_ME')}
    </BaseBadge>
  );
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type GetAllDetailedOffersInvitedResponseItemDtoCargoNeedDtoTransportation =
  (typeof GetAllDetailedOffersInvitedResponseItemDtoCargoNeedDtoTransportation)[keyof typeof GetAllDetailedOffersInvitedResponseItemDtoCargoNeedDtoTransportation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAllDetailedOffersInvitedResponseItemDtoCargoNeedDtoTransportation = {
  CFR: 'CFR',
  FOB: 'FOB',
  CIF: 'CIF',
} as const;

import { Currency, PrepaymentEvents } from '../../types';
import { Incoterm, PaymentTerms } from '../../enums';
import { PricingAndPaymentTermsSchema } from './pricing-and-payment-terms';
import {
  CashAgainstDocumentsSchema,
  ConsumptionPaymentSchema,
  DocumentCollectionSchema,
  IrrevocableLetterSchema,
  OpenAccountSchema,
  OtherPaymentSchema,
  PaymentConditionSchema,
  PrepaymentConditionSchema,
} from './payment-conditions';

export const PRICING_AND_PAYMENT_TERMS_INITIAL_VALUES: PricingAndPaymentTermsSchema = {
  incoterm: Incoterm.FOB,
  paymentTerms: PaymentTerms.PRE_PAYMENT,
  price: 0,
  currency: Currency.USD,
};

export const PREPAYMENT_INITIAL_VALUES: PrepaymentConditionSchema = {
  percentage: 0,
  days: 0,
  event: '',
};

export const CASH_AGAINST_DOCUMENTS_INITIAL_VALUES: CashAgainstDocumentsSchema = {
  percentage: 0,
  days: 0,
  numberOfCopies: 0,
};

export const IRREVOCABLE_LETTER_INITIAL_VALUES: IrrevocableLetterSchema = {
  percentage: 0,
  days: 0,
  event: '',
  bankName: '',
  swiftCode: '',
};

export const DOCUMENT_COLLECTION_INITIAL_VALUES: DocumentCollectionSchema = {
  percentage: 0,
  days: 0,
  event: '',
};

export const OPEN_ACCOUNT_INITIAL_VALUES: OpenAccountSchema = {
  percentage: 0,
  days: 0,
  event: '',
};

export const CONSUMPTION_PAYMENT_INITIAL_VALUES: ConsumptionPaymentSchema = {
  percentage: 0,
  days: 0,
  event: '',
  numberOfInstallments: 0,
};

export const OTHER_PAYMENT_INITIAL_VALUES: OtherPaymentSchema = {
  percentage: 0,
  methodOfPayment: '',
};

export const PAYMENT_CONDITION_INITIAL_VALUES: PaymentConditionSchema = {
  key: undefined,
  name: 'empty',
  prepayment: PREPAYMENT_INITIAL_VALUES,
  cashAgainstDocuments: CASH_AGAINST_DOCUMENTS_INITIAL_VALUES,
  irrevocableLetter: IRREVOCABLE_LETTER_INITIAL_VALUES,
  documentCollection: DOCUMENT_COLLECTION_INITIAL_VALUES,
  openAccount: OPEN_ACCOUNT_INITIAL_VALUES,
  consumptionPayment: CONSUMPTION_PAYMENT_INITIAL_VALUES,
  other: OTHER_PAYMENT_INITIAL_VALUES,
};

//Empty values
export const PRICING_AND_PAYMENT_TERMS_EMPTY_VALUES = {
  incoterm: null,
  paymentTerms: null,
  price: undefined,
  currency: null,
};

export const PAYMENT_CONDITION_EMPTY_VALUES = {
  name: 'empty',
  prepayment: {
    percentage: 0,
    days: 0,
    event: '',
  },
  cashAgainstDocuments: {
    percentage: 0,
    days: 0,
    numberOfCopies: 0,
  },
  irrevocableLetter: {
    percentage: 0,
    days: 0,
    event: '',
    bankName: '',
    swiftCode: '',
  },
  documentCollection: {
    percentage: 0,
    days: 0,
    event: '',
  },
  openAccount: {
    percentage: 0,
    days: 0,
    event: '',
  },
  consumptionPayment: {
    percentage: 0,
    days: 0,
    event: '',
    numberOfInstallments: 0,
  },
  other: {
    percentage: 0,
    methodOfPayment: '',
  },
};

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type GetAllDetailedOffersInvitedResponseItemDtoCargoOfferDtoPaymentTerms =
  (typeof GetAllDetailedOffersInvitedResponseItemDtoCargoOfferDtoPaymentTerms)[keyof typeof GetAllDetailedOffersInvitedResponseItemDtoCargoOfferDtoPaymentTerms];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAllDetailedOffersInvitedResponseItemDtoCargoOfferDtoPaymentTerms = {
  PRE_PAYMENT: 'PRE_PAYMENT',
  CONSUMPTION: 'CONSUMPTION',
  CASH_AGAINS_DOCUMENTS: 'CASH_AGAINS_DOCUMENTS',
  OPEN_ACCOUNT: 'OPEN_ACCOUNT',
  LETTER_OF_CREDIT_AT_SIGHT: 'LETTER_OF_CREDIT_AT_SIGHT',
  LETTER_OF_CREDIT_DEFERRED: 'LETTER_OF_CREDIT_DEFERRED',
  DOCUMENTARY_COLLECTION: 'DOCUMENTARY_COLLECTION',
  OTHER: 'OTHER',
} as const;

import { Button, Group, Stack, Textarea } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { useForm, zodResolver } from '@mantine/form';
import { CreateCargoFeedbackDTO } from 'api/generated/model';

import { Text } from '@/components';

import { StepFunction, schemaCounterpartyMessage } from '../types';

export interface CounterpartyFormProps {
  onBackClick: StepFunction;
  onNextClick: StepFunction;
  formState: CreateCargoFeedbackDTO;
}

export function CounterpartyForm(props: CounterpartyFormProps) {
  const { onBackClick, onNextClick, formState } = props;
  const { t } = useTranslation('myCargos');
  const form = useForm({
    initialValues: formState,
    validate: zodResolver(schemaCounterpartyMessage),
  });

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        if (form.validate().hasErrors) return;
        onNextClick(values);
      })}
    >
      <Stack spacing={24}>
        <Text sx={{ color: '#535A60' }} align="center" size="S">
          {t('modals.cancel.descriptions.reason')}
        </Text>
        <Textarea
          placeholder={t('modals.cancel.placeholders.counterpartyMessage')}
          {...form.getInputProps('unfinishedReason')}
        />
        <Group grow>
          <Button onClick={() => onBackClick(form.values)} variant="outline">
            {t('modals.cancel.buttons.back')}
          </Button>
          <Button type="submit">{t('modals.cancel.buttons.discontinue')}</Button>
        </Group>
      </Stack>
    </form>
  );
}

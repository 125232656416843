import { ActionIcon, Box, Button, createStyles, Group, Indicator, Popover, Stack, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { formatDistanceToNow } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { RiChat3Line } from 'react-icons/ri';
import { ChatGroupUnreadMessagesDTO } from 'api/generated/model';
import { useGetChatGroupsUnreadMessages } from 'api/generated/chat/chat';

import { routes } from '@/common/routes';
import { ResponsivePopover, Text } from '@/components';

const SYNC_INTERVAL = 1000 * 30; // 30 seconds

const useStyles = createStyles((theme) => ({
  stack: {
    '&:hover': {
      backgroundColor: theme.colors.blue[0],
      cursor: 'pointer',
    },
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },
}));

interface SendbirdNotificationPopoverContentProps {
  onNotificationClick: (id: string) => void;
  unreadChats: ChatGroupUnreadMessagesDTO[];
}

function SendbirdNotificationPopoverContent(props: SendbirdNotificationPopoverContentProps) {
  const { onNotificationClick, unreadChats } = props;
  const { t } = useTranslation('common');
  const router = useRouter();
  const { classes } = useStyles();

  return (
    <Stack spacing={0}>
      {unreadChats.map((notification) => (
        <Stack
          p={16}
          className={classes.stack}
          key={notification.id}
          onClick={() => onNotificationClick(notification.id)}
        >
          <Group position="apart" noWrap>
            <Text
              color="gray.9"
              weight={600}
              sx={() => ({
                textOverflow: 'ellipsis',
                width: 200,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              })}
            >
              {notification.name}
            </Text>
            <Text color="gray.7" size="XS">
              {formatDistanceToNow(new Date(notification.lastMessage.createdAt ?? 0), { addSuffix: true })}
            </Text>
          </Group>
          <Group position="apart">
            {notification.lastMessage.filename && (
              <Text size="S">
                <strong>{notification.lastMessage.filename}</strong> has been shared
              </Text>
            )}
            {notification.lastMessage.message && <Text size="S">{notification.lastMessage.message}</Text>}
            {unreadChats && (
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.colors.red[9],
                  border: `1px solid ${theme.colors.red[9]}`,
                  borderRadius: theme.radius.xl,
                  width: 24,
                  display: 'flex',
                  justifyContent: 'center',
                })}
              >
                <Text color="white" size="S">
                  {notification.unreadMessageCount}
                </Text>
              </Box>
            )}
          </Group>
        </Stack>
      ))}
      <Button onClick={() => router.push(routes.Chat)} variant="subtle">
        {t('nav.allChatButton')}
      </Button>
    </Stack>
  );
}

export function SendbirdNotificationIndicator() {
  const { theme } = useStyles();
  const { t } = useTranslation('common');
  const [opened, { toggle: togglePopover }] = useDisclosure(false);
  const router = useRouter();
  const { data: unreadChats } = useGetChatGroupsUnreadMessages({
    query: { select: (data) => data.data, staleTime: 0 },
  });

  const unreadMessageTotalCount = unreadChats?.reduce((acc, chat) => acc + chat.unreadMessageCount, 0);

  const onNotificationClick = (id: string) => {
    router.push(routes.MessageCenter(id));
  };

  return (
    <ResponsivePopover
      opened={opened}
      width={400}
      position="bottom"
      shadow="md"
      onClose={togglePopover}
      closeOnClickOutside
      data-testid="messageButton"
    >
      <Popover.Target>
        <Indicator
          inline
          disabled={(unreadMessageTotalCount || 0) === 0}
          label={unreadMessageTotalCount}
          color={theme.colors.red[9]}
          size={20}
          radius="xl"
          onClick={togglePopover}
          sx={{ lineHeight: '16px' }}
        >
          <Tooltip label={t('nav.chatMessages')}>
            <ActionIcon size={'md'} variant="transparent">
              <RiChat3Line size={20} color="white" />
            </ActionIcon>
          </Tooltip>
        </Indicator>
      </Popover.Target>
      <Popover.Dropdown p={0}>
        <SendbirdNotificationPopoverContent onNotificationClick={onNotificationClick} unreadChats={unreadChats || []} />
      </Popover.Dropdown>
    </ResponsivePopover>
  );
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type Currency = (typeof Currency)[keyof typeof Currency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Currency = {
  EUR: 'EUR',
  USD: 'USD',
} as const;

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type Grabs = (typeof Grabs)[keyof typeof Grabs];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Grabs = {
  GRABLESS: 'GRABLESS',
  GRABBED: 'GRABBED',
} as const;

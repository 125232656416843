/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type PlantTypeOfWork = (typeof PlantTypeOfWork)[keyof typeof PlantTypeOfWork];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlantTypeOfWork = {
  INTEGRATED: 'INTEGRATED',
  GRINDING: 'GRINDING',
} as const;

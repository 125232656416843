export enum ExclusionFromLayTime {
  INITIAL_DRAFT_SURVEY = 'INITIAL_DRAFT_SURVEY',
  INTERMEDIARY_DRAFT_SURVEY = 'INTERMEDIARY_DRAFT_SURVEY',
  FINAL_DRAFT_SURVEY = 'FINAL_DRAFT_SURVEY',
  FIRST_SHIFTING = 'FIRST_SHIFTING',
  INTERMEDIARY_SHIFTING = 'INTERMEDIARY_SHIFTING',
  OPENING_OF_HATCHES = 'OPENING_OF_HATCHES',
  WARPING = 'WARPING',
  SWELL = 'SWELL',
  CUSTOM_CLEARANCE = 'CUSTOM_CLEARANCE',
  OTHERS = 'OTHERS',
}

export enum LaytimeCalculations {
  BILATERAL_AGREEMENT = 'BILATERAL_AGREEMENT',
  SELLER_CALCULATION = 'SELLER_CALCULATION',
  BUYER_CALCULATION = 'BUYER_CALCULATION',
  TRUSTED_PARTY = 'TRUSTED_PARTY',
}

export enum FreightTerms {
  SSHINC = 'SSHINC',
  FSHINC = 'FSHINC',
  SSHEX_EIU = 'SSHEX_EIU',
  SSHEX_UU = 'SSHEX_UU',
  SatNoonSHEX_EIU = 'SatNoonSHEX_EIU',
  SatNoonSHEX_UU = 'SatNoonSHEX_UU',
  SHEX_EIU = 'SHEX_EIU',
  SHEX_UU = 'SHEX_UU',
  SHINC = 'SHINC',
}

export enum NorTendering {
  ATDN_PLUS_SSHINC = 'ATDN_PLUS_SSHINC',
  FSHINC = 'FSHINC',
  SSHEX_EIU = 'SSHEX_EIU',
  SSHEX_UU = 'SSHEX_UU',
  SATNOONSHEX_EIU = 'SATNOONSHEX_EIU',
  SATNOONSHEX_UU = 'SATNOONSHEX_UU',
  SHEX_EIU = 'SHEX_EIU',
  SHEX_UU = 'SHEX_UU',
}

export enum FreightPeriodTerms {
  PWWD = 'PWWD',
  PER_WORKING_HOUR = 'PER_WORKING_HOUR',
}

export enum TurnTimeTerms {
  EVEN_IF_USED = 'EVEN_IF_USED',
  UNLESS_USED = 'UNLESS_USED',
}

export enum PDA {
  AT_BUYERS_COST = 'AT_BUYERS_COST',
  AT_SELLERS_COST = 'AT_SELLERS_COST',
}

export enum BerthConditions {
  AAAA = 'AAAA',
  AA = 'AA',
  NAABSA = 'NAABSA',
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoTenderNegotiationStatusAcceptOffer =
  (typeof CargoTenderNegotiationStatusAcceptOffer)[keyof typeof CargoTenderNegotiationStatusAcceptOffer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoTenderNegotiationStatusAcceptOffer = {
  PARTICIPANT_ACCEPTED: 'PARTICIPANT_ACCEPTED',
  CREATOR_ACCEPTED: 'CREATOR_ACCEPTED',
} as const;

import { TransactionSide, WorkflowDTO } from 'api/generated/model';

export interface Counterparty {
  counterpartyId: string;
  counterpartyName: string;
}

export function getCounterparty(workflow: WorkflowDTO): Counterparty {
  switch (workflow.transactionSide) {
    case TransactionSide.BUYER:
      return {
        counterpartyId: workflow.sellerCompanyId,
        counterpartyName: workflow.sellerCompanyName,
      };
    case TransactionSide.SELLER:
      return {
        counterpartyId: workflow.buyerCompanyId,
        counterpartyName: workflow.buyerCompanyName,
      };
  }
}

import { Badge, DefaultMantineColor } from '@mantine/core';
import { CargoWorkflowGlobalEventType } from 'api/generated/model';
import { useTranslation } from 'next-i18next';

export interface CargoWorkflowGlobalEventBadgeProps {
  event: CargoWorkflowGlobalEventType;
}

export function CargoWorkflowGlobalEventBadge(props: CargoWorkflowGlobalEventBadgeProps) {
  const { event } = props;
  const { t } = useTranslation('common');
  let color: DefaultMantineColor;

  switch (event) {
    case CargoWorkflowGlobalEventType.LOADING_ARRIVAL_TIME:
    case CargoWorkflowGlobalEventType.LOADING_BERTHING_TIME:
    case CargoWorkflowGlobalEventType.LOADING_COMPLETION_TIME:
      color = 'grape';
      break;
    case CargoWorkflowGlobalEventType.DISCHARGING_ARRIVAL_TIME:
    case CargoWorkflowGlobalEventType.DISCHARGING_BERTHING_TIME:
    case CargoWorkflowGlobalEventType.DISCHARGING_COMPLETION_TIME:
      color = 'blue';
      break;
    default:
      color = 'gray';
  }

  return (
    <Badge color={color} size="xs" data-testid="cargoWorkflowGlobalEvent-badge" sx={{ textAlign: 'start' }}>
      {t(`badges.cargoWorkflowGlobalEvent.${event}`)}
    </Badge>
  );
}

import { Card, Stack } from '@mantine/core';
import { ReactNode } from 'react';

import { Text } from '@/components';

interface FormCardSectionProps {
  title?: string;
  children: ReactNode;
}

export function FormCardSection(props: FormCardSectionProps) {
  const { title, children } = props;

  return (
    <Card withBorder px={16} py={16} sx={{ overflow: 'visible' }}>
      <Stack>
        {title && <Text weight={600}>{title}</Text>}
        {children}
      </Stack>
    </Card>
  );
}

import { CompanyDetailsDTO } from 'api/generated/model';

export const getFullCompanyAddress = (company: CompanyDetailsDTO) => {
  const { address1, address2, city, zipCode, country } = company;
  let combinedAddress = address1;

  if (address2) combinedAddress = combinedAddress + ', ' + address2;

  return `${combinedAddress}, ${city}, ${zipCode}, ${country}`;
};

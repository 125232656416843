import { AppShell, Box, Container, ContainerProps, Header as MantineHeader, createStyles } from '@mantine/core';
import { CompanyType, UserRole } from 'api/generated/model';
import { ReactNode } from 'react';
import { useDisclosure } from '@mantine/hooks';

import { Error403 } from '@/components';
import { useHasPermission } from '@/core';
import { isIosSafari } from '@/common/is-ios-safari';

import { Header } from './Header';
import { Navbar } from './Navbar';

const useStyles = createStyles((theme) => ({
  root: {
    backgroundColor: theme.colors.gray[0],
    minHeight: isIosSafari() ? '100svh' : '100vh',
  },
  additionalHeader: {
    top: 56,
    right: 0,
    position: 'fixed',
    left: 0,

    [theme.fn.largerThan('md')]: {
      left: 56,
    },
  },
  appShell: {
    minHeight: isIosSafari() ? '100svh' : '100vh',
  },
}));

function ContainerComponent(props: Omit<AppPageContentContainerProps, 'allowedRoles'>) {
  const { additionalHeader, children, hideHeader, headersSize, ...rest } = props;
  const { classes } = useStyles();
  const [navbarExpanded, { open: openNavbar, close: closeNavbar, toggle: toggleNavbar }] = useDisclosure(false);

  return (
    <Box className={classes.root}>
      <AppShell
        padding={0}
        classNames={{ main: classes.appShell }}
        navbar={
          <Navbar
            width={{ base: '100%', md: 56 }}
            navbarExpanded={navbarExpanded}
            openNavbar={openNavbar}
            closeNavbar={closeNavbar}
          />
        }
        header={
          <MantineHeader height={headersSize ? headersSize + 56 : 56} data-chat-hide>
            {!hideHeader && <Header opened={navbarExpanded} toggle={toggleNavbar} />}
            {additionalHeader && <Box className={classes.additionalHeader}>{additionalHeader}</Box>}
          </MantineHeader>
        }
      >
        <Container size="xl" {...rest}>
          {children}
        </Container>
      </AppShell>
    </Box>
  );
}

export interface AppPageContentContainerProps extends Omit<ContainerProps, 'size'> {
  children: ReactNode;
  hideHeader?: boolean;
  headersSize?: number;
  allowedRoles?: UserRole[];
  additionalHeader?: ReactNode;
  allowUnverifiedCompany?: boolean;
  allowedCompanyTypes?: CompanyType[];
}

export function AppPageContentContainer(props: AppPageContentContainerProps) {
  const {
    children,
    headersSize,
    allowUnverifiedCompany = false,
    allowedRoles = Object.values(UserRole),
    allowedCompanyTypes = Object.values(CompanyType),
    ...rest
  } = props;

  const { isCompanyVerified, hasUserRolePermission, hasCompanyPermission } = useHasPermission();

  const isRoleAllowed = hasUserRolePermission(allowedRoles);
  const isCompanyTypeAllowed = hasCompanyPermission(allowedCompanyTypes);
  const allowUnverifiedCompanyAllowed = allowUnverifiedCompany || isCompanyVerified;

  const shouldRenderPageContent = isRoleAllowed && isCompanyTypeAllowed && allowUnverifiedCompanyAllowed;

  // TODO: Move this logic to the server-side-props
  return shouldRenderPageContent ? (
    <ContainerComponent headersSize={headersSize} {...rest}>
      {children}
    </ContainerComponent>
  ) : (
    <ContainerComponent headersSize={headersSize} {...rest} pb={0}>
      <Error403 sx={{ minHeight: `calc(100vh - ${headersSize}px)` }} />
    </ContainerComponent>
  );
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type UpdateShortlistedCompanyResponseDtoType =
  (typeof UpdateShortlistedCompanyResponseDtoType)[keyof typeof UpdateShortlistedCompanyResponseDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateShortlistedCompanyResponseDtoType = {
  BUY: 'BUY',
  SELL: 'SELL',
  BUY_AND_SELL: 'BUY_AND_SELL',
  CHARTERING: 'CHARTERING',
  SERVICE_PROVIDER: 'SERVICE_PROVIDER',
} as const;

import { ReactNode, useState } from 'react';
import { SessionProvider } from 'next-auth/react';
import type { Session } from 'next-auth';

import { RefreshTokenHandler } from '@/components';

export interface SessionWithRefreshProviderProps {
  session: Session;
  children: ReactNode;
}

export function SessionWithRefreshProvider({ children, session }: SessionWithRefreshProviderProps) {
  const [refetchInterval, setRefreshInterval] = useState(0); // zero means no refreshing

  return (
    <SessionProvider session={session} refetchInterval={refetchInterval}>
      {children}
      <RefreshTokenHandler setRefreshInterval={setRefreshInterval} />
    </SessionProvider>
  );
}

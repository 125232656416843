import { Textarea, TextareaProps } from '@mantine/core';
import { useTranslation } from 'next-i18next';

export function CommentsInput(props: TextareaProps) {
  const { t } = useTranslation('cargoListings');

  return (
    <Textarea
      size="md"
      label={t('labels.comments')}
      placeholder={t('placeholders.comments')}
      withAsterisk={false}
      styles={{
        input: {
          minHeight: 146,
        },
      }}
      {...props}
    />
  );
}

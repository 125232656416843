import { useEffect, useState } from 'react';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>;
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

export function useAppInstallationPrompt(): [BeforeInstallPromptEvent | null, () => void, boolean] {
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent>();
  const [showInstallApp, setShowInstallApp] = useState(false);

  const promptToInstall = () => {
    if (!deferredPrompt) {
      return;
    }

    return deferredPrompt.prompt();
  };

  useEffect(() => {
    const beforeInstall = (event: BeforeInstallPromptEvent) => {
      event.preventDefault();
      setDeferredPrompt(event);
      setShowInstallApp(true);
    };

    const afterInstall = (event) => {
      setDeferredPrompt(null);
      setShowInstallApp(false);
    };

    window.addEventListener('beforeinstallprompt', beforeInstall);
    window.addEventListener('appinstalled', afterInstall);

    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstall);
      window.removeEventListener('appinstalled', afterInstall);
    };
  }, []);

  return [deferredPrompt, promptToInstall, showInstallApp];
}

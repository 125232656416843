/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type NotificationType = (typeof NotificationType)[keyof typeof NotificationType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationType = {
  NEGOTIATION_EXPIRY_EXTEND_REQUESTED: 'NEGOTIATION_EXPIRY_EXTEND_REQUESTED',
  NEGOTIATION_EXPIRY_EXTENDED: 'NEGOTIATION_EXPIRY_EXTENDED',
  NEGOTIATION_EXPIRY_DUE: 'NEGOTIATION_EXPIRY_DUE',
  NEGOTIATION_STATUS_CHANGED: 'NEGOTIATION_STATUS_CHANGED',
  WORKFLOW_SERVICE_SUBSCRPTION_STATUS_CHANGED: 'WORKFLOW_SERVICE_SUBSCRPTION_STATUS_CHANGED',
  WOKRFLOW_TASK_COMPLETED: 'WOKRFLOW_TASK_COMPLETED',
  WORKFLOW_TASK_SHARED: 'WORKFLOW_TASK_SHARED',
  WORKFLOW_DOCUMENT_SHARED: 'WORKFLOW_DOCUMENT_SHARED',
  WORKFLOW_DUE_DOCUMENTS_REMINDER_SENT: 'WORKFLOW_DUE_DOCUMENTS_REMINDER_SENT',
  WORKFLOW_GLOBAL_EVENTS_UPDATED: 'WORKFLOW_GLOBAL_EVENTS_UPDATED',
  FREIGHT_NEGOTIATION_STATUS_CHANGED: 'FREIGHT_NEGOTIATION_STATUS_CHANGED',
  EXTERNAL_CARGO_CREATED: 'EXTERNAL_CARGO_CREATED',
  EXTERNAL_CARGO_INVITATION_ACCEPTED: 'EXTERNAL_CARGO_INVITATION_ACCEPTED',
  EXTERNAL_CARGO_INVITATION_REJECTED: 'EXTERNAL_CARGO_INVITATION_REJECTED',
  CARGO_OFFER_CANCELED: 'CARGO_OFFER_CANCELED',
  CARGO_NEED_CANCELED: 'CARGO_NEED_CANCELED',
  FREIGHT_NEGOTIATION_EXPIRY_DATE_EXTENSION_REQUESTED: 'FREIGHT_NEGOTIATION_EXPIRY_DATE_EXTENSION_REQUESTED',
  FREIGHT_NEGOTIATION_EXPIRY_DATE_EXTENSION_REQUEST_ACCEPTED:
    'FREIGHT_NEGOTIATION_EXPIRY_DATE_EXTENSION_REQUEST_ACCEPTED',
  FREIGHT_NEGOTIATION_EXPIRY_DATE_EXTENSION_REQUEST_REJECTED:
    'FREIGHT_NEGOTIATION_EXPIRY_DATE_EXTENSION_REQUEST_REJECTED',
  FREIGHT_NEGOTIATION_EXPIRING_SOON: 'FREIGHT_NEGOTIATION_EXPIRING_SOON',
  OPPOSING_COMPANY_GLOBAL_EVENTS_SELECTED: 'OPPOSING_COMPANY_GLOBAL_EVENTS_SELECTED',
  OPPOSING_COMPANY_TEMPLATE_LOADED: 'OPPOSING_COMPANY_TEMPLATE_LOADED',
  CARGO_OFFER_CREATED: 'CARGO_OFFER_CREATED',
  CARGO_OFFER_PROMOTED: 'CARGO_OFFER_PROMOTED',
  CARGO_NEED_PROMOTED: 'CARGO_NEED_PROMOTED',
  CARGO_NEED_CREATED: 'CARGO_NEED_CREATED',
  CARGO_DETAILED_OFFER_NEED_CREATED: 'CARGO_DETAILED_OFFER_NEED_CREATED',
  CARGO_DETAILED_OFFER_AVAILABILITY_CREATED: 'CARGO_DETAILED_OFFER_AVAILABILITY_CREATED',
  CARGO_TENDER_POSTED_TO_NETWORK: 'CARGO_TENDER_POSTED_TO_NETWORK',
  CARGO_TENDER_PARTICIPATION_REQUESTED: 'CARGO_TENDER_PARTICIPATION_REQUESTED',
  CARGO_TENDER_NEGOTIATION_OFFER_INITIAL: 'CARGO_TENDER_NEGOTIATION_OFFER_INITIAL',
  CARGO_TENDER_NEGOTIATION_COUNTEROFFER_CREATOR: 'CARGO_TENDER_NEGOTIATION_COUNTEROFFER_CREATOR',
  CARGO_TENDER_NEGOTIATION_COUNTEROFFER_PARTICIPANT: 'CARGO_TENDER_NEGOTIATION_COUNTEROFFER_PARTICIPANT',
  CARGO_TENDER_NEGOTIATION_OFFER_ACCEPTED_BY_CREATOR: 'CARGO_TENDER_NEGOTIATION_OFFER_ACCEPTED_BY_CREATOR',
  CARGO_TENDER_NEGOTIATION_OFFER_ACCEPTED_BY_PARTICIPANT: 'CARGO_TENDER_NEGOTIATION_OFFER_ACCEPTED_BY_PARTICIPANT',
  CARGO_TENDER_NEGOTIATION_OFFER_REJECTED_BY_CREATOR: 'CARGO_TENDER_NEGOTIATION_OFFER_REJECTED_BY_CREATOR',
  CARGO_TENDER_NEGOTIATION_OFFER_REJECTED_BY_PARTICIPANT: 'CARGO_TENDER_NEGOTIATION_OFFER_REJECTED_BY_PARTICIPANT',
  CARGO_TENDER_NEGOTIATION_CONTRACTED: 'CARGO_TENDER_NEGOTIATION_CONTRACTED',
  WORKFLOW_TASK_STATUS_CHANGED: 'WORKFLOW_TASK_STATUS_CHANGED',
  WORKFLOW_DOCUMENT_SHARED_CHANGED: 'WORKFLOW_DOCUMENT_SHARED_CHANGED',
  WORKFLOW_DOCUMENT_FILE_UPLOADED: 'WORKFLOW_DOCUMENT_FILE_UPLOADED',
  WORKFLOW_DOCUMENT_FILE_DELETED: 'WORKFLOW_DOCUMENT_FILE_DELETED',
  DETAILED_OFFER_PARTICIPANT_REJECT: 'DETAILED_OFFER_PARTICIPANT_REJECT',
  DETAILED_OFFER_PARTICIPANT_ACCEPT: 'DETAILED_OFFER_PARTICIPANT_ACCEPT',
  DETAILED_OFFER_PARTICIPANT_COUNTEROFFER: 'DETAILED_OFFER_PARTICIPANT_COUNTEROFFER',
  SERVICE_PROVIDER_INVITED_TO_WORKFLOW: 'SERVICE_PROVIDER_INVITED_TO_WORKFLOW',
} as const;

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type GetSharedCargoItemResponseDtoCargoInformationDtoCargoNeedDtoTransportation =
  (typeof GetSharedCargoItemResponseDtoCargoInformationDtoCargoNeedDtoTransportation)[keyof typeof GetSharedCargoItemResponseDtoCargoInformationDtoCargoNeedDtoTransportation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSharedCargoItemResponseDtoCargoInformationDtoCargoNeedDtoTransportation = {
  CFR: 'CFR',
  FOB: 'FOB',
  CIF: 'CIF',
} as const;

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type AcceptCompanyPlatformInvitatioExistingUserDTOType =
  (typeof AcceptCompanyPlatformInvitatioExistingUserDTOType)[keyof typeof AcceptCompanyPlatformInvitatioExistingUserDTOType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AcceptCompanyPlatformInvitatioExistingUserDTOType = {
  EXISTING: 'EXISTING',
} as const;

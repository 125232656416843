import { Select, SelectProps } from '@mantine/core';
import { useGetStandardPlants } from 'api/generated/masterdata/masterdata';

import { CargoTradeType } from '@/common/types';

import { useGetCompanyPlantsFilteredSelectItems } from '../state/use-get-company-plants-filtered-select-items';

export interface PlantFilteredSelectProps extends Omit<SelectProps, 'data'> {
  companyId: string;
  productId: string;
  cargoTradeType: CargoTradeType;
}

export function PlantFilteredSelect({ companyId, productId, cargoTradeType, ...props }: PlantFilteredSelectProps) {
  const plantSelectItems = useGetCompanyPlantsFilteredSelectItems(companyId, {
    byProductId: productId,
    byType: cargoTradeType,
  });

  return <Select data={plantSelectItems} disabled={plantSelectItems.length === 0} {...props} />;
}

export function PlantUnFilteredSelect({ companyId, productId, cargoTradeType, ...props }: PlantFilteredSelectProps) {
  const plantsSelectItems = useGetStandardPlants({
    query: { select: (res) => res.data.items },
  });

  const itemMaper = (item) => {
    return {
      value: item.id,
      label: item.name,
    };
  };
  const plants = (plantsSelectItems.data || []).map(itemMaper);

  return <Select data={plants} disabled={plants.length === 0} {...props} id="plant" />;
}

export interface PlantLabelValueSelectProps extends Omit<PlantFilteredSelectProps, 'onChange'> {
  companyId: string;
  productId: string;
  cargoTradeType: CargoTradeType;
  onChange: (value: { label: string; value: string }) => void;
}

export const PlantLabelValueSelect = ({
  onChange,
  companyId,
  productId,
  cargoTradeType,
  ...props
}: PlantLabelValueSelectProps) => {
  const plantSelectItems = useGetCompanyPlantsFilteredSelectItems(
    companyId,
    {
      byProductId: productId,
      byType: cargoTradeType,
    },
    false
  );

  const handleChange = (value: string) => {
    const selectedItem = plantSelectItems.find((item) => item.value === value);
    onChange({ value, label: selectedItem.label });
  };

  return <Select data={plantSelectItems} onChange={handleChange} disabled={plantSelectItems.length === 0} {...props} />;
};

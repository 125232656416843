import { IToggle } from '@unleash/nextjs';
import React from 'react';

import { AvailableFeatureFlags } from '../../api/generated/model';

export const FeatureFlagsContext = React.createContext<IToggle[]>([]);

const useFeatureFlagsContext = () => React.useContext(FeatureFlagsContext);

export const useFeature = (feature: AvailableFeatureFlags): boolean => {
  const flags = useFeatureFlagsContext();

  return flags?.find((flag) => flag.name === feature)?.enabled ?? false;
};

import { isAxiosError } from 'axios';
import { useTranslation } from 'next-i18next';
import { useGetCompanyProductSpecificationDownloadUrl } from 'api/generated/specifications/specifications';

import { isHttpError } from '@/common/error-guards';

export function useSpecificationDownloadUrl(specificationId: string) {
  const { mutateAsync, ...rest } = useGetCompanyProductSpecificationDownloadUrl();

  const getUrl = async () => {
    const data = await mutateAsync({ id: specificationId });
    return data.data.specificationFileUrl;
  };

  return { getUrl, ...rest };
}

function useGetErrorMessage() {
  const { t } = useTranslation('common');

  const getErrorMessage = (errorName: string) => {
    switch (errorName) {
      case 'ProductSpecificationResourceNotFound':
        return t('productSpecification.noFile');
    }
  };

  return {
    getErrorMessage,
  };
}

export function useHandleSpecificationErrors() {
  const { t } = useTranslation('common');
  const { getErrorMessage } = useGetErrorMessage();

  const handleSpecificationErrors = (error: unknown) => {
    if (isAxiosError(error) && isHttpError(error.response.data)) {
      return getErrorMessage(error.response.data.name);
    }
    return t('error.unexpectedError.message');
  };

  return {
    handleSpecificationErrors,
  };
}

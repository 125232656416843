import { z } from 'zod';

export const prepaymentSchema = z.object({
  percentage: z.number(),
  days: z.number(),
  event: z.string(),
});

export const cashAgainstDocumentsSchema = z.object({
  percentage: z.number(),
  days: z.number(),
  numberOfCopies: z.number(),
});

export const irrevocableLetterSchema = z.object({
  percentage: z.number(),
  days: z.number(),
  event: z.string(),
  bankName: z.string(),
  swiftCode: z.string(),
});

export const documentCollectionSchema = z.object({
  percentage: z.number(),
  days: z.number(),
  event: z.string(),
});

export const openAccountSchema = z.object({
  percentage: z.number(),
  days: z.number(),
  event: z.string(),
});

export const consumptionPaymentSchema = z.object({
  percentage: z.number(),
  days: z.number(),
  event: z.string(),
  numberOfInstallments: z.number(),
});

export const otherSchema = z.object({
  percentage: z.number(),
  methodOfPayment: z.string(),
});

export const paymentConditionSchema = z.object({
  key: z.string().optional(),
  name: z.string().nonempty(),
  prepayment: prepaymentSchema.optional(),
  cashAgainstDocuments: cashAgainstDocumentsSchema.optional(),
  irrevocableLetter: irrevocableLetterSchema.optional(),
  documentCollection: documentCollectionSchema.optional(),
  openAccount: openAccountSchema.optional(),
  consumptionPayment: consumptionPaymentSchema.optional(),
  other: otherSchema.optional(),
});

export const cargoTenderPaymentConditionsSchema = z.object({
  allocation: z.string().optional(),
  conditions: z.array(paymentConditionSchema).min(1),
});

export type CargoTenderPaymentConditions = z.infer<typeof cargoTenderPaymentConditionsSchema>;
export type PaymentCondition = z.infer<typeof paymentConditionSchema>;

export type CheckboxCondition = {
  label: string;
  checked: boolean;
};

export type PaymentConditionCheck = {
  key?: string;
  name?: string;
  prepayment?: PrepaymentConditionCheck;
  cashAgainstDocuments?: CashAgainstDocumentsCheck;
  irrevocableLetter?: IrrevocableLetterCheck;
  documentCollection?: DocumentCollectionCheck;
  openAccount?: OpenAccountCheck;
  consumptionPayment?: ConsumptionPaymentCheck;
  other?: OtherPaymentCheck;
};

export type PrepaymentCondition = z.infer<typeof prepaymentSchema>;
export type PrepaymentConditionCheck = PrepaymentCondition & CheckboxCondition;

export type CashAgainstDocuments = z.infer<typeof cashAgainstDocumentsSchema>;
export type CashAgainstDocumentsCheck = CashAgainstDocuments & CheckboxCondition;

export type IrrevocableLetter = z.infer<typeof irrevocableLetterSchema>;
export type IrrevocableLetterCheck = IrrevocableLetter & CheckboxCondition;

export type DocumentCollection = z.infer<typeof documentCollectionSchema>;
export type DocumentCollectionCheck = DocumentCollection & CheckboxCondition;

export type OpenAccount = z.infer<typeof openAccountSchema>;
export type OpenAccountCheck = OpenAccount & CheckboxCondition;

export type ConsumptionPayment = z.infer<typeof consumptionPaymentSchema>;
export type ConsumptionPaymentCheck = ConsumptionPayment & CheckboxCondition;

export type OtherPayment = z.infer<typeof otherSchema>;
export type OtherPaymentCheck = OtherPayment & CheckboxCondition;

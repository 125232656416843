/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type Da = (typeof Da)[keyof typeof Da];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Da = {
  YES: 'YES',
  NO: 'NO',
} as const;

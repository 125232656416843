import { ReactNode } from 'react';
import { Alert, createStyles, Group, Stack } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { RiInformationLine } from 'react-icons/ri';

import { ProductSpecificationByQuery, ProductSpecificationByQueryProps, Text } from '@/components';

const useStyles = createStyles((theme) => ({
  root: {
    padding: 0,
    border: 0,
    [theme.fn.largerThan('md')]: {
      width: 475,
    },
  },
  message: {
    fontSize: '12px',
    color: theme.colors.gray[7],
  },
  icon: {
    color: theme.colors.gray[5],
    marginRight: '5px',
  },
}));

export interface ProductSpecificationWrapperProps {
  children: ReactNode;
}

export function ProductSpecificationWrapper(props: ProductSpecificationByQueryProps) {
  const { t } = useTranslation('recapForm');
  const { classes } = useStyles();

  return (
    <Stack spacing={5}>
      <Group spacing={10}>
        <Text weight={500}>{t('labels.productSpecification')} </Text>
        <ProductSpecificationByQuery {...props} />
      </Group>
      <Alert classNames={classes} icon={<RiInformationLine size={16} />} variant="outline">
        {t('productSpecificationHint')}
      </Alert>
    </Stack>
  );
}

import { Anchor, Button, Checkbox, Container, Group, Stack, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { Trans, useTranslation } from 'next-i18next';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { RiArrowLeftLine } from 'react-icons/ri';

import { CountrySelector, NeedHelp, Text } from '@/components';
import { routes } from '@/common/routes';

import { getSelectCountryData, stepOneInputs } from '../data';
import { RegisterForm } from '../types';
import { useSharedRegistrationStyles } from './use-shared-registration-styles';

export interface StepOneProps {
  form: UseFormReturnType<RegisterForm, undefined>;
  goBack: VoidFunction;
  submit: (form: RegisterForm) => void;
  submitting: boolean;
}

export function StepOne(props: StepOneProps) {
  const [countryCode, setCountryCode] = useState('');
  const { t, i18n } = useTranslation('register');
  const { form, submitting, goBack, submit } = props;
  const countryOptions = useMemo(() => getSelectCountryData(i18n.language), [i18n.language]);
  const { classes } = useSharedRegistrationStyles();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <Container className={classes.stepContainer}>
      <form onSubmit={form.onSubmit(submit)}>
        <Text size="S" className={classes.companyText}>
          {t('labels.companyData')}
        </Text>
        <Stack className={classes.stack}>
          {stepOneInputs.map(({ label, placeholder }, i) => (
            <TextInput
              size="md"
              key={label}
              label={t(`labels.${label}`) as ReactNode}
              placeholder={t(`placeholders.${placeholder}`)}
              data-testid={label}
              withAsterisk={label !== 'address2'}
              autoFocus={i === 0}
              {...form.getInputProps(label)}
            />
          ))}

          <CountrySelector
            size="md"
            label={t('labels.country')}
            placeholder={t('placeholders.country')}
            data-testid={'country'}
            data={countryOptions}
            itemComponent={CountrySelector.selectOption}
            countryCode={countryCode}
            searchable
            withAsterisk
            filterDataOnExactSearchMatch
            {...form.getInputProps('country', { withError: true })}
            onChange={(value) => {
              form.getInputProps('country').onChange(value);
              setCountryCode(value.toLocaleLowerCase());
            }}
          />

          <TextInput
            size="md"
            label={t('labels.vatNumber')}
            placeholder={t('placeholders.vatNumber')}
            data-testid={'vatNumber'}
            withAsterisk
            {...form.getInputProps('vatNumber', { withError: true })}
          />

          <Checkbox
            classNames={{ label: classes.text }}
            label={
              <Trans i18nKey="labels.checkbox.terms" t={t}>
                I have read and agreed to the
                <Anchor
                  underline={false}
                  className={classes.termsAndCondition}
                  href={routes.TermsOfUse}
                  target="_blank"
                  key="link"
                />
              </Trans>
            }
            data-testid={'termsCheckbox'}
            color="green"
            {...form.getInputProps('isTermsAndConditionsAccepted', { type: 'checkbox', withError: true })}
          />

          <Button className={classes.button} fullWidth size="lg" type="submit" loading={submitting}>
            {t('labels.register')}
          </Button>

          <Group position="apart">
            <Button className={classes.backButton} variant="subtle" leftIcon={<RiArrowLeftLine />} onClick={goBack}>
              {t('labels.back')}
            </Button>
            <NeedHelp />
          </Group>
        </Stack>
      </form>
    </Container>
  );
}

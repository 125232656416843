import { z } from 'zod';

import { Jurisdiction, Laws } from '../../types';

export const lawAndJurisdiction = z.object({
  law: z
    .nativeEnum(Laws)
    .nullable()
    .refine((val) => val !== null, {
      message: 'This field is required',
    }),
  jurisdiction: z
    .nativeEnum(Jurisdiction)
    .nullable()
    .refine((val) => val !== null, {
      message: 'This field is required',
    }),
});

export type LawAndJurisdictionSchema = z.infer<typeof lawAndJurisdiction>;

import { Group, Paper, Skeleton } from '@mantine/core';

import { BackButton, Title } from '@/components';

interface PageHeaderProps {
  title: string;
  children?: React.ReactNode;
  backBtn?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
}

export function PageHeader({ title, children, backBtn = true, isLoading, onClick }: PageHeaderProps) {
  return (
    <Paper p={{ base: '14px 12px', md: '20px 24px' }}>
      {isLoading ? (
        <Skeleton width="100%" height="2.5em" />
      ) : (
        <Group position="apart" maw={1232} mx="auto">
          <Group spacing={8}>
            {backBtn && <BackButton onClick={onClick} />}
            <Title order={1} size="XL" weight={600}>
              {title}
            </Title>
          </Group>
          {children}
        </Group>
      )}
    </Paper>
  );
}

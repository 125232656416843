import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { FreightRecapFormTemplateListItemDTO } from 'api/generated/model';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { CargoIdCell, DateCell } from '@/components';
import { LoadFreightTemplateTable } from '@/views/freight-recap-form';

import { ActionsFunction } from '../types';

const columnHelper = createColumnHelper<FreightRecapFormTemplateListItemDTO>();

interface UseFreightTemplateColumnsProps {
  onDelete: ActionsFunction;
  onUseTemplate: ActionsFunction;
}

export function useFreightTemplateColumns(handlers: UseFreightTemplateColumnsProps) {
  const { t } = useTranslation('recapForm');

  const columns: ColumnDef<FreightRecapFormTemplateListItemDTO>[] = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: t('modals.loadTemplate.table.templateName'),
        cell: CargoIdCell,
      }),
      columnHelper.accessor('createdAt', {
        header: t('modals.loadTemplate.table.createdAt'),
        cell: DateCell,
        meta: {
          mobileHidden: true,
        },
      }),
      columnHelper.accessor('id', {
        header: '',
        enableSorting: false,
        cell: (props) => <LoadFreightTemplateTable.ActionsCell {...props} {...handlers} />,
      }),
    ],
    [handlers, t]
  );

  return columns;
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoTenderStatus = (typeof CargoTenderStatus)[keyof typeof CargoTenderStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoTenderStatus = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
} as const;

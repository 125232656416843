import { Flex, NumberInput, Select } from '@mantine/core';
import { Trans, useTranslation } from 'next-i18next';

import { PrepaymentEvents } from '@/blink-forms';
import { GetInputProps } from '@/common/types';
import { useGetSelectDataWithTranslations } from '@/views/tenders/utils/use-get-select-data-with-translations';
import { PercentageField } from '@/components';
import { getThousandSeparatedNumberProps } from '@/common/get-thousand-seperated-number';

interface PrepaymentFieldGroupProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getInputProps: GetInputProps<any>;
  data?: any;
  percentagePath: string;
  daysPath: string;
  eventPath: string;
}

export function Prepayment({ getInputProps, percentagePath, daysPath, eventPath }: PrepaymentFieldGroupProps) {
  const { t } = useTranslation('tenderNegotiation');

  const PrepaymentEventsData = useGetSelectDataWithTranslations(PrepaymentEvents);

  return (
    <Flex gap="md" justify="flex-start" align="center" direction="row" wrap="wrap" sx={{ paddingTop: 20 }}>
      <Trans
        t={t}
        i18nKey="formSentences.prepayment"
        components={{
          percentage: (
            <PercentageField min={0} max={100} maxLength={3} h={'auto'} w={70} {...getInputProps(percentagePath)} />
          ),
          days: <NumberInput h={'auto'} w={56} {...getInputProps(daysPath)} {...getThousandSeparatedNumberProps()} />,
          select: <Select h={'auto'} data={PrepaymentEventsData} {...getInputProps(eventPath)} />,
        }}
      />
    </Flex>
  );
}

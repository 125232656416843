import { useForm, UseFormReturnType, zodResolver } from '@mantine/form';
import { GetInputPropsType } from '@mantine/form/lib/types';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';

import { GetPaths } from '@/common/types';
import { FreightRecapForm, freightRecapFormSchema } from '@/blink-forms';
import recapFormMasterdata from '@/views/recap-form/masterdata.json';

import {
  FreightPaymentConditionType,
  InsertableFormGroupIndex,
  SelectedFreightPaymentConditions,
  FreightTemplate,
} from '../types';

interface FreightRecapFormState {
  form: UseFormReturnType<FreightRecapForm>;
  selectedPaymentConditions: SelectedFreightPaymentConditions;
  initialValues: FreightRecapForm;
  companyName: string;
  togglePaymentCondition: (condition: FreightPaymentConditionType) => void;
  setSelectedPaymentConditions: Dispatch<SetStateAction<SelectedFreightPaymentConditions>>;
}

const FreightRecapFormContextProvider = createContext<FreightRecapFormState>(null);

interface FreightRecapFormProviderProps {
  children: ReactNode;
  initialValues: FreightRecapForm;
  selectedFreightPaymentConditions: SelectedFreightPaymentConditions;
  companyName: string;
  template?: FreightTemplate;
}

export function FreightRecapFormProvider(props: FreightRecapFormProviderProps) {
  const { children, initialValues, selectedFreightPaymentConditions, companyName, template } = props;

  const form = useForm<FreightRecapForm>({
    initialValues,
    validate: zodResolver(freightRecapFormSchema),
    validateInputOnBlur: true,
  });

  const [selectedPaymentConditions, setSelectedPaymentConditions] = useState<SelectedFreightPaymentConditions>(
    selectedFreightPaymentConditions
  );
  useEffect(() => {
    if (template && template.recapForm) {
      form.setValues(initialValues);
      setSelectedPaymentConditions(selectedFreightPaymentConditions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  const togglePaymentCondition = (condition: FreightPaymentConditionType) =>
    setSelectedPaymentConditions((state) => {
      form.setFieldValue(
        `payment.conditions.${condition}`,
        state[condition].checked ? undefined : condition === 'other' ? '' : {}
      );
      const checked = !state[condition].checked;
      const isDirty = false;
      return { ...state, [condition]: { checked, isDirty } };
    });

  return (
    <FreightRecapFormContextProvider.Provider
      value={{
        initialValues,
        form,
        selectedPaymentConditions,
        companyName,
        togglePaymentCondition,
        setSelectedPaymentConditions,
      }}
    >
      {children}
    </FreightRecapFormContextProvider.Provider>
  );
}

export function useFreightRecapFormState() {
  const freightRecapFormState = useContext(FreightRecapFormContextProvider);
  const getInputProps = (
    path: GetPaths<FreightRecapForm, InsertableFormGroupIndex>,
    getInputPropsOptions?: { type?: GetInputPropsType; withError?: boolean; withFocus?: boolean }
  ) => ({
    ...freightRecapFormState.form.getInputProps(path, getInputPropsOptions),
    isDirty: freightRecapFormState.form.isDirty(path),
  });

  return { ...freightRecapFormState, getInputProps, masterdata: recapFormMasterdata };
}

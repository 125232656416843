import { Flex, TextInput } from '@mantine/core';
import { Trans, useTranslation } from 'next-i18next';

import { GetInputProps } from '@/common/types';
import { PercentageField } from '@/components';

interface OtherPaymentGroupProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getInputProps: GetInputProps<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  percentagePath: string;
  methodOfPaymentPath: string;
}

export function OtherPayment({ getInputProps, percentagePath, methodOfPaymentPath }: OtherPaymentGroupProps) {
  const { t } = useTranslation('tenderNegotiation');

  return (
    <Flex gap="md" justify="flex-start" align="center" direction="row" wrap="wrap" sx={{ paddingTop: 20 }}>
      <Trans
        t={t}
        i18nKey="formSentences.other"
        components={{
          percentage: <PercentageField min={0} max={100} maxLength={3} h={'auto'} {...getInputProps(percentagePath)} />,
          text: <TextInput h={'auto'} {...getInputProps(methodOfPaymentPath)} />,
        }}
      />
    </Flex>
  );
}

import { ReactNode } from 'react';
import { createStyles, Group, Image, Paper, Stack } from '@mantine/core';

import { NeedHelp, Text, Title } from '@/components';

const useStyles = createStyles((theme) => ({
  paper: {
    padding: '96px 104px 96px 56px',
    backgroundColor: '#F3F4F6',
    borderRadius: 8,

    [theme.fn.smallerThan('md')]: {
      padding: 16,
    },
  },
  group: {
    flexWrap: 'nowrap',
    gap: 80,

    [theme.fn.smallerThan('md')]: {
      flexWrap: 'wrap',
      gap: 20,
    },
  },
}));

interface EmptyResultsProps {
  children: ReactNode;
  title: string;
  description?: string;
}

export function EmptyResults({ children, title, description }: EmptyResultsProps) {
  const { classes } = useStyles();

  return (
    <Paper className={classes.paper}>
      <Group position="center" className={classes.group}>
        <Image src="/images/empty-results.svg" alt="Empty results" width={178} height={235} />
        <Stack>
          <Title order={2} size="XL">
            {title}
          </Title>
          {description && <Text color="gray.8">{description}</Text>}
          <NeedHelp align="start" />
          <Group spacing={8}>{children}</Group>
        </Stack>
      </Group>
    </Paper>
  );
}

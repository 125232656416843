import axios from 'axios';
import { AxiosRequestConfig } from 'axios';
import { Session } from 'next-auth';

export function setBearerToken(session: Session, config?: AxiosRequestConfig) {
  if (session?.id_token) {
    const bearer = `Bearer ${session.id_token}`;
    axios.defaults.headers.common['Authorization'] = bearer;
    if (config) {
      config.headers['Authorization'] = bearer;
    }
  }
}

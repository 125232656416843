import {
  Anchor,
  AnchorProps,
  Box,
  Checkbox as MnCheckbox,
  CheckboxProps as MnCheckboxProps,
  createStyles,
  InputBaseProps,
  NumberInput as MnNumberInput,
  NumberInputProps as MnNumberInputProps,
  Radio as MnRadio,
  RadioProps as MnRadioProps,
  Select as MnSelect,
  SelectProps as MnSelectProps,
  TextInput as MnTextInput,
  TextInputProps as MnTextInputProps,
  Tooltip,
  Textarea as MnTextarea,
  TextareaProps as MnTextareaProps,
} from '@mantine/core';
import { useRouter } from 'next/router';
import { Fragment, memo, ReactElement, ReactNode } from 'react';
import { IconType } from 'react-icons/lib';
import { RiAddLine, RiAlertLine, RiCloseLine } from 'react-icons/ri';

import { InsertableFormGroupIndex } from '../state/RecapFormProvider';
import { InsertableFormGroup } from '../types';

const useStyles = createStyles((theme) => ({
  horizontalField: {
    position: 'relative',

    [theme.fn.largerThan('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  highlightField: {
    '&:before': {
      background: theme.colors.yellow[0],
      content: '""',
      position: 'absolute',
      left: -4,
      right: -4,
      top: -4,
      bottom: -4,
      borderRadius: 8,
    },
  },
  horizontalLabel: {
    whiteSpace: 'nowrap',
    marginRight: 8,
    transform: 'translateY(4px)',
  },
  horizontalError: {
    [theme.fn.largerThan('md')]: {
      display: 'none',
    },
  },
  horizontalDescription: {
    [theme.fn.largerThan('md')]: {
      fontSize: 16,
      marginLeft: 16,
    },
  },
}));

interface IconLinkProps extends AnchorProps {
  children: ReactNode;
  onClick?: () => void;
  icon: IconType;
}

function IconLink(props: IconLinkProps) {
  const { children, icon: Icon, ...rest } = props;
  return (
    <Anchor component="button" weight={600} sx={{ display: 'flex', alignItems: 'center' }} {...rest}>
      <Icon style={{ fontSize: '24', marginRight: 8 }} />
      {children}
    </Anchor>
  );
}

export function AddItemLink(props: Omit<IconLinkProps, 'icon'>) {
  return <IconLink icon={RiAddLine} {...props} />;
}

export function RemoveItemLink(props: Omit<IconLinkProps, 'icon'>) {
  return <IconLink icon={RiCloseLine} {...props} />;
}

interface UseBaseClassesProps extends Pick<InputBaseProps, 'w'> {
  isDirty?: boolean;
  horizontal?: boolean;
  label?: ReactNode;
  highlighted?: boolean;
}

export function useBaseInputProps(props: UseBaseClassesProps) {
  const { isDirty, label, w, highlighted } = props;
  const { classes, cx } = useStyles();
  const router = useRouter();
  const isCounteroffer = router.pathname.includes('counteroffer');
  const isHighlight = highlighted || (isCounteroffer && isDirty);
  const horizontal = props.horizontal ?? !label;
  const classNames = {
    root: cx(isHighlight && classes.highlightField, horizontal && classes.horizontalField),
    label: cx(horizontal && classes.horizontalLabel),
    description: cx(horizontal && classes.horizontalDescription),
    error: cx(horizontal && classes.horizontalError),
  };
  const preparedLabel = isHighlight ? (
    <>
      {label}
      <Tooltip label="Modified">
        <Box
          sx={(theme) => ({
            display: 'inline-block',
            color: theme.colors.yellow[6],
            fontSize: 18,
            transform: 'translateY(4px)',
          })}
          ml={4}
        >
          <RiAlertLine />
        </Box>
      </Tooltip>
    </>
  ) : (
    label
  );

  return {
    classNames,
    label: preparedLabel,
    size: 'md',
    w: w && typeof w === 'number' && isHighlight ? w + 40 : w,
  } as const;
}

interface SelectProps extends MnSelectProps {
  horizontal?: boolean;
  grow?: boolean;
  isDirty?: boolean;
  highlighted?: boolean;
}

export function SelectWithoutMemo(props: SelectProps) {
  const { horizontal, grow = true, isDirty, label, highlighted, withAsterisk, ...rest } = props;

  return (
    <MnSelect
      {...useBaseInputProps({ isDirty, horizontal, label, highlighted })}
      sx={{ height: 'auto', flexGrow: grow ? 1 : 0 }}
      withAsterisk={withAsterisk}
      {...rest}
    />
  );
}

export const Select = memo(
  SelectWithoutMemo,
  (prev, next) => prev.value === next.value && prev.error === next.error && prev.label === next.label
);

interface TextInputProps extends MnTextInputProps {
  horizontal?: boolean;
  isDirty?: boolean;
}

export function TextInputWithoutMemo(props: TextInputProps) {
  const { horizontal, isDirty, label, ...rest } = props;

  return (
    <MnTextInput
      {...useBaseInputProps({ isDirty, horizontal, label })}
      sx={{ height: 'auto' }}
      {...rest}
      onChange={(event) => {
        rest.onChange(event);
        if (event.currentTarget.value === '') {
          rest.onChange(undefined);
        }
      }}
    />
  );
}

export const TextInput = memo(
  TextInputWithoutMemo,
  (prev, next) => prev.value === next.value && prev.error === next.error
);

interface TextareaProps extends MnTextareaProps {
  horizontal?: boolean;
  isDirty?: boolean;
}

function TextareaWithoutMemo(props: TextareaProps) {
  const { horizontal, isDirty, label, ...rest } = props;
  return (
    <MnTextarea
      {...useBaseInputProps({ isDirty, horizontal, label })}
      sx={{ height: 'auto' }}
      {...rest}
      onChange={(event) => {
        rest.onChange(event);
        if (event.currentTarget.value === '') {
          rest.onChange(undefined);
        }
      }}
    />
  );
}

export const Textarea = memo(
  TextareaWithoutMemo,
  (prev, next) => prev.value === next.value && prev.error === next.error
);

export interface NumberInputProps extends MnNumberInputProps {
  horizontal?: boolean;
  isDirty?: boolean;
}

function NumberInputWithoutMemo(props: NumberInputProps) {
  const { horizontal, isDirty, label, w, ...rest } = props;
  return <MnNumberInput {...useBaseInputProps({ isDirty, horizontal, label, w })} sx={{ height: 'auto' }} {...rest} />;
}

export const NumberInput = memo(
  NumberInputWithoutMemo,
  (prev, next) => prev.value === next.value && prev.error === next.error && prev.label === next.label
);

interface CheckboxProps extends MnCheckboxProps {
  isDirty?: boolean;
}

function CheckboxWithoutMemo(props: CheckboxProps) {
  const { isDirty, label, ...rest } = props;
  return <MnCheckbox {...useBaseInputProps({ isDirty, horizontal: false, label })} {...rest} />;
}

export const Checkbox = memo(
  CheckboxWithoutMemo,
  (prev, next) =>
    prev.value === next.value &&
    prev.error === next.error &&
    prev.checked === next.checked &&
    prev.isDirty === next.isDirty
);

export const CheckboxGroup = MnCheckbox.Group;

interface RadioProps extends MnRadioProps {
  isDirty?: boolean;
}

export function Radio(props: RadioProps) {
  const { isDirty, label, ...rest } = props;
  return <MnRadio {...useBaseInputProps({ isDirty, horizontal: false, label })} {...rest} />;
}

Radio.Group = MnRadio.Group;

export type FieldGroupComponent = (props: { index: InsertableFormGroupIndex }) => ReactElement;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface InsertableFieldGroupProps<T extends any[]> {
  group: InsertableFormGroup<T>;
  fieldGroupComponent: FieldGroupComponent;
  removeLabel: string;
  addLabel: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function InsertableFieldGroupWithoutMemo<T extends any[]>(props: InsertableFieldGroupProps<T>) {
  const { group, fieldGroupComponent: FieldGroupComponent, removeLabel, addLabel } = props;

  return (
    <Fragment key={(group.value || []).length}>
      {(group.value || []).map((_, i: InsertableFormGroupIndex) => (
        // eslint-disable-next-line react/jsx-key
        <Fragment key={i}>
          <FieldGroupComponent index={i} />
          {group.canRemove && <RemoveItemLink onClick={() => group.remove(i)}>{removeLabel}</RemoveItemLink>}
        </Fragment>
      ))}
      {group.canInsert && <AddItemLink onClick={() => group.insert()}>{addLabel}</AddItemLink>}
    </Fragment>
  );
}

export const InsertableFieldGroup = memo(InsertableFieldGroupWithoutMemo);

function isNumeric(n: string | undefined) {
  return n !== undefined && n !== '' && !isNaN(+n) && !n.endsWith('.');
}

export function getBasePercentageProps(precision = 1) {
  const toFraction = (value: string) => `${+value / 100}`;

  const toPercentage = (value: string, precision: number) => {
    const percentage = (+value * 100).toFixed(precision);
    return parseFloat(percentage).toString();
  };

  return {
    parser: (value: string | undefined) => {
      const number = value?.replace(',', '.');
      return isNumeric(number) ? toFraction(number) : number;
    },
    formatter: (value: string | undefined) => {
      const number = value?.replace(',', '.');
      return isNumeric(number) ? toPercentage(number, precision) : number;
    },
    precision: precision + 2,
    step: 10 ** (-1 * precision),
    rightSection: '%',
  };
}

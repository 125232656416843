import { useTranslation } from 'next-i18next';
import { FreightAlgorithm, FreightAlgorithmRequest } from 'api/blink-freight/model';
import { useCalculator } from 'api/blink-freight/default/default';
import { useEffect } from 'react';
import { RiInformationLine } from 'react-icons/ri';
import { Group, Tooltip, Box, createStyles } from '@mantine/core';

import { DEFAULT_FREIGHT_ESTIMATE_COMMISION } from '@/common/consts';
import { Text } from '@/components';

export interface FreightEstimateValueProps {
  loadingPort?: string;
  dischargingPort?: string;
  loadingRate?: number;
  dischargingRate?: number;
  quantity?: number;
  minRange?: number;
  maxRange?: number;
  laycanDate?: string;
  product?: string;
}

const useStyles = createStyles((theme) => ({
  icon: {
    color: theme.fn.primaryColor(),
    fontSize: 14,
    display: 'inline-flex',
    [theme.fn.smallerThan('xs')]: {
      fontSize: 16,
      height: 36,
    },
  },
}));

const formatResults = (data: FreightAlgorithm) => {
  if (data?.freight) {
    return `$${data.freight.min}-${data.freight.max}`;
  }
};

export function FreightEstimateValue(props: FreightEstimateValueProps) {
  const { t } = useTranslation('freightDetails');
  const { theme } = useStyles();
  const {
    loadingPort,
    dischargingPort,
    loadingRate,
    dischargingRate,
    quantity,
    minRange,
    maxRange,
    laycanDate,
    product,
  } = props;

  const freightEstimateRequest: FreightAlgorithmRequest = {
    commission: DEFAULT_FREIGHT_ESTIMATE_COMMISION,
    loadingPort,
    dischargingPort,
    loadingRate,
    dischargingRate,
    quantity,
    minRange,
    maxRange,
    laycanDate,
    product,
  };

  const requestFreightEstimate = useCalculator({
    mutation: {
      mutationKey: ['freightCalculator'],
    },
    axios: { baseURL: process.env.NEXT_PUBLIC_BLINK_FREIGHT_URL },
  });

  useEffect(() => {
    requestFreightEstimate.mutate({
      data: freightEstimateRequest,
    });
  }, []);

  let priceEstimate =
    t('freightEstimateSummary.estimateNotAvailable') != 'freightEstimateSummary.estimateNotAvailable'
      ? t('freightEstimateSummary.estimateNotAvailable')
      : 'Estimate not available';

  if (requestFreightEstimate?.data) {
    priceEstimate = formatResults(requestFreightEstimate?.data?.data);
  }

  const freightEstimateData: FreightAlgorithm = requestFreightEstimate?.data?.data;
  const freightTooltipValue = freightEstimateData
    ? `${
        t('freightEstimateSummary.legendCalculated') != 'freightEstimateSummary.legendCalculated'
          ? t('freightEstimateSummary.legendCalculated')
          : 'Calculated on the following data'
      }
      ${
        t('freightEstimateSummary.vesselType') != 'freightEstimateSummary.vesselType'
          ? t('freightEstimateSummary.vesselType')
          : 'Vessel type: '
      } ${freightEstimateData.vesselType}
      ${
        t('freightEstimateSummary.speed') != 'freightEstimateSummary.speed'
          ? t('freightEstimateSummary.speed')
          : 'Speed: '
      } ${freightEstimateData.fuelType}
      ${
        t('freightEstimateSummary.intake') != 'freightEstimateSummary.intake'
          ? t('freightEstimateSummary.intake')
          : 'Intake: '
      } ${freightEstimateData.intake} mts`
    : '';

  return (
    <Group spacing={4}>
      <Text size="M" weight={600}>
        {t('freightEstimateSummary.blinkEstimate') != 'freightEstimateSummary.blinkEstimate'
          ? t('freightEstimateSummary.blinkEstimate')
          : `Blink's estimate`}
        :
      </Text>
      <Text size="M">{priceEstimate}</Text>
      {requestFreightEstimate?.data ? (
        <Tooltip label={freightTooltipValue} multiline width={422} withArrow>
          <Box sx={{ transform: 'translateY(3px) translateX(-3px)' }}>
            <RiInformationLine color={theme.fn.primaryColor()} />
          </Box>
        </Tooltip>
      ) : null}
    </Group>
  );
}

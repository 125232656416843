/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type GetAllDetailedOffersInvitedResponseItemDtoCargoDtoCompanyPortWithPortDtoFreeDA =
  (typeof GetAllDetailedOffersInvitedResponseItemDtoCargoDtoCompanyPortWithPortDtoFreeDA)[keyof typeof GetAllDetailedOffersInvitedResponseItemDtoCargoDtoCompanyPortWithPortDtoFreeDA];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAllDetailedOffersInvitedResponseItemDtoCargoDtoCompanyPortWithPortDtoFreeDA = {
  YES: 'YES',
  NO: 'NO',
} as const;

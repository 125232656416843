import { SelectItem } from '@mantine/core';
import { useGetCompanyPorts } from 'api/generated/companies/companies';
import { useGetStandarDataSelectOptions } from 'api/generated/masterdata/masterdata';
import { PortDTO, StandardDataSelectOptionsDTO } from 'api/generated/model';
import { AxiosResponse } from 'axios';

import { toSelectItem } from '@/common/arrays';
import { mapPlantsToSelectData } from '@/common/mapping-helpers';

interface SelectStandardDataProps {
  data: AxiosResponse<StandardDataSelectOptionsDTO>;
  origin: string;
  productName: string;
  packaging?: string;
}

interface NegotiationSides {
  sellerCompanyId: string;
  buyerCompanyId: string;
}

interface NegotiationSidesPorts {
  buyerPorts: PortDTO[];
  sellerPorts: PortDTO[];
}

export function useStandardData(sides: NegotiationSides, input: Omit<SelectStandardDataProps, 'data'>) {
  const sellerPorts = useGetCompanyPorts(sides.sellerCompanyId, {
    query: { select: (response) => response.data.items },
  });
  const buyerPorts = useGetCompanyPorts(sides.buyerCompanyId, { query: { select: (response) => response.data.items } });
  return {
    ...useGetStandarDataSelectOptions({
      query: {
        select: (data) =>
          selectStandardData(
            { data, ...input },
            { sellerPorts: sellerPorts.data || [], buyerPorts: buyerPorts.data || [] }
          ),
      },
    }),
    isLoading: sellerPorts.isLoading || buyerPorts.isLoading,
  };
}

function selectStandardData(props: SelectStandardDataProps, negotiationSidesPorts: NegotiationSidesPorts) {
  const { data, origin, productName, packaging } = props;
  const { sellerPorts, buyerPorts } = negotiationSidesPorts;
  const response = data.data;
  const products = response.products.filter(
    (product) => product.name === productName && (!packaging || product.packaging === packaging)
  );
  const packagings: string[] = [...new Set(products.map((product) => product.packaging))];
  const grades: string[] = [...new Set(products.map((product) => product.grade.name))];

  const plants: SelectItem[] = mapPlantsToSelectData(response.plants.filter((plant) => plant.country === origin));
  const allPlants: SelectItem[] = mapPlantsToSelectData(response.plants);

  const loadingPorts: SelectItem[] = response.ports
    .filter((port) => sellerPorts.some((sellerPort) => sellerPort.standardPortId === port.id))
    .map(toSelectItem);
  const dischargingPorts = response.ports
    .filter((port) => buyerPorts.some((buyerPort) => buyerPort.standardPortId === port.id))
    .map(toSelectItem);

  return { plants, allPlants, products, packagings, grades, loadingPorts, dischargingPorts };
}

import { Box, Flex, UnstyledButton, createStyles } from '@mantine/core';
import { format } from 'date-fns';

import { StaticIndicator, Text } from '@/components';

import { ResolvedNotification } from '../state/use-get-notifications';

const useStyles = createStyles((theme) => ({
  time: {
    color: theme.colors.background[2],
    display: 'block',
    paddingTop: 10,
    fontSize: 12,
    lineHeight: 1,
  },
  item: {
    padding: '9px 9px 11px',
    borderBottom: `solid 1px ${theme.colors.gray[1]}`,

    '&:hover': {
      backgroundColor: theme.colors.gray[0],
    },
  },
  message: {
    strong: {
      color: theme.colors.gray[8],
    },
  },
}));

interface NotificationItemProps {
  notification: ResolvedNotification;
  onNotificationClick: (notification: ResolvedNotification) => void;
}

export function NotificationItem({ notification, onNotificationClick }: NotificationItemProps) {
  const { classes } = useStyles();

  return (
    <UnstyledButton className={classes.item} onClick={() => onNotificationClick(notification)}>
      <Flex wrap="nowrap" gap={8}>
        <Box sx={{ paddingTop: 6, minWidth: 8 }}>
          <StaticIndicator size={8} disabled={Boolean(notification.readAt)}>
            {' '}
          </StaticIndicator>
        </Box>

        <Flex direction="column" align="flex-start">
          {notification.title && (
            <Text size="XS" weight={600} color="gray.8">
              {notification.title}
            </Text>
          )}
          <Text size="XS" className={classes.message} color="background.2">
            <span dangerouslySetInnerHTML={{ __html: notification.message }} />
          </Text>
          <time className={classes.time}>{format(new Date(notification.createdAt), "MMMM d, yyyy 'at' H:mm a")}</time>
        </Flex>
      </Flex>
    </UnstyledButton>
  );
}

import { Button, createStyles, Group, Stack, ThemeIcon } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { RiFileMarkLine } from 'react-icons/ri';
import { CreateCargoDraftDTORecapForm } from 'api/generated/model';
import { UseFormReturnType } from '@mantine/form';

import { usePopupOnNavigationChange } from '@/common/use-popup-on-navigation';
import { BaseModal, Text } from '@/components';
import { useFreightRecapFormState } from '@/views/freight-recap-form';

import { useRecapFormContext } from '../state/RecapFormProvider';

const useStyles = createStyles((theme) => ({
  themeIcon: {
    width: 40,
    height: 40,
    borderRadius: theme.radius.md,
    backgroundColor: theme.colors.blue[0],
  },
  icon: {
    color: theme.fn.primaryColor(),
    fontSize: 20,
  },
}));

interface SaveDraftModalProps<T> {
  opened: boolean;
  displayPopup: boolean;
  form: UseFormReturnType<T>;
  toggle: VoidFunction;
  onSaveDraft: (data: CreateCargoDraftDTORecapForm) => void;
}

function SaveDraftModal<T>(props: SaveDraftModalProps<T>) {
  const { opened, displayPopup, form, toggle, onSaveDraft } = props;
  const { classes } = useStyles();
  const { t } = useTranslation('recapForm');

  const saveDraft = () => {
    if (form.validate()) {
      onSaveDraft(form.values);
    }
    form.resetDirty();
  };
  const { onConfirmRouteChange, onPopupReject } = usePopupOnNavigationChange({
    displayPopup: form.isDirty() && displayPopup,
    onConfirm: saveDraft,
    isModalOpened: opened,
    toggleModal: toggle,
    alertMessage: t('modals.saveDraft.leaveRouteMessage'),
  });

  const icon = (
    <ThemeIcon className={classes.themeIcon}>
      <RiFileMarkLine className={classes.icon} />
    </ThemeIcon>
  );

  const content = (
    <Stack spacing={24}>
      <Text color="gray.6" size="S">
        {t('modals.saveDraft.description')}
      </Text>
      <Group grow>
        <Button onClick={onPopupReject} variant="outline" size="md">
          {t('modals.saveDraft.discard')}
        </Button>
        <Button onClick={onConfirmRouteChange} size="md">
          {t('modals.saveDraft.saveDraft')}
        </Button>
      </Group>
    </Stack>
  );

  return (
    <BaseModal icon={icon} opened={opened} close={toggle} header={t('modals.saveDraft.heading')} content={content} />
  );
}

export function SaveCargoDraftModal<T>(props: Omit<SaveDraftModalProps<T>, 'form'>) {
  const { form } = useRecapFormContext();

  return <SaveDraftModal {...props} form={form} />;
}

export function SaveFreightDraftModal<T>(props: Omit<SaveDraftModalProps<T>, 'form'>) {
  const { form } = useFreightRecapFormState();

  return <SaveDraftModal {...props} form={form} />;
}

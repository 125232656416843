/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';

import type {
  CreateTaskResponseDTO,
  CreateTaskRequestDTO,
  GetWorkflowsResponseDTOV2,
  ShareTaskFileRequestDto,
  UploadTaskFileRequestDto,
  GetWorkflowTaskResponseDto,
  UpdateTaskRequestDTO,
  GetSelectedCargoesGlobalDateResponseDTO,
  GetSelectedCargosWorkflowGlobalDateParams,
  GetSelectedCargosTaskAndDocumentResponseV2DTO,
  GetSelectedV2CargosWorkflowTasksAndDocumentsParams,
  GetWorkflowAssociatedCompaniesResponseDTO,
  GetWorkflowTasksByWorkflowIdResponseDTO,
  GetWorkflowTasksByWorkflowIdParams,
  GetWorkflowTasksForTemplateUseResponseDto,
  GetWorkflowTaskForTemplateUseParams,
  GetWorkflowsMilestoneStatDTO,
  GetSelectedWorkflowsTaskStatParams,
  AnaBookedCargoDTO,
  GetFilteredCargosParams,
  GetSelectedCargosTaskAndDocumentResponseDTO,
  GetSelectedCargosWorkflowTasksAndDocumentsParams,
  WorkflowCategoriesWithTaskNamesDto,
  GetClosestCargoWorkflowResponseDTO,
  GetBookedCargosWithStatsInfoResponseDTO,
  BookedCargoDTO,
  GetBookedCargosParams,
  WorkflowDTO,
  CreateExternalCargoDTO,
  ArchivedCargoDTO,
  GetArchivedCargosParams,
  GetCargoWorkflowParams,
  GetCargoWorkflowsParams,
  UpdateWorkflowContractDataDTO,
  CargoInvitationDTO,
  StepperAction,
  UpdateVesselDetailsDTO,
  UpdateExternalRefIdDTO,
  GetPortCallIdResponseDto,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const createTask = (
  workflowId: string,
  createTaskRequestDTO: CreateTaskRequestDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CreateTaskResponseDTO>> => {
  return axios.post(`/api/v2/workflow/${workflowId}/task`, createTaskRequestDTO, options);
};

export type CreateTaskMutationResult = NonNullable<Awaited<ReturnType<typeof createTask>>>;
export type CreateTaskMutationBody = CreateTaskRequestDTO;
export type CreateTaskMutationError = AxiosError<void>;

export const useCreateTask = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTask>>,
    TError,
    { workflowId: string; data: CreateTaskRequestDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createTask>>,
    { workflowId: string; data: CreateTaskRequestDTO }
  > = (props) => {
    const { workflowId, data } = props ?? {};

    return createTask(workflowId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof createTask>>,
    TError,
    { workflowId: string; data: CreateTaskRequestDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const deleteTaskFile = (
  taskId: string,
  fileId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/v2/workflow/task/${taskId}/file/${fileId}`, options);
};

export type DeleteTaskFileMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTaskFile>>>;

export type DeleteTaskFileMutationError = AxiosError<unknown>;

export const useDeleteTaskFile = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTaskFile>>,
    TError,
    { taskId: string; fileId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTaskFile>>, { taskId: string; fileId: string }> = (
    props
  ) => {
    const { taskId, fileId } = props ?? {};

    return deleteTaskFile(taskId, fileId, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof deleteTaskFile>>, TError, { taskId: string; fileId: string }, TContext>(
    mutationFn,
    mutationOptions
  );
};
export const getV2BookedAndArchivedCargos = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetWorkflowsResponseDTOV2[]>> => {
  return axios.get(`/api/v2/workflow`, options);
};

export const getGetV2BookedAndArchivedCargosQueryKey = () => [`/api/v2/workflow`];

export type GetV2BookedAndArchivedCargosQueryResult = NonNullable<
  Awaited<ReturnType<typeof getV2BookedAndArchivedCargos>>
>;
export type GetV2BookedAndArchivedCargosQueryError = AxiosError<void>;

export const useGetV2BookedAndArchivedCargos = <
  TData = Awaited<ReturnType<typeof getV2BookedAndArchivedCargos>>,
  TError = AxiosError<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getV2BookedAndArchivedCargos>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetV2BookedAndArchivedCargosQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getV2BookedAndArchivedCargos>>> = ({ signal }) =>
    getV2BookedAndArchivedCargos({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getV2BookedAndArchivedCargos>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const shareTaskFile = (
  workflowId: string,
  taskId: string,
  documentId: string,
  shareTaskFileRequestDto: ShareTaskFileRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `/api/v2/workflow/${workflowId}/task/${taskId}/share/${documentId}`,
    shareTaskFileRequestDto,
    options
  );
};

export type ShareTaskFileMutationResult = NonNullable<Awaited<ReturnType<typeof shareTaskFile>>>;
export type ShareTaskFileMutationBody = ShareTaskFileRequestDto;
export type ShareTaskFileMutationError = AxiosError<unknown>;

export const useShareTaskFile = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shareTaskFile>>,
    TError,
    { workflowId: string; taskId: string; documentId: string; data: ShareTaskFileRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shareTaskFile>>,
    { workflowId: string; taskId: string; documentId: string; data: ShareTaskFileRequestDto }
  > = (props) => {
    const { workflowId, taskId, documentId, data } = props ?? {};

    return shareTaskFile(workflowId, taskId, documentId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof shareTaskFile>>,
    TError,
    { workflowId: string; taskId: string; documentId: string; data: ShareTaskFileRequestDto },
    TContext
  >(mutationFn, mutationOptions);
};
export const uploadTaskDocument = (
  workflowId: string,
  taskId: string,
  uploadTaskFileRequestDto: UploadTaskFileRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  const formData = new FormData();
  formData.append('id', uploadTaskFileRequestDto.id);
  uploadTaskFileRequestDto.documentSharedWith.forEach((value) => formData.append('documentSharedWith', value));
  formData.append('uploadedDate', uploadTaskFileRequestDto.uploadedDate);
  formData.append('documentName', uploadTaskFileRequestDto.documentName);
  formData.append('file', uploadTaskFileRequestDto.file);

  return axios.post(`/api/v2/workflow/${workflowId}/task/${taskId}`, formData, options);
};

export type UploadTaskDocumentMutationResult = NonNullable<Awaited<ReturnType<typeof uploadTaskDocument>>>;
export type UploadTaskDocumentMutationBody = UploadTaskFileRequestDto;
export type UploadTaskDocumentMutationError = AxiosError<unknown>;

export const useUploadTaskDocument = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadTaskDocument>>,
    TError,
    { workflowId: string; taskId: string; data: UploadTaskFileRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadTaskDocument>>,
    { workflowId: string; taskId: string; data: UploadTaskFileRequestDto }
  > = (props) => {
    const { workflowId, taskId, data } = props ?? {};

    return uploadTaskDocument(workflowId, taskId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof uploadTaskDocument>>,
    TError,
    { workflowId: string; taskId: string; data: UploadTaskFileRequestDto },
    TContext
  >(mutationFn, mutationOptions);
};
export const getWorkflowTask = (
  workflowId: string,
  taskId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetWorkflowTaskResponseDto>> => {
  return axios.get(`/api/v2/workflow/${workflowId}/task/${taskId}`, options);
};

export const getGetWorkflowTaskQueryKey = (workflowId: string, taskId: string) => [
  `/api/v2/workflow/${workflowId}/task/${taskId}`,
];

export type GetWorkflowTaskQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowTask>>>;
export type GetWorkflowTaskQueryError = AxiosError<void>;

export const useGetWorkflowTask = <TData = Awaited<ReturnType<typeof getWorkflowTask>>, TError = AxiosError<void>>(
  workflowId: string,
  taskId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTask>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWorkflowTaskQueryKey(workflowId, taskId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowTask>>> = ({ signal }) =>
    getWorkflowTask(workflowId, taskId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getWorkflowTask>>, TError, TData>(queryKey, queryFn, {
    enabled: !!(workflowId && taskId),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const updateTask = (
  workflowId: string,
  taskId: string,
  updateTaskRequestDTO: UpdateTaskRequestDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/api/v2/workflow/${workflowId}/task/${taskId}`, updateTaskRequestDTO, options);
};

export type UpdateTaskMutationResult = NonNullable<Awaited<ReturnType<typeof updateTask>>>;
export type UpdateTaskMutationBody = UpdateTaskRequestDTO;
export type UpdateTaskMutationError = AxiosError<unknown>;

export const useUpdateTask = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTask>>,
    TError,
    { workflowId: string; taskId: string; data: UpdateTaskRequestDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTask>>,
    { workflowId: string; taskId: string; data: UpdateTaskRequestDTO }
  > = (props) => {
    const { workflowId, taskId, data } = props ?? {};

    return updateTask(workflowId, taskId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateTask>>,
    TError,
    { workflowId: string; taskId: string; data: UpdateTaskRequestDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const getSelectedCargosWorkflowGlobalDate = (
  params: GetSelectedCargosWorkflowGlobalDateParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetSelectedCargoesGlobalDateResponseDTO[]>> => {
  return axios.get(`/api/v2/workflow/global-date`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetSelectedCargosWorkflowGlobalDateQueryKey = (params: GetSelectedCargosWorkflowGlobalDateParams) => [
  `/api/v2/workflow/global-date`,
  ...(params ? [params] : []),
];

export type GetSelectedCargosWorkflowGlobalDateQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSelectedCargosWorkflowGlobalDate>>
>;
export type GetSelectedCargosWorkflowGlobalDateQueryError = AxiosError<void>;

export const useGetSelectedCargosWorkflowGlobalDate = <
  TData = Awaited<ReturnType<typeof getSelectedCargosWorkflowGlobalDate>>,
  TError = AxiosError<void>
>(
  params: GetSelectedCargosWorkflowGlobalDateParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSelectedCargosWorkflowGlobalDate>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSelectedCargosWorkflowGlobalDateQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSelectedCargosWorkflowGlobalDate>>> = ({ signal }) =>
    getSelectedCargosWorkflowGlobalDate(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getSelectedCargosWorkflowGlobalDate>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getSelectedV2CargosWorkflowTasksAndDocuments = (
  params: GetSelectedV2CargosWorkflowTasksAndDocumentsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetSelectedCargosTaskAndDocumentResponseV2DTO[]>> => {
  return axios.get(`/api/v2/workflow/tasks-documents`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetSelectedV2CargosWorkflowTasksAndDocumentsQueryKey = (
  params: GetSelectedV2CargosWorkflowTasksAndDocumentsParams
) => [`/api/v2/workflow/tasks-documents`, ...(params ? [params] : [])];

export type GetSelectedV2CargosWorkflowTasksAndDocumentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSelectedV2CargosWorkflowTasksAndDocuments>>
>;
export type GetSelectedV2CargosWorkflowTasksAndDocumentsQueryError = AxiosError<void>;

export const useGetSelectedV2CargosWorkflowTasksAndDocuments = <
  TData = Awaited<ReturnType<typeof getSelectedV2CargosWorkflowTasksAndDocuments>>,
  TError = AxiosError<void>
>(
  params: GetSelectedV2CargosWorkflowTasksAndDocumentsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSelectedV2CargosWorkflowTasksAndDocuments>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSelectedV2CargosWorkflowTasksAndDocumentsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSelectedV2CargosWorkflowTasksAndDocuments>>> = ({
    signal,
  }) => getSelectedV2CargosWorkflowTasksAndDocuments(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getSelectedV2CargosWorkflowTasksAndDocuments>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getWorkflowAssociatedCompanies = (
  workflowId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetWorkflowAssociatedCompaniesResponseDTO>> => {
  return axios.get(`/api/v2/workflow/${workflowId}/workflow-associated-companies`, options);
};

export const getGetWorkflowAssociatedCompaniesQueryKey = (workflowId: string) => [
  `/api/v2/workflow/${workflowId}/workflow-associated-companies`,
];

export type GetWorkflowAssociatedCompaniesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getWorkflowAssociatedCompanies>>
>;
export type GetWorkflowAssociatedCompaniesQueryError = AxiosError<void>;

export const useGetWorkflowAssociatedCompanies = <
  TData = Awaited<ReturnType<typeof getWorkflowAssociatedCompanies>>,
  TError = AxiosError<void>
>(
  workflowId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getWorkflowAssociatedCompanies>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWorkflowAssociatedCompaniesQueryKey(workflowId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowAssociatedCompanies>>> = ({ signal }) =>
    getWorkflowAssociatedCompanies(workflowId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getWorkflowAssociatedCompanies>>, TError, TData>(queryKey, queryFn, {
    enabled: !!workflowId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getWorkflowTasksByWorkflowId = (
  workflowId: string,
  params?: GetWorkflowTasksByWorkflowIdParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetWorkflowTasksByWorkflowIdResponseDTO>> => {
  return axios.get(`/api/v1/workflow/${workflowId}/tasks-ana`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetWorkflowTasksByWorkflowIdQueryKey = (
  workflowId: string,
  params?: GetWorkflowTasksByWorkflowIdParams
) => [`/api/v1/workflow/${workflowId}/tasks-ana`, ...(params ? [params] : [])];

export type GetWorkflowTasksByWorkflowIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getWorkflowTasksByWorkflowId>>
>;
export type GetWorkflowTasksByWorkflowIdQueryError = AxiosError<void>;

export const useGetWorkflowTasksByWorkflowId = <
  TData = Awaited<ReturnType<typeof getWorkflowTasksByWorkflowId>>,
  TError = AxiosError<void>
>(
  workflowId: string,
  params?: GetWorkflowTasksByWorkflowIdParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTasksByWorkflowId>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWorkflowTasksByWorkflowIdQueryKey(workflowId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowTasksByWorkflowId>>> = ({ signal }) =>
    getWorkflowTasksByWorkflowId(workflowId, params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getWorkflowTasksByWorkflowId>>, TError, TData>(queryKey, queryFn, {
    enabled: !!workflowId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getWorkflowTaskForTemplateUse = (
  workflowId: string,
  params?: GetWorkflowTaskForTemplateUseParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<unknown>> => {
  return axios.get(`/api/v2/workflow/${workflowId}/tasks-for-template`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetWorkflowTaskForTemplateUseQueryKey = (
  workflowId: string,
  params?: GetWorkflowTaskForTemplateUseParams
) => [`/api/v2/workflow/${workflowId}/tasks-for-template`, ...(params ? [params] : [])];

export type GetWorkflowTaskForTemplateUseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getWorkflowTaskForTemplateUse>>
>;
export type GetWorkflowTaskForTemplateUseQueryError = AxiosError<void | GetWorkflowTasksForTemplateUseResponseDto[]>;

export const useGetWorkflowTaskForTemplateUse = <
  TData = Awaited<ReturnType<typeof getWorkflowTaskForTemplateUse>>,
  TError = AxiosError<void | GetWorkflowTasksForTemplateUseResponseDto[]>
>(
  workflowId: string,
  params?: GetWorkflowTaskForTemplateUseParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTaskForTemplateUse>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWorkflowTaskForTemplateUseQueryKey(workflowId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowTaskForTemplateUse>>> = ({ signal }) =>
    getWorkflowTaskForTemplateUse(workflowId, params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getWorkflowTaskForTemplateUse>>, TError, TData>(queryKey, queryFn, {
    enabled: !!workflowId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getSelectedWorkflowsTaskStat = (
  params: GetSelectedWorkflowsTaskStatParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetWorkflowsMilestoneStatDTO[]>> => {
  return axios.get(`/api/v2/workflow/tasks/stats`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetSelectedWorkflowsTaskStatQueryKey = (params: GetSelectedWorkflowsTaskStatParams) => [
  `/api/v2/workflow/tasks/stats`,
  ...(params ? [params] : []),
];

export type GetSelectedWorkflowsTaskStatQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSelectedWorkflowsTaskStat>>
>;
export type GetSelectedWorkflowsTaskStatQueryError = AxiosError<void>;

export const useGetSelectedWorkflowsTaskStat = <
  TData = Awaited<ReturnType<typeof getSelectedWorkflowsTaskStat>>,
  TError = AxiosError<void>
>(
  params: GetSelectedWorkflowsTaskStatParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSelectedWorkflowsTaskStat>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSelectedWorkflowsTaskStatQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSelectedWorkflowsTaskStat>>> = ({ signal }) =>
    getSelectedWorkflowsTaskStat(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getSelectedWorkflowsTaskStat>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getFilteredCargos = (
  params?: GetFilteredCargosParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AnaBookedCargoDTO[]>> => {
  return axios.get(`/api/v2/ana/workflow`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetFilteredCargosQueryKey = (params?: GetFilteredCargosParams) => [
  `/api/v2/ana/workflow`,
  ...(params ? [params] : []),
];

export type GetFilteredCargosQueryResult = NonNullable<Awaited<ReturnType<typeof getFilteredCargos>>>;
export type GetFilteredCargosQueryError = AxiosError<void>;

export const useGetFilteredCargos = <TData = Awaited<ReturnType<typeof getFilteredCargos>>, TError = AxiosError<void>>(
  params?: GetFilteredCargosParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getFilteredCargos>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFilteredCargosQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFilteredCargos>>> = ({ signal }) =>
    getFilteredCargos(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getFilteredCargos>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getSelectedCargosWorkflowTasksAndDocuments = (
  params: GetSelectedCargosWorkflowTasksAndDocumentsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetSelectedCargosTaskAndDocumentResponseDTO>> => {
  return axios.get(`/api/v2/workflow/tasks-and-documents`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetSelectedCargosWorkflowTasksAndDocumentsQueryKey = (
  params: GetSelectedCargosWorkflowTasksAndDocumentsParams
) => [`/api/v2/workflow/tasks-and-documents`, ...(params ? [params] : [])];

export type GetSelectedCargosWorkflowTasksAndDocumentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSelectedCargosWorkflowTasksAndDocuments>>
>;
export type GetSelectedCargosWorkflowTasksAndDocumentsQueryError = AxiosError<void>;

export const useGetSelectedCargosWorkflowTasksAndDocuments = <
  TData = Awaited<ReturnType<typeof getSelectedCargosWorkflowTasksAndDocuments>>,
  TError = AxiosError<void>
>(
  params: GetSelectedCargosWorkflowTasksAndDocumentsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSelectedCargosWorkflowTasksAndDocuments>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSelectedCargosWorkflowTasksAndDocumentsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSelectedCargosWorkflowTasksAndDocuments>>> = ({ signal }) =>
    getSelectedCargosWorkflowTasksAndDocuments(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getSelectedCargosWorkflowTasksAndDocuments>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getWorkflowTaskNames = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<WorkflowCategoriesWithTaskNamesDto[]>> => {
  return axios.get(`/api/v2/workflow/workflow-task-names`, options);
};

export const getGetWorkflowTaskNamesQueryKey = () => [`/api/v2/workflow/workflow-task-names`];

export type GetWorkflowTaskNamesQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowTaskNames>>>;
export type GetWorkflowTaskNamesQueryError = AxiosError<void>;

export const useGetWorkflowTaskNames = <
  TData = Awaited<ReturnType<typeof getWorkflowTaskNames>>,
  TError = AxiosError<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTaskNames>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWorkflowTaskNamesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowTaskNames>>> = ({ signal }) =>
    getWorkflowTaskNames({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getWorkflowTaskNames>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getClosestCargoWorkflowService = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetClosestCargoWorkflowResponseDTO>> => {
  return axios.get(`/api/v2/workflow/get-closest-cargo-workflow`, options);
};

export const getGetClosestCargoWorkflowServiceQueryKey = () => [`/api/v2/workflow/get-closest-cargo-workflow`];

export type GetClosestCargoWorkflowServiceQueryResult = NonNullable<
  Awaited<ReturnType<typeof getClosestCargoWorkflowService>>
>;
export type GetClosestCargoWorkflowServiceQueryError = AxiosError<void>;

export const useGetClosestCargoWorkflowService = <
  TData = Awaited<ReturnType<typeof getClosestCargoWorkflowService>>,
  TError = AxiosError<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getClosestCargoWorkflowService>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetClosestCargoWorkflowServiceQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClosestCargoWorkflowService>>> = ({ signal }) =>
    getClosestCargoWorkflowService({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getClosestCargoWorkflowService>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getBookedCargosWithStatsInfo = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetBookedCargosWithStatsInfoResponseDTO[]>> => {
  return axios.get(`/api/v2/workflow/get-booked-cargos-with-stats-info`, options);
};

export const getGetBookedCargosWithStatsInfoQueryKey = () => [`/api/v2/workflow/get-booked-cargos-with-stats-info`];

export type GetBookedCargosWithStatsInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBookedCargosWithStatsInfo>>
>;
export type GetBookedCargosWithStatsInfoQueryError = AxiosError<void>;

export const useGetBookedCargosWithStatsInfo = <
  TData = Awaited<ReturnType<typeof getBookedCargosWithStatsInfo>>,
  TError = AxiosError<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getBookedCargosWithStatsInfo>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBookedCargosWithStatsInfoQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBookedCargosWithStatsInfo>>> = ({ signal }) =>
    getBookedCargosWithStatsInfo({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getBookedCargosWithStatsInfo>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getBookedCargos = (
  params?: GetBookedCargosParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<BookedCargoDTO[]>> => {
  return axios.get(`/api/v1/workflow`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetBookedCargosQueryKey = (params?: GetBookedCargosParams) => [
  `/api/v1/workflow`,
  ...(params ? [params] : []),
];

export type GetBookedCargosQueryResult = NonNullable<Awaited<ReturnType<typeof getBookedCargos>>>;
export type GetBookedCargosQueryError = AxiosError<void>;

export const useGetBookedCargos = <TData = Awaited<ReturnType<typeof getBookedCargos>>, TError = AxiosError<void>>(
  params?: GetBookedCargosParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getBookedCargos>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBookedCargosQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBookedCargos>>> = ({ signal }) =>
    getBookedCargos(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getBookedCargos>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const createExternalCargo = (
  createExternalCargoDTO: CreateExternalCargoDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<WorkflowDTO>> => {
  return axios.post(`/api/v1/workflow`, createExternalCargoDTO, options);
};

export type CreateExternalCargoMutationResult = NonNullable<Awaited<ReturnType<typeof createExternalCargo>>>;
export type CreateExternalCargoMutationBody = CreateExternalCargoDTO;
export type CreateExternalCargoMutationError = AxiosError<void>;

export const useCreateExternalCargo = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createExternalCargo>>,
    TError,
    { data: CreateExternalCargoDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createExternalCargo>>,
    { data: CreateExternalCargoDTO }
  > = (props) => {
    const { data } = props ?? {};

    return createExternalCargo(data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof createExternalCargo>>,
    TError,
    { data: CreateExternalCargoDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const getArchivedCargos = (
  params: GetArchivedCargosParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ArchivedCargoDTO[]>> => {
  return axios.get(`/api/v1/workflow/archived`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetArchivedCargosQueryKey = (params: GetArchivedCargosParams) => [
  `/api/v1/workflow/archived`,
  ...(params ? [params] : []),
];

export type GetArchivedCargosQueryResult = NonNullable<Awaited<ReturnType<typeof getArchivedCargos>>>;
export type GetArchivedCargosQueryError = AxiosError<void>;

export const useGetArchivedCargos = <TData = Awaited<ReturnType<typeof getArchivedCargos>>, TError = AxiosError<void>>(
  params: GetArchivedCargosParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getArchivedCargos>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetArchivedCargosQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getArchivedCargos>>> = ({ signal }) =>
    getArchivedCargos(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getArchivedCargos>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getCargoWorkflow = (
  workflowId: string,
  params?: GetCargoWorkflowParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<WorkflowDTO>> => {
  return axios.get(`/api/v1/workflow/${workflowId}`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetCargoWorkflowQueryKey = (workflowId: string, params?: GetCargoWorkflowParams) => [
  `/api/v1/workflow/${workflowId}`,
  ...(params ? [params] : []),
];

export type GetCargoWorkflowQueryResult = NonNullable<Awaited<ReturnType<typeof getCargoWorkflow>>>;
export type GetCargoWorkflowQueryError = AxiosError<void>;

export const useGetCargoWorkflow = <TData = Awaited<ReturnType<typeof getCargoWorkflow>>, TError = AxiosError<void>>(
  workflowId: string,
  params?: GetCargoWorkflowParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCargoWorkflow>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCargoWorkflowQueryKey(workflowId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCargoWorkflow>>> = ({ signal }) =>
    getCargoWorkflow(workflowId, params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCargoWorkflow>>, TError, TData>(queryKey, queryFn, {
    enabled: !!workflowId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getCargoWorkflows = (
  workflowIds: string,
  params?: GetCargoWorkflowsParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<WorkflowDTO[]>> => {
  return axios.get(`/api/v1/workflow/multiple/${workflowIds}`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetCargoWorkflowsQueryKey = (workflowIds: string, params?: GetCargoWorkflowsParams) => [
  `/api/v1/workflow/multiple/${workflowIds}`,
  ...(params ? [params] : []),
];

export type GetCargoWorkflowsQueryResult = NonNullable<Awaited<ReturnType<typeof getCargoWorkflows>>>;
export type GetCargoWorkflowsQueryError = AxiosError<void>;

export const useGetCargoWorkflows = <TData = Awaited<ReturnType<typeof getCargoWorkflows>>, TError = AxiosError<void>>(
  workflowIds: string,
  params?: GetCargoWorkflowsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCargoWorkflows>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCargoWorkflowsQueryKey(workflowIds, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCargoWorkflows>>> = ({ signal }) =>
    getCargoWorkflows(workflowIds, params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCargoWorkflows>>, TError, TData>(queryKey, queryFn, {
    enabled: !!workflowIds,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const updateContractData = (
  workflowId: string,
  updateWorkflowContractDataDTO: UpdateWorkflowContractDataDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/api/v1/workflow/${workflowId}/contract-data`, updateWorkflowContractDataDTO, options);
};

export type UpdateContractDataMutationResult = NonNullable<Awaited<ReturnType<typeof updateContractData>>>;
export type UpdateContractDataMutationBody = UpdateWorkflowContractDataDTO;
export type UpdateContractDataMutationError = AxiosError<unknown>;

export const useUpdateContractData = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateContractData>>,
    TError,
    { workflowId: string; data: UpdateWorkflowContractDataDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateContractData>>,
    { workflowId: string; data: UpdateWorkflowContractDataDTO }
  > = (props) => {
    const { workflowId, data } = props ?? {};

    return updateContractData(workflowId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateContractData>>,
    TError,
    { workflowId: string; data: UpdateWorkflowContractDataDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const archiveCargoWorkflow = (
  workflowId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/api/v1/workflow/archive/${workflowId}`, undefined, options);
};

export type ArchiveCargoWorkflowMutationResult = NonNullable<Awaited<ReturnType<typeof archiveCargoWorkflow>>>;

export type ArchiveCargoWorkflowMutationError = AxiosError<unknown>;

export const useArchiveCargoWorkflow = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof archiveCargoWorkflow>>,
    TError,
    { workflowId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof archiveCargoWorkflow>>, { workflowId: string }> = (
    props
  ) => {
    const { workflowId } = props ?? {};

    return archiveCargoWorkflow(workflowId, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof archiveCargoWorkflow>>, TError, { workflowId: string }, TContext>(
    mutationFn,
    mutationOptions
  );
};
export const unarchiveCargoWorkflow = (
  workflowId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/api/v1/workflow/unarchive/${workflowId}`, undefined, options);
};

export type UnarchiveCargoWorkflowMutationResult = NonNullable<Awaited<ReturnType<typeof unarchiveCargoWorkflow>>>;

export type UnarchiveCargoWorkflowMutationError = AxiosError<unknown>;

export const useUnarchiveCargoWorkflow = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof unarchiveCargoWorkflow>>,
    TError,
    { workflowId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof unarchiveCargoWorkflow>>, { workflowId: string }> = (
    props
  ) => {
    const { workflowId } = props ?? {};

    return unarchiveCargoWorkflow(workflowId, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof unarchiveCargoWorkflow>>, TError, { workflowId: string }, TContext>(
    mutationFn,
    mutationOptions
  );
};
export const getCompanyToCargoWorkflowInvitation = (
  workflowId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CargoInvitationDTO>> => {
  return axios.get(`/api/v1/workflow/${workflowId}/invite`, options);
};

export const getGetCompanyToCargoWorkflowInvitationQueryKey = (workflowId: string) => [
  `/api/v1/workflow/${workflowId}/invite`,
];

export type GetCompanyToCargoWorkflowInvitationQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompanyToCargoWorkflowInvitation>>
>;
export type GetCompanyToCargoWorkflowInvitationQueryError = AxiosError<void>;

export const useGetCompanyToCargoWorkflowInvitation = <
  TData = Awaited<ReturnType<typeof getCompanyToCargoWorkflowInvitation>>,
  TError = AxiosError<void>
>(
  workflowId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCompanyToCargoWorkflowInvitation>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCompanyToCargoWorkflowInvitationQueryKey(workflowId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyToCargoWorkflowInvitation>>> = ({ signal }) =>
    getCompanyToCargoWorkflowInvitation(workflowId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCompanyToCargoWorkflowInvitation>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!workflowId, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const addCompanyToCargoWorkflow = (
  workflowId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/v1/workflow/${workflowId}/invite`, undefined, options);
};

export type AddCompanyToCargoWorkflowMutationResult = NonNullable<
  Awaited<ReturnType<typeof addCompanyToCargoWorkflow>>
>;

export type AddCompanyToCargoWorkflowMutationError = AxiosError<unknown>;

export const useAddCompanyToCargoWorkflow = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addCompanyToCargoWorkflow>>,
    TError,
    { workflowId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof addCompanyToCargoWorkflow>>, { workflowId: string }> = (
    props
  ) => {
    const { workflowId } = props ?? {};

    return addCompanyToCargoWorkflow(workflowId, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof addCompanyToCargoWorkflow>>, TError, { workflowId: string }, TContext>(
    mutationFn,
    mutationOptions
  );
};
export const updateWorkflowStatus = (
  workflowId: string,
  stepperAction: StepperAction,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/api/v1/workflow/${workflowId}/status/${stepperAction}`, undefined, options);
};

export type UpdateWorkflowStatusMutationResult = NonNullable<Awaited<ReturnType<typeof updateWorkflowStatus>>>;

export type UpdateWorkflowStatusMutationError = AxiosError<unknown>;

export const useUpdateWorkflowStatus = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateWorkflowStatus>>,
    TError,
    { workflowId: string; stepperAction: StepperAction },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateWorkflowStatus>>,
    { workflowId: string; stepperAction: StepperAction }
  > = (props) => {
    const { workflowId, stepperAction } = props ?? {};

    return updateWorkflowStatus(workflowId, stepperAction, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateWorkflowStatus>>,
    TError,
    { workflowId: string; stepperAction: StepperAction },
    TContext
  >(mutationFn, mutationOptions);
};
export const updateVesselDetails = (
  workflowId: string,
  updateVesselDetailsDTO: UpdateVesselDetailsDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/api/v1/workflow/${workflowId}/vessel-details`, updateVesselDetailsDTO, options);
};

export type UpdateVesselDetailsMutationResult = NonNullable<Awaited<ReturnType<typeof updateVesselDetails>>>;
export type UpdateVesselDetailsMutationBody = UpdateVesselDetailsDTO;
export type UpdateVesselDetailsMutationError = AxiosError<unknown>;

export const useUpdateVesselDetails = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateVesselDetails>>,
    TError,
    { workflowId: string; data: UpdateVesselDetailsDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateVesselDetails>>,
    { workflowId: string; data: UpdateVesselDetailsDTO }
  > = (props) => {
    const { workflowId, data } = props ?? {};

    return updateVesselDetails(workflowId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateVesselDetails>>,
    TError,
    { workflowId: string; data: UpdateVesselDetailsDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const updateExternalRefId = (
  workflowId: string,
  updateExternalRefIdDTO: UpdateExternalRefIdDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<BookedCargoDTO>> => {
  return axios.patch(`/api/v1/workflow/${workflowId}/external-ref-id`, updateExternalRefIdDTO, options);
};

export type UpdateExternalRefIdMutationResult = NonNullable<Awaited<ReturnType<typeof updateExternalRefId>>>;
export type UpdateExternalRefIdMutationBody = UpdateExternalRefIdDTO;
export type UpdateExternalRefIdMutationError = AxiosError<void>;

export const useUpdateExternalRefId = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateExternalRefId>>,
    TError,
    { workflowId: string; data: UpdateExternalRefIdDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateExternalRefId>>,
    { workflowId: string; data: UpdateExternalRefIdDTO }
  > = (props) => {
    const { workflowId, data } = props ?? {};

    return updateExternalRefId(workflowId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateExternalRefId>>,
    TError,
    { workflowId: string; data: UpdateExternalRefIdDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const updateWorkflowCSMUnlock = (
  workflowId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/api/v1/workflow/${workflowId}/csm-unlock`, undefined, options);
};

export type UpdateWorkflowCSMUnlockMutationResult = NonNullable<Awaited<ReturnType<typeof updateWorkflowCSMUnlock>>>;

export type UpdateWorkflowCSMUnlockMutationError = AxiosError<unknown>;

export const useUpdateWorkflowCSMUnlock = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateWorkflowCSMUnlock>>,
    TError,
    { workflowId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateWorkflowCSMUnlock>>, { workflowId: string }> = (
    props
  ) => {
    const { workflowId } = props ?? {};

    return updateWorkflowCSMUnlock(workflowId, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof updateWorkflowCSMUnlock>>, TError, { workflowId: string }, TContext>(
    mutationFn,
    mutationOptions
  );
};
export const getWorkFLowPortCallId = (
  workflowId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<GetPortCallIdResponseDto>> => {
  return axios.get(`/api/v2/workflow/${workflowId}/port-calls`, options);
};

export const getGetWorkFLowPortCallIdQueryKey = (workflowId: string) => [`/api/v2/workflow/${workflowId}/port-calls`];

export type GetWorkFLowPortCallIdQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkFLowPortCallId>>>;
export type GetWorkFLowPortCallIdQueryError = AxiosError<void>;

export const useGetWorkFLowPortCallId = <
  TData = Awaited<ReturnType<typeof getWorkFLowPortCallId>>,
  TError = AxiosError<void>
>(
  workflowId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getWorkFLowPortCallId>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWorkFLowPortCallIdQueryKey(workflowId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkFLowPortCallId>>> = ({ signal }) =>
    getWorkFLowPortCallId(workflowId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getWorkFLowPortCallId>>, TError, TData>(queryKey, queryFn, {
    enabled: !!workflowId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

import { Stack } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { PrepaymentDTO } from 'api/generated/model';

import { Text } from '@/components';
import { decimalToPercentage } from '@/views/tenders/utils/percentage';

interface PrepaymentProps {
  prepayment: PrepaymentDTO;
}

export const PrepaymentLabel = ({ prepayment }: PrepaymentProps) => {
  const { t } = useTranslation('tenderContract');

  return (
    <Stack spacing={0}>
      <Text weight="bold">{t(`labels.prepayment`)}</Text>
      <PrepaymentText prepayment={prepayment} />
    </Stack>
  );
};

export const PrepaymentText = ({ prepayment }: PrepaymentProps) => {
  const { t } = useTranslation('tenderContract');

  return (
    <Text>
      {t(`valuesFormatted.prepaymentConditions`, {
        days: prepayment.days,
        event: t(`valuesFormatted.${prepayment.event}`),
        percentage: decimalToPercentage(prepayment.percentage),
      })}
    </Text>
  );
};

import { Row } from '@tanstack/react-table';
import {
  ArchivedCargoDTO,
  BookedCargoDTO,
  CargoNeedNegotiationDTO,
  CargoOfferNegotiationDTO,
  CargoStatus,
} from 'api/generated/model';

export interface RowData {
  id: string;
  description: string;
  status: CargoStatus;
  negotationsCount: number;
}

export type MyCargoRowData = CargoOfferNegotiationDTO | CargoNeedNegotiationDTO;

export interface NegotiationsRowData {
  id: string;
  companyName: string;
  relatedCargoId: string;
  cargoDescription: string;
  negotiation: string;
  expires: string;
}

export type OfferType = 'request' | 'offer';

export enum NegotiationActions {
  SEE_HISTORY,
  CANCEL_NEGOTIATION,
  REJECT_NEGOTIATION,
  REQUEST_EXTENSION,
  EXTEND,
  CHAT,
  SEE_DETAILS,
  CSM_ENABLE_PRIVATE_DATA,
}

export enum MyCargoTab {
  Purchases = 'purchases',
  Sales = 'sales',
  Canceled = 'canceled',
}

export enum TypeCargo {
  Prospect = 'prospect',
  Booked = 'booked',
}

export enum NegotiationDetailsTab {
  HISTORY = 'history',
  CHAT = 'chat',
}

export enum TypeCargoTab {
  PROSPECT = 'prospect',
  BOOKED = 'booked',
  ARCHIVED = 'archived',
  CANCELLED = 'canceled',
}

export type BookedCargoTableActionFunction = (row: Row<BookedCargoDTO>) => void;
export type ArchivedCargoTableActionFunction = (row: Row<ArchivedCargoDTO>) => void;

export const isMyCargoPurchaseTab = (myCargoTab: MyCargoTab) => {
  return myCargoTab === MyCargoTab.Purchases;
};

import { DrawerProps } from '@mantine/core';

import { ThemeComponent } from './types';

export const Drawer: ThemeComponent<DrawerProps> = {
  defaultProps: {
    size: 800,
  },
  styles: {
    drawer: {
      overflowY: 'auto',
    },
  },
};

import {
  ColumnDef,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
  flexRender,
} from '@tanstack/react-table';
import { useState } from 'react';
import type { PolymorphicComponentProps } from '@mantine/utils';
import { createStyles } from '@mantine/core';

import { BaseTable, BaseTableCard } from './BaseTable';

const useStyles = createStyles((theme) => ({
  hiddenOnMobile: {
    [theme.fn.smallerThan('md')]: {
      pointerEvents: 'none',
      display: 'none',
    },
  },
  expandedRow: {
    backgroundColor: theme.colors.blue[0],
    borderRadius: theme.radius.md,

    '& td': {
      borderColor: `${theme.colors.gray[2]} !important`,
    },
  },
  expandedCell: {
    backgroundColor: theme.colors.gray[1],
  },
}));

export interface TableWithSubRowsProps<TData, TValue> {
  data: TData[];
  columns: ColumnDef<TData, TValue>[];
  isLoading: boolean;
  onRowClick?: (row: Row<TData>) => void;
}

export function TableWithSubRows<TData extends { id: string; subRows?: TSubRow[] }, TValue, TSubRow>(
  props: TableWithSubRowsProps<TData, TValue>
) {
  const { data, columns, isLoading, onRowClick } = props;

  const [sorting, setSorting] = useState<SortingState>([]);
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
      sorting,
    },
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getSubRows: (row) => row.subRows as any,
  });

  return (
    <BaseTable
      table={table}
      isLoading={isLoading}
      renderRow={({ row }) => (
        <>
          <RenderRow row={row} onClick={() => row.depth === 0 && onRowClick && onRowClick(row)} data-hover="true" />
        </>
      )}
    />
  );
}

interface RenderRowProps<TData> extends PolymorphicComponentProps<'tr'> {
  row: Row<TData>;
}

function RenderRow<TData>(props: RenderRowProps<TData>) {
  const { row, ...rest } = props;
  const { cx, classes } = useStyles();

  return (
    <tr {...rest} className={cx({ [classes.expandedRow]: row.getIsExpanded() })} data-hover={!row.getIsExpanded()}>
      {row.getVisibleCells().map((cell) => (
        <td
          className={cx({
            [classes.hiddenOnMobile]: cell.column.columnDef.meta?.mobileHidden,
            [classes.expandedCell]: row.depth > 0,
          })}
          key={cell.id}
          data-testid={`${cell.column.columnDef.header}-${cell.id}`}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  );
}

TableWithSubRows.Card = BaseTableCard;

import { useEffect, useState } from 'react';

export const useMediaQuery = (width: number) => {
  const [isSmaller, setIsSmaller] = useState(typeof window !== 'undefined' && window.innerWidth < width);
  const onResize = () => {
    setIsSmaller(window.innerWidth < width);
  };

  useEffect(() => {
    onResize();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isSmaller;
};

export const formatterThousand = (value: number | string): string => {
  if (value === null || value === undefined || value === '') return '';

  const numberFormatter = new Intl.NumberFormat('en-US');
  const stringed = String(value).replaceAll(',', '');
  const parsedValue = Number(stringed);

  if (isNaN(parsedValue)) return '';

  return numberFormatter.format(parsedValue);
};

const unformatterThousand = (value: string): string => {
  if (!value) return '';
  return value.replaceAll(',', '');
};

export function getThousandSeparatedNumberProps() {
  return {
    formatter: (value: string | undefined) => formatterThousand(value),
    parser: (value: string | undefined) => unformatterThousand(value),
  };
}

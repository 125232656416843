import { Center, Container, createStyles, Stack } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { CompanyType } from 'api/generated/model';
import { Trans, useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { z } from 'zod';

import { getTranslationsPageProps } from '@/common/server-side-props';
import { Title, Text } from '@/components';
import { AccountVerified, RegisterForm, StepOne, StepZero, useRegistrationStepper } from '@/views/registration';

export const useStyles = createStyles((theme) => ({
  pageContainer: {
    minHeight: '100vh',
    backgroundColor: theme.colors.gray[0],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '64px 0 120px 0',
  },
  contentContainer: {
    flexGrow: 1,
  },
  text: {
    alignSelf: 'flex-end',
    color: theme.colors.gray[7],
  },
  stack: {
    width: '100%',
    gap: 28,
  },
  darkText: {
    color: theme.colors.gray[8],
  },
  lightText: {
    color: theme.colors.gray[6],
  },
  title: {
    width: 124,
    marginBottom: 4,
    marginTop: 36,
  },
}));

export const getStaticProps = getTranslationsPageProps(['common', 'zod', 'register']);

const schemaStepOne = z.object({
  address1: z.string().min(2).max(255),
  address2: z.string().max(255).optional(),
  city: z.string().min(2).max(255),
  zipCode: z.string().min(2).max(20),
  country: z.string().min(2).max(255),
  vatNumber: z.string().min(2).max(255),
  isTermsAndConditionsAccepted: z.literal(true),
});

const Register = () => {
  const formData = {
    companyName: undefined,
    firstName: undefined,
    lastName: undefined,
    contactEmail: undefined,
    contactPhoneNumber: undefined,
    contactPhoneNumberPrefix: undefined,
    companyType: CompanyType.SELL,
    address1: undefined,
    address2: undefined,
    city: undefined,
    zipCode: undefined,
    country: undefined,
    vatNumber: undefined,
    isTermsAndConditionsAccepted: false,
  };
  const { classes, cx } = useStyles();
  const { t } = useTranslation(['register', 'zod']);
  const schemaStepZero = useMemo(
    () =>
      z.object({
        companyName: z.string().min(2).max(255),
        firstName: z.string().min(2).max(255),
        lastName: z.string().min(2).max(255),
        contactEmail: z.string().email(t('zod:errors.email')),
        contactPhoneNumber: z
          .string()
          .min(7)
          .max(11)
          .regex(/^\+?\d+((-|\s)\d+)*$/, t('zod:errors.phoneNumber')),
        contactPhoneNumberPrefix: z.string().min(2),
        companyType: z.nativeEnum(CompanyType),
      }),
    [t]
  );
  const { goToStepOne, goToStepZero, submitRegisterCompany, state } = useRegistrationStepper({
    onUniqueConstraintError: (paths) =>
      paths.forEach((path) => {
        form.setFieldError(
          path,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          t(`zod:errors.UniqueConstraintViolation`, { fieldName: t(`register:labels.${path}` as any) })
        );
      }),
  });

  const form = useForm<RegisterForm>({
    validate: (values) => {
      if (state.type === 'FILL_REGISTRATION_FORM' && state.currentStep === 0)
        return zodResolver(schemaStepZero)(values);
      if (state.type === 'FILL_REGISTRATION_FORM' && state.currentStep === 1) return zodResolver(schemaStepOne)(values);
      return {};
    },
    initialValues: formData,
    transformValues: (values) => ({
      ...values,
      contactPhoneNumberPrefix: values.contactPhoneNumberPrefix.split('_')[0],
      address2: values.address2 === '' ? undefined : values.address2,
    }),
  });

  return (
    <Container fluid className={classes.pageContainer}>
      <Container size={'md'} className={classes.contentContainer}>
        <Stack className={classes.stack}>
          {state.type === 'FILL_REGISTRATION_FORM' && (
            <>
              <Text size="S" className={cx(classes.text, classes.lightText)}>
                <Trans
                  t={t}
                  i18nKey="register:stepper"
                  values={{ currentStep: state.currentStep + 1, numberOfSteps: 2 }}
                >
                  <span className={classes.darkText} />
                </Trans>
              </Text>
              <Center>
                <Title className={classes.title} order={1} size="3XL" align="center">
                  {t('register:header')}
                </Title>
              </Center>
            </>
          )}
          {state.type === 'FILL_REGISTRATION_FORM' && state.currentStep === 0 && (
            <StepZero next={goToStepOne} form={form} />
          )}
          {((state.type === 'FILL_REGISTRATION_FORM' && state.currentStep === 1) || state.type === 'SUBMITTING') && (
            <StepOne
              goBack={goToStepZero}
              submit={submitRegisterCompany}
              submitting={state.type === 'SUBMITTING'}
              form={form}
            />
          )}
          {state.type === 'REGISTER_COMPANY_SUCCESS' && (
            <AccountVerified email={form.getInputProps('contactEmail').value} />
          )}
        </Stack>
      </Container>
    </Container>
  );
};

export default Register;

import {
  Anchor,
  Box,
  Collapse,
  createStyles,
  Group,
  Navbar as MantineNavbar,
  NavbarProps as MantineNavbarProps,
  Stack,
  ThemeIcon,
  UnstyledButton,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import NextLink from 'next/link';
import { useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useRef } from 'react';
import { IconType } from 'react-icons/lib';
import {
  RiArrowRightSLine,
  RiBarChartBoxLine,
  RiDiscussLine,
  RiInstallFill,
  RiLayout2Line,
  RiLogoutBoxLine,
  RiShipLine,
  RiStockLine,
} from 'react-icons/ri';
import { FaBroadcastTower } from 'react-icons/fa';
import { FiBox } from 'react-icons/fi';
import { TbCalendarRepeat } from 'react-icons/tb';
import { signOut } from 'pages/auth/signout';
import { HideForbiddenElement } from 'components/hide-forbidden-element/HideForbiddenElement';
import {
  CompanyRole,
  UserCompanyContextDto,
  UserRole,
  UserCompanyStatus,
  AvailableFeatureFlags,
} from 'api/generated/model';
import { useGetClosestCargoWorkflowService } from 'api/generated/workflow/workflow';

import { routes } from '@/common/routes';
import { Text } from '@/components';
import { useAppInstallationPrompt } from '@/core/hooks/use-app-installation-prompt';
import { useChangeCompanyContext } from '@/core/hooks/use-change-company-context';
import { useAuthRoles, useFeature, useIsMIUser } from '@/core';
import { NON_PASSIVE_NON_PRIVATE_COMPANY_STATUS } from '@/common/utils/company-status';
import { useShouldShowMarketplace } from '@/core/hooks/use-should-show-marketplace';

import { Avatar } from '../avatar/Avatar';

const useStyles = createStyles((theme) => {
  return {
    navbar: {
      width: 0,
      overflow: 'hidden',
      backgroundColor: theme.colors.primary[2],
      borderRight: 'none',
      top: 55,
      marginRight: 1,
      height: 'auto',
      transition: 'width 200ms linear',
      zIndex: 1000,

      [theme.fn.largerThan('md')]: {
        width: 56,
      },
    },
    navbarVisible: {
      width: '100%',

      [theme.fn.largerThan('md')]: {
        width: 228,
      },
      transition: 'width 200ms linear',
    },
    header: {
      paddingBottom: 16,
      marginBottom: 24,
      borderBottom: `1px solid ${theme.fn.rgba(theme.white, 0.1)}`,
    },
    footer: {
      marginTop: 16,
      borderTop: `1px solid ${theme.fn.rgba(theme.white, 0.1)}`,
    },
    link: {
      ...theme.fn.focusStyles(),
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      fontSize: theme.fontSizes.sm,
      color: theme.white,
      padding: 8,
      fontWeight: 500,
      overflow: 'hidden',

      '&:hover': {
        backgroundColor: theme.fn.lighten(theme.colors.primary[2], 0.1),
      },
    },
    linkIcon: {
      fontSize: 20,
      color: theme.white,
    },
    linkActive: {
      '&, &:hover': {
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${theme.colors.primary[2]}`,
        borderRight: `2px solid ${theme.colors.primary[0]}`,
      },
    },
    companySwitch: {
      cursor: 'pointer',
    },
    companyCollapsable: {
      padding: '16px',
    },
    companySwitchChiron: {
      display: 'inline-block',
      marginLeft: '4px',
      transition: 'transform 200ms ease-in-out',
    },
    companySwitchChironRotate0: {
      transform: 'rotate(0deg) translateY(3px)',
    },
    companySwitchChironRotate90: {
      transform: 'rotate(-90deg) translateY(3px)',
    },
    drawer: {
      backgroundColor: theme.colors.primary[2],
      width: '100%',
    },
    closeButton: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  };
});

interface LinkItem {
  link: string;
  label: string;
  dataTestId?: string;
  icon: IconType;
  allowedRoles: UserRole[];
  allowUnverifiedCompany: boolean;
  allowedCompanyStatus?: UserCompanyStatus[];
  allowedCompanyRoles?: CompanyRole | CompanyRole[];
  enabled: boolean;
}

interface NavbarProps extends Omit<MantineNavbarProps, 'children'> {
  navbarExpanded: boolean;
  openNavbar: VoidFunction;
  closeNavbar: VoidFunction;
}

export function Navbar(props: Readonly<NavbarProps>) {
  const router = useRouter();

  const { classes, cx } = useStyles();
  const { t } = useTranslation('common');
  const navbarRef = useRef<HTMLDivElement>(null);
  const cargoTendersEnabled = useFeature(AvailableFeatureFlags.cargoTender);

  const marketplaceEnabled = useShouldShowMarketplace();
  const isMarketplaceSeparated = useFeature(AvailableFeatureFlags.separateMarketplace);
  const shouldShowForNonMiUser = !useIsMIUser();

  const isServiceProvider = useAuthRoles().isServiceProvider;

  const [isCompanySelectOpen, { toggle: toggleCompanySelect, close: closeCompanySelect }] = useDisclosure(false);

  const { width, openNavbar, closeNavbar, navbarExpanded } = props;

  useEffect(() => {
    const navbar = navbarRef.current;
    navbar?.addEventListener('mouseenter', openNavbar);
    navbar?.addEventListener('mouseleave', () => {
      closeNavbar();
      closeCompanySelect();
    });

    return () => {
      navbar?.removeEventListener('mouseenter', openNavbar);
      navbar?.removeEventListener('mouseleave', () => {
        closeNavbar();
        closeCompanySelect();
      });
    };
  }, [navbarRef, openNavbar, closeNavbar, closeCompanySelect]);

  const { data: closestCargoWorkflow } = useGetClosestCargoWorkflowService({
    query: {
      select: ({ data }) => data,
    },
  });

  const linkItems = useMemo<LinkItem[]>(() => {
    return [
      {
        enabled: true,
        link: routes.App,
        icon: RiLayout2Line,
        label: t('nav.dashboard'),
        dataTestId: 'link-dashboard',
        allowUnverifiedCompany: true,
        allowedCompanyStatus: NON_PASSIVE_NON_PRIVATE_COMPANY_STATUS,
        allowedCompanyRoles: [CompanyRole.ALLOWED_TO_NEGOTIATE],
        allowedRoles: [UserRole.BUYER, UserRole.SELLER, UserRole.FINANCE, UserRole.SERVICES, UserRole.OPERATIONS],
      },
      {
        icon: FiBox,
        link: routes.MyCargos(),
        label: t('nav.myCargos'),
        dataTestId: 'link-my-cargos',
        allowUnverifiedCompany: true,
        allowedRoles: [UserRole.BUYER, UserRole.SELLER, UserRole.FINANCE, UserRole.SERVICES, UserRole.OPERATIONS],
        enabled: true,
      },
      {
        icon: TbCalendarRepeat,
        link: routes.MyTenders(),
        label: t('nav.myTenders'),
        dataTestId: 'link-my-tenders',
        allowUnverifiedCompany: false,
        allowedRoles: [UserRole.BUYER, UserRole.SELLER],
        allowedCompanyStatus: NON_PASSIVE_NON_PRIVATE_COMPANY_STATUS,
        enabled: cargoTendersEnabled,
      },
      {
        icon: RiShipLine,
        link: routes.MyFreights(),
        label: t('nav.myFreights'),
        dataTestId: 'link-my-freights',
        allowUnverifiedCompany: false,
        allowedRoles: [UserRole.BUYER, UserRole.SELLER, UserRole.CHARTERING_MANAGER],
        allowedCompanyStatus: NON_PASSIVE_NON_PRIVATE_COMPANY_STATUS,
        enabled: true,
      },
      {
        icon: FaBroadcastTower,
        link: routes.CargoWorkflowOperations(closestCargoWorkflow?.id),
        label: t('nav.controlTower'),
        dataTestId: 'control-tower',
        allowUnverifiedCompany: true,
        allowedRoles: [UserRole.BUYER, UserRole.SELLER, UserRole.FINANCE, UserRole.SERVICES, UserRole.OPERATIONS],
        enabled: !!closestCargoWorkflow,
      },
      {
        icon: RiBarChartBoxLine,
        allowUnverifiedCompany: true,
        link: routes.MarketIntelligence(),
        label: t('nav.marketIntelligence'),
        dataTestId: 'link-market-intelligence',
        allowedRoles: Object.values(UserRole),
        allowedCompanyStatus: NON_PASSIVE_NON_PRIVATE_COMPANY_STATUS,
        enabled: !isServiceProvider,
      },
      {
        icon: RiStockLine,
        link: routes.Marketplace(),
        label: t('nav.marketplace'),
        dataTestId: 'link-marketplace',
        allowUnverifiedCompany: false,
        allowedRoles: [UserRole.BUYER, UserRole.SELLER],
        allowedCompanyStatus: NON_PASSIVE_NON_PRIVATE_COMPANY_STATUS,
        enabled: isMarketplaceSeparated && marketplaceEnabled,
      },
    ];
  }, [t, closestCargoWorkflow]);

  const links = linkItems.map((item) => {
    if (!item.enabled) {
      return null;
    }
    return (
      <HideForbiddenElement
        key={item.label}
        allowedRoles={item.allowedRoles}
        allowedCompanyRoles={item.allowedCompanyRoles}
        allowedCompanyStatus={item.allowedCompanyStatus}
        allowUnverifiedCompany={item.allowUnverifiedCompany}
      >
        <Box
          component={NextLink}
          href={item.link}
          className={cx(classes.link, { [classes.linkActive]: item.link === router.pathname })}
          data-testid={item.dataTestId}
        >
          <ThemeIcon size="xl" color={item.link === router.pathname ? 'primary.0' : 'transparent'} mr={12}>
            <item.icon className={classes.linkIcon} />
          </ThemeIcon>
          <Text size="S" weight={600} color="white" miw="max-content">
            {item.label}
          </Text>
        </Box>
      </HideForbiddenElement>
    );
  });

  const [_, promptToInstallApp, showInstallApp] = useAppInstallationPrompt();

  return (
    <MantineNavbar
      className={cx(classes.navbar, { [classes.navbarVisible]: navbarExpanded })}
      width={width}
      ref={navbarRef}
    >
      <MantineNavbar.Section
        sx={(theme) => ({ borderBottom: `1px solid ${theme.fn.rgba(theme.white, 0.1)}` })}
        className={classes.companySwitch}
      >
        <CompanySelector isCompanySelectOpen={isCompanySelectOpen} toggleCompanySelectOpen={toggleCompanySelect} />
      </MantineNavbar.Section>

      <MantineNavbar.Section grow>{links}</MantineNavbar.Section>
      <MantineNavbar.Section className={classes.footer}>
        {showInstallApp && shouldShowForNonMiUser && (
          <UnstyledButton onClick={promptToInstallApp} className={classes.link} style={{ width: '100%' }}>
            <ThemeIcon size="xl" color={'transparent'} mr={12}>
              <RiInstallFill className={classes.linkIcon} />
            </ThemeIcon>
            <Text size="S" weight={600} color="white" miw="max-content">
              {t('nav.installApp')}
            </Text>
          </UnstyledButton>
        )}
        {!isServiceProvider && shouldShowForNonMiUser && (
          <Box
            component={NextLink}
            href={routes.HelpCenter}
            className={cx(classes.link, { [classes.linkActive]: routes.HelpCenter === router.pathname })}
            data-testid="link-help-center"
          >
            <ThemeIcon size="xl" color={routes.HelpCenter === router.pathname ? 'primary.0' : 'transparent'} mr={12}>
              <RiDiscussLine className={classes.linkIcon} />
            </ThemeIcon>
            <Text size="S" weight={600} color="white" miw="max-content">
              {t('nav.helpCenter')}
            </Text>
          </Box>
        )}
        <Anchor
          href="/auth/signout"
          onClick={(e) => {
            e.preventDefault();
            signOut();
          }}
          className={classes.link}
          underline={false}
          sx={{ paddingBlock: 16 }}
          data-testid="link-logout"
        >
          <ThemeIcon size="xl" color="transparent">
            <RiLogoutBoxLine className={classes.linkIcon} />
          </ThemeIcon>
          <Text size="S" weight={600} color="white" ml={12}>
            {t('nav.logout')}
          </Text>
        </Anchor>
      </MantineNavbar.Section>
    </MantineNavbar>
  );
}

interface CompanySelectorProps {
  isCompanySelectOpen: boolean;
  toggleCompanySelectOpen: VoidFunction;
}

const CompanySelector = (props: CompanySelectorProps) => {
  const { isCompanySelectOpen, toggleCompanySelectOpen } = props;
  const { data: session } = useSession();

  const { activeCompany, companies } = session.me;
  const inactiveCompanies = companies.filter((company) => company.companyId !== activeCompany.companyId);
  const { isServiceProvider } = useAuthRoles();

  return (
    <>
      <Group
        px={8}
        py={16}
        noWrap
        onClick={toggleCompanySelectOpen}
        sx={(theme) => ({
          '&:hover': {
            backgroundColor: theme.fn.lighten(theme.colors.primary[2], 0.1),
          },
        })}
      >
        <Avatar src={activeCompany.companyLogo} alt="company logo" size={40} radius="sm" />
        <Stack spacing={0} sx={{ overflow: 'hidden' }} miw="max-content" pl={0}>
          {inactiveCompanies.length ? (
            <>
              <CompanyName companyName={activeCompany.companyName} />
              <CompanySelectSecondaryText isOpen={isCompanySelectOpen} />
            </>
          ) : (
            <CompanyNameWithLink companyName={activeCompany.companyName} isServiceProvider={isServiceProvider} />
          )}
        </Stack>
      </Group>

      {!!inactiveCompanies.length && (
        <Collapse in={isCompanySelectOpen} mx={0}>
          <Stack spacing={0}>
            {inactiveCompanies.map((company) => (
              <CompanySelectMenuItem company={company} key={company.companyId} />
            ))}
          </Stack>
        </Collapse>
      )}
    </>
  );
};

interface CompanySelectMenuItemProps {
  company: UserCompanyContextDto;
}

const CompanySelectMenuItem = ({ company }: CompanySelectMenuItemProps) => {
  const { classes } = useStyles();
  const setActiveCompany = useChangeCompanyContext();

  return (
    <Group
      noWrap
      px={8}
      py={16}
      sx={(theme) => ({ borderTop: `1px solid ${theme.fn.rgba(theme.white, 0.1)}` })}
      onClick={() => setActiveCompany(company)}
      className={classes.link}
    >
      <Avatar src={company.companyLogo} alt="company logo" size={40} radius="sm" />
      <Text size="S" weight={600} color="white.0" sx={{ textOverflow: 'ellipsis' }} miw="max-content">
        {company.companyName}
      </Text>
    </Group>
  );
};

const CompanySelectSecondaryText = ({ isOpen }: { isOpen: boolean }) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation('common');

  return (
    <Text size="XS" weight={600} color="gray.4" miw="max-content">
      {isOpen ? t('nav.hideSwitchCompany') : t('nav.switchCompany')}
      <Box
        component={'span'}
        className={cx(classes.companySwitchChiron, {
          [classes.companySwitchChironRotate90]: isOpen,
          [classes.companySwitchChironRotate0]: !isOpen,
        })}
      >
        <RiArrowRightSLine />
      </Box>
    </Text>
  );
};

const CompanyName = ({ companyName }: { companyName: string }) => (
  <Text size="S" weight={600} color="white.0" underline sx={{ textOverflow: 'ellipsis' }}>
    {companyName}
  </Text>
);

const CompanyNameWithLink = ({
  companyName,
  isServiceProvider,
}: {
  companyName: string;
  isServiceProvider: boolean;
}) => {
  return isServiceProvider ? (
    <CompanyName companyName={companyName} />
  ) : (
    <Anchor href={routes.CompanyAccount} sx={{ paddingBlock: 20 }}>
      <CompanyName companyName={companyName} />
    </Anchor>
  );
};

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';

import type {
  ContractTemplatesDTO,
  GetContractTemplatesParams,
  ContractTemplateDTO,
  ApiErrorResponse,
  CreateContractTemplateDTO,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getContractTemplates = (
  companyId: string,
  params?: GetContractTemplatesParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ContractTemplatesDTO>> => {
  return axios.get(`/api/v1/companies/${companyId}/contract-templates`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetContractTemplatesQueryKey = (companyId: string, params?: GetContractTemplatesParams) => [
  `/api/v1/companies/${companyId}/contract-templates`,
  ...(params ? [params] : []),
];

export type GetContractTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof getContractTemplates>>>;
export type GetContractTemplatesQueryError = AxiosError<void>;

export const useGetContractTemplates = <
  TData = Awaited<ReturnType<typeof getContractTemplates>>,
  TError = AxiosError<void>
>(
  companyId: string,
  params?: GetContractTemplatesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getContractTemplates>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetContractTemplatesQueryKey(companyId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getContractTemplates>>> = ({ signal }) =>
    getContractTemplates(companyId, params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getContractTemplates>>, TError, TData>(queryKey, queryFn, {
    enabled: !!companyId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const createContractTemplate = (
  companyId: unknown,
  createContractTemplateDTO: CreateContractTemplateDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ContractTemplateDTO>> => {
  return axios.post(`/api/v1/companies/${companyId}/contract-templates`, createContractTemplateDTO, options);
};

export type CreateContractTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof createContractTemplate>>>;
export type CreateContractTemplateMutationBody = CreateContractTemplateDTO;
export type CreateContractTemplateMutationError = AxiosError<void | ApiErrorResponse>;

export const useCreateContractTemplate = <TError = AxiosError<void | ApiErrorResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createContractTemplate>>,
    TError,
    { companyId: unknown; data: CreateContractTemplateDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createContractTemplate>>,
    { companyId: unknown; data: CreateContractTemplateDTO }
  > = (props) => {
    const { companyId, data } = props ?? {};

    return createContractTemplate(companyId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof createContractTemplate>>,
    TError,
    { companyId: unknown; data: CreateContractTemplateDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const getContractTemplate = (
  companyId: unknown,
  templateId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ContractTemplateDTO>> => {
  return axios.get(`/api/v1/companies/${companyId}/contract-templates/${templateId}`, options);
};

export const getGetContractTemplateQueryKey = (companyId: unknown, templateId: string) => [
  `/api/v1/companies/${companyId}/contract-templates/${templateId}`,
];

export type GetContractTemplateQueryResult = NonNullable<Awaited<ReturnType<typeof getContractTemplate>>>;
export type GetContractTemplateQueryError = AxiosError<void>;

export const useGetContractTemplate = <
  TData = Awaited<ReturnType<typeof getContractTemplate>>,
  TError = AxiosError<void>
>(
  companyId: unknown,
  templateId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getContractTemplate>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetContractTemplateQueryKey(companyId, templateId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getContractTemplate>>> = ({ signal }) =>
    getContractTemplate(companyId, templateId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getContractTemplate>>, TError, TData>(queryKey, queryFn, {
    enabled: !!(companyId && templateId),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const deleteContractTemplate = (
  companyId: unknown,
  templateId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/v1/companies/${companyId}/contract-templates/${templateId}`, options);
};

export type DeleteContractTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof deleteContractTemplate>>>;

export type DeleteContractTemplateMutationError = AxiosError<unknown>;

export const useDeleteContractTemplate = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteContractTemplate>>,
    TError,
    { companyId: unknown; templateId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteContractTemplate>>,
    { companyId: unknown; templateId: string }
  > = (props) => {
    const { companyId, templateId } = props ?? {};

    return deleteContractTemplate(companyId, templateId, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteContractTemplate>>,
    TError,
    { companyId: unknown; templateId: string },
    TContext
  >(mutationFn, mutationOptions);
};

import { useTranslation } from 'next-i18next';
import { Select, TextInput } from '@mantine/core';

import { GetPaths } from '@/common/types';
import { AnalysisTypes } from '@/blink-forms/types';
import { DEFAULT_ANALYSIS_TYPE_VALUE } from '@/views/tenders/constants';
import { useGetSelectDataWithTranslations } from '@/views/tenders/utils/use-get-select-data-with-translations';

interface AnalysisFieldGroupProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getInputProps: (path: GetPaths<any>) => any;
  data?: any;
  namePath: string;
  typePath: string;
  conditionsPath: string;
}

export function AnalysisFieldGroup({ getInputProps, namePath, typePath, conditionsPath }: AnalysisFieldGroupProps) {
  const { t } = useTranslation('tenderNegotiation');
  const analysisSelectData = useGetSelectDataWithTranslations(AnalysisTypes);

  return (
    <>
      <TextInput
        label={t('labels.conditions')}
        placeholder={t('placeholders.conditions')}
        withAsterisk={false}
        {...getInputProps(conditionsPath)}
      />
      <TextInput
        label={t('labels.analysisNameOrIndication')}
        placeholder={t('placeholders.name')}
        withAsterisk={false}
        {...getInputProps(namePath)}
      />
      <Select
        withAsterisk
        label={t('labels.type')}
        data={analysisSelectData}
        defaultValue={DEFAULT_ANALYSIS_TYPE_VALUE}
        {...getInputProps(typePath)}
      />
    </>
  );
}

import { Checkbox, Stack } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { UseFormReturnType } from '@mantine/form';
import { AvailableFeatureFlags } from 'api/generated/model';

import { useFeature } from '@/core';
import { Title } from '@/components';
import { ConditionalRequestFreightFullSchema } from '@/blink-forms';

import { RequestFreightFormFields } from './RequestFreightFormFields';
import { FreightEstimateAdpater, FreightEstimateAdpaterProps } from './FreightEstimateAdapter';

export interface RequestFreightProps<T, K> {
  form: UseFormReturnType<T>;
  cargoOfferData: K;
}

export function RequestFreight<T extends ConditionalRequestFreightFullSchema, K extends FreightEstimateAdpaterProps>({
  form,
  cargoOfferData,
}: RequestFreightProps<T, K>) {
  const { t } = useTranslation('cargoDetails');

  const isNewFreightEstimateEnabled = useFeature(AvailableFeatureFlags.freightIntegrationEnabled);

  return (
    <Stack spacing="xl">
      <Title size="L" fw={600}>
        {t('headers.requestFreight')}
      </Title>
      <Checkbox label={t('labels.sendDetails')} {...form.getInputProps('requestFreightDetails')} />
      {form.values.requestFreightDetails && (
        <>
          {isNewFreightEstimateEnabled && <FreightEstimateAdpater {...cargoOfferData} />}
          <RequestFreightFormFields form={form} />
        </>
      )}
    </Stack>
  );
}

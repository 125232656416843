import { useTranslation } from 'next-i18next';
import { RiErrorWarningFill } from 'react-icons/ri';
import { Button, Group, Stack } from '@mantine/core';

import { BackgroundIcon, BaseModal, Text } from '@/components';

const icon = <BackgroundIcon color="orange" icon={RiErrorWarningFill} size={20} />;

interface ContentProps {
  isLoading: boolean;
  onBack: VoidFunction;
  onConfirm: VoidFunction;
}

function Content(props: ContentProps) {
  const { t } = useTranslation('recapForm');
  const { isLoading, onBack, onConfirm } = props;

  return (
    <Stack>
      <Text color="gray.6" size="S">
        {t('modals.confirmTemplate.descriptionDetailedOffer')}
      </Text>

      <Group grow>
        <Button onClick={onBack} variant="outline" size="md">
          {t('buttons.back')}
        </Button>

        <Button onClick={onConfirm} size="md" loading={isLoading}>
          {t('buttons.confirm')}
        </Button>
      </Group>
    </Stack>
  );
}

export interface LoadDetailedOfferTemplateModalProps {
  opened: boolean;
  isLoading: boolean;
  toggle: VoidFunction;
  onLoadTemplate: VoidFunction;
}

export function LoadDetailedOfferTemplateModal(props: LoadDetailedOfferTemplateModalProps) {
  const { opened, isLoading, toggle, onLoadTemplate } = props;
  const { t } = useTranslation('recapForm');

  return (
    <BaseModal
      opened={opened}
      close={toggle}
      icon={icon}
      header={t('modals.confirmTemplate.heading')}
      content={<Content onBack={toggle} onConfirm={onLoadTemplate} isLoading={isLoading} />}
    />
  );
}

import { Box } from '@mantine/core';
export function DotDivider() {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.colors.gray[4],
        borderRadius: '50%',
        height: 4,
        width: 4,
        transform: 'translateY(50%)',
      })}
    />
  );
}

import { createStyles, MantineColor, ThemeIcon, ThemeIconProps } from '@mantine/core';
import { IconType } from 'react-icons/lib';

const useStyles = createStyles((theme, params: Omit<ThemeIconProps, 'children'>) => ({
  themeIcon: {
    width: 40,
    height: 40,
    borderRadius: theme.radius.md,
    backgroundColor: params.color === 'gray' ? theme.colors.gray[2] : theme.colors[params.color][0],
    border: `1px solid ${params.color === 'gray' ? theme.colors.gray[4] : theme.colors[params.color][1]}`,
  },
  icon: {
    color: params.color === 'gray' ? theme.colors.gray[8] : theme.colors[params.color][6],
  },
}));

export interface BackgroundIconProps extends Partial<ThemeIconProps> {
  icon: IconType;
  color: MantineColor;
}

export function BackgroundIcon(props: BackgroundIconProps) {
  const { icon: Icon, color, size, ...rest } = props;
  const { classes } = useStyles({ color });

  return (
    <ThemeIcon className={classes.themeIcon} {...rest}>
      <Icon className={classes.icon} size={size} />
    </ThemeIcon>
  );
}

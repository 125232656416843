/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoTenderFreightConditionsDTOLaytimeCalculationsItem =
  (typeof CargoTenderFreightConditionsDTOLaytimeCalculationsItem)[keyof typeof CargoTenderFreightConditionsDTOLaytimeCalculationsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoTenderFreightConditionsDTOLaytimeCalculationsItem = {
  BILATERAL_AGREEMENT: 'BILATERAL_AGREEMENT',
  SELLER_CALCULATION: 'SELLER_CALCULATION',
  BUYER_CALCULATION: 'BUYER_CALCULATION',
  TRUSTED_PARTY: 'TRUSTED_PARTY',
} as const;

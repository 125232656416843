import { NumberInputProps } from '@mantine/core';

import { ThemeComponent } from './types';

export const NumberInput: ThemeComponent<NumberInputProps> = {
  defaultProps: {
    hideControls: true,
  },
  styles: (_theme, params) => ({
    root: {
      height: params.size === 'sm' ? 'auto' : 80,
    },
    wrapper: {
      width: '100%',
      marginBottom: 0,
    },
    input: {
      height: 48,

      ...(params.size === 'sm' && {
        height: 40,
      }),
    },
    label: {
      fontSize: 16,
      marginBottom: 8,
    },
    error: {
      position: 'relative',
      marginTop: 0,
    },
  }),
};

import { ReactNode } from 'react';
import { IconType } from 'react-icons';
import { Flex, MantineColor, Alert as ManAlert, AlertProps as ManAlertProps } from '@mantine/core';

import { useAlertStyle } from './Alert.style';
import { IconWithBackground } from '../../icons/IconWithBackground/IconWithBackground';

export interface AlertProps extends Omit<ManAlertProps, 'icon' | 'onClick' | 'children' | 'content'> {
  icon: IconType;
  content: ReactNode;
  color?: MantineColor;
  iconColor?: MantineColor;
}

export const Alert = ({ icon, content, classNames, color = 'blue', iconColor = 'blue', ...props }: AlertProps) => {
  const { classes } = useAlertStyle();

  return (
    <ManAlert {...props} color={color} classNames={{ ...classes, ...classNames }}>
      <Flex align="center" direction="row" justify="space-between">
        <Flex align="center" direction="row" justify="flex-start">
          <IconWithBackground mr="md" size={20} icon={icon} color={iconColor} />
          {content}
        </Flex>
      </Flex>
    </ManAlert>
  );
};

import { ActionIcon, Button, Group, Paper, useMantineTheme } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { CompanyType, FreightNegotiationStepStatusLabel, UserRole } from 'api/generated/model';

import { ExpireBadgeWithButton, Title } from '@/components';
import { useHasPermission } from '@/core';
import { isTimerVisible } from '@/common/freight-negotiation-status';

interface FreightRecapFormHeaderProps {
  onBackClick: VoidFunction;
  onLoadTemplate: VoidFunction;
}

export function FreightRecapFormHeader(props: FreightRecapFormHeaderProps) {
  const { onBackClick, onLoadTemplate } = props;
  const { t } = useTranslation('freightRecapForm');
  const theme = useMantineTheme();

  return (
    <Paper px={24} py={20} radius={0} sx={(theme) => ({ borderBottom: `1px solid ${theme.colors.gray[2]}` })}>
      <Group maw={1232} mx="auto" spacing={8}>
        <ActionIcon variant="transparent" onClick={onBackClick}>
          <RiArrowLeftSLine size={24} color={theme.fn.primaryColor()} style={{ transform: 'translateY(2px)' }} />
        </ActionIcon>

        <Title size="XL" color="gray.9" weight={600}>
          {t('headers.freightOffer')}
        </Title>

        <Button variant="outline" size="sm" ml="auto" onClick={onLoadTemplate}>
          {t('buttons.loadTemplate')}
        </Button>
      </Group>
    </Paper>
  );
}

interface FreightCounterOfferRecapFormHeaderProps {
  expiresAt: string;
  isRequested: boolean;
  onExpireBadgeClick: VoidFunction;
  onBackClick: VoidFunction;
  onLoadTemplate: VoidFunction;
  status: FreightNegotiationStepStatusLabel;
}

export function FreightCounterOfferRecapFormHeader(props: FreightCounterOfferRecapFormHeaderProps) {
  const { expiresAt, isRequested, onExpireBadgeClick, onBackClick, onLoadTemplate, status } = props;
  const { t } = useTranslation('freightRecapForm');
  const { hasUserRolePermission, hasCompanyPermission } = useHasPermission();
  const theme = useMantineTheme();
  const isCharteringManager =
    hasUserRolePermission([UserRole.CHARTERING_MANAGER]) && hasCompanyPermission([CompanyType.CHARTERING]);

  return (
    <Paper px={24} py={20} radius={0} sx={(theme) => ({ borderBottom: `1px solid ${theme.colors.gray[2]}` })}>
      <Group maw={1232} mx="auto" spacing={8}>
        <ActionIcon variant="transparent" onClick={onBackClick}>
          <RiArrowLeftSLine size={24} color={theme.fn.primaryColor()} style={{ transform: 'translateY(2px)' }} />
        </ActionIcon>

        <Title size="XL" color="gray.9" weight={600}>
          {t('headers.freightOffer')}
        </Title>

        <Group ml="auto" spacing={8}>
          {isTimerVisible(status) && (
            <ExpireBadgeWithButton
              expiresAt={expiresAt}
              isRequested={isRequested}
              isSeller={isCharteringManager}
              onClick={onExpireBadgeClick}
            />
          )}

          <Button variant="outline" size="sm" onClick={onLoadTemplate}>
            {t('buttons.loadTemplate')}
          </Button>
        </Group>
      </Group>
    </Paper>
  );
}

import { ZodIssueCode, defaultErrorMap, ZodParsedType } from 'zod';
import { I18n } from 'next-i18next';

export const makeZodI18nMap =
  (t: I18n['t']): typeof defaultErrorMap =>
  (issue, ctx) => {
    let message: string;
    message = defaultErrorMap(issue, ctx).message;

    switch (issue.code) {
      case ZodIssueCode.invalid_type:
        if (issue.received === ZodParsedType.undefined) {
          message = t('zod:errors.required', {
            defaultValue: message,
          });
        }
        break;
      case ZodIssueCode.invalid_literal:
        if (issue.expected === true) {
          message = t('zod:errors.required', {
            defaultValue: message,
          });
        }
        break;
      case ZodIssueCode.too_small:
        if (['string', 'array'].includes(issue.type)) {
          message = t('zod:errors.required', {
            defaultValue: message,
          });
        }
        break;
      case ZodIssueCode.custom:
        message = t(`zod:errors.${issue.path.join('-')}`);
        break;
      case ZodIssueCode.too_big:
        if (issue.type === 'number') {
          message = t('zod:errors.numberTooBig', { maximum: issue.maximum });
        } else {
          message = t('zod:errors.fieldTooBig', { maximum: issue.maximum });
        }
        break;
      case ZodIssueCode.invalid_string:
        if (issue.path.includes('phoneNumber')) {
          message = t('zod:errors.phoneNumber');
        }
        break;
      default:
    }

    return { message };
  };

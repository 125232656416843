/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CreateServiceProviderResponseDtoCompanyDtoContactDtoStatus =
  (typeof CreateServiceProviderResponseDtoCompanyDtoContactDtoStatus)[keyof typeof CreateServiceProviderResponseDtoCompanyDtoContactDtoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateServiceProviderResponseDtoCompanyDtoContactDtoStatus = {
  UNVERIFIED: 'UNVERIFIED',
  VERIFIED: 'VERIFIED',
  REJECTED: 'REJECTED',
} as const;

import { z } from 'zod';

import { surveyorOptional } from '../surveyor/surveyor';

export const weight = z.object({
  surveyorAtLoadport: z.array(surveyorOptional),
  surveyorAtDischargeport: z.array(surveyorOptional),
  other: z.string().optional(),
});

export type WeightSchema = z.infer<typeof weight>;

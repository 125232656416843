import { Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'next-i18next';
import { RiFilter3Line } from 'react-icons/ri';

import { Filters, FiltersProps, ResponsiveDrawerWrapper } from '@/components';

export function ResponsiveFilterWrapper(props: Omit<FiltersProps, 'onDone'>) {
  const { t } = useTranslation('common');
  const [opened, { toggle }] = useDisclosure(false);

  return (
    <ResponsiveDrawerWrapper
      opened={opened}
      toggle={toggle}
      openingButton={
        <Button leftIcon={<RiFilter3Line />} onClick={toggle}>
          {t('labels.filters', { count: props.filtersCount })}
        </Button>
      }
    >
      <Filters {...props} onDone={toggle} />
    </ResponsiveDrawerWrapper>
  );
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type ServiceProviderTypeEnum = (typeof ServiceProviderTypeEnum)[keyof typeof ServiceProviderTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServiceProviderTypeEnum = {
  ACCOUNTING_FINANCE: 'ACCOUNTING_FINANCE',
  BANK_TRADE_FINANCE_PROVIDER: 'BANK_TRADE_FINANCE_PROVIDER',
  LAWYER: 'LAWYER',
  ECTN_PROVIDER: 'ECTN_PROVIDER',
  INSURANCE_BROKER: 'INSURANCE_BROKER',
  VESSEL_FREIGHT_BROKER: 'VESSEL_FREIGHT_BROKER',
  TRADE_OPERATIONS: 'TRADE_OPERATIONS',
  SHIPPING_AGENT: 'SHIPPING_AGENT',
  SHIPPING_OPERATIONS: 'SHIPPING_OPERATIONS',
  SURVEYOR_QUALITY: 'SURVEYOR_QUALITY',
  SURVEYOR_CUSTOM: 'SURVEYOR_CUSTOM',
  TRADE_OPERATION: 'TRADE_OPERATION',
  MARITIME_AGENT: 'MARITIME_AGENT',
  BROKER: 'BROKER',
  VESSEL_OWNER: 'VESSEL_OWNER',
  OTHER: 'OTHER',
} as const;

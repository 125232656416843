import { z } from 'zod';

import { mainAnalysis } from './main-analysis';
import { arbitrationAnalysis } from './arbitration-analysis';
import { surveyorOptional } from '../surveyor/surveyor';

export const samplingAndAnalysis = z.object({
  surveyorAtLoadport: z.array(surveyorOptional).optional(),
  surveyorAtDischargeport: z.array(surveyorOptional).optional(),
  mainAnalysis: z.array(mainAnalysis).optional(),
  arbitrationAnalysis: arbitrationAnalysis.optional(),
  other: z.string().optional(),
});

export type SamplingAndAnalysisSchema = z.infer<typeof samplingAndAnalysis>;

import { createStyles } from '@mantine/core';

interface UseFlexibleConfirmationModalStyle {
  scrollable: boolean;
  titleCentralized: boolean;
}

export const useFlexibleConfirmationModalStyle = createStyles(
  (theme, { scrollable, titleCentralized }: UseFlexibleConfirmationModalStyle) => ({
    header: {
      marginBottom: 0,
    },
    body: {
      overflowY: scrollable ? 'scroll' : 'auto',
    },
    customTextAlign: {
      textAlign: titleCentralized ? 'center' : 'left',
      [theme.fn.smallerThan('xs')]: {
        textAlign: titleCentralized ? 'center' : 'left',
      },
    },
  })
);

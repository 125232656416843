import { useRouter } from 'next/router';
import { UserRole } from 'api/generated/model';

import { routes } from '@/common/routes';
import { useHasPermission } from '@/core';

export const useRedirectAfterWorkflow = () => {
  const router = useRouter();
  const { hasUserRolePermission } = useHasPermission();

  const redirectToAfterWorkflow = (workflowId: string) => {
    if (hasUserRolePermission([UserRole.BUYER, UserRole.SELLER])) {
      return router.push(routes.CargoWorkflowSummary(workflowId));
    }
    if (hasUserRolePermission([UserRole.SERVICES])) {
      router.push(routes.CargoWorkflowServices(workflowId));
    }
    if (hasUserRolePermission([UserRole.OPERATIONS, UserRole.FINANCE])) {
      router.push(routes.CargoWorkflowOperations(workflowId));
    }
  };

  return { redirectToAfterWorkflow };
};

import { CostsTermsWithNA } from '../../types';
import { AgentsInPortSchema } from './agents-in-port';

export const AGENTS_IN_PORT_INITIAL_VALUES: AgentsInPortSchema = {
  mainAgentConditions: CostsTermsWithNA.NA,
  mainAgentName: '',
  protectiveAgentConditions: CostsTermsWithNA.NA,
  protectiveAgentName: '',
};

//Empty values
export const AGENTS_IN_PORT_EMPTY_VALUES = {
  mainAgentConditions: null,
  mainAgentName: undefined,
  protectiveAgentConditions: null,
  protectiveAgentName: undefined,
};

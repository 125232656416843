import { useTranslation } from 'next-i18next';

import { MyCargoRowData } from '@/views/my-cargos';
import { formatDate } from '@/common/format-date';
import { transformToPercentage } from '@/common/percentage-transform';
import { useIsCargoNeedNegotiation } from '@/views/my-cargos/utils/use-is-cargo-need-negotiation';
import { formatterThousand } from '@/common/get-thousand-seperated-number';

export const useGetCargoDescription = (cargo: MyCargoRowData) => {
  const { t } = useTranslation('myCargos');
  const isCargoNeedNegotiation = useIsCargoNeedNegotiation(cargo);
  const dateMin = isCargoNeedNegotiation ? cargo.etaMin : cargo.laycanMin;
  const dateMax = isCargoNeedNegotiation ? cargo.etaMax : cargo.laycanMax;

  const productDescription = t('table.description.product', {
    productQuantity: formatterThousand(cargo.quantity),
    productName: cargo.productName.toLowerCase(),
    minTolerancePercentage: transformToPercentage(cargo.toleranceMinPercentage),
    maxTolerancePercentage: transformToPercentage(cargo.toleranceMaxPercentage),
  });
  const gradeDescription = t('table.description.grade', { grade: cargo.productQuality });
  const etaDescription = t('table.description.eta', {
    etaStartDate: formatDate(dateMin),
    etaEndDate: formatDate(dateMax),
  });
  const portDescription = `${
    cargo.loadingPortName ? t('table.description.loadingPort', { loadingPort: cargo.loadingPortName }) : ''
  } ${
    cargo.dischargingPortName
      ? t('table.description.dischargingPort', { dischargingPort: cargo.dischargingPortName })
      : ''
  }`;

  return `${productDescription}, ${gradeDescription},${
    !isCargoNeedNegotiation ? ` ${t('table.description.loading')}` : ''
  } ${etaDescription}, ${portDescription}`;
};

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type NegotiationStatus = (typeof NegotiationStatus)[keyof typeof NegotiationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NegotiationStatus = {
  RFQ_SENT: 'RFQ_SENT',
  RFQ_RECEIVED: 'RFQ_RECEIVED',
  OFFER_RECEIVED: 'OFFER_RECEIVED',
  OFFER_SENT: 'OFFER_SENT',
  COUNTER_OFFER_RECEIVED: 'COUNTER_OFFER_RECEIVED',
  COUNTER_OFFER_SENT: 'COUNTER_OFFER_SENT',
  REJECTED: 'REJECTED',
  REJECTED_SELLER: 'REJECTED_SELLER',
  REJECTED_BUYER: 'REJECTED_BUYER',
  REVIEW_RECEIVED: 'REVIEW_RECEIVED',
  REVIEW_SENT: 'REVIEW_SENT',
  SELLER_ACCEPTED: 'SELLER_ACCEPTED',
  BUYER_ACCEPTED: 'BUYER_ACCEPTED',
  INVALID_BUYER: 'INVALID_BUYER',
  INVALID_SELLER: 'INVALID_SELLER',
  EXPIRED: 'EXPIRED',
  CANCELED: 'CANCELED',
  SELLER_CANCELED: 'SELLER_CANCELED',
  BUYER_CANCELED: 'BUYER_CANCELED',
  DRAFT: 'DRAFT',
} as const;

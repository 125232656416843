import { Paper, PaperProps } from '@mantine/core';

import { Title } from '../typography/Title';

type OmittedPaperProps = 'p' | 'px' | 'pb' | 'pt' | 'py' | 'pl' | 'pr' | 'radius';

interface TitledCardProps extends Omit<PaperProps, OmittedPaperProps> {
  title: string;
  children?: React.ReactNode;
}

export function TitledCard({ title, children, ...restPaperProps }: TitledCardProps) {
  return (
    <Paper px="md" pt="sm" pb="md" bg="gray.0" radius="md" {...restPaperProps}>
      <Title weight="bold" size="L" mb="md">
        {title}
      </Title>
      {children}
    </Paper>
  );
}

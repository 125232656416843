import { Box, createStyles } from '@mantine/core';
import { ReactNode, Children, isValidElement } from 'react';

const useStyles = createStyles((theme) => ({
  containerWithDivider: {
    display: 'grid',
    [theme.fn.largerThan('md')]: {
      gridTemplateColumns: '40% auto 1fr',
      gridColumnGap: 32,
    },
  },
  containerWithoutDivider: {
    display: 'grid',
    [theme.fn.largerThan('md')]: {
      gridTemplateColumns: '1fr 1fr',
      gridColumnGap: 32,
    },
  },
  divider: {
    border: 0,
    borderTopWidth: 1,
    borderColor: theme.colors.gray[1],
    borderStyle: 'solid',
    margin: '12px 0',

    [theme.fn.largerThan('md')]: {
      height: 'auto',
      borderTopWidth: 0,
      borderLeftWidth: 1,
      margin: '0 24px',
    },
  },
}));

interface CargoCardGridProps {
  children: ReactNode;
  className?: string;
}

export function CargoCardGrid({ children, className }: CargoCardGridProps) {
  const { classes, cx } = useStyles();

  const childArray: ReactNode[] = Children.toArray(children);
  const hasThreeChildrenAndSecondIsDivider: boolean =
    childArray.length === 3 && isValidElement(childArray[1]) && childArray[1].type === CargoCardGridDivider;

  return (
    <Box
      className={cx(
        hasThreeChildrenAndSecondIsDivider ? classes.containerWithDivider : classes.containerWithoutDivider,
        className
      )}
    >
      {children}
    </Box>
  );
}

function CargoCardGridDivider() {
  const { classes } = useStyles();
  return <Box className={classes.divider} />;
}

CargoCardGrid.Divider = CargoCardGridDivider;

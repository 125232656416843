import { ReactNode } from 'react';
import { Grid } from '@mantine/core';

export interface AdaptableGridProps {
  children: ReactNode[];
  columns?: number;
}

export function AdaptableGrid({ children, columns }: AdaptableGridProps) {
  const mdSpan = Math.max(1, Math.floor(columns / children.length));
  const smSpan = mdSpan * 2;

  return (
    <Grid columns={columns}>
      {children.map((child, idx) => {
        return (
          <Grid.Col key={idx} span={columns} sm={smSpan} md={mdSpan}>
            {child}
          </Grid.Col>
        );
      })}
    </Grid>
  );
}

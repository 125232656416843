import { z } from 'zod';

import { Laws, ClauseType, Jurisdiction } from '../types';
import { cargoTenderPaymentConditionsSchema } from './cargo-tender/cargo-tender-payment-conditions';

export const cargoTenderNegotiationLawAndJurisdictionSchema = z.object({
  law: z.nativeEnum(Laws),
  jurisdiction: z.nativeEnum(Jurisdiction),
});

export const cargoTenderNegotiationInsuranceAndRiskSchema = z
  .object({
    key: z.string().optional(),
    clauseName: z.string().optional(),
    clauseDetails: z.string().nonempty(),
    clauseType: z.nativeEnum(ClauseType),
  })
  .refine(
    (data) => {
      if (data.clauseType === ClauseType.OTHER) {
        return data.clauseName !== undefined && data.clauseName.trim().length > 0;
      }
      return true;
    },
    {
      message: "Clause Name is required when Clause Type is 'OTHER'",
      path: ['clauseName'],
    }
  );

export const cargoTenderNegotiationGeneralConditionsSchema = z.object({
  lawAndJurisdiction: cargoTenderNegotiationLawAndJurisdictionSchema,
  paymentConditions: cargoTenderPaymentConditionsSchema,
  contractAndClauses: z.array(cargoTenderNegotiationInsuranceAndRiskSchema).min(1).optional(),
  other: z.string().optional(),
});

export type CargoTenderNegotiationLawAndJurisdictionSection = z.infer<
  typeof cargoTenderNegotiationLawAndJurisdictionSchema
>;

export type CargoTenderNegotiationInsuranceAndRisk = z.infer<typeof cargoTenderNegotiationInsuranceAndRiskSchema>;

export type CargoTenderNegotiationGeneralConditionsForm = z.infer<typeof cargoTenderNegotiationGeneralConditionsSchema>;

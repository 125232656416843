import { Stack, StackProps } from '@mantine/core';

import { Text } from '../typography/Text';

export interface DefinitionProps extends StackProps {
  label: string;
  children?: React.ReactNode;
}

export function Definition({ label, children, ...restStackProps }: DefinitionProps) {
  return (
    <Stack spacing={4} {...restStackProps}>
      <Text color="gray65.0">{label}</Text>
      <Text weight="bold">{children}</Text>
    </Stack>
  );
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import type { UseQueryOptions, QueryFunction, UseQueryResult, QueryKey } from '@tanstack/react-query';

import type { ChatSessionTokenDto, ChatGroupUnreadMessagesDTO, ChatGroupDTO, ChatType, UserChatDTO } from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Get token chat for platform user
 */
export const getChatTokenForUser = (options?: AxiosRequestConfig): Promise<AxiosResponse<ChatSessionTokenDto>> => {
  return axios.get(`/api/v1/chat/session_token`, options);
};

export const getGetChatTokenForUserQueryKey = () => [`/api/v1/chat/session_token`];

export type GetChatTokenForUserQueryResult = NonNullable<Awaited<ReturnType<typeof getChatTokenForUser>>>;
export type GetChatTokenForUserQueryError = AxiosError<void>;

export const useGetChatTokenForUser = <
  TData = Awaited<ReturnType<typeof getChatTokenForUser>>,
  TError = AxiosError<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getChatTokenForUser>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetChatTokenForUserQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getChatTokenForUser>>> = ({ signal }) =>
    getChatTokenForUser({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getChatTokenForUser>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Get unread messages for user's chat groups
 */
export const getChatGroupsUnreadMessages = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ChatGroupUnreadMessagesDTO[]>> => {
  return axios.get(`/api/v1/chat/unread_messages`, options);
};

export const getGetChatGroupsUnreadMessagesQueryKey = () => [`/api/v1/chat/unread_messages`];

export type GetChatGroupsUnreadMessagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getChatGroupsUnreadMessages>>
>;
export type GetChatGroupsUnreadMessagesQueryError = AxiosError<void>;

export const useGetChatGroupsUnreadMessages = <
  TData = Awaited<ReturnType<typeof getChatGroupsUnreadMessages>>,
  TError = AxiosError<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getChatGroupsUnreadMessages>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetChatGroupsUnreadMessagesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getChatGroupsUnreadMessages>>> = ({ signal }) =>
    getChatGroupsUnreadMessages({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getChatGroupsUnreadMessages>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Get chat group by type and id
 */
export const getChatGroup = (
  type: ChatType,
  id: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ChatGroupDTO>> => {
  return axios.get(`/api/v1/chat/groups/${type}/${id}`, options);
};

export const getGetChatGroupQueryKey = (type: ChatType, id: string) => [`/api/v1/chat/groups/${type}/${id}`];

export type GetChatGroupQueryResult = NonNullable<Awaited<ReturnType<typeof getChatGroup>>>;
export type GetChatGroupQueryError = AxiosError<void>;

export const useGetChatGroup = <TData = Awaited<ReturnType<typeof getChatGroup>>, TError = AxiosError<void>>(
  type: ChatType,
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getChatGroup>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetChatGroupQueryKey(type, id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getChatGroup>>> = ({ signal }) =>
    getChatGroup(type, id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getChatGroup>>, TError, TData>(queryKey, queryFn, {
    enabled: !!(type && id),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Get all user chat groups
 */
export const getAllChatGroups = (options?: AxiosRequestConfig): Promise<AxiosResponse<UserChatDTO[]>> => {
  return axios.get(`/api/v1/chat/groups`, options);
};

export const getGetAllChatGroupsQueryKey = () => [`/api/v1/chat/groups`];

export type GetAllChatGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllChatGroups>>>;
export type GetAllChatGroupsQueryError = AxiosError<void>;

export const useGetAllChatGroups = <
  TData = Awaited<ReturnType<typeof getAllChatGroups>>,
  TError = AxiosError<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllChatGroups>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllChatGroupsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllChatGroups>>> = ({ signal }) =>
    getAllChatGroups({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getAllChatGroups>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Sync all user chats he has access to
 */
export const syncUserChats = (options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.get(`/api/v1/chat/sync`, options);
};

export const getSyncUserChatsQueryKey = () => [`/api/v1/chat/sync`];

export type SyncUserChatsQueryResult = NonNullable<Awaited<ReturnType<typeof syncUserChats>>>;
export type SyncUserChatsQueryError = AxiosError<unknown>;

export const useSyncUserChats = <
  TData = Awaited<ReturnType<typeof syncUserChats>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof syncUserChats>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSyncUserChatsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof syncUserChats>>> = ({ signal }) =>
    syncUserChats({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof syncUserChats>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

import { useEffect } from 'react';

interface UseWarningOnExitOptions {
  disabled?: boolean;
  onBeforeUnload?: () => void;
}

export function useWarningOnExit(message: string, options: UseWarningOnExitOptions = {}) {
  const { disabled, onBeforeUnload } = options;

  useEffect(() => {
    const beforeUnload = (e: BeforeUnloadEvent) => {
      if (!disabled) {
        onBeforeUnload && onBeforeUnload();
        const event = e ?? window?.event;
        event.returnValue = message;
        return message;
      }
      return null;
    };

    window.addEventListener('beforeunload', beforeUnload);

    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [disabled, message, onBeforeUnload]);
}

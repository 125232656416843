import { format } from 'date-fns';

type UnformattedDate = string | null | Date;

export const SHORT_MONTH_DATE_FORMAT = 'DD MMM YYYY';
export const COMPACT_FULL_DATE_FORMAT = 'DD/MM/YYYY';
export const SHORT_MONTH_DATE_FORMAT_FNS = 'dd MMM yyyy';

export const formatTime = (date: string) => format(new Date(date), 'h:mm a');

export const formatDate = (date: UnformattedDate, dateFormat = 'dd/LL/yyyy') =>
  date ? format(new Date(date), dateFormat) : '-';

export const formatDateRange = (...dates: [UnformattedDate, UnformattedDate, ...UnformattedDate[]]) =>
  dates.map((date) => formatDate(date)).join(' - ');

export const formatMarketIntelligenceReportDate = (date: string) => format(new Date(date), 'MMM dd yyyy');

export const formatMarketIntelligenceReportTime = (date: string) => format(new Date(date), 'HH:mm O');

export const formatSOFDateTime = (date: UnformattedDate) =>
  date ? format(new Date(date), 'dd MMM yyyy @ HH:mm') : '-';

export const formatMinAndMaxDateRange = ({
  dateFormat = 'MM/dd/yyyy',
  dates: { minDate, maxDate },
}: {
  dateFormat?: string;
  dates: {
    minDate: Date;
    maxDate: Date;
  };
}) => {
  return `${format(minDate, dateFormat)} - ${format(maxDate, dateFormat)}`;
};

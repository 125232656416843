/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type ContractTemplateType = (typeof ContractTemplateType)[keyof typeof ContractTemplateType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContractTemplateType = {
  CARGO_NEGOTIATION: 'CARGO_NEGOTIATION',
  DETAILED_OFFER: 'DETAILED_OFFER',
} as const;

import { useState } from 'react';

import { UnarchiveMutationData, useUnarchiveMutation } from './use-unarchive-mutation';
import { ArchiveMutationData, useArchiveMutation } from './use-archive-mutation';

export type UseArchivingModalConfig = {
  archiveCallback?: VoidFunction;
  unarchiveCallback?: VoidFunction;
};

const useModalData = <T>() => {
  const [modalData, setModalData] = useState<T | null>(null);

  const setData = (data: T) => {
    setModalData(data);
  };

  const clearData = () => {
    setModalData(null);
  };

  return {
    setData,
    modalData,
    clearData,
  };
};

export const useArchivingModal = ({ archiveCallback, unarchiveCallback }: UseArchivingModalConfig) => {
  const [isModalVisible, setIsModalVisible] = useState({ archive: false, unarchive: false });

  const {
    setData: setArchiveData,
    modalData: archiveModalData,
    clearData: clearArchiveData,
  } = useModalData<ArchiveMutationData>();
  const {
    setData: setUnarchiveData,
    modalData: unarchiveModalData,
    clearData: clearUnarchiveData,
  } = useModalData<UnarchiveMutationData>();

  const { archiveCargo, isProcessing: isProcessingArchiveCargo } = useArchiveMutation(
    archiveModalData,
    archiveCallback
  );
  const { unarchiveCargo, isProcessing: isProcessingUnarchiveCargo } = useUnarchiveMutation(
    unarchiveModalData,
    unarchiveCallback
  );

  const openModal = (type: 'archive' | 'unarchive', data: ArchiveMutationData | UnarchiveMutationData) => {
    setIsModalVisible({ ...isModalVisible, [type]: true });
    type === 'archive' ? setArchiveData(data as ArchiveMutationData) : setUnarchiveData(data as UnarchiveMutationData);
  };

  const closeModal = (type: 'archive' | 'unarchive') => {
    setIsModalVisible({ ...isModalVisible, [type]: false });
    type === 'archive' ? clearArchiveData() : clearUnarchiveData();
  };

  return {
    openModal,
    closeModal,
    archiveCargo,
    unarchiveCargo,
    isProcessingArchiveCargo,
    isProcessingUnarchiveCargo,
    isArchivedModalVisible: isModalVisible.archive,
    isUnarchiveModalVisible: isModalVisible.unarchive,
  };
};

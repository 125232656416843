import { Divider, Grid, Group, Radio, Stack } from '@mantine/core';
import { Trans, useTranslation } from 'next-i18next';
import { ReactNode } from 'react';

import { NumberInput, Select, TextInput } from '@/views/recap-form';
import { getFixedNumberProps } from '@/common/get-fixed-number-props';

import { useFreightRecapFormState } from '../state/FreightRecapFormProvider';

export interface FreightRecapFormVesselGroupProps {
  children: ReactNode;
}

export function FreightRecapFormVesselGroup() {
  const { t } = useTranslation('freightRecapForm');
  const { masterdata, form, getInputProps } = useFreightRecapFormState();

  const { type } = form.values.vessel;
  const clearNominatedVesselFields = () => form.setFieldValue('vessel.type', undefined);
  const clearTbnFields = () => {
    form.setFieldValue('vessel.maxAge', undefined);
    form.setFieldValue('vessel.nominationDays', undefined);
  };

  return (
    <>
      <Radio.Group name="type" orientation="vertical" mb={24} {...getInputProps('vessel.type')}>
        <Stack spacing={8}>
          <Radio
            value="TBN"
            label={t('labels.TBN')}
            data-testid="vesselInformation-TBN"
            onClick={clearNominatedVesselFields}
          />
          {type === 'TBN' && (
            <Group>
              <Trans t={t} i18nKey="labels.tbnMaxAge">
                Max age <NumberInput min={0} w={52} {...getInputProps('vessel.maxAge')} /> years
              </Trans>
              <Divider orientation="vertical" />
              <Trans
                t={t}
                i18nKey="labels.vesselsToBeNominated"
                components={{
                  bold: <strong />,
                  input: (
                    <NumberInput
                      min={0}
                      w={52}
                      data-testid="vesselInformation-TBN-nominationDays"
                      {...getInputProps('vessel.nominationDays')}
                    />
                  ),
                }}
              />
            </Group>
          )}
        </Stack>
        <Stack spacing={8}>
          <Radio
            value="NOMINATED_VESSEL"
            label={t('labels.NOMINATED_VESSEL')}
            data-testid="vesselInformation-nominatedVessel"
            onClick={clearTbnFields}
          />
          {type === 'NOMINATED_VESSEL' && (
            <TextInput
              label={t('labels.imoNumber')}
              placeholder={t('placeholders.id')}
              withAsterisk={false}
              horizontal
              data-testid="vesselInformation-nominatedVessel-IMO"
              {...getInputProps('vessel.IMO')}
            />
          )}
        </Stack>
      </Radio.Group>

      <Grid>
        <Grid.Col span={12} md={6}>
          <TextInput
            horizontal
            label={t('labels.vesselOwnerName')}
            placeholder={t('placeholders.id')}
            styles={{ wrapper: { width: '100%' } }}
            data-testid="vesselInformation-vesselOwnerName"
            {...getInputProps('vessel.ownerName')}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <TextInput
            horizontal
            label={t('labels.vesselOwnerAddress')}
            placeholder={t('placeholders.id')}
            styles={{ wrapper: { width: '100%' } }}
            withAsterisk={false}
            data-testid="vesselInformation-vesselOwnerAddress"
            {...getInputProps('vessel.ownerAddress')}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <TextInput
            horizontal
            label={t('labels.VATnumber')}
            placeholder={t('placeholders.id')}
            styles={{ wrapper: { width: '100%' } }}
            withAsterisk={false}
            data-testid="vesselInformation-VAT"
            {...getInputProps('vessel.ownerVat')}
          />
        </Grid.Col>

        <Grid.Col span={12}>
          <Divider />
        </Grid.Col>

        <Grid.Col span={6} md={4} lg={2}>
          <Select
            label={t('labels.gears')}
            data={masterdata.gears}
            withAsterisk={false}
            data-testid="vesselInformation-gears"
            {...getInputProps('vessel.gears')}
          />
        </Grid.Col>
        <Grid.Col span={6} md={4} lg={2}>
          <NumberInput
            label={t('labels.cranesNumber')}
            min={0}
            withAsterisk={false}
            data-testid="vesselInformation-cranesNumber"
            {...getInputProps('vessel.cranesNumber')}
          />
        </Grid.Col>
        <Grid.Col span={6} md={4} lg={2}>
          <NumberInput
            label={t('labels.cranesCapacity')}
            rightSection="mts"
            min={0}
            {...getFixedNumberProps(1)}
            withAsterisk={false}
            data-testid="vesselInformation-cranesCapacity"
            {...getInputProps('vessel.cranesCapacity')}
          />
        </Grid.Col>
        <Grid.Col span={6} md={4} lg={2}>
          <Select
            label={t('labels.grabs')}
            data={masterdata.freightGrabs}
            withAsterisk={false}
            min={0}
            data-testid="vesselInformation-grabs"
            {...getInputProps('vessel.grabs')}
          />
        </Grid.Col>
        <Grid.Col span={6} md={4} lg={2}>
          <NumberInput
            label={t('labels.grabsNumber')}
            min={0}
            withAsterisk={false}
            data-testid="vesselInformation-grabsNumber"
            {...getInputProps('vessel.grabsNumber')}
          />
        </Grid.Col>
        <Grid.Col span={6} md={4} lg={2}>
          <NumberInput
            label={t('labels.grabCapacity')}
            min={0}
            {...getFixedNumberProps(1)}
            rightSection="cbm"
            withAsterisk={false}
            data-testid="vesselInformation-grabsCapacity"
            {...getInputProps('vessel.grabCapacity')}
          />
        </Grid.Col>
      </Grid>
    </>
  );
}

import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef } from 'react';

import { useWarningOnExit } from './use-warning-on-exit';

export interface UsePopupOnNavigationChangeProps {
  displayPopup: boolean;
  alertMessage: string;
  isModalOpened: boolean;
  toggleModal: VoidFunction;
  onConfirm: VoidFunction;
}

export const usePopupOnNavigationChange = (props: UsePopupOnNavigationChangeProps) => {
  const { displayPopup, toggleModal, onConfirm, alertMessage, isModalOpened } = props;
  const router = useRouter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const historyRef = useRef<any>(history.state);
  useWarningOnExit(alertMessage, {
    disabled: !displayPopup,
    onBeforeUnload: () => {
      if (!isModalOpened) toggleModal();
    },
  });
  const initRoute = useRef<string>(router.asPath);
  const nextRoute = useRef<string>(router.asPath);

  useEffect(() => {
    if (displayPopup) {
      router.beforePopState((newState) => {
        nextRoute.current = newState.as;
        if (newState.as !== router.asPath) {
          history.pushState(historyRef.current.url, '', historyRef.current.url);
          router.push(historyRef.current.url, historyRef.current.as);

          return false;
        }
        return true;
      });

      return () => {
        router.beforePopState(() => true);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleModal, displayPopup]);

  const onRouteChangeStart = useCallback(
    (url: string) => {
      const isNextRouteSameAsCurrent = url === historyRef.current.url;
      if (!isNextRouteSameAsCurrent) nextRoute.current = url;
      if (!displayPopup) return;

      toggleModal();
      router.events.emit('routeChangeError');
      throw 'cancelRouteChange';
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toggleModal, displayPopup]
  );

  const removeListener = useCallback(() => {
    router.events.off('routeChangeStart', onRouteChangeStart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRouteChangeStart]);

  const onConfirmRouteChange = () => {
    removeListener();
    onConfirm();
    const isNextRouteSameAsCurrent = nextRoute.current === historyRef.current.url;
    isNextRouteSameAsCurrent ? toggleModal() : router.push(nextRoute.current);
  };

  const onPopupReject = () => {
    removeListener();
    const isNextRouteSameAsCurrent = nextRoute.current === historyRef.current.url;
    if (!isNextRouteSameAsCurrent) {
      router.push(nextRoute.current);
    } else {
      toggleModal();
    }
    nextRoute.current = initRoute.current;
  };

  useEffect(() => {
    router.events.on('routeChangeStart', onRouteChangeStart);

    return removeListener;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRouteChangeStart, removeListener]);

  return {
    onConfirmRouteChange,
    onPopupReject,
  };
};

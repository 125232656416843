import {
  useGetFilteredCompanyPlants,
  UseGetFilteredCompanyPlantsFilters,
} from '@/common/api-utils/use-filter-company-plants';
import { useMapPlantsToSelectData } from '@/common/mapping-helpers';

// TODO
// This useProductFilters = true is a temporary solution, should be removed in the future
export function useGetCompanyPlantsFilteredSelectItems(
  companyId: string,
  filters: UseGetFilteredCompanyPlantsFilters,
  useProductFilter = true
) {
  const { byProductId, byType } = filters;
  const { data: filteredPlants } = useGetFilteredCompanyPlants(
    companyId,
    {
      byProductId,
      byType,
    },
    useProductFilter
  );

  return useMapPlantsToSelectData(filteredPlants);
}

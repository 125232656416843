/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CompanyUserStatus = (typeof CompanyUserStatus)[keyof typeof CompanyUserStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyUserStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
} as const;

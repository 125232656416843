import { useTranslation } from 'next-i18next';

import { BlinkBadge } from './BlinkBadge';

export function PrivateBadge() {
  const { t } = useTranslation('common');

  return (
    <BlinkBadge color="blue" showLogo={false}>
      {t('badges.companyInfo.PRIVATE')}
    </BlinkBadge>
  );
}

import { ReactNode } from 'react';
import { Group } from '@mantine/core';

import { Text, TextSize } from '@/components';

interface LabeledValueProps {
  label: string;
  value: ReactNode;
  labelBold?: boolean;
  valueBold?: boolean;
  horizontal?: boolean;
  styleProps?: object;
  valueSize?: TextSize;
}

export function LabeledValue(props: LabeledValueProps) {
  const { label, value, horizontal = false, labelBold = true, valueBold = false, styleProps, valueSize = 'M' } = props;

  return (
    <Group spacing={4} sx={{ flexDirection: horizontal ? 'row' : 'column', alignItems: 'flex-start' }} {...styleProps}>
      <Text size="M" weight={labelBold ? 'bold' : 'inherit'}>
        {label}
        {horizontal && ':'}
      </Text>
      <Text size={valueSize} weight={valueBold ? 'bold' : 'inherit'}>
        {value}
      </Text>
    </Group>
  );
}

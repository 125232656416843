import { CargoStatus } from 'api/generated/model';

import { NegotiationsRowData, RowData } from './types';

export const DATA: RowData[] = [
  {
    id: 'CREQ22',
    description: '50.00 MTS of clinker (-10%, +10%), ETA. July 23/10, from Abidjan, firm',
    status: CargoStatus.TENTATIVE,
    negotationsCount: 23,
  },
  {
    id: 'CREQ23',
    description: '50.00 MTS of clinker (-10%, +10%), ETA. July 23/10, from Abidjan, firm',
    status: CargoStatus.FIRM,
    negotationsCount: 5,
  },
  {
    id: 'CREQ78',
    description: '50.00 MTS of clinker (-10%, +10%), ETA. July 23/10, from Abidjan, firm',
    status: CargoStatus.TENTATIVE,
    negotationsCount: 25,
  },
  {
    id: 'CREQ44',
    description: '50.00 MTS of clinker (-10%, +10%), ETA. July 23/10, from Abidjan, firm',
    status: CargoStatus.TENTATIVE,
    negotationsCount: 12,
  },
  {
    id: 'CREQ617',
    description: '50.00 MTS of clinker (-10%, +10%), ETA. July 23/10, from Abidjan, firm',
    status: CargoStatus.TENTATIVE,
    negotationsCount: 6,
  },
];

export const SALES_DATA: RowData[] = [
  {
    id: 'CREQ89',
    description: '50.00 MTS of clinker (-10%, +10%), ETA. July 23/10, from Abidjan, firm',
    status: CargoStatus.FIRM,
    negotationsCount: 5,
  },
  {
    id: 'CREQ90',
    description: '75.00 MTS of clinker (-10%, +10%), ETA. July 23/10, from Abidjan, firm',
    status: CargoStatus.FIRM,
    negotationsCount: 8,
  },
  {
    id: 'CREQ99',
    description: '50.00 MTS of clinker (-10%, +10%), ETA. July 23/10, from Abidjan, firm',
    status: CargoStatus.TENTATIVE,
    negotationsCount: 14,
  },
  {
    id: 'CREQ919',
    description: '30.00 MTS of clinker (-10%, +10%), ETA. July 23/10, from Abidjan, firm',
    status: CargoStatus.TENTATIVE,
    negotationsCount: 21,
  },
  {
    id: 'CREQ567',
    description: '20.00 MTS of clinker (-10%, +10%), ETA. July 23/10, from Abidjan, firm',
    status: CargoStatus.FIRM,
    negotationsCount: 16,
  },
];

export const SELECTS = ['cargoId', 'negotiationStatus', 'eta', 'plant', 'port', 'product', 'transportation'];

export const NEGOTIATIONS_TABLE_DATA: NegotiationsRowData[] = [
  {
    id: 'id1',
    companyName: 'Company Name 1',
    relatedCargoId: 'CREQ22',
    cargoDescription: '50.00 MTS of clinker (-10%, +10%), ETA. July 23/10, from Abidjan, firm',
    negotiation: 'counterofferSent',
    expires: '2022-12-13T19:27:50.036Z',
  },
  {
    id: 'id2',
    companyName: 'Company Name 2',
    relatedCargoId: 'CREQ22',
    cargoDescription: '50.00 MTS of clinker (-10%, +10%), ETA. July 23/10, from Abidjan, firm',
    negotiation: 'requestReceived',
    expires: '2022-12-13T19:27:50.036Z',
  },
  {
    id: 'id3',
    companyName: 'Company Name 3',
    relatedCargoId: 'CREQ78',
    cargoDescription: '50.00 MTS of clinker (-10%, +10%), ETA. July 23/10, from Abidjan, firm',
    negotiation: 'requestReceived',
    expires: '2022-12-13T19:27:50.036Z',
  },
  {
    id: 'id4',
    companyName: 'Company Name 4',
    relatedCargoId: 'CREQ48',
    cargoDescription: '50.00 MTS of clinker (-10%, +10%), ETA. July 23/10, from Abidjan, firm',
    negotiation: 'counterofferSent',
    expires: '2022-12-13T19:27:50.036Z',
  },
  {
    id: 'id5',
    companyName: 'Company Name 5',
    relatedCargoId: 'CREQ617',
    cargoDescription: '50.00 MTS of clinker (-10%, +10%), ETA. July 23/10, from Abidjan, firm',
    negotiation: 'counterofferReceived',
    expires: '2022-12-13T19:27:50.036Z',
  },
];

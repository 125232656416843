/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type ActivityType = (typeof ActivityType)[keyof typeof ActivityType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActivityType = {
  IMPORT: 'IMPORT',
  EXPORT: 'EXPORT',
  IMPORT_AND_EXPORT: 'IMPORT_AND_EXPORT',
} as const;

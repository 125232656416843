import { Button, createStyles, Group, Radio, Stack, Textarea } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { RiEyeOffFill } from 'react-icons/ri';
import { useForm, zodResolver } from '@mantine/form';
import { CreateCargoFeedbackDTO } from 'api/generated/model';

import { Text } from '@/components';

import { FeedbackReason, schemaPlatformFeedback, StepFunction } from '../types';

const useStyles = createStyles((theme) => ({
  icon: {
    color: theme.colors.red[9],
    fontSize: 14,
    flex: '1 0 14px',
  },
  group: {
    backgroundColor: theme.colors.gray[0],
    padding: 4,
    border: `1px solid ${theme.colors.gray[1]}`,
  },
  label: {
    display: 'block',
  },
}));

export interface PlatformFeedbackFormProps {
  onBackClick: StepFunction;
  onNextClick: StepFunction;
  formState: CreateCargoFeedbackDTO;
  submitting: boolean;
}

export function PlatformFeedbackForm(props: PlatformFeedbackFormProps) {
  const { onBackClick, onNextClick, formState, submitting } = props;
  const { classes } = useStyles();
  const { t } = useTranslation('myCargos');
  const form = useForm({
    initialValues: formState,
    validate: zodResolver(schemaPlatformFeedback),
  });

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        if (form.validate().hasErrors) return;
        onNextClick(values);
      })}
    >
      <Stack spacing={24}>
        <Group className={classes.group} align="baseline" noWrap spacing={8}>
          <RiEyeOffFill className={classes.icon} />
          <Text size="S" color="gray.7" sx={{ wordBreak: 'break-word' }}>
            {t('modals.cancel.descriptions.information')}
          </Text>
        </Group>
        <Radio.Group {...form.getInputProps('feedbackType')}>
          {Object.values(FeedbackReason).map((reason) => (
            <Radio
              key={reason}
              classNames={{ label: classes.label }}
              label={t(`modals.cancel.labels.${reason}`)}
              value={reason}
            />
          ))}
        </Radio.Group>
        {(form.values as any).feedbackType === FeedbackReason.OTHER_REASON ? (
          <Textarea
            mt={4}
            placeholder={t('modals.cancel.placeholders.provideReason')}
            {...form.getInputProps('feedbackComment')}
          />
        ) : (
          <Textarea
            label={t('modals.cancel.labels.comments')}
            inputWrapperOrder={['label', 'description', 'input', 'error']}
            description={t('modals.cancel.descriptions.additionalDetails')}
            {...form.getInputProps('feedbackComment')}
            mt={12}
            withAsterisk
          />
        )}
        <Group grow>
          <Button onClick={() => onBackClick(form.values)} variant="outline">
            {t('modals.cancel.buttons.back')}
          </Button>
          <Button loading={submitting} type="submit">
            {t('modals.cancel.buttons.confirm')}
          </Button>
        </Group>
      </Stack>
    </form>
  );
}

import { ActionIcon, Button, createStyles, FileInput, Flex, TextInput } from '@mantine/core';
import sendbirdSelectors from '@sendbird/uikit-react/sendbirdSelectors';
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { RiFileLine, RiSendPlaneFill } from 'react-icons/ri';

import { useMediaQuery } from '@/core/hooks/use-media-query';
import { isIosSafari } from '@/common/is-ios-safari';

import { usePreventScrolling } from './usePreventScrolling';

const useStyles = createStyles({
  input: {
    width: '100%',
  },
  icon: {
    transform: 'translateX(12px)',
  },
});

export function SendbirdMessageInput(props) {
  const { channelURL } = props;
  const { t } = useTranslation('myCargos');
  const [message, setMessage] = useState('');
  const { classes, theme } = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.md);
  const context = useSendbirdStateContext();
  const sendMessage = sendbirdSelectors.getSendUserMessage(context);
  const sendFileMessage = sendbirdSelectors.getSendFileMessage(context);
  const channel = sendbirdSelectors.getGetGroupChannel(context);

  const isIPhone = isIosSafari();
  const [lockScroll, unlockScroll] = usePreventScrolling();

  const uploadFile = (files: File[]) => {
    channel(channelURL).then((currentChannel) => {
      for (const file of files) {
        sendFileMessage(currentChannel, {
          file,
        });
      }
    });
  };

  const send = () => {
    if (message !== '') {
      channel(channelURL).then((currentChannel) => {
        sendMessage(currentChannel, {
          message,
        });
      });
      setMessage('');
    }
  };

  const onEnter = (event) => {
    if (event.key === 'Enter') {
      send();
    }
  };

  return (
    <Flex
      p={16}
      pb={0}
      gap={{ base: 8, md: 16 }}
      align="center"
      sx={{
        position: 'relative',
        width: '100%',
      }}
    >
      <TextInput
        placeholder={t('chat.inputPrompt')}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        sx={{ height: 'auto', flexGrow: 1 }}
        className={classes.input}
        size="lg"
        onKeyDown={onEnter}
        inputMode="search"
        onFocus={() => {
          if (isIPhone) {
            lockScroll();
          }
        }}
        onBlur={() => {
          if (isIPhone) {
            unlockScroll();
          }
        }}
      />
      <FileInput
        classNames={{ icon: classes.icon }}
        icon={<RiFileLine />}
        iconWidth={30}
        size="lg"
        sx={{}}
        placeholder={null}
        valueComponent={() => <></>}
        onChange={uploadFile}
        multiple={true}
        pl={0}
        accept="image/*,video/*,audio/*, .doc, .pdf, .docx, .xls, .xlsx, .ppt"
      />
      {isMobile ? (
        <ActionIcon color="primary.1" variant="transparent" onClick={send}>
          <RiSendPlaneFill size={24} />
        </ActionIcon>
      ) : (
        <Button size="lg" onClick={send} sx={{ maxWidth: 90 }} p={16}>
          {t('chat.send')}
        </Button>
      )}
    </Flex>
  );
}

import { ActionIcon, Button, Group, Paper, useMantineTheme } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useQueryClient } from '@tanstack/react-query';
import {
  getGetCargoNegotiationHistoryQueryKey,
  useAcceptExtendCargoExpiryRequest,
  useGetCargoNegotiationHistory,
  useRequestCargoExpiryExtension,
} from 'api/generated/cargo-negotiation/cargo-negotiation';
import { CompanyType, UserRole } from 'api/generated/model';
import { useTranslation } from 'next-i18next';
import { ReactElement } from 'react';
import { RiArrowLeftSLine } from 'react-icons/ri';

import {
  AppPageContentContainer,
  ExpireBadgeWithButton,
  GrantExtendModal,
  RequestExtendModal,
  Title,
} from '@/components';
import { useStatusNegotiationModal } from '@/views/cargo-cancelation/state/CancelModalContext';
import { OfferType } from '@/views/my-cargos';
import { useExtendDeadlineErrors } from '@/views/my-cargos/components/state/use-extend-deadline-errors';

interface RecapFormLayoutProps {
  allowedCompanyTypes: CompanyType[];
  materialForm: ReactElement;
  onLoadTemplate?: VoidFunction;
  heading: string;
  onBackClick: VoidFunction;
  offerType?: OfferType;
  negotiationId?: string;
  isSeller?: boolean;
}

export function RecapFormLayout(props: RecapFormLayoutProps) {
  const {
    materialForm,
    onLoadTemplate,
    offerType,
    isSeller,
    negotiationId,
    heading,
    onBackClick,
    allowedCompanyTypes,
  } = props;
  const { t } = useTranslation('recapForm');

  const { extendRequestError } = useExtendDeadlineErrors();
  const queryClient = useQueryClient();
  const theme = useMantineTheme();

  const onExpireBadgeClick = () => {
    isSeller ? toggleExtend(negotiationId) : toggleRequestExtension(negotiationId);
  };

  const negotiation = useGetCargoNegotiationHistory(negotiationId, {
    query: { select: (response) => response.data },
  });

  const onExtendSuccess = () => {
    queryClient.invalidateQueries(getGetCargoNegotiationHistoryQueryKey(negotiationId));
    onExpireBadgeClick();
  };

  const { isExtendModalOpened, isRequestExtensionModalOpened, toggleExtend, toggleRequestExtension } =
    useStatusNegotiationModal();

  const requestCargoExpiryExtension = useRequestCargoExpiryExtension({
    mutation: {
      onSuccess: () => {
        showNotification({
          color: 'green',
          message: t('notifications.requestCargoExtension'),
          title: undefined,
        });
        onExtendSuccess();
      },
      onError: (error) => {
        extendRequestError(error);
        toggleExtend(negotiationId);
      },
    },
  });

  const extendCargoExpiryDate = useAcceptExtendCargoExpiryRequest({
    mutation: {
      onSuccess: () => {
        showNotification({
          color: 'green',
          message: t('notifications.cargoDeadlineExtended'),
          title: undefined,
        });
        onExtendSuccess();
      },
      onError: (error) => {
        extendRequestError(error);
        toggleExtend(negotiationId);
      },
    },
  });

  const isExpiryExtensionRequested = negotiation.data?.expiryExtendRequest.at(-1)?.status === 'REQUESTED';

  return (
    <AppPageContentContainer
      allowUnverifiedCompany
      allowedRoles={[UserRole.ADMIN, UserRole.SELLER, UserRole.BUYER]}
      allowedCompanyTypes={allowedCompanyTypes}
      additionalHeader={
        <Paper px={{ base: 20, md: 24 }} py={{ base: 16, md: 20 }}>
          <Group maw={1232} mx="auto" position="apart" align="center">
            <Group spacing={8}>
              <ActionIcon variant="transparent" onClick={onBackClick}>
                <RiArrowLeftSLine size={24} color={theme.fn.primaryColor()} style={{ transform: 'translateY(2px)' }} />
              </ActionIcon>
              <Title size="XL" order={1}>
                {heading}
              </Title>
            </Group>
            <Group spacing={20}>
              {!negotiation.isLoading && negotiation?.data.negotiationHistory[0].expiresAt && (
                <ExpireBadgeWithButton
                  expiresAt={negotiation?.data.negotiationHistory[0].expiresAt}
                  onClick={onExpireBadgeClick}
                  isSeller={offerType === 'offer'}
                  isRequested={isExpiryExtensionRequested}
                />
              )}
              {onLoadTemplate && (
                <Button variant="outline" size="sm" onClick={onLoadTemplate}>
                  {t('buttons.loadTemplate')}
                </Button>
              )}
            </Group>
          </Group>
        </Paper>
      }
      headersSize={72}
      pb={{ base: 32, md: 80 }}
    >
      {materialForm}
      <RequestExtendModal
        opened={isRequestExtensionModalOpened}
        toggle={() => toggleRequestExtension(negotiationId)}
        loading={requestCargoExpiryExtension.isLoading}
        onConfirm={() => requestCargoExpiryExtension.mutate({ negotiationId })}
      />
      <GrantExtendModal
        opened={isExtendModalOpened}
        toggle={() => toggleExtend(negotiationId)}
        loading={extendCargoExpiryDate.isLoading}
        onConfirm={() => extendCargoExpiryDate.mutate({ negotiationId })}
        isRequested={isExpiryExtensionRequested}
      />
    </AppPageContentContainer>
  );
}

import { Grid, Group } from '@mantine/core';
import { Currency } from 'api/generated/model';
import { useTranslation } from 'next-i18next';

import { Text } from '@/components';
import { NumberInput, Select, TextInput } from '@/views/recap-form';
import { getFixedNumberProps } from '@/common/get-fixed-number-props';
import { formatCurrency } from '@/common/format-currency';

import { useFreightRecapFormState } from '../state/FreightRecapFormProvider';

export function FreightRecapFormPricingGroup() {
  const { t, i18n } = useTranslation('freightRecapForm');
  const { form, getInputProps } = useFreightRecapFormState();
  const { price, currency } = form.values.price;

  return (
    <>
      <Grid gutter={16} mb={12}>
        <Grid.Col span={8} md={4}>
          <NumberInput
            label={t('labels.freightPrice')}
            placeholder={t('placeholders.freightPrice')}
            min={0}
            {...getFixedNumberProps(2)}
            size="md"
            data-testid="pricing-price"
            {...getInputProps('price.price')}
          />
        </Grid.Col>

        <Grid.Col span={4} md={2}>
          <Select
            defaultValue={Currency.USD}
            label={t('labels.currency')}
            data={Object.values(Currency).sort()}
            size="md"
            data-testid="pricing-currency"
            {...getInputProps('price.currency')}
          />
        </Grid.Col>

        <Grid.Col span={12}>
          {price && (
            <Group spacing={16}>
              <Text size="XL" weight={600}>
                {t('labels.total')}
              </Text>
              <Text size="XL" weight={600}>
                {formatCurrency(price, currency, i18n.language, 2)}
              </Text>
            </Group>
          )}
        </Grid.Col>

        <Grid.Col span={12} md={6}>
          <TextInput
            label={t('labels.priceComments')}
            placeholder={t('placeholders.priceComments')}
            withAsterisk={false}
            size="md"
            data-testid="pricing-comments"
            {...getInputProps('price.comments')}
          />
        </Grid.Col>
      </Grid>
    </>
  );
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type GetSharedCargoItemResponseDtoCargoInformationDtoCargoDtoCompanyPortWithPortDtoFreeDA =
  (typeof GetSharedCargoItemResponseDtoCargoInformationDtoCargoDtoCompanyPortWithPortDtoFreeDA)[keyof typeof GetSharedCargoItemResponseDtoCargoInformationDtoCargoDtoCompanyPortWithPortDtoFreeDA];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSharedCargoItemResponseDtoCargoInformationDtoCargoDtoCompanyPortWithPortDtoFreeDA = {
  YES: 'YES',
  NO: 'NO',
} as const;

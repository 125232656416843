/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type PriceUnit = (typeof PriceUnit)[keyof typeof PriceUnit];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PriceUnit = {
  CFR: 'CFR',
  WEIGHT: 'WEIGHT',
} as const;

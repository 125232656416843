import { FreightRecapForm } from '@/blink-forms';
import { RecapFormMasterdataTranslations } from '@/views/recap-form/state/use-recap-form-masterdata';
import { fractionToPercentage, transformToPercentage } from '@/common/percentage-transform';
import { formatDate } from '@/common/format-date';

const selector =
  <T>(
    selectorFn: (
      form: FreightRecapForm,
      masterdataTranslations?: RecapFormMasterdataTranslations,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      additionalData?: any
    ) => T
  ) =>
  (
    form: FreightRecapForm | undefined,
    masterdataTranslations?: RecapFormMasterdataTranslations,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    additionalData?: any
  ) =>
    form ? selectorFn(form, masterdataTranslations, additionalData) : undefined;

export const toVesselOwnerArgs = selector(({ vessel }) => ({
  name: vessel.ownerName,
  address: vessel.ownerAddress,
  vat: vessel.ownerVat,
}));

export const toPriceArgs = selector(({ price }) => ({
  price: price.price,
  currency: price.currency,
  priceComments: price.comments,
}));

export const toCargoArgs = selector(({ product }, translations) => ({
  quantity: product.quantity,
  maxTolerance: transformToPercentage(product.maxTolerance),
  minTolerance: transformToPercentage(product.minTolerance),
  whoseOption: translations.freightToleranceOptions[product.option],
  commodity: product.name,
  packaging: product.packaging,
  quality: product.grade,
}));

export const toLaycanArgs = selector(({ product }) => ({
  laycanStart: formatDate(product.laycan[0]),
  laycanEnd: formatDate(product.laycan[1]),
}));

export const toVesselTbnArgs = selector(({ vessel }) => ({
  days: vessel.nominationDays,
  maxAge: vessel.maxAge,
}));

export const toVesselNominatedArgs = selector(({ vessel }) => ({
  imo: vessel.IMO,
}));

export const toVesselInformationArgs = selector(({ vessel }) => ({
  cranesNumber: vessel.cranesNumber,
  cranesCapacity: vessel.cranesCapacity,
  grabsNumber: vessel.grabsNumber,
  grabCapacity: vessel.grabCapacity,
}));

export const toLoadingPortArgs = selector(({ product }) => ({
  loadingPort: product.loadingPort,
}));

export const toLoadingConditionsArgs = selector(({ loadingConditions, product }, translations, additionalData) => ({
  safePortNo: loadingConditions.safePortNumber,
  safeBerthNo: loadingConditions.safeBerthNumber,
  berthConditions: loadingConditions.berthConditions,
  loadingPort: product.loadingPort,
  freshWaterSaltWater: translations.freshWaterSaltWater[additionalData.freshWaterSaltWater],
  portDraft: product.loadingPortGuaranteedDraft,
  portLoa: product.loadingPortGuaranteedLOA,
  portBeam: product.loadingPortGuaranteedBeam,
  otherRestrictions: additionalData.otherRestrictions,
  loadingRate: loadingConditions.freightRate,
  loadingPeriodTerms: translations.freightPeriodTerms[loadingConditions.freightPortPeriodTerms],
  loadingTerms: loadingConditions.freightRateTerms,
  turnTime: translations.turnTimes[loadingConditions.freightTurnTimeTerms],
  turnTimeHours: loadingConditions.freightTurnTime,
  norTendering: loadingConditions.freightNORTendering,
  exclusionFromLayTime: loadingConditions.freightExclusionFormLaytime
    ?.map((exclusionFromLaytime) => translations.exclusionFromLaytime[exclusionFromLaytime])
    .join(', '),
  freightExclusionFormLaytimeOther: loadingConditions.freightExclusionFormLaytimeOther,
  vesselPda: translations.freightPdas[loadingConditions.freightVesselPDA],
  cargoPda: translations.freightPdas[loadingConditions.freightCargoPDA],
  other: loadingConditions.freightPDAOther,
}));

export const toDischargingPortArgs = selector(({ product }) => ({
  dischargingPort: product.dischargingPort,
}));

export const toDischargingConditionsArgs = selector(
  ({ dischargingConditions, product }, translations, additionalData) => ({
    safePortNo: dischargingConditions.safePortNumber,
    safeBerthNo: dischargingConditions.safeBerthNumber,
    berthConditions: dischargingConditions.berthConditions,
    dischargingPort: product.dischargingPort,
    freshWaterSaltWater: translations.freshWaterSaltWater[additionalData.freshWaterSaltWater],
    portDraft: product.dischargingPortGuaranteedDraft,
    portLoa: product.dischargingPortGuaranteedLOA,
    portBeam: product.dischargingPortGuaranteedBeam,
    otherRestrictions: additionalData.otherRestrictions,
    dischargingRate: dischargingConditions.freightRate,
    dischargingPeriodTerms: translations.freightPeriodTerms[dischargingConditions.freightPortPeriodTerms],
    dischargingTerms: dischargingConditions.freightRateTerms,
    turnTime: translations.turnTimes[dischargingConditions.freightTurnTimeTerms],
    turnTimeHours: dischargingConditions.freightTurnTime,
    norTendering: dischargingConditions.freightNORTendering,
    exclusionFromLayTime: dischargingConditions.freightExclusionFormLaytime
      ?.map((exclusionFromLaytime) => translations.exclusionFromLaytime[exclusionFromLaytime])
      .join(', '),
    freightExclusionFormLaytimeOther: dischargingConditions.freightExclusionFormLaytimeOther,
    vesselPda: translations.freightPdas[dischargingConditions.freightVesselPDA],
    cargoPda: translations.freightPdas[dischargingConditions.freightCargoPDA],
    other: dischargingConditions.freightPDAOther,
  })
);

export const toAgentsArgs = selector(({ nomination }, translations) => ({
  agentsAtLoadingPort: nomination.agentAtLoadingPort,
  agentAtLoadingPortConditions: translations.freightAgentsConditions[nomination.agentAtLoadingPortConditions],
  agentsAtDischargingPort: nomination.agentAtDischargingPort,
  agentAtDischargingPortConditions: translations.freightAgentsConditions[nomination.agentAtDischargingPortConditions],
}));

export const toPrepaymentTermsArgs = selector(({ payment }, translations) =>
  payment.conditions.TTPrepayment
    ? {
        percentage: fractionToPercentage(payment.conditions.TTPrepayment.percentagePrepayement),
        days: payment.conditions.TTPrepayment.daysPrepayment,
        event: translations.prepaymentEvents[payment.conditions.TTPrepayment.eventPrepayment],
      }
    : undefined
);

export const toAfterLoadingTermsArgs = selector(({ payment }) =>
  payment.conditions.TTAfterLoading
    ? {
        percentage: fractionToPercentage(payment.conditions.TTAfterLoading.percentageAfterLoading),
        days: payment.conditions.TTAfterLoading.daysAfterLoading,
      }
    : undefined
);

export const toBeforeBreakingBullTermsArgs = selector(({ payment }) =>
  payment.conditions.TTBeforeBreakingBulk
    ? {
        percentage: fractionToPercentage(payment.conditions.TTBeforeBreakingBulk.percentageBeforeBreakingBulk),
        days: payment.conditions.TTBeforeBreakingBulk.daysBeforeBreakingBulk,
      }
    : undefined
);

export const toOpenTermsArgs = selector(({ payment }, translations) =>
  payment.conditions.TTOpenTerms
    ? {
        percentage: fractionToPercentage(payment.conditions.TTOpenTerms.percentageOpenTerms),
        days: payment.conditions.TTOpenTerms.daysOpenTerms,
        event: translations.openAccountEvents[payment.conditions.TTOpenTerms.EventOpenTerms],
      }
    : undefined
);

export const toDemurrageRateArgs = selector(({ payment }, translations) => ({
  event: translations.demurrageEvents[payment.event],
  rateCondition: translations.demurrageRateConditions[payment.rateType],
  rate: payment.rate,
}));

export const toDemurrageEventArgs = selector(({ payment }, translations) => ({
  event: translations.demurrageEvents[payment.type],
  maxDays: payment.openAccountMaxDays,
}));

export const toLaytimeCalculationsArgs = selector(({ payment }, translations) =>
  payment.laytimeCalculation?.length
    ? {
        laytimeCalculations: payment.laytimeCalculation
          ?.map((calculation) => translations.freightLaytimeCalculations[calculation])
          .join(', '),
      }
    : undefined
);

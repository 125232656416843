/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type OfferPurpose = (typeof OfferPurpose)[keyof typeof OfferPurpose];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OfferPurpose = {
  BUY: 'BUY',
  SELL: 'SELL',
} as const;

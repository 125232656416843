/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CounterpartyShortlistedDTOType =
  (typeof CounterpartyShortlistedDTOType)[keyof typeof CounterpartyShortlistedDTOType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CounterpartyShortlistedDTOType = {
  SHORTLISTED: 'SHORTLISTED',
} as const;

import { ReactNode } from 'react';
import { Button, createStyles, Group, Paper, Stack } from '@mantine/core';
import { useTranslation } from 'next-i18next';

import { Title } from '@/components';

export interface FiltersProps {
  resetFilters: VoidFunction;
  className?: string;
  filtersCount: number;
  onDone: VoidFunction;
  children: ReactNode;
}

const useStyles = createStyles((theme) => ({
  paper: {
    backgroundColor: theme.colors.gray[1],
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: 8,
    padding: 16,

    [theme.fn.smallerThan('xs')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  slider: {
    paddingBottom: 24,
  },
  markLabel: {
    marginTop: 2,
  },
  stack: {
    marginTop: 8,
  },
  checkbox: {
    borderRadius: 2,
    height: 20,
    fontWeight: 500,
  },
  hideOnMobile: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
      pointerEvents: 'none',
    },
  },
  hideOnDesktop: {
    [theme.fn.largerThan('md')]: {
      display: 'none',
      pointerEvents: 'none',
    },
  },
  stickyPaper: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: theme.white,
    padding: 16,
    margin: '16px -24px -16px -24px',
    borderTop: `1px solid ${theme.colors.gray[3]}`,
  },
}));

export function Filters(props: FiltersProps) {
  const { className, resetFilters, filtersCount, onDone, children } = props;
  const { t } = useTranslation('common');

  const { classes, cx } = useStyles();

  return (
    <Paper className={cx(classes.paper, className)}>
      <form>
        <Group position="apart">
          <Title order={2} size="XL">
            {t('labels.filters', { count: filtersCount })}
          </Title>
          <Button className={classes.hideOnMobile} size="sm" variant="outline" onClick={resetFilters}>
            {t('buttons.clearAll')}
          </Button>
        </Group>

        <Stack spacing={16} mt={24}>
          {children}
        </Stack>
        <Paper className={cx(classes.stickyPaper, classes.hideOnDesktop)}>
          <Button size="sm" variant="outline" fullWidth onClick={resetFilters} mb={8}>
            {t('buttons.clearAll')}
          </Button>
          <Button size="sm" fullWidth onClick={onDone}>
            {t('buttons.done')}
          </Button>
        </Paper>
      </form>
    </Paper>
  );
}

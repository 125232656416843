import { z } from 'zod';

import { DemurrageRateConditions, DemurrageEvents, DemurrageType } from '../../types';

export const demurrage = z
  .object({
    rate: z.number().min(1).optional(),
    rateCondition: z.nativeEnum(DemurrageRateConditions).optional().nullable(),
    rateEvent: z.nativeEnum(DemurrageEvents).optional().nullable(),
    type: z.nativeEnum(DemurrageType).optional().nullable(),
    openAccountMaxDays: z.number().optional(),
    openAccountMaxDaysEvent: z.string().min(1).optional().nullable(),
  })
  .refine(
    (data) => {
      if (data.type == 'OPEN_ACCOUNT') {
        return data.openAccountMaxDays !== undefined;
      }
      return true;
    },
    {
      message: 'This field is required',
      path: ['openAccountMaxDays'],
    }
  )
  .refine(
    (data) => {
      if (data.type == 'OPEN_ACCOUNT') {
        return data.openAccountMaxDaysEvent !== undefined && data.openAccountMaxDaysEvent !== null;
      }
      return true;
    },
    {
      message: 'This field is required',
      path: ['openAccountMaxDaysEvent'],
    }
  );

export type DemurrageSchema = z.infer<typeof demurrage>;

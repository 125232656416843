/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';

import type {
  CargoNegotiationsDTO,
  CargoOfferNegotiations,
  CargoNeedNegotiations,
  ApiErrorResponse,
  CreateCargoRfqDTO,
  CreateCargoNegotiationOfferDTO,
  NotFoundException,
  CargoNegotiation,
  CreateCargoCounterOfferDTO,
  CreateCargoFeedbackDTO,
  CargoNegotiationHistoryStepDTO,
  CargoNegotiationDTO,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getCompanyCargoNegotiations = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CargoNegotiationsDTO>> => {
  return axios.get(`/api/v1/cargo-negotiation`, options);
};

export const getGetCompanyCargoNegotiationsQueryKey = () => [`/api/v1/cargo-negotiation`];

export type GetCompanyCargoNegotiationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompanyCargoNegotiations>>
>;
export type GetCompanyCargoNegotiationsQueryError = AxiosError<void>;

export const useGetCompanyCargoNegotiations = <
  TData = Awaited<ReturnType<typeof getCompanyCargoNegotiations>>,
  TError = AxiosError<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getCompanyCargoNegotiations>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCompanyCargoNegotiationsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyCargoNegotiations>>> = ({ signal }) =>
    getCompanyCargoNegotiations({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCompanyCargoNegotiations>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getCompanyCargoOfferNegotiations = (
  offerId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CargoOfferNegotiations>> => {
  return axios.get(`/api/v1/cargo-negotiation/listings/${offerId}/negotiations`, options);
};

export const getGetCompanyCargoOfferNegotiationsQueryKey = (offerId: string) => [
  `/api/v1/cargo-negotiation/listings/${offerId}/negotiations`,
];

export type GetCompanyCargoOfferNegotiationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompanyCargoOfferNegotiations>>
>;
export type GetCompanyCargoOfferNegotiationsQueryError = AxiosError<void>;

export const useGetCompanyCargoOfferNegotiations = <
  TData = Awaited<ReturnType<typeof getCompanyCargoOfferNegotiations>>,
  TError = AxiosError<void>
>(
  offerId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCompanyCargoOfferNegotiations>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCompanyCargoOfferNegotiationsQueryKey(offerId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyCargoOfferNegotiations>>> = ({ signal }) =>
    getCompanyCargoOfferNegotiations(offerId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCompanyCargoOfferNegotiations>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!offerId, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getCompanyCargoNeedNegotiations = (
  needId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CargoNeedNegotiations>> => {
  return axios.get(`/api/v1/cargo-negotiation/requests/${needId}/negotiations`, options);
};

export const getGetCompanyCargoNeedNegotiationsQueryKey = (needId: string) => [
  `/api/v1/cargo-negotiation/requests/${needId}/negotiations`,
];

export type GetCompanyCargoNeedNegotiationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompanyCargoNeedNegotiations>>
>;
export type GetCompanyCargoNeedNegotiationsQueryError = AxiosError<void>;

export const useGetCompanyCargoNeedNegotiations = <
  TData = Awaited<ReturnType<typeof getCompanyCargoNeedNegotiations>>,
  TError = AxiosError<void>
>(
  needId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCompanyCargoNeedNegotiations>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCompanyCargoNeedNegotiationsQueryKey(needId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyCargoNeedNegotiations>>> = ({ signal }) =>
    getCompanyCargoNeedNegotiations(needId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCompanyCargoNeedNegotiations>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!needId, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const postCargoProspects = (
  createCargoRfqDTO: CreateCargoRfqDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/v1/cargo-negotiation/negotiations/rfq`, createCargoRfqDTO, options);
};

export type PostCargoProspectsMutationResult = NonNullable<Awaited<ReturnType<typeof postCargoProspects>>>;
export type PostCargoProspectsMutationBody = CreateCargoRfqDTO;
export type PostCargoProspectsMutationError = AxiosError<ApiErrorResponse>;

export const usePostCargoProspects = <TError = AxiosError<ApiErrorResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCargoProspects>>,
    TError,
    { data: CreateCargoRfqDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCargoProspects>>, { data: CreateCargoRfqDTO }> = (
    props
  ) => {
    const { data } = props ?? {};

    return postCargoProspects(data, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof postCargoProspects>>, TError, { data: CreateCargoRfqDTO }, TContext>(
    mutationFn,
    mutationOptions
  );
};
export const postCargoNegotiationOffer = (
  createCargoNegotiationOfferDTO: CreateCargoNegotiationOfferDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/v1/cargo-negotiation/negotiations/offer`, createCargoNegotiationOfferDTO, options);
};

export type PostCargoNegotiationOfferMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCargoNegotiationOffer>>
>;
export type PostCargoNegotiationOfferMutationBody = CreateCargoNegotiationOfferDTO;
export type PostCargoNegotiationOfferMutationError = AxiosError<ApiErrorResponse>;

export const usePostCargoNegotiationOffer = <TError = AxiosError<ApiErrorResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCargoNegotiationOffer>>,
    TError,
    { data: CreateCargoNegotiationOfferDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCargoNegotiationOffer>>,
    { data: CreateCargoNegotiationOfferDTO }
  > = (props) => {
    const { data } = props ?? {};

    return postCargoNegotiationOffer(data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof postCargoNegotiationOffer>>,
    TError,
    { data: CreateCargoNegotiationOfferDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const deleteCargoNegotiation = (
  negotiationId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<unknown>> => {
  return axios.delete(`/api/v1/cargo-negotiation/negotiations/${negotiationId}`, options);
};

export type DeleteCargoNegotiationMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCargoNegotiation>>>;

export type DeleteCargoNegotiationMutationError = AxiosError<void | NotFoundException>;

export const useDeleteCargoNegotiation = <TError = AxiosError<void | NotFoundException>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCargoNegotiation>>,
    TError,
    { negotiationId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCargoNegotiation>>, { negotiationId: string }> = (
    props
  ) => {
    const { negotiationId } = props ?? {};

    return deleteCargoNegotiation(negotiationId, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof deleteCargoNegotiation>>, TError, { negotiationId: string }, TContext>(
    mutationFn,
    mutationOptions
  );
};
export const getCargoNegotiationHistory = (
  negotiationId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CargoNegotiation>> => {
  return axios.get(`/api/v1/cargo-negotiation/negotiations/${negotiationId}`, options);
};

export const getGetCargoNegotiationHistoryQueryKey = (negotiationId: string) => [
  `/api/v1/cargo-negotiation/negotiations/${negotiationId}`,
];

export type GetCargoNegotiationHistoryQueryResult = NonNullable<Awaited<ReturnType<typeof getCargoNegotiationHistory>>>;
export type GetCargoNegotiationHistoryQueryError = AxiosError<void>;

export const useGetCargoNegotiationHistory = <
  TData = Awaited<ReturnType<typeof getCargoNegotiationHistory>>,
  TError = AxiosError<void>
>(
  negotiationId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCargoNegotiationHistory>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCargoNegotiationHistoryQueryKey(negotiationId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCargoNegotiationHistory>>> = ({ signal }) =>
    getCargoNegotiationHistory(negotiationId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCargoNegotiationHistory>>, TError, TData>(queryKey, queryFn, {
    enabled: !!negotiationId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const updateCSMUnlock = (
  negotiationId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CargoNegotiation>> => {
  return axios.patch(`/api/v1/cargo-negotiation/negotiations/${negotiationId}/csm-unlock`, undefined, options);
};

export type UpdateCSMUnlockMutationResult = NonNullable<Awaited<ReturnType<typeof updateCSMUnlock>>>;

export type UpdateCSMUnlockMutationError = AxiosError<void>;

export const useUpdateCSMUnlock = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCSMUnlock>>,
    TError,
    { negotiationId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCSMUnlock>>, { negotiationId: string }> = (
    props
  ) => {
    const { negotiationId } = props ?? {};

    return updateCSMUnlock(negotiationId, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof updateCSMUnlock>>, TError, { negotiationId: string }, TContext>(
    mutationFn,
    mutationOptions
  );
};
export const postCargoCounterOffer = (
  negotiationId: string,
  createCargoCounterOfferDTO: CreateCargoCounterOfferDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `/api/v1/cargo-negotiation/negotiations/${negotiationId}/counter-offer`,
    createCargoCounterOfferDTO,
    options
  );
};

export type PostCargoCounterOfferMutationResult = NonNullable<Awaited<ReturnType<typeof postCargoCounterOffer>>>;
export type PostCargoCounterOfferMutationBody = CreateCargoCounterOfferDTO;
export type PostCargoCounterOfferMutationError = AxiosError<ApiErrorResponse>;

export const usePostCargoCounterOffer = <TError = AxiosError<ApiErrorResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCargoCounterOffer>>,
    TError,
    { negotiationId: string; data: CreateCargoCounterOfferDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCargoCounterOffer>>,
    { negotiationId: string; data: CreateCargoCounterOfferDTO }
  > = (props) => {
    const { negotiationId, data } = props ?? {};

    return postCargoCounterOffer(negotiationId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof postCargoCounterOffer>>,
    TError,
    { negotiationId: string; data: CreateCargoCounterOfferDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const postCargoAccept = (negotiationId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/v1/cargo-negotiation/negotiations/${negotiationId}/accept`, undefined, options);
};

export type PostCargoAcceptMutationResult = NonNullable<Awaited<ReturnType<typeof postCargoAccept>>>;

export type PostCargoAcceptMutationError = AxiosError<ApiErrorResponse>;

export const usePostCargoAccept = <TError = AxiosError<ApiErrorResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCargoAccept>>,
    TError,
    { negotiationId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCargoAccept>>, { negotiationId: string }> = (
    props
  ) => {
    const { negotiationId } = props ?? {};

    return postCargoAccept(negotiationId, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof postCargoAccept>>, TError, { negotiationId: string }, TContext>(
    mutationFn,
    mutationOptions
  );
};
export const postCargoReject = (
  negotiationId: string,
  createCargoFeedbackDTO: CreateCargoFeedbackDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<unknown>> => {
  return axios.post(`/api/v1/cargo-negotiation/negotiations/${negotiationId}/reject`, createCargoFeedbackDTO, options);
};

export type PostCargoRejectMutationResult = NonNullable<Awaited<ReturnType<typeof postCargoReject>>>;
export type PostCargoRejectMutationBody = CreateCargoFeedbackDTO;
export type PostCargoRejectMutationError = AxiosError<ApiErrorResponse | void>;

export const usePostCargoReject = <TError = AxiosError<ApiErrorResponse | void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCargoReject>>,
    TError,
    { negotiationId: string; data: CreateCargoFeedbackDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCargoReject>>,
    { negotiationId: string; data: CreateCargoFeedbackDTO }
  > = (props) => {
    const { negotiationId, data } = props ?? {};

    return postCargoReject(negotiationId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof postCargoReject>>,
    TError,
    { negotiationId: string; data: CreateCargoFeedbackDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const postCargoCancel = (
  negotiationId: string,
  createCargoFeedbackDTO: CreateCargoFeedbackDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<unknown>> => {
  return axios.post(`/api/v1/cargo-negotiation/negotiations/${negotiationId}/cancel`, createCargoFeedbackDTO, options);
};

export type PostCargoCancelMutationResult = NonNullable<Awaited<ReturnType<typeof postCargoCancel>>>;
export type PostCargoCancelMutationBody = CreateCargoFeedbackDTO;
export type PostCargoCancelMutationError = AxiosError<ApiErrorResponse | void>;

export const usePostCargoCancel = <TError = AxiosError<ApiErrorResponse | void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCargoCancel>>,
    TError,
    { negotiationId: string; data: CreateCargoFeedbackDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCargoCancel>>,
    { negotiationId: string; data: CreateCargoFeedbackDTO }
  > = (props) => {
    const { negotiationId, data } = props ?? {};

    return postCargoCancel(negotiationId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof postCargoCancel>>,
    TError,
    { negotiationId: string; data: CreateCargoFeedbackDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const getCargoNegotiationHistoryStep = (
  negotiationId: string,
  historyStepId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CargoNegotiationHistoryStepDTO>> => {
  return axios.get(`/api/v1/cargo-negotiation/negotiations/${negotiationId}/history/${historyStepId}`, options);
};

export const getGetCargoNegotiationHistoryStepQueryKey = (negotiationId: string, historyStepId: string) => [
  `/api/v1/cargo-negotiation/negotiations/${negotiationId}/history/${historyStepId}`,
];

export type GetCargoNegotiationHistoryStepQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCargoNegotiationHistoryStep>>
>;
export type GetCargoNegotiationHistoryStepQueryError = AxiosError<void>;

export const useGetCargoNegotiationHistoryStep = <
  TData = Awaited<ReturnType<typeof getCargoNegotiationHistoryStep>>,
  TError = AxiosError<void>
>(
  negotiationId: string,
  historyStepId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCargoNegotiationHistoryStep>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCargoNegotiationHistoryStepQueryKey(negotiationId, historyStepId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCargoNegotiationHistoryStep>>> = ({ signal }) =>
    getCargoNegotiationHistoryStep(negotiationId, historyStepId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCargoNegotiationHistoryStep>>, TError, TData>(queryKey, queryFn, {
    enabled: !!(negotiationId && historyStepId),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const requestCargoExpiryExtension = (
  negotiationId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `/api/v1/cargo-negotiation/negotiations/${negotiationId}/request-expiry-extension`,
    undefined,
    options
  );
};

export type RequestCargoExpiryExtensionMutationResult = NonNullable<
  Awaited<ReturnType<typeof requestCargoExpiryExtension>>
>;

export type RequestCargoExpiryExtensionMutationError = AxiosError<unknown>;

export const useRequestCargoExpiryExtension = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof requestCargoExpiryExtension>>,
    TError,
    { negotiationId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof requestCargoExpiryExtension>>,
    { negotiationId: string }
  > = (props) => {
    const { negotiationId } = props ?? {};

    return requestCargoExpiryExtension(negotiationId, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof requestCargoExpiryExtension>>,
    TError,
    { negotiationId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const extendCargoExpiry = (
  negotiationId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CargoNegotiationDTO>> => {
  return axios.post(`/api/v1/cargo-negotiation/negotiations/${negotiationId}/extend-expiry`, undefined, options);
};

export type ExtendCargoExpiryMutationResult = NonNullable<Awaited<ReturnType<typeof extendCargoExpiry>>>;

export type ExtendCargoExpiryMutationError = AxiosError<void>;

export const useExtendCargoExpiry = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof extendCargoExpiry>>,
    TError,
    { negotiationId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof extendCargoExpiry>>, { negotiationId: string }> = (
    props
  ) => {
    const { negotiationId } = props ?? {};

    return extendCargoExpiry(negotiationId, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof extendCargoExpiry>>, TError, { negotiationId: string }, TContext>(
    mutationFn,
    mutationOptions
  );
};
export const acceptExtendCargoExpiryRequest = (
  negotiationId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CargoNegotiationDTO>> => {
  return axios.post(
    `/api/v1/cargo-negotiation/negotiations/${negotiationId}/accept-extend-expiry-request`,
    undefined,
    options
  );
};

export type AcceptExtendCargoExpiryRequestMutationResult = NonNullable<
  Awaited<ReturnType<typeof acceptExtendCargoExpiryRequest>>
>;

export type AcceptExtendCargoExpiryRequestMutationError = AxiosError<void>;

export const useAcceptExtendCargoExpiryRequest = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof acceptExtendCargoExpiryRequest>>,
    TError,
    { negotiationId: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof acceptExtendCargoExpiryRequest>>,
    { negotiationId: string }
  > = (props) => {
    const { negotiationId } = props ?? {};

    return acceptExtendCargoExpiryRequest(negotiationId, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof acceptExtendCargoExpiryRequest>>,
    TError,
    { negotiationId: string },
    TContext
  >(mutationFn, mutationOptions);
};

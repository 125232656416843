import { Loader } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { ProductSpecificationQuery } from 'api/generated/model/productSpecificationQuery';
import { useGetCompanyProductSpecification } from 'api/generated/specifications/specifications';

import { InlineError } from '@/components';

import { ProductSpecification } from './ProductSpecification';

export interface ProductSpecificationByQueryProps extends ProductSpecificationQuery {
  className?: string;
}

export function ProductSpecificationByQuery({ className, ...query }: ProductSpecificationByQueryProps) {
  const { t } = useTranslation('common');
  const { isLoading, data, error, isFetched } = useGetCompanyProductSpecification(
    { filters: query },
    {
      query: {
        retry: 1,
      },
    }
  );

  if (!query.plantId) {
    return <InlineError>{t('productSpecification.noPlant')}</InlineError>;
  }

  // not founding specification is handled by ProductSpecification
  if (error && error.response.status !== 404) {
    return <InlineError>{t('error.unexpectedError.message')}</InlineError>;
  }

  if (isLoading && !isFetched) {
    return <Loader size="xs" />;
  }

  const { specificationFileName, specificationId } = data?.data || {};

  return (
    <ProductSpecification
      specificationFileName={specificationFileName}
      specificationId={specificationId}
      className={className}
    />
  );
}

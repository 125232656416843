/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type FreightNegotiationExpiryExtendRequestStatus =
  (typeof FreightNegotiationExpiryExtendRequestStatus)[keyof typeof FreightNegotiationExpiryExtendRequestStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FreightNegotiationExpiryExtendRequestStatus = {
  REQUESTED: 'REQUESTED',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
} as const;

import { Box, createStyles } from '@mantine/core';
import {
  useGetCompanyCargoNeedNegotiations,
  useGetCompanyCargoOfferNegotiations,
} from 'api/generated/cargo-negotiation/cargo-negotiation';

import { Table } from '@/components';
import { OfferType } from '@/views/my-cargos';

import { NegotiationColumns } from '../types';

const useStyles = createStyles((theme) => ({
  box: {
    backgroundColor: theme.white,
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[2]}`,
    width: '100%',
  },
}));

function useNegotiationsData(type: OfferType, id: string) {
  const offerNegotiations = useGetCompanyCargoOfferNegotiations(id, {
    query: {
      select: (data) => data.data.cargoNegotiations,
      enabled: type === 'offer',
      staleTime: 0,
    },
  });
  const needNegotiations = useGetCompanyCargoNeedNegotiations(id, {
    query: {
      select: (data) => data.data.cargoNegotiations,
      enabled: type === 'request',
      staleTime: 0,
    },
  });

  return type === 'offer' ? offerNegotiations : needNegotiations;
}

export interface NegotationsTableProps {
  id: string;
  type: OfferType;
  columns: NegotiationColumns;
}

export function NegotiationsTable(props: NegotationsTableProps) {
  const { id, columns, ...useColumnsProps } = props;
  const { classes } = useStyles();
  const { data, isLoading } = useNegotiationsData(useColumnsProps.type, id);

  return (
    <Box className={classes.box} data-testid="negotiations-table">
      <Table.Card>
        <Table columns={columns} data={data || []} isLoading={isLoading} />
      </Table.Card>
    </Box>
  );
}

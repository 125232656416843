/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type GetCargoTenderListSortStatus =
  (typeof GetCargoTenderListSortStatus)[keyof typeof GetCargoTenderListSortStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCargoTenderListSortStatus = {
  asc: 'asc',
  desc: 'desc',
} as const;

import { Group, Select, Image, SelectProps, createStyles } from '@mantine/core';
import { forwardRef } from 'react';

import { Text } from '../typography/Text';

export interface SelectOptionProps {
  value: string;
  label: string;
  imageLink: string;
  alt: string;
  name?: string;
}

export interface CountrySelectorProps extends SelectProps {
  countryCode: string;
  hideImage?: boolean;
}

const useStyles = createStyles(() => ({
  image: {
    marginLeft: 12,
    marginRight: 8,
  },
}));

export const CountrySelector = (props: CountrySelectorProps) => {
  const { classes } = useStyles();
  const { countryCode, hideImage, ...selectProps } = props;

  return (
    <Select
      icon={
        countryCode && !hideImage ? (
          <Image className={classes.image} src={`https://flagcdn.com/24x18/${countryCode}.png`} alt="select icon" />
        ) : null
      }
      iconWidth={36}
      {...selectProps}
    />
  );
};

CountrySelector.selectOption = forwardRef<HTMLDivElement, SelectOptionProps>(function selectOption(props, ref) {
  const { label, imageLink, alt, ...others } = props;
  return (
    <Group pl={4} pr={4} ref={ref} {...others}>
      <Image sx={(theme) => ({ boxShadow: theme.shadows.xl })} width={20} src={imageLink} alt={alt} />
      <Text>{label}</Text>
    </Group>
  );
});

import { Stack } from '@mantine/core';
import { CellContext, ColumnDefTemplate } from '@tanstack/react-table';

import { Text } from '@/components';
import { transformToPercentage } from '@/common/percentage-transform';
import { formatterThousand } from '@/common/get-thousand-seperated-number';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const QuantityCell: ColumnDefTemplate<CellContext<any, number>> = ({ row }) => {
  const minTolerance = transformToPercentage(row.original.toleranceMinPercentage);
  const maxTolerance = transformToPercentage(row.original.toleranceMaxPercentage);

  return (
    <Stack spacing={2}>
      <Text size="S" color="gray.7">
        {`${formatterThousand(row.original.quantity)} mts`}
      </Text>
      <Text size="S" color="gray.7">
        {`(-${minTolerance}%\n+${maxTolerance}%)`}
      </Text>
    </Stack>
  );
};

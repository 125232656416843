import { Badge } from '@mantine/core';

import { Text } from '@/components';
import { useGradientBackgroundStyles } from '@/common/use-gradient-background';

interface GradientBadgeProps {
  label: string;
}

export function GradientBadge(props: GradientBadgeProps) {
  const gradient = useGradientBackgroundStyles();

  return (
    <Badge className={gradient}>
      <Text size="S" color="white" weight={500}>
        {props.label.toLocaleUpperCase()}
      </Text>
    </Badge>
  );
}

import { Flex, Stack } from '@mantine/core';

import { Text } from '@/components';

export interface InputLabelProps {
  label: string;
  sublabel?: string;
  withAsterisk?: boolean;
}

export function InputLabel(props: InputLabelProps) {
  const { label, sublabel, withAsterisk = false } = props;

  return (
    <Stack spacing={8}>
      <Flex gap={8}>
        <Text size="M" weight={500} color="gray.9">
          {label}
        </Text>
        {withAsterisk && (
          <Text size="M" weight={500} color="red.5">
            *
          </Text>
        )}
      </Flex>
      {sublabel && (
        <Text size="S" weight={500} color="gray.7">
          {sublabel}
        </Text>
      )}
    </Stack>
  );
}

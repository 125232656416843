import { SURVEYOR_INITIAL_VALUES } from '../surveyor/initial-values';
import { WeightSchema } from './weight';

export const WEIGHT_INITIAL_VALUES: WeightSchema = {
  surveyorAtLoadport: [SURVEYOR_INITIAL_VALUES],
  surveyorAtDischargeport: [SURVEYOR_INITIAL_VALUES],
  other: '',
};

//Empty values
export const weigthEmptyValues = (surveyorAtLoadportLength = 1, surveyorAtDischargeportLength = 1) => {
  return {
    surveyorAtLoadport: Array.from({ length: surveyorAtLoadportLength }, () => ({
      name: '',
      surveyorCost: null,
      conditions: '',
    })),
    surveyorAtDischargeport: Array.from({ length: surveyorAtDischargeportLength }, () => ({
      name: '',
      surveyorCost: null,
      conditions: '',
    })),
    other: '',
  };
};

import { Alert, AlertProps, createStyles, MantineTheme } from '@mantine/core';
import { RiInformationLine } from 'react-icons/ri';

type IconLayout = 'one-line' | 'top';

const getOneLineIconStyle = (theme: MantineTheme) => ({
  padding: 12,
  width: 44,
  height: 44,
  backgroundColor: theme.colors.blue[1],
  borderRadius: 4,
});

const useStyles = createStyles((theme, { iconLayout }: { iconLayout: IconLayout }) => ({
  title: {
    color: theme.colors.deepBlue[7],
    marginBottom: 0,
  },
  icon: {
    color: theme.colors.deepBlue[7],
    ...(iconLayout === 'one-line' ? getOneLineIconStyle(theme) : {}),
  },
  root: {
    border: '1px solid',
    borderColor: theme.colors.blue[1],
  },
  message: {
    fontSize: theme.fontSizes.sm,
    lineHeight: 1.5,
    paddingTop: 1,
  },
}));

export interface CompactInfoAlertProps extends AlertProps {
  iconLayout?: IconLayout;
  color?: string;
}

export function CompactInfoAlert({
  classNames,
  iconLayout = 'top',
  color = 'blue.0',
  ...restProps
}: CompactInfoAlertProps) {
  const { classes } = useStyles({ iconLayout });

  return (
    <Alert
      icon={<RiInformationLine size={20} />}
      classNames={{ ...classes, ...classNames }}
      color={color}
      {...restProps}
    />
  );
}

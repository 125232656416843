import { Flex, Grid, NumberInput, Select, TextInput } from '@mantine/core';
import { Trans, useTranslation } from 'next-i18next';

import { Text } from '@/components';
import { IrrevocableLetterEvents, PrepaymentEvents } from '@/blink-forms';
import { GetInputProps } from '@/common/types';
import { useGetSelectDataWithTranslations } from '@/views/tenders/utils/use-get-select-data-with-translations';
import { PercentageField } from '@/components';
import { getThousandSeparatedNumberProps } from '@/common/get-thousand-seperated-number';

interface IrrevocableLetterFieldGroupProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getInputProps: GetInputProps<any>;
  data?: any;
  percentagePath: string;
  daysPath: string;
  eventPath: string;
  bankName: string;
  swiftCode: string;
}

export function IrrevocableLetter({
  getInputProps,
  percentagePath,
  daysPath,
  eventPath,
  bankName,
  swiftCode,
}: IrrevocableLetterFieldGroupProps) {
  const { t } = useTranslation('tenderNegotiation');

  const IrrevocableLetterEventData = useGetSelectDataWithTranslations(IrrevocableLetterEvents);

  return (
    <Grid>
      <Grid.Col span={12}>
        <Flex gap="md" justify="flex-start" align="center" direction="row" wrap="wrap" sx={{ paddingTop: 20 }}>
          <Trans
            t={t}
            i18nKey="formSentences.irrevocableLetter"
            components={{
              percentage: (
                <PercentageField min={0} max={100} maxLength={3} h={'auto'} {...getInputProps(percentagePath)} />
              ),
              days: (
                <NumberInput h={'auto'} w={56} {...getInputProps(daysPath)} {...getThousandSeparatedNumberProps()} />
              ),
            }}
          />
          <Text>{t('labels.confirmationCostAt')}</Text>
          <Select h={'auto'} data={IrrevocableLetterEventData} {...getInputProps(eventPath)} />
        </Flex>
      </Grid.Col>
      <Grid.Col span={12}>
        <Flex gap="md" justify="flex-start" align="center" direction="row" wrap="wrap">
          <TextInput
            label={t('labels.bankName')}
            placeholder={t('placeholders.conditions')}
            withAsterisk={false}
            {...getInputProps(bankName)}
          />
          <TextInput
            label={t('labels.swiftCode')}
            placeholder={t('placeholders.name')}
            withAsterisk={false}
            {...getInputProps(swiftCode)}
          />
        </Flex>
      </Grid.Col>
    </Grid>
  );
}

import { createStyles } from '@mantine/core';
import { ProductSpecificationQuery } from 'api/generated/model/productSpecificationQuery';

import { ProductSpecificationByQuery } from '@/components';

const useStyles = createStyles(() => ({
  productSpecification: {
    display: 'inline-block',
  },
}));

export interface ProductSpecificationByQueryDiffProps {
  companyId: string;
  specificationQuery?: Omit<ProductSpecificationQuery, 'companyId'>;
  prevSpecificationQuery?: Omit<ProductSpecificationQuery, 'companyId'>;
}

export function ProductSpecificationByQueryDiff({
  companyId,
  specificationQuery,
  prevSpecificationQuery,
}: ProductSpecificationByQueryDiffProps) {
  const { classes } = useStyles();

  if (!specificationQuery && !prevSpecificationQuery) {
    return null;
  }

  const { gradeName, plantId, productId } = specificationQuery || prevSpecificationQuery;

  return (
    <ProductSpecificationByQuery
      companyId={companyId}
      gradeName={gradeName}
      plantId={plantId}
      productId={productId}
      className={classes.productSpecification}
    />
  );
}

import { ActionIcon, Anchor, Badge, Box, Burger, createStyles, Group, Image, Tooltip } from '@mantine/core';
import NextLink from 'next/link';
import { CompanyType, UserCompanyStatus, UserRole } from 'api/generated/model';
import { useTranslation } from 'next-i18next';
import { RiBuildingLine, RiFolderLockFill, RiUser3Line, RiUserSettingsLine, RiGroupLine } from 'react-icons/ri';
import React from 'react';
import { ENABLE_SENDBIRD } from 'components/chat-session-wrapper/ChatSessionWrapper';

import { routes } from '@/common/routes';
import { useHasPermission } from '@/core';
import { useMediaQuery } from '@/core/hooks/use-media-query';
import { SendbirdNotificationIndicator } from '@/views/chat/SendbirdNotificationIndicator';
import { NotificationIndicator } from '@/views/notifications';

import { HideForbiddenElement } from '../hide-forbidden-element/HideForbiddenElement';

const useStyles = createStyles((theme) => ({
  header: {
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    backgroundColor: theme.colors.primary[2],
    borderBottom: 'none',
    height: 56,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,
  },
  inner: {
    height: 56,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  badge: {
    color: theme.colors.red[9],
    '& .mantine-Badge-leftSection': {
      lineHeight: '12px',
    },
  },
}));

interface HeaderProps {
  opened: boolean;
  toggle: VoidFunction;
}

export function RestrictedAccessBadge() {
  const { t } = useTranslation('common');
  const { classes } = useStyles();

  return (
    <Tooltip
      position={'bottom-start'}
      arrowPosition={'center'}
      w={250}
      zIndex={2000}
      multiline
      label={t('tooltips.restrictedAccess')}
      withArrow
    >
      <Badge className={classes.badge} color="red" pl={2} leftSection={<RiFolderLockFill size={20} />} size={'xs'}>
        {t('badges.RESTRICTED_ACCESS')}
      </Badge>
    </Tooltip>
  );
}

export function Header(props: HeaderProps) {
  const { opened, toggle } = props;
  const { classes, theme } = useStyles();
  const { isCompanyVerified, hasUserRolePermission, hasCompanyPermission, hasAllowedCompanyStatus } =
    useHasPermission();
  const { t } = useTranslation('common');
  const isMobile = useMediaQuery(theme.breakpoints.md);
  const isCompanyPassive = hasAllowedCompanyStatus([UserCompanyStatus.PASSIVE]);

  const onLogoClick = () => {
    if (isCompanyVerified) {
      return hasUserRolePermission([UserRole.CHARTERING_MANAGER]) && hasCompanyPermission([CompanyType.CHARTERING])
        ? routes.MyFreights()
        : routes.App;
    } else {
      return routes.CompanyAccount;
    }
  };

  return (
    <Box className={classes.header}>
      <div className={classes.inner}>
        <Group>
          {isMobile && <Burger opened={opened} onClick={toggle} size="sm" color="white" />}
          <Anchor component={NextLink} href={onLogoClick()}>
            <Image
              src="/images/Logo.svg"
              alt="logo"
              height={20}
              width={'auto'}
              mt={4}
              sx={{ transform: 'translateY(-2px)' }}
            />
          </Anchor>
          {isCompanyPassive && <RestrictedAccessBadge />}
        </Group>
        <Group>
          <HideForbiddenElement allowedRoles={Object.values(UserRole)} hiddenForMIOnlyUsers={true}>
            {ENABLE_SENDBIRD && <SendbirdNotificationIndicator />}
          </HideForbiddenElement>
          <HideForbiddenElement
            hiddenForMIOnlyUsers={true}
            allowUnverifiedCompany={true}
            allowedRoles={Object.values(UserRole)}
          >
            <NotificationIndicator />
          </HideForbiddenElement>

          <HideForbiddenElement
            allowUnverifiedCompany
            allowedRoles={Object.values(UserRole)}
            allowedCompanyTypes={[CompanyType.BUY_AND_SELL, CompanyType.BUY, CompanyType.SELL]}
            hiddenForMIOnlyUsers={true}
            hiddenForPrivateCompanies={true}
          >
            <NextLink href={routes.CompanyAccount}>
              <Tooltip label={t('nav.companyAccount')}>
                <ActionIcon size={'md'} variant="transparent">
                  <RiBuildingLine size={20} color="white" />
                </ActionIcon>
              </Tooltip>
            </NextLink>
          </HideForbiddenElement>

          <HideForbiddenElement
            allowUnverifiedCompany
            allowedRoles={[UserRole.ADMIN]}
            allowedCompanyTypes={[CompanyType.BUY_AND_SELL, CompanyType.BUY, CompanyType.SELL]}
            hiddenForMIOnlyUsers={true}
            hiddenForPrivateCompanies={true}
          >
            <NextLink href={routes.UserManagement}>
              <Tooltip label={t('nav.userManagement')}>
                <ActionIcon size={'md'} variant="transparent">
                  <RiGroupLine size={19} color="white" />
                </ActionIcon>
              </Tooltip>
            </NextLink>
          </HideForbiddenElement>

          <HideForbiddenElement allowUnverifiedCompany>
            <NextLink href={routes.UserSettings}>
              <Tooltip label={t('nav.account')}>
                <ActionIcon size={'md'} variant="transparent">
                  <RiUserSettingsLine size={20} color="white" />
                </ActionIcon>
              </Tooltip>
            </NextLink>
          </HideForbiddenElement>
        </Group>
      </div>
    </Box>
  );
}

import { ActionIcon, Menu, MenuItemProps, MenuProps, createStyles } from '@mantine/core';
import { ReactNode } from 'react';
import { RiMore2Fill } from 'react-icons/ri';

import { ConfirmationPopover, ConfirmationPopoverProps } from '../popover/ConfirmationPopover';

const useStyles = createStyles((theme) => ({
  item: {
    ':hover': {
      backgroundColor: theme.colors.indigo[0],
    },
  },
}));

export interface Action extends MenuItemProps {
  key?: string;
  label: ReactNode;
  confirmationPopover?: ConfirmationPopoverProps;
  onClick?: VoidFunction;
}

export interface ActionMenuProps extends Omit<MenuProps, 'children'> {
  actions?: Action[];
  children?: ReactNode;
}

export function ActionMenu({ actions = [], children, ...restProps }: ActionMenuProps) {
  const { classes, theme } = useStyles();

  return (
    <>
      <Menu classNames={classes} {...restProps}>
        <Menu.Target data-testid="action-target">
          <ActionIcon
            component="button"
            variant="transparent"
            aria-label="More actions"
            size={20}
            onClick={(event) => event.stopPropagation()}
          >
            <RiMore2Fill fontSize={24} color={theme.fn.primaryColor()} />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
          {children}
          {actions.map(({ label, key = label.toString(), confirmationPopover, onClick, ...rest }) =>
            confirmationPopover?.content ? (
              <ConfirmationPopover key={key} {...confirmationPopover}>
                <Menu.Item {...rest}>{label}</Menu.Item>
              </ConfirmationPopover>
            ) : (
              <Menu.Item
                key={key}
                onClick={(e) => {
                  e.stopPropagation();
                  if (onClick) {
                    onClick();
                  }
                }}
                {...rest}
              >
                {label}
              </Menu.Item>
            )
          )}
        </Menu.Dropdown>
      </Menu>
    </>
  );
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoWorkflowGlobalEventType =
  (typeof CargoWorkflowGlobalEventType)[keyof typeof CargoWorkflowGlobalEventType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoWorkflowGlobalEventType = {
  FIXTURE_DATE: 'FIXTURE_DATE',
  FIRST_DAY_OF_LAYCAN: 'FIRST_DAY_OF_LAYCAN',
  LOADING_ARRIVAL_TIME: 'LOADING_ARRIVAL_TIME',
  LOADING_BERTHING_TIME: 'LOADING_BERTHING_TIME',
  LOADING_COMPLETION_TIME: 'LOADING_COMPLETION_TIME',
  DISCHARGING_ARRIVAL_TIME: 'DISCHARGING_ARRIVAL_TIME',
  DISCHARGING_BERTHING_TIME: 'DISCHARGING_BERTHING_TIME',
  DISCHARGING_COMPLETION_TIME: 'DISCHARGING_COMPLETION_TIME',
} as const;

export function getFixedNumberProps(precision = 2) {
  return {
    parser: (value: string | undefined) => value?.replace(',', '.'),
    formatter: (value: string | undefined) => value?.replace(',', '.'),
    precision,
    step: 10 ** (-1 * precision),
  };
}

export function getFixedPositiveNumberProps(precision = 2) {
  return {
    parser: (value: string | undefined) => {
      return value?.replace(',', '.').replace(/-/g, '');
    },
    formatter: (value: string | undefined) => {
      return value?.replace(',', '.').replace(/-/g, '');
    },
    precision,
    step: 10 ** (-1 * precision),
  };
}

/**
 * Filters a string input to allow only numeric values with a specified precision for decimals.
 * It supports negative values and ensures that only digits and a single decimal point are accepted,
 * limiting the decimal part to the given precision.
 *
 * @param precision The number of decimal places to allow. Defaults to 2.
 * @returns A function that takes a string or undefined and returns a filtered string with only allowed characters.
 */
export function getNumericInputWithPrecision(precision = 2) {
  return {
    parser: (value: string | undefined) => {
      // Allows only digits, one decimal point, and limits decimal places to the specified precision
      const regex = new RegExp(`^-?\\d*\\.?\\d{0,${precision}}`);
      const match = value?.match(regex);
      return match ? match[0] : '';
    },
    formatter: (value: string | undefined) => {
      // Ensures the decimal format uses a dot
      return value?.replace(',', '.');
    },
    precision,
    step: 10 ** (-1 * precision),
  };
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoShipmentStatus = (typeof CargoShipmentStatus)[keyof typeof CargoShipmentStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoShipmentStatus = {
  FIXED: 'FIXED',
  LAYCAN_DECLARED: 'LAYCAN_DECLARED',
  NOMINATED: 'NOMINATED',
  WAITING_AT_LOAD_PORT: 'WAITING_AT_LOAD_PORT',
  LOADING: 'LOADING',
  SAILING: 'SAILING',
  WAITING_AT_DISCHARGE_PORT: 'WAITING_AT_DISCHARGE_PORT',
  DISCHARGING: 'DISCHARGING',
  IN_TRANSIT_TO_CONSUMER: 'IN_TRANSIT_TO_CONSUMER',
  AT_CONSUMER: 'AT_CONSUMER',
  IN_CONSUMPTION: 'IN_CONSUMPTION',
  COMPLETED: 'COMPLETED',
} as const;

import { useTranslation } from 'next-i18next';

import { Textarea } from '@/views/recap-form';

import { useFreightRecapFormState } from '../state/FreightRecapFormProvider';

export function FreightRecapFormCommentsGroup() {
  const { t } = useTranslation('freightRecapForm');
  const { getInputProps } = useFreightRecapFormState();

  return (
    <Textarea
      size="md"
      minRows={7}
      label={t('labels.comments')}
      withAsterisk={false}
      placeholder={t('placeholders.comments')}
      data-testid="comments-comment"
      {...getInputProps('comments')}
    />
  );
}

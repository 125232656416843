import { useTranslation } from 'next-i18next';
import { Group, Paper, Skeleton, Stack } from '@mantine/core';
import { useGetCargoWorkflow } from 'api/generated/workflow/workflow';
import { WorkflowDTO } from 'api/generated/model';
import { useGetCompany } from 'api/generated/companies/companies';

import { Text, Avatar } from '@/components';
import { ContractData } from '@/views/booked-cargo-summary-page/types/CargoWorkflow';
import { formatDate } from '@/common/format-date';

import { Counterparty, getCounterparty } from '../../common/get-counterparty';
export interface ShortCargoWorkflowSummaryProps {
  workflowId: string;
}

function getTranslationDataValues(workflow: WorkflowDTO) {
  const { offeredTransportation, contractData } = workflow || {};
  const {
    product: { cargo: { laycan, quantity, productQuality, productName, loadingPort, dischargingPort } = {} } = {},
  } = (contractData || {}) as ContractData;

  const [laycanStart, laycanEnd] = laycan || [];

  return {
    offeredTransportation,
    quantity,
    productQuality,
    productName,
    loadingPort: loadingPort?.name,
    dischargingPort: dischargingPort?.name,
    laycanStart: formatDate(laycanStart),
    laycanEnd: formatDate(laycanEnd),
  };
}

export function ShortCargoWorkflowSummary({ workflowId }: ShortCargoWorkflowSummaryProps) {
  const { t } = useTranslation('common');
  const { data: workflowData, isLoading: workflowLoading } = useGetCargoWorkflow(
    workflowId,
    {
      hideFinancialByRoles: true,
    },
    {
      query: { select: ({ data }) => data },
    }
  );

  const counterparty: Partial<Counterparty> = workflowData ? getCounterparty(workflowData) : {};
  const { data: companyData, isLoading: companyLoading } = useGetCompany(counterparty?.counterpartyId, {
    query: { select: ({ data }) => data, enabled: Boolean(counterparty?.counterpartyId) },
  });

  if (workflowLoading || companyLoading) {
    return <Skeleton height={155} width="100%" />;
  }

  return (
    <Paper p="md" bg="background.1">
      <Stack spacing="md">
        <Group>
          <Avatar src={companyData.logoUrl} />
          <Text weight={500}>{counterparty.counterpartyName}</Text>
        </Group>
        <Text size="S">{t('shortCargoWorkflowSummary', getTranslationDataValues(workflowData))}</Text>
      </Stack>
    </Paper>
  );
}

import { Stack } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { IrrevocableLetterDTO } from 'api/generated/model';

import { Text } from '@/components';
import { decimalToPercentage } from '@/views/tenders/utils/percentage';

interface IrrevocableLetterProps {
  irrevocableLetter: IrrevocableLetterDTO;
}

export const IrrevocableLetterLabel = ({ irrevocableLetter }: IrrevocableLetterProps) => {
  const { t } = useTranslation('tenderContract');

  return (
    <Stack spacing={0}>
      <Text weight="bold">{t(`labels.irrevocableLetter`)}</Text>
      <IrrevocableLetterText irrevocableLetter={irrevocableLetter} />
    </Stack>
  );
};

export const IrrevocableLetterText = ({ irrevocableLetter }: IrrevocableLetterProps) => {
  const { t } = useTranslation('tenderContract');

  return (
    <>
      <Text>
        {t(`valuesFormatted.irrevocableLetterConditions`, {
          days: irrevocableLetter.days,
          event: t(`valuesFormatted.${irrevocableLetter.event}`),
          percentage: decimalToPercentage(irrevocableLetter.percentage),
        })}
      </Text>
      <Text>
        {t(`valuesFormatted.confirmationCost`, {
          event: t(`valuesFormatted.${irrevocableLetter.event}`),
        })}
      </Text>
      <Text>
        {t(`valuesFormatted.bankName`, {
          bankName: irrevocableLetter.bankName,
        })}
      </Text>
      <Text>
        {t(`valuesFormatted.swiftCode`, {
          swiftCode: irrevocableLetter.swiftCode,
        })}
      </Text>
    </>
  );
};

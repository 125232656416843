/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type VesselType = (typeof VesselType)[keyof typeof VesselType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VesselType = {
  TBN: 'TBN',
  NOMINATED_VESSEL: 'NOMINATED_VESSEL',
} as const;

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type WorkflowDocumentThresholdCondition =
  (typeof WorkflowDocumentThresholdCondition)[keyof typeof WorkflowDocumentThresholdCondition];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkflowDocumentThresholdCondition = {
  BEFORE: 'BEFORE',
  AFTER: 'AFTER',
} as const;

import { UseQueryOptions } from '@tanstack/react-query';
import { getReports, useGetReports } from 'api/market-intelligence/default/default';

export function useGetMarketIntelligenceReports<TError, TData>(
  query: UseQueryOptions<Awaited<ReturnType<typeof getReports>>, TError, TData>,
  page = 0
) {
  const { isLoading, isError, data } = useGetReports(
    { page },
    {
      query,
      axios: { baseURL: process.env.NEXT_PUBLIC_BLINK_MARKET_INTELLIGENCE_URL },
    }
  );
  return { isLoading, isError, data };
}

import { z } from 'zod';

import { zTolerancePercentage } from '../types';
import {
  conditionalRequestFreightFullSchema,
  conditionalRequestFreightSchema,
  offerVisibilitySchema,
} from './promoting-extension';

const surveyorCostSchema = z.object({
  name: z.string().optional(),
  cost: z.string().min(1),
  conditions: z.string().optional(),
});

const paymentConditionSchema = z.object({
  percentage: z.number().min(0),
  days: z.number().min(1),
  event: z.string().min(1),
});

export const cargoSchema = z.object({
  productId: z.string().min(1).optional(),
  productName: z.string().min(1),
  quantity: z.number().min(1),
  minTolerance: zTolerancePercentage,
  maxTolerance: zTolerancePercentage,
  toleranceOption: z.string().min(1),
  loadingPortId: z.string().min(1),
  dischargingPortId: z.string(),
  laycan: z.tuple([z.string().datetime(), z.string().datetime()]),
  productQuality: z.string().min(1),
  productPackaging: z.string().min(1),
});

export const productSchema = z.object({
  vesselInformation: z.object({
    type: z.string().optional(),
    maxAge: z.number().optional(),
    vesselsNominationDays: z.number().optional(),
    vesselsNominationEvent: z.string().optional(),
    imoNumber: z.string().optional(),
    gears: z.string().optional(),
    cranesNumber: z.number().min(1).optional(),
    cranesCapacity: z.number().min(1).optional(),
    grabs: z.string().optional(),
    grabsNumber: z.number().min(1).optional(),
    grabCapacity: z.number().min(1).optional(),
  }),
  weight: z.object({
    loadportSurveyors: z.array(surveyorCostSchema).optional(),
    dischargeSurveyors: z.array(surveyorCostSchema).optional(),
    otherSurveyor: z.string().min(1).optional(),
  }),
  samplingAndAnalysis: z.object({
    surveyorNames: z.array(surveyorCostSchema).optional(),
    surveyorsAtDischargePort: z.array(surveyorCostSchema).optional(),
    analysis: z
      .array(
        z.object({
          type: z.string().min(1).optional(),
          name: z.string().optional(),
          conditions: z.string().optional(),
        })
      )
      .min(1),
    arbitrationAnalysis: z.object({
      name: z.string().optional(),
      conditions: z.string().optional(),
    }),
    others: z.string().optional(),
  }),
});

export const baseRecapFormSchema = z.object({
  price: z.object({
    price: z.number().min(1),
    currency: z.enum(['EUR', 'USD']),
  }),
  payment: z.object({
    conditions: z
      .object({
        prepayment: paymentConditionSchema.optional(),
        cashAgainstDocuments: paymentConditionSchema
          .omit({ event: true })
          .extend({ numberOfCopies: z.string() })
          .optional(),
        irrevocableLetter: paymentConditionSchema.extend({ bankName: z.string(), swiftCode: z.string() }).optional(),
        documentCollection: paymentConditionSchema.optional(),
        openAccount: paymentConditionSchema.optional(),
        consumptionPayment: paymentConditionSchema.extend({ numberOfInstallments: z.number() }).optional(),
        other: z
          .object({
            percentage: z.number().min(0),
            methodOfPayment: z.string().min(1),
          })
          .optional(),
      })
      .refine(
        (conditions) => {
          // Initialize sum for the current condition
          let sum = 0;

          // Add percentage of each payment method if it exists
          if (conditions.prepayment) sum += conditions.prepayment.percentage;
          if (conditions.cashAgainstDocuments) sum += conditions.cashAgainstDocuments.percentage;
          if (conditions.irrevocableLetter) sum += conditions.irrevocableLetter.percentage;
          if (conditions.documentCollection) sum += conditions.documentCollection.percentage;
          if (conditions.openAccount) sum += conditions.openAccount.percentage;
          if (conditions.consumptionPayment) sum += conditions.consumptionPayment.percentage;
          if (conditions.other) sum += conditions.other.percentage;

          return sum === 1; // percentages are not converted
        },
        {
          message: 'The total percentage must equal 100% of payment conditions. Change the percentage in conditions.',
        }
      )
      .refine(
        (data) => {
          const values = Object.values(data);
          // Count how many conditions are actually filled (not undefined and have data)
          const filledConditions = values.filter((condition) => {
            return condition && typeof condition === 'object' && Object.keys(condition).length > 0;
          });
          return filledConditions.length >= 1;
        },
        {
          message: 'At least one payment condition must be selected and filled.',
        }
      ),
    demurrage: z
      .object({
        rate: z.number().min(1).optional(),
        rateCondition: z.string().optional(),
        event: z.string().optional(),
        type: z.string().min(1).optional(),
        openAccount: z
          .object({
            maxDays: z.number().optional(),
            event: z.string().optional(),
          })
          .optional(),
      })
      .refine(
        (data) => {
          if (data.type == 'OPEN_ACCOUNT') {
            return data.openAccount?.maxDays !== undefined;
          }
          return true;
        },
        {
          message: 'This field is required',
          path: ['openAccount', 'maxDays'],
        }
      )
      .refine(
        (data) => {
          if (data.type == 'OPEN_ACCOUNT') {
            return data.openAccount?.event !== undefined && data.openAccount?.event !== null;
          }
          return true;
        },
        {
          message: 'This field is required',
          path: ['openAccount', 'event'],
        }
      ),
  }),
  lawJurisdiction: z.object({
    law: z.string().min(1),
    jurisdiction: z.string().min(1),
  }),
  other: z.string().optional(),
});

export const recapFormSchema = baseRecapFormSchema.extend({
  product: productSchema.extend({
    cargo: cargoSchema.extend({
      originPlantId: z.string().min(1),
      originPlantCountry: z.string().optional(),
      destinationPlantId: z.string().optional(),
    }),
    freightConditions: z.object({
      safePortNo: z.string().min(1),
      safeBerthNo: z.string().min(1),
      freightRate: z.number().min(1),
      freightTerms: z.string().min(1),
      freightPeriodTerms: z.string().min(1),
      norTendering: z.string().optional(),
      turnTime: z.number().min(1),
      turnTimeTerms: z.string().optional(),
      cargoPda: z.string().min(1),
      vesselPda: z.string().min(1),
      berthConditions: z.string().optional(),
      exclusionFromLayTime: z.array(z.string()).optional(),
      exclusionFromLayTimeOtherExclusion: z.string().min(1).optional(),
      laytimeCalculations: z.array(z.string()).optional(),
      other: z.string().optional(),
    }),
    agentsInDischargingPort: z.object({
      mainAgendConditions: z.string().min(1),
      protectiveAgendConditions: z.string().min(1),
      mainAgentName: z.string().optional(),
      protectiveAgentName: z.string().optional(),
    }),
  }),
});

export const recapFormFullSchemaWithPromoting = conditionalRequestFreightFullSchema
  .and(recapFormSchema)
  .and(offerVisibilitySchema);

export const recapFormSchemaWithPromoting = conditionalRequestFreightSchema
  .and(recapFormSchema)
  .and(offerVisibilitySchema);

export type RecapFormFullWithPromoting = z.infer<typeof recapFormFullSchemaWithPromoting>;
export type RecapFormWithPromoting = z.infer<typeof recapFormSchemaWithPromoting>;

export type RecapForm = z.infer<typeof recapFormSchema>;

export const partialRecapFormSchema = recapFormSchema.deepPartial();

export type PartialRecapForm = z.infer<typeof partialRecapFormSchema>;

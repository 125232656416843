/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type AvailableFeatureFlags = (typeof AvailableFeatureFlags)[keyof typeof AvailableFeatureFlags];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AvailableFeatureFlags = {
  co2CalcIntegrationEnabled: 'co2CalcIntegrationEnabled',
  freightIntegrationEnabled: 'freightIntegrationEnabled',
  cctTraderOperations: 'cctTraderOperations',
  cargoVolumesChart: 'cargoVolumesChart',
  cargoTender: 'cargoTender',
  serviceProvidersEnabled: 'serviceProvidersEnabled',
  privateCompaniesEnabled: 'privateCompaniesEnabled',
  modularizationEnabled: 'modularizationEnabled',
  mockedChartsEnabled: 'mockedChartsEnabled',
  sofEnabled: 'sofEnabled',
  separateMarketplace: 'separateMarketplace',
  newLinkingCargosEnabled: 'newLinkingCargosEnabled',
  detailedOfferTemplates: 'detailedOfferTemplates',
  magicLink: 'magicLink',
} as const;

import { MutableRefObject, useState, ReactNode, ReactElement } from 'react';
import { createPortal } from 'react-dom';

import { ReplacableContext } from './ReplacableContext';

interface ReplacableContextProviderProps {
  children: React.ReactNode;
  replacements: Record<string, ReactNode>;
}

function isReactElement(element: ReactNode): element is ReactElement {
  return typeof element === 'object' && element !== null && 'type' in element;
}

export function ReplacableContextProvider({ children, replacements }: ReplacableContextProviderProps) {
  const [elements, setElementsObj] = useState<Record<string, MutableRefObject<HTMLDivElement>>>({});

  const setElement = (elementKey: string, ref: MutableRefObject<HTMLDivElement>) => {
    setElementsObj((state) => {
      if (state[elementKey]) {
        console.error(`ReplacableContext: Element with key "${elementKey}" already exists`);
      }

      return { ...state, [elementKey]: ref };
    });
  };

  const isElementReplaced = (elementKey: string) => Boolean(replacements[elementKey]);

  const getDebugInfo = (elementKey: string) => {
    const replacement = replacements[elementKey];

    return {
      replacement: isReactElement(replacement) ? replacement.type : replacement,
    };
  };

  return (
    <ReplacableContext.Provider value={{ elements, setElement, isElementReplaced, getDebugInfo }}>
      {children}
      {Object.entries(replacements).map(([key, element]) =>
        elements[key] ? createPortal(element, elements[key].current, key) : null
      )}
    </ReplacableContext.Provider>
  );
}

import { useTranslation } from 'next-i18next';
import { Grid, Textarea } from '@mantine/core';

import { useRecapFormContext } from '../state/RecapFormProvider';

export function OtherFormGroup() {
  const { t } = useTranslation('common');
  const { getInputProps } = useRecapFormContext();

  return (
    <Grid>
      <Grid.Col span={12} md={6}>
        <Textarea {...getInputProps('other')} minRows={4} placeholder={t('placeholders.freeText')} />
      </Grid.Col>
    </Grid>
  );
}

import { CargoTransportation } from 'api/generated/model';
import { useTranslation } from 'next-i18next';

import { BlinkBadge } from './BlinkBadge';

interface IncotermBadgeProps {
  useBlinkLogo?: boolean;
  cargoTransportation: CargoTransportation;
}

export function IncotermBadge({ useBlinkLogo = true, cargoTransportation }: IncotermBadgeProps) {
  const { t } = useTranslation('common');

  switch (cargoTransportation) {
    case CargoTransportation.FOB:
      return (
        <BlinkBadge showLogo={useBlinkLogo} color="green">
          {t('badges.incoterm.FOB')}
        </BlinkBadge>
      );
    case CargoTransportation.CFR:
      return (
        <BlinkBadge showLogo={useBlinkLogo} color="orange">
          {t('badges.incoterm.CFR')}
        </BlinkBadge>
      );
    case CargoTransportation.CIF:
      return (
        <BlinkBadge showLogo={useBlinkLogo} color="indigo">
          {t('badges.incoterm.CIF')}
        </BlinkBadge>
      );
    default:
      return (
        <BlinkBadge showLogo={useBlinkLogo} color="gray">
          Unknown status...
        </BlinkBadge>
      );
  }
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type GetAllServiceProvidersPortsByWorkflowResponseDtoCompanyDtoContactDtoStatus =
  (typeof GetAllServiceProvidersPortsByWorkflowResponseDtoCompanyDtoContactDtoStatus)[keyof typeof GetAllServiceProvidersPortsByWorkflowResponseDtoCompanyDtoContactDtoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAllServiceProvidersPortsByWorkflowResponseDtoCompanyDtoContactDtoStatus = {
  UNVERIFIED: 'UNVERIFIED',
  VERIFIED: 'VERIFIED',
  REJECTED: 'REJECTED',
} as const;

import { Checkbox } from '@mantine/core';
import { ReactNode, useState } from 'react';

interface CheckboxWithContentProps {
  label?: string;
  isChecked?: boolean;
  conditionKey?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  index: number;
  children?: ReactNode;
}

export function CheckboxWithContent({
  label,
  isChecked,
  index,
  children,
  conditionKey,
  onChange,
  ...checkboxProps
}: CheckboxWithContentProps) {
  return (
    <>
      <Checkbox label={label || ''} value={conditionKey} checked={isChecked} onChange={onChange} {...checkboxProps} />
      {isChecked && children}
    </>
  );
}

import { Alert, AlertProps, createStyles } from '@mantine/core';
import { ReactNode } from 'react';

const useStyles = createStyles((theme) => ({
  alert: {
    backgroundColor: theme.colors.blue[0],
    border: `1px solid ${theme.colors.blue[1]}`,
    padding: '24px 24px 40px 24px',

    [theme.fn.smallerThan('md')]: {
      padding: 4,
    },
  },
}));

export interface InfoAlertProps extends AlertProps {
  children: ReactNode;
}

export function InfoAlert(props: InfoAlertProps) {
  const { children, className, ...alertProps } = props;
  const { classes, cx } = useStyles();

  return (
    <Alert className={cx(className, classes.alert)} radius="md" {...alertProps}>
      {children}
    </Alert>
  );
}

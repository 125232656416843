/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type GetServiceProviderResponseDtoCompanyDtoContactDtoContactRoles =
  (typeof GetServiceProviderResponseDtoCompanyDtoContactDtoContactRoles)[keyof typeof GetServiceProviderResponseDtoCompanyDtoContactDtoContactRoles];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetServiceProviderResponseDtoCompanyDtoContactDtoContactRoles = {
  ADMIN: 'ADMIN',
  BUYER: 'BUYER',
  SELLER: 'SELLER',
  SERVICES: 'SERVICES',
  OPERATIONS: 'OPERATIONS',
  FINANCE: 'FINANCE',
  CHARTERING_MANAGER: 'CHARTERING_MANAGER',
  MI_USER: 'MI_USER',
} as const;

import { useEffect } from 'react';
import { Select, SelectProps, Skeleton } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useTranslation } from 'next-i18next';

import { useGetFilteredCompanyProducts } from '@/common/api-utils/use-filter-company-products';
import { useMapProductsToSelectData } from '@/common/mapping-helpers';

export interface ProductSelectProps extends Omit<SelectProps, 'data'> {
  companyId: string;
  productName?: string;
}

export function ProductSelect({ companyId, productName, ...props }: ProductSelectProps) {
  const { t } = useTranslation('cargoDetails');

  const {
    data: filteredProducts,
    isLoading,
    isFetched,
  } = useGetFilteredCompanyProducts(companyId, { byName: productName ?? '' });
  const productSelectItems = useMapProductsToSelectData(filteredProducts);

  useEffect(() => {
    if (isFetched && filteredProducts.length === 0) {
      showNotification({
        disallowClose: false,
        autoClose: 8000,
        color: 'red',
        title: t('errors.productsNotFound.title', { productName }),
        message: t('errors.productsNotFound.message'),
      });
    }
  }, [filteredProducts, t, isFetched, productName]);

  return isLoading ? (
    <Skeleton width="100%" height="55px" />
  ) : (
    <Select disabled={productSelectItems.length === 0} data={productSelectItems} {...props} />
  );
}

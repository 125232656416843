import { ActionIcon, Anchor, Box, BoxProps, Group, Loader, Tooltip, packSx, MantineTheme } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { RiCloseCircleFill } from 'react-icons/ri';

import { Text } from '@/components';
import { truncateString } from '@/core/helpers/truncateString';

import { downloadAFile } from './downloadAFile';
import { useLazyUrl } from './useLazyUrl';

type styleVariants = 'filled' | 'transparent';

export interface DownloadFileProps extends BoxProps {
  filename: string;
  fileKey?: string;
  url: string | (() => Promise<string>);
  maxFilenameLength?: number;
  onDelete?: VoidFunction;
  variant?: styleVariants;
  icon?: React.ReactNode;
}

export function DownloadFile(props: DownloadFileProps) {
  const { filename, fileKey, url, maxFilenameLength, onDelete, sx, variant = 'filled', icon, ...rest } = props;

  const { fetchUrl, fileUrl, isFetching } = useLazyUrl(url, fileKey, filename);
  const [isFileFetching] = useDebouncedValue(isFetching, 200);

  const download = async () => {
    const url = await fetchUrl();
    downloadAFile(url, filename);
  };

  const getVariantStyles = (variant: styleVariants, theme: MantineTheme) => {
    switch (variant) {
      case 'filled':
        return {
          box: {
            borderRadius: 36,
            backgroundColor: theme.colors.gray[2],
          },
          anchor: {
            color: isFileFetching ? theme.colors.dark[1] : theme.colors.primary,
          },
        };
      case 'transparent':
        return {
          box: {
            paddingLeft: 0,
            paddingRight: 0,
            color: theme.colors.primary[1],
          },
          anchor: {
            color: theme.colors.primary[1],
          },
        };
    }
  };

  return (
    <Tooltip
      label={filename}
      maw={200}
      disabled={maxFilenameLength ? !!filename && filename.length <= maxFilenameLength : true}
      multiline
      withArrow
    >
      <Box
        sx={[
          (theme) => ({
            padding: '4px 12px',
            width: 'max-content',
            ...getVariantStyles(variant, theme).box,
          }),
          ...packSx(sx),
        ]}
        onFocus={() => fetchUrl()}
        onMouseEnter={() => fetchUrl()}
        {...rest}
      >
        <Group spacing={8} noWrap>
          {icon}
          <Anchor
            href={fileUrl}
            download
            underline={variant === 'filled'}
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              transform: 'translateY(-1px)',
              ...getVariantStyles(variant, theme).anchor,
            })}
            onClick={(event) => {
              event.stopPropagation();

              if (!fileUrl && !isFetching) {
                download();
              }
            }}
          >
            <Text weight={600}>{maxFilenameLength ? truncateString(filename, maxFilenameLength) : filename}</Text>
            {isFileFetching && <Loader ml={8} mt={4} size={12} />}
          </Anchor>
          {onDelete && (
            <ActionIcon variant="transparent" onClick={onDelete} size={24} data-testid="delete-document-button">
              <RiCloseCircleFill />
            </ActionIcon>
          )}
        </Group>
      </Box>
    </Tooltip>
  );
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type ShipmentReferenceType = (typeof ShipmentReferenceType)[keyof typeof ShipmentReferenceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShipmentReferenceType = {
  PURCHASE: 'PURCHASE',
  SALE: 'SALE',
} as const;

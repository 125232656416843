function isNumeric(n: string | number | undefined) {
  return typeof n === 'number' || (n !== undefined && n !== '' && !isNaN(+n) && !n.endsWith('.'));
}

interface FractionToPercentageOptions {
  suffix?: string;
  precision?: number;
}

export function transformToPercentage(value: string | number) {
  return (+value * 100).toFixed(2);
}

export function fractionToPercentage(value: string | number, options: FractionToPercentageOptions = {}) {
  const { suffix = '%', precision = 1 } = options;
  if (!isNumeric(value)) {
    return value;
  }

  const numberValue = Number(value) * 100;
  const decimalNumber = Number(numberValue.toString().split('.')?.[1]);

  let percentage = (decimalNumber ?? 0) < precision ? numberValue.toString() : numberValue.toFixed(precision);

  if (percentage.endsWith('.0')) {
    percentage = percentage.slice(0, -2);
  }

  return `${percentage}${suffix}`;
}

export function percentageToFraction(value: string | number): string {
  let preparedValue = typeof value === 'number' ? value : value?.replace(/%/, '');
  if (isNumeric(preparedValue)) {
    preparedValue = +preparedValue;
    const numberDigits = (preparedValue + '').split('.')?.[1]?.length || 0;

    return (preparedValue / 100).toFixed(preparedValue === 0 ? 0 : numberDigits + 2);
  }
  return preparedValue as string;
}

export function formatCurrency(
  value: number,
  currency: string,
  locale = 'en-US',
  minDecimals = 0,
  maxDecimals = 2
): string {
  if (!value) {
    return '-';
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'code',
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals,
  }).format(value);
}

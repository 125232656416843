import { InputProps } from '@mantine/core';

import { ThemeComponent } from './types';

export const Input: ThemeComponent<InputProps> = {
  styles: (theme) => ({
    rightSection: {
      color: theme.colors.gray[6],
      fontSize: 14,
      paddingRight: 12,
      width: 30,
      justifyContent: 'flex-end',
      whiteSpace: 'nowrap',
    },
  }),
};

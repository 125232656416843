import { useRouter } from 'next/router';
import { useStyles } from 'pages/register';
import { RiChat3Line } from 'react-icons/ri';
import { useTranslation } from 'next-i18next';
import { Group, Button, Indicator, ActionIcon } from '@mantine/core';
import { CellContext, ColumnDefTemplate } from '@tanstack/react-table';
import { useGetChatGroupsUnreadMessages } from 'api/generated/chat/chat';
import { UserRole, ArchivedCargoDTO } from 'api/generated/model';

import { routes } from '@/common/routes';
import { formatDate } from '@/common/format-date';
import { useMediaQuery } from '@/core/hooks/use-media-query';
import { Text, ActionMenu, HideForbiddenElement, ENABLE_SENDBIRD } from '@/components';
import { useEnablePrivateDataForCSM } from '@/common/use-enable-private-data-for-csm';
import { useAuthRoles } from '@/core';

import { ArchivedCargoTableActionFunction } from '../../types';
import { CargoIdRefIdCell, CompanyNameCell, LinkedEntitiesCell, VesselNameCell } from './TableCells';

type MyCargosArchivedTableCell = ColumnDefTemplate<CellContext<ArchivedCargoDTO, string>>;

export const ArchivedCargoIdRefIdCell: MyCargosArchivedTableCell = ({ row }) => {
  return (
    <CargoIdRefIdCell
      workflowId={row.original.id}
      shortId={row.original.shortId}
      externalRefId={row.original.externalRefId}
      isArchived={true}
    />
  );
};

export const ArchivedCompanyNameCell: MyCargosArchivedTableCell = ({ row }) => {
  return <CompanyNameCell companyId={row.original.companyId} companyName={row.original.companyName} />;
};

export const ArchivedVesselNameCell: MyCargosArchivedTableCell = ({ row }) => {
  return <VesselNameCell vesselIMO={row.original.vesselIMO} vesselName={row.original.vesselName} />;
};

export const BuyerArchivedDateCell: MyCargosArchivedTableCell = ({ row }) => {
  return (
    <Group spacing={8}>
      <Text color="gray.9" weight={500}>
        {formatDate(row.original.buyerArchivedDate)}
      </Text>
    </Group>
  );
};

export const SellerArchivedDateCell: MyCargosArchivedTableCell = ({ row }) => {
  return (
    <Group spacing={8}>
      <Text color="gray.9" weight={500}>
        {formatDate(row.original.sellerArchivedDate)}
      </Text>
    </Group>
  );
};

export const ArchivedLinkedEntitiesCell: MyCargosArchivedTableCell = ({ row }) => {
  return (
    <LinkedEntitiesCell
      linkedWorkflowId={row.original.linkedWorkflowId}
      linkedWorkflowShortId={row.original.linkedWorkflowShortId}
    />
  );
};

interface MyCargosArchivedTableActionCellProps extends CellContext<ArchivedCargoDTO, string> {
  onRowClick: ArchivedCargoTableActionFunction;
  onLinkCargoClick: ArchivedCargoTableActionFunction;
  onUnarchiveCargoClick: ArchivedCargoTableActionFunction;
}

export const MyCargosArchivedTableActionCell: ColumnDefTemplate<MyCargosArchivedTableActionCellProps> = ({
  row,
  onRowClick,
  onLinkCargoClick,
  onUnarchiveCargoClick,
}) => {
  const router = useRouter();
  const { theme } = useStyles();
  const { t } = useTranslation('myCargos');
  const isMobile = useMediaQuery(theme.breakpoints.md);
  const { onEnablePrivateDataForCSM } = useEnablePrivateDataForCSM();
  const { isServiceProvider } = useAuthRoles();
  const unreadMessageTotalCount = useGetChatGroupsUnreadMessages({
    query: {
      select: ({ data }) => data.length,
      enabled: ENABLE_SENDBIRD,
    },
  });

  const actions = [
    { label: t('table.menu.cmsEnablePrivacyData'), onClick: () => onEnablePrivateDataForCSM(row) },
    ...[{ label: t('table.menu.unarchiveCargo'), onClick: () => onUnarchiveCargoClick(row) }],
  ];

  return (
    <Group noWrap spacing={12} position="apart">
      {ENABLE_SENDBIRD && !isMobile && (
        <Indicator
          inline
          size={20}
          radius="xl"
          color={theme.colors.red[9]}
          label={unreadMessageTotalCount.data}
          onClick={() => router.push(routes.CargoWorkflowChat(row.original.id))}
          disabled={unreadMessageTotalCount.isLoading || unreadMessageTotalCount.data === 0}
          sx={{
            marginTop: '15px',
            lineHeight: '16px',
            transform: 'translate(7px, -7px)',
          }}
        >
          <ActionIcon variant="transparent">
            <RiChat3Line size={20} color={theme.fn.primaryColor()} />
          </ActionIcon>
        </Indicator>
      )}

      <Button variant="outline" onClick={() => onRowClick(row)}>
        {t('table.buttons.viewCargo')}
      </Button>

      <HideForbiddenElement
        allowUnverifiedCompany
        allowedRoles={[UserRole.BUYER, UserRole.SELLER, UserRole.FINANCE, UserRole.SERVICES, UserRole.OPERATIONS]}
      >
        {!isServiceProvider && <ActionMenu withinPortal actions={actions} position="bottom-end" />}
      </HideForbiddenElement>
    </Group>
  );
};

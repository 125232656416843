/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type OfferIncoterm = (typeof OfferIncoterm)[keyof typeof OfferIncoterm];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OfferIncoterm = {
  FOB: 'FOB',
  CFR: 'CFR',
} as const;

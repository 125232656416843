import { Button, Group, useMantineTheme } from '@mantine/core';
import { ExpireBadge } from 'components/expire-badge/ExpireBadge';
import { useTranslation } from 'next-i18next';

export interface ExpireBadgeWithButtonProps {
  isSeller: boolean;
  expiresAt: string;
  isRequested: boolean;
  onClick: VoidFunction;
}

export function ExpireBadgeWithButton(props: ExpireBadgeWithButtonProps) {
  const { isSeller, expiresAt, onClick, isRequested } = props;
  const { t } = useTranslation('common');
  const theme = useMantineTheme();
  const extensionTranslation = isSeller ? t('buttons.extend') : t('buttons.requestExtension');
  const shouldShowButton = isSeller || (!isRequested && !isSeller);

  return (
    <Group spacing={12} noWrap>
      <ExpireBadge expiresAt={expiresAt} onClick={onClick} isSeller={isSeller} isRequested={isRequested} />
      {shouldShowButton && (
        <Button
          size="sm"
          variant="subtle"
          px={0}
          color={theme.fn.primaryColor()}
          sx={{ fontSize: 14, lineHeight: '22px', fontWeight: 600 }}
          onClick={onClick}
        >
          {extensionTranslation}
        </Button>
      )}
    </Group>
  );
}

import { Button, Group, Stack } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { RiDeleteBin7Line } from 'react-icons/ri';

import { BackgroundIcon, BaseModal, Text } from '@/components';

const icon = <BackgroundIcon color="red" icon={RiDeleteBin7Line} size={20} />;

interface ContentProps {
  isLoading: boolean;
  onBack: VoidFunction;
  onConfirm: VoidFunction;
}

function Content(props: ContentProps) {
  const { t } = useTranslation('recapForm');
  const { isLoading, onBack, onConfirm } = props;

  return (
    <Stack>
      <Text color="gray.6" size="S">
        {t('modals.deleteTemplate.description')}
      </Text>

      <Group grow>
        <Button onClick={onBack} variant="outline" size="md">
          {t('buttons.back')}
        </Button>
        <Button onClick={onConfirm} size="md" loading={isLoading}>
          {t('buttons.confirm')}
        </Button>
      </Group>
    </Stack>
  );
}

export interface DeleteTemplateModalProps {
  opened: boolean;
  isLoading: boolean;
  toggle: VoidFunction;
  onDeleteTemplate: VoidFunction;
}

export function DeleteTemplateModal(props: DeleteTemplateModalProps) {
  const { opened, isLoading, toggle, onDeleteTemplate } = props;
  const { t } = useTranslation('recapForm');

  return (
    <BaseModal
      opened={opened}
      close={toggle}
      icon={icon}
      header={t('modals.deleteTemplate.heading')}
      content={<Content onBack={toggle} onConfirm={onDeleteTemplate} isLoading={isLoading} />}
    />
  );
}

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type UpdateShortlistedCompanyResponseDtoMarketRegions =
  (typeof UpdateShortlistedCompanyResponseDtoMarketRegions)[keyof typeof UpdateShortlistedCompanyResponseDtoMarketRegions];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateShortlistedCompanyResponseDtoMarketRegions = {
  NORTH_AMERICA: 'NORTH_AMERICA',
  LATIN_AMERICA: 'LATIN_AMERICA',
  WEST_AFRICA: 'WEST_AFRICA',
  MEDITERRANEAN_BASIN: 'MEDITERRANEAN_BASIN',
  EUROPE: 'EUROPE',
  MIDDLE_EAST_INDIAN_OCEAN: 'MIDDLE_EAST_INDIAN_OCEAN',
  SOUTH_EAST_ASIA_FAR_EAST_PACIFIC: 'SOUTH_EAST_ASIA_FAR_EAST_PACIFIC',
  OCEANIA: 'OCEANIA',
} as const;

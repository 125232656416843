import { SelectItem } from '@mantine/core';

export function uniq<T extends { id: string }>(items: T[], uniqByKey: keyof T = 'id'): T[] {
  return [...new Map(items.map((item) => [item[uniqByKey], item])).values()];
}

interface SelectLike {
  id: string;
  name: string;
}

export function toSelectItem(item: SelectLike): SelectItem {
  return { value: item.id, label: item.name };
}

export function sortSelectItems(items: SelectItem[]) {
  return items.sort((a, b) => a.label?.localeCompare(b.label));
}

import { showNotification } from '@mantine/notifications';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import { ApiErrorResponse } from 'api/generated/model';

import { isAxiosError, isClientError, isRestError } from './error-guards';

type HttpStatus = number;

export type DefaultHttpStatusMessages = Record<HttpStatus, string>;

export interface UseHandleFormErrorProps {
  translatePath: (path: string) => string | undefined;
  overrideMessage?: (status: HttpStatus, error: ApiErrorResponse) => string | undefined;
}

export function useHandleFormError(props: UseHandleFormErrorProps) {
  const { translatePath, overrideMessage } = props;
  const { t } = useTranslation('common');

  const handleError = useCallback(
    (e: unknown, defaultMessages: DefaultHttpStatusMessages = {}) => {
      const message = {
        title: t('error.unexpectedError.title'),
        message: t('error.unexpectedError.message'),
      };
      if (isAxiosError(e) && isClientError(e) && isRestError(e?.response?.data)) {
        const firstError = e.response.data.subErrors?.[0];
        message.title = t('error.validationError.title');
        if (!firstError || !translatePath(firstError.path)) {
          message.message = `${message.message} (${e.response.data.message})`;
        } else {
          const fieldName = translatePath(firstError.path);
          const msg = overrideMessage
            ? overrideMessage(e.response.status, e.response.data) || firstError.message
            : firstError.message;
          message.message = t('error.validationError.message', {
            fieldName,
            message: msg,
          });
        }
      } else if (isAxiosError(e) && defaultMessages[e.response.status]) {
        message.message = defaultMessages[e.response.status];
      }
      showNotification({ color: 'red', ...message });
    },
    [translatePath, t, overrideMessage]
  );

  return handleError;
}

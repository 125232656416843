import { createStyles, MantineColor } from '@mantine/core';

export const useIconWithBackgroundStyle = createStyles((theme, { color }: { color: MantineColor }) => {
  const isGray = color === 'gray';
  const iconColor = isGray ? theme.colors.gray[8] : theme.colors[color][2];
  const borderColor = isGray ? theme.colors.gray[4] : theme.colors[color][1];
  const backgroundColor = isGray ? theme.colors.gray[2] : theme.colors[color][0];

  return {
    icon: {
      color: iconColor,
    },
    themeIcon: {
      width: 40,
      height: 40,
      borderRadius: theme.radius.md,
      backgroundColor: backgroundColor,
      border: `1px solid ${borderColor}`,
    },
  };
});

import { defaultZDateRange } from '../../consts';
import { ProductGeneralInfoSchema } from '../product/product-general-info';

export const PRODUCT_GENERAL_INFO_INITIAL_VALUES: ProductGeneralInfoSchema = {
  product: '',
  quantity: 0,
  minTolerance: 0,
  maxTolerance: 0,
  originPlant: '',
  loadingPort: '',
  destinationPlant: '',
  dischargingPort: '',
  laycan: defaultZDateRange,
  productName: '',
  originPlantName: '',
  loadingPortName: '',
  destinationPlantName: '',
  dischargingPortName: '',
};

//Empty values
export const PRODUCT_GENERAL_INFO_EMPTY_VALUES = {
  product: '',
  quantity: undefined,
  destinationPlant: '',
  dischargingPort: '',
  loadingPort: '',
  originPlant: '',
  minTolerance: undefined,
  maxTolerance: undefined,
  laycan: undefined,
  productName: undefined,
  originPlantName: undefined,
  loadingPortName: undefined,
  destinationPlantName: undefined,
  dischargingPortName: undefined,
};

import { Drawer, Paper } from '@mantine/core';
import { useGetFreightNegotiationContractTemplateList } from 'api/generated/freight-contract-template/freight-contract-template';
import { useTranslation } from 'next-i18next';

import { Title } from '@/components';
import { EmptyResults } from '@/views/recap-form';

import { ActionsFunction } from '../types';
import { LoadFreightTemplateTable } from './LoadFreightTemplateTable';

export interface LoadFreightTemplateDrawerProps {
  opened: boolean;
  toggle: VoidFunction;
  onUseTemplate: ActionsFunction;
  onDeleteTemplate: ActionsFunction;
}

export function LoadFreightTemplateDrawer(props: LoadFreightTemplateDrawerProps) {
  const { opened, toggle, onUseTemplate, onDeleteTemplate } = props;
  const { t } = useTranslation('recapForm');
  const { data, isLoading } = useGetFreightNegotiationContractTemplateList({
    query: { select: ({ data }) => data.items, staleTime: 0 },
  });

  return (
    <Drawer opened={opened} onClose={toggle} position="right" padding={24}>
      <Title size="XL" mb={24}>
        {t('modals.loadTemplate.heading')}
      </Title>
      {data?.length ? (
        <LoadFreightTemplateTable
          data={data}
          isLoading={isLoading}
          onUseTemplate={onUseTemplate}
          onDelete={onDeleteTemplate}
        />
      ) : (
        <Paper>
          <EmptyResults />
        </Paper>
      )}
    </Drawer>
  );
}

import { Select, SelectProps } from '@mantine/core';

import { useGetCompanyPortsSelectItems } from '../state/use-get-company-ports-select-items';

export interface PortSelectProps extends Omit<SelectProps, 'data'> {
  companyId: string;
}

export function PortSelect({ companyId, ...props }: PortSelectProps) {
  const portSelectItems = useGetCompanyPortsSelectItems(companyId);

  return <Select data={portSelectItems} disabled={portSelectItems.length === 0} {...props} />;
}

import { Group, Stack } from '@mantine/core';
import { ReactNode } from 'react';

import { Text } from '@/components';

export interface FreightRecapFormActionItemProps {
  label: string;
  description: string;
  icon: ReactNode;
}

export function FreightRecapFormActionItem(props: FreightRecapFormActionItemProps) {
  const { label, description, icon } = props;

  return (
    <Group spacing="md">
      {icon}
      <Stack spacing={0}>
        <Text color="gray.9" weight={600}>
          {label}
        </Text>
        <Text size="S" color="background.2">
          {description}
        </Text>
      </Stack>
    </Group>
  );
}

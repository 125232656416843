import { Box, createStyles, Skeleton, Stack } from '@mantine/core';
import { useGetStandardPortById, useGetStandardPorts } from 'api/generated/masterdata/masterdata';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';

import { Text } from '@/components';
import { FreightRecapForm, FreightRequestForm } from '@/blink-forms';
import {
  HighlightProvider,
  HighlightSentenceDiff,
  HighlightSingleDiff,
  useRecapFormMasterdata,
} from '@/views/recap-form';

import * as argsSelector from '../utils/recap-preview-data-selectors';

const useStyles = createStyles(() => ({
  labeledValue: {
    '& > *': {
      marginRight: '4px',
    },
  },
}));

interface LabeledProps {
  label: string;
  children: ReactNode;
}

function Labeled(props: LabeledProps) {
  const { label, children } = props;
  const { classes } = useStyles();

  return (
    <Box>
      <Text component="span" weight={600} sx={{ flexShrink: 0 }}>
        {label}:&nbsp;
      </Text>
      <span className={classes.labeledValue}>
        {typeof children === 'object' ? children : <Text component="span">{children}</Text>}
      </span>
    </Box>
  );
}

export interface FreightRecapPreviewProps {
  currentForm: FreightRecapForm;
  companyName: string;
  requestForm: FreightRequestForm;
  prevForm?: FreightRecapForm;
}

export function FreightRecapPreview(props: FreightRecapPreviewProps) {
  const { currentForm, companyName, prevForm, requestForm } = props;
  const { t } = useTranslation('freightRecapForm');
  const masterdata = useRecapFormMasterdata();
  const masterdataTranslations = masterdata.translations.data;

  const isMasterdataLoading = masterdata.translations.isLoading || !currentForm || !requestForm;

  const ports = useGetStandardPorts({
    query: {
      select: ({ data }) => ({
        loadingPortId: data.items.find((port) => port.name === currentForm.product.loadingPort)?.id,
        dischargingPortId: data.items.find((port) => port.name === currentForm.product.dischargingPort)?.id,
      }),
      enabled: !isMasterdataLoading,
    },
  });
  const loadingPort = useGetStandardPortById(ports.data?.loadingPortId, {
    query: {
      select: ({ data }) => ({
        freshWaterSaltWater: data.freshWaterSaltWater,
      }),
      enabled: ports.isSuccess,
    },
  });
  const dischargingPort = useGetStandardPortById(ports.data?.dischargingPortId, {
    query: {
      select: ({ data }) => ({
        freshWaterSaltWater: data.freshWaterSaltWater,
      }),
      enabled: ports.isSuccess,
    },
  });

  const isPortsLoading = ports.isLoading || loadingPort.isLoading || dischargingPort.isLoading;

  return (
    <Stack spacing={16}>
      {isMasterdataLoading || isPortsLoading ? (
        <Skeleton width={'100%'} height={400} radius="md" />
      ) : (
        <HighlightProvider highlight={!!prevForm}>
          <Labeled label={t('preview.labels.vesselOwner')}>
            <HighlightSentenceDiff
              t={t}
              i18nKey="preview.sentences.vesselOwner"
              prevArgs={argsSelector.toVesselOwnerArgs(prevForm)}
              currentArgs={argsSelector.toVesselOwnerArgs(currentForm)}
            />
          </Labeled>
          <Labeled label={t('preview.labels.broker')}>Blink</Labeled>
          <Labeled label={t('preview.labels.charterer')}>{companyName}</Labeled>
          <Labeled label={t('preview.labels.freightPrice')}>
            <HighlightSentenceDiff
              t={t}
              i18nKey="preview.sentences.price"
              prevArgs={argsSelector.toPriceArgs(prevForm)}
              currentArgs={argsSelector.toPriceArgs(currentForm)}
            />
          </Labeled>
          <Labeled label={t('preview.labels.cargo')}>
            <HighlightSentenceDiff
              t={t}
              i18nKey="preview.sentences.cargo"
              prevArgs={argsSelector.toCargoArgs(prevForm, masterdataTranslations)}
              currentArgs={argsSelector.toCargoArgs(currentForm, masterdataTranslations)}
            />
          </Labeled>
          <Labeled label={t('preview.labels.laycan')}>
            <HighlightSentenceDiff
              t={t}
              i18nKey="preview.sentences.laycan"
              prevArgs={argsSelector.toLaycanArgs(prevForm)}
              currentArgs={argsSelector.toLaycanArgs(currentForm)}
            />
          </Labeled>
          <Labeled label={t('preview.labels.vesselInformation')}>
            {currentForm.vessel.type === 'TBN' ? (
              <HighlightSentenceDiff
                t={t}
                i18nKey="preview.sentences.vesselTBN"
                prevArgs={argsSelector.toVesselTbnArgs(prevForm)}
                currentArgs={argsSelector.toVesselTbnArgs(currentForm)}
              />
            ) : (
              <HighlightSentenceDiff
                t={t}
                i18nKey="preview.sentences.vesselNominated"
                prevArgs={argsSelector.toVesselNominatedArgs(prevForm)}
                currentArgs={argsSelector.toVesselNominatedArgs(currentForm)}
              />
            )}
            <HighlightSentenceDiff
              t={t}
              i18nKey="preview.sentences.vesselInformation"
              prevArgs={argsSelector.toVesselInformationArgs(prevForm)}
              currentArgs={argsSelector.toVesselInformationArgs(currentForm)}
            />
          </Labeled>
          <Labeled label={t('preview.labels.loadingPort')}>
            <HighlightSentenceDiff
              t={t}
              i18nKey="preview.sentences.loadingPort"
              prevArgs={argsSelector.toLoadingPortArgs(prevForm)}
              currentArgs={argsSelector.toLoadingPortArgs(currentForm)}
            />
          </Labeled>
          <Labeled label={t('preview.labels.loadingConditions')}>
            <HighlightSentenceDiff
              t={t}
              i18nKey="preview.sentences.loadingConditions"
              prevArgs={argsSelector.toLoadingConditionsArgs(prevForm, masterdataTranslations, loadingPort.data)}
              currentArgs={argsSelector.toLoadingConditionsArgs(currentForm, masterdataTranslations, loadingPort.data)}
            />
          </Labeled>
          <Labeled label={t('preview.labels.loadingPortOtherRestrictions')}>
            <HighlightSingleDiff current={requestForm.loadingPortOtherRestrictions} />
          </Labeled>
          <Labeled label={t('preview.labels.dischargingPort')}>
            <HighlightSentenceDiff
              t={t}
              i18nKey="preview.sentences.dischargingPort"
              prevArgs={argsSelector.toDischargingPortArgs(prevForm)}
              currentArgs={argsSelector.toDischargingPortArgs(currentForm)}
            />
          </Labeled>
          <Labeled label={t('preview.labels.dischargingConditions')}>
            <HighlightSentenceDiff
              t={t}
              i18nKey="preview.sentences.dischargingConditions"
              prevArgs={argsSelector.toDischargingConditionsArgs(
                prevForm,
                masterdataTranslations,
                dischargingPort.data
              )}
              currentArgs={argsSelector.toDischargingConditionsArgs(
                currentForm,
                masterdataTranslations,
                dischargingPort.data
              )}
            />
          </Labeled>
          <Labeled label={t('preview.labels.dischargingPortOtherRestrictions')}>
            <HighlightSingleDiff current={requestForm.dischargingPortOtherRestrictions} />
          </Labeled>
          <Labeled label={t('preview.labels.agents')}>
            <HighlightSentenceDiff
              t={t}
              i18nKey="preview.sentences.agents"
              prevArgs={argsSelector.toAgentsArgs(prevForm, masterdataTranslations)}
              currentArgs={argsSelector.toAgentsArgs(currentForm, masterdataTranslations)}
            />
          </Labeled>
          <Labeled label={t('preview.labels.freightPaymentTerms')}>
            <HighlightSentenceDiff
              t={t}
              i18nKey="preview.sentences.paymentTerms.prepayment"
              prevArgs={argsSelector.toPrepaymentTermsArgs(prevForm, masterdataTranslations)}
              currentArgs={argsSelector.toPrepaymentTermsArgs(currentForm, masterdataTranslations)}
            />
            <HighlightSentenceDiff
              t={t}
              i18nKey="preview.sentences.paymentTerms.ttAfterLoading"
              prevArgs={argsSelector.toAfterLoadingTermsArgs(prevForm)}
              currentArgs={argsSelector.toAfterLoadingTermsArgs(currentForm)}
            />
            <HighlightSentenceDiff
              t={t}
              i18nKey="preview.sentences.paymentTerms.ttBeforeBreakingBulk"
              prevArgs={argsSelector.toBeforeBreakingBullTermsArgs(prevForm)}
              currentArgs={argsSelector.toBeforeBreakingBullTermsArgs(currentForm)}
            />
            <HighlightSentenceDiff
              t={t}
              i18nKey="preview.sentences.paymentTerms.ttOpenTerms"
              prevArgs={argsSelector.toOpenTermsArgs(prevForm, masterdataTranslations)}
              currentArgs={argsSelector.toOpenTermsArgs(currentForm, masterdataTranslations)}
            />
            <HighlightSingleDiff
              prev={prevForm?.payment.conditions.other}
              current={currentForm?.payment.conditions.other}
            />
          </Labeled>
          <Labeled label={t('preview.labels.demurrage')}>
            <HighlightSentenceDiff
              t={t}
              i18nKey="preview.sentences.demurrageRate"
              prevArgs={argsSelector.toDemurrageRateArgs(prevForm, masterdataTranslations)}
              currentArgs={argsSelector.toDemurrageRateArgs(currentForm, masterdataTranslations)}
            />
            <HighlightSentenceDiff
              t={t}
              i18nKey="preview.sentences.demurrageEvent"
              prevArgs={argsSelector.toDemurrageEventArgs(prevForm, masterdataTranslations)}
              currentArgs={argsSelector.toDemurrageEventArgs(currentForm, masterdataTranslations)}
            />
            <HighlightSentenceDiff
              t={t}
              i18nKey="preview.sentences.laytimeCalculations"
              prevArgs={argsSelector.toLaytimeCalculationsArgs(prevForm, masterdataTranslations)}
              currentArgs={argsSelector.toLaytimeCalculationsArgs(currentForm, masterdataTranslations)}
            />
          </Labeled>
          <Labeled label={t('preview.labels.other')}>
            <HighlightSingleDiff prev={prevForm?.comments} current={currentForm?.comments} />
          </Labeled>
        </HighlightProvider>
      )}
    </Stack>
  );
}

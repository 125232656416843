import { useGetCompanyPlants } from 'api/generated/companies/companies';
import { PlantRelatedPorts } from 'api/generated/model/plantRelatedPorts';
import { PlantRelatedPort } from 'api/generated/model/plantRelatedPort';
import { PlantDTO } from 'api/generated/model';

export type UseGetFilteredCompanyPlantsFilters =
  | {
      byProductId: string;
      byType: keyof PlantRelatedPorts;
    }
  | {
      byProductId?: never;
      byType?: never;
    };

const hasPortProduct = (port: PlantRelatedPort, productId: string) =>
  port.products.some((product) => product.id === productId);

const hasPlantProduct = (
  plant: PlantDTO,
  type: keyof PlantRelatedPorts,
  productId: string,
  useProductFilter: boolean
) =>
  useProductFilter
    ? plant.relatedPorts[type].some((port) => hasPortProduct(port, productId))
    : plant.relatedPorts[type];

export function useGetFilteredCompanyPlants(
  companyId: string,
  { byProductId, byType }: UseGetFilteredCompanyPlantsFilters,
  useProductFilter = true
) {
  return useGetCompanyPlants(companyId, {
    query: {
      select: ({ data }) => {
        return data.items.filter((plant) => hasPlantProduct(plant, byType, byProductId, useProductFilter));
      },
    },
  });
}

import { createStyles } from '@mantine/core';
import {
  ColumnDef,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { ReactElement, useEffect, useState } from 'react';

import { BaseTable, BaseTableCard, RenderBasicRow } from './BaseTable';

const useStyles = createStyles((theme) => ({
  expandedCategory: {
    background: theme.colors.background[1],
  },
  expandedRow: {
    background: theme.white,
  },
}));

export interface RenderGroupTableRowProps<TData> {
  row: Row<TData>;
  isExpanded: boolean;
  toggleExpand: () => void;
  subrows: Row<TData>[];
}

export interface GrouppedTableProps<TData, TValue> {
  data: TData[];
  columns: ColumnDef<TData, TValue>[];
  isLoading: boolean;
  grouping: string[];
  autoExpanded?: ExpandedState;
  renderGroupRow?: (props: RenderGroupTableRowProps<TData>) => ReactElement;
  hideHeader?: boolean;
  autoResetAll?: boolean;
  className?: string;
}

export function GrouppedTable<TData extends { id: string }, TValue>(props: GrouppedTableProps<TData, TValue>) {
  const { classes, cx } = useStyles();
  const { data, columns, isLoading, grouping, renderGroupRow: GroupRow, autoExpanded, hideHeader, className } = props;

  const [sorting, setSorting] = useState<SortingState>([]);
  const [expanded, setExpanded] = useState<ExpandedState>(autoExpanded || {});
  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
      grouping,
      sorting,
      columnVisibility: grouping.reduce((acc, curr) => ({ ...acc, [curr]: false }), {}),
    },
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    autoResetExpanded: false,
    autoResetAll: false,
  });

  useEffect(() => {
    autoExpanded && table.setExpanded(autoExpanded || {});
  }, [data, autoExpanded]);

  return (
    <BaseTable
      table={table}
      isLoading={isLoading}
      hideHeader={hideHeader}
      className={className}
      renderRow={({ row }) =>
        row.getCanExpand() && GroupRow ? (
          <tr className={cx(row.getIsExpanded() && classes.expandedCategory)}>
            <td colSpan={row.getVisibleCells().length}>
              <GroupRow
                row={row}
                isExpanded={row.getIsExpanded()}
                toggleExpand={row.toggleExpanded}
                subrows={row.subRows}
              />
            </td>
          </tr>
        ) : (
          <RenderBasicRow row={row} className={cx(row.depth > 0 && classes.expandedRow)} />
        )
      }
    />
  );
}

GrouppedTable.Card = BaseTableCard;

import { Anchor, createStyles } from '@mantine/core';
import { Trans, useTranslation } from 'next-i18next';

import { MAIL_TO_CONTACT_URL } from '@/common/consts';
import { Text, TextProps } from '@/components';

type Align = 'start' | 'end';

const useStyles = createStyles((theme, { align }: { align: Align }) => ({
  text: {
    alignSelf: `flex-${align}`,
    color: theme.colors.gray[7],
  },
  anchor: {
    fontWeight: 600,
    paddingBottom: 4,
    borderBottom: '1px solid',
  },
}));

interface NeedHelpProps extends TextProps {
  align?: Align;
}

export function NeedHelp({ align = 'end', ...props }: NeedHelpProps) {
  const { t } = useTranslation('common');
  const { classes } = useStyles({ align });

  return (
    <Text size="S" className={classes.text} {...props}>
      <Trans t={t} i18nKey="help">
        Need help? {<Anchor underline={false} className={classes.anchor} href={MAIL_TO_CONTACT_URL} key="link" />}
      </Trans>
    </Text>
  );
}

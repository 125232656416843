import { CellContext, ColumnDefTemplate } from '@tanstack/react-table';
import { CargoNegotiationDTO, NegotiationStatus } from 'api/generated/model';

import { NegotiationBadge } from '@/views/my-cargos';

export const NegotiationTableBadge: ColumnDefTemplate<CellContext<CargoNegotiationDTO, NegotiationStatus>> = ({
  row,
  getValue,
}) => {
  return <NegotiationBadge status={getValue()} hasDraft={row.original.hasDraft} />;
};

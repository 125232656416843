import { z } from 'zod';

import { Incoterm, PaymentTerms } from '../../enums';
import { Currency } from '../../types';

export const pricingAndPaymentTerms = z.object({
  incoterm: z
    .nativeEnum(Incoterm)
    .nullable()
    .refine((val) => val !== null, {
      message: 'This field is required',
    }),
  paymentTerms: z
    .nativeEnum(PaymentTerms)
    .nullable()
    .refine((val) => val !== null, {
      message: 'This field is required',
    }),
  price: z.number().gt(0),
  currency: z
    .nativeEnum(Currency)
    .nullable()
    .refine((val) => val !== null, {
      message: 'This field is required',
    }),
});

export type PricingAndPaymentTermsSchema = z.infer<typeof pricingAndPaymentTerms>;

import { ActionIcon, Button, Group, useMantineTheme } from '@mantine/core';
import { CellContext, ColumnDefTemplate } from '@tanstack/react-table';
import { FreightRecapFormTemplateListItemDTO } from 'api/generated/model';
import { useTranslation } from 'next-i18next';
import { RiDeleteBin7Line } from 'react-icons/ri';

import { Table } from '@/components';

import { useFreightTemplateColumns } from '../state/use-freight-template-columns';
import { ActionsFunction } from '../types';

export interface LoadFreightTemplateTableProps {
  data: FreightRecapFormTemplateListItemDTO[];
  isLoading: boolean;
  onDelete: ActionsFunction;
  onUseTemplate: ActionsFunction;
}

export function LoadFreightTemplateTable(props: LoadFreightTemplateTableProps) {
  const { data, isLoading, onDelete, onUseTemplate } = props;
  const columns = useFreightTemplateColumns({ onDelete, onUseTemplate });

  return (
    <Table.Card bordered>
      <Table data={data} columns={columns} isLoading={isLoading} />
    </Table.Card>
  );
}

interface ActionsCellProps extends CellContext<FreightRecapFormTemplateListItemDTO, string> {
  onDelete: ActionsFunction;
  onUseTemplate: ActionsFunction;
}

const ActionsCell: ColumnDefTemplate<ActionsCellProps> = ({ row, onDelete, onUseTemplate }) => {
  const { t } = useTranslation('recapForm');
  const theme = useMantineTheme();

  return (
    <Group spacing={8} noWrap>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          onUseTemplate(row);
        }}
      >
        {t('buttons.useTemplate')}
      </Button>
      <ActionIcon
        variant="transparent"
        onClick={(e) => {
          e.stopPropagation();
          onDelete(row);
        }}
      >
        <RiDeleteBin7Line color={theme.colors.gray[6]} />
      </ActionIcon>
    </Group>
  );
};

LoadFreightTemplateTable.ActionsCell = ActionsCell;

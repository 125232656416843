import { useGetCompanyProducts } from 'api/generated/companies/companies';

export interface Filters {
  byName?: string;
}

export function useGetFilteredCompanyProducts(companyId: string, { byName }: Filters = { byName: '' }) {
  return useGetCompanyProducts(companyId, {
    query: {
      select: ({ data }) => data.items.filter((item) => item.name.includes(byName)),
    },
  });
}

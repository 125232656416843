/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type RecentCargoTypeEnum = (typeof RecentCargoTypeEnum)[keyof typeof RecentCargoTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RecentCargoTypeEnum = {
  NEED: 'NEED',
  OFFER: 'OFFER',
} as const;

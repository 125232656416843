import { Anchor, Group } from '@mantine/core';
import NextLink from 'next/link';
import { ReactNode } from 'react';

interface LinkWithIconProps {
  icon: ReactNode;
  href: string;
  children: ReactNode;
  iconSide?: 'left' | 'right';
}

export function LinkWithIcon(props: LinkWithIconProps) {
  const { icon, href, children, iconSide = 'left' } = props;

  return (
    <Anchor weight={500} component={NextLink} href={href} onClick={(e) => e.stopPropagation()}>
      <Group spacing={4}>
        {iconSide === 'left' && icon}
        {children}
        {iconSide === 'right' && icon}
      </Group>
    </Anchor>
  );
}

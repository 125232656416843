/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoTenderDemurrageDTORateEvent =
  (typeof CargoTenderDemurrageDTORateEvent)[keyof typeof CargoTenderDemurrageDTORateEvent];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoTenderDemurrageDTORateEvent = {
  FIXED_AT_RECAP: 'FIXED_AT_RECAP',
  TO_BE_ADVISED_WITH_VESSEL_NOMINATION: 'TO_BE_ADVISED_WITH_VESSEL_NOMINATION',
} as const;

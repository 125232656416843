import { useTranslation } from 'next-i18next';

import { Text } from '@/components';

export function FreightRecapFormPreviewTitle() {
  const { t } = useTranslation('freightRecapForm');

  return (
    <Text weight={600} color="gray.9">
      {t('headers.preview')}
    </Text>
  );
}

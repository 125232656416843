import { Drawer, Paper } from '@mantine/core';
import { useGetContractTemplates } from 'api/generated/contract-template/contract-template';
import { CargoTransportation, NegotiationSide } from 'api/generated/model';
import { useTranslation } from 'next-i18next';

import { Title } from '@/components';
import { useAuthRoles } from '@/core';
import { EmptyResults } from '@/views/recap-form';

import { ActionsFunction } from '../types';
import { LoadTemplateTable } from './LoadTemplateTable';

export interface LoadTemplateDrawerProps {
  opened: boolean;
  negotiationSide: NegotiationSide;
  offeredTransportation: CargoTransportation;
  toggle: VoidFunction;
  onUseTemplate: ActionsFunction;
  onDeleteTemplate: ActionsFunction;
}

export function LoadTemplateDrawer(props: LoadTemplateDrawerProps) {
  const { opened, negotiationSide, offeredTransportation, toggle, onUseTemplate, onDeleteTemplate } = props;
  const { companyId } = useAuthRoles();
  const { data, isLoading } = useGetContractTemplates(
    companyId,
    { negotiationSide, offeredTransportation },
    { query: { select: (response) => response.data.items, staleTime: 0 } }
  );
  const { t } = useTranslation('recapForm');

  return (
    <Drawer opened={opened} onClose={toggle} position="right" padding={24}>
      <Title size="XL" mb={24}>
        {t('modals.loadTemplate.heading')}
      </Title>
      {data?.length ? (
        <LoadTemplateTable
          data={data}
          onUseTemplate={onUseTemplate}
          onDelete={onDeleteTemplate}
          isLoading={isLoading}
        />
      ) : (
        <Paper>
          <EmptyResults />
        </Paper>
      )}
    </Drawer>
  );
}

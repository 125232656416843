import { Button as ManButton, ButtonProps as ManButtonProps } from '@mantine/core';

type BaseButtonProps = ManButtonProps & {
  label: string;
  onClick: VoidFunction;
};

export const BaseButton = ({ label, onClick, ...props }: BaseButtonProps) => {
  return (
    <ManButton {...props} onClick={onClick}>
      {label}
    </ManButton>
  );
};

/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoTenderNegotiationStatus =
  (typeof CargoTenderNegotiationStatus)[keyof typeof CargoTenderNegotiationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoTenderNegotiationStatus = {
  PARTICIPATION_REQUESTED: 'PARTICIPATION_REQUESTED',
  PARTICIPATION_APPROVED: 'PARTICIPATION_APPROVED',
  PARTICIPANT_COUNTEROFFER: 'PARTICIPANT_COUNTEROFFER',
  CREATOR_COUNTEROFFER: 'CREATOR_COUNTEROFFER',
  PARTICIPANT_ACCEPTED: 'PARTICIPANT_ACCEPTED',
  CREATOR_ACCEPTED: 'CREATOR_ACCEPTED',
  PARTICIPANT_REJECTED: 'PARTICIPANT_REJECTED',
  CREATOR_REJECTED: 'CREATOR_REJECTED',
  CONTRACTED: 'CONTRACTED',
} as const;

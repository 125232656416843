import { useTranslation, Trans } from 'next-i18next';
import { CargoPromotionDTO } from 'api/generated/model';

import { formatDate } from '@/common/format-date';

interface CargoPromotionInstructionsProps {
  cargoPromotion: CargoPromotionDTO;
}

export function CargoPromotionInstructions({ cargoPromotion }: CargoPromotionInstructionsProps) {
  const { t } = useTranslation('cargoListings');

  return (
    <Trans
      t={t}
      i18nKey={cargoPromotion.tenderingRules ? 'instructionsWithRules' : 'instructions'}
      values={{
        rules: cargoPromotion.tenderingRules,
        ships: cargoPromotion.numberOfShipments,
        from: formatDate(cargoPromotion.contractPeriodFrom),
        to: formatDate(cargoPromotion.contractPeriodTo),
      }}
    >
      <strong>Instruction header</strong>
      Tendering rules
    </Trans>
  );
}

import { z } from 'zod';

import {
  Currency,
  CostsTerms,
  BerthConditions,
  ExclusionFromLayTime,
  LaytimeCalculations,
  NorTendering,
  TurnTimeTerms,
  FreightTerms,
  FreightPeriodTerms,
  CostsTermsWithoutShared,
} from '../types';
import { cargoTenderFormGroupSchema } from './cargo-tender-group';
import { cargoTenderDemurrageSchema } from './cargo-tender/cargo-tender-demurrage';
import { cargoTenderSamplingAnalysisSchema } from './cargo-tender-sampling-analysis';

export const cargoTenderNegotiationCargoDeclarationSectionSchema = z.object({
  minQuantityPerVessel: z.number(),
  maxQuantityPerVessel: z.number(),
  minShipmentTolerance: z.number(),
  maxShipmentTolerance: z.number(),
  daysForDeclaration: z.number(),
});

export const cargoTenderNegotiationVesselInformationSectionSchema = z.object({
  maxAge: z.number().optional(),
  vesselsNominationDays: z.number().optional(),
  requiredParticulars: z.string(),
  other: z.string().optional(),
});

export const cargoTenderNegotiationSurveyorWeightSectionSchema = z.object({
  other: z.string().optional(),
  surveyorAtLoadingPortCost: z.nativeEnum(CostsTerms),
  surveyorAtLoadingPortName: z.string().optional(),
  surveyorAtLoadingPortConditions: z.string().optional(),
  surveyorAtDischargePortCost: z.nativeEnum(CostsTerms),
  surveyorAtDischargePortName: z.string().optional(),
  surveyorAtDischargePortConditions: z.string().optional(),
});

export const cargoTenderNegotiationDischargingConditionsSectionSchema = z
  .object({
    safePortNo: z.number(),
    safeBerthNo: z.number(),
    safeBerthConditions: z.nativeEnum(BerthConditions),
    freightRate: z.number(),
    freightTerms: z.nativeEnum(FreightTerms),
    freightPeriodTerms: z.nativeEnum(FreightPeriodTerms),
    norTendering: z.nativeEnum(NorTendering),
    turnTime: z.number(),
    turnTimeTerms: z.nativeEnum(TurnTimeTerms),
    exclusionFromLayTime: z.array(z.nativeEnum(ExclusionFromLayTime)).optional(),
    exclusionFromLayTimeOther: z.string().optional(),
    laytimeCalculations: z.array(z.nativeEnum(LaytimeCalculations)).optional(),
    cargoPda: z.nativeEnum(CostsTermsWithoutShared),
    vesselPda: z.nativeEnum(CostsTermsWithoutShared),
    other: z.string().optional(),
  })
  .refine(
    (data) => {
      return (
        !(data.exclusionFromLayTime && data.exclusionFromLayTime.includes(ExclusionFromLayTime.OTHERS)) ||
        (data.exclusionFromLayTimeOther && data.exclusionFromLayTimeOther.trim().length > 0)
      );
    },
    {
      message: 'Other exclusion is required when OTHERS is selected.',
      path: ['exclusionFromLayTimeOther'],
    }
  );

export const cargoTenderNegotiationAgentsInPortSectionSchema = z.object({
  mainAgentConditions: z.nativeEnum(CostsTerms),
  mainAgentName: z.string().optional(),
  protectiveAgentConditions: z.nativeEnum(CostsTerms),
  protectiveAgentName: z.string().optional(),
  other: z.string().optional(),
});

export const cargoTenderNegotiationFormGroupSchema = z
  .object({
    price: z.number().min(1),
    priceCurrency: z.nativeEnum(Currency),
    preferredDestinations: z.string().optional(),
    preferredOrigins: z.string().optional(),
  })
  .merge(cargoTenderFormGroupSchema)
  .extend({
    cargoDeclaration: cargoTenderNegotiationCargoDeclarationSectionSchema,
    surveyorWeight: cargoTenderNegotiationSurveyorWeightSectionSchema,
    dischargingConditions: cargoTenderNegotiationDischargingConditionsSectionSchema,
    vesselInformation: cargoTenderNegotiationVesselInformationSectionSchema,
    demurrage: cargoTenderDemurrageSchema.optional(),
    samplingAnalysis: cargoTenderSamplingAnalysisSchema,
    agentsInPort: cargoTenderNegotiationAgentsInPortSectionSchema,
  });

export type CargoTenderNegotiationAgentsInPortSection = z.infer<typeof cargoTenderNegotiationAgentsInPortSectionSchema>;
export type CargoTenderNegotiationDischargingConditionsSection = z.infer<
  typeof cargoTenderNegotiationDischargingConditionsSectionSchema
>;
export type CargoTenderNegotiationFormCargoDeclarationSection = z.infer<
  typeof cargoTenderNegotiationCargoDeclarationSectionSchema
>;
export type CargoTenderNegotiationFormVesselInformationSection = z.infer<
  typeof cargoTenderNegotiationVesselInformationSectionSchema
>;

export type CargoTenderNegotiationFormSurveyorWeightSection = z.infer<
  typeof cargoTenderNegotiationSurveyorWeightSectionSchema
>;

export type CargoTenderNegotiationFormGroupSchema = z.infer<typeof cargoTenderNegotiationFormGroupSchema>;

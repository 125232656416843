import { Group, GroupProps } from '@mantine/core';
import { ReactNode } from 'react';
import { IconType } from 'react-icons/lib';

import { Text } from '@/components';

interface CargoFieldProps extends GroupProps {
  icon?: IconType;
  label: string;
  description: ReactNode;
}

export function CargoField(props: CargoFieldProps) {
  const { label, description, icon: Icon, ...rest } = props;

  return (
    <Group align="flex-start" position="apart" noWrap {...rest}>
      <Group spacing={8} sx={(theme) => ({ color: theme.colors.background[2] })} role="term">
        {Icon && <Icon />}
        <Text size="S">{label}</Text>
      </Group>
      {typeof description === 'string' || typeof description === 'number' ? (
        <Text size="S" color="gray.8" weight={500}>
          {description}
        </Text>
      ) : (
        description
      )}
    </Group>
  );
}

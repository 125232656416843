import * as Sentry from '@sentry/nextjs';

import { arrayBufferToBase64 } from '@/core/helpers/arrayBufferToBase64.helper';

import { subscribePushNotifications } from '../../api/generated/notifications/notifications';

async function getServiceWorkerRegistration() {
  const registration = await navigator.serviceWorker.getRegistration();

  if (!registration) {
    const error = new Error('Unable to get a service worker registration');
    error.name = 'ServiceWorkerRegistrationNotReceived';

    throw error;
  }

  return registration;
}

async function getOrCreatePushManagerSubscription(registration: ServiceWorkerRegistration) {
  const subscription: PushSubscription | null = await registration.pushManager.getSubscription();

  if (subscription) return subscription;

  return registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: process.env.NEXT_PUBLIC_VAPID_PUBLIC_KEY,
  });
}

export async function subscribeServiceWorkerToPushManager() {
  let registration: ServiceWorkerRegistration;
  let subscription: PushSubscription;

  try {
    registration = await getServiceWorkerRegistration();
    subscription = await getOrCreatePushManagerSubscription(registration);
  } catch (e) {
    if (e.name === 'ServiceWorkerRegistrationNotReceived') Sentry.captureException(e);
    else Sentry.captureException(new Error(`Failed to subscribe to push notifications. Error: ${e}`));

    return;
  }

  if (subscription) {
    try {
      const [p256dh, auth] = [subscription.getKey('p256dh'), subscription.getKey('auth')].map(arrayBufferToBase64);
      subscribePushNotifications({
        endpoint: subscription.endpoint,
        p256dh,
        auth,
      });
    } catch (e) {
      Sentry.captureException(new Error(`Failed to subscribe to push notifications. Error: ${e}`));
    }
  }
}

import { SVGProps } from 'react';

type CookieIconProps = SVGProps<SVGSVGElement>;

export function CookieIcon(props: CookieIconProps) {
  const { ...rest } = props;

  return (
    <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M14 13.3359C15.1046 13.3359 16 12.4405 16 11.3359C16 10.2314 15.1046 9.33594 14 9.33594C12.8954 9.33594 12 10.2314 12 11.3359C12 12.4405 12.8954 13.3359 14 13.3359Z"
        fill="currentColor"
      />
      <path
        d="M11.334 20C12.4386 20 13.334 19.1046 13.334 18C13.334 16.8954 12.4386 16 11.334 16C10.2294 16 9.33398 16.8954 9.33398 18C9.33398 19.1046 10.2294 20 11.334 20Z"
        fill="currentColor"
      />
      <path
        d="M19.9993 21.3307C20.7357 21.3307 21.3327 20.7338 21.3327 19.9974C21.3327 19.261 20.7357 18.6641 19.9993 18.6641C19.263 18.6641 18.666 19.261 18.666 19.9974C18.666 20.7338 19.263 21.3307 19.9993 21.3307Z"
        fill="currentColor"
      />
      <path
        d="M29.266 14.6535C26.8794 14.6135 24.3327 12.0535 25.6927 9.02688C21.7327 10.3602 17.986 6.90688 18.7727 2.94688C9.47935 0.986878 2.66602 8.54688 2.66602 16.0002C2.66602 23.3602 8.63935 29.3335 15.9993 29.3335C23.8527 29.3335 30.0527 22.5602 29.266 14.6535ZM15.9993 26.6669C10.1193 26.6669 5.33268 21.8802 5.33268 16.0002C5.33268 11.5869 8.97268 5.09354 16.106 5.30688C16.666 8.69354 19.3593 11.3869 22.7593 11.8935C22.8527 12.3735 23.4527 15.2935 26.6527 16.7335C26.266 22.4802 21.4127 26.6669 15.9993 26.6669Z"
        fill="currentColor"
      />
    </svg>
  );
}

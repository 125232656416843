import { z } from 'zod';

import { CargoTenderShipmentFrequency, zDateRange } from '../types';
import { MAX_SHIPMENTS, MIN_SHIPMENTS } from '../consts';

export const shipmentsByDateSchema = z.object({
  from: z.date(),
  to: z.date(),
});

export const fixedQuantitySchema = z
  .object({
    minTotalQuantity: z.number(),
    maxTotalQuantity: z.number(),
    shipmentToleranceMin: z.number(), // no validation - only passthrough to API
    shipmentToleranceMax: z.number(), // no validation - only passthrough to API
    frequency: z.nativeEnum(CargoTenderShipmentFrequency),
    minEstimatedShipments: z.number().min(MIN_SHIPMENTS).max(MAX_SHIPMENTS),
    maxEstimatedShipments: z.number().min(MIN_SHIPMENTS).max(MAX_SHIPMENTS),
    shipmentsByDate: z.array(zDateRange.optional()).optional(),
    isNegotiation: z.boolean().optional(),
  })
  .refine(
    (data) => {
      if (data.isNegotiation) {
        if (data.frequency === CargoTenderShipmentFrequency.BY_DATES) {
          const allDates = data.shipmentsByDate?.filter((date) => date);
          const lengthCorrect = allDates?.length === data.maxEstimatedShipments;
          return lengthCorrect;
        }
        return true;
      }
      return true;
    },
    {
      message: 'Shipment dates are required',
      path: ['shipmentsByDate'],
    }
  );

export const optionalQuantitySchema = z.object({
  minTotalQuantity: z.number(),
  maxTotalQuantity: z.number(),
  maxEstimatedShipments: z.number(),
  shipmentToleranceMin: z.number(), // no validation - only passthrough to API
  shipmentToleranceMax: z.number(), // no validation - only passthrough to API
});

export function downloadAFile(url: string, filename: string) {
  const link = document.createElement('a');

  link.href = url;
  link.download = filename;
  link.style['display'] = 'none';

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
}

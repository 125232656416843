/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type FreightNegotiationStepDetailsStepSide =
  (typeof FreightNegotiationStepDetailsStepSide)[keyof typeof FreightNegotiationStepDetailsStepSide];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FreightNegotiationStepDetailsStepSide = {
  TRADER: 'TRADER',
  CHARTERER: 'CHARTERER',
} as const;

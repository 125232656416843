/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */

export type CargoTransportation = (typeof CargoTransportation)[keyof typeof CargoTransportation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CargoTransportation = {
  CFR: 'CFR',
  FOB: 'FOB',
  CIF: 'CIF',
} as const;

import { Global } from '@mantine/core';

export function SendbirdStyles() {
  return (
    <Global
      styles={(theme) => ({
        '.sendbird-channel-list': {
          width: '100%',
        },
        '.sendbird-theme--light .sendbird-conversation': {
          border: 'none',
        },
        '.sendbird-message-content-reactions, .sendbird-message-item-reaction-menu, #sendbird-emoji-list-portal, .sendbird-message-status, .sendbird-message-content__left.use-reactions.incoming':
          {
            display: 'none !important',
          },
      })}
    />
  );
}

import { ReactNode } from 'react';
import { Box, Flex, Grid, Group, Stack } from '@mantine/core';
import { Trans, useTranslation } from 'next-i18next';
import { FaAsterisk } from 'react-icons/fa6';

import { FormCardSection, Text, Title } from '@/components';
import { PrepaymentEvents } from '@/blink-forms';
import { useRecapFormContext } from '@/views/recap-form';
import { getFixedNumberProps } from '@/common/get-fixed-number-props';
import { useGetSelectDataWithTranslations } from '@/views/tenders/utils/use-get-select-data-with-translations';

import { SelectedPaymentConditions } from '../types';
import { Radio, Select, Checkbox, TextInput, NumberInput, getBasePercentageProps } from './RecapFormElements';

interface PaymentConditionCheckboxProps {
  children: ReactNode;
  conditionKey: keyof SelectedPaymentConditions;
}

const PaymentConditionCheckbox = ({ conditionKey, children }: PaymentConditionCheckboxProps) => {
  const { t } = useTranslation('recapForm');
  const { selectedPaymentConditions, togglePaymentCondition } = useRecapFormContext();
  return (
    <>
      <Checkbox
        label={t(`labels.${String(conditionKey)}`)}
        {...selectedPaymentConditions[conditionKey]}
        onChange={() => togglePaymentCondition(conditionKey)}
        size="sm"
        data-testid={t(`labels.${String(conditionKey)}`)}
      />
      {selectedPaymentConditions[conditionKey].checked && children}
    </>
  );
};

export const PaymentFormGroup = () => {
  const { t } = useTranslation('recapForm');
  const { form, getInputProps, masterdata } = useRecapFormContext();

  const isDemurrageRadioDirty = (value: string) => form.isDirty('payment.demurrage.type') && demurrage.type === value;

  const { demurrage } = form.values.payment;
  const PrepaymentEventsData = useGetSelectDataWithTranslations(PrepaymentEvents);

  return (
    <Stack spacing={24}>
      <FormCardSection>
        <Flex align="center">
          <Title size="M" fw={700}>
            {t('sections.paymentConditions')}
          </Title>
          <Box ml={4} mb={6}>
            <FaAsterisk size="8" color="red" />
          </Box>
        </Flex>
        <PaymentConditionCheckbox conditionKey="prepayment">
          <Group>
            <Trans
              t={t}
              i18nKey="formSentences.prepayment"
              components={{
                days: (
                  <NumberInput
                    w={56}
                    {...getInputProps('payment.conditions.prepayment.days')}
                    data-testid={'prepayment-day'}
                  />
                ),
                percentage: (
                  <NumberInput
                    w={70}
                    {...getBasePercentageProps()}
                    {...getInputProps('payment.conditions.prepayment.percentage')}
                    data-testid={'prepayment-percentage'}
                  />
                ),
                select: (
                  <Select
                    {...getInputProps('payment.conditions.prepayment.event')}
                    grow={false}
                    data={PrepaymentEventsData}
                    data-testid={'prepayment-select'}
                  />
                ),
              }}
            />
          </Group>
        </PaymentConditionCheckbox>
        <PaymentConditionCheckbox conditionKey="cashAgainstDocuments">
          <Group>
            <Trans
              t={t}
              i18nKey="formSentences.cashAgainstDocuments"
              components={{
                days: <NumberInput w={56} {...getInputProps('payment.conditions.cashAgainstDocuments.days')} />,
                text: <TextInput {...getInputProps('payment.conditions.cashAgainstDocuments.numberOfCopies')} />,
                percentage: (
                  <NumberInput
                    {...getBasePercentageProps()}
                    {...getInputProps('payment.conditions.cashAgainstDocuments.percentage')}
                    w={70}
                  />
                ),
              }}
            />
          </Group>
        </PaymentConditionCheckbox>
        <PaymentConditionCheckbox conditionKey="irrevocableLetter">
          <>
            <Group>
              <Trans
                t={t}
                i18nKey="formSentences.irrevocableLetter"
                components={{
                  days: <NumberInput w={56} {...getInputProps('payment.conditions.irrevocableLetter.days')} />,
                  percentage: (
                    <NumberInput
                      w={70}
                      {...getBasePercentageProps()}
                      {...getInputProps('payment.conditions.irrevocableLetter.percentage')}
                    />
                  ),
                }}
              />
              <Select
                {...getInputProps('payment.conditions.irrevocableLetter.event')}
                horizontal
                grow={false}
                data={masterdata.irrevocableLetter}
                label={t('labels.confirmationCostAt')}
              />
            </Group>
            <Grid>
              <Grid.Col span={12} md={4}>
                <TextInput
                  {...getInputProps('payment.conditions.irrevocableLetter.bankName')}
                  label={t('labels.bankName')}
                />
              </Grid.Col>
              <Grid.Col span={12} md={4}>
                <TextInput
                  {...getInputProps('payment.conditions.irrevocableLetter.swiftCode')}
                  label={t('labels.swiftCode')}
                />
              </Grid.Col>
            </Grid>
          </>
        </PaymentConditionCheckbox>
        <PaymentConditionCheckbox conditionKey="documentCollection">
          <Group>
            <Trans
              t={t}
              i18nKey="formSentences.documentaryCollection"
              components={{
                days: <NumberInput w={56} {...getInputProps('payment.conditions.documentCollection.days')} />,
                percentage: (
                  <NumberInput
                    w={70}
                    {...getBasePercentageProps()}
                    {...getInputProps('payment.conditions.documentCollection.percentage')}
                  />
                ),
                select: (
                  <Select
                    {...getInputProps('payment.conditions.documentCollection.event')}
                    grow={false}
                    data={masterdata.documentaryCollectionEvents}
                  />
                ),
              }}
            />
          </Group>
        </PaymentConditionCheckbox>
        <PaymentConditionCheckbox conditionKey="openAccount">
          <Group>
            <Trans
              t={t}
              i18nKey="formSentences.openAccount"
              components={{
                days: <NumberInput w={56} {...getInputProps('payment.conditions.openAccount.days')} />,
                percentage: (
                  <NumberInput
                    w={70}
                    {...getBasePercentageProps()}
                    {...getInputProps('payment.conditions.openAccount.percentage')}
                  />
                ),
                select: (
                  <Select
                    {...getInputProps('payment.conditions.openAccount.event')}
                    grow={false}
                    data={masterdata.openAccountEvents}
                  />
                ),
              }}
            />
          </Group>
        </PaymentConditionCheckbox>
        <PaymentConditionCheckbox conditionKey="consumptionPayment">
          <Group>
            <Trans
              t={t}
              i18nKey="formSentences.consumptionPayment"
              components={{
                percentage: (
                  <NumberInput
                    w={70}
                    {...getBasePercentageProps()}
                    {...getInputProps('payment.conditions.consumptionPayment.percentage')}
                  />
                ),
                number: (
                  <NumberInput
                    {...getInputProps('payment.conditions.consumptionPayment.numberOfInstallments')}
                    w={70}
                  />
                ),
                days: <NumberInput w={56} {...getInputProps('payment.conditions.consumptionPayment.days')} />,
                select: (
                  <Select
                    {...getInputProps('payment.conditions.consumptionPayment.event')}
                    grow={false}
                    data={masterdata.consumptionEvents}
                  />
                ),
              }}
            />
          </Group>
        </PaymentConditionCheckbox>
        <PaymentConditionCheckbox conditionKey="other">
          <Group>
            <Trans
              t={t}
              i18nKey="formSentences.other"
              components={{
                text: <TextInput {...getInputProps('payment.conditions.other.methodOfPayment')} />,
                percentage: (
                  <NumberInput
                    w={70}
                    {...getBasePercentageProps()}
                    {...getInputProps('payment.conditions.other.percentage')}
                  />
                ),
              }}
            />
          </Group>
        </PaymentConditionCheckbox>

        {form?.errors['payment.conditions'] && (
          <Text className="paymentConditionsError" color="red" size="S" weight={'bold'}>
            {form?.errors['payment.conditions']}
          </Text>
        )}
      </FormCardSection>
      <FormCardSection title={t('sections.demurrage')}>
        <Grid align="end">
          <Grid.Col span={12} md={6}>
            <NumberInput
              {...getFixedNumberProps(1)}
              {...getInputProps('payment.demurrage.rate')}
              min={0}
              withAsterisk={false}
              label={t('labels.rateDay')}
            />
          </Grid.Col>
          <Grid.Col span={12} md={2}>
            <Select
              withAsterisk={false}
              defaultValue={'MAX'}
              data={masterdata.demurrageRateConditions}
              {...getInputProps('payment.demurrage.rateCondition')}
            />
          </Grid.Col>
          <Grid.Col span={12} md={4}>
            <Select
              withAsterisk={false}
              data={masterdata.demurrageEvents}
              {...getInputProps('payment.demurrage.event')}
            />
          </Grid.Col>
        </Grid>
        <Radio.Group orientation="vertical" {...getInputProps('payment.demurrage.type')}>
          <Group align="flex-start">
            <Radio
              mt={4}
              value="OPEN_ACCOUNT"
              label={t('labels.openAccount')}
              isDirty={isDemurrageRadioDirty('OPEN_ACCOUNT')}
              onClick={() => form.setFieldValue('payment.demurrage.openAccount', {})}
            />
            {demurrage.type === 'OPEN_ACCOUNT' && (
              <Group align="flex-start">
                <NumberInput
                  {...getInputProps('payment.demurrage.openAccount.maxDays')}
                  label={t('labels.maxDays')}
                  description={t('hints.openAccountMaxDays')}
                />
                <Select
                  {...getInputProps('payment.demurrage.openAccount.event')}
                  label={<>&nbsp;</>}
                  sx={{ minWidth: 300 }}
                  data={masterdata.openAccountEvents}
                />
              </Group>
            )}
          </Group>
          <Radio
            value="PROVISION_TO_SELLER"
            label={t('labels.provisionToSeller')}
            isDirty={isDemurrageRadioDirty('PROVISION_TO_SELLER')}
            onClick={() => form.setFieldValue('payment.demurrage.openAccount', undefined)}
          />
          <Radio
            value="PROVISION_TO_TRUSTED_PARTY"
            label={t('labels.provisionToTrustedParty')}
            isDirty={isDemurrageRadioDirty('PROVISION_TO_TRUSTED_PARTY')}
            onClick={() => form.setFieldValue('payment.demurrage.openAccount', undefined)}
          />
        </Radio.Group>
      </FormCardSection>
    </Stack>
  );
};

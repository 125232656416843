/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';

import type { WorkflowServicesDTO, SubscribedServicesIdsDTO, SubscribedServicesDTO } from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getCargoWorkflowServices = (
  workflowId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<WorkflowServicesDTO>> => {
  return axios.get(`/api/v1/workflow/${workflowId}/services`, options);
};

export const getGetCargoWorkflowServicesQueryKey = (workflowId: string) => [`/api/v1/workflow/${workflowId}/services`];

export type GetCargoWorkflowServicesQueryResult = NonNullable<Awaited<ReturnType<typeof getCargoWorkflowServices>>>;
export type GetCargoWorkflowServicesQueryError = AxiosError<void>;

export const useGetCargoWorkflowServices = <
  TData = Awaited<ReturnType<typeof getCargoWorkflowServices>>,
  TError = AxiosError<void>
>(
  workflowId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCargoWorkflowServices>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCargoWorkflowServicesQueryKey(workflowId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCargoWorkflowServices>>> = ({ signal }) =>
    getCargoWorkflowServices(workflowId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCargoWorkflowServices>>, TError, TData>(queryKey, queryFn, {
    enabled: !!workflowId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const postRequestCargoWorkflowServices = (
  workflowId: string,
  subscribedServicesIdsDTO: SubscribedServicesIdsDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/v1/workflow/${workflowId}/services`, subscribedServicesIdsDTO, options);
};

export type PostRequestCargoWorkflowServicesMutationResult = NonNullable<
  Awaited<ReturnType<typeof postRequestCargoWorkflowServices>>
>;
export type PostRequestCargoWorkflowServicesMutationBody = SubscribedServicesIdsDTO;
export type PostRequestCargoWorkflowServicesMutationError = AxiosError<unknown>;

export const usePostRequestCargoWorkflowServices = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postRequestCargoWorkflowServices>>,
    TError,
    { workflowId: string; data: SubscribedServicesIdsDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postRequestCargoWorkflowServices>>,
    { workflowId: string; data: SubscribedServicesIdsDTO }
  > = (props) => {
    const { workflowId, data } = props ?? {};

    return postRequestCargoWorkflowServices(workflowId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof postRequestCargoWorkflowServices>>,
    TError,
    { workflowId: string; data: SubscribedServicesIdsDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const deleteCargoWorkflowSubscribedServices = (
  workflowId: string,
  subscribedServicesIdsDTO: SubscribedServicesIdsDTO,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/v1/workflow/${workflowId}/services`, { data: subscribedServicesIdsDTO, ...options });
};

export type DeleteCargoWorkflowSubscribedServicesMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCargoWorkflowSubscribedServices>>
>;
export type DeleteCargoWorkflowSubscribedServicesMutationBody = SubscribedServicesIdsDTO;
export type DeleteCargoWorkflowSubscribedServicesMutationError = AxiosError<unknown>;

export const useDeleteCargoWorkflowSubscribedServices = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCargoWorkflowSubscribedServices>>,
    TError,
    { workflowId: string; data: SubscribedServicesIdsDTO },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCargoWorkflowSubscribedServices>>,
    { workflowId: string; data: SubscribedServicesIdsDTO }
  > = (props) => {
    const { workflowId, data } = props ?? {};

    return deleteCargoWorkflowSubscribedServices(workflowId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteCargoWorkflowSubscribedServices>>,
    TError,
    { workflowId: string; data: SubscribedServicesIdsDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const getCargoWorkflowRequestedServices = (
  workflowId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<SubscribedServicesDTO>> => {
  return axios.get(`/api/v1/workflow/${workflowId}/services/requested`, options);
};

export const getGetCargoWorkflowRequestedServicesQueryKey = (workflowId: string) => [
  `/api/v1/workflow/${workflowId}/services/requested`,
];

export type GetCargoWorkflowRequestedServicesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCargoWorkflowRequestedServices>>
>;
export type GetCargoWorkflowRequestedServicesQueryError = AxiosError<void>;

export const useGetCargoWorkflowRequestedServices = <
  TData = Awaited<ReturnType<typeof getCargoWorkflowRequestedServices>>,
  TError = AxiosError<void>
>(
  workflowId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCargoWorkflowRequestedServices>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCargoWorkflowRequestedServicesQueryKey(workflowId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCargoWorkflowRequestedServices>>> = ({ signal }) =>
    getCargoWorkflowRequestedServices(workflowId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCargoWorkflowRequestedServices>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!workflowId, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getCargoWorkflowSubscribedServices = (
  workflowId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<SubscribedServicesDTO>> => {
  return axios.get(`/api/v1/workflow/${workflowId}/services/booked`, options);
};

export const getGetCargoWorkflowSubscribedServicesQueryKey = (workflowId: string) => [
  `/api/v1/workflow/${workflowId}/services/booked`,
];

export type GetCargoWorkflowSubscribedServicesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCargoWorkflowSubscribedServices>>
>;
export type GetCargoWorkflowSubscribedServicesQueryError = AxiosError<void>;

export const useGetCargoWorkflowSubscribedServices = <
  TData = Awaited<ReturnType<typeof getCargoWorkflowSubscribedServices>>,
  TError = AxiosError<void>
>(
  workflowId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCargoWorkflowSubscribedServices>>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCargoWorkflowSubscribedServicesQueryKey(workflowId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCargoWorkflowSubscribedServices>>> = ({ signal }) =>
    getCargoWorkflowSubscribedServices(workflowId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getCargoWorkflowSubscribedServices>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!workflowId, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

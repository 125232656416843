import { z } from 'zod';

import { Gears, Grabs, VesselType } from '../../enums';
import { vesselTypeNominated } from './vessel-type-nominated';
import { vesselTypeTBN } from './vessel-type-tbn';

export const vesselInformation = z.object({
  type: z.nativeEnum(VesselType).optional().nullable(),
  vesselNominated: vesselTypeNominated.optional(),
  vesselTBN: vesselTypeTBN.optional(),
  gears: z.nativeEnum(Gears).optional().nullable(),
  cranesNumber: z.number().optional(),
  cranesCapacity: z.number().optional(),
  grabs: z.nativeEnum(Grabs).optional().nullable(),
  grabsNumber: z.number().optional(),
  grabCapacity: z.number().optional(),
});

export type VesselInformationSchema = z.infer<typeof vesselInformation>;

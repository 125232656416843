import { Select, SelectProps } from '@mantine/core';
import { useGetStandardPorts } from 'api/generated/masterdata/masterdata';

import { CargoTradeType } from '@/common/types';
import { useGetPortsSelectData } from '@/views/tenders/components/TenderFormsGroups/hooks';

import { useGetCompanyPortsFilteredSelectItems } from '../state/use-get-company-ports-filtered-select-items';

export interface PortFilteredSelectProps extends Omit<SelectProps, 'data'> {
  companyId: string;
  plantId: string;
  productId: string;
  cargoTradeType: CargoTradeType;
}

export function PortUnFilteredSelect({
  companyId,
  plantId,
  productId,
  cargoTradeType,
  ...props
}: PortFilteredSelectProps) {
  const portSelectItems = useGetStandardPorts({
    query: { select: (res) => res.data.items },
  });

  const itemMaper = (item) => {
    return {
      value: item.id,
      label: item.name,
    };
  };
  const ports = (portSelectItems.data || []).map(itemMaper);

  return <Select data={ports} disabled={ports.length === 0} {...props} id="port" />;
}

export function PortFilteredSelect({
  companyId,
  plantId,
  productId,
  cargoTradeType,
  ...props
}: PortFilteredSelectProps) {
  const portSelectItems = useGetCompanyPortsFilteredSelectItems(companyId, {
    byPlantId: plantId,
    byType: cargoTradeType,
    byProductId: productId,
  });

  return <Select data={portSelectItems} disabled={portSelectItems.length === 0} {...props} />;
}

export interface PortLabelValueSelectProps extends Omit<PortFilteredSelectProps, 'onChange'> {
  plantId: string;
  companyId: string;
  productId: string;
  cargoTradeType: CargoTradeType;
  onChange?: (selected: { label: string; value: string }) => void;
}

export const PortLabelValueSelect = ({
  plantId,
  onChange,
  companyId,
  productId,
  cargoTradeType,
  ...props
}: PortLabelValueSelectProps) => {
  const portSelectItems = useGetCompanyPortsFilteredSelectItems(
    companyId,
    {
      byPlantId: plantId,
      byType: cargoTradeType,
      byProductId: productId,
    },
    false
  );

  const handleChange = (value: string) => {
    const selectedItem = portSelectItems.find((item) => item.value === value);
    onChange?.({ value, label: selectedItem.label });
  };

  return <Select data={portSelectItems} onChange={handleChange} disabled={portSelectItems.length === 0} {...props} />;
};

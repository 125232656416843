import { z } from 'zod';

import { pricingAndPaymentTerms } from '../pricing-and-payment/pricing-and-payment-terms';
import { weight } from '../weight/weight';
import { samplingAndAnalysis } from '../sampling-and-analysis/sampling-and-analysis';
import { vesselInformation } from '../vessel-information/vessel-information';
import { dischargingConditions } from '../loading-discharging-conditions/discharging-conditions';
import { loadingConditions } from '../loading-discharging-conditions/loading-conditions';
import { agentsInPort } from '../agents-in-port/agents-in-port';
import { demurrage } from '../demurrage/demurrage';
import { paymentCondition } from '../pricing-and-payment/payment-conditions';
import { lawAndJurisdiction } from '../law-and-jurisdiction/law-and-jurisdiction';
import { productGeneralInfo } from '../product/product-general-info';

export const detailedOffer = z.object({
  pricingAndPaymentTerms: pricingAndPaymentTerms,
  productGeneralInfo: productGeneralInfo.optional(),
  weight: weight.optional(),
  samplingAndAnalysis: samplingAndAnalysis.optional(),
  vesselInformation: vesselInformation.optional(),
  dischargingConditions: dischargingConditions.optional(),
  loadingConditions: loadingConditions.optional(),
  paymentConditions: paymentCondition
    .refine(
      (conditions) => {
        // Initialize sum for the current condition
        let sum = 0;

        // Add percentage of each payment method if it exists
        if (conditions.prepayment) sum += conditions.prepayment.percentage;
        if (conditions.cashAgainstDocuments) sum += conditions.cashAgainstDocuments.percentage;
        if (conditions.irrevocableLetter) sum += conditions.irrevocableLetter.percentage;
        if (conditions.documentCollection) sum += conditions.documentCollection.percentage;
        if (conditions.openAccount) sum += conditions.openAccount.percentage;
        if (conditions.consumptionPayment) sum += conditions.consumptionPayment.percentage;
        if (conditions.other) sum += conditions.other.percentage;

        return sum === 1; // percentages are not converted
      },
      {
        message: 'The total percentage must equal 100% of payment conditions. Change the percentage in conditions.',
      }
    )
    .refine(
      (data) => {
        const values = Object.values(data);
        // Count how many conditions are actually filled (not undefined and have data)
        const filledConditions = values.filter((condition) => {
          return condition && typeof condition === 'object' && Object.keys(condition).length > 0;
        });
        return filledConditions.length >= 1;
      },
      {
        message: 'At least one payment condition must be selected and filled.',
      }
    ),
  demurrage: demurrage.optional(),
  agentsInLoadingPort: agentsInPort.optional(),
  agentsInDischargingPort: agentsInPort.optional(),
  lawAndJurisdiction: lawAndJurisdiction,
  other: z.string().optional(),
  sendCustomMessage: z.boolean().optional(),
  customMessage: z.string().optional(),
});

export type DetailedOfferSchema = z.infer<typeof detailedOffer>;

import { Center, createStyles, Modal, ModalProps, Stack } from '@mantine/core';
import { ReactElement } from 'react';

import { NeedHelp, Title } from '@/components';

declare module '@mantine/core' {
  export interface ModalProps {
    scrollable?: boolean;
  }
}

export interface BaseModalProps extends Omit<ModalProps, 'onClose' | 'content'> {
  opened: boolean;
  header: ReactElement | string;
  icon?: ReactElement;
  content?: ReactElement;
  hideContact?: boolean;
  close: VoidFunction;
  disabledClosing?: boolean;
  notCenterTitle?: boolean;
}

const useStyles = createStyles((theme, params: BaseModalProps) => ({
  header: {
    marginBottom: 0,
  },
  body: {
    overflowY: `${params.scrollable ? 'scroll' : 'auto'}`,
  },
  customTextAlign: {
    [theme.fn.smallerThan('xs')]: {
      textAlign: params.notCenterTitle ? 'left' : 'center',
    },
    [theme.fn.largerThan('xs')]: {
      textAlign: 'center',
    },
  },
}));

export function BaseModal(props: BaseModalProps) {
  const { opened, header, icon, content, close, hideContact, disabledClosing, notCenterTitle, ...mantineModalProps } =
    props;
  const { classes } = useStyles(props);

  return (
    <Modal
      classNames={classes}
      padding={24}
      opened={opened}
      onClose={close}
      closeOnClickOutside={!disabledClosing}
      closeOnEscape={!disabledClosing}
      withCloseButton={!disabledClosing}
      radius="md"
      data-testid={'modal'}
      {...mantineModalProps}
    >
      <Stack spacing={24}>
        <Center mb={-8}>{icon}</Center>
        <Title order={2} size="XL" weight={600} className={classes.customTextAlign}>
          {header}
        </Title>
        {content}
        {!hideContact && <NeedHelp />}
      </Stack>
    </Modal>
  );
}

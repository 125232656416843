import { PlantDTO, PlantRelatedPort, PortDTO, ProductDTO } from 'api/generated/model';
import { useMemo } from 'react';

type ProductLike = Pick<ProductDTO, 'id' | 'name' | 'grade' | 'packaging'>;
type PlantLike = Pick<PlantDTO, 'id' | 'companyName' | 'name'>;
type PortLike = Pick<PlantRelatedPort, 'id' | 'name'>;
type PortMultiselectLike = Pick<PortDTO, 'id' | 'name' | 'country'>;

export const productToLabel = (product: ProductLike) => `${product.name} (${product.grade}) in ${product.packaging}`;

export const productToName = (product: ProductLike) => `${product.name}`;

export const useMapProductsToSelectData = (products?: ProductLike[]) =>
  useMemo(() => products?.map((product) => ({ value: product.id, label: productToLabel(product) })) || [], [products]);

export const useMapProductsToNameData = (products?: ProductLike[]) => {
  const productNames = [...new Set(products?.map((product) => productToName(product)))] || [];
  return useMemo(() => productNames?.map((product) => ({ value: product, label: product })) || [], [products]);
};

export function mapPlantsToSelectData<T extends PlantLike>(plants?: T[]) {
  return plants?.map((plant) => ({ value: plant.id, label: `${plant.companyName} - ${plant.name}` })) || [];
}

export function mapPortsToSelectData<T extends PortLike>(ports?: T[]) {
  return ports?.map((port) => ({ value: port.id, label: port.name })) || [];
}

export function mapPortsToMultiselectData<T extends PortMultiselectLike>(ports?: T[]) {
  return ports?.map((port) => ({ value: port.id, label: port.name, group: port.country })) || [];
}

export const useMapPlantsToSelectData = (plants?: PlantLike[]) =>
  useMemo(() => mapPlantsToSelectData(plants), [plants]);

export const useMapPortsToSelectData = (ports?: PortLike[]) => useMemo(() => mapPortsToSelectData(ports), [ports]);

export const useMapPortsToMultiselectData = (ports?: PortMultiselectLike[]) =>
  useMemo(() => mapPortsToMultiselectData(ports), [ports]);

import { InputWrapperProps } from '@mantine/core';

import { ThemeComponent } from './types';

export const InputWrapper: ThemeComponent<InputWrapperProps> = {
  styles: () => ({
    label: {
      position: 'relative',
      fontSize: 16,
      marginBottom: 8,
    },
    root: {
      position: 'relative',
    },
    description: {
      position: 'relative',
      color: '#535A60',
    },
  }),
  defaultProps: {
    inputWrapperOrder: ['label', 'input', 'description', 'error'],
    withAsterisk: true,
  },
};
